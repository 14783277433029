import React, {useState, useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import Select, {components} from 'react-select';
import removeImg from './icons/remove.svg'
import uuid from 'react-uuid';
import country_arr from './countries-arr.js';
import s_a from './states-arr.js';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import currency_arr from './currency-arr.js' ;

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

// import $ from 'jquery';
// import moment from 'moment';

function EditCustomerDetails(props) {
    const [user, setUser] = useState({});
    const [organization_id,setOrganization_id] = useState("")
    const [organization_list, setorganization_list] = useState([]);
    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);
    const [state, setstate] = useState(2);

    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
	const [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);

    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [customerDetails, setCustomerDetails] = useState(null)
    const [currencyList, setCurrencyList] = useState([])
    const [termsList, setTermsList] = useState([])

    const [customerType, setCustomerType] = useState("business")
    const [primaryContact, setPrimaryContact] = useState({salutation: null, firstName: "", lastName: ""})
    const [companyName, setCompanyName] = useState("")
    const [customerDisplayNameOptions, setCustomerDisplayNameOptions] = useState([])
    const [selectedCustomerDisplayName, setSelectedCustomerDisplayName] = useState(null)
    const [workPhone, setWorkPhone] = useState("")
    const [mobile, setMobile] = useState("")
    const [designation, setDesignation] = useState("")
    const [department, setDepartment] = useState("")
    const [email, setEmail] = useState("")
    const [website, setWebsite] = useState("")
    const [selectedLeadSource, setSelectedLeadSource] = useState(null)

    const [currentPanel, setCurrentPanel] = useState("otherDetails")

    const [selectedIdentityType, setSelectedIdentityType] = useState(null)
    const [identityNumber, setIdentityNumber] = useState("")
    const [pan, setPan] = useState("")
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const [selectedTerm, setSelectedTerm] = useState(null)
    const [portalCheck, setPortalCheck] = useState(false)
    const [selectedPortalLanguage, setSelectedPortalLanguage] = useState(null)
    const [facebookLink, setFacebookLink] = useState("")
    const [twitterLink, setTwitterLink] = useState("")

    const [billingAttention, setBillingAttention] = useState("")
    const [billingCountryOptions, setBillingCountryOptions] = useState([]);
    const [billingStateOptions, setBillingStateOptions] = useState([]);
    const [billingSelectedCountry, setBillingSelectedCountry] = useState(null)
    const [billingSelectedState, setBillingSelectedState] = useState(null)
    const [billingCity, setBillingCity] = useState("")
    const [billingPincode, setBillingPincode] = useState("")
    const [billingAddress1, setBillingAddress1] = useState("")
    const [billingAddress2, setBillingAddress2] = useState("")
    const [billingPhone, setBillingPhone] = useState("")
    const [billingFax, setBillingFax] = useState("")
    const [billingDeliveryInstruction, setBillingDeliveryInstruction] = useState("")

    const [shippingAttention, setShippingAttention] = useState("")
    const [shippingCountryOptions, setShippingCountryOptions] = useState([]);
    const [shippingStateOptions, setShippingStateOptions] = useState([]);
    const [shippingSelectedCountry, setShippingSelectedCountry] = useState(null)
    const [shippingSelectedState, setShippingSelectedState] = useState(null)
    const [shippingCity, setShippingCity] = useState("")
    const [shippingPincode, setShippingPincode] = useState("")
    const [shippingAddress1, setShippingAddress1] = useState("")
    const [shippingAddress2, setShippingAddress2] = useState("")
    const [shippingPhone, setShippingPhone] = useState("")
    const [shippingFax, setShippingFax] = useState("")
    const [shippingDeliveryInstruction, setShippingDeliveryInstruction] = useState("")

    const [profilePhoto, setProfilePhoto] = useState("")
    const [fileNames, setFileNames] = useState([])
    const [fileSessionUniqueId, setFileSessionUniqueId] = useState(uuid())
    const [oldProfilePhoto, setOldProfilePhoto] = useState(null)
    const [oldFileNames, setOldFileNames] = useState([])

    const [contactPersonsArray, setContactPersonsArray] = useState([{salutation:null, firstName:"", lastName:"", email:"", workPhone:"", mobile:"", designation:"", department:""}])

    const [remarks, setRemarks] = useState("")

    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

    const [termsModalShow, setTermsModalShow] = useState(false)
    const [termsPanelList, setTermsPanelList] = useState([])
    const [termsSubmitButtonState, setTermsSubmitButtonState] = useState(false)

    const [currencyModalShow, setCurrencyModalShow] = useState(false)
    const [selectedCurrencyCode, setSelectedCurrencyCode] = useState(null)
    const [currencySymbol, setCurrencySymbol] = useState("")
    const [selectedCurrencyDecimalPlace, setSelectedCurrencyDecimalPlace] = useState(null)
    const [selectedCurrencyFormat, setSelectedCurrencyFormat] = useState(null)
    const [currencySubmitButtonState, setCurrencySubmitButtonState] = useState(false)

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

	var page_name = "edit-customer-details";

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true);
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    setRedirect_to_login(1);
                });
                
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if(temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else
                                    {
                                        temp = 2;
                                    }
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
							
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setRedirect_to_login(1);
                });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }

                // console.log(timer)
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);





    useEffect(() => {
        if(customerDetails != null){

            let basicDetailsObj = customerDetails.basic[0]

            setCustomerType(basicDetailsObj.customer_type)
            let tpc = JSON.parse(basicDetailsObj.primary_contact)
            if(tpc.salutation!=null){
                tpc = {...tpc,salutation: {label: tpc.salutation,value: tpc.salutation}}
            }
            setPrimaryContact(tpc)
            setCompanyName(basicDetailsObj.company_name)
            setSelectedCustomerDisplayName({label: basicDetailsObj.customer_display_name, value: basicDetailsObj.customer_display_name})
            setWorkPhone(basicDetailsObj.work_phone)
            setMobile(basicDetailsObj.mobile)
            setDesignation(basicDetailsObj.designation)
            setDepartment(basicDetailsObj.department)
            setEmail(basicDetailsObj.email)
            setWebsite(basicDetailsObj.website)
            setSelectedLeadSource({label: basicDetailsObj.lead_source, value: basicDetailsObj.lead_source})

            let tit = {}
            if(basicDetailsObj.identity_type == "aadhar_card")
                tit = {label: "Aadhar Card", value: "aadhar_card"}
            else
                tit = {label: "Voter Card", value: "voter_card"}
            setSelectedIdentityType(tit)
            setIdentityNumber(basicDetailsObj.identity_number)
            setPan(basicDetailsObj.pan)
            let tc = JSON.parse(basicDetailsObj.currency)
            setSelectedCurrency({label: tc.currency_code, value: tc.currency_id})
            let tte = JSON.parse(basicDetailsObj.term)
            if(tte != null){
                tte = {label: tte.term_name, value: tte.term_days}
            }
            setSelectedTerm(tte)
            setPortalCheck(basicDetailsObj.portal_access=="yes"?true:false) 
            setSelectedPortalLanguage(basicDetailsObj.portal_language==null?null:basicDetailsObj.portal_language)
            setFacebookLink(basicDetailsObj.facebook_link)
            setTwitterLink(basicDetailsObj.twitter_link)

            let billingAddressObj = customerDetails.address.find(obj=>obj.address_type == "billing")
            let shippingAddressObj = customerDetails.address.find(obj=>obj.address_type == "shipping")

            setBillingAttention(billingAddressObj.attention)
            setBillingSelectedCountry(billingAddressObj.country==null?null:{label:billingAddressObj.country, value:billingAddressObj.country})
            setBillingSelectedState(billingAddressObj.state==null?null:{label:billingAddressObj.state, value:billingAddressObj.state})
            setBillingCity(billingAddressObj.city)
            setBillingPincode(billingAddressObj.pincode)
            setBillingAddress1(billingAddressObj.address_line1)
            setBillingAddress2(billingAddressObj.address_line2)
            setBillingPhone(billingAddressObj.phone)
            setBillingFax(billingAddressObj.fax)
            setBillingDeliveryInstruction(billingAddressObj.delivery_instruction)

            setShippingAttention(shippingAddressObj.attention)
            setShippingSelectedCountry(shippingAddressObj.country==null?null:{label:shippingAddressObj.country, value:shippingAddressObj.country})
            setShippingSelectedState(shippingAddressObj.state==null?null:{label:shippingAddressObj.state, value:shippingAddressObj.state})
            setShippingCity(shippingAddressObj.city)
            setShippingPincode(shippingAddressObj.pincode)
            setShippingAddress1(shippingAddressObj.address_line1)
            setShippingAddress2(shippingAddressObj.address_line2)
            setShippingPhone(shippingAddressObj.phone)
            setShippingFax(shippingAddressObj.fax)
            setShippingDeliveryInstruction(shippingAddressObj.delivery_instruction)

            let tca = JSON.parse(basicDetailsObj.contact_persons)
            tca = tca.map((obj)=>{
                if(obj.salutation != null){
                    obj.salutation = {label: obj.salutation, value: obj.salutation}
                }
                return obj                
            })
            
            if(tca.length>0){
                setContactPersonsArray(tca)
            }

            let documentsObj = customerDetails.document

            let topf = documentsObj.find((obj)=>obj.file_name.startsWith("profile"))
            if(topf != undefined){
                setOldProfilePhoto(topf)
            }
            let tfn = documentsObj.filter((obj)=>!(obj.file_name.startsWith("profile")))
            if(tfn.length>0){
                setOldFileNames(tfn)
            }
        }
    }, [customerDetails])
    

    async function getCustomerDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/customer-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "cid": props.match.params.id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        let body = await response.json();
        if (body.basic.length != 0) {
            setCustomerDetails(body)
        }
        else{
            throw "no such customer"
        }
    }
    async function getCurrencies() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        let body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCurrencyList(body)
        }
        else{
            throw "get currency error"
        }
    }
    async function getTerms() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-invoice-terms`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        let body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setTermsList(body)
        }
        else{
            throw "get terms error"
        }
    }
    useEffect(() => {
		if ((read || write) && (user.email && visibility)) {
            let p1 = getCustomerDetails();
            p1.then(() => {
                let p2 = getCurrencies()
                let p3 = getTerms()
                Promise.all([p2,p3])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(4)
                    console.log(err)
                })
            })
            .catch((errx) => {
                setstate(3)
                console.log(errx)
            })
        }

	}, [user, visibility, read, write])

    useEffect(() => {
        let tarr = []
        tarr = [...tarr, primaryContact.firstName+" "+primaryContact.lastName, primaryContact.lastName+", "+primaryContact.firstName, primaryContact.firstName, primaryContact.lastName]
        if(primaryContact.salutation!=null)
            tarr = [...tarr, primaryContact.salutation.value+" "+primaryContact.firstName+" "+primaryContact.lastName, primaryContact.salutation.value+" "+primaryContact.firstName, primaryContact.salutation.value+" "+primaryContact.lastName]

        // console.log(selectedCustomerDisplayName, tarr)

        if((selectedCustomerDisplayName!=null) && (!tarr.includes(selectedCustomerDisplayName.value)))
            setSelectedCustomerDisplayName(null)

        let tempArr = []
        if(primaryContact.salutation!=null){
            if(primaryContact.firstName.trim()!=""){
                if(primaryContact.lastName.trim()!=""){
                    tempArr.push(primaryContact.salutation.value+" "+primaryContact.firstName+" "+primaryContact.lastName, primaryContact.firstName+" "+primaryContact.lastName, primaryContact.lastName+", "+primaryContact.firstName)
                }
                else{
                    tempArr.push(primaryContact.salutation.value+" "+primaryContact.firstName, primaryContact.firstName)
                }
            }
            else{
                if(primaryContact.lastName.trim()!=""){
                    tempArr.push(primaryContact.salutation.value+" "+primaryContact.lastName, primaryContact.lastName)
                }
            }
        }
        else{
            if(primaryContact.firstName.trim()!=""){
                if(primaryContact.lastName.trim()!=""){
                    tempArr.push(primaryContact.firstName+" "+primaryContact.lastName, primaryContact.lastName+", "+primaryContact.firstName)
                }
                else{
                    tempArr.push(primaryContact.firstName)
                }
            }
            else{
                if(primaryContact.lastName.trim()!=""){
                    tempArr.push(primaryContact.lastName)
                }
            }
        }
        setCustomerDisplayNameOptions(tempArr)
    }, [primaryContact])
    
	
	useEffect(() => {
		let obj_array = []
		country_arr.country_arr.forEach((obj, index) => {
            if(index!=0){
                let obj2 = {};
                obj2.label = obj;
                obj2.value = obj;

                obj_array.push(obj2);
            }
		})
		setBillingCountryOptions(obj_array);
		setShippingCountryOptions(obj_array);
	}, []);
	
	function loadBillingStateOptions(country) {
		let selectedCountryIndex = 0;
		billingCountryOptions.map((obj, index) => {
			if (obj.value == country.value) {
				selectedCountryIndex = index;
			}
		})
        console.log(selectedCountryIndex)

		let state_arr = s_a.s_a[selectedCountryIndex+1].split("|");
		let obj_array = [];
		for (let i = 0; i < state_arr.length; i++) {
			obj_array.push({ value: state_arr[i], label: state_arr[i] });
		}
		setBillingStateOptions(obj_array);
	}

    function loadShippingStateOptions(country) {
		let selectedCountryIndex = 0;
		shippingCountryOptions.map((obj, index) => {
			if (obj.value == country.value) {
				selectedCountryIndex = index;
			}
		})
        console.log(selectedCountryIndex)

		let state_arr = s_a.s_a[selectedCountryIndex+1].split("|");
		let obj_array = [];
		for (let i = 0; i < state_arr.length; i++) {
			obj_array.push({ value: state_arr[i], label: state_arr[i] });
		}
		setShippingStateOptions(obj_array);
	}

    function copyAddress(){
        setShippingAttention(billingAttention)
        setShippingCountryOptions(billingCountryOptions)
        setShippingStateOptions(billingStateOptions)
        setShippingSelectedCountry(billingSelectedCountry)
        setShippingSelectedState(billingSelectedState)
        setShippingCity(billingCity)
        setShippingPincode(billingPincode)
        setShippingAddress1(billingAddress1)
        setShippingAddress2(billingAddress2)
        setShippingPhone(billingPhone)
        setShippingFax(billingFax)
        setShippingDeliveryInstruction(billingDeliveryInstruction)
    }

    const documentDelete = (folder, fileName, originalFileName, id) => {
        
        var object = {};
        object.folder = folder
        object.file_name = fileName
        object.original_file_name = originalFileName
        object.id = id

        object.organization_id = organization_id
        object.page_name = page_name;

        console.log(object)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/delete-customer-documents`, object, headers).then((res) => {
            if (res.data.operation === "success") {
                swal("Great!", "Document deleted successfully!", "success")
                if(object.file_name.startsWith("profile")){
                    setOldProfilePhoto(null)
                }
                else{
                    let temp = oldFileNames.map(x=>{return {...x}})
                    temp = temp.filter((obj)=>obj.id != id)
                    setOldFileNames(temp)
                }
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const register = () => {
    
        var nexp = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~0-9]/;
        if ((primaryContact.firstName == "") && (primaryContact.lastName == "")) {
            swal("Warning!", "Primary Contact must contain first name or last name", "warning");
            return
        }
        
        if(nexp.test(primaryContact.firstName)){
            swal("Warning!", "First name cannot contain a number or symbols", "warning");
            return
        }
        if(nexp.test(primaryContact.lastName)){
            swal("Warning!", "Last name cannot contain a number or symbols", "warning");
            return
        }

        if(selectedCustomerDisplayName == null){
            swal("Warning!", "Customer Display Name cannot be empty", "warning");
            return
        }

        if(workPhone == ""){
            swal("Warning!", "Work phone cannot be empty", "warning");
            return
        }
        let regex1 = /^(\+\d{1,3}[- ]?)?\d{10}$/
        if(!regex1.test(workPhone)){
            swal("Warning!", "Please provide valid Work phone number", "warning");
            return
        }


        if((portalCheck) && (email == "")) {
            swal("Warning!", "Email id cannot be empty", "warning");
            return false;
        }
        let regex2 = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if((email!="") && (!regex2.test(email))) {
            swal("Warning!", "Please enter a valid Email id", "warning");
            return false;
        }

        if(selectedLeadSource == null){
            swal("Warning!", "Lead Source cannot be empty", "warning");
            return
        }

        if(selectedIdentityType == null){
            swal("Warning!", "Identity type cannot be empty", "warning");
            return
        }
        if(identityNumber == '')
		{
            swal("Warning!", "Identity Number cannot be empty", "warning");
            return
        }
        let regex3 = /^[0-9]{4}?[0-9]{4}?[0-9]{4}$/;
        if ((selectedIdentityType.value == 'aadhar_card') && (!regex3.test(identityNumber))) {
            swal("Warning!", "Please enter a valid Aadhar number without any spaces", "warning");
            return
        }

        let regex4 = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        if (!regex4.test(pan)) {
            swal("Warning!", "Please enter a valid Pan number", "warning");
            return
        }

        if(selectedCurrency == null){
            swal("Warning!", "Currency cannot be empty", "warning");
            return
        }

        let object = {};
        
        object.cid = props.match.params.id
        
        object.customer_type = customerType
        object.primary_contact = {...primaryContact,salutation: primaryContact.salutation==null?null:primaryContact.salutation.value}
        object.company_name = companyName
        object.customer_display_name = selectedCustomerDisplayName.value
        object.work_phone = workPhone
        object.mobile = mobile
        object.designation = designation
        object.department = department
        object.email = email
        object.website = website
        object.lead_source = (selectedLeadSource==null)?null:selectedLeadSource.value

        object.identity_type = selectedIdentityType.value
        object.identity_number = identityNumber
        object.pan = pan
        object.currency = {currency_code: selectedCurrency.label, currency_id: selectedCurrency.value}
        object.term = (selectedTerm==null)?undefined:{term_name:selectedTerm.label, term_days:selectedTerm.value}
        object.portal_check = portalCheck?"yes":"no"
        object.portal_language = (selectedPortalLanguage==null)?null:selectedPortalLanguage.value
        object.facebook_link = facebookLink
        object.twitter_link = twitterLink

        object.billing_attention = billingAttention
        object.billing_country = (billingSelectedCountry==null)?null:billingSelectedCountry.value
        object.billing_state = (billingSelectedState==null)?null:billingSelectedState.value
        object.billing_city = billingCity
        object.billing_pincode = billingPincode
        object.billing_address1 = billingAddress1
        object.billing_address2 = billingAddress2
        object.billing_phone = billingPhone
        object.billing_fax = billingFax
        object.billing_delivery_instruction = billingDeliveryInstruction

        object.shipping_attention = shippingAttention
        object.shipping_country = (shippingSelectedCountry==null)?null:shippingSelectedCountry.value
        object.shipping_state = (shippingSelectedState==null)?null:shippingSelectedState.value
        object.shipping_city = shippingCity
        object.shipping_pincode = shippingPincode
        object.shipping_address1 = shippingAddress1
        object.shipping_address2 = shippingAddress2
        object.shipping_phone = shippingPhone
        object.shipping_fax = shippingFax
        object.shipping_delivery_instruction = shippingDeliveryInstruction

        object.profile_photo = profilePhoto
        object.file_names = fileNames
        object.unique_id = fileSessionUniqueId

        let temp = contactPersonsArray.filter((obj)=>{
            if (!((obj.salutation == null) && (obj.firstName == "") && (obj.lastName == "") && (obj.email == "") && (obj.workPhone == "") && (obj.mobile == "") && (obj.designation == "") && (obj.department == "")))
                return true
        })
        temp = temp.map((obj)=>{
            if(obj.salutation != null){
                obj.salutation = obj.salutation.value
            }
            return(obj)
        })
        object.contact_persons_array = temp
        object.remarks = remarks

        object.organization_id=organization_id;
        object.page_name=page_name;

        console.log(object);
        setMainSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-customer`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data.operation === "same_email") {
                swal("Oops!", "Email already exists!!", "error");
            }
            else if (res.data.operation === "success") {
                swal("Great!", "You have updated the Customer successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const newTermsSubmit = () => {
        let f1 = true
        termsPanelList.forEach(obj=>{
            if((obj.term_name.trim() == "") || (obj.no_of_days=="")){
                f1=false
            }
        })
        if(f1 == false){
            swal("Oops!", "Term Name or No of Days cannot be empty", "warning");
            return
        }

        let temp = termsPanelList.map(obj=>{ return obj.term_name.trim() })
        if(temp.length!= [... new Set(temp)].length){
            swal("Oops!", "Term Name cannot be a duplicate", "warning");
            return
        }

        var object = {};

        object.terms_panel_list = termsPanelList

        object.organization_id = organization_id;
        object.page_name = page_name;

        // console.log(object);
        setTermsSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-invoice-terms`, object, headers).then((res) => {
            setTermsSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Sales Person added successfully!", "success")
                handleNewTermsModalClose()
                getTerms()
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const handleNewTermsModalClose = () => {
        setTermsModalShow(false)
        setTermsPanelList([]) 
    }

    const newCurrencySubmit = () => {

        if(selectedCurrencyCode == null){
            swal("Oops!", "Currency Code cannot be empty", "error");
            return
        }
        else if(currencySymbol == ""){
            swal("Oops!", "Currency Symbol cannot be empty", "error");
            return
        }

        var object = {};

        object.currency_code = selectedCurrencyCode.value
        object.currency_symbol = currencySymbol
		object.decimal_place = selectedCurrencyDecimalPlace==null?null:selectedCurrencyDecimalPlace.value
		object.format = selectedCurrencyFormat==null?null:selectedCurrencyFormat.value

        object.organization_id = organization_id;
        object.page_name = page_name;

        // console.log(object);
        setCurrencySubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-currency`, object, headers).then((res) => {
            setCurrencySubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Currency added successfully!", "success")
                handleNewCurrencyModalClose()
                getCurrencies()
            }
            else if(res.data.operation === "same_currency_code"){
                swal("Oops!", "This Currency code is already used!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const handleNewCurrencyModalClose = () => {
        setCurrencyModalShow(false)
        setSelectedCurrencyCode(null) 
        setCurrencySymbol("")
        setSelectedCurrencyDecimalPlace(null)
        setSelectedCurrencyFormat(null)
    }

    var files = [];

    const CustomLayout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
        const previewsToDisplay = previews.filter((preview) => {
            return preview.props.meta.status !== 'error_validation';
        });

        return (
            <div {...dropzoneProps}>
                {previewsToDisplay}
                {files.length < maxFiles && input}
            </div>
        );
    };

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        return (
            <div className='w-100' style={{overflowX:"scroll"}}>
                <div className="preview-box" style={{minWidth:"max-content"}}>
                    <img src={previewUrl} />
                    <span className="name" title={name}>{name.length>20?name.substring(0,10) +"..."+ name.substring(name.length-10):name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>
                    {status != "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" style={{ backgroundImage: `url(${removeImg})` }} onClick={remove} />}
                    {status == "done" && <span className="dzu-previewButton remove" style={{ backgroundImage: `url(${removeImg})` }} onClick={remove} />}
                </div>
            </div>
        )
    }

    const getUploadParams1 = ({ file }) => {

        const body = new FormData();
        body.append('dataFiles', file);
        body.append('unique_id', fileSessionUniqueId)
        body.append('user_id', user.email);
        body.append('page_name', page_name);

        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        
        setMainSubmitButtonState(true)
        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-customer-profile`, headers, body }
    }

    const handleChangeStatus1 = ({ meta }, status, allFiles) => {
        const { name } = meta;
        
        if (status === 'done') {
            setMainSubmitButtonState(false)
            setProfilePhoto(name)
        }
        else if (status === 'removed') {
            setMainSubmitButtonState(false)
            setProfilePhoto("")
        }
        else if(status === "error_file_size")
        {
            allFiles[0].remove()
            swal("Oops!", "File size exceeded", "warning");
        }
    };

    const getUploadParams2 = ({ file }) => {

        const body = new FormData();
        body.append('dataFiles', file);
        body.append('unique_id', fileSessionUniqueId)
        body.append('user_id', user.email);
        body.append('page_name', page_name);

        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        
        setMainSubmitButtonState(true)
        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-customer-documents`, headers, body }
    }

    const handleChangeStatus2 = ({ meta }, status, allFiles) => {
        const { name } = meta;
        
        if (status === 'done') {
            setMainSubmitButtonState(false)
            setFileNames([...fileNames, name])
        }
        else if (status === 'removed') {
            setMainSubmitButtonState(false)
            let temp = [...fileNames] 
            temp.splice(temp.indexOf(name), 1)
            setFileNames(temp)
        }
        else if(status === "error_file_size")
        {
            allFiles[0].remove()
            swal("Oops!", "File size exceeded", "warning");
        }
    };

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    return (
        <>
            {
                (redirect == 1 ||(read==0 && write==0)) ? 
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> :
                 null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}

            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Edit Customer</h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">

                        {
                            state == 1?
                                <>
                                    <div className="card">
                                        <div className="card-body">

                                            <div className='container'>
                                                <div className="row mb-5">
                                                    <div className='col-4 my-auto'>
                                                        <label className="fs-5 fw-bold mb-2">Customer Type</label>
                                                    </div>
                                                    <div className='col-4'>
                                                        <label className="fs-5 mb-2" onClick={()=>{ setCustomerType("business") }}> Business
                                                            <input type="radio" className="form-check-input ms-5" checked={customerType=="business"} />
                                                        </label>
                                                    </div>
                                                    <div className='col-4'>
                                                        <label className="fs-5 mb-2" onClick={()=>{ setCustomerType("individual") }}>Individual
                                                            <input type="radio" className="form-check-input ms-5" checked={customerType=="individual"}/>
                                                        </label>                                                    
                                                    </div>
                                                </div>

                                                <div className="row mb-5">
                                                    <div className="col-3 my-auto">
                                                        <label className="required fs-5 fw-bold mb-2">Primary Contact</label>
                                                    </div>
                                                    <div className='col-3 my-auto'>
                                                        <Select
                                                            options={["Mr.","Mrs.","Ms.","Miss.","Dr."].map(x=>{return {label:x, value:x} })}
                                                            value={primaryContact.salutation}
                                                            onChange={(val)=>{ 
                                                                setPrimaryContact({...primaryContact, salutation: val})
                                                            }}
                                                            isClearable
                                                            placeholder="Salutation"
                                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                        />
                                                    </div>
                                                    <div className='col-6 input-group-text p-0'>
                                                        <input type="text" className="form-control form-control-solid" value={primaryContact.firstName} onChange={(e)=>{setPrimaryContact({...primaryContact,firstName: e.target.value}) }} placeholder='First Name'/>
                                                        <input type="text" className="form-control form-control-solid" value={primaryContact.lastName} onChange={(e)=>{setPrimaryContact({...primaryContact,lastName: e.target.value}) }} placeholder='Last Name'/>
                                                    </div>
                                                </div>

                                                <div className="row mb-5">
                                                    <div className="col-md-6">
                                                        <label className="fs-5 fw-bold mb-2">Company Name</label>
                                                        <input type="text" className="form-control form-control-solid" value={companyName} onChange={(e)=>{setCompanyName(e.target.value)}} />
                                                    </div>
                                                    <div className="col-md-6 my-auto">
                                                        <label className="required fs-5 fw-bold mb-2">Customer Display Name</label>
                                                        <Select
                                                            options={ customerDisplayNameOptions.map(x=>{return {label:x, value:x} })}
                                                            value={selectedCustomerDisplayName}
                                                            onChange={(val)=>{ setSelectedCustomerDisplayName(val) }}
                                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mb-5">
                                                    <div className="col-md-6">
                                                        <label className="required fs-5 fw-bold mb-2">Work Phone</label>
                                                        <input type="number" className="form-control form-control-solid" value={workPhone} onChange={(e)=>{setWorkPhone(e.target.value)}} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="fs-5 fw-bold mb-2">Mobile</label>
                                                        <input type="number" className="form-control form-control-solid" value={mobile} onChange={(e)=>{setMobile(e.target.value)}} />
                                                    </div>
                                                </div>

                                                <div className="row mb-5">
                                                    <div className="col-md-6">
                                                        <label className="fs-5 fw-bold mb-2">Designation</label>
                                                        <input type="text" className="form-control form-control-solid" value={designation} onChange={(e)=>{setDesignation(e.target.value)}} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="fs-5 fw-bold mb-2">Department</label>
                                                        <input type="text" className="form-control form-control-solid" value={department} onChange={(e)=>{setDepartment(e.target.value)}} />
                                                    </div>
                                                </div> 

                                                <div className="row mb-5">
                                                    <div className="col-md-6">
                                                        <label className= {"fs-5 fw-bold mb-2 "+(portalCheck?"required":"")}>Email</label>
                                                        <input type="email" className="form-control form-control-solid" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <label className="fs-5 fw-bold mb-2">Website</label>
                                                        <input type="text" className="form-control form-control-solid" value={website} onChange={(e)=>{setWebsite(e.target.value)}} />
                                                    </div>
                                                </div>

                                                <div className="row mb-5">
                                                    <div className='col-md-3 my-auto'>
                                                        <label className="required fs-5 fw-bold mb-2">Lead Source:</label>
                                                    </div>
                                                    <div className='col-md-9'>
                                                        <Select
                                                            options={["CRM","Websites","Social","Referral","Cold Call","SEO"].map(x=>{return {label:x, value:x} })}
                                                            value={selectedLeadSource}
                                                            onChange={(val) => { setSelectedLeadSource(val) }}
                                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            <div className='' style={{marginTop:"35px"}}>
                                                <Tabs className="mb-3 fs-4" activeKey={currentPanel} onSelect={(k) => setCurrentPanel(k)}>

                                                    <Tab eventKey="otherDetails" title="Other Details" className='mt-10'>
                                                        <div className='container'>
                                                            <div className='row mb-5'>
                                                                <div className="col-md-4 my-auto">
                                                                    <label className="required fs-5 fw-bold mb-2">Identity Type</label>
                                                                    <Select
                                                                        options = {[{label:"Aadhar Card", value:"aadhar_card"}, {label:"Voter Card", value:"voter_card"}]}
                                                                        value= {selectedIdentityType}
                                                                        onChange= {(val)=>{setSelectedIdentityType(val)} }
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label className="required fs-5 fw-bold mb-2">Identity Number</label>
                                                                    <input type="text" className="form-control form-control-solid" value={identityNumber} onChange={(e)=>{setIdentityNumber(e.target.value)}} />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label className="required fs-5 fw-bold mb-2">Pan</label>
                                                                    <input type="text" className="form-control form-control-solid" value={pan} onChange={(e)=>{setPan(e.target.value)}} />
                                                                </div>
                                                            </div>

                                                            <div className="row mb-5">
                                                                <div className="col-md-6">
                                                                    <label className="required fs-5 fw-bold mb-2">Currency</label>
                                                                    <Select
                                                                        options={ currencyList.map(x=>{return {label:x.currency_code,value:x.currency_id} }) }
                                                                        onChange={(val) => {setSelectedCurrency(val)}}        
                                                                        value={selectedCurrency}
                                                                        components={{ 
                                                                            MenuList: (props) => {
                                                                                return (
                                                                                    <components.MenuList  {...props}>
                                                                                        {props.children} 
                                                                                        <div className='d-flex justify-content-center'>
                                                                                            <button className='btn btn-success rounded btn-sm my-1' onClick={()=>{ setCurrencyModalShow(true) }}> &#10011; Add New Currency</button>
                                                                                        </div>
                                                                                    </components.MenuList >
                                                                                ) 
                                                                            } 
                                                                        }}
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="fs-5 fw-bold mb-2">Terms</label>
                                                                    <Select
                                                                        options={ termsList.map(x=>{return {label:x.term_name, value:x.no_of_days} }) }
                                                                        onChange={(val) => {setSelectedTerm(val)}}        
                                                                        value={selectedTerm}
                                                                        isClearable
                                                                        components={{ 
                                                                            MenuList: (props) => {
                                                                                return (
                                                                                    <components.MenuList  {...props}>
                                                                                        {props.children} 
                                                                                        <div className='d-flex justify-content-center'>
                                                                                            <button className='btn btn-success rounded btn-sm my-1' 
                                                                                                onClick={()=>{
                                                                                                    setTermsModalShow(true)
                                                                                                    setTermsPanelList(termsList.map(x=>{return {...x}}) ) 
                                                                                                }} 
                                                                                            >&#10011; Configure Terms</button>
                                                                                        </div>
                                                                                    </components.MenuList >
                                                                                ) 
                                                                            } 
                                                                        }}
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="row mb-5">
                                                                <div className="col-4 my-auto">
                                                                    <label className="fs-5 fw-bold mb-2">Enable Portal for Customer :</label>
                                                                </div>
                                                                <div className="col-2">
                                                                    <div className="form-check form-check-solid form-check-primary form-switch mt-2">   
                                                                        <input className="form-check-input w-45px h-30px" type="checkbox" checked={portalCheck} onChange={(e)=>{setPortalCheck(e.target.checked) }} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <Select
                                                                        options={["Arabic", "Catalan", "Chinese (Simplified)", "Croatian", "Danish", "Dutch", "English", "Finnish", "French", "German", "Greek", "Indonesian", "Italian", "Japanese", "Norwegian", "Polish", "Portuguese", "Portuguese (Brazilian)", "Russian", "Slovak", "Spanish", "Spanish (Argentine)", "Swedish"].map(x=>{return {label:x, value:x}})}
                                                                        value={selectedPortalLanguage}
                                                                        onChange={(val)=>{setSelectedPortalLanguage(val)}}
                                                                        placeholder="Select Customer Portal Language"
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    />
                                                                </div>
                                                                {portalCheck && <div className='fs-7'>(Email address is mandatory)</div>}
                                                            </div>

                                                            <div className="row mb-5">
                                                                <div className="col-md-6">
                                                                    <label className="fs-5 fw-bold mb-2">Facebook</label>
                                                                    <div className='input-group'>
                                                                        <div className="input-group-prepend">
                                                                            <span class="input-group-text h-100" >
                                                                                <i className="bi bi-facebook" style={{color:"blue"}}></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" className="form-control" value={facebookLink} onChange={(e)=>{setFacebookLink(e.target.value)}} placeholder={"http://www.facebook.com"} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="fs-5 fw-bold mb-2">Twitter</label>
                                                                    <div className='input-group'>
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text h-100" >
                                                                                <i className="bi bi-twitter" style={{color:"skyblue"}}></i>
                                                                            </span>
                                                                        </div>
                                                                        <input type="text" className="form-control" value={twitterLink} onChange={(e)=>{setTwitterLink(e.target.value)}} placeholder={"http://www.twitter.com"}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>

                                                    <Tab eventKey="address" title="Address" className='mt-10'>
                                                        <div className='container'>
                                                            <h3 className='mb-5'>Billing Address:</h3>
                                                            <div className="row mb-5">
                                                                <div className="col-md-12">
                                                                    <label className="fs-5 fw-bold mb-2">Attention</label>
                                                                    <input type="text" className="form-control form-control-solid" value={billingAttention} onChange={(e)=>{setBillingAttention(e.target.value)}} />
                                                                </div>
                                                            </div>

                                                            <div className="row mb-5">
                                                                <div className="col-md-6">
                                                                    <label className="fs-5 fw-bold mb-2">Country</label>
                                                                    <Select
                                                                        options={billingCountryOptions}
                                                                        value={billingSelectedCountry}
                                                                        onChange={(val) => {setBillingSelectedCountry(val); setBillingSelectedState(null); loadBillingStateOptions(val);}}
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    />
                                                                </div>
                                                                
                                                                <div className="col-md-6">
                                                                    <label className="fs-5 fw-bold mb-2">State / Province / Region</label>
                                                                    <Select
                                                                        options={billingStateOptions}
                                                                        value={billingSelectedState}
                                                                        onChange={(val) => {setBillingSelectedState(val)}}
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row mb-5">
                                                                <div className="col-md-12">
                                                                    <label className="fs-5 fw-bold mb-2">Address Line 1</label>
                                                                    <input type="text" className="form-control form-control-solid" value={billingAddress1} onChange={(e)=>{setBillingAddress1(e.target.value)}} />
                                                                </div>
                                                            </div>

                                                            <div className="row mb-5">
                                                                <div className="col-md-12">
                                                                    <label className="fs-5 fw-bold mb-2">Address Line 2</label>
                                                                    <input type="text" className="form-control form-control-solid" value={billingAddress2} onChange={(e)=>{setBillingAddress2(e.target.value)}} />
                                                                </div>
                                                            </div>

                                                            <div className="row mb-5">
                                                                <div className="col-md-6">
                                                                    <label className="fs-5 fw-bold mb-2">Town/City</label>
                                                                    <input type="text" className="form-control form-control-solid" value={billingCity} onChange={(e)=>{setBillingCity(e.target.value)}} />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="fs-5 fw-bold mb-2">Pin Code</label>
                                                                    <input type="text" className="form-control form-control-solid" value={billingPincode} onChange={(e)=>{setBillingPincode(e.target.value)}} />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row mb-5">
                                                                <div className="col-md-6">
                                                                    <label className="fs-5 fw-bold mb-2">Phone</label>
                                                                    <input type="text" className="form-control form-control-solid" value={billingPhone} onChange={(e)=>{setBillingPhone(e.target.value)}} />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="fs-5 fw-bold mb-2">Fax</label>
                                                                    <input type="text" className="form-control form-control-solid" value={billingFax} onChange={(e)=>{setBillingFax(e.target.value)}} />
                                                                </div>
                                                            </div>

                                                            <div className="row mb-5">
                                                                <div className="col-md-12">
                                                                    <label className="form-label">Delivery Instruction </label> 
                                                                    <textarea rows="3" className="form-control form-control-solid" value={billingDeliveryInstruction} onChange={(e)=>{setBillingDeliveryInstruction(e.target.value)}} placeholder='Please Enter your Delivery Instruction'></textarea>
                                                                </div>
                                                            </div>

                                                            <div className='d-flex my-5 align-items-center'>
                                                                <h3>Shipping Address:</h3>
                                                                <button className='btn btn-outline-info rounded ms-10' onClick={()=>{copyAddress()}} >Copy Billing Address Details to Shipping Address</button>
                                                            </div>
                                                                
                                                            <div className="row mb-5">
                                                                <div className="col-md-12">
                                                                    <label className="fs-5 fw-bold mb-2">Attention</label>
                                                                    <input type="text" className="form-control form-control-solid" value={shippingAttention} onChange={(e)=>{setShippingAttention(e.target.value)}} />
                                                                </div>
                                                            </div>

                                                            <div className="row mb-5">
                                                                <div className="col-md-6">
                                                                    <label className="fs-5 fw-bold mb-2">Country</label>
                                                                    <Select
                                                                        options={shippingCountryOptions}
                                                                        value={shippingSelectedCountry}
                                                                        onChange={(val) => {setShippingSelectedCountry(val); setShippingSelectedState(null); loadShippingStateOptions(val);}}
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    />
                                                                </div>
                                                                
                                                                <div className="col-md-6">
                                                                    <label className="fs-5 fw-bold mb-2">State / Province / Region</label>
                                                                    <Select
                                                                        options={shippingStateOptions}
                                                                        value={shippingSelectedState}
                                                                        onChange={(val) => {setShippingSelectedState(val)}}
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row mb-5">
                                                                <div className="col-md-12">
                                                                    <label className="fs-5 fw-bold mb-2">Address Line 1</label>
                                                                    <input type="text" className="form-control form-control-solid" value={shippingAddress1} onChange={(e)=>{setShippingAddress1(e.target.value)}} />
                                                                </div>
                                                            </div>

                                                            <div className="row mb-5">
                                                                <div className="col-md-12">
                                                                    <label className="fs-5 fw-bold mb-2">Address Line 2</label>
                                                                    <input type="text" className="form-control form-control-solid" value={shippingAddress2} onChange={(e)=>{setShippingAddress2(e.target.value)}} />
                                                                </div>
                                                            </div>

                                                            <div className="row mb-5">
                                                                <div className="col-md-6">
                                                                    <label className="fs-5 fw-bold mb-2">Town/City</label>
                                                                    <input type="text" className="form-control form-control-solid" value={shippingCity} onChange={(e)=>{setShippingCity(e.target.value)}} />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="fs-5 fw-bold mb-2">Pin Code</label>
                                                                    <input type="text" className="form-control form-control-solid" value={shippingPincode} onChange={(e)=>{setShippingPincode(e.target.value)}} />
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row mb-5">
                                                                <div className="col-md-6">
                                                                    <label className="fs-5 fw-bold mb-2">Phone</label>
                                                                    <input type="text" className="form-control form-control-solid" value={shippingPhone} onChange={(e)=>{setShippingPhone(e.target.value)}} />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label className="fs-5 fw-bold mb-2">Fax</label>
                                                                    <input type="text" className="form-control form-control-solid" value={shippingFax} onChange={(e)=>{setShippingFax(e.target.value)}} />
                                                                </div>
                                                            </div>

                                                            <div className="row mb-5">
                                                                <div className="col-md-12">
                                                                    <label className="form-label">Delivery Instruction </label> 
                                                                    <textarea rows="3" className="form-control form-control-solid" value={shippingDeliveryInstruction} onChange={(e)=>{setShippingDeliveryInstruction(e.target.value)}} placeholder='Please Enter your Delivery Instruction'></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>

                                                    <Tab eventKey="profile" title="Profile" className='mt-10'>
                                                        <div className='container'>
                                                            <div className='row mb-3'>
                                                                <div className='col-6 my-auto'>
                                                                    {
                                                                        oldProfilePhoto != null?
                                                                            <div className='text-center text-primary fw-bold fs-1'>
                                                                                Remove current profile photo to upload new image
                                                                            </div>
                                                                        :
                                                                            write == 1 &&
                                                                            <>
                                                                                <label className="fs-5 fw-bold mb-2">Upload Profile Photo (Single file upload)</label>
                                                                                <Dropzone
                                                                                    getUploadParams={getUploadParams1}
                                                                                    onChangeStatus={handleChangeStatus1}
                                                                                    LayoutComponent={CustomLayout}
                                                                                    PreviewComponent={Preview}
                                                                                    styles={{
                                                                                        dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                                                        inputLabelWithFiles: { margin: '20px 3%' }
                                                                                    }}
                                                                                    maxFiles={1}
                                                                                    maxSizeBytes={1024*1024*2}
                                                                                    accept="image/*"
                                                                                />
                                                                            </>
                                                                    }
                                                                </div>
                                                                <div className='col-6'>
                                                                    {
                                                                        oldProfilePhoto == null ? <div className='text-center pt-20 fs-4 text-gray-600'>No files uploaded previously</div>
                                                                        :
                                                                        <div className="text-center">
                                                                            <img className='d-block mt-10 mx-auto mb-2 rounded' style={{width:"40%"}} title={oldProfilePhoto.original_file_name} src={`${process.env.REACT_APP_GENERAL_BUCKET}/customer_profile_picture/`+oldProfilePhoto.file_name}></img>
                                                                            {
                                                                                write == 1 &&
                                                                                <button className='btn btn-secondary btn-sm rounded' 
                                                                                    onClick={()=>{
                                                                                        swal({
                                                                                            title: "Are you sure?",
                                                                                            text: "Once deleted, you will not be able to recover this file!",
                                                                                            icon: "warning",
                                                                                            buttons: true,
                                                                                            dangerMode: true,
                                                                                        })
                                                                                        .then((willDelete) => {
                                                                                            if (willDelete) {
                                                                                                documentDelete("customer_profile_picture", oldProfilePhoto.file_name, oldProfilePhoto.original_file_name, oldProfilePhoto.id)
                                                                                            }
                                                                                        })
                                                                                    }} 
                                                                                >&#10006;</button>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='row mb-3'>
                                                                <div className='col-6'>
                                                                    {
                                                                        oldFileNames.length == 3?
                                                                            <div className='text-center text-primary fw-bold fs-1'>
                                                                                Remove current documents to upload new documents
                                                                            </div>
                                                                        :
                                                                            write == 1 &&
                                                                            <>
                                                                                <label className="fs-5 fw-bold mb-2">Upload Identity Proof (Maximum 3 files)</label>
                                                                                <Dropzone
                                                                                    getUploadParams={getUploadParams2}
                                                                                    onChangeStatus={handleChangeStatus2}
                                                                                    LayoutComponent={CustomLayout}
                                                                                    PreviewComponent={Preview}
                                                                                    styles={{
                                                                                        dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                                                        inputLabelWithFiles: { margin: '20px 3%' }
                                                                                    }}
                                                                                    maxFiles={3 - oldFileNames.length}
                                                                                    maxSizeBytes={1024*1024*2}
                                                                                    accept="image/*,.pdf"
                                                                                />
                                                                            </>
                                                                    }
                                                                    
                                                                </div>
                                                                <div className='col-6 my-auto'>
                                                                    {
                                                                        oldFileNames.length == 0? <div className='text-center pt-20 fs-4 text-gray-600'>No documents uploaded previously</div>
                                                                        :
                                                                        <div className='row'>
                                                                            {
                                                                                oldFileNames.map((x)=>{
                                                                                    return (
                                                                                        <div className="col-4 text-center my-auto">
                                                                                            <div className='mb-2 border border-dark rounded fs-6' title={x.original_file_name}>{x.original_file_name.length>20?x.original_file_name.substring(0,10) +"..."+ x.original_file_name.substring(x.original_file_name.length-10):x.original_file_name}</div>
                                                                                            {
                                                                                                write == 1 &&
                                                                                                <button className='btn btn-secondary btn-sm btn-labeled rounded' 
                                                                                                    onClick={()=>{
                                                                                                        swal({
                                                                                                            title: "Are you sure?",
                                                                                                            text: "Once deleted, you will not be able to recover this file!",
                                                                                                            icon: "warning",
                                                                                                            buttons: true,
                                                                                                            dangerMode: true,
                                                                                                        })
                                                                                                        .then((willDelete) => {
                                                                                                            if (willDelete) {
                                                                                                                documentDelete("customer_documents", x.file_name, x.original_file_name, x.id)
                                                                                                            }
                                                                                                        })
                                                                                                        
                                                                                                    }} 
                                                                                                >&#10006;</button>
                                                                                            }
                                                                                        </div> 
                                                                                    )
                                                                                })
                                                                            }   
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>

                                                    <Tab eventKey="contactPersons" title="Contact Persons" className='mt-10'>
                                                        <div className='w-100' style={{overflowX:"scroll"}}>
                                                            <div className='container' style={{minWidth:"max-content"}}>
                                                                <div className="row">
                                                                    <div className="col">
                                                                        <label className="fs-5 fw-bold mb-2">Salutation</label>
                                                                    </div>
                                                                    <div className="col">
                                                                        <label className="fs-5 fw-bold mb-2">First Name</label>
                                                                    </div>
                                                                    <div className="col">
                                                                        <label className="fs-5 fw-bold mb-2">Last Name</label>
                                                                    </div>
                                                                    <div className="col">
                                                                        <label className="fs-5 fw-bold mb-2">Email Address</label>
                                                                    </div>
                                                                    <div className="col">
                                                                        <label className="fs-5 fw-bold mb-2">Work Phone</label>
                                                                    </div>
                                                                    <div className="col">
                                                                        <label className="fs-5 fw-bold mb-2">Mobile</label>
                                                                    </div>
                                                                    <div className="col">
                                                                        <label className="fs-5 fw-bold mb-2">Designation</label>
                                                                    </div>
                                                                    <div className="col">
                                                                        <label className="fs-5 fw-bold mb-2">Department</label>
                                                                    </div>
                                                                    {
                                                                        contactPersonsArray.length>1 && <div className='col'></div> 
                                                                    }
                                                                </div>
                                                                {
                                                                    contactPersonsArray.map((obj,indx)=>{
                                                                        return (
                                                                            <div className="row mb-1">
                                                                                <div className="col border border-dark rounded my-auto px-0 py-1">
                                                                                    <Select
                                                                                        options={["Mr.","Mrs.","Ms.","Miss.","Dr."].map(x=>{return {label:x, value:x} })}
                                                                                        value={obj.salutation}
                                                                                        onChange={(val)=>{ 
                                                                                            let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                            temp[indx].salutation = val
                                                                                            setContactPersonsArray(temp)
                                                                                        }}
                                                                                        placeholder=""
                                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                    />
                                                                                </div>
                                                                                <div className="col border border-dark rounded px-1">
                                                                                    <input type="text" className="form-control form-control-solid" value={obj.firstName} 
                                                                                        onChange={(e)=>{
                                                                                            let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                            temp[indx].firstName = e.target.value
                                                                                            setContactPersonsArray(temp)
                                                                                        }} 
                                                                                    />
                                                                                </div>
                                                                                <div className="col border border-dark rounded px-1">
                                                                                    <input type="text" className="form-control form-control-solid" value={obj.lastName} 
                                                                                        onChange={(e)=>{
                                                                                            let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                            temp[indx].lastName = e.target.value
                                                                                            setContactPersonsArray(temp)
                                                                                        }} 
                                                                                    />
                                                                                </div>
                                                                                <div className="col border border-dark rounded px-1">
                                                                                    <input type="email" className="form-control form-control-solid" value={obj.email} 
                                                                                        onChange={(e)=>{
                                                                                            let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                            temp[indx].email = e.target.value
                                                                                            setContactPersonsArray(temp)
                                                                                        }} 
                                                                                    />
                                                                                </div>
                                                                                <div className="col border border-dark rounded px-1">
                                                                                    <input type="text" className="form-control form-control-solid" value={obj.workPhone} 
                                                                                        onChange={(e)=>{
                                                                                            let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                            temp[indx].workPhone = e.target.value
                                                                                            setContactPersonsArray(temp)
                                                                                        }} 
                                                                                    />
                                                                                </div>
                                                                                <div className="col border border-dark rounded px-1">
                                                                                    <input type="text" className="form-control form-control-solid" value={obj.mobile} 
                                                                                        onChange={(e)=>{
                                                                                            let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                            temp[indx].mobile = e.target.value
                                                                                            setContactPersonsArray(temp)
                                                                                        }} 
                                                                                    />
                                                                                </div>
                                                                                <div className="col border border-dark rounded px-1">
                                                                                    <input type="text" className="form-control form-control-solid" value={obj.designation} 
                                                                                        onChange={(e)=>{
                                                                                            let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                            temp[indx].designation = e.target.value
                                                                                            setContactPersonsArray(temp)
                                                                                        }} 
                                                                                    />
                                                                                </div>
                                                                                <div className="col border border-dark rounded px-1">
                                                                                    <input type="text" className="form-control form-control-solid" value={obj.department} 
                                                                                        onChange={(e)=>{
                                                                                            let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                            temp[indx].department = e.target.value
                                                                                            setContactPersonsArray(temp)
                                                                                        }} 
                                                                                    />
                                                                                </div>
                                                                                {
                                                                                    contactPersonsArray.length>1 &&
                                                                                    <div className="col my-auto">
                                                                                        <button className='btn btn-sm btn-outline-danger rounded-pill fs-7 border border-danger' 
                                                                                            onClick={()=>{
                                                                                                let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                                temp.splice(indx,1)
                                                                                                setContactPersonsArray(temp)
                                                                                            }}
                                                                                        >&#10006;</button>
                                                                                    </div>
                                                                                }
                                                                            </div>  
                                                                        )
                                                                    })
                                                                }
                                                                <div className='mt-10'>
                                                                    <button className='btn btn-primary rounded' onClick={()=>{ setContactPersonsArray([...contactPersonsArray, {salutation:null, firstName:"", lastName:"", email:"", workPhone:"", mobile:"", designation:"", department:""} ]) }}>+ Add Contact Person</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>

                                                    <Tab eventKey="customFields" title="Custom Fields" className='mt-10'>
                                                        <div className='container'>
                                                            This Feature is in development
                                                        </div>
                                                    </Tab>

                                                    <Tab eventKey="remarks" title="Remarks" className='mt-10'>
                                                        <div className='container'>
                                                            <div className="row mb-5">
                                                                <div className="col-md-12">
                                                                    <label className="fs-5 fw-bold mb-2">Remarks (For Internal Use)</label>
                                                                    <textarea className="form-control form-control-solid" rows={5} value={remarks} onChange={(e)=>{setRemarks(e.target.value)}} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Tab>

                                                </Tabs>
                                            </div>


                                            <div style={{marginTop:"35px"}}>                           
                                                {
                                                    write==1 ?
                                                        <div className='d-flex justify-content-center'>
                                                            <button className="btn btn-primary btn-lg rounded" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => {register(e)}}>
                                                                <span className="indicator-label">Submit &#x2794;</span>
                                                                <span className="indicator-progress">Please wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    :
                                                        <div className='d-flex justify-content-center'>
                                                            <button className="btn btn-primary btn-lg rounded" disabled>
                                                                <span className="indicator-label">Submit &#x2794;</span>
                                                                <span className="indicator-progress">Please wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                            </button>
                                                        </div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </>
                            :
                            state == 2?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                            :
                            state == 3?
                            <>
                                <div className="flex-lg-row-fluid me-lg-15">
                                    <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                        <div className="card-body pt-0">
                                            <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                            <div className="d-flex align-items-center p-3 mb-2"></div>

                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                <div className="d-flex flex-stack flex-grow-1">
                                                    <div className="fw-bold">
                                                        <h4 className="text-gray-800 fw-bolder">No Such Customers</h4>
                                                        <div className="fs-6 text-gray-600"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="flex-lg-row-fluid me-lg-15">
                                    <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                        <div className="card-body pt-0">
                                            <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                            <div className="d-flex align-items-center p-3 mb-2"></div>

                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                <div className="d-flex flex-stack flex-grow-1">
                                                    <div className="fw-bold">
                                                        <h4 className="text-gray-800 fw-bolder">Something went wrong. Please refresh this page</h4>
                                                        <div className="fs-6 text-gray-600"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>

            </div>

            <Modal show={currencyModalShow} onHide={()=>{handleNewCurrencyModalClose()}} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>New Currency</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{handleNewCurrencyModalClose()}}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-5 fw-bold mb-2 required">Currency Code</label>
                                <Select
                                    options={Object.keys(currency_arr.currency_arr).map(x=>{return {label: x,value: x}})}
                                    onChange={(e) => { setSelectedCurrencyCode(e); setCurrencySymbol(currency_arr.currency_arr[e.value].currency_symbol); }}
                                    value={selectedCurrencyCode}
                                    placeholder="--Choose a Currency--"
                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-6 fw-bold mb-2 required">Currency symbol</label>
                                <input type="text" className="form-control form-control-solid" value={currencySymbol} onChange={(e)=>{setCurrencySymbol(e.target.value)}}/>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-5 fw-bold mb-2">Decimal Places</label>
                                <Select
                                    options={[0,2,3].map(x=>{return {label:x,value:x}})}
                                    onChange={(e) => { setSelectedCurrencyDecimalPlace(e) }}
                                    value={selectedCurrencyDecimalPlace}
                                    placeholder="--Choose Decimal Places--"
                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-6 fw-bold mb-2">Format</label>
                                <Select
                                    options={["1,234,567.89", "1.234.567,89", "1 234 567,89" ].map(x=>{return {label:x,value:x}})}
                                    onChange={(e) => { setSelectedCurrencyFormat(e) }}
                                    value={selectedCurrencyFormat}
                                    placeholder="--Choose a Format--"
                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    {
                        write == 1 &&
                        <button className="btn btn-success rounded me-auto" data-kt-indicator={currencySubmitButtonState?"on":"off"} disabled={currencySubmitButtonState} onClick={()=>{newCurrencySubmit()}}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                    <button className="btn btn-danger rounded" onClick={()=>{handleNewCurrencyModalClose()}}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={termsModalShow} onHide={()=>{handleNewTermsModalClose()}} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Configure Payment Terms</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{handleNewTermsModalClose()}}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row">
                            <div className='col-5 text-center'>
                                <label className="fs-5 fw-bold my-2 required">Term Name</label>
                            </div>
                            <div className='col-5 text-center'>
                                <label className="fs-5 fw-bold my-2 required">Number of Days</label>
                            </div>
                        </div>
                        {
                            termsPanelList.length > 0 ?
                            termsPanelList.map((x,indx)=>{
                                return (
                                    <div className="row mb-2">
                                        <div className='col-5'>
                                            <input type="text" className="form-control form-control-solid" value={x.term_name} 
                                                onChange={(e)=>{
                                                    let temp = termsPanelList.map(x=>{return {...x}})
                                                    temp[indx].term_name = e.target.value 
                                                    setTermsPanelList(temp)
                                                }}
                                            />
                                        </div>
                                        <div className='col-5'>
                                            <input type="number" min="0" className="form-control form-control-solid" value={x.no_of_days} 
                                                onChange={(e)=>{
                                                    if(e.target.value>=0){
                                                        let temp = termsPanelList.map(x=>{return {...x}})
                                                        temp[indx].no_of_days = e.target.value 
                                                        setTermsPanelList(temp)
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className='col-2 my-auto'>
                                            <button className='btn btn-sm btn-outline-danger rounded-pill border border-danger' 
                                                onClick={()=>{
                                                    let temp = termsPanelList.map(a=>{return{...a}})
                                                    temp.splice(indx,1)
                                                    setTermsPanelList(temp)
                                                }}
                                            >&#10006;</button>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <p className='text-center'>No terms available. Create a new term</p>
                        }
                        <div className="row">
                            <div className='col-6'>
                                <button className='btn btn-link' onClick={()=>{setTermsPanelList([...termsPanelList, {term_name: "", no_of_days: ""}]) }} >Add a term</button>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    {
                        write == 1 &&
                        <button className="btn btn-success rounded me-auto" data-kt-indicator={termsSubmitButtonState?"on":"off"} disabled={termsSubmitButtonState} onClick={()=>{newTermsSubmit()}}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                    <button className="btn btn-danger rounded" onClick={()=>{handleNewTermsModalClose()}}>Close</button>
                </Modal.Footer>
            </Modal>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org}/>
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer}/>

        </>
    );
}
export default EditCustomerDetails;