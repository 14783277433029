import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import moment from 'moment';

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

// import ReactDOM from 'react-dom';
// import { date } from 'yup';
 

function ViewCustomerDetails(props) {
    const [user, setUser] = useState({});
    const [organization_id, setOrganization_id] = useState("")
    const [organization_list, setorganization_list] = useState([]);
    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);
    const [state, setstate] = useState(2);

    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
	const [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);

    const [customerDetails, setCustomerDetails] = useState(null)
    const [customerInvoiceDetails, setCustomerInvoiceDetails] = useState([])
    const [customerPaymentRecords, setCustomerPaymentRecords] = useState([])
    const [customerInvoiceDetailsApiState, setCustomerInvoiceDetailsApiState] = useState("done")

    const [customerId, setCustomerId] = useState("")
    const [customerName, setCustomerName] = useState("")
    const [customerDesignation, setCustomerDesignation] = useState("")
    const [customerCompanyName, setCustomerCompanyName] = useState("")
    const [customerWorkPhone, setCustomerWorkPhone] = useState("")
    const [customerProfilePic, setCustomerProfilePic] = useState("")
    const [customerBillingAddress, setCustomerBillingAddress] = useState("")

    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    var page_name = "view-customer-details";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };


    

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true);
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    setRedirect_to_login(1);
                });
                
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if(temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else
                                    {
                                        temp = 2;
                                    }
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
							
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setRedirect_to_login(1);
                });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }

                // console.log(timer)
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);





    async function getCustomerDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/customer-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "cid": props.match.params.id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        let body = await response.json();
        // console.log(body)
        if (body.operation == "danger") {
            throw body.message
        }
        else{
            setCustomerDetails(body)
        }
    }
    async function getCustomerPaymentRecords() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-customer-payment-records`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "cid": props.match.params.id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        let body = await response.json();
        // console.log(body)
        if (body.operation == "danger") {
            throw body.message
        }
        else{
            setCustomerPaymentRecords(body)
        }
    }
    async function getCustomerInvoiceDetails() {
        setCustomerInvoiceDetailsApiState("processing")
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-customer-invoices`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "cid": props.match.params.id, "startDate": startDate, "endDate": endDate }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        let body = await response.json();
        setCustomerInvoiceDetails(body)
        setCustomerInvoiceDetailsApiState("done")
    }
    

    useEffect(() => {
		if ((read || write) && (user.email && visibility)) {
			let p1 = getCustomerDetails();
			let p2 = getCustomerPaymentRecords();
            Promise.all([p1,p2])
            .then(()=>{
                setstate(1)
                console.log("all apis done")
            })
            .catch((err)=>{
                console.log(err)
                if(err == "no such customer"){
                    setstate(3)
                }
                else{
                    setstate(4)
                }
            })
		}

	}, [user, visibility, read, write])

    useEffect(() => {
        if(customerDetails!=null){
            setCustomerId(customerDetails.basic[0].cid)
            setCustomerName(customerDetails.basic[0].customer_display_name)
            setCustomerDesignation(customerDetails.basic[0].designation)
            setCustomerCompanyName(customerDetails.basic[0].company_name)
            setCustomerWorkPhone(customerDetails.basic[0].work_phone)

            let t = customerDetails.document.find(x=>x.file_name.startsWith("profile"))
            t==undefined? setCustomerProfilePic("/assets/media/avatars/blank.png") : setCustomerProfilePic(process.env.REACT_APP_GENERAL_BUCKET+"/customer_profile_picture/"+t.file_name)

            let badd = customerDetails.address.find(x=>x.address_type=="billing")
            setCustomerBillingAddress(badd.address_line1)
        }
    }, [customerDetails])

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }} />
                </> : null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}

            <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                            <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Customer Details</h1>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            state == 1 ?
                            <div className="d-flex flex-column flex-xl-row">
                                <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
                                    <div className="card mb-5 mb-xl-8">
                                        <div className="card-body pt-15">
                                            <div className="d-flex flex-center flex-column mb-5">
                                                <div className="symbol symbol-100px symbol-circle mb-7">
                                                    <img src={customerProfilePic} alt="profile_image" />
                                                </div>
                                                <a href="#" className="fs-3 text-gray-800 text-hover-primary fw-bold mb-1">{customerName}</a>
                                                <div className="fs-5 fw-semibold text-muted mb-6">{customerDesignation}</div>
                                            </div>
                                            <div className="d-flex flex-stack fs-4 py-3">
                                                <div className="fw-bold rotate collapsible" data-bs-toggle="collapse" href="#kt_customer_view_details" role="button" aria-expanded="false" aria-controls="kt_customer_view_details">Details
                                                    <span className="ms-2 rotate-180">
                                                        <span className="svg-icon svg-icon-3">
                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor" />
                                                            </svg>
                                                        </span>
                                                    </span></div>
                                                <span title='Edit Customer Details'>
                                                    <Link className="btn btn-sm btn-light-primary" to={`/edit-customer-details/${props.match.params.id}`}>Edit</Link>
                                                </span>
                                            </div>
                                            <div className="separator separator-dashed my-3"></div>
                                            <div id="kt_customer_view_details" className="collapse show">
                                                <div className="py-5 fs-6">
                                                    <div className="badge badge-light-info d-inline">Premium user</div>
                                                    <div className="fw-bold mt-5">Customer ID</div>
                                                    <div className="text-gray-600">{customerId}</div>
                                                    <div className="fw-bold mt-5">Company Name</div>
                                                    <div className="text-gray-600">{customerCompanyName}</div>
                                                    <div className="fw-bold mt-5">Work Phone</div>
                                                    <div className="text-gray-600">{customerWorkPhone}</div>
                                                    <div className="fw-bold mt-5">Billing Address</div>
                                                    <div className="text-gray-600">{customerBillingAddress}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-lg-row-fluid ms-lg-15">
                                    <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8">
                                        <li className="nav-item">
                                            <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#kt_customer_view_payment_records_tab">Payment Records</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#kt_customer_view_invoices_tab">Invoices</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content" id="myTabContent">
                                        <div className="tab-pane fade show active" id="kt_customer_view_payment_records_tab" role="tabpanel">
                                            <div className="card pt-4 mb-6 mb-xl-9">
                                                <div className="card-header border-0">
                                                    <div className="card-title">
                                                        <h2>Payment Records</h2>
                                                    </div>
                                                </div>
                                                <div className="card-body pt-0 pb-5">
                                                    <div className='table-responsive'>
                                                        <table className="table align-middle table-row-dashed gy-5" id="kt_table_customers_payment">
                                                            <thead className="border-bottom border-gray-200 fs-7 fw-bold">
                                                                <tr className="text-start text-muted text-uppercase gs-0">
                                                                    <th className="min-w-100px">Invoice No.</th>
                                                                    <th>Status</th>
                                                                    <th>Amount</th>
                                                                    <th className="min-w-100px">Date</th>
                                                                    <th className="text-end min-w-100px pe-4">Actions</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="fs-6 fw-semibold text-gray-600">
                                                                {
                                                                    customerPaymentRecords.length == 0 ? 
                                                                    <tr>
                                                                        <td className='text-center' colSpan={5}>
                                                                            <div className='my-20'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="150" height="150" viewBox="0 0 647.63626 632.17383">
                                                                                    <path d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z" transform="translate(-276.18187 -133.91309)" fill="#f2f2f2"/>
                                                                                    <path d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z" transform="translate(-276.18187 -133.91309)" fill="#3f3d56"/>
                                                                                    <path d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z" transform="translate(-276.18187 -133.91309)" fill="#183060"/>
                                                                                    <circle cx="190.15351" cy="24.95465" r="20" fill="#183060"/>
                                                                                    <circle cx="190.15351" cy="24.95465" r="12.66462" fill="#fff"/>
                                                                                    <path d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z" transform="translate(-276.18187 -133.91309)" fill="#e6e6e6"/>
                                                                                    <path d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z" transform="translate(-276.18187 -133.91309)" fill="#3f3d56"/>
                                                                                    <path d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z" transform="translate(-276.18187 -133.91309)" fill="#183060"/>
                                                                                    <circle cx="433.63626" cy="105.17383" r="20" fill="#183060"/>
                                                                                    <circle cx="433.63626" cy="105.17383" r="12.18187" fill="#fff"/>
                                                                                </svg>
                                                                                <div className='fs-3 mt-2'>No data available</div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    :
                                                                    customerPaymentRecords.map(obj=>{
                                                                        let t = JSON.parse(obj.invoice_currency)
                                                                        let t2
                                                                        if (obj.add_status == "DRAFT") {
                                                                            t2 = "badge-secondary"
                                                                        }
                                                                        else if (obj.add_status == "INVOICED") {
                                                                            t2 = "badge-light-primary"
                                                                        }
                                                                        else if (obj.add_status == "PAID") {
                                                                            t2 = "badge-light-success"
                                                                        }
                                                                        else if ((obj.add_status == "VOID")||(obj.add_status == "UNCOLLECTIBLE")) { 
                                                                            t2 = "badge-light-dark"
                                                                        }
                                                                        return(
                                                                            <tr>
                                                                                <td><span className="text-gray-600 text-hover-primary mb-1">{obj.invoice_name}</span></td>
                                                                                <td><span className={`badge ${t2}`}>{obj.add_status}</span></td>
                                                                                <td>{t.currency_symbol+" "+obj.grand_total}</td>
                                                                                <td>{moment(obj.invoice_date).format('YYYY-MM-DD')}</td>
                                                                                <td className="pe-0 text-end"><Link className="btn btn-sm btn-light btn-active-light-primary" to={`/view-invoice-details/${obj.invoice_id}`}>View</Link></td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="kt_customer_view_invoices_tab" role="tabpanel">
                                            <div className="card pt-2 mb-6 mb-xl-9">
                                                <div className="card-header border-0">
                                                    <div className="card-title">
                                                        <h2>Invoices</h2>
                                                    </div>
                                                    <div className="card-toolbar m-0">
                                                        <div className='d-flex'>
                                                            <div className='d-flex'>
                                                                <input className='form-control' type="date" value={startDate} onChange={(e)=>{setStartDate(e.target.value)}} min={endDate!="" && moment(endDate).subtract(30,'days').format('YYYY-MM-DD')} max={endDate!=""?endDate:moment().format('YYYY-MM-DD')} />
                                                                <div className='my-auto mx-2'>-</div>
                                                                <input className='form-control' type="date" value={endDate} onChange={(e)=>{setEndDate(e.target.value)}} min={startDate!="" && startDate} max={(startDate!="" && moment(startDate).add(30,'days').format('YYYY-MM-DD')<moment().format('YYYY-MM-DD'))?moment(startDate).add(30,'days').format('YYYY-MM-DD'):moment().format('YYYY-MM-DD')} />
                                                            </div>
                                                            <button className="btn btn-danger rounded-pill ms-10" disabled={customerInvoiceDetailsApiState=="processing"?true:false} onClick={()=>{getCustomerInvoiceDetails()}}>{customerInvoiceDetailsApiState=="processing"?<><span className="spinner-border spinner-border-sm me-3"></span>Please wait...</>:"Submit"}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body pt-0">
                                                    <div id="kt_referred_users_tab_content" className="tab-content">
                                                        <div id="kt_customer_details_invoices_1" className="py-0 tab-pane fade show active" role="tabpanel">
                                                            <div className='table-responsive'>
                                                                <table id="kt_customer_details_invoices_table_1" className="table align-middle table-row-dashed fs-6 fw-bold gy-5">
                                                                    <thead className="border-bottom border-gray-200 fs-7 text-uppercase fw-bold">
                                                                        <tr className="text-start text-muted gs-0">
                                                                            <th className="min-w-100px">Invoice</th>
                                                                            <th className="min-w-100px">Order ID</th>
                                                                            <th className="min-w-100px">Amount</th>
                                                                            <th className="min-w-100px">Status</th>
                                                                            <th className="min-w-125px text-end pe-7">Date</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="fs-6 fw-semibold text-gray-600">
                                                                        {
                                                                            customerInvoiceDetails.length == 0? 
                                                                                <tr><td className='text-center' colSpan={5}><div className='fs-1 my-10'>Select date range to see invoices</div></td></tr>
                                                                            :
                                                                            customerInvoiceDetails.map(obj=>{
                                                                                let t = JSON.parse(obj.invoice_currency)
                                                                                let t2
                                                                                if (obj.add_status == "DRAFT") {
                                                                                    t2 = "badge-secondary"
                                                                                }
                                                                                else if (obj.add_status == "INVOICED") {
                                                                                    t2 = "badge-light-primary"
                                                                                }
                                                                                else if (obj.add_status == "PAID") {
                                                                                    t2 = "badge-light-success"
                                                                                }
                                                                                else if ((obj.add_status == "VOID")||(obj.add_status == "UNCOLLECTIBLE")) { 
                                                                                    t2 = "badge-light-dark"
                                                                                }
                                                                                return(
                                                                                    <tr>
                                                                                        <td><span className="text-gray-600 text-hover-primary">{obj.invoice_name}</span></td>
                                                                                        <td><span className="text-gray-600 text-hover-primary">{obj.order_number==""?"---":obj.order_number}</span></td>
                                                                                        <td className="text-dark">{t.currency_symbol+" "+obj.grand_total}</td>
                                                                                        <td><span className={`badge ${t2}`}>{obj.add_status}</span></td>
                                                                                        <td className='text-end'>{moment(obj.invoice_date).format('YYYY-MM-DD')}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            :state == 2 ?
                            <div className="card">
                                <div className="card-body pt-6">
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                </div>
                            </div>
                            :state == 3 ?
                            <div className="flex-lg-row-fluid me-lg-15">
                                <div className="card">
                                    <div className="card-body py-10">
                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                            <div className="d-flex flex-stack flex-grow-1">
                                                <div className="fw-bold">
                                                    <h4 className="text-gray-800 fw-bolder">No such Customer</h4>
                                                    <div className="fs-6 text-gray-600"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="flex-lg-row-fluid me-lg-15">
                                <div className="card">
                                    <div className="card-body py-10">
                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                            <div className="d-flex flex-stack flex-grow-1">
                                                <div className="fw-bold">
                                                    <h4 className="text-gray-800 fw-bolder">Something went wrong. Please Refresh this page</h4>
                                                    <div className="fs-6 text-gray-600"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>


            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org}/>
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer}/>
        </>
    );
}

export default ViewCustomerDetails;
