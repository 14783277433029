import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import './support.css'
//import './previewbox.css'
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import { v1 as uuid } from 'uuid'

import { Link, Redirect } from 'react-router-dom'

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import removeImg from './icons/remove.svg'

const loaderstyle = {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "auto",
    marginBottom: "auto",
};

export const Support = () => {
    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    const [accessToken, setaccessToken] = useState('');
    const [loading, setloading] = useState(false);
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [id, setId] = useState("");
    let [status, setstatus] = useState("");
    let [add_date, setadd_date] = useState("");
    let timer_flag = 0;

    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const maxfileSize = 5 * 1024 * 1024;

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);
    //const [organization_id, setOrganization_id] = useState("");
    let [fileNames, setFileNames] = useState(["", ""])
    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);
	var page_name = "support";

    setFileNames = (name) => {
        fileNames.push(name)
        let uniq = fileNames => [...new Set(fileNames)];
        fileNames = fileNames.slice(-2)
    }

    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    // const id = uniqid();

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    });

                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
                        else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
									else
									{
										temp = 2;
									}
                                }

                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            res.data.audience_count = (res.data.audience_count)
                            if (res.data.audience_count > 0) {
                                const timer_decrease = setInterval(() => {
                                    res.data.audience_count = (res.data.audience_count - 1);
                                    //console.log(res.data.audience_count);
                                    if (res.data.audience_count > 0 && res.data.audience_count < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                                        if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                                            setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                                            window.$('#kt_modal_timer').modal('show');
                                        }
                                    }
                                }, 1000);

                                return () => clearInterval(timer_decrease);
                            }
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    });

            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
            }
        }
        validateJWT();
        loadScripts();
        setId(uuid());
    }, [])


    useEffect(() => {

    }, [timer]);

    function loadScripts() {
        var se = document.createElement('script');
        se.src = "assets/js/custom/pages/company/support.js";
        document.body.appendChild(se);
    }

    const change_subject = (e) => {
        setSubject(e.target.value);
    }
    const change_message = (e) => {
        setMessage(e.target.value);
    }

    async function save_data() {

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/send-us-query`, { method: 'POST', body: JSON.stringify({ "id": id, "email": user.email, 'subject': subject, 'message': message, 'filenames': fileNames,'page_name':page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        // console.log(body)
        // setloading(true);

        if (body.operation == 'success') {
            swal("", "Your query is successfully sent!", "success").then((res) => {
                window.location.href = "/"
            });
        }
        else {
            swal("Oops!", "Seems like we couldn't send the query", "error").then((res) => {
                window.location.href = "/"
            });
        }

        setSubject('');
        setMessage('');
    }


    var files = [];
    var sendButton = document.getElementById("kt_contact_submit_button");
    const CustomLayout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
        // Remove previews which do not pass the validation
        const previewsToDisplay = previews.filter((preview) => {
            return preview.props.meta.status !== 'error_validation';
        });

        return (

            <div {...dropzoneProps}>
                {previewsToDisplay}
                {files.length < maxFiles && input}
            </div>

        );
    };

    const handleChangeStatus2 = ({ meta }, status) => {
        // console.log('handleChangeStatus2');
        const { name } = meta;
        // console.log(name)
        let updatedFiles = [];
        if (status === 'done' || status === 'removed') {
            // Add meta to files
            if (status === 'done') {
                updatedFiles = [...files, meta];
                sendButton.setAttribute("data-kt-indicator", "off");
                sendButton.disabled = false;
            }
            // Remove meta from files
            else if (status === 'removed') {
                sendButton.setAttribute("data-kt-indicator", "off");
                sendButton.disabled = false;
                fileNames = fileNames.filter(item => item !== String(name))
                if (fileNames.length === 0) {
                    fileNames.push("")
                    fileNames.push("")
                }
            }
            else {
                updatedFiles = files.filter(files, (file) => {
                    return file.name !== meta.name
                        || file.size !== meta.size
                        || file.type !== meta.type;
                });

            }
            files = updatedFiles;
        }
    };

    const handleSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove());
        files = [];
    };
    const handleValidation2 = ({ meta }) => {
        return files.find(e => e.name === meta.name && e.size === meta.size && e.type === meta.type);
    }


    const icon = {
        remove: { backgroundImage: `url(${removeImg})` },
    }

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        setFileNames(String(name))
        return (

            <div className="preview-box">
                <img src={previewUrl} />
                <span className="name">{name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>
                {status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
                {status === "done" && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
            </div>
        )
    }

    const getUploadParams = ({ file }) => {
        //console.log(file);
        const body = new FormData();
        body.append('dataFiles', file);
        body.append('id', id);
        body.append('user_id', user.email);
        body.append('page_name', page_name);
        //body.append('organization_id', organization_id);
        // console.log(body);
        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        sendButton.setAttribute("data-kt-indicator", "on");
        sendButton.disabled = true;
        return { url: `${process.env.REACT_APP_SERVER_URL}/uploaddocuments`, headers, body }
    }

    // handle the status of the file upload
    const handleChangeStatus = ({ xhr }) => {
        //console.log('handleChangeStatus');
        if (xhr) {
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    const result = JSON.parse(xhr.response);
                    // console.log(result);
                }
            }
        }
    }

    return (
        <>
            {
                (redirect == 1 ||(read == 0 && write == 0)) ? 
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div class="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" class="container-fluid d-flex flex-stack">
                        <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" class="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                            <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Support</h1>
                            <span class="h-20px border-gray-200 border-start mx-4"></span>
                            <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                <li class="breadcrumb-item text-muted">
                                    <Link to="/" class="text-muted text-hover-primary">Home</Link>
                                </li>
                                <li class="breadcrumb-item">
                                    <span class="bullet bg-gray-200 w-5px h-2px"></span>
                                </li>
                                <li class="breadcrumb-item text-muted">Support</li>
                                <li class="breadcrumb-item">
                                    <span class="bullet bg-gray-200 w-5px h-2px"></span>
                                </li>
                                <li class="breadcrumb-item text-dark">Send us Query</li>

                            </ul>
                        </div>

                    </div>
                </div>
                <div class="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" class="container">
                        <div class="card">
                            <div class="card-body p-lg-17">
                                <div class="row mb-3">
                                    <div class="col-md-12 pe-lg-10">
                                        <form class="form mb-15" id="kt_contact_form">
                                            <h1 class="fw-bolder text-dark mb-9">Send Us Query</h1>
                                            <div class="d-flex flex-column mb-5 fv-row">
                                                <label class="fs-5 fw-bold mb-2">Subject</label>
                                                <input class="form-control form-control-solid" placeholder="" name="subject" value={subject} onChange={change_subject} autoComplete="off" />
                                            </div>

                                            <div class="d-flex flex-column mb-10 fv-row">
                                                <label class="fs-6 fw-bold mb-2">Message</label>
                                                <textarea class="form-control form-control-solid" rows="6" name="message" placeholder="" value={message} onChange={change_message} autoComplete="off"></textarea>
                                            </div>

                                            <div>
                                                <label class="fs-5 fw-bold mb-2">Maximum 2 files</label>
                                                <Dropzone
                                                    getUploadParams={getUploadParams}
                                                    onChangeStatus={handleChangeStatus, handleChangeStatus2}
                                                    onSubmit={handleSubmit}
                                                    validate={handleValidation2}
                                                    LayoutComponent={CustomLayout}
                                                    styles={{
                                                        dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                        inputLabelWithFiles: { margin: '20px 3%' }
                                                    }}
                                                    maxFiles={2}
                                                    // canRemove={false}
                                                    PreviewComponent={Preview}
                                                    accept="image/*,.pdf"
                                                />
                                            </div>
                                            {write==1 ?
                                            <button style={{ marginTop: '10px' }} onClick={save_data} type="button" class="btn btn-primary rounded" id="kt_contact_submit_button">
                                                <span class="indicator-label">Send Query</span>
                                                <span class="indicator-progress">Please wait...
                                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>
                                            :
                                            <button style={{ marginTop: '10px' }} onClick={save_data} type="button" class="btn btn-primary rounded" id="kt_contact_submit_button" disabled>
                                                <span class="indicator-label">Send Query</span>
                                                <span class="indicator-progress">Please wait...
                                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>
                                            }
                                        </form>
                                    </div>

                                </div>
                                <div class="row g-5 mb-5 mb-lg-15">
                                    <div class="col-sm-6 pe-lg-10">
                                        <div class="text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-lg-100">
                                            <span class="svg-icon svg-icon-3tx svg-icon-primary">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <g clip-path="url(#clip0)">
                                                        <path opacity="0.25" d="M21.3902 19.5804L19.4852 21.4853C19.4852 21.4853 14.5355 23.6066 7.46441 16.5356C0.39334 9.46451 2.51466 4.51476 2.51466 4.51476L4.41959 2.60983C5.28021 1.74921 6.70355 1.85036 7.43381 2.82404L9.25208 5.24841C9.84926 6.04465 9.77008 7.15884 9.06629 7.86262L7.46441 9.46451C7.46441 9.46451 7.46441 10.8787 10.2928 13.7071C13.1213 16.5356 14.5355 16.5356 14.5355 16.5356L16.1374 14.9337C16.8411 14.2299 17.9553 14.1507 18.7516 14.7479L21.1759 16.5662C22.1496 17.2964 22.2508 18.7198 21.3902 19.5804Z" fill="#191213" />
                                                        <path d="M4.41959 2.60987L3.92887 3.10059L8.17151 8.75745L9.06629 7.86267C9.77007 7.15888 9.84926 6.0447 9.25208 5.24846L7.4338 2.82409C6.70354 1.85041 5.28021 1.74926 4.41959 2.60987Z" fill="#121319" />
                                                        <path d="M21.3901 19.5804L20.8994 20.0711L15.2426 15.8285L16.1373 14.9337C16.8411 14.2299 17.9553 14.1507 18.7515 14.7479L21.1759 16.5662C22.1496 17.2965 22.2507 18.7198 21.3901 19.5804Z" fill="#121319" />
                                                    </g>
                                                </svg>
                                            </span>
                                            <h1 class="text-dark fw-bolder my-5">Let’s Speak</h1>
                                            <div class="text-gray-700 fw-bold fs-2">1 (833) 597-7538</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6 ps-lg-10">
                                        <div class="text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 h-lg-100">
                                            <span class="svg-icon svg-icon-3tx svg-icon-primary">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path opacity="0.25" d="M21 10C21 15.4917 16.1746 20.1791 13.5904 22.2957C12.6534 23.0631 11.3466 23.0631 10.4096 22.2957C7.82537 20.1791 3 15.4917 3 10C3 5.02944 7.02944 1 12 1C16.9706 1 21 5.02944 21 10Z" fill="#191213" />
                                                    <path d="M15 9C15 10.6569 13.6569 12 12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9Z" fill="#121319" />
                                                </svg>
                                            </span>
                                            <h1 class="text-dark fw-bolder my-5">Our Head Office</h1>
                                            <div class="text-gray-700 fs-3 fw-bold">Churchill-laan 16 II, 1052 CD, Amsterdam</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card bg-light text-center">
                                    <div class="card-body py-12">
                                        <a class="mx-4">
                                            <img src="assets/media/svg/brand-logos/facebook-4.svg" class="h-30px my-2" alt="" />
                                        </a>
                                        <a class="mx-4">
                                            <img src="assets/media/svg/brand-logos/instagram-2-1.svg" class="h-30px my-2" alt="" />
                                        </a>
                                        <a class="mx-4">
                                            <img src="assets/media/svg/brand-logos/github.svg" class="h-30px my-2" alt="" />
                                        </a>
                                        <a class="mx-4">
                                            <img src="assets/media/svg/brand-logos/behance.svg" class="h-30px my-2" alt="" />
                                        </a>
                                        <a class="mx-4">
                                            <img src="assets/media/svg/brand-logos/pinterest-p.svg" class="h-30px my-2" alt="" />
                                        </a>
                                        <a class="mx-4">
                                            <img src="assets/media/svg/brand-logos/twitter.svg" class="h-30px my-2" alt="" />
                                        </a>
                                        <a class="mx-4">
                                            <img src="assets/media/svg/brand-logos/dribbble-icon-1.svg" class="h-30px my-2" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
            {/* {<div style={{ "display": "none" }}> setTimeout(function(){loadScripts()},500) </div>} */}

            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>


        </>);

};

export default Support;
