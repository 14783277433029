import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom'
import { useHistory, useParams } from 'react-router-dom'
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import './support.css'

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import removeImg from './icons/remove.svg'

export const ViewSupportDetails = (props) => {
    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    const [accessToken, setaccessToken] = useState('');
    const [loading, setloading] = useState(false);
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [unique_id, setUnique_id] = useState("");
    const [ViewDetails, setViewDetails] = useState([]);
    let [product_name, setproduct_name] = useState("");
    let [product_description, setproduct_description] = useState("");
    let [status, setstatus] = useState("");
    let [add_date, setadd_date] = useState("");
    const [message, setMessage] = useState('');
    const [state, setState] = useState(2);
    // const ID = useParams();
    // alert(props.match.params.id);

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);
    //const [organization_id, setOrganization_id] = useState("");
    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);
	var page_name = "view-support-details";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let [fileNames, setFileNames] = useState(["", ""])
    setFileNames = (name) => {
        fileNames.push(name)
        let uniq = fileNames => [...new Set(fileNames)];
        fileNames = fileNames.slice(-2)
    }

    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    });

                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) 
						{
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
                        else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }
                            }
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            res.data.audience_count = (res.data.audience_count)
                            if (res.data.audience_count > 0) {
                                const timer_decrease = setInterval(() => {
                                    res.data.audience_count = (res.data.audience_count - 1);
                                    //console.log(res.data.audience_count);
                                    if (res.data.audience_count > 0 && res.data.audience_count < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                                        if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                                            setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                                            window.$('#kt_modal_timer').modal('show');
                                        }
                                    }
                                }, 1000);

                                return () => clearInterval(timer_decrease);
                            }
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
                    });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
            }
        }
        validateJWT();
        // getImages();
    }, [])

    useEffect(() => {

    }, [timer]);

    useEffect(() => {
        async function viewsupportdetails() {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-view-support-details/${props.match.params.id}`, {
                method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
            }
            );
            const body = await response.json();

            if (body.length) {
                if (body[0].add_status == 'Open') {
                    body[0].color = 'badge badge-light-danger fs-7 fw-bolder';
                }
                if (body[0].add_status == 'On hold') {
                    body[0].color = 'badge badge-light-warning fs-7 fw-bolder';
                }
                if (body[0].add_status == 'Success') {
                    body[0].color = 'badge badge-light-success fs-7 fw-bolder';
                }
                setState(1);
            }
            else {
                setState(0);
            }

            setloading(true);
            setViewDetails(body);
            //console.log(body);
        }
        if(read || write)
        {
            (user.email && visibility) && viewsupportdetails();
        }

    }, [user, visibility, read, write])

    const showImage = (src) => {
        // alert('show image');
        const model_img_id = document.getElementById('inside_model_img');
        model_img_id.src = src;
    }

    const change_message = (e) => {
        setMessage(e.target.value);
    }

    async function save_data() {
        if (message) {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/send-message`, { method: 'POST', body: JSON.stringify({ 'id': props.match.params.id, 'message': message, 'filenames': fileNames,'page_name':page_name}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const msg = await response.json();

            //console.log(msg);
            setMessage('');
            if (msg.operation == 'success') {
                swal("", "Your message is sent!", "success").then((value) => {
                    window.location.href = `/view-support-details/${props.match.params.id}`;
                });
            }
            else {
                swal("Oops!", "somethig wrong..", "error");
            }

        }
        else {
            swal("Oops!", "Message is required", "error");
        }
    }


    var files = [];
    var sendButton = document.getElementById("kt_contact_submit_button");
    const CustomLayout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
        // Remove previews which do not pass the validation
        const previewsToDisplay = previews.filter((preview) => {
            return preview.props.meta.status !== 'error_validation';
        });
        return (

            <div {...dropzoneProps}>
                {previewsToDisplay}
                {files.length < maxFiles && input}
            </div>

        );
    };

    const handleChangeStatus2 = ({ meta }, status) => {
        // console.log('handleChangeStatus2');
        const { name } = meta;
        // console.log(name)
        let updatedFiles = [];
        if (status === 'done' || status === 'removed') {
            // Add meta to files
            if (status === 'done') {
                updatedFiles = [...files, meta];
                sendButton.setAttribute("data-kt-indicator", "off");
                sendButton.disabled = false;
            }
            // Remove meta from files
            else if (status === 'removed') {
                sendButton.setAttribute("data-kt-indicator", "off");
                sendButton.disabled = false;
                fileNames = fileNames.filter(item => item !== String(name))
                if (fileNames.length === 0) {
                    fileNames.push("")
                    fileNames.push("")
                }
            }
            else {
                updatedFiles = files.filter(files, (file) => {
                    return file.name !== meta.name
                        || file.size !== meta.size
                        || file.type !== meta.type;
                });

            }
            files = updatedFiles;
        }
    };

    const handleSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove());
        files = [];
    };
    const handleValidation2 = ({ meta }) => {
        return files.find(e => e.name === meta.name && e.size === meta.size && e.type === meta.type);
    }

    const icon = {
        remove: { backgroundImage: `url(${removeImg})` },
    }

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        setFileNames(String(name))
        return (

            <div className="preview-box">
                <img src={previewUrl} />
                <span className="name">{name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>
                {status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
                {status === "done" && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
            </div>
        )
    }

    const getUploadParams = ({ file }) => {
        //console.log(file);
        const body = new FormData();
        body.append('dataFiles', file);
        body.append('id', props.match.params.id);
        body.append('user_id', user.email);
        body.append('page_name', page_name);
        //body.append('organization_id', organization_id);
        //console.log(body);
        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        sendButton.setAttribute("data-kt-indicator", "on");
        sendButton.disabled = true;
        return { url: `${process.env.REACT_APP_SERVER_URL}/uploaddocuments`, headers, body }
    }

    // handle the status of the file upload
    const handleChangeStatus = ({ xhr }) => {
        //console.log('handleChangeStatus');
        if (xhr) {
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    const result = JSON.parse(xhr.response);
                    // console.log(result);
                }
            }
        }
    }

    return (
        <>
            {
                (redirect == 1 ||(read == 0 && write == 0)) ? 
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
                ViewDetails.length ?
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                                    <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Query Details</h1>
                                    <span className="h-20px border-gray-200 border-start mx-4"></span>
                                    <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                        <li className="breadcrumb-item text-muted">
                                            <a className="text-muted text-hover-primary">Home</a>
                                        </li>
                                        <li className="breadcrumb-item">
                                            <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                        </li>
                                        <li className="breadcrumb-item text-muted">Support</li>
                                        <li className="breadcrumb-item">
                                            <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                        </li>
                                        <li className="breadcrumb-item text-dark">Query Details</li>

                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" className="container">
                                <div className="card mt-5 mt-xxl-8">
                                    <div className="card-body flex-row-reverse">
                                        <div style={{ paddingBottom: '25px', marginLeft: '52px' }} className="d-flex flex-column">
                                            <div className="d-flex align-items-center mb-1">
                                                <div className="text-gray-600 fs-4 fw-bolder me-3">{ViewDetails[0].subject}</div>
                                                <span className={`${ViewDetails[0].color} rounded`}>{ViewDetails[0].add_status}</span>
                                            </div>
                                        </div>
                                        <div className="tab-content">
                                            <div className="timeline">

                                                {
                                                    JSON.parse(ViewDetails[0].message).map((obj) => {
                                                        return (
                                                            <>
                                                                {
                                                                    (obj.user_flag) == 'user' ?
                                                                        <div className="timeline-item ">
                                                                            <div className="timeline-line w-40px"></div>
                                                                            <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                                                                                <div className="symbol-label bg-light">
                                                                                    <span className="svg-icon svg-icon-2 svg-icon-gray-500">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                            <polygon fill="#000000" opacity="0.3" points="5 15 3 21.5 9.5 19.5" />
                                                                                            <path d="M13.5,21 C8.25329488,21 4,16.7467051 4,11.5 C4,6.25329488 8.25329488,2 13.5,2 C18.7467051,2 23,6.25329488 23,11.5 C23,16.7467051 18.7467051,21 13.5,21 Z M9,8 C8.44771525,8 8,8.44771525 8,9 C8,9.55228475 8.44771525,10 9,10 L18,10 C18.5522847,10 19,9.55228475 19,9 C19,8.44771525 18.5522847,8 18,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 C8,13.5522847 8.44771525,14 9,14 L14,14 C14.5522847,14 15,13.5522847 15,13 C15,12.4477153 14.5522847,12 14,12 L9,12 Z" fill="#000000" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="timeline-content mb-2 mt-n1">
                                                                                <div className="pe-3 mb-5">
                                                                                    <div className="fs-5 fw-bold mb-2">{obj.message}</div>
                                                                                    <div className="d-flex align-items-center mt-1 fs-6">
                                                                                        <div className="text-muted me-2 fs-7">Added at {moment(obj.add_time).utcOffset("+05:30").format('llll')}</div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        :
                                                                        <div style={{ display: 'flex', flexDirection: 'row-reverse' }} className="timeline-item ">
                                                                            <div className="timeline-line w-40px"></div>
                                                                            <div className="timeline-icon symbol symbol-circle symbol-40px me-4">
                                                                                <div className="symbol-label bg-light">
                                                                                    <span className="svg-icon svg-icon-2 svg-icon-gray-500">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                            <polygon fill="#000000" opacity="0.3" points="5 15 3 21.5 9.5 19.5" />
                                                                                            <path d="M13.5,21 C8.25329488,21 4,16.7467051 4,11.5 C4,6.25329488 8.25329488,2 13.5,2 C18.7467051,2 23,6.25329488 23,11.5 C23,16.7467051 18.7467051,21 13.5,21 Z M9,8 C8.44771525,8 8,8.44771525 8,9 C8,9.55228475 8.44771525,10 9,10 L18,10 C18.5522847,10 19,9.55228475 19,9 C19,8.44771525 18.5522847,8 18,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 C8,13.5522847 8.44771525,14 9,14 L14,14 C14.5522847,14 15,13.5522847 15,13 C15,12.4477153 14.5522847,12 14,12 L9,12 Z" fill="#000000" />
                                                                                        </svg>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                            <div style={{ display: 'flex', flexDirection: 'row-reverse' }} className="timeline-content mb-2 mt-n1">
                                                                                <div className="pe-3 mb-5">
                                                                                    <div className="fs-5 fw-bold mb-2">{obj.message}</div>
                                                                                    <div className="d-flex align-items-center mt-1 fs-6">
                                                                                        <div className="text-muted me-2 fs-7">Added at {moment(obj.add_time).utcOffset("+05:30").format('llll')}</div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                }

                                                                {
                                                                    JSON.parse(obj.attached_files).length ?

                                                                        (obj.user_flag) == 'user' ?
                                                                            <div className="overflow-auto pb-5 ">
                                                                                <div className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-2">
                                                                                    {
                                                                                        JSON.parse(obj.attached_files).map((obj1, index) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <div className="overlay me-10">
                                                                                                        <div className="overlay-wrapper">
                                                                                                            <img id='inside_map_img' alt='img' className="rounded w-200px" src={`https://shavastra-gn.s3.ap-south-1.amazonaws.com/customer_file/${obj1}`} />
                                                                                                        </div>
                                                                                                        <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                                                                                                            <a onClick={() => showImage(`https://shavastra-gn.s3.ap-south-1.amazonaws.com/customer_file/${obj1}`)} className="btn btn-sm btn-primary me-3 rounded" data-bs-toggle="modal" data-bs-target="#kt_modal_offer_a_deal">View</a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                            <div className="overflow-auto pb-5 ">
                                                                                <div style={{ display: 'flex', flexDirection: 'row-reverse' }} className="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-2">
                                                                                    {
                                                                                        JSON.parse(obj.attached_files).map((obj1, index) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <div className="overlay me-10">
                                                                                                        <div className="overlay-wrapper">
                                                                                                            <img id='inside_map_img' alt='img' className="rounded w-200px" src={`https://shavastra-gn.s3.ap-south-1.amazonaws.com/customer_file/${obj1}`} />
                                                                                                        </div>
                                                                                                        <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                                                                                                            <a onClick={() => showImage(`https://shavastra-gn.s3.ap-south-1.amazonaws.com/customer_file/${obj1}`)} className="btn btn-sm btn-primary me-3 rounded" data-bs-toggle="modal" data-bs-target="#kt_modal_offer_a_deal">View</a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                        : null

                                                                }
                                                            </>



                                                        )
                                                    })
                                                }
                                                <div className="modal fade my-4" id="kt_modal_offer_a_deal" tabindex="-1" aria-hidden="true">
                                                    <div className="modal-dialog modal-dialog-centered mw-800px">
                                                        <div style={{ borderRadius: '30px' }} className="modal-content">
                                                            <div className="modal-header py-0 d-flex justify-content-between">
                                                                <h2></h2>
                                                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                                    <span className="svg-icon svg-icon-1">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <img id='inside_model_img' style={{ height: "500px", padding: '25px', marginTop: '0' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="card">
                                    <div class="card-body p-lg-17">
                                        <div class="row mb-3">
                                            <div class="col-md-12 pe-lg-10">
                                                <form class="form mb-15" id="kt_contact_form">
                                                    <div class="d-flex flex-column mb-10 fv-row">
                                                        <label class="fs-6 fw-bold mb-2">Message</label>
                                                        <textarea class="form-control form-control-solid" rows="6" name="message" value={message} onChange={change_message} placeholder="" autoComplete="off"></textarea>
                                                    </div>

                                                    <div>
                                                        <label class="fs-5 fw-bold mb-2">Maximum 2 files</label>
                                                        <Dropzone
                                                            getUploadParams={getUploadParams}
                                                            onChangeStatus={handleChangeStatus, handleChangeStatus2}
                                                            onSubmit={handleSubmit}
                                                            validate={handleValidation2}
                                                            LayoutComponent={CustomLayout}
                                                            styles={{
                                                                dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                                inputLabelWithFiles: { margin: '20px 3%' }
                                                            }}
                                                            maxFiles={2}
                                                            // canRemove={false}
                                                            PreviewComponent={Preview}
                                                            accept="image/*,.pdf"
                                                        />
                                                    </div>
                                                {write==1 ?
                                                    <button onClick={save_data} style={{ marginTop: '10px' }} type="button" class="btn btn-primary rounded" id="kt_contact_submit_button">
                                                        <span class="indicator-label">Send</span>
                                                        <span class="indicator-progress">Please wait...
                                                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    </button>
                                                    :
                                                    <button onClick={save_data} style={{ marginTop: '10px' }} type="button" class="btn btn-primary rounded" id="kt_contact_submit_button" disabled>
                                                        <span class="indicator-label">Send</span>
                                                        <span class="indicator-progress">Please wait...
                                                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    </button>
                                                }
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                            <div className="toolbar" id="kt_toolbar">
                                <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                    <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                                        <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Query List</h1>
                                        <span className="h-20px border-gray-200 border-start mx-4"></span>
                                        <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
                                            <li className="breadcrumb-item text-muted">
                                                <a className="text-muted text-hover-primary">Home</a>
                                            </li>
                                            <li className="breadcrumb-item">
                                                <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                            </li>
                                            <li className="breadcrumb-item text-muted">Pages</li>
                                            <li className="breadcrumb-item">
                                                <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                            </li>
                                            <li className="breadcrumb-item text-muted">Profile</li>
                                            <li className="breadcrumb-item">
                                                <span className="bullet bg-gray-200 w-5px h-2px"></span>
                                            </li>
                                            <li className="breadcrumb-item text-dark">Query List</li>
                                        </ul>
                                    </div>

                                </div>
                            </div>

                            <div className="post d-flex flex-column-fluid" id="kt_post">
                                <div className="container" id="kt_content_container">
                                    <div className="card modal-body pt-0 pb-15 px-5 px-xl-20" >
                                        <br />

                                        {state == 2 ?
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                            :
                                            <>

                                                <div className="flex-lg-row-fluid me-lg-15">
                                                    <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                                        <div className="card-header">
                                                            <div className="card-title">
                                                                <h2 className="fw-bolder">Support Ticket</h2>
                                                            </div>
                                                        </div>

                                                        <div className="card-body pt-0">
                                                            <div className="text-gray-500 fw-bold fs-5 mb-5">Something went wrong</div>

                                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                <div className="d-flex flex-stack flex-grow-1">
                                                                    <div className="fw-bold">
                                                                        <h4 className="text-gray-800 fw-bolder">Support Query Ticket is not available</h4>
                                                                        <div className="fs-6 text-gray-600">
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>

                                            </>
                                        }
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
            }

            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>

        </>);

};

export default ViewSupportDetails;
