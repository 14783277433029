import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route, Router } from "react-router-dom";

import SubscriptionList from './includes/subscription-list.js';
import SubscriptionListTrail from './includes/subscription-list-trail.js';

import BillingAddress from './includes/billing-address.js';
import BillingAddressTrail from './includes/billing-address-trail.js';

import AccountOverview from './includes/account-overview.js';
import AccountSetting from './includes/account-setting.js';
import AccountBilling from './includes/account-billing.js';
import AccountSecurity from './includes/account-security.js';

import ViewSubscription from './includes/view-subscription.js';
import SubscriptionHistory from "./includes/subscription-history.js";

import Purchase from './includes/purchase.js';
import PurchaseTrail from './includes/purchase-trail.js';
import ActicityLogs from "./includes/acticityLogs.js";
import Activity from "./includes/activity-directalerts.js";

import PaymentResponse from './includes/payment-response.js';
import Support from "./includes/support.js";
import QueryList from "./includes/support-query-list.js";
import ViewSupportDetails from "./includes/view-support-details.js";

import Vendor from "./includes/add-vendor.js";
import Manufacturer from "./includes/add-manufacturer.js";
import Subcategory from "./includes/add-subcategory.js";

import ShowVendor from "./includes/show-vendor.js";
import ShowManufacturer from "./includes/show-manufacturer.js";
import ShowSubCategory from"./includes/show-subcategory.js";

import Account from './includes/add-account.js';
import AccountGroup from './includes/account-group.js';

import ViewManufacturerDetails from "./includes/view-manufacturer-details.js";
import ViewVendorDetails from "./includes/view-vendor-details.js";

import ShowGroup from "./includes/show-account-group.js";
import ShowAccount from "./includes/show-accounts.js";

import CustomerBankDetails from "./includes/customer-bank-details.js";
import ManfacturerBankDetails from "./includes/manufaturer-bank-details";
import VendorBankDetails from "./includes/vendor-bank-details";

import UserBankDetails from "./includes/user-bank-details";
import UserBankDetailsEntry from "./includes/user-bank-details-entry";
import ShowUserBankDetails from "./includes/show-user-account-details";

import ProductStockInformation from "./includes/product-stock-information.js";
import ProductAdditionalInformation from "./includes/product-additional-information";
import ProductSpecialDiscount from "./includes/product-special-discount.js";
import ProductImages from "./includes/product-images";
import ProductPriceInformation from "./includes/product-price-information.js";
import Makegroup from "./includes/make-group.js";
import ProductVideo from "./includes/product-video.js";
import ShowDiscountedProduct from "./includes/show-discounted-product.js";
import ShowProductGroup from "./includes/show-product-group.js";
import UpdateProductSerial from "./includes/update-product-serial.js";
import RewardPoint from "./includes/reward-point.js";

import Reseller from "./includes/add-reseller.js";
import ShowReseller from "./includes/show-reseller.js";
import ViewResellerDetails from "./includes/view-reseller-details.js";
import ResellerBankDetails from "./includes/reseller-bank-details";
import ShowResellerProduct from "./includes/show-reseller-product.js";
import ResellerBankInfo from "./includes/reseller-bank-info.js";
import ResellerAssignProducts from "./includes/reseller-assign-products.js";

import ProductBilling from "./includes/product-billing.js";
import ProductBillingList from "./includes/show-billing-product.js";
import UpdateProductBilling from "./includes/product-billing-update.js";

import ShowResellerAssignedProducts from "./includes/show-reseller-assigned-products.js";
import ShowResellerProductsLog from "./includes/show-reseller-products-log.js";
import ResellerProductsLogInfo from "./includes/reseller-products-log-info.js";
import CustomerBillingProfile from "./includes/customer-billing-Profile.js";
import ShowResellerBills from "./includes/show-reseller-bills.js";

import MakePayment from "./includes/payment.js";
import AdvancePayment from "./includes/advance-payment.js";





import Signin from './includes/sign-in.js';
import Signup from './includes/sign-up.js';
import QuickSetUp from './includes/quick-set-up.js';
import ForgotPassword from"./includes/forgot-password.js";
import FirstStepForgotPassword from "./includes/first-step-forgot-password.js";
import NotFound from "./includes/NotFound.js";
import UnAuthorized from "./includes/unAuthorized.js";

import AsideNavbar from "./includes/aside-navbar.js";
import Header from "./includes/header.js";
import Footer from "./includes/footer.js";

import Dashboard from './includes/dashboard.js';
import ShowOrganizations from "./includes/show-organizations.js";
import OrganizationProfile from './includes/organization-profile.js';
import User from "./includes/add-user";
import ShowUsers from "./includes/show-users.js";
import UpdateUser from "./includes/update-user.js";

import Customer from"./includes/add-customer.js";
import ShowCustomer from "./includes/show-customer.js";
import EditCustomerDetails from "./includes/edit-customer-details.js";
import ViewCustomerDetails from "./includes/view-customer-details.js";

import Invoices from "./includes/add-invoice.js";
import ShowInvoices from "./includes/show-invoice.js";
import EditInvoiceDetails from "./includes/edit-invoice-details.js";
import ViewInvoiceDetails from "./includes/view-invoice-details.js";

import AddProject from "./includes/add-project.js";
import ListProject from "./includes/project-list.js";

import Expenses from "./includes/add-invoice-expenses.js";
import ExpensesBulk from "./includes/add-bulk-invoice-expenses.js";
import ShowExpenses from "./includes/show-invoice-expenses.js";

import Estimates from "./includes/add-invoice-estimates.js";
import ShowEstimates from "./includes/show-invoice-estimates.js";

import Category from "./includes/add-category.js";
import ShowCategory from "./includes/show-category.js";

import TaxList from "./includes/tax-list.js";
import TaxGroupList from "./includes/tax-group-list.js";

import ProductEntry from "./includes/product-entry.js";
import ShowProduct from "./includes/show-product.js";

import CurrencyList from "./includes/currency-list.js";

import TemplateInvoice from "./includes/template-invoice.js";

import AddProfile from './includes/add-profile.js';
import ShowProfiles from './includes/show-profiles.js';
import UpdateProfile from './includes/update-profile.js';
import ViewProfile from './includes/view-profiles.js';

import Roles from "./includes/add-roles.js";
import ShowRoles from "./includes/show-roles.js";
import ViewRoles from "./includes/view-roles.js";
import UpdateRoles from "./includes/update-roles.js";

import PaymentPortalConfig from "./includes/payment-portal-config.js";

import CustomerPaymentInvoice from "./includes/customer-payment-invoice.js";
import CustomerPaymentInvoiceSuccess from "./includes/customer-payment-invoice-success.js";
import CustomerPaymentInvoiceFail from "./includes/customer-payment-invoice-fail.js";

import CreateAccount from "./includes/create-account.js";

import ActivityLogAlert from './includes/activity-log-alert.js';

export default function BasePage() {

	return (
		<Switch>
			<Route exact path={"/login"} component={Signin} />
			<Route exact path={"/register"} component={Signup} />
			<Route exact path={"/quick-set-up"} component={QuickSetUp} />
			<Route exact path={"/forgot-password/:id"} component={ForgotPassword} />
			<Route exact path={"/forgot-password-email"} component={FirstStepForgotPassword} />
			<Route exact from="/not-found" component={NotFound} />

			<Route exact from="/customer-payment-invoice/:oid/:id" component={CustomerPaymentInvoice} />
			<Route exact from="/customer-payment-invoice-success/:oid/:id" component={CustomerPaymentInvoiceSuccess} />
			<Route exact from="/customer-payment-invoice-fail/:oid/:id" component={CustomerPaymentInvoiceFail} />
			<Route exact from="/create-account/:id" render={(props) => <CreateAccount {...props} />} />

			<Route exact path={"/unauthorized"} component={UnAuthorized} />
			<Route component={DefaultContainer} />
		</Switch>
	);
}

const DefaultContainer = () => (
	<>
		

		<div className="d-flex flex-column flex-root app-root" id="kt_app_root">
			<div className="app-page flex-column flex-column-fluid" id="kt_app_page">
				<ActivityLogAlert />
				<Header />

				<div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
					<AsideNavbar />
						<div className="app-main flex-column flex-row-fluid" id="kt_app_main">
						<Switch>
							<Route exact path={"/subscription-list/:id"} render={(props) => <SubscriptionList {...props} />} />
							<Route exact path={"/subscription-list-trial/:id"} render={(props) => <SubscriptionListTrail {...props} />} />
							<Route exact path={"/view-subscription/:id"} render={(props) => <ViewSubscription {...props} />} />
							<Route exact path={"/billing-address"} render={(props) => <BillingAddress {...props} />} />
							<Route exact path={"/billing-address-trial"} render={(props) => <BillingAddressTrail {...props} />} />
							<Route exact path={"/account-overview"} component={AccountOverview} />
							<Route exact path={"/account-setting"} component={AccountSetting} />
							<Route exact path={"/account-billing"} component={AccountBilling} />
							<Route exact path={"/account-security"} component={AccountSecurity} /> 
							<Route exact path={"/subscription-history"} component={SubscriptionHistory} />
							<Route exact path={"/purchase"} component={Purchase} />
							<Route exact path={"/purchase-trial"} component={PurchaseTrail} />
							<Route exact path={"/activity"} component={ActicityLogs} />
							<Route exact path={"/activity-directalerts"} component={Activity} />

							<Route exact path={"/payment-response/:response/:id"} render={(props) => <PaymentResponse {...props} />} />
							<Route exact path={"/support"} component={Support} />
							<Route exact path={"/support-query-list"} component={QueryList} />
							<Route exact path={"/view-support-details/:id"} render={(props) => <ViewSupportDetails {...props} />} />
							
							<Route exact from="/add-vendor" component={Vendor} />
                            <Route exact from="/add-manufacturer" component={Manufacturer} />
                            <Route exact from="/add-subcategory" component={Subcategory} />
							
							<Route exact from="/vendor-list" component={ShowVendor} />
							<Route exact from="/manufacturer-list" component={ShowManufacturer} />
							<Route exact from="/subcategory-list" component={ShowSubCategory} />
							
							<Route exact path={"/add-account"} component={Account} />
                            <Route exact path={"/account-group"} component={AccountGroup} />
							
							<Route exact path={"/view-manufacturer-details/:id"} render={(props) => <ViewManufacturerDetails {...props} />} />
                            <Route exact path={"/view-vendor-details/:id"} render={(props) => <ViewVendorDetails {...props} />} />
							
							<Route exact from="/account-group-list" component={ShowGroup} />
							<Route exact from="/account-list" component={ShowAccount} />
							
							<Route exact path={"/customer-bank-details/:id"} render={(props) => <CustomerBankDetails {...props} />} />
                            <Route exact path={"/manufacturer-bank-details/:id"} render={(props) => <ManfacturerBankDetails {...props} />} />
                            <Route exact path={"/vendor-bank-details/:id"} render={(props) => <VendorBankDetails {...props} />} />
							
							<Route exact path={"/update-user-bank-details/:id"} render={(props) => <UserBankDetails {...props} />} />
                            <Route exact path={"/user-bank-details-entry"} component={UserBankDetailsEntry}/>
                            <Route exact from="/show-user-bank-details" component={ShowUserBankDetails} />
							
							
                            <Route exact path={"/product-stock-information/:id"} render={(props) => <ProductStockInformation {...props} />} />
							<Route exact path={"/product-additional-information/:id"} render={(props) => <ProductAdditionalInformation {...props} />} />
							<Route exact path={"/product-special-discount/:id"} render={(props) => <ProductSpecialDiscount {...props} />} />
							<Route exact path={"/product-images/:pid"} render={(props) => <ProductImages {...props} />} />
							<Route exact path={"/product-price-information/:id"} render={(props) => <ProductPriceInformation {...props} />} />
                            <Route exact from="/make-group" component={Makegroup} />
							<Route exact path={"/product-video/:pid"} render={(props) => <ProductVideo {...props} />} />
							<Route exact from="/discounted-product-list" component={ShowDiscountedProduct} />
							<Route exact from="/product-group-list" component={ShowProductGroup} />
							<Route exact path={"/update-product-serial/:id"} render={(props) => <UpdateProductSerial {...props} />} />
							<Route exact from="/product-billing" component={ProductBilling} />
							<Route exact from="/billing-product-list" component={ProductBillingList} />
							<Route exact path={"/update-product-billing/:id"} render={(props) => <UpdateProductBilling {...props} />} />

							<Route exact from="/add-reseller" component={Reseller} />
							<Route exact from="/reseller-list" component={ShowReseller} />
                            <Route exact path={"/view-reseller-details/:id"} render={(props) => <ViewResellerDetails {...props} />} />
                            <Route exact path={"/reseller-bank-details/:id"} render={(props) => <ResellerBankDetails {...props} />} />
                            <Route exact from="/reseller-products" component={ShowResellerProduct} />
                            <Route exact from="/reseller-bank-info" component={ResellerBankInfo} />
							<Route exact path={"/assign-products/:id"} render={(props) => <ResellerAssignProducts {...props} />} />
							<Route exact path={"/reseller-products-list/:id"} render={(props) => <ShowResellerAssignedProducts {...props} />} />
                            <Route exact path={"/reseller-products-log/:id"} render={(props) => <ShowResellerProductsLog {...props} />} />
                            <Route exact from="/reseller-products-log-info" component={ResellerProductsLogInfo} />
							<Route exact path={"/customer-billing-Profile/:id"} render={(props) => <CustomerBillingProfile {...props} />} />
							<Route exact path={"/reseller-bill-list/:id"} render={(props) => <ShowResellerBills {...props} />} />

                            <Route exact from="/reward-point" component={RewardPoint} />

							<Route exact from="/payment" component={MakePayment} />
							<Route exact from="/advance-payment" component={AdvancePayment} />





							<Route exact path={"/"} component={Dashboard} />
							<Route exact path={"/dashboard"} component={Dashboard} />
							<Route exact path={"/show-organizations"} component={ShowOrganizations} />
                            <Route exact path={"/organization-profile"} component={OrganizationProfile} />

							<Route exact path={"/add-user"} component={User} />
							<Route exact path={"/show-user"} component={ShowUsers} />
                            <Route exact path={"/update-user/:id"} render={(props) => <UpdateUser {...props} />} />

							<Route exact path={"/add-customer"} component={Customer} />
							<Route exact path={"/customer-list"} component={ShowCustomer} />
							<Route exact path={"/edit-customer-details/:id"} render={(props) => <EditCustomerDetails {...props} />} />
							<Route exact path={"/view-customer-details/:id"} render={(props) => <ViewCustomerDetails {...props} />} />
							
							<Route exact path={"/add-invoice"} component={Invoices} />
							<Route exact path={"/add-invoice/:id"} render={(props) => <Invoices {...props} />} />
							<Route exact path={"/invoice-list"} component={ShowInvoices} />
							<Route exact path={"/edit-invoice-details/:id"} render={(props) => <EditInvoiceDetails {...props} />} />
							<Route exact path={"/view-invoice-details/:id"} render={(props) => <ViewInvoiceDetails {...props} />} />
							
							<Route exact path={"/add-project"} component={AddProject} />
							<Route exact path={"/project-list"} component={ListProject} />

							<Route exact path={"/add-expenses"} component={Expenses} />
							<Route exact path={"/add-bulk-expenses"} component={ExpensesBulk} />
							<Route exact path={"/expenses-list"} component={ShowExpenses} />

							<Route exact path={"/add-estimates"} component={Estimates} />
							<Route exact path={"/estimates-list"} component={ShowEstimates} />

							<Route exact path={"/add-category"} component={Category} />
							<Route exact path={"/category-list"} component={ShowCategory} />

							<Route exact path={"/tax-list"} component={TaxList} />
							<Route exact path={"/tax-group-list"} component={TaxGroupList} />

							<Route exact path={"/product-entry"} component={ProductEntry} />
							<Route exact path={"/product-list"} component={ShowProduct} />

							<Route exact path={"/currency-list"} component={CurrencyList} />

							<Route exact path={"/template-invoice"} component={TemplateInvoice} />

							<Route exact from="/add-profile" component={AddProfile} />
                            <Route exact from="/profile-list" component={ShowProfiles} />
                            <Route exact from="/update-profile/:id" render={(props) => <UpdateProfile {...props} />} />
                            <Route exact from="/view-profiles/:id" render={(props) => <ViewProfile {...props} />} />

							<Route exact from="/add-roles" component={Roles} />
                            <Route exact from="/roles" component={ShowRoles} />
                            <Route exact from="/view-roles/:id" component={ViewRoles} />
                            <Route exact from="/update-roles/:id" component={UpdateRoles} />

                            <Route exact from="/payment-portal-config" component={PaymentPortalConfig} />

							<Route from="*" ><Redirect to="/not-found" /> </Route>
						</Switch>
						</div>
					<Footer />
				</div>
				
			</div>
		</div>
	</>
)