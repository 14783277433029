import React, { useState, useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import Select, {components} from 'react-select';
import removeImg from './icons/remove.svg'
import uuid from 'react-uuid';
import { Modal } from 'react-bootstrap';
import currency_arr from './currency-arr.js' ;

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

// import $ from 'jquery';
// import moment from 'moment';
// import { Form, Col, Button, InputGroup } from 'react-bootstrap';
// import { Formik } from "formik";
// import * as yup from "yup";
// import ReactDOM from 'react-dom';
// import ReactTagInput from "@pathofdev/react-tag-input";
// import "@pathofdev/react-tag-input/build/index.css";
// import XLSX from 'xlsx';
// import { saveAs } from 'file-saver';
// import MUIDataTable from "mui-datatables";
// import { Workbook } from 'exceljs';

function ProductEntry(props) {
    const [user, setUser] = useState({})
    const [organization_id,setOrganization_id] = useState("")
    const [organization_list, setorganization_list] = useState([]);
    const [timer, setTimer] = useState(0)
    const [clock_timer, setClockTimer] = useState(0);
    const [state, setstate] = useState(2)

    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
	const [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);

    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    const [currencyList, setCurrencyList] = useState([])
    const [taxList, setTaxList] = useState([])
    const [organizationDetails, setOrganizationDetails] = useState(null)

    const [productType, setProductType] = useState("goods");
    const [productName, setProductName] = useState("");
    const [productSku, setProductSku] = useState("");
    const [selectedProductUnit, setSelectedProductUnit] = useState(null);
    const [selectedProductCurrency, setSelectedProductCurrency] = useState(null);
    const [productSellingPrice, setProductSellingPrice] = useState(0);
    const [productTaxable, setProductTaxable] = useState(false)
    const [selectedProductTax, setSelectedProductTax] = useState(null)
    const [productDescription, setProductDescription] = useState("");

    const [fileName, setFileName] = useState("");
    const [unique_id, setunique_id] = useState(uuid())

    const [submitButtonState, setSubmitButtonState] = useState(false);

    const [currencyModalShow, setCurrencyModalShow] = useState(false)
    const [selectedCurrencyCode, setSelectedCurrencyCode] = useState(null)
    const [currencySymbol, setCurrencySymbol] = useState("")
    const [selectedCurrencyDecimalPlace, setSelectedCurrencyDecimalPlace] = useState(null)
    const [selectedCurrencyFormat, setSelectedCurrencyFormat] = useState(null)
    const [currencySubmitButtonState, setCurrencySubmitButtonState] = useState(false)

    var page_name = "product-entry";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true);
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    setRedirect_to_login(1);
                });
                
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if(temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else
                                    {
                                        temp = 2;
                                    }
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
							
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setRedirect_to_login(1);
                });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }

                // console.log(timer)
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);





    async function getCurrencies() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCurrencyList(body)
        }
    }
    async function getTaxes() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/tax-group-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setTaxList(body)
        }
    }
    async function getOrganizationDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/show-organization`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setOrganizationDetails(body.find(x=>x.organization_id == getCookie("organization_id")))
        }
    }
    useEffect(() => {
		if ((read || write) && (user.email && visibility)) {
			let p1 = getCurrencies();
            let p2 = getTaxes();
            let p3 = getOrganizationDetails();
            Promise.all([p1,p2,p3])
            .then(()=>{
                setstate(1)
                console.log("all apis done")
            })
            .catch((err)=>{
                console.log(err)
            })
		}

	}, [user, visibility, read, write])

    useEffect(() => {
        if((organizationDetails!=null)&&(currencyList.length>0)){
            setSelectedProductCurrency({label: currencyList.find(x=>x.currency_id==organizationDetails.currency).currency_code,value:organizationDetails.currency})
        }
    }, [organizationDetails,currencyList])


    const newProductSubmit = (e) => {
        if (productName == "") {
            swal("Oops!", "Please provide a product name", "warning");
            return
        }
        if (selectedProductUnit == null) {
            swal("Oops!", "Please select an usage unit", "warning");
            return
        }
        if (selectedProductCurrency == null) {
            swal("Oops!", "Please provide a currency", "warning");
            return
        }
        if (productSellingPrice <= 0) {
            swal("Oops!", "Selling price cannot be less than or equal to 0", "warning");
            return
        }
        if (productTaxable && selectedProductTax == null) {
            swal("Oops!", "Select a tax group for the product", "warning");
            return
        }

        let object = {}

        object.product_type = productType
        object.product_name = productName
        object.sku = productSku
        object.product_unit = selectedProductUnit.value
        object.product_currency = selectedProductCurrency.value 
        object.product_selling_price = productSellingPrice
        object.product_taxable = productTaxable?"yes":"no"
        object.product_tax = productTaxable?selectedProductTax.value:null
        object.description = productDescription

        object.file_name = fileName
        object.unique_id = unique_id

        object.organization_id = organization_id
        object.page_name = page_name
        
        console.log(object)
        setSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/product`, object, headers).then((res) => {
            setSubmitButtonState(false)
            if (res.data.operation === "duplicate_name") {
                swal("Oops!", "product with this name already exists!!", "error");
            } 
            else if (res.data.operation === "duplicate_sku") {
                swal("Oops!", "product with this sku already exists!!", "error");
            }
            else if (res.data && res.data.operation == "success") {
                swal("Great!", "Product entered successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });

    }

    const newCurrencySubmit = () => {

        if(selectedCurrencyCode == null){
            swal("Oops!", "Currency Code cannot be empty", "error");
            return
        }
        else if(currencySymbol == ""){
            swal("Oops!", "Currency Symbol cannot be empty", "error");
            return
        }

        var object = {};

        object.currency_code = selectedCurrencyCode.value
        object.currency_symbol = currencySymbol
		object.decimal_place = selectedCurrencyDecimalPlace==null?null:selectedCurrencyDecimalPlace.value
		object.format = selectedCurrencyFormat==null?null:selectedCurrencyFormat.value

        object.organization_id = organization_id;
        object.page_name = page_name;

        // console.log(object);
        setCurrencySubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-currency`, object, headers).then((res) => {
            setCurrencySubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Currency added successfully!", "success")
                handleNewCurrencyModalClose()
                getCurrencies()
            }
            else if(res.data.operation === "same_currency_code"){
                swal("Oops!", "This Currency code is already used!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const handleNewCurrencyModalClose = () => {
        setCurrencyModalShow(false)
        setSelectedCurrencyCode(null) 
        setCurrencySymbol("")
        setSelectedCurrencyDecimalPlace(null)
        setSelectedCurrencyFormat(null)
    }

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        return (
            <div className="preview-box">
                <img src={previewUrl} />
                <span className="name">{name.substring(0,40)+(name.length>40?"...":"")}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>
                {status !== "done" && <><span className="percent"> ({Math.round(percent)}%)</span><span className="dzu-previewButton remove" style={{ backgroundImage: `url(${removeImg})` }} onClick={remove} /></>}
                {status === "done" && <span className="dzu-previewButton remove" style={{ backgroundImage: `url(${removeImg})` }} onClick={remove} />}
            </div>
        )
    }

    const getUploadParams = ({ file }) => {
        const body = new FormData()
        body.append('dataFiles', file)
        body.append('unique_id', unique_id)
        body.append('user_id', user.email)
		body.append('page_name', page_name)
		body.append('organization_id', organization_id)

        setSubmitButtonState(true)

        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        return { url: `${process.env.REACT_APP_SERVER_URL}/uploadproductimages`, headers, body }
    }

    const handleChangeStatus = ({ meta }, status, allFiles) => {
        const { name } = meta;
        
        if (status === 'done') {
            setFileName(name)
            setSubmitButtonState(false)
        }
        else if (status === 'removed') {
            setSubmitButtonState(false)
            let temp = [...fileName] 
            temp.splice(temp.indexOf(name), 1)
            setFileName(temp)
        }
        else if(status === "error_file_size")
        {
            allFiles[0].remove()
            swal("Oops!", "File size exceeded", "warning");
        }
    };

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> :
                null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}
            
                
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Product Entry</h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">

                        <div className="card">
                            <div class="card-body">
                                {
                                    state == 1?
                                    <>
                                        <div className="container p-0">
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div className="row mb-5">
                                                        <div className='col-4 my-auto'>
                                                            <label className="fs-5 fw-bold mb-2">Product Type</label>
                                                        </div>
                                                        <div className='col-4'>
                                                            <label className="fs-5 mb-2" onClick={()=>{ setProductType("goods") }}> Goods
                                                                <input type="radio" className="form-check-input ms-5" checked={productType=="goods"} />
                                                            </label>
                                                        </div>
                                                        <div className='col-4'>
                                                            <label className="fs-5 mb-2" onClick={()=>{ setProductType("service") }}>Service
                                                                <input type="radio" className="form-check-input ms-5" checked={productType=="service"}/>
                                                            </label>                                                    
                                                        </div>    
                                                    </div>

                                                    <div className="row mb-5">
                                                        <div className="col-12">
                                                            <label className="required fs-5 fw-bold mb-2">Product Name</label>
                                                            <input type="text" className="form-control form-control-solid" value={productName} onChange={(e) => { setProductName(e.target.value)}} placeholder="Product Name"/>
                                                            <span className="form-text text-muted">Please enter Product name.</span>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-5">
                                                        <div className="col-12">
                                                            <label className="fs-5 fw-bold mb-2">SKU</label>
                                                            <input type="text" className="form-control form-control-solid" placeholder="SKU" value={productSku} onChange={(e) => {setProductSku(e.target.value) }} />
                                                            <span className="form-text text-muted">Please enter SKU</span>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-5">
                                                        <div className="col-12">
                                                            <label className="required fs-5 fw-bold mb-2">Usage Unit</label>
                                                            <Select
                                                                options={["Quantity","Pieces","Box","Carton","Dozen","Each","Hour(s)","Impressions","Pages","Sheet","Square Feet","Pack",].map(x=>{return {label:x,value:x} }) }
                                                                onChange={(val) => {setSelectedProductUnit(val) }}        
                                                                value={selectedProductUnit}
                                                                isClearable
                                                                placeholder="--Choose Option--"
                                                                styles={{...(userTheme=='dark'?dropdownStyleDark:{}),
                                                                    container: (provided, state) => ({
                                                                        ...provided,
                                                                        minWidth: "100px"
                                                                    })
                                                                }}
                                                            />
                                                            <span className="form-text text-muted">Please enter your Usage Unit .</span>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-5">
                                                        <div className="col-12 form-group ">
                                                            <label className="required fs-5 fw-bold mb-2">Selling Price</label>
                                                            <div className='d-flex align-items-center'>
                                                                <Select
                                                                    options={ currencyList.map(x=>{return {label:x.currency_code,value:x.currency_id} }) }
                                                                    onChange={(val) => {setSelectedProductCurrency(val) }}        
                                                                    value={selectedProductCurrency}
                                                                    components={{ 
                                                                        MenuList: (props) => {
                                                                            return (
                                                                                <components.MenuList  {...props}>
                                                                                    {props.children} 
                                                                                    <div className='d-flex justify-content-center'>
                                                                                        <button className='btn btn-success rounded btn-sm my-1' onClick={()=>{setCurrencyModalShow(true)}} >[&#10011;] New</button>
                                                                                    </div>
                                                                                </components.MenuList >
                                                                            ) 
                                                                        } 
                                                                    }}
                                                                    placeholder="CCY..."
                                                                    styles={{...(userTheme=='dark'?dropdownStyleDark:{}),
                                                                        container: (provided, state) => ({
                                                                            ...provided,
                                                                            minWidth: "100px"
                                                                        })
                                                                    }}
                                                                />
                                                                <input type="number" className="form-control form-control-solid flex-grow-1" value={productSellingPrice} 
                                                                    onChange={(e) => {
                                                                        if(parseFloat(e.target.value)>=0){
                                                                            setProductSellingPrice(parseFloat(e.target.value))
                                                                        }
                                                                    }} 
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-5">
                                                        <div className="col-12 form-group ">
                                                            <label className="required fs-5 fw-bold mb-2">Product Tax</label>
                                                            <div className='d-flex align-items-center'>
                                                                <div className="form-check form-check-solid form-check-primary form-switch mt-2">   
                                                                    <input className="form-check-input w-45px h-30px" type="checkbox" checked={productTaxable} onChange={(e)=>{e.target.checked==false && setSelectedProductTax(null); setProductTaxable(e.target.checked) }} />    
                                                                </div>
                                                                <div className='flex-grow-1 ms-5'>
                                                                    <Select
                                                                        options={ taxList.map(x=>{return {label:x.tax_group_name + ` (${x.tax_group_percentage}%)`, value:x.tax_group_id} }) }
                                                                        onChange={(val) => {setSelectedProductTax(val)}} 
                                                                        isClearable
                                                                        value={selectedProductTax}
                                                                        isDisabled={!productTaxable}
                                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-5">
                                                        <div className="col-12">
                                                            <label className="fs-5 fw-bold mb-2">Description</label>
                                                            <textarea className="form-control form-control-solid" rows={3} value={productDescription} onChange={(e) => {setProductDescription(e.target.value)}} placeholder="Description" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6'>
                                                    {
                                                        write == 1 &&
                                                        <div className='h-100 px-10'>
                                                            <label className="fs-5 fw-bold mt-4 mb-2">Upload a single file (maximum: 2MB)</label>
                                                            <Dropzone
                                                                getUploadParams={getUploadParams}
                                                                onChangeStatus={handleChangeStatus}
                                                                styles={{ inputLabelWithFiles: { margin: '20px 3%' }, dropzone: {height: "75%"},inputLabel: {textAlign: "center"} }}
                                                                maxFiles={1}
                                                                maxSizeBytes={1024*1024*2}
                                                                PreviewComponent={Preview}
                                                                accept="image/*,.pdf"
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <br/>

                                        {write == 1 &&
                                            <button type="button" className="btn btn-primary rounded" data-kt-indicator={submitButtonState?"on":"off"} disabled={submitButtonState} onClick={()=>{newProductSubmit() }}>
                                                <span className="indicator-label">Submit</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        }
                                    </>
                                    :
                                    <>
                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    </>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            
            <Modal show={currencyModalShow} onHide={()=>{handleNewCurrencyModalClose()}} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>New Currency</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{handleNewCurrencyModalClose()}}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-5 fw-bold mb-2 required">Currency Code</label>
                                <Select
                                    options={Object.keys(currency_arr.currency_arr).map(x=>{return {label: x,value: x}})}
                                    onChange={(e) => { setSelectedCurrencyCode(e); setCurrencySymbol(currency_arr.currency_arr[e.value].currency_symbol); }}
                                    value={selectedCurrencyCode}
                                    placeholder="--Choose a Currency--"
                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-6 fw-bold mb-2 required">Currency symbol</label>
                                <input type="text" className="form-control form-control-solid" value={currencySymbol} onChange={(e)=>{setCurrencySymbol(e.target.value)}}/>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-5 fw-bold mb-2">Decimal Places</label>
                                <Select
                                    options={[0,2,3].map(x=>{return {label:x,value:x}})}
                                    onChange={(e) => { setSelectedCurrencyDecimalPlace(e) }}
                                    value={selectedCurrencyDecimalPlace}
                                    placeholder="--Choose Decimal Places--"
                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-6 fw-bold mb-2">Format</label>
                                <Select
                                    options={["1,234,567.89", "1.234.567,89", "1 234 567,89" ].map(x=>{return {label:x,value:x}})}
                                    onChange={(e) => { setSelectedCurrencyFormat(e) }}
                                    value={selectedCurrencyFormat}
                                    placeholder="--Choose a Format--"
                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    {
                        write == 1 &&
                        <button className="btn btn-success rounded me-auto" data-kt-indicator={currencySubmitButtonState?"on":"off"} disabled={currencySubmitButtonState} onClick={()=>{newCurrencySubmit()}}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                    <button className="btn btn-danger rounded" onClick={()=>{handleNewCurrencyModalClose()}}>Close</button>
                </Modal.Footer>
            </Modal>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org}/>
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer}/>
        </>
    );
}
export default ProductEntry;