import React, {lazy,Suspense,useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import { Link, Redirect } from 'react-router-dom'
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';

import country_arr from './countries-arr.js';
import s_a from './states-arr.js';

import Select from 'react-select';

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';

import moment from 'moment';


function UserBankDetails(props) {
	let [subscription_details, setSubscriptionDetails] = useState([]);
	let [user, setUser] = useState({});
	let [username, setUsername] = useState("");
	let [image,setImage] = useState("");
	let [currency, setCurrency] = useState("default");
	let [state,setstate] = useState(2);
	let [timer,setTimer] = useState(0);
	let [clock_timer,setClockTimer] = useState(0);
	let [account_no,setaccount_no] = useState("");
	let [bank_name,setbank_name] = useState("");
	let [branch_code,setbranch_code] = useState("");
	let [branch_name,setbranch_name] = useState("");
	let [email,setemail] = useState("");
	let [notes,setnotes] = useState("");
	let [mobileno,setmobileno] = useState("");
	let [phoneno,setphoneno] = useState("");
	let [short_name,setshort_name] = useState("");
	let [address_line1,setaddress_line1] = useState("");
	let [website,setwebsite] = useState("");
	let [faxno,setfaxno] = useState("");
	let [ifsc,setifsc] = useState("");
	let [contact_person,setcontact_person] = useState("");
	//const [organization_id, setOrganization_id] = useState("");
	const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);
	var page_name = "update-user-bank-details";
	let loader = 0;
	
	const loaderstyle = {
	  display: "block",
	  marginLeft: "auto",
	  marginRight: "auto",
	  marginTop: "auto",
	  marginBottom: "auto",
	};
	
	const renderTime = ({ remainingTime }) => {
		
	  if (remainingTime === 0)
	  {
		//return <div className="timer">Too lale...</div>;
		window.location.href = "/login";
	  }

	  return (
		<div className="timer">
		  <div className="text">Remaining</div>
		  <div className="value">{remainingTime}</div>
		  <div className="text">seconds</div>
		</div>
	  );
	};
	
	function setCookie(cname, cvalue, exdays) {
	  var d = new Date();
	  d.setTime(d.getTime() + (exdays*24*60*60*1000));
	  var expires = "expires="+ d.toUTCString();
	  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}
	
	function getCookie(cname) {
	  var name = cname + "=";
	  var ca = document.cookie.split(';');
	  for(var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
		  c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
		  return c.substring(name.length, c.length);
		}
	  }
	  return "";
	}
	
	
	useEffect(() => {
			const validateJWT =()=>{
		
			if(getCookie("access_token") != "")
			{
				var object = {};
				object.token = getCookie("access_token");
			
				var headers = {
				  headers: {
					"Content-Type": "application/json",
					"Accept": "*/*"
				  }
				}
				axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`,object, headers).then((res) => {
				
					if(res.data.operation == "success")
					{
						$.get('https://www.cloudflare.com/cdn-cgi/trace', function(data) {
						  data = data.trim().split('\n').reduce(function(obj, pair) {
							pair = pair.split('=');
							return obj[pair[0]] = pair[1], obj;
						  }, {});
						  //loadCurrency2(data.loc);
						  
						});
						
						
						var user_object = {};
						user_object.email = res.data.email;
						user_object.name = res.data.name;
						user_object.img = res.data.img;
						user_object.phone = res.data.phone;
						setUser(user_object)
						setUsername(res.data.name)
						//setstate(1)
					}
					else
					{
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
				.catch(function (error) {
					swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
				});
				
				axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`,object, headers).then((res) => {
				
					if(res.data.operation == "success")
					{
						var token = res.data.audience_license;
						if (res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
						else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1)
										{
											temp = 1;
                                            setvisibility(1);
											
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
											
                                            break;
                                        }
                                        else
										{
                                            setredirect(1);
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }

                            }
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
						if(!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi"))
						{
							setCookie('access_token', res.data.audience_license, 1)
							setTimer(process.env.REACT_APP_JWT_EXPIRY);
							//setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
						}
						else
						{
							setTimer(res.data.audience_count)
							//setClockTimer(res.data.audience_count)
						}
					}
					else
					{
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
				.catch(function (error) {
					console.log(error);
					swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
				});
			}
			else
			{
				swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
			}
		}
		
		validateJWT();
	},[])
	
	useEffect(() => {
		if(timer > 0)
		{
			const timer_decrease = setInterval(() => {
			  setTimer(timer - 1);
			  if(timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE)
			  {
				if(!document.getElementById("kt_modal_timer").classList.contains("show"))
				{
					setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
					window.$('#kt_modal_timer').modal('show');
				}
			  }
			}, 1000);
			
			return () => clearInterval(timer_decrease);
		}
	}, [timer]);
	
	useEffect(() => {
		async function getuserbankdetails() {
				
			var object = {};
			object.email = user.email;
			
			const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-user-individual-bank-details`, { method: 'POST', body : JSON.stringify({"id" :props.match.params.id,"page_name":page_name}) , headers: {"Content-type": "application/json; charset=UTF-8", "token" : getCookie("access_token") }}
				);
				const body = await response.json();
				
				setstate(1)
				if(body.length>0)
				{
					setaccount_no(body[0].account_number)
					setbank_name(body[0].bank_name)
					setbranch_name(body[0].branch_name)
					setbranch_code(body[0].branch_code)
					setphoneno(body[0].account_number)
					setmobileno(body[0].account_number)
					setshort_name(body[0].short_name)
					setifsc(body[0].ifsc_code)
					setfaxno(body[0].fax_number)
					setemail(body[0].email_id)
					setnotes(body[0].notes)
					setwebsite(body[0].website)
					setcontact_person(body[0].contact_person)
					setaddress_line1(body[0].address)
					let facilities=[];
					facilities=body[0].facilities.split(",")
					for(let i=0;i<facilities.length;i++)
					{
						if(facilities[i]=="Cheque")
						{
							document.getElementById("Cheque").checked = "checked";
						}
						if(facilities[i]=="Fund Transfer")
						{
							document.getElementById("Fund Transfer").checked = "checked";
						}
						if(facilities[i]=="Internet Banking")
						{
							document.getElementById("Internet Banking").checked = "checked";
						}
					}
				}
				
				
		}
		if(read || write)
        {
            (user.email && visibility) && getuserbankdetails();
        }

		
	},[user, visibility, read, write])

	
	const add_bank_details = async (e)=>{
			e.preventDefault();
			
			var object = {};
			object.id = props.match.params.id;
			object.page_name = page_name;
			//object.organization_id = organization_id;
			object.account_no = document.getElementById("account_no").value;
			object.bank_name = document.getElementById("bank_name").value;
			object.branch_code = document.getElementById("branch_code").value;
			object.branch_name = document.getElementById("branch_name").value;
			object.email = document.getElementById("email").value;
			object.mobileno = document.getElementById("mobileno").value;
			object.phone = document.getElementById("phoneno").value;
			object.faxno = document.getElementById("faxno").value;
			object.address_line1 = document.getElementById("address_line1").value;
			object.contact_person = document.getElementById("contact_person").value;
			object.website = document.getElementById("website").value;
			object.ifsc = document.getElementById("ifsc").value;
			object.short_name = document.getElementById("short_name").value;
			object.notes = document.getElementById("notes").value;
			object.facilities = [];
			var facilities_flag = 0;
			var ele = document.getElementsByName('platform[]');
			var i = 0;
			for(i = 0; i < ele.length; i++) 
			{
				if(ele[i].checked)
				{
					facilities_flag = 1;
					object.facilities.push(ele[i].value);
				}
			}
			

			if(object.account_no=="")
			{
				swal("Warning!", "Provide a account number", "warning");
				return false;
			}
			
			if(object.bank_name=="")
			{
				swal("Warning!", "Provide a bank name", "warning");
				return false;
			}
			
			if(object.branch_code=="")
			{
				swal("Warning!", "Provide a branch code", "warning");
				return false;
			}
			
			if(object.branch_name=="")
			{
				swal("Warning!", "Provide a branch name", "warning");
				return false;
			}
			
			
			var exp = /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
			if (object.email == "") {
				swal("Oops!", "Please enter a valid email id", "error");
				return false;
			}
			else if (!object.email.match(exp)) {
				swal("Oops!", "Please enter a valid email id", "error");
				return false;
			}
			
			if(object.phone=="")
			{
				swal("Warning!", "Provide a phone", "warning");
				return false;
			}
			
			
			if(object.faxno=="")
			{
				swal("Warning!", "Provide a fax number", "warning");
				return false;
			}
			
			
			if(object.mobileno=="")
			{
				swal("Warning!", "Provide a mobile number", "warning");
				return false;
			}
			
			
			if(object.address_line1=="")
			{
				swal("Warning!", "Please fill the Address", "warning");
				return false;
			}
			if(object.ifsc=="")
			{
				swal("Warning!", "Please fill the Ifsc code", "warning");
				return false;
			}
			if(object.contact_person=="")
			{
				swal("Warning!", "Please fill the Contact person", "warning");
				return false;
			}
			if(object.website=="")
			{
				swal("Warning!", "Please fill the Website", "warning");
				return false;
			}
			if(object.short_name=="")
			{
				swal("Warning!", "Please fill the Short name", "warning");
				return false;
			}
			if(object.notes=="")
			{
				swal("Warning!", "Please fill the Notes", "warning");
				return false;
			}
			var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
			if (!regexp.test(object.website))
			{
				swal("Warning!", "Please enter valid Url", "warning");
				return false;
			}
	
			if(facilities_flag == 0)
			{
				swal("Oops!", "please select atleast one facilities", "error");
				return false;
			}
			if(document.getElementById("kt_careers_submit_button"))
			{
				document.getElementById("kt_careers_submit_button").setAttribute("data-kt-indicator", "on");
				document.getElementById("kt_careers_submit_button").disabled = !0;
			}
			

			var headers = {
			  headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token" : getCookie("access_token")
			  }
			}
			
			//console.log(object);
			
			axios.post(`${process.env.REACT_APP_SERVER_URL}/update-user-bank-details`,object, headers).then((res) => {
				if(res.data.operation == "success")
				{
					swal("Great!","Bank Details are succesfully updated", "success").then((res) => {
						window.location.reload();
					});
				}
				else
				{
					if(res.data.message != "danger")
					{
						swal("Oops!", res.data.message, "error").then((res) => {
							window.location.reload();
						});
					}
					else
					{
						swal("Oops!", "something went wrong", "error").then((res) => {
							window.location.reload();
						});
					}
					
				}
			})
			.catch(function (error) {
				//swal("Oops!", "something went wrong", "error").then((res) => {
				//	window.location.reload();
				//});
			});
			
	}

	return (
		<>
					{
						(redirect == 1 ||(read == 0 && write == 0)) ? 
							<>
								<Redirect to={{
									pathname: "/unauthorized",
								}}
								/>
							</> :
							null
					}
					
					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
						<div className="toolbar" id="kt_toolbar">
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
								<div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
									<h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Update Bank Details
									<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
									<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
									</h1>
									
								</div>
								
							</div>
						</div>
						
						
						<div className="post d-flex flex-column-fluid" id="kt_post">
                    		<div className="container" id="kt_content_container">
                        		<div className="card  pt-0 pb-15 px-3 px-xl-10">
                            	<br />
						
						{state==1  ?
						<>
										
						<div className="d-flex flex-column flex-lg-row mb-17">
							
							<div className="flex-lg-row-fluid me-0 me-lg-20">
								
								<form className="form mb-15" id="kt_careers_form" onSubmit={(e) => { add_bank_details(e); }}>
									
									<div className="row mb-5">
										
										<div className="col-md-6 fv-row">
											
											<label className="required fs-5 fw-bold mb-2">Account No.</label>
											
											
											<input type="text" className="form-control form-control-solid" placeholder="" onChange={(e) => { setaccount_no(e.target.value) }} name="account_no" id="account_no" value={account_no}/>
											
										</div>
										
										
										<div className="col-md-6 fv-row">
											
											<label className="required fs-5 fw-bold mb-2">Name of the Bank</label>
											
											
											<input type="text" className="form-control form-control-solid" onChange={(e) => {setbank_name(e.target.value) }}  placeholder="" name="bank_name" id="bank_name" value={bank_name} />
											
										</div>
										
									</div>
									
									
									<div className="row mb-5">
										
										<div className="col-md-6 fv-row">
											
											<label className="required fs-5 fw-bold mb-2">Branch Name</label>
											
											
											<input className="form-control form-control-solid" onChange={(e) => {setbranch_name(e.target.value) }} placeholder="" name="branch_name" id="branch_name" value={branch_name}/>
											
										</div>
										
										<div className="col-md-6 fv-row">
											
											<label className="required fs-5 fw-bold mb-2">Branch Code</label>
											
											
											<input type="text" className="form-control form-control-solid" onChange={(e) => {setbranch_code(e.target.value) }} placeholder="" name="branch_code" id="branch_code" value={branch_code}/>
											
										</div>
										
										
									</div>
									
									
									<div className="row mb-5">
										
										<div className="col-md-6 fv-row">
											
											<label className="required fs-5 fw-bold mb-2">IFSC Code</label>
											
											
											<input type="text" className="form-control form-control-solid" onChange={(e) => {setifsc(e.target.value) }} placeholder="" name="ifsc" id="ifsc" value={ifsc}/>
											
										</div>
										
										<div className="col-md-6 fv-row">
											
											<label className="required fs-5 fw-bold mb-2">Short Name</label>
											
											
											<input type="text" className="form-control form-control-solid" onChange={(e) => {setshort_name(e.target.value) }} placeholder="" name="short_name" id="short_name" value={short_name}/>
											
										</div>
										
									</div>
									<div className="row mb-5">
										
										<div className="col-md-6 fv-row">
											
											<label className="required fs-5 fw-bold mb-2">Mobile No.</label>
											
											
											<input type="text" className="form-control form-control-solid" onChange={(e) => {setmobileno(e.target.value) }} placeholder="" name="mobileno" id="mobileno" value={mobileno}/>
											
										</div>
										
										<div className="col-md-6 fv-row">
											
											<label className="required fs-5 fw-bold mb-2">Fax No.</label>
											
											
											<input type="text" className="form-control form-control-solid" onChange={(e) => {setfaxno(e.target.value) }} placeholder="" name="faxno" id="faxno" value={faxno}/>
											
										</div>
										
									</div>
									<div className="row mb-5">
										
										<div className="col-md-6 fv-row">
											
											<label className="required fs-5 fw-bold mb-2">Email Id</label>
											
											
											<input type="text" className="form-control form-control-solid" onChange={(e) => {setemail(e.target.value) }} placeholder="" name="email" id="email" value={email}/>
											
										</div>
										
										<div className="col-md-6 fv-row">
											
											<label className="required fs-5 fw-bold mb-2">Phone no.</label>
											
											
											<input type="text" className="form-control form-control-solid" onChange={(e) => {setphoneno(e.target.value) }} placeholder="" name="phoneno" id="phoneno" value={phoneno}/>
											
										</div>
										
									</div>
									<div className="row mb-5">
										
										<div className="col-md-12 fv-row">
											
											<label className="required fs-5 fw-bold mb-2">Address</label>
											
											
											<textarea className="form-control form-control-solid"  onChange={(e) => {setaddress_line1(e.target.value) }} placeholder="" name="address_line1" id="address_line1" value={address_line1}/>
											
										</div>
										
									</div>
									<div className="row mb-5">
										
										<div className="col-md-6 fv-row">
											
											<label className="required fs-5 fw-bold mb-2">Contact Person</label>
											
											
											<input type="text" className="form-control form-control-solid" onChange={(e) => {setcontact_person(e.target.value) }} placeholder="" name="contact_person" id="contact_person" value={contact_person} />
											
										</div>
										
										<div className="col-md-6 fv-row">
											
											<label className="required fs-5 fw-bold mb-2">Website</label>
											
											
											<input type="text" className="form-control form-control-solid" onChange={(e) => {setwebsite(e.target.value) }} placeholder="" name="website" id="website" value={website}/>
											
										</div>
										
									</div>
									<div className="mb-10 fv-row">
											
											<label className="required fs-5 fw-bold mb-2">Notes</label>
											
											
											<input type="text" className="form-control form-control-solid" onChange={(e) => {setnotes(e.target.value) }} placeholder="" name="notes" id="notes" value={notes}/>
											
										</div>
									<div className="mb-10 fv-row">
											
											<label className="d-flex align-items-center form-label mb-3">Facilities
											<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Select Facilities for banking"></i></label>
											
											<div className="row mb-2" data-kt-buttons="true">
														<div className="col">
															
															<div className="form-check form-check-custom form-check-solid me-9">
																<input className="form-check-input" type="checkbox" value="Cheque" name="platform[]" id="Cheque" />
																<label className="form-check-label ms-3" >Cheque</label>
															</div>
														</div>
														<div className="col">
															
															<div className="form-check form-check-custom form-check-solid me-9">
																<input className="form-check-input" type="checkbox" value="Fund Transfer" name="platform[]" id="Fund Transfer" />
																<label className="form-check-label ms-3" >Fund Transfer</label>
															</div>
														</div>
														<div className="col">
															
															<div className="form-check form-check-custom form-check-solid me-9">
																<input className="form-check-input" type="checkbox" value="Internet Banking" name="platform[]" id="Internet Banking" />
																<label className="form-check-label ms-3" >Internet Banking</label>
															</div>
														</div>
												
											</div>
												
										</div>
									
									
									<div className="separator mb-8"></div>
									
									{write == 1 ?
									<button type="submit" className="btn btn-primary rounded" id="kt_careers_submit_button" >
										
										<span className="indicator-label">Save & Proceed</span>
										<span className="indicator-progress">Please wait...
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
										
										
									</button>
									:
									<button type="submit" className="btn btn-primary rounded" id="kt_careers_submit_button" disabled >
										
										<span className="indicator-label">Save & Proceed</span>
										<span className="indicator-progress">Please wait...
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
										
										
									</button>
									}
									
								</form>
								
								
							</div>	
							
						</div>
						</>
						
						:
						
						<>
						<img src="/images/loader-06.svg" alt="" style={loaderstyle} />

						<div className="flex-lg-row-fluid me-lg-15">
							<div className="card card-flush pt-3 mb-5 mb-lg-10">

							</div>
						</div>
						</>
						}
								
								
								
						
						<br />
						</div>
						</div>
						</div>
					</div>
					
					
						
								<div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">
									
									<div className="modal-dialog modal-dialog-centered mw-650px mh-950px">
										
										<div className="modal-content">
										
										<div className="modal-header" id="kt_modal_new_address_header">
												
													<h2>Session Out</h2>
													
													<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
														
														<span className="svg-icon svg-icon-1">
															<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
																	<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
																	<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
																</g>
															</svg>
														</span>
														
													</div>
													
												</div>
												
												<div className="modal-body py-10 px-lg-17">
													
													<div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
														
														<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">
															
															<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
																<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																	<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
																	<rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
																	<rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
																</svg>
															</span>
															
															
															<div className="d-flex flex-stack flex-grow-1">
																
																<div className="fw-bold">
																	<h4 className="text-gray-800 fw-bolder">Warning</h4>
																	<div className="fs-6 text-gray-600">Your session is going to time out</div>
																</div>
																
															</div>
															
														</div>
														
														{clock_timer > 0 &&
														<div className="d-flex mb-9 p-6" style = {{"justify-content" : "center", "height" : "150px"}}>
															<CountdownCircleTimer
															  isPlaying
															  duration={clock_timer}
															  colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
															  onComplete={() => [true, 1000]}
															>
															  {renderTime}
															</CountdownCircleTimer>
														</div>
														}
														
													</div>
													
												</div>
												
												<div className="modal-footer flex-center">
													
													<button type="button" onClick={(e) => {window.location.reload()}}  className="btn btn-primary">
														<span className="indicator-label">Stay Signed in</span>
														<span className="indicator-progress">Please wait...
														<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
													</button>
													
												</div>
											
										</div>
									</div>
								</div>
					
					
		</>
	);
}
export default UserBankDetails;