import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom'
import {Modal} from "react-bootstrap";
import html2canvas from 'html2canvas';

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

// import uuid from 'react-uuid';
// import moment from 'moment';
// import Dropzone from 'react-dropzone-uploader';
// import 'react-dropzone-uploader/dist/styles.css'
// import Select, {components} from 'react-select';
// import removeImg from './icons/remove.svg'
// import ReactTagInput from "@pathofdev/react-tag-input"; 
// import "@pathofdev/react-tag-input/build/index.css";
// import currency_arr from './currency-arr.js' ;
// import ReactDOM from 'react-dom';
// import $ from 'jquery';


function TemplateInvoice(props) {
    const [user, setUser] = useState({});
    const [organization_id,setOrganization_id] = useState("")
    const [organization_list, setorganization_list] = useState([]);
    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);
    const [state, setstate] = useState(2);

    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
	const [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);

    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
    
    const [allTemplates, setAllTemplates] = useState([])
    const [organizationTemplates, setOrganizationTemplates] = useState([])

    const [defaultTemplateSubmitButtonState, setDefaultTemplateSubmitButtonState] = useState(false)

    const [templateModalShow, setTemplateModalShow] = useState(false)
    const [selectedTemplateId, setSelectedTemplateId] = useState(null)
    const [templateSubmitButtonState, setTemplateSubmitButtonState] = useState(false)

    const [templateEditModalShow, setTemplateEditModalShow] = useState(false)
    const [templateEditId, setTemplateEditId] = useState("")
    const [templateCustomObj, setTemplateCustomObj] = useState({"invoice": true, "customer_name": true, "order_number": true, "issue_date": true, "due_date": true, "terms": true, "sales_person": true, "invoice_currency": true, "subject": true, "customer_notes": true, "terms_and_conditions": true })
    const [templateEditHtmlFunc, setTemplateEditHtmlFunc] = useState(null)
    const [templatePreviewCanvas, setTemplatePreviewCanvas] = useState(null)
    const [templateEditSubmitButtonState, setTemplateEditSubmitButtonState] = useState(false)

	const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);
	
    const elemRef = useRef(null)

	var page_name = "template-invoice";

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }
	
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true);
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    setRedirect_to_login(1);
                });
                
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if(temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else
                                    {
                                        temp = 2;
                                    }
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
							
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setRedirect_to_login(1);
                });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }

                // console.log(timer)
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);





    async function getAllTemplates() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-all-templates`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setAllTemplates(body)
        }
    }
    async function getOrganizationTemplates() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-organization-templates`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setOrganizationTemplates(body.filter(x=>x.template_category=="invoice"))
        }
    }

    useEffect(() => {
		if ((read || write) && (user.email && visibility)) {
    
			let p1 = getAllTemplates()
            let p2 = getOrganizationTemplates()
            
            Promise.all([p1,p2])
            .then(()=>{
                setstate(1)       
                console.log("all apis done")
            })
            .catch((err)=>{
                setstate(3)
                console.log(err)
            })
		}

	}, [user, visibility, read, write])

    const submitOrganizationDefaultTemplate = (id) => {

        let object = {};

        object.id = id
        object.template_category = "invoice"

        object.organization_id = organization_id;
        object.page_name = page_name;

        console.log(object);
        setDefaultTemplateSubmitButtonState(true)

        let headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/set-organization-default-template`, object, headers).then((res) => {
            setDefaultTemplateSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Default Template Updated successfully!", "success")
                getOrganizationTemplates()
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const newOrganizationInvoiceTemplate = () => {

        if(selectedTemplateId == null){
            swal("Oops!", "Select a template first", "error");
            return
        }

        var object = {};

        object.template_id = selectedTemplateId

        object.organization_id = organization_id;
        object.page_name = page_name;

        console.log(object);
        setTemplateSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-organization-template`, object, headers).then((res) => {
            setTemplateSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Template added successfully!", "success")

                handleTemplateModalClose()
                getOrganizationTemplates()
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const handleTemplateModalClose = () => {
        setTemplateModalShow(false)
        setSelectedTemplateId(null)
    }

    const templateEditInit = async (id) => {
        setTemplateEditId(id)
        let t = organizationTemplates.find(x=>x.id==id)
        t.customized=="yes" && setTemplateCustomObj(JSON.parse(t.customized_changes))

        let mt = allTemplates.find(x=>x.template_id==t.template_id) 
        let getTemplateFunc = require("./templates/invoice/"+mt.template_file_name).getTemplate
        setTemplateEditHtmlFunc(getTemplateFunc)

        let invoiceData = {
            invoice_name: "Invoice Name",
            customer_name: "Customer Name",
            order_number: "Order No.",
            invoice_date: "1/1/2022",
            due_date: "12/1/2022",
            term: 40,
            sales_person: "Sales Person Name",
            invoice_currency: "USD",
            invoice_currency_symbol: "$",
            subject: "Invoice Subject",
            item_array: [],
            adjustment: {text:"Adjustmnet text", value: 0},
            tax: null,
            grand_total: 0,
            customer_notes: "Customer Notes",
            terms_and_conditions: "Terms and Cnnditions"
        }

        let html = getTemplateFunc(invoiceData,"yes", JSON.stringify(templateCustomObj))
        elemRef.current.innerHTML = html

        let t2 = await html2canvas(elemRef.current)
        setTemplatePreviewCanvas(t2.toDataURL())

        setTemplateEditModalShow(true)
    }

    useEffect(async() => {
        if(templateEditHtmlFunc != null){

            let invoiceData = {
                invoice_name: "Invoice Name",
                customer_name: "Customer Name",
                order_number: "Order No.",
                invoice_date: "1/1/2022",
                due_date: "12/1/2022",
                term: 40,
                sales_person: "Sales Person Name",
                invoice_currency: "USD",
                invoice_currency_symbol: "$",
                subject: "Invoice Subject",
                item_array: [],
                adjustment: {text:"Adjustmnet text", value: 0},
                tax: null,
                grand_total: 0,
                customer_notes: "Customer Notes",
                terms_and_conditions: "Terms and Cnnditions"
            }

            let html = templateEditHtmlFunc(invoiceData,"yes", JSON.stringify(templateCustomObj))
            elemRef.current.innerHTML = html

            let t2 = await html2canvas(elemRef.current)
            setTemplatePreviewCanvas(t2.toDataURL())
        }
    }, [templateEditHtmlFunc, templateCustomObj])
    

    const updateOrganizationInvoiceTemplate = () => {

        // if(selectedTemplateId == null){
        //     swal("Oops!", "Select a template first", "error");
        //     return
        // }

        // var object = {};

        // object.template_id = selectedTemplateId

        // object.organization_id = organization_id;
        // object.page_name = page_name;

        // console.log(object);
        // setTemplateSubmitButtonState(true)

        // var headers = {
        //     headers: {
        //         "Content-Type": "application/json",
        //         "Accept": "*/*",
        //         "token": getCookie("access_token")
        //     }
        // }
        // axios.post(`${process.env.REACT_APP_SERVER_URL}/add-organization-template`, object, headers).then((res) => {
        //     setTemplateSubmitButtonState(false)
        //     if (res.data.operation === "success") {
        //         swal("Great!", "Template added successfully!", "success")

        //         handleTemplateModalClose()
        //         getOrganizationTemplates()
        //     }
        //     else {
        //         swal("Oops!", "Something went wrong!", "error");
        //     }
        // })
        // .catch(function (error) {
        //     console.log(error)
        // });
    }

    const handleTemplateEditModalClose = () => {
        setTemplateEditModalShow(false)
        setTemplateEditId("")
        setTemplateCustomObj({"invoice": true, "customer_name": true, "order_number": true, "issue_date": true, "due_date": true, "terms": true, "sales_person": true, "invoice_currency": true, "subject": true, "customer_notes": true, "terms_and_conditions": true })
        setTemplatePreviewCanvas(null)
    }

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    return ( 
        <>
			{
               (redirect == 1 || (read == 0 && write == 0)) ? 
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> :
                null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}

            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Invoice Templates</h1>
                        <div class="d-flex align-items-center gap-2 gap-lg-3">
                            <button className='btn btn-success rounded' data-kt-indicator={state!=1?"on":"off"} disabled={state!=1} onClick={()=>{setTemplateModalShow(true)}}>
                                <span className="indicator-label">+ New Template</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            state == 1?
                            <>                            
                                <div className="card">
                                    <div className="card-body">

                                        <div className='container px-1' style={{overflowX: "scroll"}}>
                                            <div className='d-flex justify-content-start' style={{minWidth: "max-content"}}>
                                                {
                                                    organizationTemplates.map((obj,indx)=>{
                                                        return(
                                                            <div className='mx-2'>
                                                                <div className='bg-secondary rounded mx-3' style={{width: "300px", height: "430px", position: "relative"}}>
                                                                    {
                                                                        obj.default_template=="yes" && 
                                                                        <div style={{transform: "rotate(180deg)", position: "absolute", bottom: "-10px", left: "0px"}}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="90px" height="96px">
                                                                                <path fill="#eb6773" d="M37,6c0-1.105-0.895-2-2-2H13c-1.105,0-2,0.895-2,2v4h26V6z"/>
                                                                                <path fill="#b31523" d="M11,41.72c0,0.996,1.092,1.606,1.94,1.084L24,35.998l11.06,6.806C35.908,43.326,37,42.716,37,41.72 V30H11V41.72z"/>
                                                                                <rect width="26" height="12" x="11" y="18" fill="#cf1928"/>
                                                                                <rect width="26" height="8" x="11" y="10" fill="#d9414f"/>
                                                                            </svg>
                                                                        </div>
                                                                    }
                                                                    <img className='w-100' src={`template_thumb/invoice/${allTemplates.find(x=>x.template_id==obj.template_id).template_thumbnail_name}`} />
                                                                </div>
                                                                <div className='text-center mt-5'>
                                                                    <button className='btn btn-primary btn-sm mx-2' onClick={()=>{templateEditInit(obj.id)}}>Edit</button>
                                                                    {
                                                                        obj.default_template!="yes" && 
                                                                        <button className="btn btn-sm btn-success rounded" data-kt-indicator={defaultTemplateSubmitButtonState?"on":"off"} disabled={defaultTemplateSubmitButtonState} onClick={()=>{submitOrganizationDefaultTemplate(obj.id)}}>
                                                                            <span className="indicator-label">Set as Default</span>
                                                                            <span className="indicator-progress">Please wait...
                                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                            </span>
                                                                        </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div ref={elemRef}>Test</div>
                            </>
                            :
                            state == 2 ?
                            <div className="card">
                                <div className="card-body pt-6">
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                </div>
                            </div>
                            :
                            <div className="card">
                                <div className="card-body">
                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                        <div className="d-flex flex-stack flex-grow-1">
                                            <div className="fw-bold">
                                                <h4 className="text-gray-800 fw-bolder">Something went wrong. Please reload this page.</h4>
                                                <div className="fs-6 text-gray-600"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

            </div>

            <Modal show={templateModalShow} onHide={()=>{handleTemplateModalClose()}} size="xl" centered>
                <Modal.Header>
                    <Modal.Title>Choose new Template</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{handleTemplateModalClose()}}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container px-1' style={{overflowX: "scroll"}}>
                        <div className='d-flex justify-content-start' style={{minWidth: "max-content"}}>
                            {
                                
                                allTemplates.map((obj,indx)=>{
                                    return(
                                        <div className='bg-secondary rounded mx-3 d-flex justify-content-center align-items-center' style={{width: "300px", height: "430px", position: "relative", transition: "border 0.2s", border: obj.template_id == selectedTemplateId?"5px double #f1416c": "none"}} onClick={()=>{setSelectedTemplateId(obj.template_id)}}>
                                            {
                                                obj.default_template=="yes" && 
                                                <div style={{transform: "rotate(180deg)", position: "absolute", bottom: "-11px", left: "0px"}}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="90px" height="96px">
                                                        <path fill="#eb6773" d="M37,6c0-1.105-0.895-2-2-2H13c-1.105,0-2,0.895-2,2v4h26V6z"/>
                                                        <path fill="#b31523" d="M11,41.72c0,0.996,1.092,1.606,1.94,1.084L24,35.998l11.06,6.806C35.908,43.326,37,42.716,37,41.72 V30H11V41.72z"/>
                                                        <rect width="26" height="12" x="11" y="18" fill="#cf1928"/>
                                                        <rect width="26" height="8" x="11" y="10" fill="#d9414f"/>
                                                    </svg>
                                                </div>
                                            }
                                            <img className='w-100' src={`template_thumb/invoice/${obj.template_thumbnail_name}`} />
                                        </div>            
                                    )
                                })
                            }           
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    {
                        write == 1 &&
                        <button className="btn btn-success rounded" data-kt-indicator={templateSubmitButtonState?"on":"off"} disabled={templateSubmitButtonState} onClick={()=>{newOrganizationInvoiceTemplate()}}>
                            <span className="indicator-label">+ Add</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                    <button className="btn btn-danger rounded" onClick={()=>{handleTemplateModalClose()}}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={templateEditModalShow} onHide={()=>{handleTemplateEditModalClose()}} size={"xl"} centered>
                <Modal.Header>
                    <Modal.Title>Edit Template</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{handleTemplateEditModalClose()}}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container d-flex'>
                        <div className="container">
                            <div className="form-check form-check-custom form-check-solid my-2" onClick={(e)=>{setTemplateCustomObj({...templateCustomObj, invoice: e.target.checked}) }}>
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault1" checked={templateCustomObj.invoice} />
                                <label className="form-check-label" for="flexCheckDefault1">Invoice</label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid my-2" onClick={(e)=>{setTemplateCustomObj({...templateCustomObj, customer_name: e.target.checked}) }}>
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault2" checked={templateCustomObj.customer_name} />
                                <label className="form-check-label" for="flexCheckDefault2">Customer Name</label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid my-2" onClick={(e)=>{setTemplateCustomObj({...templateCustomObj, order_number: e.target.checked}) }}>
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault3" checked={templateCustomObj.order_number} />
                                <label className="form-check-label" for="flexCheckDefault3">Order Number</label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid my-2" onClick={(e)=>{setTemplateCustomObj({...templateCustomObj, issue_date: e.target.checked}) }}>
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault4" checked={templateCustomObj.issue_date} />
                                <label className="form-check-label" for="flexCheckDefault4">Issue Date</label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid my-2" onClick={(e)=>{setTemplateCustomObj({...templateCustomObj, due_date: e.target.checked}) }}>
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault5" checked={templateCustomObj.due_date} />
                                <label className="form-check-label" for="flexCheckDefault5">Due Date</label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid my-2" onClick={(e)=>{setTemplateCustomObj({...templateCustomObj, terms: e.target.checked}) }}>
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault6" checked={templateCustomObj.terms} />
                                <label className="form-check-label" for="flexCheckDefault6">Terms</label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid my-2" onClick={(e)=>{setTemplateCustomObj({...templateCustomObj, sales_person: e.target.checked}) }}>
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault7" checked={templateCustomObj.sales_person} />
                                <label className="form-check-label" for="flexCheckDefault7">Sales Person</label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid my-2" onClick={(e)=>{setTemplateCustomObj({...templateCustomObj, invoice_currency: e.target.checked}) }}>
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault8" checked={templateCustomObj.invoice_currency} />
                                <label className="form-check-label" for="flexCheckDefault8">Invoice Currency</label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid my-2" onClick={(e)=>{setTemplateCustomObj({...templateCustomObj, subject: e.target.checked}) }}>
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault9" checked={templateCustomObj.subject} />
                                <label className="form-check-label" for="flexCheckDefault9">Subject</label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid my-2" onClick={(e)=>{setTemplateCustomObj({...templateCustomObj, customer_notes: e.target.checked}) }}>
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault10" checked={templateCustomObj.customer_notes} />
                                <label className="form-check-label" for="flexCheckDefault10">Customer Notes</label>
                            </div>
                            <div className="form-check form-check-custom form-check-solid my-2" onClick={(e)=>{setTemplateCustomObj({...templateCustomObj, terms_and_conditions: e.target.checked}) }}>
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault11" checked={templateCustomObj.terms_and_conditions} />
                                <label className="form-check-label" for="flexCheckDefault11">Terms and Conditions</label>
                            </div>
                        </div>
                        <div className="container">
                            <img src={templatePreviewCanvas} alt="loading_preview" width={"100%"}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    {
                        write == 1 &&
                        <button className="btn btn-success rounded" data-kt-indicator={templateEditSubmitButtonState?"on":"off"} disabled={templateEditSubmitButtonState} onClick={()=>{updateOrganizationInvoiceTemplate()}}>
                            <span className="indicator-label">Save Changes</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                    <button className="btn btn-danger rounded" onClick={()=>{handleTemplateEditModalClose()}}>Close</button>
                </Modal.Footer>
            </Modal>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org}/>
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer}/>
        </>
    );
}


export default TemplateInvoice;