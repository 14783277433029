import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {setCookie,getCookie} from '../common_component/cookie.js';


function AsideNavbar() {
	const [access, setaccess] = useState([]);
	const [selected, setSelected] = useState("");
	const [levelOne, setLevelOne] = useState("");
	const [levelTwo, setLevelTwo] = useState("");
	const [view, setview] = useState("");

	var page_name = "aside-navbar";

	useEffect(() => {
		//setCookie("lastpage", window.location.href, 1)
		const validateJWT = () => {

			if (getCookie("access_token") != "") {
				var object = {};
				object.token = getCookie("access_token");

				var headers = {
					headers: {
						"Content-Type": "application/json",
						"Accept": "*/*"
					}
				}
				axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var user_object = {};
						let organization_id="";

						if(getCookie("organization_id")!="")
						{
							organization_id=getCookie("organization_id");
							user_object.email = res.data.email;
							var headers = {
								headers: {
									"Content-Type": "application/json",
									"token": getCookie("access_token")
								}
							}
							var object1 = {};
							object1.email = res.data.email;
							object1.page_name = page_name;
							object1.organization_id = organization_id;
							let currentUrl = window.location.href;
							let original_url = currentUrl.split("/");
							original_url = (/\d/.test(original_url[original_url.length - 1])) ? original_url[original_url.length - 2] : original_url[original_url.length - 1];
							if(original_url=="")
							{
								original_url="dashboard";
							}
							let index1=0;
							axios.post(`${process.env.REACT_APP_SERVER_URL}/user-access-info`, object1, headers).then((res1) => {
								if(res1.data.operation=="success")
								{
									let temp = res1.data.data.access.map(x=>{return {...x}})
									temp = temp.map((obj)=>{
										if((obj.key == "account")||(obj.key == "subcategory")||(obj.key == "user")){
											obj.visibility = 0
										}
										return obj
									})
									setaccess(temp)

									// setaccess(res1.data.data.access);
									// for (let i = 0; i < res1.data.data.access.length; i++) {
									// 	let component = res1.data.data.access[i].inside_components;
									// 	for (let j = 0; j < component.length; j++) {
									// 		if (component[j].hasOwnProperty(original_url)) {
									// 			index1 = i;
									// 			setview(res1.data.data.access[index1].key);
									// 		}
									// 		else if(component[j].hasOwnProperty("key")){
									// 			let sub_inside_components= component[j].inside_components
									// 			for (let k = 0; k < sub_inside_components.length; k++) {
									// 				if(sub_inside_components[k].hasOwnProperty(original_url)){
									// 					index1 = i;
									// 					setview(res1.data.data.access[index1].key);
									// 				}
									// 			}
									// 		}
									// 	}
									// }
								}
								else
								{
									setaccess({});
								}
								
							})  
						}
						else
						{
							var object = {};
							object.page_name=page_name;
							var headers = {
								headers: {
									"Content-Type": "application/json",
									"token": getCookie("access_token"),
									"Accept": "*/*"
								}
							}

							axios.post(`${process.env.REACT_APP_SERVER_URL}/show-default-organization`, object, headers).then((res) => {

								if (res.data.length>0) {
									//console.log(res.data[0].organization_id)
									organization_id=res.data[0].organization_id;
									user_object.email = res.data.email;
									var headers = {
										headers: {
											"Content-Type": "application/json",
											"token": getCookie("access_token")
										}
									}
									var object1 = {};
									object1.email = res.data.email;
									object1.page_name = page_name;
									object1.organization_id = organization_id;
									axios.post(`${process.env.REACT_APP_SERVER_URL}/user-access-info`, object1, headers).then((res1) => {
										let temp = res1.data.data.access.map(x=>{return {...x}})
										temp = temp.map((obj)=>{
											if((obj.key == "account")||(obj.key == "subcategory")||(obj.key == "user")){
												obj.visibility = 0
											}
											return obj
										})
										setaccess(temp)
										// setaccess(res1.data.data.access)
									})
								}
							
							})    
						}	
					}

				})

			}
		}
		validateJWT();
	}, [])

	let optionMap = {
		"/show-organizations": [],
		"/organization-profile": [],
		"/add-profile": [],
		"/profile-list": ["update-profile","view-profiles"],
		"/add-roles": [],
		"/roles": ["update-roles","view-roles"],
		"/payment-portal-config":[],

		"/add-customer": [],
		"/customer-list": ["/edit-customer-details","/view-customer-details"],

		"/template-invoice": [],
								
		"/add-invoice": [],
		"/add-invoice/:id": [],
		"/invoice-list": ["/edit-invoice-details","/view-invoice-details"],
								
		"/add-project": [],
		"/project-list": [],

		"/add-expenses": [],
		"/add-bulk-expenses": [],
		"/expenses-list": [],

		"/add-estimates": [],
		"/estimates-list": [],

		"/category-list": [],

		"/currency-list": [],

		"/tax-list": [],
		"/tax-group-list": [],

		"/product-entry": [],
		"/product-list": [],

		"none": ["/", "/dashboard", "/login"]
	}
	let not_show=["edit-customer-details","view-customer-details","customer-bank-details","edit-invoice-details","view-invoice-details","add-category","product-stock-information","product-additional-information","product-special-discount","product-price-information","make-group","product-images","product-video","discounted-product-list","update-product-serial","product-group-list","product-billing","billing-product-list","update-product-billing","update-profile","view-profiles","update-roles","view-roles"]

	useEffect(() => {
		let id = setInterval(() => {
			// console.log("id: "+id)
			let currentUrl = window.location.href
			let t = currentUrl.split("/")

			t = (/\d/.test(t[t.length - 1])) ? t[t.length - 2] : t[t.length - 1]

			let targetOption = undefined;
			Object.keys(optionMap).forEach((elem) => {
				if (optionMap[elem].includes("/" + t)) {
					targetOption = elem
				}
			})
			if (targetOption == undefined) {
				setSelected(t)
			}
			else {
				if (targetOption != "none") {
					setSelected(targetOption.replace("/",""))
				}
			}

		}, 500)
		return () => {
			clearInterval(id)
		}
	}, [])

	//Function for custom link as per item name in the json
	const customLink = (option) => {
		return `/${option}`
	}
	//Function to format Menu Header and submenu items 
	const formatString = (str) => {

		if (str.includes("_")) {
			//Removing "_" and formatting first letter of each word to Capital
			return str.split("_")[0].replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
		}
		else if (str.includes("account")) {
			//Removing "-" and formatting first letter of each word to Capital and showing last word of the string
			let allWords = str.replace(/-/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
			return allWords.split(" ").splice(-1).join(" ")
		}
		else if (str.includes("-")) {
			//Removing "-" and formatting first letter of each word to Capital and showing last 2 word of the string
			let allWords = str.replace(/-/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
			return allWords.split(" ").splice(-2).join(" ")
		}
		else {
			let allWords = str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
			return allWords.split(" ").splice(-2).join(" ")
		}
	}
	//Function to render custom icon as per Menu Title in the json
	const customIcon = (menuName) => {
		// console.log(menuName)
		if (menuName === "Admin View") {
			return (
				<span className="svg-icon svg-icon-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M6.5 11C8.98528 11 11 8.98528 11 6.5C11 4.01472 8.98528 2 6.5 2C4.01472 2 2 4.01472 2 6.5C2 8.98528 4.01472 11 6.5 11Z" fill="currentColor" />
						<path opacity="0.3" d="M13 6.5C13 4 15 2 17.5 2C20 2 22 4 22 6.5C22 9 20 11 17.5 11C15 11 13 9 13 6.5ZM6.5 22C9 22 11 20 11 17.5C11 15 9 13 6.5 13C4 13 2 15 2 17.5C2 20 4 22 6.5 22ZM17.5 22C20 22 22 20 22 17.5C22 15 20 13 17.5 13C15 13 13 15 13 17.5C13 20 15 22 17.5 22Z" fill="currentColor" />
					</svg>
				</span>
			)
		}
		else if(menuName === "customer")
		{
			return (
				<span class="svg-icon svg-icon-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M11.7 8L7.49998 15.3L4.59999 20.3C3.49999 18.4 3.1 17.7 2.3 16.3C1.9 15.7 1.9 14.9 2.3 14.3L8.8 3L11.7 8Z" fill="currentColor"></path>
						<path opacity="0.3" d="M11.7 8L8.79999 3H13.4C14.1 3 14.8 3.4 15.2 4L20.6 13.3H14.8L11.7 8ZM7.49997 15.2L4.59998 20.2H17.6C18.3 20.2 19 19.8 19.4 19.2C20.2 17.7 20.6 17 21.7 15.2H7.49997Z" fill="currentColor"></path>
					</svg>
				</span>
			)
		}
		else if(menuName === "templates")
		{
			return (
				<span class="svg-icon svg-icon-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M20 7H3C2.4 7 2 6.6 2 6V3C2 2.4 2.4 2 3 2H20C20.6 2 21 2.4 21 3V6C21 6.6 20.6 7 20 7ZM7 9H3C2.4 9 2 9.4 2 10V20C2 20.6 2.4 21 3 21H7C7.6 21 8 20.6 8 20V10C8 9.4 7.6 9 7 9Z" fill="currentColor"></path>
						<path opacity="0.3" d="M20 21H11C10.4 21 10 20.6 10 20V10C10 9.4 10.4 9 11 9H20C20.6 9 21 9.4 21 10V20C21 20.6 20.6 21 20 21Z" fill="currentColor"></path>
					</svg>
				</span>
			)
		}
		else if(menuName === "invoices")
		{
			return (
				<span class="svg-icon svg-icon-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M22 7H2V11H22V7Z" fill="currentColor"></path>
						<path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19ZM14 14C14 13.4 13.6 13 13 13H5C4.4 13 4 13.4 4 14C4 14.6 4.4 15 5 15H13C13.6 15 14 14.6 14 14ZM16 15.5C16 16.3 16.7 17 17.5 17H18.5C19.3 17 20 16.3 20 15.5C20 14.7 19.3 14 18.5 14H17.5C16.7 14 16 14.7 16 15.5Z" fill="currentColor"></path>
					</svg>
				</span>
			)
		}
		else if(menuName === "project")
		{
			return (
				<span class="svg-icon svg-icon-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M18 21.6C16.6 20.4 9.1 20.3 6.3 21.2C5.7 21.4 5.1 21.2 4.7 20.8L2 18C4.2 15.8 10.8 15.1 15.8 15.8C16.2 18.3 17 20.5 18 21.6ZM18.8 2.8C18.4 2.4 17.8 2.20001 17.2 2.40001C14.4 3.30001 6.9 3.2 5.5 2C6.8 3.3 7.4 5.5 7.7 7.7C9 7.9 10.3 8 11.7 8C15.8 8 19.8 7.2 21.5 5.5L18.8 2.8Z" fill="currentColor"></path>
						<path opacity="0.3" d="M21.2 17.3C21.4 17.9 21.2 18.5 20.8 18.9L18 21.6C15.8 19.4 15.1 12.8 15.8 7.8C18.3 7.4 20.4 6.70001 21.5 5.60001C20.4 7.00001 20.2 14.5 21.2 17.3ZM8 11.7C8 9 7.7 4.2 5.5 2L2.8 4.8C2.4 5.2 2.2 5.80001 2.4 6.40001C2.7 7.40001 3.00001 9.2 3.10001 11.7C3.10001 15.5 2.40001 17.6 2.10001 18C3.20001 16.9 5.3 16.2 7.8 15.8C8 14.2 8 12.7 8 11.7Z" fill="currentColor"></path>
					</svg>
				</span>
			)
		}
		else if(menuName === "expenses")
		{
			return (
				<span class="svg-icon svg-icon-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8.7 4.19995L4 6.30005V18.8999L8.7 16.8V19L3.1 21.5C2.6 21.7 2 21.4 2 20.8V6C2 5.4 2.3 4.89995 2.9 4.69995L8.7 2.09998V4.19995Z" fill="currentColor"></path>
						<path d="M15.3 19.8L20 17.6999V5.09992L15.3 7.19989V4.99994L20.9 2.49994C21.4 2.29994 22 2.59989 22 3.19989V17.9999C22 18.5999 21.7 19.1 21.1 19.3L15.3 21.8998V19.8Z" fill="currentColor"></path>
						<path opacity="0.3" d="M15.3 7.19995L20 5.09998V17.7L15.3 19.8V7.19995Z" fill="currentColor"></path>
						<path opacity="0.3" d="M8.70001 4.19995V2L15.4 5V7.19995L8.70001 4.19995ZM8.70001 16.8V19L15.4 22V19.8L8.70001 16.8Z" fill="currentColor"></path>
						<path opacity="0.3" d="M8.7 16.8L4 18.8999V6.30005L8.7 4.19995V16.8Z" fill="currentColor"></path>
					</svg>
				</span>
			)
		}
		else if(menuName === "estimates")
		{
			return (
				<span class="svg-icon svg-icon-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path opacity="0.3" d="M11.8 5.2L17.7 8.6V15.4L11.8 18.8L5.90001 15.4V8.6L11.8 5.2ZM11.8 2C11.5 2 11.2 2.1 11 2.2L3.8 6.4C3.3 6.7 3 7.3 3 7.9V16.2C3 16.8 3.3 17.4 3.8 17.7L11 21.9C11.3 22 11.5 22.1 11.8 22.1C12.1 22.1 12.4 22 12.6 21.9L19.8 17.7C20.3 17.4 20.6 16.8 20.6 16.2V7.9C20.6 7.3 20.3 6.7 19.8 6.4L12.6 2.2C12.4 2.1 12.1 2 11.8 2Z" fill="currentColor"></path>
						<path d="M11.8 8.69995L8.90001 10.3V13.7L11.8 15.3L14.7 13.7V10.3L11.8 8.69995Z" fill="currentColor"></path>
					</svg>
				</span>
			)
		}
		else if(menuName === "category")
		{
			return (
				<span className="svg-icon svg-icon-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path opacity="0.3" d="M7 20.5L2 17.6V11.8L7 8.90002L12 11.8V17.6L7 20.5ZM21 20.8V18.5L19 17.3L17 18.5V20.8L19 22L21 20.8Z" fill="currentColor"></path>
						<path d="M22 14.1V6L15 2L8 6V14.1L15 18.2L22 14.1Z" fill="currentColor"></path>
					</svg>
				</span>
			)
		}
		else if(menuName === "currency")
		{
			return (
				<span className="svg-icon svg-icon-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path opacity="0.3" d="M20.859 12.596L17.736 13.596L10.388 20.944C10.2915 21.0406 10.1769 21.1172 10.0508 21.1695C9.9247 21.2218 9.78953 21.2486 9.65302 21.2486C9.5165 21.2486 9.3813 21.2218 9.25519 21.1695C9.12907 21.1172 9.01449 21.0406 8.918 20.944L2.29999 14.3229C2.10543 14.1278 1.99619 13.8635 1.99619 13.588C1.99619 13.3124 2.10543 13.0481 2.29999 12.853L11.853 3.29999C11.9495 3.20341 12.0641 3.12679 12.1902 3.07452C12.3163 3.02225 12.4515 2.9953 12.588 2.9953C12.7245 2.9953 12.8597 3.02225 12.9858 3.07452C13.1119 3.12679 13.2265 3.20341 13.323 3.29999L21.199 11.176C21.3036 11.2791 21.3797 11.4075 21.4201 11.5486C21.4605 11.6898 21.4637 11.8391 21.4295 11.9819C21.3953 12.1247 21.3249 12.2562 21.2249 12.3638C21.125 12.4714 20.9989 12.5514 20.859 12.596Z" fill="currentColor"></path>
						<path d="M14.8 10.184C14.7447 10.1843 14.6895 10.1796 14.635 10.1699L5.816 8.69997C5.55436 8.65634 5.32077 8.51055 5.16661 8.29469C5.01246 8.07884 4.95035 7.8106 4.99397 7.54897C5.0376 7.28733 5.18339 7.05371 5.39925 6.89955C5.6151 6.7454 5.88334 6.68332 6.14498 6.72694L14.963 8.19692C15.2112 8.23733 15.435 8.36982 15.59 8.56789C15.7449 8.76596 15.8195 9.01502 15.7989 9.26564C15.7784 9.51626 15.6642 9.75001 15.479 9.92018C15.2939 10.0904 15.0514 10.1846 14.8 10.184ZM17 18.6229C17 19.0281 17.0985 19.4272 17.287 19.7859C17.4755 20.1446 17.7484 20.4521 18.0821 20.6819C18.4158 20.9117 18.8004 21.0571 19.2027 21.1052C19.605 21.1534 20.0131 21.103 20.3916 20.9585C20.7702 20.814 21.1079 20.5797 21.3758 20.2757C21.6437 19.9716 21.8336 19.607 21.9293 19.2133C22.025 18.8195 22.0235 18.4085 21.925 18.0154C21.8266 17.6223 21.634 17.259 21.364 16.9569L19.843 15.257C19.7999 15.2085 19.7471 15.1697 19.688 15.1432C19.6289 15.1167 19.5648 15.1029 19.5 15.1029C19.4352 15.1029 19.3711 15.1167 19.312 15.1432C19.2529 15.1697 19.2001 15.2085 19.157 15.257L17.636 16.9569C17.2254 17.4146 16.9988 18.0081 17 18.6229ZM10.388 20.9409L17.736 13.5929H1.99999C1.99921 13.7291 2.02532 13.8643 2.0768 13.9904C2.12828 14.1165 2.2041 14.2311 2.29997 14.3279L8.91399 20.9409C9.01055 21.0381 9.12539 21.1152 9.25188 21.1679C9.37836 21.2205 9.51399 21.2476 9.65099 21.2476C9.78798 21.2476 9.92361 21.2205 10.0501 21.1679C10.1766 21.1152 10.2914 21.0381 10.388 20.9409Z" fill="currentColor"></path>
					</svg>
				</span>
			)
		}
		else if(menuName === "tax")
		{
			return (
				<span className="svg-icon svg-icon-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M11.2929 2.70711C11.6834 2.31658 12.3166 2.31658 12.7071 2.70711L15.2929 5.29289C15.6834 5.68342 15.6834 6.31658 15.2929 6.70711L12.7071 9.29289C12.3166 9.68342 11.6834 9.68342 11.2929 9.29289L8.70711 6.70711C8.31658 6.31658 8.31658 5.68342 8.70711 5.29289L11.2929 2.70711Z" fill="currentColor"></path>
						<path d="M11.2929 14.7071C11.6834 14.3166 12.3166 14.3166 12.7071 14.7071L15.2929 17.2929C15.6834 17.6834 15.6834 18.3166 15.2929 18.7071L12.7071 21.2929C12.3166 21.6834 11.6834 21.6834 11.2929 21.2929L8.70711 18.7071C8.31658 18.3166 8.31658 17.6834 8.70711 17.2929L11.2929 14.7071Z" fill="currentColor"></path>
						<path opacity="0.3" d="M5.29289 8.70711C5.68342 8.31658 6.31658 8.31658 6.70711 8.70711L9.29289 11.2929C9.68342 11.6834 9.68342 12.3166 9.29289 12.7071L6.70711 15.2929C6.31658 15.6834 5.68342 15.6834 5.29289 15.2929L2.70711 12.7071C2.31658 12.3166 2.31658 11.6834 2.70711 11.2929L5.29289 8.70711Z" fill="currentColor"></path>
						<path opacity="0.3" d="M17.2929 8.70711C17.6834 8.31658 18.3166 8.31658 18.7071 8.70711L21.2929 11.2929C21.6834 11.6834 21.6834 12.3166 21.2929 12.7071L18.7071 15.2929C18.3166 15.6834 17.6834 15.6834 17.2929 15.2929L14.7071 12.7071C14.3166 12.3166 14.3166 11.6834 14.7071 11.2929L17.2929 8.70711Z" fill="currentColor"></path>
					</svg>
				</span>
			)
		}
		else if(menuName === "product")
		{
			return (
				<span className="svg-icon svg-icon-2">
					<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path opacity="0.3" d="M8.9 21L7.19999 22.6999C6.79999 23.0999 6.2 23.0999 5.8 22.6999L4.1 21H8.9ZM4 16.0999L2.3 17.8C1.9 18.2 1.9 18.7999 2.3 19.1999L4 20.9V16.0999ZM19.3 9.1999L15.8 5.6999C15.4 5.2999 14.8 5.2999 14.4 5.6999L9 11.0999V21L19.3 10.6999C19.7 10.2999 19.7 9.5999 19.3 9.1999Z" fill="currentColor"></path>
						<path d="M21 15V20C21 20.6 20.6 21 20 21H11.8L18.8 14H20C20.6 14 21 14.4 21 15ZM10 21V4C10 3.4 9.6 3 9 3H4C3.4 3 3 3.4 3 4V21C3 21.6 3.4 22 4 22H9C9.6 22 10 21.6 10 21ZM7.5 18.5C7.5 19.1 7.1 19.5 6.5 19.5C5.9 19.5 5.5 19.1 5.5 18.5C5.5 17.9 5.9 17.5 6.5 17.5C7.1 17.5 7.5 17.9 7.5 18.5Z" fill="currentColor"></path>
					</svg>
				</span>
			)
		}
		else
		{
			return (
				<span className="svg-icon svg-icon-2">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z" fill="currentColor"></path>
						<path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path>
					</svg>
				</span>
			)
		}
	}


	const handleLvOne = (title) => {
		// console.log(title)
		handleLvTwo("")
		if (title === levelOne) {
			setLevelOne("")
		}
		else {
			setLevelOne(title)
		}
	}
	const handleLvTwo = (title) => {
		// console.log(title)
		if (title === levelTwo) {
			setLevelTwo("")
		}
		else {
			setLevelTwo(title)
		}
	}

	useEffect(() => {
		if (access.length > 0) {
			access.map((obj) => {
				if (obj.hasOwnProperty("key")) {

					let tempArr2 = []
					obj.inside_components.forEach(x => {
						if (Object.keys(x)[0] === "key") {
							x.inside_components.forEach(y => tempArr2.push(Object.keys(y)[0]))
						}
						else {
							tempArr2.push(Object.keys(x)[0])
						}
					})

					if (tempArr2.includes(selected)) {
						handleLvOne(obj.key)
					}
				}
			})
			let tempArr2 = []
			access.forEach((obj) => {
				obj.inside_components.forEach(x => {
					if (Object.keys(x)[0] === "key") {
						tempArr2.push(x)
					}
				})
			})
			tempArr2.forEach((obj) => {
				let a = obj.inside_components.map(y => Object.keys(y)[0])
				if (a.includes(selected)) {
					handleLvTwo(obj.key)
				}
			})
		}

	}, [access])

	return (
		<>
			<div id="kt_app_sidebar" className="app-sidebar flex-column" data-kt-drawer="true" data-kt-drawer-name="app-sidebar" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="225px" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_app_sidebar_mobile_toggle">
				<div className="app-sidebar-logo px-6" id="kt_app_sidebar_logo">
					<a href="/dashboard">
						<img alt="Logo" src="https://cdn-adocrm.s3.amazonaws.com/static/ado-crm-logo.png" className="h-25px app-sidebar-logo-default" />
						<img alt="Logo" src="https://cdn-adocrm.s3.amazonaws.com/static/ado-crm-logo.png" className="h-20px app-sidebar-logo-minimize" />
					</a>
					
					<div id="kt_app_sidebar_toggle" className="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate" data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="app-sidebar-minimize">						
						<span className="svg-icon svg-icon-2 rotate-180">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path opacity="0.5" d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z" fill="currentColor" />
								<path d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z" fill="currentColor" />
							</svg>
						</span>
					</div>
				</div>
				
				<div className="app-sidebar-menu overflow-hidden flex-column-fluid">
					<div id="kt_app_sidebar_menu_wrapper" className="app-sidebar-wrapper hover-scroll-overlay-y my-5" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer" data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px" data-kt-scroll-save-state="true">
						<div className="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true" data-kt-menu-expand="false">
							{access.length > 0 && access.map((obj, index) => {
							let variable = false
							let tempArr = []
							obj.inside_components.forEach(x => {
								if (Object.keys(x)[0] === "key") {
									x.inside_components.forEach(y => tempArr.push(Object.keys(y)[0]))
								}
								else {
									tempArr.push(Object.keys(x)[0])
								}
							})

							if (tempArr.includes(selected)) {
								variable = true
							}
							return (
								<>
									{
										(obj.hasOwnProperty("key") && obj.visibility == 1) &&
										<>			
											<div data-kt-menu-trigger="click" class="menu-item menu-accordion">
												<span class="menu-link">
													<span class="menu-icon">{customIcon(obj.key)}</span>
													<span class="menu-title">{formatString(obj.key)}</span>
													<span class="menu-arrow"></span>
												</span>

												<div class="menu-sub menu-sub-accordion">
													{
														obj.inside_components.length > 0 && obj.inside_components.map((obj1, index1) => {
															return ( 
																<>
																	{
																		(obj1.hasOwnProperty("key")) ?
																		<>	
																			<div data-kt-menu-trigger="click" className={`menu-item menu-accordion ${(levelTwo === obj1.key) ? "hover show" : ""}`}>
																				<span className="menu-link" onClick={() => { handleLvTwo(obj1.key) }}>
																					<span className="menu-icon">{customIcon(obj1.key)}</span>
																					<span className="menu-title">{formatString(obj1.key)}</span>
																					<span className="menu-arrow"></span>
																				</span>
																				
																				
																				<div className="menu-sub menu-sub-accordion">
																					{obj1.inside_components.length > 0 && obj1.inside_components.map((obj2, index2) => {
																						return (
																							<>
																								{
																									(Object.values(obj2)[0] == 1 && !not_show.includes(Object.keys(obj2)[0])) &&
																									<div className="menu-item">
																										<Link className={`menu-link ${(selected === Object.keys(obj2)[0]) ? "active" : ""}`} to={customLink(Object.keys(obj2)[0])} onClick={() => { setSelected(Object.keys(obj2)[0]) }}>
																										<span className="menu-bullet">
																												<span className="bullet bullet-dot"></span>
																											</span>
																											<span className="menu-title">{(Object.keys(obj2)[0] === "add-category") ? "Add Cuisine" : ((Object.keys(obj2)[0] === "category-list") ? "Cuisine List" : ((Object.keys(obj2)[0] === "add-subcategory") ? "Add Course" : ((Object.keys(obj2)[0] === "subcategory-list") ? "Course List" : formatString(Object.keys(obj2)[0]))))}</span>
																										</Link>
																									</div>
																								}
																							</>
																						)
																					})}
																				</div>	
																			</div>
																		</>
																		:
																		(Object.values(obj1)[0] == 1) && !not_show.includes(Object.keys(obj1)[0]) &&
																		<div class="menu-item">
																			<Link className={`menu-link ${(selected === Object.keys(obj1)[0]) ? "active" : ""}`} to={customLink(Object.keys(obj1)[0])} onClick={() => { setSelected(Object.keys(obj1)[0]) }}>
																				<span class="menu-bullet"><span class="bullet bullet-dot"></span></span>
																				<span class="menu-title">{formatString(Object.keys(obj1)[0])}</span>
																			</Link>
																		</div>
																	}
																</>
															)
														})
													}
												</div>
											</div>
										</>
									}				
								</>
							)

							})}

						</div>
					</div>
				</div>
				
				<div className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6" id="kt_app_sidebar_footer">
					<a onClick={(event) => { setCookie("access_token", "", -1, true); window.location.href = "/login"; }} className="btn btn-custom btn-primary w-100" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-delay-show="8000" title="Check out the complete documentation with over 100 components">
						<span className="btn-label">Sign Out</span>
						<span className="svg-icon btn-icon svg-icon-2">
							<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
									<rect x="0" y="0" width="24" height="24" />
									<path d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z" fill="#000000" opacity="0.3" />
									<path d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z" fill="#000000" />
									<rect fill="#000000" opacity="0.3" x="7" y="10" width="5" height="2" rx="1" />
									<rect fill="#000000" opacity="0.3" x="7" y="14" width="9" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</a>
				</div>
				
			</div>
		</>
	);
}
export default AsideNavbar;