import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import axios from 'axios';
import swal from 'sweetalert';
import $, { data } from 'jquery';
import moment from 'moment';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import Select from 'react-select';
import removeImg from './icons/remove.svg'
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import uuid from 'react-uuid';
import { Link, Redirect } from 'react-router-dom';

function AddUser(props) {
    let [user, setUser] = useState({});
    let [state, setstate] = useState(2);
    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    const [userlist, setuserList] = useState([])
    const [userlist1, setuserList1] = useState([])

    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [Mobile, setMobile] = useState("");
    const [Employee_id, setEmployee_id] = useState("");
    const [Gender, setGender] = useState("");
    const [Designation, setDesignation] = useState("");
    const [Skip_mail, setSkip_mail] = useState(false);
    const [Dob_date, setDob_date] = useState("");
    const [Joining_date, setJoining_date] = useState("");
    let [Department, setDepartment] = useState("");
    let [Submit_to, setSubmit_to] = useState("");
    const [DepartmentList, setDepartmentList] = useState([]);
    let [Role, setRole] = useState("");
    const [UserRoleList, setUserRoleList] = useState([]);
    let [Policy, setPolicy] = useState("");
    const [PolicyList, setPolicyList] = useState([]);
    const [GenderList, setGenderList] = useState([{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }])
    const [editmodal, setEditModal] = useState([]);
    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

    var page_name = "add-user";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setorganization_list(res.data)
						window.$("#ModalOrganization").modal("show")
						//setstate1(1)
					}
				})     
            }
        }
        else {
            swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                window.location.href = "/login"
            });
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }

                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            res.data.audience_count = (res.data.audience_count)
                            if (res.data.audience_count > 0) {
                                const timer_decrease = setInterval(() => {
                                    res.data.audience_count = (res.data.audience_count - 1);
                                    //console.log(res.data.audience_count);
                                    if (res.data.audience_count > 0 && res.data.audience_count < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                                        if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                                            setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                                            window.$('#kt_modal_timer').modal('show');
                                        }
                                    }
                                }, 1000);

                                return () => clearInterval(timer_decrease);
                            }
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }

        organization_id!="" && validateJWT();

    }, [organization_id])

    useEffect(() => {
        async function getuserdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/user-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if (body.length > 0) {
                setstate(1);
            }
            else {
                setstate(0);
            }
            let temp=[];
            temp.push({value:"user_all" ,label:"All User"});
            for(let i=0;i<body.length;i++)
            {
                temp.push({value: body[i].user_id ,label:body[i].user_id});
            }
            setuserList1(temp);
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.log_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.name;
                    temp_obj.value = obj.user_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setuserList(temp_dropdown);

        }
        if(read || write)
        {
            (user.email && visibility) && getuserdetails();
        }

    }, [user, visibility, read, write])


    useEffect(() => {

        // async function getdeparmentdetails() {
        //     var object = {};
        //     object.email = user.email;

        //     const response = await fetch(
        //         `${process.env.REACT_APP_SERVER_URL}/department-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        //     );
        //     const body = await response.json();
        //     var temp_dropdown = [];
        //     body.map((obj) => {
        //         if (obj.status === "Active") {
        //             var temp_obj = {};
        //             temp_obj.label = obj.department_name;
        //             temp_obj.value = obj.department_id;
        //             temp_dropdown.push(temp_obj);
        //         }
        //     })
        //     setDepartmentList(temp_dropdown);
        // }
        // if (read || write) {
        //     user.email && visibility && getdeparmentdetails();
        // }
        async function getUserRoledetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-roles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id,"service":"expense"}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.log_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.user_role;
                    temp_obj.value = obj.user_role_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setUserRoleList(temp_dropdown);
        }
        if (read || write) {
            user.email && visibility && getUserRoledetails();
        }

        // async function getUserPolicydetails() {
        //     var object = {};
        //     object.email = user.email;

        //     const response = await fetch(
        //         `${process.env.REACT_APP_SERVER_URL}/get-travel-policy`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id,"service":"expense"}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        //     );
        //     const body = await response.json();
        //     var temp_dropdown = [];
        //     body.map((obj) => {
        //         if (obj.add_status === "Active") {
        //             var temp_obj = {};
        //             temp_obj.label = obj.policy_name;
        //             temp_obj.value = obj.unique_id;
        //             temp_dropdown.push(temp_obj);
        //         }
        //     })
        //     console.log(temp_dropdown)
        //     setPolicy(body.filter(option => option.default_policy =="Yes")[0].unique_id);
        //     setPolicyList(temp_dropdown);
        // }
        // if (read || write) {
        //     user.email && visibility && getUserPolicydetails();
        // }

    }, [user, visibility, read, write])


    const submitUser = (e) => {
        e.preventDefault();
        var object = {};
        object.organization_id=organization_id;
        object.name = Name;
        object.email_id = Email;
        object.mobile = Mobile;
        object.Employee_id = Employee_id;
        object.Submit_to = Submit_to;
        object.role = Role;
        object.service = "expense";
        object.Dob_date = Dob_date;
        object.department = "";
        object.Joining_date = Joining_date;
        object.Gender = Gender;
        object.Policy = "";
        object.Skip_mail = Skip_mail;
        object.Designation= Designation;
        object.page_name = page_name;


        if (object.name == "") {
            swal("Warning!", "Name cannot be a empty", "warning");
            return false;
        }
        var exp=/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if(object.email_id=="")
        {
            //console.log("7");
            swal("Warning!", "Please provide an email", "warning");
            return false;
        }
        else if (!object.email_id.match(exp))
        {
            //console.log("8");
            swal("Warning!", "Please provide a valid email", "warning");
            return false;
        }
        for (var i = 0; i < userlist1.length; ++i) {
            if (userlist1[i].label == object.email_id) {
                swal("Oops!", "This email id already connected to your organization, try different one!", "error");
                return false;
            }
        }
        if (object.role == "") {
            swal("Warning!", "Role field cannot be a empty", "warning");
            return false;
        }
        // if (object.Policy == "") {
        //     swal("Warning!", "Policy field cannot be a empty", "warning");
        //     return false;
        // }
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-user`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "user added successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else if (res.data.operation === "same_user_name") {
                swal("Oops!", "Use a different User name!!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        }).catch(function (error) {
            console.log(error)
        });
        
    }

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {state==1 ?
                <>

                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">New User
                                    <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                    <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                </h1>
                            </div>
                        </div>

                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" className="container">
                                <div className="card">
                                    <form className="form">
                                        <div className="card-body border-top p-9">
                                            <div className=" py-10 px-lg-17">
                                                <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                                    <div className="row mb-5">
                                                        <div className="col-md-6 fv-row">
                                                            <label className="required fs-5 fw-bold mb-2">Name</label>
                                                            <input id="department_name" type="text" className="form-control form-control-solid " placeholder="" name="department-name" value={Name} onChange={(e) => { setName(e.target.value) }} readOnly={write?false:true}/>
                                                        </div>
                                                        <div className="col-md-6 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Employee ID</label>
                                                            <input id="department_name" type="text" className="form-control form-control-solid " placeholder="" name="department-name" value={Employee_id} onChange={(e) => { setEmployee_id(e.target.value) }} readOnly={write?false:true}/>
                                                        </div>

                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-6 fv-row">
                                                            <label className="required fs-5 fw-bold mb-2">Email Address</label>
                                                            <input id='category_code' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={Email} onChange={(e) => { setEmail(e.target.value) }} readOnly={write?false:true}/>
                                                        </div>
                                                        <div className="col-md-6 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Mobile</label>
                                                            <input id='category_code' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={Mobile} onChange={(e) => { setMobile(e.target.value) }} readOnly={write?false:true}/>
                                                        </div>
                                                    </div>
                                                {console.log(UserRoleList)}
                                                    <div className="row mb-5">
                                                        <div className="col-md-12 fv-row">
                                                            <label className="required fs-5 fw-bold mb-2">Role</label>
                                                            <Select
                                                                name="category"
                                                                id="category"
                                                                className="form-control form-control-solid"
                                                                options={UserRoleList}
                                                                onChange={(event) => { setRole(event.value) }}
                                                                value={UserRoleList.filter(option => option.value == Role).length != 0 && UserRoleList.filter(option => option.value == Role)[0]}
                                                                placeholder="--Choose a Role--"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-12 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Submits To</label>
                                                            <Select
                                                                name="category"
                                                                id="category"
                                                                className="form-control form-control-solid"
                                                                options={userlist}
                                                                onChange={(event) => { setSubmit_to(event.value) }}
                                                                value={userlist.filter(option => option.value == Submit_to).length != 0 && userlist.filter(option => option.value == Submit_to)[0]}
                                                                placeholder="--Select--"
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* <div className="row mb-5">
                                                        <div className="col-md-12 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Department Name</label>
                                                            <Select
                                                                name="category"
                                                                id="category"
                                                                className="form-control form-control-solid"
                                                                options={DepartmentList}
                                                                onChange={(event) => { setDepartment(event.value) }}
                                                                value={DepartmentList.filter(option => option.value == Department).length != 0 && DepartmentList.filter(option => option.value == Department)[0]}
                                                                placeholder="--Choose a Department--"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 fv-row">
                                                        <label className="required fs-5 fw-bold mb-2">Policy</label>
                                                        <Select
                                                            name="category"
                                                            id="category"
                                                            className="form-control form-control-solid"
                                                            options={PolicyList}
                                                            onChange={(event) => { setPolicy(event.value) }}
                                                            value={PolicyList.filter(option => option.value == Policy).length != 0 && PolicyList.filter(option => option.value == Policy)[0]}
                                                            placeholder="--Select--"
                                                        />
                                                    </div> */}
                                                    <div className="row mb-5">
                                                        <div className="col-md-6 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Date of birth</label>
                                                            <input type="date" name="Dob_date" className="form-control" id="Dob_date" placeholder="Sales Start Date" value={Dob_date}
                                                                onChange={(e) => {
                                                                    setDob_date(e.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Gender</label>
                                                            <Select
                                                                name="category"
                                                                id="category"
                                                                className="form-control form-control-solid"
                                                                options={GenderList}
                                                                onChange={(event) => { setGender(event.value) }}
                                                                value={GenderList.filter(option => option.value == Gender).length != 0 && GenderList.filter(option => option.value == Gender)[0]}
                                                                placeholder="--Select--"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-md-6 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Date of Joining</label>
                                                            <input type="date" name="Joining_date" className="form-control" id="Joining_date" placeholder="Sales Start Date" value={Joining_date}
                                                                onChange={(e) => {
                                                                    setJoining_date(e.target.value);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-md-6 fv-row">
                                                            <label className=" fs-5 fw-bold mb-2">Designation</label>
                                                            <Select
                                                                name="category"
                                                                id="category"
                                                                className="form-control form-control-solid"
                                                                options={UserRoleList}
                                                                onChange={(event) => { setDesignation(event.value) }}
                                                                value={UserRoleList.filter(option => option.value == Designation).length != 0 && UserRoleList.filter(option => option.value == Designation)[0]}
                                                                placeholder="--Select--"
                                                            />
                                                        </div>
                                                    </div>
                                                    <label class="form-check form-check-custom form-check-solid align-items-start">
                                                        <input class="form-check-input me-3" type="checkbox" name="email-preferences[]" value={Skip_mail} onChange={(e) => {
                                                                    setSkip_mail(e.target.checked);
                                                                }}/>
                                                        <span class="form-check-label d-flex flex-column align-items-start">
                                                            <span class="fw-bolder fs-5 mb-0">Skip invitation email</span>
                                                            {Skip_mail &&<span class="text-muted fs-6">You can invite them later from Admin View - User Management.</span>}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="modal-footer flex-center">
                                                {write==1 &&
                                                <button type="submit"
                                                    className="btn btn-primary rounded"
                                                    onClick={(e) => { submitUser(e) }}
                                                    data-kt-indicator={mainSubmitButtonState?"on":"off"}
                                                    disabled={mainSubmitButtonState}
                                                >
                                                    <span className="indicator-label">Submit</span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                </button>}
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">New User
                                    <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                    <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                </h1>
                            </div>
                        </div>

                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div className="container" id="kt_content_container">
                                <div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
                                    <br />

                                    {state == 2 ?
                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        :
                                        <>
                                            <div className="flex-lg-row-fluid me-lg-15">
                                                <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                                    <div className="card-header"></div>
                                                    <div className="card-body pt-0">
                                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                            <div className="d-flex flex-stack flex-grow-1">
                                                                <div className="fw-bold">
                                                                    <h4 className="text-gray-800 fw-bolder">Please Login</h4>
                                                                    <div className="fs-6 text-gray-600">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }

                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            }

            <div class="modal fade" id="ModalOrganization" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1" role="dialog" aria-label="myModalLabel" aria-hidden="true"  data-pause="99999999999999999">
                <div class="modal-dialog mw-700px">
                    <div class="modal-content ">
                        <div class="modal-header">
                            <h2>Select Organization</h2>
                            {/*<div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                <span class="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                            </div> */}
                        </div>
                        <div className="modal-body py-10 px-lg-17">
                            <form class="form-default">
                                <div  class="modal-body tt-background">
                                    <div class="tt-modal-discount row">
                                        <div className="row mb-5">
                                            {
                                            organization_list.length > 0 ? organization_list.map((obj, index) => {
                                                if(index < 5)
                                                {
                                                    return(
                                                        <>									
                                                        <div class="col-lg-12">
                                                            {(getCookie("organization_id")==obj.organization_id)?
                                                            <input type="radio" class="btn-check" onClick={(e) => { setCookie('organization_id', obj.organization_id, 1); window.location.reload(); }} name="account_type" value="personal" id={obj.organization_id} checked/>
                                                            :
                                                            <input type="radio" class="btn-check" name="account_type" onClick={(e) => { setCookie('organization_id', obj.organization_id, 1); window.location.reload(); }} value="personal"  id={obj.organization_id}/>
                                                            }
                                                            <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10" for={obj.organization_id}>
                                                                <span class="svg-icon svg-icon-3x me-5">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black"></path>
                                                                        <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black"></path>
                                                                    </svg>
                                                                </span>
                                                                <span class="d-block fw-bold text-start">
                                                                    <span class="text-dark fw-bolder d-block fs-4 mb-2">{obj.organization_name}</span>
                                                                    <span class="text-muted fw-bold fs-6">Organization ID:{obj.organization_id}</span>
                                                                </span>
                                                            </label>
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                        </>
                                                    );
                                                }
                                            })
                                            :
                                            <>
                                            { state == 2 ?
                                                <img src="/images/loader-06.svg" alt="" style={loaderstyle}  />
                                                :
                                                null
                                            }
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>


        </>
    );
}
export default AddUser;