import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
// import axios from 'axios';
// import swal from 'sweetalert';
// import { Redirect } from 'react-router-dom'
// // import CryptoJS from 'crypto-js'
// import moment from 'moment';

// import {setCookie,getCookie} from '../common_component/cookie.js';


// import {Modal} from "react-bootstrap";
// import html2canvas from 'html2canvas';
// import uuid from 'react-uuid';
// import moment from 'moment';
// import Dropzone from 'react-dropzone-uploader';
// import 'react-dropzone-uploader/dist/styles.css'
// import Select, {components} from 'react-select';
// import removeImg from './icons/remove.svg'
// import ReactTagInput from "@pathofdev/react-tag-input"; 
// import "@pathofdev/react-tag-input/build/index.css";
// import currency_arr from './currency-arr.js' ;
// import ReactDOM from 'react-dom';
// import $ from 'jquery';


function CustomerPaymentInvoiceFail(props) {

	var page_name = "customer-payment-invoice-fail";

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }
	
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    return ( 
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl text-center mt-20">
                                        
                        <div className="card" style={
                            {
                                background: "white",
                                padding: "60px",
                                borderRadius: "4px",
                                boxShadow: "0 2px 3px #C8D0D8",
                                display: "inline-block",
                                margin: "0 auto"
                            }
                        }>
                            <div style={
                                {
                                    borderRadius: "200px",
                                    height: "200px",
                                    width: "200px",
                                    background: " #F8FAF5",
                                    margin: "0 auto"
                                }
                            }>
                                <p className="checkmark" style={
                                    {
                                        color: "#D2042D",
                                        fontSize: "100px",
                                        lineHeight: "200px"
                                    }
                                }>✗</p>
                            </div>
                            <h1 style={
                                {
                                    color: "#D2042D",
                                    fontFamily: "'Nunito Sans', 'Helvetica Neue', sans-serif",
                                    fontWeight: "900",
                                    fontSize: "40px",
                                    margin: "20px"
                                }
                            }>Payment Failed</h1> 
                            <p style={
                                {
                                    color: "#404F5E",
                                    fontFamily: "'Nunito Sans', 'Helvetica Neue', sans-serif",
                                    fontSize: "20px",
                                    margin: "0"
                                }
                            }>The payment transaction has failed;<br/> please try again later</p>
                            <Link className="btn btn-secondary mt-5" to={`/customer-payment-invoice/${props.match.params.oid}/${props.match.params.id}`}>Retry Payment</Link>
                        </div>
                        
                    </div>
                </div>

            </div>
        </>
    );
}


export default CustomerPaymentInvoiceFail;