import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import $ from 'jquery';
import axios from 'axios';
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom';

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';

import moment from 'moment';

function SubscriptionList(props) {
	let [subscription_details, setSubscriptionDetails] = useState([]);
	let [subscription, setSubscription] = useState([]);
	let [duration, setDuration] = useState("mon");
	let [currency, setCurrency] = useState("default");
	let [user_network_details, set_user_network_details] = useState({});
	let [subscription_features, setSubscriptionFeatures] = useState([]);
	let [user, setUser] = useState({});
	let [redirect_to_billing, setRedirect_to_billing] = useState(2);
	let [purchaseplan, setPurchasePlan] = useState({});
	let [state, setstate] = useState(2);

	let [date, setDate] = useState(new Date().toString());
	let [date2, setDate2] = useState("");
	let [expiry_date2, setExpiryDate2] = useState("");
	let [price, setprice] = useState("");
	//let [duration1, setduration1] = useState("");
	//let [timer,setTimer] = useState(0);
	let timer = 0;
	let [clock_timer, setClockTimer] = useState(0);
	const [visibility, setvisibility] = useState(0);
	let [redirect, setredirect] = useState(0);
	//const [organization_id, setOrganization_id] = useState("");
	let [read, setread] = useState(2);
	let [write, setwrite] = useState(2);

	let loader = 0;

	let [subscription_list, set_subscription_list] = useState([]);
	var page_name = "subscription-list";
	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	const renderTime = ({ remainingTime }) => {

		if (remainingTime === 0) {
			//return <div className="timer">Too lale...</div>;
			window.location.href = "/login";
		}

		return (
			<div className="timer">
				<div className="text">Remaining</div>
				<div className="value">{remainingTime}</div>
				<div className="text">seconds</div>
			</div>
		);
	};

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	function getCountryCurrency(country) {
		var object = {};
		object.country = country;

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/get-country-currency`, object, headers).then((res) => {

			//console.log(res.data);
			if (res.data.message == "found") {
				setCurrency(res.data.currency)
				return (res.data.currency);
				//setCurrency("EUR")
				//setCurrency("default")
			}
			else {
				setCurrency("default");
				return ("default");
			}
		})
			.catch(function (error) {
				setCurrency("default");
				return ("default");
			});
	}

	useEffect(() => {
		setCookie("lastpage", window.location.href, 1)
		const validateJWT = () => {

			if (getCookie("access_token") != "") {
				var object = {};
				object.token = getCookie("access_token");

				var headers = {
					headers: {
						"Content-Type": "application/json",
						"Accept": "*/*"
					}
				}
				axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var user_object = {};
						user_object.email = res.data.email;
						user_object.name = res.data.name;
						user_object.img = res.data.img;
						setUser(user_object)
					}
					else {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
					.catch(function (error) {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					});

				axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
					if (res.data.operation == "success") {
						var token = res.data.audience_license;
						if (res.data.organization_flag == 0) {
							swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
								window.location.href = "/login"
							});
						}
						else {
							var path = window.location.pathname;
							path = path.replace(/\/$/, "");
							path = decodeURIComponent(path);
							var original_url = path.split("/")[1]
							let index = 0;
							let temp = -1;
							for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
								let component = res.data.user_access.access[i].inside_components;
								for (let j = 0; j < component.length; j++) {
									if (component[j].hasOwnProperty(original_url)) {
										index = i;
										if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
											setvisibility(1);
											setread(res.data.user_access.access[index].inside_components[j]["read"])
											setwrite(res.data.user_access.access[index].inside_components[j]["write"])
											// setwrite(0)
											break;
										}
										else {
											setredirect(1);
										}
									}
									else
									{
										temp = 2;
									}
								}
							}
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
						}
						if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
							setCookie('access_token', res.data.audience_license, 1)
							//setTimer(process.env.REACT_APP_JWT_EXPIRY);
							//setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
							timer = process.env.REACT_APP_JWT_EXPIRY;
							if (timer > 0) {
								const timer_decrease = setInterval(() => {
									timer = (timer - 1);
									//console.log(res.data.audience_count);
									if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
										if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
											setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
											window.$('#kt_modal_timer').modal('show');
										}
									}
								}, 1000);

								return () => clearInterval(timer_decrease);
							}
						}
						else {
							//setTimer(res.data.audience_count)
							//setClockTimer(res.data.audience_count)
							timer = res.data.audience_count;
							res.data.audience_count = (res.data.audience_count)
							if (res.data.audience_count > 0) {
								const timer_decrease = setInterval(() => {
									res.data.audience_count = (res.data.audience_count - 1);
									//console.log(res.data.audience_count);
									if (res.data.audience_count > 0 && res.data.audience_count < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
										if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
											setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
											window.$('#kt_modal_timer').modal('show');
										}
									}
								}, 1000);

								return () => clearInterval(timer_decrease);
							}
						}
					}
					else {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
					.catch(function (error) {
						console.log(error);
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					});
			}
			else {
				swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
					window.location.href = "/login"
				});
			}
		}

		validateJWT();




		let today1 = new Date().toLocaleString('hi-IN', { timeZone: 'Asia/Kolkata', hour12: false });
		var today_array = today1.split(", ");
		var date_string = today_array[0];
		var date_string_array = date_string.split("/");
		//console.log(date_string_array);
		//console.log(String(date_string_array[2]) + "-" + String(date_string_array[1]) + "-" + date_string_array[0].padStart(2, '0'));
		setDate2(String(date_string_array[2]) + "-" + String(date_string_array[1]) + "-" + date_string_array[0].padStart(2, '0'))
		let date = new Date(date_string_array[2], date_string_array[1], date_string_array[0].padStart(2, '0'));
		let longMonth = date.toLocaleString('en-us', { month: 'long' }); var today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

		var today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

		setDate(today)


		$.get('https://www.cloudflare.com/cdn-cgi/trace', function (data) {
			data = data.trim().split('\n').reduce(function (obj, pair) {
				pair = pair.split('=');
				return obj[pair[0]] = pair[1], obj;
			}, {});
			//set_user_network_details(data);
			//getCountryCurrency(data.loc);

		});
	}, [])



	//console.log(timer);

	useEffect(() => {
		async function get_subscription_details() {
			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-purchase-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			set_subscription_list(body);
		}
		if (read || write) {
			(user.email && visibility) && get_subscription_details()
		}
	}, [user, visibility, read, write])

	async function price1(subscription_id, duration1, currency1) {

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-subscription-details/${subscription_id}`, {
			method: 'POST',
			body: JSON.stringify({ "email": user.email, "page_name": page_name })
			, headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
		}
		);

		const body = await response.json();
		var json = JSON.parse(body[0].subscription_charges);
		let price3 = 0;
		json.map((obj2, index2) => {

			if (currency1 == obj2.currency || (obj2.default == "Yes" && currency1 == "default")) {
				obj2.charges.map((obj3, index3) => {

					if (obj3.duration_in_month == "1" && obj3.duration_in_letter == duration1) {
						price = Number(obj3.charges - obj3.discount_amount);
						price3 = price;

					}
					if (obj3.duration_in_month == "12" && obj3.duration_in_letter == duration1) {
						price = Number(obj3.charges - obj3.discount_amount);
						price3 = price;

					}
					if (obj3.duration_in_month == "3" && obj3.duration_in_letter == duration1) {
						price = Number(obj3.charges - obj3.discount_amount);
						price3 = price;
					}
					if (obj3.duration_in_month == "6" && obj3.duration_in_letter == duration1) {
						price = Number(obj3.charges - obj3.discount_amount);
						price3 = price;
					}

				})

			}

		})
		return price3;

	}
	//console.log(moment.utc().format('YYYY-MM-DD'));
	const purchase_plan = () => {

		var object = {};
		object.email = user.email;
		object.product_id = props.match.params.id;
		object.currency = currency;
		//object.currency = "EUR";
		object.purchase_flag = "Purchase";

		//object.starting_date = date2;
		object.starting_date = moment.utc().format('YYYY-MM-DD');

		if (duration == "mon") {
			object.duration = "month";

			var today2 = new Date();
			var today3 = new Date(today2.setDate(today2.getDate() + 30));

			var today1 = today3.toLocaleString('hi-IN', { timeZone: 'Asia/Kolkata', hour12: false });
			var today_array = today1.split(", ");
			var date_string = today_array[0];
			var date_string_array = date_string.split("/");

			//object.ending_date = String(date_string_array[2]) + "-" + String(date_string_array[1]) + "-" + date_string_array[0].padStart(2, '0');
			setExpiryDate2(String(date_string_array[2]) + "-" + String(date_string_array[1]) + "-" + date_string_array[0].padStart(2, '0'))
			date = new Date(date_string_array[2], date_string_array[1], date_string_array[0].padStart(2, '0'));
			var longMonth = date.toLocaleString('en-us', { month: 'long' });
			var today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

			today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

			//setExpiryDate(today)
			object.ending_date = moment.utc().add(30, 'days').format('YYYY-MM-DD');
		}
		else if (duration == "3 mon") {
			object.duration = "quarterly";

			var today2 = new Date();
			var today3 = new Date(today2.setDate(today2.getDate() + 90));

			var today1 = today3.toLocaleString('hi-IN', { timeZone: 'Asia/Kolkata', hour12: false });
			var today_array = today1.split(", ");
			var date_string = today_array[0];
			var date_string_array = date_string.split("/");

			//object.ending_date = String(date_string_array[2]) + "-" + String(date_string_array[1]) + "-" + date_string_array[0].padStart(2, '0');
			setExpiryDate2(String(date_string_array[2]) + "-" + String(date_string_array[1]) + "-" + date_string_array[0].padStart(2, '0'))
			date = new Date(date_string_array[2], date_string_array[1], date_string_array[0].padStart(2, '0'));
			var longMonth = date.toLocaleString('en-us', { month: 'long' });
			var today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

			today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

			//setExpiryDate(today)
			//object.ending_date = today;
			object.ending_date = moment.utc().add(90, 'days').format('YYYY-MM-DD');
		}
		else if (duration == "6 mon") {
			object.duration = "half yearly";

			var today2 = new Date();
			var today3 = new Date(today2.setDate(today2.getDate() + 180));

			var today1 = today3.toLocaleString('hi-IN', { timeZone: 'Asia/Kolkata', hour12: false });
			var today_array = today1.split(", ");
			var date_string = today_array[0];
			var date_string_array = date_string.split("/");

			//object.ending_date = String(date_string_array[2]) + "-" + String(date_string_array[1]) + "-" + date_string_array[0].padStart(2, '0');
			setExpiryDate2(String(date_string_array[2]) + "-" + String(date_string_array[1]) + "-" + date_string_array[0].padStart(2, '0'))
			date = new Date(date_string_array[2], date_string_array[1], date_string_array[0].padStart(2, '0'));
			var longMonth = date.toLocaleString('en-us', { month: 'long' });
			var today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

			today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

			//setExpiryDate(today)
			object.ending_date = moment.utc().add(180, 'days').format('YYYY-MM-DD');

		}
		else if (duration == "year") {
			object.duration = "yearly";

			var today2 = new Date();
			var today3 = new Date(today2.setDate(today2.getDate() + 365));

			var today1 = today3.toLocaleString('hi-IN', { timeZone: 'Asia/Kolkata', hour12: false });
			var today_array = today1.split(", ");
			var date_string = today_array[0];
			var date_string_array = date_string.split("/");

			//object.ending_date = String(date_string_array[2]) + "-" + String(date_string_array[1]) + "-" + date_string_array[0].padStart(2, '0');
			setExpiryDate2(String(date_string_array[2]) + "-" + String(date_string_array[1]) + "-" + date_string_array[0].padStart(2, '0'))
			date = new Date(date_string_array[2], date_string_array[1], date_string_array[0].padStart(2, '0'));
			var longMonth = date.toLocaleString('en-us', { month: 'long' });
			var today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

			today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];
			object.ending_date = moment.utc().add(365, 'days').format('YYYY-MM-DD');
		}

		var ele = document.getElementsByName('plan');

		var i = 0;
		for (i = 0; i < ele.length; i++) {
			if (ele[i].checked) {
				object.subscription_id = ele[i].value;

				if (subscription.filter(item => item.unique_id == ele[i].value).length > 0) {
					if (subscription.filter(item => item.unique_id == ele[i].value)[0].subscription_charges.filter(item2 => item2.currency == currency).length > 0) {
						if (subscription.filter(item => item.unique_id == ele[i].value)[0].subscription_charges.filter(item2 => item2.currency == currency)[0].charges.filter(item3 => item3.duration_in_letter == object.duration).length > 0) {
							object.price = Number(subscription.filter(item => item.unique_id == ele[i].value)[0].subscription_charges.filter(item2 => item2.currency == currency)[0].charges.filter(item3 => item3.duration_in_letter == object.duration)[0].charges - subscription.filter(item => item.unique_id == ele[i].value)[0].subscription_charges.filter(item2 => item2.currency == currency)[0].charges.filter(item3 => item3.duration_in_letter == object.duration)[0].discount_amount)
						}
					}
				}

				break
			}
		}

		const result = subscription_list.filter(item => item.add_status == "Active" && item.product_id == props.match.params.id);

		var object2 = {};
		object2.email = user.email;
		object2.page_name = page_name;

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		var purchase_flag = 0;


		if (document.getElementById("kt_careers_submit_button")) {
			document.getElementById("kt_careers_submit_button").setAttribute("data-kt-indicator", "on");
			document.getElementById("kt_careers_submit_button").disabled = !0;
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/check-billing-address`, object2, headers).then((res) => {
			if (res.data.operation == "success" && res.data.data == "found") {
				object.address_details = res.data.value;

				var currency_object = {};
				currency_object.country = res.data.value.country;
				currency_object.page_name = page_name;

				axios.post(`${process.env.REACT_APP_SERVER_URL}/get-country-currency`, currency_object, headers).then(async (res) => {

					let currency = res.data.currency;
					let message = res.data.message

					if (message == "found") {
						let price2 = await price1(object.subscription_id, object.duration, currency);
						if (price2 > 0) {
							object.price = price2;
						}
						if (currency != "default") {
							object.currency = currency;
						}

						let now = new Date();
						for (var i = 0; i < subscription_list.length; i++) {
							if (subscription_list[i].add_status == "Active" && subscription_list[i].product_id == props.match.params.id && subscription_list[i].price < object.price && subscription_list[i].currency == object.currency) {
								now = moment(now);
								let end = moment(subscription_list[i].starting_date).format('YYYY-MM-DD');
								var duration_date = moment.duration(now.diff(end));
								if (duration_date.asDays() > 0) {
									var discount = subscription_list[i].price - (Math.ceil(duration_date.asDays()) * (subscription_list[i].price / 30));
									var percentage = discount * 100 / object.price
									object.discount = parseFloat(percentage).toFixed(2);
									object.upgrade = 1;
									purchase_flag = 1;
								}
								break;
							}
						}
						//console.log(object);
						if (result.length > 0) {
							if (purchase_flag == 0) {
								//console.log(object);
								swal("Warning!", "You need to cancel your existing subscription.", "warning").then((res) => {
									window.location.reload();
								});
								return false;
							}
						}

						if (object.currency == "INR") {
							paymentHandler_razorpay_inr(object);
							//paymentHandler_stripe_inr(object);
						}
						else if (object.currency == "EUR") {
							paymentHandler_stripe_euro(object);
						}
						else if (object.currency == "USD") {
							paymentHandler_stripe_usd(object);
						}

					}
					else {
						if (currency != "default") {
							object.currency = res.data.currency;
						}

						let now = new Date();
						for (var i = 0; i < subscription_list.length; i++) {
							if (subscription_list[i].add_status == "Active" && subscription_list[i].product_id == props.match.params.id && subscription_list[i].price < object.price && subscription_list[i].currency == object.currency) {
								now = moment(now);
								let end = moment(subscription_list[i].starting_date).format('YYYY-MM-DD');
								var duration_date = moment.duration(now.diff(end));
								console.log(duration_date.asDays());
								if (duration_date.asDays() > 0) {
									var discount = subscription_list[i].price - (Math.ceil(duration_date.asDays()) * (subscription_list[i].price / 30));
									//console.log(discount);
									var percentage = discount * 100 / object.price
									object.discount = parseFloat(percentage).toFixed(2);
									object.upgrade = 1;
									purchase_flag = 1;
								}
								break;
							}
						}
						//console.log(object);
						if (result.length > 0) {
							if (purchase_flag == 0) {
								swal("Warning!", "You need to cancel your existing subscription.", "warning").then((res) => {
									window.location.reload();
								});
								return false;
							}
						}

						if (object.currency == "INR") {
							paymentHandler_razorpay_inr(object);
						}
						else if (object.currency == "EUR") {
							paymentHandler_stripe_euro(object);
						}
						else if (object.currency == "USD") {
							paymentHandler_stripe_usd(object);
						}
					}

				})
					.catch(function (error) {
						console.log(error);

						if (object.currency == "INR") {
							//paymentHandler_razorpay_inr(object);
						}
						else if (object.currency == "EUR") {
							//paymentHandler_stripe_euro(object);
						}
						else if (object.currency == "USD") {
							//paymentHandler_stripe_usd(object);
						}
					});

				//setRedirect(1)

			}
			else {
				setRedirect_to_billing(0)
			}
		})
			.catch(function (error) {
				swal("Oops!", "something went wrong", "error").then((res) => {
					window.location.reload();
				});
			});
		//object.organization_id = organization_id;
		setPurchasePlan(object)

	}
	//console.log(price)
	const paymentHandler_razorpay_inr = async (object) => {
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		object.payment_gateway = "razorpay";
		object.response = "";

		const API_URL = process.env.REACT_APP_SERVER_URL;
		//const orderUrl = `${API_URL}/place-order`;
		const orderUrl = `${API_URL}/create-session-razorpay`;
		/*
		var payment_object = {};
		payment_object.amount = object.price;
						
		const response = await axios.post(orderUrl,payment_object);
		const { data } = response;
		console.log(data);
		*/

		const response = await axios.post(orderUrl, object);
		const { data } = response;

		//console.log(data.id);

		object.s_id = data.id;
		object.page_name = page_name;

		axios.post(`${process.env.REACT_APP_SERVER_URL}/purchase-plan-razorpay`, object, headers).then(async (res) => {
			if (res.data && res.data.operation == "success") {
				const options = {
					key: process.env.REACT_APP_RAZOR_PAY_KEY_ID,
					subscription_id: data.id,

					handler: async (response) => {

						try {
							const paymentId = response.razorpay_payment_id;

							if (response.razorpay_payment_id) {
								object.response = response;
								object.id = res.data.id;
								object.page_name = page_name;

								axios.post(`${process.env.REACT_APP_SERVER_URL}/purchase-plan-razorpay-update`, object, headers).then((res2) => {
									if (res2.data && res2.data.operation == "success") {
										swal("Great!", "Payment Successfull!", "success").then((value) => {
											window.location.href = "/payment-response/success/" + res.data.id;
										});
									}
									else {
										swal("Great!", "We are still processing your payment. Please check back after few minutes.", "success").then((value) => {
											window.location.href = "/payment-response/success/" + res.data.id;
										});
									}
								});
							}
							else {
								swal("Oops!", "Something went wrong!", "error");
							}


							//const url = `${API_URL}/capture/${paymentId}`;
							/*
							const url = `${API_URL}/verification`;
						    
							var object2 = {};
							object2.razorpay_payment_id = response.razorpay_payment_id;
							object2.razorpay_signature = response.razorpay_signature;
							object2.sid = data.id;
				   	
							const captureResponse = await axios.post(url, object2)
						    
							let response1 = JSON.parse(captureResponse.data);
						    
							if(response1.id)
							{
							   object.response = response1;
							   object.id = res.data.id;
						   	
							   axios.post(`${process.env.REACT_APP_SERVER_URL}/purchase-plan-razorpay-update`,object, headers).then((res) => {
								   if(res.data && res.data.operation == "success")
								   {
									   swal("Great!", "Payment Successfull!", "success").then((value) => {
										   //window.location.href = "/subscription-history";
									   });
								   }
								   else
								   {
									   swal("Great!", "Order Placed!", "success").then((value) => {
										   //window.location.href = "/subscription-history";
									   });
								   }
							   });
						   	
							}
							*/
						} catch (err) {
							console.log(err);
							swal("Oops!", "Something went wrong!", "error");
						}

					},
					theme: {
						color: "#686CFD",
					},
				};

				const rzp1 = new window.Razorpay(options);
				rzp1.open();
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});

	};

	const paymentHandler_stripe_euro = async (object) => {
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		object.response = "";
		object.payment_gateway = "stripe-euro";
		object.page_name = page_name;

		axios.post(`${process.env.REACT_APP_SERVER_URL}/purchase-plan-stripe`, object, headers).then(async (res) => {
			if (res.data && res.data.operation == "success") {
				const API_URL = process.env.REACT_APP_SERVER_URL;
				const orderUrl = `${API_URL}/create-session-euro`;
				var payment_object = {};
				payment_object.id = res.data.id;
				payment_object.email = user.email;

				object.id = res.data.id;
				object.email = user.email;

				object.upgrade = 1;

				const response = await axios.post(orderUrl, object);
				//console.log(response.data.message);

				if (response.data.message.id) {
					var stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_EURO);
					$(document).ready(function () {
						setTimeout(function () {
							stripe.redirectToCheckout({
								sessionId: response.data.message.id
							}).then(function (result) {
							});
						}, 1000);
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}

			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});
	};

	const paymentHandler_stripe_inr = async (object) => {
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		object.response = "";
		object.payment_gateway = "stripe-inr";
		object.page_name = page_name;


		axios.post(`${process.env.REACT_APP_SERVER_URL}/purchase-plan-stripe`, object, headers).then(async (res) => {
			if (res.data && res.data.operation == "success") {
				const API_URL = process.env.REACT_APP_SERVER_URL;
				const orderUrl = `${API_URL}/create-session-inr`;
				var payment_object = {};
				payment_object.id = res.data.id;
				payment_object.email = user.email;

				object.id = res.data.id;
				object.email = user.email;

				object.upgrade = 1;

				const response = await axios.post(orderUrl, object);
				//console.log(response.data.message);

				if (response.data.message.id) {
					var stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_EURO);
					$(document).ready(function () {
						setTimeout(function () {
							stripe.redirectToCheckout({
								sessionId: response.data.message.id
							}).then(function (result) {
							});
						}, 1000);
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}

			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});
	};

	const paymentHandler_stripe_usd = async (object) => {
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		object.response = "";
		object.payment_gateway = "stripe-usd";
		object.page_name = page_name;

		axios.post(`${process.env.REACT_APP_SERVER_URL}/purchase-plan-stripe`, object, headers).then(async (res) => {
			if (res.data && res.data.operation == "success") {
				const API_URL = process.env.REACT_APP_SERVER_URL;
				const orderUrl = `${API_URL}/create-session-usd`;
				var payment_object = {};
				payment_object.id = res.data.id;
				payment_object.email = user.email;

				object.id = res.data.id;
				object.email = user.email;
				object.upgrade = 1;

				const response = await axios.post(orderUrl, object);
				//console.log(response.data.message);

				if (response.data.message.id) {
					var stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_USD);
					$(document).ready(function () {
						setTimeout(function () {
							stripe.redirectToCheckout({
								sessionId: response.data.message.id
							}).then(function (result) {
							});
						}, 1000);
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}

			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});
	};

	function loadScripts() {

		if (loader != 1) {

			var elem = document.getElementById("upgrade_js");
			if (!elem) {
				var se = document.createElement('script');
				se.src = "/assets/js/custom/modals/upgrade-plan.js";
				se.id = "upgrade_js";
				document.body.appendChild(se);
			}

		}
		loader = 1;
	}

	useEffect(() => {

		async function getsubscriptionfeatureslist(values) {
			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-subscription-feature-list`, {
				method: 'POST',body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
			}
			);
			const body = await response.json();
			setSubscriptionFeatures(body)
		}
		getsubscriptionfeatureslist()

		async function getsubscriptionlist(values) {

			var object = {};
			object.product = props.match.params.id;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-subscription-list/${props.match.params.id}`, {
					method: 'POST',
				body: JSON.stringify({ "email": user.email, "page_name": page_name }),
				headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
			}
			);
			const body = await response.json();

			let currency1 = currency;

			body.map((obj, index) => {
				var json = JSON.parse(obj.subscription_charges);
				var json2 = JSON.parse(obj.subscription_features);
				obj.subscription_charges = json;
				obj.subscription_features = json2;

				json.map((obj2, index2) => {

					if (currency1 == obj2.currency || (obj2.default == "Yes" && currency1 == "default")) {
						obj.currency = obj2.currency;

						if (currency1 == "default") {
							currency1 = obj2.currency
						}

						obj2.charges.map((obj3, index3) => {
							if (obj3.duration_in_month == "1") {
								obj.monthly_charge = Number(obj3.charges - obj3.discount_amount);
								obj.monthly_charge_without_discount = obj3.charges;
								obj.monthly_charge_unit = obj3.duration_in_letter;
								obj.monthly_discount = obj3.discount_amount;
								obj.price = Number(obj3.charges - obj3.discount_amount);
							}
							if (obj3.duration_in_month == "12") {
								obj.yearly_charge = Number(obj3.charges - obj3.discount_amount);
								obj.yearly_charge_without_discount = obj3.charges;
								obj.yearly_charge_unit = obj3.duration_in_letter;
								obj.yearly_discount = obj3.discount_amount;
								obj.price = Number(obj3.charges - obj3.discount_amount);
							}
							if (obj3.duration_in_month == "3") {
								obj.quarterly_charge = Number(obj3.charges - obj3.discount_amount);
								obj.quarterly_charge_without_discount = obj3.charges;
								obj.quarterly_charge_unit = obj3.duration_in_letter;
								obj.quarterly_discount = obj3.discount_amount;
								obj.price = Number(obj3.charges - obj3.discount_amount);
							}
							if (obj3.duration_in_month == "6") {
								obj.half_yearly_charge = Number(obj3.charges - obj3.discount_amount);
								obj.half_yearly_charge_without_discount = obj3.charges;
								obj.half_yearly_charge_unit = obj3.duration_in_letter;
								obj.half_yearly_discount = obj3.discount_amount;
								obj.price = Number(obj3.charges - obj3.discount_amount);
							}
						})
					}
				})
			})
			setCurrency(currency1)
			setSubscription(body)

			if (body.length > 0) {
				setstate(1)
				document.getElementById(`${body[0].unique_id}`).checked = "checked";
			}
			else {
				setstate(0)
			}

		}
		if (read || write) {
			(user.email && currency && subscription_list && visibility) && getsubscriptionlist()
		}



	}, [currency, user, subscription_list, visibility, read, write])



	return (
		<>
			{
				(redirect == 1 || (read == 0 && write == 0)) ?
					<>
						<Redirect to={{
							pathname: "/unauthorized",
						}}
						/>
					</> :
					null
			}

			{
				redirect_to_billing == 1 ? null : (redirect_to_billing == 0 ? <>
					<Redirect to={{
						pathname: "/billing-address",
						state: purchaseplan
					}}
					/>
				</> : null)
			}


			{subscription.length > 0 ?
				<>
					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
						<div className="toolbar" id="kt_toolbar">
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
								<div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
									<h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Subscriptions
										<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
										<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
									</h1>

								</div>

							</div>
						</div>



						<div className="post d-flex flex-column-fluid" id="kt_post">
							<div className="container" id="kt_content_container">
								<div className="card modal-body pt-0 pb-15 px-5 px-xl-5 ">
									<br />
									<div className="mb-13 text-center">
										<h1 className="mb-3">Upgrade a Plan</h1>
										<div className="text-gray-400 fw-bold fs-5">If you need more info, please check
											<a href="https://www.getlivealerts.com/pricing-plans" className="link-primary fw-bolder"> Pricing Guidelines</a>.</div>
									</div>


									<div className="d-flex flex-column" id="kt_modal_upgrade_plan">

										<div className="nav-group nav-group-outline mx-auto" data-kt-buttons="true">
											{subscription[0].subscription_charges[0].charges.filter(item => item.duration_in_letter == "month").length > 0 &&
												<a onClick={(e) => { setDuration("mon") }} className="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 active" data-kt-plan="month">Monthly</a>
											}
											{subscription[0].subscription_charges[0].charges.filter(item => item.duration_in_letter == "quarterly").length > 0 &&
												<a onClick={(e) => { setDuration("3 mon") }} className="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3" data-kt-plan="quarterly">3 Months</a>
											}
											{subscription[0].subscription_charges[0].charges.filter(item => item.duration_in_letter == "half yearly").length > 0 &&
												<a onClick={(e) => { setDuration("6 mon") }} className="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3" data-kt-plan="half_yearly">6 Months</a>
											}
											{subscription[0].subscription_charges[0].charges.filter(item => item.duration_in_letter == "yearly").length > 0 &&
												<a onClick={(e) => { setDuration("year") }} className="btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3" data-kt-plan="annual">Annual</a>
											}



										</div>

										<div className="row mt-10">

											<div className="col-lg-6 mb-10 mb-lg-0">

												<div className="nav flex-column">

													{subscription.map((obj, index) => {
														if (index == 0) {
															return (
																<div className="nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 active mb-6" data-bs-toggle="tab" data-bs-target={`#kt_upgrade_plan_${index}`}>
																	<div className="d-flex align-items-center me-2">
																		<div className="form-check form-check-custom form-check-solid form-check-success me-6">
																			<input className="form-check-input" type="radio" name="plan" id={obj.unique_id} value={obj.unique_id} />
																		</div>
																		<div className="flex-grow-1">
																			<h2 className="d-flex align-items-center fs-2 fw-bolder flex-wrap">{obj.subscription_name}</h2>
																			<div className="fw-bold opacity-50">{obj.short_description}</div>
																		</div>
																	</div>
																	<div className="ms-5">
																		<span className="mb-2">{obj.currency == "INR" ? "₹" : (obj.currency == "EUR" ? "€" : ((obj.currency == "USD" ? "$" : null)))}</span>
																		<span style={{ "fontSize": "152%" }} className=" fw-bolder" data-kt-plan-price-month={`${obj.monthly_charge}`} data-kt-plan-price-month_without_discount={`${obj.monthly_charge_without_discount}`} data-kt-plan-price-month_discount={`${obj.monthly_discount}`}
																			data-kt-plan-price-annual={`${obj.yearly_charge}`} data-kt-plan-price-annual_without_discount={`${obj.yearly_charge_without_discount}`} data-kt-plan-price-annual_discount={`${obj.yearly_discount}`}
																			data-kt-plan-price-quarterly={`${obj.quarterly_charge}`} data-kt-plan-price-quarterly_without_discount={`${obj.quarterly_charge_without_discount}`} data-kt-plan-price-quarterly_discount={`${obj.quarterly_discount}`}
																			data-kt-plan-price-half_yearly={`${obj.half_yearly_charge}`} data-kt-plan-price-half_yearly_without_discount={`${obj.half_yearly_charge_without_discount}`} data-kt-plan-price-half_yearly_discount={`${obj.half_yearly_discount}`} >{obj.monthly_charge} {(obj.monthly_discount > 0) && <del>{obj.monthly_charge_without_discount}</del>}</span>
																		<span className="fs-7 opacity-50">/
																			<span data-kt-element="period"></span>{duration}</span>
																	</div>
																</div>
															);
														}
														else {
															return (
																<div className="nav-link btn btn-outline btn-outline-dashed btn-color-dark btn-active btn-active-primary d-flex flex-stack text-start p-6 mb-6" data-bs-toggle="tab" data-bs-target={`#kt_upgrade_plan_${index}`}>
																	<div className="d-flex align-items-center me-2">
																		<div className="form-check form-check-custom form-check-solid form-check-success me-6">
																			<input className="form-check-input" type="radio" name="plan" id={obj.unique_id} value={obj.unique_id} />
																		</div>
																		<div className="flex-grow-1">
																			<h2 className="d-flex align-items-center fs-2 fw-bolder flex-wrap">{obj.subscription_name}</h2>
																			<div className="fw-bold opacity-50">{obj.short_description}</div>
																		</div>
																	</div>
																	<div className="ms-5">
																		<span className="mb-2">{obj.currency == "INR" ? "₹" : (obj.currency == "EUR" ? "€" : ((obj.currency == "USD" ? "$" : null)))}</span>
																		<span style={{ "fontSize": "152%" }} className=" fw-bolder" data-kt-plan-price-month={`${obj.monthly_charge}`} data-kt-plan-price-month_without_discount={`${obj.monthly_charge}`} data-kt-plan-price-month_discount={`${obj.monthly_discount}`}
																			data-kt-plan-price-annual={`${obj.yearly_charge}`} data-kt-plan-price-annual_without_discount={`${obj.yearly_charge_without_discount}`} data-kt-plan-price-annual_discount={`${obj.yearly_discount}`}
																			data-kt-plan-price-quarterly={`${obj.quarterly_charge}`} data-kt-plan-price-quarterly_without_discount={`${obj.quarterly_charge_without_discount}`} data-kt-plan-price-quarterly_discount={`${obj.quarterly_discount}`}
																			data-kt-plan-price-half_yearly={`${obj.half_yearly_charge}`} data-kt-plan-price-half_yearly_without_discount={`${obj.half_yearly_charge_without_discount}`} data-kt-plan-price-half_yearly_discount={`${obj.half_yearly_discount}`} >{obj.monthly_charge} {(obj.monthly_discount > 0) && <del>{obj.monthly_charge_without_discount}</del>}</span>
																		<span className="fs-7 opacity-50">/
																			<span data-kt-element="period"></span>{duration}</span>
																	</div>
																</div>
															);
														}
													})}

												</div>

											</div>


											<div className="col-lg-6">

												<div className="tab-content rounded h-100 bg-light p-5">



													{subscription.map((obj, index) => {
														if (index == 0) {
															return (
																<div className="tab-pane fade show active" id={`kt_upgrade_plan_${index}`}>
																	<div className="pb-5">
																		<h2 className="fw-bolder text-dark">What’s in {obj.subscription_name} Plan?</h2>
																		<div className="text-gray-400 fw-bold">{obj.short_description}</div>
																	</div>
																	<div className="pt-1" >


																		{
																			subscription_features.map((obj2, index2) => {

																				if (obj2.value_applicability == 1 && obj2.boolean_applicability == 1) {

																					return (
																						<div className="d-flex align-items-center mb-7">
																							{(obj.subscription_features.filter(item => item.duration == duration).length > 0 && obj.subscription_features.filter(item => item.duration == duration)[0].features.filter(item => item.feature == obj2.unique_id).length > 0) ?
																								<>
																									<span className="fw-bold fs-5 text-gray-700 flex-grow-1">{obj2.feature_name}</span>
																									<span style={{ "text-align": "right" }} className="fw-bold fs-5 text-gray-700 flex-grow-1">
																										{obj.subscription_features.filter(item => item.duration == duration)[0].features.filter(item => item.feature == obj2.unique_id)[0].value} {obj.subscription_features.filter(item => item.duration == duration)[0].features.filter(item => item.feature == obj2.unique_id)[0].unit}
																									</span>
																								</>
																								:
																								<>
																									<span className="fw-bold fs-5 text-gray-700 flex-grow-1">{obj2.feature_name}</span>
																									<span className="svg-icon svg-icon-1 ">
																										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																											<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
																											<path d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z" fill="#000000" />
																										</svg>
																									</span>
																								</>
																							}

																						</div>
																					);
																				}
																				else if (obj2.value_applicability == 0 && obj2.boolean_applicability == 1) {
																					return (
																						<div className="d-flex align-items-center mb-7">

																							{(obj.subscription_features.filter(item => item.duration == duration).length > 0 && obj.subscription_features.filter(item => item.duration == duration)[0].features.filter(item => item.feature == obj2.unique_id).length > 0) ?
																								<>
																									<span className="fw-bold fs-5 text-gray-700 flex-grow-1">{obj2.feature_name}</span>
																									<span className="svg-icon svg-icon-1 svg-icon-success">
																										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																											<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
																											<path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="#000000" fill-rule="nonzero" />
																										</svg>
																									</span>
																								</>
																								:
																								<>
																									<span className="fw-bold fs-5 text-gray-700 flex-grow-1">{obj2.feature_name}</span>
																									<span className="svg-icon svg-icon-1">
																										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																											<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
																											<path d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z" fill="#000000" />
																										</svg>
																									</span>
																								</>
																							}

																						</div>
																					);
																				}
																			})
																		}

																	</div>
																</div>
															);
														}
														else {
															return (
																<div className="tab-pane fade" id={`kt_upgrade_plan_${index}`}>
																	<div className="pb-5">
																		<h2 className="fw-bolder text-dark">What’s in {obj.subscription_name} Plan?</h2>
																		<div className="text-gray-400 fw-bold">{obj.short_description}</div>
																	</div>
																	<div className="pt-1">


																		{
																			subscription_features.map((obj2, index2) => {

																				if (obj2.value_applicability == 1 && obj2.boolean_applicability == 1) {

																					return (
																						<div className="d-flex align-items-center mb-7">
																							{(obj.subscription_features.filter(item => item.duration == duration).length > 0 && obj.subscription_features.filter(item => item.duration == duration)[0].features.filter(item => item.feature == obj2.unique_id).length > 0) ?
																								<>
																									<span className="fw-bold fs-5 text-gray-700 flex-grow-1">{obj2.feature_name}</span>
																									<span style={{ "text-align": "right" }} className="fw-bold fs-5 text-gray-700 flex-grow-1">
																										{obj.subscription_features.filter(item => item.duration == duration)[0].features.filter(item => item.feature == obj2.unique_id)[0].value} {obj.subscription_features.filter(item => item.duration == duration)[0].features.filter(item => item.feature == obj2.unique_id)[0].unit}
																									</span>
																								</>
																								:
																								<>
																									<span className="fw-bold fs-5 text-gray-700 flex-grow-1">{obj2.feature_name}</span>
																									<span className="svg-icon svg-icon-1 ">
																										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																											<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
																											<path d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z" fill="#000000" />
																										</svg>
																									</span>
																								</>
																							}

																						</div>
																					);
																				}
																				else if (obj2.value_applicability == 0 && obj2.boolean_applicability == 1) {
																					return (
																						<div className="d-flex align-items-center mb-7">

																							{(obj.subscription_features.filter(item => item.duration == duration).length > 0 && obj.subscription_features.filter(item => item.duration == duration)[0].features.filter(item => item.feature == obj2.unique_id).length > 0) ?
																								<>
																									<span className="fw-bold fs-5 text-gray-700 flex-grow-1">{obj2.feature_name}</span>
																									<span className="svg-icon svg-icon-1 svg-icon-success">
																										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																											<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
																											<path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="#000000" fill-rule="nonzero" />
																										</svg>
																									</span>
																								</>
																								:
																								<>
																									<span className="fw-bold fs-5 text-gray-700 flex-grow-1">{obj2.feature_name}</span>
																									<span className="svg-icon svg-icon-1">
																										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																											<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
																											<path d="M12.0355339,10.6213203 L14.863961,7.79289322 C15.2544853,7.40236893 15.8876503,7.40236893 16.2781746,7.79289322 C16.6686989,8.18341751 16.6686989,8.81658249 16.2781746,9.20710678 L13.4497475,12.0355339 L16.2781746,14.863961 C16.6686989,15.2544853 16.6686989,15.8876503 16.2781746,16.2781746 C15.8876503,16.6686989 15.2544853,16.6686989 14.863961,16.2781746 L12.0355339,13.4497475 L9.20710678,16.2781746 C8.81658249,16.6686989 8.18341751,16.6686989 7.79289322,16.2781746 C7.40236893,15.8876503 7.40236893,15.2544853 7.79289322,14.863961 L10.6213203,12.0355339 L7.79289322,9.20710678 C7.40236893,8.81658249 7.40236893,8.18341751 7.79289322,7.79289322 C8.18341751,7.40236893 8.81658249,7.40236893 9.20710678,7.79289322 L12.0355339,10.6213203 Z" fill="#000000" />
																										</svg>
																									</span>
																								</>
																							}

																						</div>
																					);
																				}

																			})
																		}

																	</div>
																</div>
															);
														}
													})}




												</div>

											</div>

										</div>

									</div>
									<div className="d-flex flex-center flex-row-fluid pt-12">

										<button type="submit" className="btn btn-primary rounded" id="kt_careers_submit_button" onClick={(event) => { event.target.setAttribute("data-kt-indicator", "on"); event.target.disabled = !0; purchase_plan(); }}>

											<span className="indicator-label">Save & Proceed</span>
											<span className="indicator-progress">Please wait...
												<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>

										</button>
									</div>
									<br />
								</div>
							</div>
						</div>
					</div>
					{subscription.length > 0 && <div style={{ "display": "none" }}> setTimeout(function(){loadScripts()},500) </div>}
				</>
				:
				<>
					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">

						<div className="post d-flex flex-column-fluid" id="kt_post">
							<div className="container" id="kt_content_container">
								<div className="card modal-body pt-0 pb-15 px-5 px-xl-20" >
									<br />

									{state == 2 ?
										<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
										:
										<>

											<div className="flex-lg-row-fluid me-lg-15">
												<div className="card card-flush pt-3 mb-5 mb-lg-10">

													<div className="card-header">
														<div className="card-title">
															<h2 className="fw-bolder">Purchase Plan</h2>
														</div>
													</div>


													<div className="card-body pt-0">
														<div className="text-gray-500 fw-bold fs-5 mb-5">Purchase a subscription:</div>
														<div className="d-flex align-items-center p-3 mb-2">

														</div>
														<div className="mb-7 d-none">
															<a className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_customer_search">Select Customer</a>
															<span className="fw-bolder text-gray-500 mx-2">or</span>
															<a className="btn btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_users_search">Add New Customer</a>
														</div>

														<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
															<div className="d-flex flex-stack flex-grow-1">
																<div className="fw-bold">
																	<h4 className="text-gray-800 fw-bolder">Subscriptions are not Available for the Product.</h4>
																	<div className="fs-6 text-gray-600">
																	</div>
																</div>

															</div>

														</div>

													</div>

												</div>
											</div>

										</>
									}


									<br />
								</div>
							</div>
						</div>
					</div>
				</>
			}



			<div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

				<div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

					<div className="modal-content">

						<div className="modal-header" id="kt_modal_new_address_header">

							<h2>Session Out</h2>

							<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

								<span className="svg-icon svg-icon-1">
									<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
										<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
											<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
											<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
										</g>
									</svg>
								</span>

							</div>

						</div>

						<div className="modal-body py-10 px-lg-17">

							<div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

								<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

									<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
											<rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
											<rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
										</svg>
									</span>


									<div className="d-flex flex-stack flex-grow-1">

										<div className="fw-bold">
											<h4 className="text-gray-800 fw-bolder">Warning</h4>
											<div className="fs-6 text-gray-600">Your session is going to time out</div>
										</div>

									</div>

								</div>

								{clock_timer > 0 &&
									<div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
										<CountdownCircleTimer
											isPlaying
											duration={clock_timer}
											colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
											onComplete={() => [true, 1000]}
										>
											{renderTime}
										</CountdownCircleTimer>
									</div>
								}

							</div>

						</div>

						<div className="modal-footer flex-center">

							<button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
								<span className="indicator-label">Stay Signed in</span>
								<span className="indicator-progress">Please wait...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
							</button>

						</div>

					</div>
				</div>
			</div>







		</>
	);
}
export default SubscriptionList;