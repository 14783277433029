import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import { Modal } from 'react-bootstrap';
import MUIDataTable from "mui-datatables";
import {MuiThemeProvider, createTheme} from '@material-ui/core';

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

// import SetColor from './SetColor.js';
// import Select from 'react-select';
// import moment from 'moment';
// import country_arr from './countries-arr.js';
// import s_a from './states-arr.js'; 

export const ShowCustomer = () => {
    
    const [user, setUser] = useState({});
    const [organization_id, setOrganization_id] = useState("")
    const [organization_list, setorganization_list] = useState([]);
    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);
    const [state, setstate] = useState(2);

    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
	const [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);

    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [customerList, setCustomerList] = useState([])

    const [data, setData] = useState([]);
    
    const [statusModalShow, setStatusModalShow] = useState(false)
    const [statusId, setStatusId] = useState("")
    const [statusValue, setStatusValue] = useState("")
    const [statusSubmitButtonState, setStatusSubmitButtonState] = useState(false)
    
    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    var page_name = "customer-list";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: "none",
        filterType: "dropdown",
        responsive: "vertical",
        tableBodyHeight: "480px",
        tableBodyMaxHeight: "",
        searchPlaceholder: 'Search Customers',
    };

    const columns = [
        {
            name: "name",
            label: "Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "companyName",
            label: "Company Name",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "workPhone",
            label: "Work Phone",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "leadSource",
            label: "Lead Source",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "portalAccess",
            label: "Portal Access",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let color
                    if(value == "Active")
                        color = "green"   
                    else
                        color = "red"
                    
                    return (
                        <p
                            className='my-auto'
                            value={value}
                            onChange={event => updateValue(event.target.value, tableMeta.columnIndex)}
                            style={{ color: color }}>{value}
                        </p>
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true);
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    setRedirect_to_login(1);
                });
                
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if(temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else
                                    {
                                        temp = 2;
                                    }
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
							
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setRedirect_to_login(1);
                });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }

                // console.log(timer)
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);





    async function getCustomers() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/customer-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        let body = await response.json();
        // console.log(body) 

        if (body.length != 0) {
            setCustomerList(body)
        }
        else{
            throw "no data"
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getCustomers()
            p1.then(() => {
                setstate(1)
                console.log("all apis done")
            })
            .catch((err) => {
                setstate(3)
                console.log(err)
            })
        }
    }, [user, visibility, read, write])

    useEffect(() => {
        if (customerList.length > 0) {
            let temp_data = [];
            customerList.map((obj) => {

                let id = obj.cid;
                let temp_obj = {};
                temp_obj.name = obj.customer_display_name;
                temp_obj.companyName = obj.company_name;
                temp_obj.email = obj.email;
                temp_obj.workPhone = obj.work_phone;
                temp_obj.leadSource = obj.lead_source;
                temp_obj.portalAccess = obj.portal_access.toUpperCase();
                // temp_obj.receivables = obj. ;
                temp_obj.status = obj.add_status;
        
                temp_obj.actions =
                    <div className="dropdown">
                        <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-bars px-0"></i></button>
                        {
                            write ?
                                <>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><Link className="dropdown-item" to={`/edit-customer-details/${id}`}>Edit Details</Link></li>
                                        <li><a className="dropdown-item" onClick={(e) => {setStatusId(id); setStatusValue(obj.add_status); setStatusModalShow(true); }}>Change Status</a></li>
                                        <li><Link className="dropdown-item" to={`/view-customer-details/${id}`}>View Details</Link></li>
                                        {/* <li><Link className="dropdown-item" to={`/customer-bank-details/${id}`}>Add/Edit Bank Details</Link></li> */}
                                    </ul>
                                </>
                            :
                            read &&
                                <>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a className="dropdown-item" href={`/view-customer-details/${id}`}>View Details</a></li>
                                    </ul>
                                </>
                        }
                        
                    </div>

                temp_data.push(temp_obj);
            })
            setData(temp_data);
        }
        else {
            setData([]);
        }
    }, [customerList])

    const submitStatus = () => {
        let values = {"id": statusId, "status": (statusValue=="Active")?"Deactivated":"Active", "page_name": page_name, "organization_id": organization_id };

        console.log(values);
        setStatusSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-customer`, values, headers).then((res) => {
            setStatusSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Status changed successfully!", "success")
                handleStatusModalClose()
                getCustomers()
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const handleStatusModalClose = () => {
        setStatusModalShow(false)
        setStatusId("") 
        setStatusValue("")
    }

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    return (
        <>
            {
                (redirect == 1 ||(read==0 && write==0)) ? 
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> :
                null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}

            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Show Customers</h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">

                        <div className="card">
                            <div className="card-body pt-6">
                                {
                                    state == 1?
                                    <>
                                        <MuiThemeProvider theme={createTheme({palette: { type:userTheme }})}>
                                            <MUIDataTable
                                                title={"Customer Details"}
                                                data={data}
                                                columns={columns}
                                                options={options}
                                            />
                                        </MuiThemeProvider>
                                    </>
                                    :
                                    state == 2?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :
                                    <>
                                        <div className="card-body pt-0">
                                            <div className="text-gray-500 fw-bold fs-5 mb-5">Show Customers</div>

                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                <div className="d-flex flex-stack flex-grow-1">
                                                    <div className="fw-bold">
                                                        <h4 className="text-gray-800 fw-bolder">No Customers Added.</h4>
                                                        <div className="fs-6 text-gray-600"> </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <Modal show={statusModalShow} onHide={()=>{handleStatusModalClose()}} >
                <Modal.Header>
                    <Modal.Title>Change Status</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{handleStatusModalClose()}}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="form-group row">
                            <div className="col-lg-12">
                                Current Status is : {statusValue == "Active" ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
                                <h3>Are you sure about changing status?</h3>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    <button className="btn btn-secondary rounded" onClick={()=>{handleStatusModalClose()}}>Cancel</button>
                    {
                        (statusValue == "Active") ? 
                        <button type="button" className="btn btn-danger rounded" data-kt-indicator={statusSubmitButtonState?"on":"off"} disabled={statusSubmitButtonState} onClick={(e) => {submitStatus()}} >
                            <span className="indicator-label">Deactivate</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button> 
                        : 
                        <button type="button" className="btn btn-success rounded" data-kt-indicator={statusSubmitButtonState?"on":"off"} disabled={statusSubmitButtonState} onClick={(e) => {submitStatus()}} >
                            <span className="indicator-label">Activate</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org}/>
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer}/>
        </>
    );
};
export default ShowCustomer;