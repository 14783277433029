import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment';
import './Activity.css';

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';

export const ViewManufacturerDetails = (props) => {
    // console.log(props.match.params.id);
    const [accessToken, setaccessToken] = useState('');
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [loading, setloading] = useState(false);
    let [timezone_change, set_timezone_change] = useState("+00:00");
    let [basicInformations, set_basicInformations] = useState({});
    let [addressDetails, set_addressDetails] = useState({});
    let [attachMents, set_attachMents] = useState([]);
    //const [organization_id, setOrganization_id] = useState("");
    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);
    const [state, setstate] = useState(2);
	
	const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);
	
	var page_name = "view-manufacturer-details";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };


    const colors = ['sucsess', 'primary', 'danger', 'warning',];

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });

                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0)
						{
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
						else
						{
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
						
						
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }
        validateJWT();
    }, [])

    useEffect(() => {
        if (timer > 0) {
            const timer_decrease = setInterval(() => {
                setTimer(timer - 1);
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        window.$('#kt_modal_timer').modal('show');
                    }
                }
            }, 1000);

            return () => clearInterval(timer_decrease);
        }
    }, [timer]);


    useEffect(() => {
        function isEmpty(obj) {
            return Object.keys(obj).length === 0;
        }
        async function getactivitydetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/manufacturer-details`, { method: 'POST', body: JSON.stringify({ "id": props.match.params.id, "page_name":page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(isEmpty(body)===false)
            {
                var temp_arr = [];
                for (var p in body) {
                    temp_arr.push(body[p]);
                }
                console.log(temp_arr[0]);
                var temp_obj = {};
                temp_obj.full_name = temp_arr[0].manufacturer_name;
                temp_obj.email = temp_arr[0].email;
                temp_obj.phone = temp_arr[0].phone;
                temp_obj.mobile = temp_arr[0].mobile;
                temp_obj.note = temp_arr[0].note;
                temp_obj.website = temp_arr[0].website;
                temp_obj.add_date = temp_arr[0].add_date;
                set_basicInformations(temp_obj);
                temp_obj = {};
                temp_obj.street = temp_arr[0].street;
                temp_obj.city = temp_arr[0].city;
                temp_obj.pincode = temp_arr[0].pincode;
                temp_obj.country = temp_arr[0].country;
                temp_obj.state = temp_arr[0].state;
                set_addressDetails(temp_obj);
                set_attachMents(temp_arr[1]);
                setloading(true);
            }
            else
            {
                setstate(1);
            }
        }
		if(read || write)
        {
            (user.email && visibility) && getactivitydetails();
        }

    }, [user, visibility, read, write])

    const showImage = (src) => {
        // alert('show image');
        const model_img_id = document.getElementById('inside_model_img');
        model_img_id.src = src;
    }
    // console.log(timezone_change);

    return (
        <>
			{
               (redirect == 1 || (read == 0 && write == 0)) ? 
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> 
				:
                null
            }
			
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                            <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Manufacturer Details
                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                            </h1>
                        </div>
                    </div>
                </div>

                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div className="container" id="kt_content_container">
                        <div className="card  pt-0 pb-15 px-3 px-xl-10">
                            <br />

                            {loading ?
                                <>

                                    <div className="card mb-5 mb-xl-10">
                                        <div className="card-body pt-9 pb-0">

                                            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">

                                                {
                                                    attachMents.length > 0 && attachMents.map((obj) => {
                                                        return (
                                                            <>
                                                                {
                                                                    obj.file_name.startsWith('profile') ?
                                                                        <div className="me-7 mb-4 rounded">
                                                                            <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative rounded">
                                                                                <img style={{ "max-height": "150px", "max-width": "150px" }} src={`https://shavastra-gn.s3.ap-south-1.amazonaws.com/manufacturer_profile_pictures/${obj.file_name}`} alt="image" className="rounded" />
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                            </>
                                                        )
                                                    })
                                                }


                                                <div className="flex-grow-1">

                                                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">

                                                        <div className="d-flex flex-column">


                                                            <div className="d-flex align-items-center mb-2">
                                                                <a className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">{basicInformations.full_name}</a>
                                                            </div>

                                                            <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                                                <a className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                                                                    {user.email}
                                                                </a>
                                                            </div>
                                                            <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                                                <a className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                                                                    {addressDetails.city} {addressDetails.state} {addressDetails.street} {addressDetails.pincode}
                                                                </a>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-2">
                                                                <a className="text-gray-800 text-hover-primary fs-6 fw-bolder me-1">{basicInformations.phone}</a>
                                                            </div>

                                                        </div>

                                                    </div>


                                                </div>

                                            </div>



                                        </div>

                                        <div class="card mt-5 mt-xxl-8">

                                            <div className="card-rounded bg-light d-flex flex-stack flex-wrap p-5 col-12">
                                                <ul className="nav flex-wrap border-transparent fw-bolder col-12" role="tablist">
                                                    <li className="nav-item my-1 col-4" role="presentation">
                                                        <a className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase active" data-bs-toggle="tab" href="#kt_table_widget_5_tab_1">Basic Information</a>
                                                    </li>
                                                    <li className="nav-item my-1 col-4" role="presentation">
                                                        <a className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase" data-bs-toggle="tab" href="#kt_table_widget_5_tab_2">Address Details</a>
                                                    </li>
                                                    <li className="nav-item my-1 col-4" role="presentation">
                                                        <a className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase" data-bs-toggle="tab" href="#kt_table_widget_5_tab_3">Documents</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card card-xxl-stretch mb-5 mb-xl-10">



                                        <div class="card-body py-3">
                                            <div class="tab-content">
                                                <div class="tab-pane fade show active" id="kt_table_widget_5_tab_1">
                                                    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
                                                        <div class="card-header cursor-pointer">
                                                            <div class="card-title m-0">
                                                                <h3 class="fw-bolder m-0">Profile Details</h3>
                                                            </div>
                                                        </div>
                                                        <div class="card-body p-9">
                                                            <div class="row mb-7">
                                                                <label class="col-lg-4 fw-bold text-muted">Full Name</label>
                                                                <div class="col-lg-8">
                                                                    <span class="fw-bolder fs-6 text-dark">{basicInformations.full_name}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-7">
                                                                <label class="col-lg-4 fw-bold text-muted">Email</label>
                                                                <div class="col-lg-8 fv-row">
                                                                    <span class="fw-bold fs-6">{basicInformations.email}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-7">
                                                                <label class="col-lg-4 fw-bold text-muted">Phone Number
                                                                    <i class="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active"></i></label>
                                                                <div class="col-lg-8 d-flex align-items-center">
                                                                    <span class="fw-bolder fs-6 me-2">{basicInformations.phone}</span>
                                                                    <span class="badge badge-success">Verified</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-7">
                                                                <label class="col-lg-4 fw-bold text-muted">Alternative Phone Number
                                                                    <i class="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active"></i></label>
                                                                <div class="col-lg-8 d-flex align-items-center">
                                                                    <span class="fw-bolder fs-6 me-2">{basicInformations.mobile}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-7">
                                                                <label class="col-lg-4 fw-bold text-muted">Website</label>
                                                                <div class="col-lg-8">
                                                                    <a href="#" class="fw-bold fs-6 text-dark text-hover-primary">{basicInformations.website}</a>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="kt_table_widget_5_tab_2">
                                                    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
                                                        <div class="card-header cursor-pointer">
                                                            <div class="card-title m-0">
                                                                <h3 class="fw-bolder m-0">Address Details</h3>
                                                            </div>
                                                        </div>
                                                        <div class="card-body p-9">
                                                            <div class="row mb-7">
                                                                <label class="col-lg-4 fw-bold text-muted">Street</label>
                                                                <div class="col-lg-8">
                                                                    <span class="fw-bold fs-6 text-dark">{addressDetails.street}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-7">
                                                                <label class="col-lg-4 fw-bold text-muted">City</label>
                                                                <div class="col-lg-8 fv-row">
                                                                    <span class="fw-bold fs-6">{addressDetails.city}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-7">
                                                                <label class="col-lg-4 fw-bold text-muted">Pin Code</label>
                                                                <div class="col-lg-8 fv-row">
                                                                    <span class="fw-bold fs-6">{addressDetails.pincode}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-7">
                                                                <label class="col-lg-4 fw-bold text-muted">Country</label>
                                                                <div class="col-lg-8 fv-row">
                                                                    <span class="fw-bold fs-6">{addressDetails.country}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-7">
                                                                <label class="col-lg-4 fw-bold text-muted">State</label>
                                                                <div class="col-lg-8 fv-row">
                                                                    <span class="fw-bold fs-6">{addressDetails.state}</span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="kt_table_widget_5_tab_3">
                                                    <div class="timeline-content mb-10 mt-n1 py-4">
                                                        <div class="pe-3 mb-5">
                                                            <div class="fs-5 fw-bold mb-2">{basicInformations.note}</div>
                                                            <div class="d-flex align-items-center mt-1 fs-6">
                                                                <div class="text-muted me-2 fs-7">{moment(basicInformations.add_date).format('lll')}</div>
                                                            </div>
                                                        </div>
                                                        <div class="overflow-auto pb-5">
                                                            <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">
                                                                {
                                                                    attachMents.length > 0 && attachMents.map((obj) => {
                                                                        return (
                                                                            <>
                                                                                {
                                                                                    obj.file_name.startsWith('profile') ?
                                                                                        null
                                                                                        :
                                                                                        <div className="overlay me-10">
                                                                                            <div className="overlay-wrapper">
                                                                                                <img id='inside_map_img' alt='img' className="rounded w-200px" src={`https://shavastra-udf.s3.ap-south-1.amazonaws.com/manufacturer_documents/${obj.file_name}`} />
                                                                                            </div>
                                                                                            <div className="overlay-layer bg-dark bg-opacity-10 rounded">
                                                                                                <a onClick={() => showImage(`https://shavastra-udf.s3.ap-south-1.amazonaws.com/manufacturer_documents/${obj.file_name}`)} className="btn btn-sm btn-primary me-3 rounded" data-bs-toggle="modal" data-bs-target="#kt_modal_offer_a_deal">View</a>
                                                                                            </div>
                                                                                        </div>
                                                                                }
                                                                                

                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                                {
                                                                    attachMents.length === 0 &&
                                                                    <>
                                                                    <div className="flex-lg-row-fluid me-lg-15">
                                                                        <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                                                            <div className="card-body pt-0">
                                                                                <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                                                <div className="d-flex align-items-center p-3 mb-2">


                                                                                </div>


                                                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                                    <div className="d-flex flex-stack flex-grow-1">
                                                                                        <div className="fw-bold">
                                                                                            <h4 className="text-gray-800 fw-bolder">No Documents Available</h4>
                                                                                            <div className="fs-6 text-gray-600">
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>

                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    </>

                                                                }


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </>
                                :
                                (state == 1 ) ?
                                    <>

                                        <div className="flex-lg-row-fluid me-lg-15">
                                            <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                                <div className="card-body pt-0">
                                                    <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                    <div className="d-flex align-items-center p-3 mb-2">


                                                    </div>


                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
                                                                <div className="fs-6 text-gray-600">
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </>
                                : <>
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />

                                    <div className="flex-lg-row-fluid me-lg-15">
                                        <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                        </div>
                                    </div>
                                </>
                                
                            }

                            <br />
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade my-4" id="kt_modal_offer_a_deal" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-800px">
                    <div style={{ borderRadius: '30px' }} className="modal-content">
                        <div className="modal-header py-0 d-flex justify-content-between">
                            <h2></h2>
                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <img id='inside_model_img' style={{ height: "500px", padding: '25px', marginTop: '0' }} />
                    </div>
                </div>
            </div>


            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>


        </>);

};

export default ViewManufacturerDetails;
