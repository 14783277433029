import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import { Link, Redirect } from 'react-router-dom'
import country_arr from './countries-arr.js';
import s_a from './states-arr.js';

import Select from 'react-select';

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';

import moment from 'moment';


function BillingAddress(props) {
	let [subscription_details, setSubscriptionDetails] = useState([]);
	let [user, setUser] = useState({});
	let [username, setUsername] = useState("");
	let [image, setImage] = useState("");
	let [currency, setCurrency] = useState("default");
	let [state, setstate] = useState(2);

	let [date, setDate] = useState(new Date().toString());
	let [expiry_date, setExpiryDate] = useState(new Date().toString());

	let [date2, setDate2] = useState("");
	let [expiry_date2, setExpiryDate2] = useState("");

	let [country_array, set_country_array] = useState([]);

	let [state_array, set_state_array] = useState([]);

	let [country, setcountry] = useState("");
	let [state1, setstate1] = useState("");

	let [timer, setTimer] = useState(0);
	let [clock_timer, setClockTimer] = useState(0);
	let [name, setname] = useState("");
	const [visibility, setvisibility] = useState(0);
	let [redirect, setredirect] = useState(0);
	//const [organization_id, setOrganization_id] = useState("");
	let [read, setread] = useState(2);
	let [write, setwrite] = useState(2);
	let loader = 0;

	var page_name = "billing-address"
	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	const renderTime = ({ remainingTime }) => {

		if (remainingTime === 0) {
			//return <div className="timer">Too lale...</div>;
			window.location.href = "/login";
		}

		return (
			<div className="timer">
				<div className="text">Remaining</div>
				<div className="value">{remainingTime}</div>
				<div className="text">seconds</div>
			</div>
		);
	};

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}


	useEffect(() => {
		const validateJWT = () => {

			if (getCookie("access_token") != "") {
				var object = {};
				object.token = getCookie("access_token");

				var headers = {
					headers: {
						"Content-Type": "application/json",
						"Accept": "*/*"
					}
				}
				axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						$.get('https://www.cloudflare.com/cdn-cgi/trace', function (data) {
							data = data.trim().split('\n').reduce(function (obj, pair) {
								pair = pair.split('=');
								return obj[pair[0]] = pair[1], obj;
							}, {});
							//loadCurrency2(data.loc);

						});


						var user_object = {};
						user_object.email = res.data.email;
						user_object.name = res.data.name;
						user_object.img = res.data.img;
						user_object.phone = res.data.phone;
						setUser(user_object)
						setUsername(res.data.name)
					}
					else {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
					.catch(function (error) {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					});

				axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var token = res.data.audience_license;
						if (res.data.organization_flag == 0) {
							swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
								window.location.href = "/login"
							});
						}
						else {
							var path = window.location.pathname;
							path = path.replace(/\/$/, "");
							path = decodeURIComponent(path);
							var original_url = path.split("/")[1]
							let index = 0;
							let temp = -1;
							for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
								let component = res.data.user_access.access[i].inside_components;
								for (let j = 0; j < component.length; j++) {
									if (component[j].hasOwnProperty(original_url)) {
										index = i;
										if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
											setvisibility(1);
											setread(res.data.user_access.access[index].inside_components[j]["read"])
											setwrite(res.data.user_access.access[index].inside_components[j]["write"])
											// setwrite(0)
											break;
										}
										else {
											setredirect(1);
										}
									}
									else
									{
										temp = 2;
									}
								}
							}
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
						}
						if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
							setCookie('access_token', res.data.audience_license, 1)
							setTimer(process.env.REACT_APP_JWT_EXPIRY);
							//setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
						}
						else {
							setTimer(res.data.audience_count)
							//setClockTimer(res.data.audience_count)
						}
					}
					else {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
					.catch(function (error) {
						console.log(error);
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					});
			}
			else {
				swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
					window.location.href = "/login"
				});
			}
		}

		validateJWT();

		let today1 = new Date().toLocaleString('hi-IN', { timeZone: 'Asia/Kolkata', hour12: false });
		var today_array = today1.split(", ");
		var date_string = today_array[0];
		var date_string_array = date_string.split("/");
		console.log(date_string_array);
		console.log(String(date_string_array[2]) + "-" + String(date_string_array[1]) + "-" + date_string_array[0].padStart(2, '0'));
		setDate2(String(date_string_array[2]) + "-" + String(date_string_array[1]) + "-" + date_string_array[0].padStart(2, '0'))
		let date = new Date(date_string_array[2], date_string_array[1], date_string_array[0].padStart(2, '0'));
		let longMonth = date.toLocaleString('en-us', { month: 'long' }); var today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

		var today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

		setDate(today)

		var today2 = new Date();
		if (props.location.state.duration == "month") {
			var today3 = new Date(today2.setDate(today2.getDate() + 30));

			today1 = today3.toLocaleString('hi-IN', { timeZone: 'Asia/Kolkata', hour12: false });
			today_array = today1.split(", ");
			date_string = today_array[0];
			date_string_array = date_string.split("/");

			//setExpiryDate2(String(date_string_array[2]) + "-" + String(date_string_array[1]) + "-" + date_string_array[0].padStart(2, '0'))
			date = new Date(date_string_array[2], date_string_array[1], date_string_array[0].padStart(2, '0'));
			longMonth = date.toLocaleString('en-us', { month: 'long' });
			today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

			today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

			setExpiryDate(today)
			setExpiryDate2(moment.utc().add(30, 'days').format('YYYY-MM-DD'))
		}
		else if (props.location.state.duration == "quarterly") {
			var today3 = new Date(today2.setDate(today2.getDate() + 90));

			today1 = today3.toLocaleString('hi-IN', { timeZone: 'Asia/Kolkata', hour12: false });
			today_array = today1.split(", ");
			date_string = today_array[0];
			date_string_array = date_string.split("/");
			//setExpiryDate2(String(date_string_array[2]) + "-" + String(date_string_array[1]) + "-" + date_string_array[0].padStart(2, '0'))
			date = new Date(date_string_array[2], date_string_array[1], date_string_array[0].padStart(2, '0'));
			longMonth = date.toLocaleString('en-us', { month: 'long' });
			today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

			today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

			setExpiryDate(today)

			setExpiryDate2(moment.utc().add(90, 'days').format('YYYY-MM-DD'))
		}
		else if (props.location.state.duration == "half yearly") {
			var today3 = new Date(today2.setDate(today2.getDate() + 180));

			today1 = today3.toLocaleString('hi-IN', { timeZone: 'Asia/Kolkata', hour12: false });
			today_array = today1.split(", ");
			date_string = today_array[0];
			date_string_array = date_string.split("/");
			//setExpiryDate2(String(date_string_array[2]) + "-" + String(date_string_array[1]) + "-" + date_string_array[0].padStart(2, '0'))
			date = new Date(date_string_array[2], date_string_array[1], date_string_array[0].padStart(2, '0'));
			longMonth = date.toLocaleString('en-us', { month: 'long' });
			today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

			today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

			setExpiryDate(today)

			setExpiryDate2(moment.utc().add(180, 'days').format('YYYY-MM-DD'))
		}
		else if (props.location.state.duration == "yearly") {
			var today3 = new Date(today2.setDate(today2.getDate() + 365));

			today1 = today3.toLocaleString('hi-IN', { timeZone: 'Asia/Kolkata', hour12: false });
			today_array = today1.split(", ");
			date_string = today_array[0];
			date_string_array = date_string.split("/");
			//setExpiryDate2(String(date_string_array[2]) + "-" + String(date_string_array[1]) + "-" + date_string_array[0].padStart(2, '0'))
			date = new Date(date_string_array[2], date_string_array[1], date_string_array[0].padStart(2, '0'));
			longMonth = date.toLocaleString('en-us', { month: 'long' });
			today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

			today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

			setExpiryDate(today)

			setExpiryDate2(moment.utc().add(180, 'days').format('YYYY-MM-DD'))
		}

		var obj_array = [];
		var obj2 = {};
		country_arr.country_arr.map((obj, index) => {
			obj2 = {};
			obj2.label = obj;
			if (index > 0) {
				obj2.value = obj;
			}
			else {
				obj2.value = "";
			}
			obj_array.push(obj2);
		})
		set_country_array(obj_array);

	}, [])

	useEffect(() => {
		if (timer > 0) {
			const timer_decrease = setInterval(() => {
				setTimer(timer - 1);
				if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
					if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
						setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
						window.$('#kt_modal_timer').modal('show');
					}
				}
			}, 1000);

			return () => clearInterval(timer_decrease);
		}
	}, [timer]);

	useEffect(() => {
		async function getaccountdetails() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-account-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			//console.log(body)
			if (body.length > 0) {
				setname(body[0].account_name)
				if (body[0].image_name.startsWith("http")) {
					setImage(body[0].image_name)
				}
				else if (body[0].image_name) {
					setImage(`https://shavastra-gn.s3.ap-south-1.amazonaws.com/account_image/${body[0].image_name}`);
				}
				else {
					setImage(user.img)
				}
			}
			else {

				setname(user.name)
				setImage(user.img)
			}

		}
		if (read || write) {
			user.email && visibility && getaccountdetails();
		}
	}, [user, visibility, read, write])
	//console.log(s_a.s_a);
	function loadCurrency(country) {
		setstate1("");
		var selectedCountryIndex = 0;
		country_array.map((obj, index) => {
			if (obj.value == country.value) {
				selectedCountryIndex = index;
			}
		})

		setcountry(country.value);

		set_state_array([]);
		setstate1("");

		var state_arr = s_a.s_a[selectedCountryIndex].split("|");

		var obj_array = [];
		var obj2 = {};

		obj_array.push({ value: "", label: "Select State" });
		for (let i = 0; i < state_arr.length; i++) {
			obj_array.push({ value: state_arr[i], label: state_arr[i] });
		}

		set_state_array(obj_array);

		//set_state_array(state_arr);

		var object = {};
		object.country = country.value;
		object.page_name = page_name;

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/get-country-currency`, object, headers).then((res) => {

			if (res.data.message == "found") {
				setCurrency(res.data.currency)

				subscription_details.length > 0 && subscription_details.map((obj, index) => {
					var json = obj.subscription_charges;
					var json2 = obj.subscription_features;

					json.map((obj2, index2) => {
						if (res.data.currency == obj2.currency || (obj2.default == "Yes" && res.data.currency == "default")) {
							obj.currency = res.data.currency;
							obj2.charges.map((obj3, index3) => {
								if (obj3.duration_in_month == "1" && obj3.duration_in_letter == props.location.state.duration) {
									obj.price = Number(obj3.charges - obj3.discount_amount);
								}
								if (obj3.duration_in_month == "12" && obj3.duration_in_letter == props.location.state.duration) {
									obj.price = Number(obj3.charges - obj3.discount_amount);
								}
								if (obj3.duration_in_month == "3" && obj3.duration_in_letter == props.location.state.duration) {
									obj.price = Number(obj3.charges - obj3.discount_amount);
								}
								if (obj3.duration_in_month == "6" && obj3.duration_in_letter == props.location.state.duration) {
									obj.price = Number(obj3.charges - obj3.discount_amount);
								}
							})
						}
					})
				})
				setSubscriptionDetails(subscription_details)
			}
			else {
				setCurrency("default")
				subscription_details.length > 0 && subscription_details.map((obj, index) => {
					var json = obj.subscription_charges;
					var json2 = obj.subscription_features;

					json.map((obj2, index2) => {
						if ((obj2.default == "Yes")) {
							obj.currency = obj2.currency;

							obj2.charges.map((obj3, index3) => {
								if (obj3.duration_in_month == "1" && obj3.duration_in_letter == props.location.state.duration) {
									obj.price = Number(obj3.charges - obj3.discount_amount);
								}
								if (obj3.duration_in_month == "12" && obj3.duration_in_letter == props.location.state.duration) {
									obj.price = Number(obj3.charges - obj3.discount_amount);
								}
								if (obj3.duration_in_month == "3" && obj3.duration_in_letter == props.location.state.duration) {
									obj.price = Number(obj3.charges - obj3.discount_amount);
								}
								if (obj3.duration_in_month == "6" && obj3.duration_in_letter == props.location.state.duration) {
									obj.price = Number(obj3.charges - obj3.discount_amount);
								}
							})
						}
					})
				})
				setSubscriptionDetails(subscription_details)
			}
		})
			.catch(function (error) {
				setCurrency("default")
				subscription_details.length > 0 && subscription_details.map((obj, index) => {
					var json = obj.subscription_charges;
					var json2 = obj.subscription_features;

					json.map((obj2, index2) => {
						if ((obj2.default == "Yes")) {
							obj.currency = obj2.currency;
							obj2.charges.map((obj3, index3) => {
								if (obj3.duration_in_month == "1" && obj3.duration_in_letter == props.location.state.duration) {
									obj.price = Number(obj3.charges - obj3.discount_amount);
								}
								if (obj3.duration_in_month == "12" && obj3.duration_in_letter == props.location.state.duration) {
									obj.price = Number(obj3.charges - obj3.discount_amount);
								}
								if (obj3.duration_in_month == "3" && obj3.duration_in_letter == props.location.state.duration) {
									obj.price = Number(obj3.charges - obj3.discount_amount);
								}
								if (obj3.duration_in_month == "6" && obj3.duration_in_letter == props.location.state.duration) {
									obj.price = Number(obj3.charges - obj3.discount_amount);
								}
							})
						}
					})
				})

				setSubscriptionDetails(subscription_details)
			});
	}

	function loadCurrency2(country) {
		var object = {};
		object.country = country;
		object.page_name = page_name;

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/get-country-currency`, object, headers).then((res) => {

			if (res.data.message == "found") {
				setCurrency(res.data.currency)
			}
			else {
				setCurrency("default")
			}
		})
			.catch(function (error) {
				setCurrency("default")
			});
	}

	useEffect(() => {
		async function getsubscriptiondetails() {
			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-subscription-details/${props.location.state.subscription_id}`, {
				method: 'POST',
				body: JSON.stringify({ "email": user.email, "page_name": page_name })
				, headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
			}
			);
			const body = await response.json();

			body.map((obj, index) => {
				var json = JSON.parse(obj.subscription_charges);
				var json2 = JSON.parse(obj.subscription_features);
				obj.subscription_charges = json;
				obj.subscription_features = json2;

				json.map((obj2, index2) => {
					if (currency == obj2.currency || (obj2.default == "Yes" && currency == "default")) {
						obj.currency = obj2.currency;
						obj2.charges.map((obj3, index3) => {
							//console.log(obj);
							if (obj3.duration_in_month == "1" && obj3.duration_in_letter == props.location.state.duration) {
								obj.price = Number(obj3.charges - obj3.discount_amount);
							}
							if (obj3.duration_in_month == "12" && obj3.duration_in_letter == props.location.state.duration) {
								obj.price = Number(obj3.charges - obj3.discount_amount);
							}
							if (obj3.duration_in_month == "3" && obj3.duration_in_letter == props.location.state.duration) {
								obj.price = Number(obj3.charges - obj3.discount_amount);
							}
							if (obj3.duration_in_month == "6" && obj3.duration_in_letter == props.location.state.duration) {
								obj.price = Number(obj3.charges - obj3.discount_amount);
							}
						})

					}

				})
			})
			if (body.length > 0) {
				setstate(1)
			}
			else {
				setstate(0)
			}

			setSubscriptionDetails(body)
		}
		if (read || write) {
			(user.email && currency && visibility) && getsubscriptiondetails()
		}
	}, [currency, user, visibility, read, write]);

	useEffect(() => {
		if (!document.getElementById("country_script")) {
			setTimeout(() => {
				var xhrObj = new XMLHttpRequest();

				xhrObj.open('GET', "/assets/countries.js", false);
				xhrObj.send('');

				var se = document.createElement('script');
				se.type = "text/javascript";
				se.id = "country_script";
				se.text = xhrObj.responseText;
				//document.body.appendChild(se);
			}, 5000);
			loader = 1;
		}
	}, [subscription_details]);




	const add_address = async (e) => {
		e.preventDefault();

		var object = {};
		object.address_details = {};
		//object.organization_id = organization_id;
		object.address_details.first_name = document.getElementById("first_name").value;
		object.address_details.last_name = document.getElementById("last_name").value;
		object.address_details.country = country;
		object.address_details.state = state1;
		object.address_details.billing_email = document.getElementById("email").value;
		object.address_details.phone = document.getElementById("mobileno").value;
		object.address_details.pincode = document.getElementById("pincode").value;
		object.address_details.city = document.getElementById("city").value;
		object.address_details.address_line1 = document.getElementById("address_line1").value;
		object.address_details.address_line2 = document.getElementById("address_line2").value;
		object.address_details.email = user.email;

		object.email = user.email;
		object.subscription_id = props.location.state.subscription_id;

		//object.starting_date = date2;
		object.starting_date = moment.utc().format('YYYY-MM-DD');
		//object.ending_date = expiry_date2;
		object.ending_date = expiry_date2;

		object.currency = currency;
		object.duration = props.location.state.duration;
		object.price = subscription_details[0].price;
		object.purchase_flag = "Purchase";
		object.product_id = props.location.state.product_id;
		object.page_name = page_name;

		if (object.address_details.first_name == "") {
			swal("Warning!", "Provide a first name", "warning");
			return false;
		}
		else if (!isNaN(object.address_details.first_name)) {
			swal("Warning!", "Provide a first name", "warning");
			return false;
		}

		if (object.address_details.last_name == "") {
			swal("Warning!", "Provide a last name", "warning");
			return false;
		}
		else if (!isNaN(object.address_details.last_name)) {
			swal("Warning!", "Provide a last name", "warning");
			return false;
		}

		if (object.address_details.country == "") {
			swal("Warning!", "Provide a country", "warning");
			return false;
		}
		else if (!isNaN(object.address_details.country)) {
			swal("Warning!", "Provide a country", "warning");
			return false;
		}

		if (object.address_details.state == "") {
			swal("Warning!", "Provide a state", "warning");
			return false;
		}
		else if (!isNaN(object.address_details.state)) {
			swal("Warning!", "Provide a state", "warning");
			return false;
		}

		if (object.address_details.billing_email == "") {
			swal("Warning!", "Provide a email", "warning");
			return false;
		}
		else if (!isNaN(object.address_details.billing_email)) {
			swal("Warning!", "Provide a email", "warning");
			return false;
		}

		if (object.address_details.phone == "") {
			swal("Warning!", "Provide a phone", "warning");
			return false;
		}


		if (object.address_details.pincode == "") {
			swal("Warning!", "Provide a Zip Code", "warning");
			return false;
		}


		if (object.address_details.city == "") {
			swal("Warning!", "Provide a city", "warning");
			return false;
		}


		if (object.address_details.address_line1 == "") {
			swal("Warning!", "Please fill the Address Line1", "warning");
			return false;
		}

		if (document.getElementById("kt_careers_submit_button")) {
			document.getElementById("kt_careers_submit_button").setAttribute("data-kt-indicator", "on");
			document.getElementById("kt_careers_submit_button").disabled = !0;
		}

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		//console.log(object);

		axios.post(`${process.env.REACT_APP_SERVER_URL}/add-address`, object, headers).then((res) => {
			if (res.data.operation == "success") {
				//console.log(object.currency);
				//console.log(object.price);

				if (object.currency == "INR") {
					//console.log("rpay");
					paymentHandler_razorpay_inr(object);
				}
				else if (object.currency == "EUR") {
					//console.log("stripe euro");
					paymentHandler_stripe_euro(object);
				}
				else if (object.currency == "USD") {
					//console.log("stripe usd");
					paymentHandler_stripe_usd(object);
				}
				//swal("Great!", "Address Added", "success");
			}
			else {
				if (res.data.message != "danger") {
					swal("Oops!", res.data.message, "error").then((res) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "something went wrong", "error").then((res) => {
						window.location.reload();
					});
				}
			}
		})
			.catch(function (error) {
				swal("Oops!", "something went wrong", "error").then((res) => {
					window.location.reload();
				});
			});

	}

	const paymentHandler_razorpay_inr = async (object) => {
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		object.payment_gateway = "razorpay";
		object.response = "";

		const API_URL = process.env.REACT_APP_SERVER_URL;
		//const orderUrl = `${API_URL}/place-order`;
		const orderUrl = `${API_URL}/create-session-razorpay`;
		/*
		var payment_object = {};
		payment_object.amount = object.price;
						
		const response = await axios.post(orderUrl,payment_object);
		const { data } = response;
		console.log(data);
		*/

		const response = await axios.post(orderUrl, object);
		const { data } = response;

		//console.log(data.id);

		object.s_id = data.id;
		object.page_name = page_name;

		axios.post(`${process.env.REACT_APP_SERVER_URL}/purchase-plan-razorpay`, object, headers).then(async (res) => {
			if (res.data && res.data.operation == "success") {
				const options = {
					key: process.env.REACT_APP_RAZOR_PAY_KEY_ID,
					subscription_id: data.id,

					handler: async (response) => {

						try {
							const paymentId = response.razorpay_payment_id;

							if (response.razorpay_payment_id) {
								object.response = response;
								object.id = res.data.id;
								object.page_name = page_name;

								axios.post(`${process.env.REACT_APP_SERVER_URL}/purchase-plan-razorpay-update`, object, headers).then((res2) => {
									if (res2.data && res2.data.operation == "success") {
										swal("Great!", "Payment Successfull!", "success").then((value) => {
											window.location.href = "/payment-response/success/" + res.data.id;
										});
									}
									else {
										swal("Great!", "We are still processing your payment. Please check back after few minutes.", "success").then((value) => {
											window.location.href = "/payment-response/success/" + res.data.id;
										});
									}
								});
							}
							else {
								swal("Oops!", "Something went wrong!", "error");
							}


							//const url = `${API_URL}/capture/${paymentId}`;
							/*
							const url = `${API_URL}/verification`;
						    
							var object2 = {};
							object2.razorpay_payment_id = response.razorpay_payment_id;
							object2.razorpay_signature = response.razorpay_signature;
							object2.sid = data.id;
				   	
							const captureResponse = await axios.post(url, object2)
						    
							let response1 = JSON.parse(captureResponse.data);
						    
							if(response1.id)
							{
							   object.response = response1;
							   object.id = res.data.id;
						   	
							   axios.post(`${process.env.REACT_APP_SERVER_URL}/purchase-plan-razorpay-update`,object, headers).then((res) => {
								   if(res.data && res.data.operation == "success")
								   {
									   swal("Great!", "Payment Successfull!", "success").then((value) => {
										   //window.location.href = "/subscription-history";
									   });
								   }
								   else
								   {
									   swal("Great!", "Order Placed!", "success").then((value) => {
										   //window.location.href = "/subscription-history";
									   });
								   }
							   });
						   	
							}
							*/
						} catch (err) {
							console.log(err);
							swal("Oops!", "Something went wrong!", "error");
						}

					},
					theme: {
						color: "#686CFD",
					},
				};

				const rzp1 = new window.Razorpay(options);
				rzp1.open();
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});

	};


	const paymentHandler_stripe_euro = async (object) => {
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		object.response = "";
		object.payment_gateway = "stripe-euro";
		object.page_name = page_name;

		axios.post(`${process.env.REACT_APP_SERVER_URL}/purchase-plan-stripe`, object, headers).then(async (res) => {
			if (res.data && res.data.operation == "success") {
				const API_URL = process.env.REACT_APP_SERVER_URL;
				const orderUrl = `${API_URL}/create-session-euro`;
				var payment_object = {};
				payment_object.id = res.data.id;
				payment_object.email = res.data.email;

				object.id = res.data.id;
				object.email = user.email;
				object.page_name = page_name;

				const response = await axios.post(orderUrl, object);
				console.log(response.data.message.id);

				if (response.data.message.id) {
					var stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_EURO);
					$(document).ready(function () {
						setTimeout(function () {
							stripe.redirectToCheckout({
								sessionId: response.data.message.id
							}).then(function (result) {
							});
						}, 1000);
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});
	};

	const paymentHandler_stripe_usd = async (object) => {
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		object.response = "";
		object.payment_gateway = "stripe-usd";
		object.page_name = page_name;

		axios.post(`${process.env.REACT_APP_SERVER_URL}/purchase-plan-stripe`, object, headers).then(async (res) => {
			if (res.data && res.data.operation == "success") {
				const API_URL = process.env.REACT_APP_SERVER_URL;
				const orderUrl = `${API_URL}/create-session-usd`;
				var payment_object = {};
				payment_object.id = res.data.id;
				payment_object.email = res.data.email;

				object.id = res.data.id;
				object.email = user.email;

				const response = await axios.post(orderUrl, object);
				//console.log("session id", response.data.message.id);

				if (response.data.message.id) {
					var stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_USD);
					$(document).ready(function () {
						setTimeout(function () {
							stripe.redirectToCheckout({
								sessionId: response.data.message.id
							}).then(function (result) {
							});
						}, 1000);
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});
	};


	const paymentHandler_stripe_inr = async (object) => {
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		object.response = "";
		object.payment_gateway = "stripe-inr";
		object.page_name = page_name;

		axios.post(`${process.env.REACT_APP_SERVER_URL}/purchase-plan-stripe`, object, headers).then(async (res) => {
			if (res.data && res.data.operation == "success") {
				const API_URL = process.env.REACT_APP_SERVER_URL;
				const orderUrl = `${API_URL}/create-session-inr`;
				var payment_object = {};
				payment_object.id = res.data.id;
				payment_object.email = res.data.email;

				object.id = res.data.id;
				object.email = user.email;

				const response = await axios.post(orderUrl, object);
				//console.log("session id", response.data.message.id);

				if (response.data.message.id) {
					var stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_USD);
					$(document).ready(function () {
						setTimeout(function () {
							stripe.redirectToCheckout({
								sessionId: response.data.message.id
							}).then(function (result) {
							});
						}, 1000);
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});
	};


	//console.log(state1);
	return (
		<>
			{
				(redirect == 1 || (read == 0 && write == 0)) ?
					<>
						<Redirect to={{
							pathname: "/unauthorized",
						}}
						/>
					</> :
					null
			}
			{subscription_details.length > 0 ?
				<>
					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
						<div className="toolbar" id="kt_toolbar">
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
								<div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
									<h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Add Billing Address
										<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
										<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
									</h1>

								</div>

							</div>
						</div>


						<div className="post d-flex flex-column-fluid" id="kt_post">
							<div className="container" id="kt_content_container">
								<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
									<br />




									<div className="d-flex flex-column flex-lg-row mb-17">

										<div className="flex-lg-row-fluid me-0 me-lg-20">

											<form className="form mb-15" id="kt_careers_form" onSubmit={(e) => { add_address(e); }}>

												<div className="row mb-5">

													<div className="col-md-6 fv-row">

														<label className="required fs-5 fw-bold mb-2">First Name</label>


														<input type="text" className="form-control form-control-solid" placeholder="" name="first_name" id="first_name" />

													</div>


													<div className="col-md-6 fv-row">

														<label className="required fs-5 fw-bold mb-2">Last Name</label>


														<input type="text" className="form-control form-control-solid" placeholder="" name="last_name" id="last_name" />

													</div>

												</div>


												<div className="row mb-5">

													<div className="col-md-6 fv-row">

														<label className="required fs-5 fw-bold mb-2">Email</label>


														<input className="form-control form-control-solid" placeholder="" name="email" id="email" value={user.email} readonly />

													</div>


													<div className="col-md-6 fv-row">

														<label className="fs-5 fw-bold mb-2">Mobile No</label>


														<input type="text" className="form-control form-control-solid" placeholder="" name="mobileno" id="mobileno" value={user.phone} readonly />

													</div>

												</div>



												<div className="d-flex flex-column mb-5 fv-row">

													<label className="d-flex align-items-center fs-5 fw-bold mb-2">
														<span className="required">Country</span>
														<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Your payment statements may very based on selected position"></i>
													</label>

													<Select
														name="country"
														id="country"
														options={country_array}
														onChange={(e) => { loadCurrency(e); }}
													/>

												</div>






												<div className="d-flex flex-column mb-5 fv-row">

													<label className="d-flex align-items-center fs-5 fw-bold mb-2">
														<span className="required">State</span>
														<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Your payment statements may very based on selected position"></i>
													</label>

													<Select
														name="state"
														id="state"
														options={state_array}
														value={
															state_array.filter(option =>
																option.value == state1)[0]
														}
														onChange={(e) => { setstate1(e.value); }}
													/>

												</div>

												<div className="row mb-5">

													<div className="col-md-6 fv-row">

														<label className="required fs-5 fw-bold mb-2">City</label>


														<input type="text" className="form-control form-control-solid" placeholder="" name="city" id="city" />

													</div>

													<div className="col-md-6 fv-row">

														<label className="required fs-5 fw-bold mb-2">Zip Code</label>


														<input type="text" className="form-control form-control-solid" placeholder="" name="pincode" id="pincode" />

													</div>

												</div>


												<div className="row mb-5">

													<div className="col-md-12 fv-row">

														<label className="required fs-5 fw-bold mb-2">Address Line 1</label>


														<input type="text" className="form-control form-control-solid" placeholder="" name="address_line1" id="address_line1" />

													</div>

												</div>

												<div className="row mb-5">

													<div className="col-md-12 fv-row">

														<label className=" fs-5 fw-bold mb-2">Address Line 2</label>


														<input type="text" className="form-control form-control-solid" placeholder="" name="address_line2" id="address_line2" />

													</div>

												</div>



												<div className="separator mb-8"></div>

												{
													write ?
														<button type="submit" className="btn btn-primary rounded" id="kt_careers_submit_button" >

															<span className="indicator-label">Save & Proceed</span>
															<span className="indicator-progress">Please wait...
																<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>

														</button>
														:
														<button type="submit" className="btn btn-primary rounded" id="kt_careers_submit_button" disabled>

															<span className="indicator-label">Save & Proceed</span>
															<span className="indicator-progress">Please wait...
																<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>

														</button>
												}


											</form>


										</div>




										<div className="flex-column flex-lg-row-auto w-100 w-xl-300px mb-10">

											<div className="card card-flush pt-3 mb-0" data-kt-sticky="true" data-kt-sticky-name="subscription-summary" data-kt-sticky-offset="{default: false, xl: '200px'}" data-kt-sticky-width="{lg: '250px', xl: '300px'}" data-kt-sticky-left="auto" data-kt-sticky-top="150px" data-kt-sticky-animation="false" data-kt-sticky-zindex="95">

												<div className="card-header">
													<div className="card-title">
														<h2>Summary</h2>
													</div>
												</div>


												<div className="card-body pt-0 fs-6">

													<div className="mb-7">

														<div className="d-flex align-items-center">

															<div className="symbol symbol-60px symbol-circle me-3">
																<img alt="Pic" src={image} />
															</div>


															<div className="d-flex flex-column">

																<a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-2">{name}</a>

																<a className="fw-bold text-gray-600 text-hover-primary">{user.email}</a>

															</div>

														</div>

													</div>


													<div className="separator separator-dashed mb-7"></div>

													{subscription_details.length > 0 &&
														<div className="mb-7">
															<h5 className="mb-3">Product details</h5>
															<div className="mb-0">
																<span className="badge badge-light-info me-2 rounded">{subscription_details[0].subscription_name}</span>
																<span className="fw-bold text-gray-600">{(subscription_details[0].currency == "INR" ? "₹" : (subscription_details[0].currency == "EUR" ? "€" : ((subscription_details[0].currency == "USD" ? "$" : null))))}

																	{subscription_details[0].price ? subscription_details[0].price : null}

																	/ {props.location.state.duration == "month" ? "Month" : (props.location.state.duration == "quarterly" ? "3 Months" : (props.location.state.duration == "half yearly" ? "6 Months" : (props.location.state.duration == "yearly" ? "Year" : null)))}</span>
															</div>
														</div>
													}


													<div className="separator separator-dashed mb-7"></div>


													<div className="mb-10">

														<h5 className="mb-4">Subscription Details</h5>


														<table className="table fs-6 fw-bold gs-0 gy-2 gx-2">

															<tr className="">
																<td className="text-gray-400">Starting on:</td>
																<td className="text-gray-800">{date}</td>
															</tr>

															<tr className="">
																<td className="text-gray-400">Expiry:</td>
																<td className="text-gray-800">{expiry_date}</td>
															</tr>

														</table>

													</div>

												</div>

											</div>

										</div>

									</div>





									<br />
								</div>
							</div>
						</div>
					</div>

				</>
				:
				<>
					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
						<div className="toolbar" id="kt_toolbar">
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
								<div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
									<h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Save & Proceed
										<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
										<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
									</h1>

								</div>

							</div>
						</div>

						<div className="post d-flex flex-column-fluid" id="kt_post" >
							<div className="container" id="kt_content_container" >
								<div className="card modal-body pt-0 pb-15 px-5 px-xl-20" >
									<br />
									{(state == 2) ?
										<img src="images/loader-06.svg" alt="" style={loaderstyle} />
										:
										<>


											<div className="flex-lg-row-fluid me-lg-15">
												<div className="card card-flush pt-3 mb-5 mb-lg-10">

													<div className="card-header">
														<div className="card-title">
															<h2 className="fw-bolder">Billing Address</h2>
														</div>
													</div>


													<div className="card-body pt-0">
														<div className="text-gray-500 fw-bold fs-5 mb-5">Add Billing Address to a purchase the subscription:</div>
														<div className="d-flex align-items-center p-3 mb-2">


														</div>
														<div className="mb-7 d-none">
															<a className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_customer_search">Select Customer</a>
															<span className="fw-bolder text-gray-500 mx-2">or</span>
															<a className="btn btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_users_search">Add New Customer</a>
														</div>

														<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
															<div className="d-flex flex-stack flex-grow-1">
																<div className="fw-bold">
																	<h4 className="text-gray-800 fw-bolder">This Subscription is not Available.</h4>
																	<div className="fs-6 text-gray-600">
																	</div>
																</div>

															</div>

														</div>

													</div>

												</div>
											</div>
										</>
									}


									<br />
								</div>
							</div>
						</div>
					</div>
				</>
			}

			<div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

				<div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

					<div className="modal-content">

						<div className="modal-header" id="kt_modal_new_address_header">

							<h2>Session Out</h2>

							<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

								<span className="svg-icon svg-icon-1">
									<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
										<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
											<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
											<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
										</g>
									</svg>
								</span>

							</div>

						</div>

						<div className="modal-body py-10 px-lg-17">

							<div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

								<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

									<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
											<rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
											<rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
										</svg>
									</span>


									<div className="d-flex flex-stack flex-grow-1">

										<div className="fw-bold">
											<h4 className="text-gray-800 fw-bolder">Warning</h4>
											<div className="fs-6 text-gray-600">Your session is going to time out</div>
										</div>

									</div>

								</div>

								{clock_timer > 0 &&
									<div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
										<CountdownCircleTimer
											isPlaying
											duration={clock_timer}
											colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
											onComplete={() => [true, 1000]}
										>
											{renderTime}
										</CountdownCircleTimer>
									</div>
								}

							</div>

						</div>

						<div className="modal-footer flex-center">

							<button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
								<span className="indicator-label">Stay Signed in</span>
								<span className="indicator-progress">Please wait...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
							</button>

						</div>

					</div>
				</div>
			</div>


		</>
	);
}
export default BillingAddress;