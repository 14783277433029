import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import { Link, Redirect } from 'react-router-dom'
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
// import {Form, Col,Button,InputGroup} from 'react-bootstrap';
import { Formik } from "formik";
import * as yup from "yup";
import moment from 'moment';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import Select from 'react-select';
import removeImg from './icons/remove.svg'
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import uuid from 'react-uuid';

import country_arr from './countries-arr.js';
import s_a from './states-arr.js';

function UpdateUser(props) {
    let [user, setUser] = useState({});

    let [currency_price, setCurrencyPrice] = useState("");
    let [currency_date, setCurrencyDate] = useState("");

    let [loader, setLoader] = useState(0);

    let [currency_value, setCurrencyValue] = useState("");
    let [currency_id, setCurrencyId] = useState("");

    let [state, setstate] = useState(2);
    let [state1, setstate1] = useState(2);

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);
    let [subscription, set_subscription] = useState("");
    let [duration, setduration] = useState("");
    const [PlatformDetails, setPlatformDetails] = useState([]);

    let [Access, setAccess] = useState({ access: [] });
    const [newAccess, setNewAccess] = useState({ access: [] });

    // let [photoName, setPhotoName] = useState([""]);
    // let [idNames, setIdNames] = useState(["", "", ""]);
    // let [fileNames, setFileNames] = useState(["", "", "", ""]);
    let [role, setRole] = useState("");
    const [unique_id, setUnique_id] = useState(uuid());
    //const [organization_id, setOrganization_id] = useState("");
    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);
    let [UserRoles, setUserRoles] = useState([]);
    const [inputFields, setInputFields] = useState([{ fname: "", lname: "", email: "", phone: "" }]);
    let [user_access1, setuser_access1] = useState([]);

    //console.log(props.location.state.user_access);
    var page_name = "update-user";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });

                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    // console.log(res.data)
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;

                        if (res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
                        else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }

                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            res.data.audience_count = (res.data.audience_count)
                            if (res.data.audience_count > 0) {
                                const timer_decrease = setInterval(() => {
                                    res.data.audience_count = (res.data.audience_count - 1);
                                    //console.log(res.data.audience_count);
                                    if (res.data.audience_count > 0 && res.data.audience_count < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                                        if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                                            setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                                            window.$('#kt_modal_timer').modal('show');
                                        }
                                    }
                                }, 1000);

                                return () => clearInterval(timer_decrease);
                            }
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }

        validateJWT();

    }, [])

    useEffect(() => {

        async function get_roles() {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-user-roles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            setUserRoles(body)
            setstate1(1)
        }
        if (read || write) {
            (user.email && visibility) && get_roles();
        }


    }, [user, visibility, read, write])

    useEffect(() => {

        if (Access.length > 0 && user_access1.length > 0 && state1 == 1) {
            // checkFunc(JSON.parse(user_access1[0].user_access).access);
            setNewAccess(JSON.parse(user_access1[0].user_access));
        }
    }, [Access, user_access1, state1])

    useEffect(() => {
        if (UserRoles.length > 0) {
            async function getuserdetails() {
                var object = {};
                object.email = user.email;

                const response = await fetch(
                    `${process.env.REACT_APP_SERVER_URL}/inividual-user-info`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "id": props.match.params.id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
                );
                const body = await response.json();
                    console.log(body)
                if (body.length > 0) {
                    setstate(1);
                    setRole(body[0].user_role_id);
                    setAccess(JSON.parse(body[0].user_access).access);
                    setuser_access1(body);
                }
                else {
                    setstate(0);
                }

                //setLoading(true);
            }

            //setNewAccess(JSON.parse(props.location.state.user_access.access));
            getuserdetails()
        }
    }, [UserRoles])


    const handleRole = (event) => {
        setRole(event.target.value);
        setAccess((JSON.parse((UserRoles.filter((item) => { return item.user_role_id === event.target.value }))[0].user_role_access)))
        setNewAccess(JSON.parse((UserRoles.filter((item) => { return item.user_role_id === event.target.value }))[0].user_role_access));
    }


    const updateNewAccess = (val, section, sub1, sub2, part) => {
        // console.log(val, section, sub1, sub2, part)
        let temp = JSON.parse(JSON.stringify(newAccess))
        let temp2 = { access: [] }
        temp2.access = temp.access.map((obj) => {
            if (sub1 == undefined) {
                //root heading
                if (obj.key == section) {
                    return { ...obj, visibility: val ? 1 : 0 }
                }
                else {
                    return { ...obj }
                }
            }
            else {
                let temp3 = obj.inside_components.map((obj2) => {
                    if (part == undefined) {
                        //branch head
                        if (obj2.key == sub1) {
                            return { ...obj2, visibility: val ? 1 : 0 }
                        }
                        else {
                            return { ...obj2 }
                        }
                    }
                    else {
                        if (sub2 == undefined) {
                            //no nested branch
                            if (Object.keys(obj2)[0] == sub1) {
                                return { ...obj2, [part]: val ? 1 : 0 }
                            }
                            else {
                                return { ...obj2 }
                            }
                        }
                        else {
                            //nested branch
                            if (obj2.key == sub1) {
                                let temp4 = obj2.inside_components.map((obj3) => {
                                    if (Object.keys(obj3)[0] == sub2) {
                                        return { ...obj3, [part]: val ? 1 : 0 }
                                    }
                                    else {
                                        return { ...obj3 }
                                    }
                                })
                                return { ...obj2, inside_components: temp4 }
                            }
                            else {
                                return { ...obj2 }
                            }

                        }
                    }
                })

                return { ...obj, inside_components: temp3 }
            }
        })
        setNewAccess(temp2)

    }
    const register = () => {
        // var temp_access={}

        var object = {};
        if (role == "") {
            swal("Warning!", "Select a role", "warning");
            return false;
        }
        else {
            object.role = (UserRoles.filter((item) => { return item.user_role_id === role }))[0].user_role
        }
        object.user_access = newAccess
        object.page_name = page_name
        object.email = user_access1[0].user_id;
        object.user_unique_id = user_access1[0].user_unique_id;
        console.log(object);
        if (object.user_access == {} || object.user_access == []) {
            swal("Oops!", "Access permission invalid, try again", "error");
            return false;
        }
        console.log(object)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-user`, object, headers).then((res) => {
            if (res.data.operation === "user_exist") {
                swal("Oops!", "Email already exist!!", "error");
            }
            else if (res.data.operation === "success") {
                swal("Great!", "Access details updated successfully!", "success").then((value) => {
                    window.location = "/show-user"
                });
            }
            else if (res.data.message.includes("already exists")) {
                swal("Oops!", res.data.message, "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
            });
    }
    // var files = [];

    const schema = yup.object({

    });
    const ScrollToTop = () => {
        $('html').scrollTop(0);
    }

    const ScrollToBottom = () => {
        // window.scrollTo(0,document.body.scrollHeight);
        $('html,body').animate({ scrollTop: document.body.scrollHeight }, "smooth");
    }
    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }

            {state == 1 ?
                <>

                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">

                                <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                                    <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Update User
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                        <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                    </h1>

                                </div>

                            </div>
                        </div>

                        <div class="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" class="container">
                                <div class="card">
                                    <div class="card-body">
                                        <button className="btn btn-secondary rounded m-3 p-5 float-right" onClick={ScrollToBottom} title="Scroll to Bottom">
                                            <i class="las la-angle-double-down" style={{ "font-size": "32px" }}></i>
                                        </button>
                                        <div class="stepper stepper-links d-flex flex-column" id="kt_create_account_stepper">
                                            <div class="stepper-nav py-5 mt-5">

                                            </div>
                                            <Formik
                                                validationSchema={schema}
                                                onSubmit={register}
                                                initialValues={{

                                                }} >
                                                {({
                                                    // handleSubmit,
                                                    handleChange,
                                                    // handleBlur,
                                                    // values,
                                                    // touched,
                                                    // isValid,
                                                    // errors,
                                                }) => (
                                                    <form class="mx-auto mw-600px w-100 pt-15 pb-10" id="kt_create_account_form"
                                                        // onSubmit={(e) => {
                                                        // e.preventDefault();
                                                        // handleSubmit();
                                                        // }}
                                                        noValidate
                                                    >


                                                        <div class="current" data-kt-stepper-element="content">

                                                            <div class="w-100">
                                                                <div class="d-flex flex-column mb-5 fv-row">
                                                                    <label class="d-flex align-items-center fs-5 fw-bold mb-2">
                                                                        <span class="required">Role:</span>
                                                                    </label>
                                                                    <select
                                                                        name="role"
                                                                        id="role"
                                                                        data-placeholder="Select a Category..."
                                                                        class="form-select form-select-solid"
                                                                        value={role}
                                                                        onChange={(event) => { handleRole(event) }}
                                                                    >
                                                                        <option value="">Select</option>
                                                                        {
                                                                            UserRoles.length > 0 && UserRoles.map((obj) => {
                                                                                return (
                                                                                    <option value={obj.user_role_id}>{obj.user_role}</option>
                                                                                )
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="table-responsive">
                                                                    <table className="table table-row-dashed border-gray-100 align-middle gy-6">
                                                                        <tbody className="fs-6 fw-bold">
                                                                            <tr>
                                                                                <td className="min-w-240px fs-3 fw-bolder">Pages</td>
                                                                                <td className="min-w-240px fw-bolder">Visibility</td>
                                                                                <td className="w-125px">
                                                                                    <label className="form-check-label " for="kt_settings_notification_email">Read</label>
                                                                                </td>
                                                                                <td className="w-125px">
                                                                                    <label className="form-check-label " for="kt_settings_notification_phone">Write</label>
                                                                                </td>

                                                                            </tr>
                                                                            {newAccess["access"].length > 0 ? newAccess["access"].map((obj, index1) => {

                                                                                return (
                                                                                    <>
                                                                                        <tr>
                                                                                            <td className="fs-4 fw-bolder">{obj.key.toUpperCase()}</td>

                                                                                            <td>
                                                                                                <div className="form-check form-check-solid">
                                                                                                    {/* {console.log(obj.visibility)} */}
                                                                                                    <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, undefined, undefined, undefined)} id={`visibility_${obj.key.replace(/-/g, "_")}`} checked={obj.visibility == 1} />
                                                                                                    <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                </div>
                                                                                            </td>

                                                                                        </tr>
                                                                                        {obj.inside_components.length > 0 ? obj.inside_components.map((obj2, index2) => {
                                                                                            return (
                                                                                                obj2.hasOwnProperty("key") && obj2.inside_components.length > 0 ?
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td className="ps-4 fs-4 fw-bold">{obj2.key.toUpperCase()}</td>

                                                                                                            <td>
                                                                                                                <div className="form-check form-check-solid">
                                                                                                                    {/* {console.log(obj.visibility)} */}
                                                                                                                    <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, undefined, undefined)} id={`visibility_${obj.key.replace(/-/g, "_")}`} checked={obj2.visibility == 1} />
                                                                                                                    <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                                </div>
                                                                                                            </td>

                                                                                                        </tr>
                                                                                                        {
                                                                                                            obj2.inside_components.map((obj3) => {
                                                                                                                // console.log(Object.keys(obj3)[0],":",obj3[Object.keys(obj3)[0]])
                                                                                                                return (
                                                                                                                    <tr>
                                                                                                                        <td className="ps-8">{Object.keys(obj3)[0]}</td>
                                                                                                                        <td>
                                                                                                                            <div className="form-check form-check-solid">
                                                                                                                                <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, Object.keys(obj3)[0], Object.keys(obj3)[0])} id={`visibility_${(Object.keys(obj3)[0]).replace(/-/g, "_")}`} checked={obj3[Object.keys(obj3)[0]] == 1} />
                                                                                                                                <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <div className="form-check form-check-solid">
                                                                                                                                <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, Object.keys(obj3)[0], "read")} id={`read_${(Object.keys(obj3)[0]).replace(/-/g, "_")}`} checked={obj3[Object.keys(obj3)[1]] == 1} />
                                                                                                                                <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                        <td>
                                                                                                                            <div className="form-check form-check-solid">
                                                                                                                                <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, obj2.key, Object.keys(obj3)[0], "write")} id={`write_${(Object.keys(obj3)[0]).replace(/-/g, "_")}`} checked={obj3[Object.keys(obj3)[2]] == 1} />
                                                                                                                                <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                )
                                                                                                            })
                                                                                                        }
                                                                                                    </>
                                                                                                    :
                                                                                                    <tr>
                                                                                                        <td className="ps-4">{Object.keys(obj2)[0]}</td>
                                                                                                        <td>
                                                                                                            <div className="form-check form-check-solid">
                                                                                                                <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, Object.keys(obj2)[0], undefined, Object.keys(obj2)[0])} id={`visibility_${(Object.keys(obj2)[0]).replace(/-/g, "_")}`} checked={obj2[Object.keys(obj2)[0]] == 1} />
                                                                                                                <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div className="form-check form-check-solid">
                                                                                                                <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, Object.keys(obj2)[0], undefined, "read")} id={`read_${(Object.keys(obj2)[0]).replace(/-/g, "_")}`} checked={obj2[Object.keys(obj2)[1]] == 1} />
                                                                                                                <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div className="form-check form-check-solid">
                                                                                                                <input className="form-check-input" type="checkbox" onChange={(e) => updateNewAccess(e.target.checked, obj.key, Object.keys(obj2)[0], undefined, "write")} id={`write_${(Object.keys(obj2)[0]).replace(/-/g, "_")}`} checked={obj2[Object.keys(obj2)[2]] == 1} />
                                                                                                                <label className="form-check-label ps-2" for="billing1"></label>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                            )
                                                                                        }) : null}
                                                                                    </>
                                                                                );
                                                                            })
                                                                                :
                                                                                null
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="d-flex flex-stack pt-15">
                                                            {write ?
                                                                <div style={{ "float": "right" }}>
                                                                    <button
                                                                        type="submit"
                                                                        id="kt_contact_submit_button"
                                                                        class="btn btn-lg btn-primary me-3"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            register();
                                                                        }}

                                                                    >
                                                                        <span class="indicator-label">Submit
                                                                            <span class="svg-icon svg-icon-3 ms-2 me-0">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
                                                                                        <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                        <span class="indicator-progress">Please wait...
                                                                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                                    </button>

                                                                </div>
                                                                :
                                                                <div style={{ "align": "right" }}>
                                                                    <button
                                                                        type="submit"
                                                                        id="kt_contact_submit_button"
                                                                        class="btn btn-lg btn-primary me-3"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            register();
                                                                        }}
                                                                        disabled
                                                                    >
                                                                        <span class="indicator-label">Submit
                                                                            <span class="svg-icon svg-icon-3 ms-2 me-0">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
                                                                                        <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </form>
                                                )}
                                            </Formik>
                                        </div>
                                        <button className="btn btn-secondary rounded m-3 p-5 float-right" onClick={ScrollToTop} title="Scroll to Top">
                                            <i class="las la-angle-double-up" style={{ "font-size": "32px" }}></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
                :
                <>
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                                    <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Update User
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                        <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                    </h1>

                                </div>

                            </div>
                        </div>

                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div className="container" id="kt_content_container">
                                <div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
                                    <br />

                                    {state == 2 ?
                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        :
                                        <>

                                            <div className="flex-lg-row-fluid me-lg-15">
                                                <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                                    <div className="card-header">
                                                        <div className="card-title">
                                                            <h2 className="fw-bolder">Update User</h2>
                                                        </div>
                                                    </div>


                                                    <div className="card-body pt-0">
                                                        <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                        <div className="d-flex align-items-center p-3 mb-2">


                                                        </div>


                                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                            <div className="d-flex flex-stack flex-grow-1">
                                                                <div className="fw-bold">
                                                                    <h4 className="text-gray-800 fw-bolder">Please Login</h4>
                                                                    <div className="fs-6 text-gray-600">
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </>
                                    }


                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            }


            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>


        </>
    );
}
export default UpdateUser;