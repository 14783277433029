import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import moment from 'moment';
import 'react-dropzone-uploader/dist/styles.css'
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import uuid from 'react-uuid';
import "@pathofdev/react-tag-input/build/index.css";
import Select from 'react-select';

function AdvancePayment(props) {
    let [user, setUser] = useState({});
    let [loader, setLoader] = useState(0);
    let [state, setstate] = useState(2);
    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);
    let [duration, setduration] = useState("");
    
    //const [organization_id, setOrganization_id] = useState("");
    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    const [customerlist, setcustomerlist] = useState([]);

    const [cid, setcid] = useState("");

    const [amount, setamount] = useState("");

    const [payment_mode, setpayment_mode] = useState("");

    const [reference_id, setreference_id] = useState("");


    const payment_mode_options = [
        { value: 'card', label: 'Card' },
        { value: 'cheque', label: 'Cheque' },
        { value: 'cash', label: 'Cash' },
        { value: 'payment gateway', label: 'Payment Gateway' }
      ];

    var page_name = "advance-payment";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });

                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;

                        if(res.data.organization_flag==0){
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
                        else {
                            // alert("Please login")
                            //var path="/add-subcategory";
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
							
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if(temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else
                                    {
                                        temp = 2;
                                    }
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            res.data.audience_count = (res.data.audience_count)
                            if (res.data.audience_count > 0) {
                                const timer_decrease = setInterval(() => {
                                    res.data.audience_count = (res.data.audience_count - 1);
                                    //console.log(res.data.audience_count);
                                    if (res.data.audience_count > 0 && res.data.audience_count < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                                        if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                                            setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                                            window.$('#kt_modal_timer').modal('show');
                                        }
                                    }
                                }, 1000);

                                return () => clearInterval(timer_decrease);
                            }
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
            }
            else
			{
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }

        validateJWT();

    }, [])

    useEffect(() => {
        async function getcustomerdetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/customer-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();	
            console.log(body)
			if(body.length>0){
                setstate(1)
                let customer=[];
                //customer.push({ value: "Select Customer", label: "Select Customer" });
                for(let i=0;i<body.length;i++)
                {
                    customer.push({label: body[i].email, value: body[i].cid})
                }
                setcustomerlist(customer)          
            }
        }
        if(read || write)
        {
            user.email && visibility && getcustomerdetails();
        }
	}, [user, visibility, read, write])

    const register = (e) => {
        e.preventDefault();
        var object = {};
        object.payment_mode = payment_mode;
        object.payment_amount = document.getElementById("amount").value;
        object.reference_id = reference_id;
        object.customer_id = cid;
        object.page_name = page_name;
        object.payment_flag ="advance";
        object.user_role ="customer";

        if (object.customer == "") {
            swal("Warning!", "Customer Name cannot be a empty", "warning");
            return false;
        }
        if (object.payment_mode == "") {
            swal("Warning!", "Payment mode cannot be a empty", "warning");
            return false;
        } 
		var cexp=/^[0-9]+$/
        if (object.payment_amount == "") {
            swal("Warning!", "Amount cannot be a empty", "warning");
            return false;
        }
		else if(!cexp.test(object.payment_amount)){
            swal("Warning!", "Amount can only be numbers!", "warning");
            return false;
        }

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/save-advance-payment-details`, object, headers).then((res) => {
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Payment details added successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else
			{
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
            });
    }


    return ( 
        <>
            {
               (redirect == 1 || (read == 0 && write == 0)) ? 
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> :
                 null
            }

            {state==1 ?
                <>
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">

                                <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                                    <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Payment Details
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                        <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                    </h1>
                                </div>

                            </div>
                        </div>

                        <div id="tt-pageContent">
                        <div className="card mb-5 mb-xl-10">
								
									<div className="card-header">
										
										<div className="card-title">
											<h3>Payment Details</h3>
										</div>
										
									</div>
									
									<div className="card-body">
										
                                        <div className="row gx-10 mb-5">
                                                {customerlist.length>0 &&
                                                <>
                                                <div className="col-lg-5">
                                                    <label className="required fs-5 fw-bold mb-2">Select Customer</label>
                                                
                                                    <div className="mb-5"> 
                                                        
                                                        <Select
                                                            styles={{
                                                                option: (provided, state) => ({
                                                                ...provided,
                                                                fontWeight: '500',
                                                                fontSize: 'larger'
                                                                }),
                                                                multiValue: (provided, state) => ({
                                                                ...provided,
                                                                fontWeight: '500',
                                                                fontSize: 'larger'
                                                                }),
                                                                placeholder: (provided, state) => ({
                                                                ...provided,
                                                                fontWeight: '500',
                                                                fontSize: 'larger'
                                                                })
                                                                }}
                                                            name="customer"
                                                            id="customer"
                                                            options={customerlist}
                                                            onChange={(event) => { 
                                                            setcid(event.value)
                                                            }}
                                                            value={
                                                                customerlist.filter(option =>
                                                                    option.value == cid)[0]
                                                            }
                                                                                                            
                                                        />                                            
                                                    </div>                
                                                    
                                                </div>
                                                <div className="col-lg-5">
                                                    <label className="required fs-5 fw-bold mb-2">Payment Amount</label>			
                                                    <input className="form-control" placeholder="Payment Amount" id="amount" name="amount" type="text" value={amount}
                                                                                onChange={(e) => {setamount(e.target.value);
                                                                                }}/>		
                                                </div> 
                                                
                                                </>
                                                } 
                                        </div>

                                        <div className="row gx-10 mb-5">
                                                <div className="col-lg-5">
                                                    <label className="required fs-5 fw-bold mb-2">Payment Mode</label>
                                                
                                                    <div className="mb-5"> 
                                                        
                                                        <Select
                                                            styles={{
                                                                option: (provided, state) => ({
                                                                ...provided,
                                                                fontWeight: '500',
                                                                fontSize: 'larger'
                                                                }),
                                                                multiValue: (provided, state) => ({
                                                                ...provided,
                                                                fontWeight: '500',
                                                                fontSize: 'larger'
                                                                }),
                                                                placeholder: (provided, state) => ({
                                                                ...provided,
                                                                fontWeight: '500',
                                                                fontSize: 'larger'
                                                                })
                                                                }}
                                                            name="payment_mode"
                                                            id="payment_mode"
                                                            options={payment_mode_options}
                                                            onChange={(event) => { 
                                                            setpayment_mode(event.value)
                                                            }}
                                                            value={
                                                                payment_mode_options.filter(option =>
                                                                    option.value == payment_mode)[0]
                                                            }
                                                                                                            
                                                        />                                            
                                                    </div>                
                                                    
                                                </div>
                                                {(payment_mode=="card" || payment_mode=="cheque")&&
                                                <div className="col-lg-5">
                                                    <label className=" fs-5 fw-bold mb-2">Reference Id.</label>			
                                                    <input className="form-control" placeholder="Reference Id" name="reference_id" type="text" value={reference_id}
                                                                                onChange={(e) => {setreference_id(e.target.value);
                                                                                }}/>		
                                                </div> 
                                                }
                                        </div>
                                        <span style={{"display":"flex", "justify-content":"flex-end", "width":"100%","padding":"0"}}>
                                        {write == 1 ?
                                            <button type="submit"
                                                className="btn btn-primary rounded"
                                                onClick={(e) => { register(e) }}
                                                style={{"margin-right":"20px"}}
                                               
                                            >
                                                <span className="indicator-label">Submit</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>
											:
											<button type="submit"
                                                className="btn btn-primary rounded"
                                                onClick={(e) => { register(e) }} disabled
                                            >
                                                <span className="indicator-label">Submit</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                            </button>
										}
                                        </span>
                                        
       
                                            </div>
                                        </div>      
                                </div>

                            </div>
                </>
                :
                <>
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                                    <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Payment Details
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                        <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                    </h1>

                                </div>

                            </div>
                        </div>

                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div className="container" id="kt_content_container">
                                <div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
                                    <br />

                                    {state == 2 ?
                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        :
                                        <>

                                            <div className="flex-lg-row-fluid me-lg-15">
                                                <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                                    <div className="card-header">
                                                        <div className="card-title">
                                                            <h2 className="fw-bolder">Payment Details</h2>
                                                        </div>
                                                    </div>


                                                    <div className="card-body pt-0">
                                                        <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                        <div className="d-flex align-items-center p-3 mb-2">


                                                        </div>


                                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                            <div className="d-flex flex-stack flex-grow-1">
                                                                <div className="fw-bold">
                                                                    <h4 className="text-gray-800 fw-bolder">Please Login</h4>
                                                                    <div className="fs-6 text-gray-600">
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </>
                                    }


                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            }


            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>


        </>
    );
}
export default AdvancePayment;