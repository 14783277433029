import React, { useState, useEffect } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Link,Redirect } from 'react-router-dom'
import moment from 'moment';

import MUIDataTable from "mui-datatables";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import SetColor from './SetColor.js';
 
export const ShowSubCategory = () => {
    const [accessToken, setaccessToken] = useState('');
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [unique_id, setUnique_id] = useState("");
    const [loading, setloading] = useState(false);
    let [state, setstate] = useState(2);

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);
    const [category, setCategory] = useState("");
    const [status, setstatus] = useState("");
    const [subcategorycode, setSubcategorycode] = useState("");
    const [subcategoryname, setSubcategoryname] = useState("");
    const [subcategorydescription, setSubcategorydescription] = useState("");
    const [checkCode, setCheckCode] = useState({});
    const [categoryDropdown, setCategoryDropdown] = useState([]);
    //const [organization_id, setOrganization_id] = useState("");
    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };
    var page_name = "subcategory-list";
    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Subcategory',
    };



    const columns = [
        {
            name: "subcategoryname",
            label: "Subcategory Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "subcategorycode",
            label: "Subcategory Code",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "category",
            label: "Category",
            options: {
                filter: true,
                sort: true
            }
        },

        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            } 
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });

                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
                        else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if(temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else
                                    {
                                        temp = 2;
                                    }
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }

        validateJWT();
    }, [])

    useEffect(() => {
        if (timer > 0) {
            const timer_decrease = setInterval(() => {
                setTimer(timer - 1);
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        window.$('#kt_modal_timer').modal('show');
                    }
                }
            }, 1000);

            return () => clearInterval(timer_decrease);
        }
    }, [timer]);



    useEffect(() => {
        async function getcategorydetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/category-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.add_status === "Active") {
                    var temp_obj = {};
                    temp_obj.category_name = obj.category_name;
                    temp_obj.category_id = obj.id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setCategoryDropdown(temp_dropdown);

            setloading(true);
        }
        if(read || write)
        {
            (user.email && visibility) && getcategorydetails();
        }

    }, [user, visibility,read, write])
    useEffect(() => {
        async function getsubcategorydetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/subcategory-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            // console.log(body);
            var temp_obj = {};
            body.map((obj) => {

                temp_obj[obj.subcategory_code] = obj.id;

            })
            setCheckCode(temp_obj);
            setEditModal(body);
			
			if(body.length>0){
                setstate(1);
            }
            else{
                setstate(0);
            }

            setloading(true);
        }
        if(read || write)
        {
            (user.email && visibility) &&  getsubcategorydetails();
        }

    }, [user, visibility,read, write])

    useEffect(() => {
        async function geteditdetails() {

            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj) => {

                    var temp_obj = {};
                    temp_obj.category = obj.category;
                    temp_obj.status = obj.add_status;
                    temp_obj.subcategorycode = obj.subcategory_code;
                    temp_obj.category = obj.category_name;
                    temp_obj.subcategoryname = obj.subcategory_name;
                    var id = obj.id;
                    temp_obj.actions =
                        <div class="dropdown">
                            {write ?
                            <>
                            <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Edit
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><a href="#" onClick={(e) => { updateSubcategory(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className="dropdown-item">Edit details</a></li>
                                <li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" class="dropdown-item" href="#">Change status</a></li>
                            </ul>
                            </>
                            :
                            null
                            }
                        </div>

                    temp_data.push(temp_obj);
                })
                setData(temp_data);
            }
            else {
                setData([]);
            }
        }
        editmodal.length > 0 && geteditdetails();

    }, [editmodal])

    const updateSubcategory = (id) => {
        // console.log(id);
        // console.log(editmodal);
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].id) {
                const modalid = document.getElementById("EditModal");
                // console.log(editmodal[i]);
                document.getElementById("id").value = id;
                setSubcategoryname(editmodal[i].subcategory_name);
                setSubcategorycode(editmodal[i].subcategory_code);
                setCategory(editmodal[i].category);
                setSubcategorydescription(editmodal[i].subcategory_description);
            }
        }

    }
    const updateStatus = (id) => {
        // console.log(id);
        // console.log(editmodal);
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].id) {
                // console.log(editmodal[i]);
                document.getElementById("st").value = editmodal[i].add_status;
                document.getElementById("id").value = id;
                setstatus(editmodal[i].add_status);
            }
        }

    }

    const submitStatus = (e) => {
        e.preventDefault();

        var id = document.getElementById("id").value;
        var status_value = document.getElementById("st").value;
        var values = { "id": id, "status": status_value,"page_name":page_name };

        // console.log(values);

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        // axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-category`, values, headers).then((res) => {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-subcategory`, values, headers).then((res) => {
            // console.log(res)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Status changed successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const submitSubcategorydetails = (e) => {
        e.preventDefault();
        var id = document.getElementById("id").value;
        var subcategory_name = document.getElementById("subcategory_name").value;
        var subcategory_code = document.getElementById("subcategory_code").value;
        var description = document.getElementById("subcategory_description").value;
		var cexp=/^[0-9]+$/
		
        if (subcategory_name.length == 0) {
            swal("Warning!", "Please enter a Category Name", "warning");
            return false;
        }
        if (subcategory_code.length == 0) {
            swal("Warning!", "Please enter a Subcategory Code", "warning");
            return false;
        }
		else if(!cexp.test(subcategory_code)){
            swal("Warning!", "Subategory Code can only be numbers!", "warning");
            return false;
        }
		
		
        if (checkCode[subcategory_code] != id && checkCode[subcategory_code]) {
            swal("Warning!", "Please enter a unique Subcategory Code", "warning");
            return false;
        }
        if (category == "" || category == undefined) {
            swal("Warning!", "Please enter a Category", "warning");
            return false;
        }
        var values = { "id": id, "name": subcategory_name, "code": subcategory_code, "description": description, "category": category,"page_name":page_name};

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }


        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-subcategory`, values, headers).then((res) => {
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Subcategory updated successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });

    }


    // console.log(editmodal)
    return (
        <>
            {
               (redirect == 1 ||(read==0 && write==0)) ? 
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> :
                 null
            }
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                            <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Show SubCategory
                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                            </h1>

                        </div>

                    </div>
                </div>

                <div className="card">

                    <div className="card-body pt-6">
                        {
                            data.length > 0 ?
                                <>
                                    <MUIDataTable
                                        title={"Subcategory Details"}
                                        data={data}
                                        columns={columns}
                                        options={options}
                                    />

                                </>
                                :
                                <>
                                    {
                                        state == 2 ?
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                            :
                                            <>
                                                <div className="card-body pt-0">
                                                    <div className="text-gray-500 fw-bold fs-5 mb-5">Show Subcategory</div>

                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">No Data Available</h4>
                                                                <div className="fs-6 text-gray-600"> </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </>
                                    }
                                </>
                        }
                    </div>

                </div>


            </div>
            <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog mw-650px">

                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Change Status</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">

                                <div className="col-lg-12">
                                    Current Status is : {status == "Active" ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
                                    <h3>Are you sure about changing status?</h3>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary btn-sm rounded" data-bs-dismiss="modal">Cancel</button>
                            <form >
                                <input type="hidden" id="id" name="activeId" />
                                <input type="hidden" id="st" name="st" />
                                {(status == "Active") ? <button type="button" className="btn btn-danger rounded" onClick={(e) => { submitStatus(e); }} >Deactivate</button> : <button type="button" className="btn btn-success rounded" onClick={(e) => { submitStatus(e); }} >Activate</button>}
                            </form>
                        </div>

                    </div>

                </div>

            </div>
            <div class="modal fade" id="EditModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered mw-650px">
                    <div class="modal-content">
                        <form class="form" action="#" id="kt_modal_new_address_form">
                            <div class="modal-header" id="kt_modal_new_address_header">
                                <h2>Update Subategory</h2>
                                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span class="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div class="modal-body py-10 px-lg-17">
                                <div class="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                    <div class="row mb-5">
                                        <div class="col-md-12 fv-row">
                                            <label class="required fs-5 fw-bold mb-2">Subcategory Name</label>
                                            <input id="subcategory_name" type="text" class="form-control form-control-solid" placeholder="" name="subcategory-name" value={subcategoryname} onChange={(e) => { setSubcategoryname(e.target.value) }} />
                                        </div>

                                    </div>

                                    <div class="d-flex flex-column mb-5 fv-row">
                                        <label class="required fs-5 fw-bold mb-2">Subcategory Code</label>
                                        <input id="subcategory_code" class="form-control form-control-solid" placeholder="" name="categorycode" value={subcategorycode} onChange={(e) => { setSubcategorycode(e.target.value) }} />
                                    </div>


                                    <div class="d-flex flex-column mb-5 fv-row">
                                        <label class="d-flex align-items-center fs-5 fw-bold mb-2">
                                            <span class="required">Category</span>
                                        </label>
                                        <select
                                            name="category"
                                            id='category'
                                            data-placeholder="Select a Category..."
                                            class="form-select form-select-solid"
                                            value={category}
                                            onChange={(event) => { setCategory(event.target.value); }}
                                        >
                                            <option value="">Select</option>
                                            {
                                                categoryDropdown.length > 0 && categoryDropdown.map((obj) => {
                                                    return (
                                                        <option value={obj.category_id}>{obj.category_name}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div class="d-flex flex-column mb-5 fv-row">
                                        <label class="fs-5 fw-bold mb-2">Subcategory Description</label>
                                        <input id="subcategory_description" class="form-control form-control-solid" placeholder="" name="subcategory-description" value={subcategorydescription} onChange={(e) => { setSubcategorydescription(e.target.value) }} />
                                    </div>


                                </div>
                            </div>
                            <div class="modal-footer flex-center">
                                <button onClick={(e) => { submitSubcategorydetails(e) }} type="submit" id="kt_modal_new_address_submit" class="btn btn-primary rounded">
                                    <span class="indicator-label">Submit</span>
                                    <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>
                            <input type="hidden" id="id" name="activeId" />
                        </form>
                    </div>
                </div>
            </div>

            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>
        </>);

};

export default ShowSubCategory;
