import React, { useState, useEffect } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import Select from 'react-select';
import moment from 'moment';
import ReactTagInput from "@pathofdev/react-tag-input";
import MUIDataTable from "mui-datatables";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import SetColor from './SetColor.js';
import "@pathofdev/react-tag-input/build/index.css";
import { OBJECTMEMBER_TYPES } from '@babel/types';

export const ProductBillingList = () => {
    const [accessToken, setaccessToken] = useState('');
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [unique_id, setUnique_id] = useState("");
    const [PurchaseDetails, setPurchaseDetails] = useState([]);
    //const [loading, setloading] = useState(false);
    let [state, setstate] = useState(2);

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);
    const [product_details, setproduct_details] = useState([]);
    
    const [status, setstatus] = useState("");
    const [amount, setamount] = useState("");
    

    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    //const [organization_id, setOrganization_id] = useState("");
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    const [discount_type, setdiscount_type] = useState("");

    const [discount_value, setdiscount_value] = useState("");

    const [total, settotal] = useState("");
    const [tax, settax] = useState("");

    var page_name = "discounted-product-list";
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Group',
    };

    const columns = [
        {
            name: "customer_name",
            label: "Customer Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "total_amount",
            label: "Total Amount",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "user_id",
            label: "User Id",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "add_date_time",
            label: "Billing Timestamp",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });

                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
                        else {

                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
									else
									{
										temp = 2;
									}
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }

        validateJWT();
    }, [])

    useEffect(() => {
        if (timer > 0) {
            const timer_decrease = setInterval(() => {
                setTimer(timer - 1);
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        window.$('#kt_modal_timer').modal('show');
                    }
                }
            }, 1000);

            return () => clearInterval(timer_decrease);
        }
    }, [timer]);


    useEffect(() => {
        
        async function getpurchasedetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/billing-product-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            setEditModal(body);

            if (body.length > 0) {
                setstate(1);
            }
            else {
                setstate(0);
            }
        }
        if (read || write) {
            user.email && visibility && getpurchasedetails();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        async function geteditdetails() {

            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj) => {
                    var temp_obj = {};
                    //for(let j=0;j<obj.product_list.length;)
                    temp_obj.customer_name = obj.email;
                    temp_obj.total_amount = obj.discounted_total_price;
                    temp_obj.user_id = obj.user_id;
                    temp_obj.add_date_time = moment(obj.add_date_time).format("YYYY-MM-DD HH:MM")
                    temp_obj.status = obj.add_status;
                    var id = obj.unique_id;
                    var total_amount = obj.discounted_total_price;
                    // console.log(id)
                    temp_obj.actions =
                        <div className="dropdown">
                             {
                                write ?
                                <>
                                <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    Edit
                                </button>
                            
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        {read && <li><a onClick={(e) => { ViewDetails(id) }} data-bs-toggle="modal" data-bs-target="#ViewDetails"  className="dropdown-item" >View details</a></li>}
                                        <li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" className="dropdown-item" href="#">Change status</a></li>
                                        {obj.add_status=="Active" && <li><Link to={`/update-product-billing/${id}`}  className="dropdown-item" >Edit details</Link></li>}
                                        <li><Link to={`/customer-billing-profile/${id}`}  className="dropdown-item" >Customer Billing Profile</Link></li>
                                    </ul>
                                  </>  
                                    :
                                    <>
                                    {read && <li><a onClick={(e) => { ViewDetails(id) }} data-bs-toggle="modal" data-bs-target="#ViewDetails"  className="dropdown-item" >View details</a></li>}
                                    </>
                            }

                        </div>

                    temp_data.push(temp_obj);
                })
                setData(temp_data);
            }
            else {
                setData([]);
            }
        }
        editmodal.length > 0 && geteditdetails();

    }, [editmodal])

 
    const updateStatus = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].unique_id) {
                //console.log(editmodal[i].add_status)
                document.getElementById("st").value = editmodal[i].add_status;
                document.getElementById("id").value = id;
                setstatus(editmodal[i].add_status);
            }
        }
    }

    const ViewDetails = (id) => {
       // console.log(editmodal)
        for (let i = 0; i < editmodal.length; i++) {
            
            if (id == editmodal[i].unique_id && editmodal[i].add_status!="Removed") {
                setamount(editmodal[i].total_price)
                
                setdiscount_type(editmodal[i].discount_type)
                //console.log(editmodal[i].discount_type)
                if(editmodal[i].discount_type)
                {
                    setdiscount_value(editmodal[i].discount_value)
                }
                let total1=0;
                let tax1=0;
                for(let j=0;j<editmodal[i].product_list.length;j++)
                {
                    if(editmodal[i].product_list[j].serial_numbers!="")
                    {
                        editmodal[i].product_list[j].serial_numbers1= editmodal[i].product_list[j].serial_numbers.split(",")
                    }
                    else{
                        editmodal[i].product_list[j].serial_numbers1=[];
                    }
                    if(editmodal[i].product_list[j].add_status=="Active")
                    {
                        if(editmodal[i].product_list[j].taxable=="Yes")
                        {
                            total1=total1+editmodal[i].product_list[j].unit_price+(parseInt(editmodal[i].product_list[j].unit_price)*parseInt(editmodal[i].product_list[j].tax))/100;
                            tax1=tax1+(parseInt(editmodal[i].product_list[j].unit_price)*parseInt(editmodal[i].product_list[j].tax))/100;
                        }
                        else
                        {
                            total1=total1+editmodal[i].product_list[j].unit_price
                        }
                    }
                }
                settax(tax1)
                if(editmodal[i].discount_type=="Amount")
                {
                    editmodal[i].discount_value=editmodal[i].discount_value;
                    total1=total1-editmodal[i].discount_value;
                }
                else{
                    editmodal[i].discount_value=(editmodal[i].discount_value*editmodal[i].discounted_total_price)/100;
                    total1=total1-editmodal[i].discount_value;
                }
                //console.log(total1)
                settotal(total1)
                setproduct_details(editmodal[i].product_list);
            }         
        }      
    }

    const submitStatus = (e) => {
        e.preventDefault();

        var id = document.getElementById("id").value;
        var status_value = document.getElementById("st").value;
        var values = { "id": id, "status": status_value, "page_name": page_name };

        //console.log(values);

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-product-billing`, values, headers).then((res) => {
            //console.log(res)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Status changed successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }
    //console.log(product_details)
    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                            <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Billing Product Details
                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                            </h1>

                        </div>

                    </div>
                </div>

                <div className="card">

                    <div className="card-body pt-6">
                        {
                            data.length > 0 ?
                                <>
                                    <MUIDataTable
                                        title={"Billing Details"}
                                        data={data}
                                        columns={columns}
                                        options={options}
                                    />

                                </>
                                :
                                <>
                                    {
                                        state == 2 ?
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                            :
                                            <>
                                                <div className="card-body pt-0">
                                                    <div className="text-gray-500 fw-bold fs-5 mb-5">Billing Product Details</div>

                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                <div className="fs-6 text-gray-600"> </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </>
                                    }
                                </>
                        }
                    </div>

                </div>


            </div>
            <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog mw-650px">

                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Change Status</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">

                                <div className="col-lg-12">
                                    Current Status is : {status == "Active" ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
                                    <h3>Are you sure about changing status?</h3>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <form >
                                <input type="hidden" id="id" name="activeId" />
                                <input type="hidden" id="st" name="st" />

                                {(status == "Active") ? <button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} >Deactivate</button> : <button type="button" className="btn btn-success" onClick={(e) => { submitStatus(e); }} >Activate</button>}
                            </form>
                        </div>

                    </div>

                </div>

            </div>

            <div className="modal fade" id="ViewDetails" tabindex="-1" aria-hidden="true">
									
                <div className="modal-dialog mw-650px">
                    
                    <div className="modal-content">
                        
                        <div className="modal-header pb-0 border-0 justify-content-end">
                            
                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                            
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                                
                            </div>
                            
                        </div>
                        
                        <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
                            
                            <div className="text-center mb-13">
                                
                                <h1 className="mb-3">Product List</h1>
                                
                                <div className="text-gray-400 fw-bold fs-5"></div>
                                
                            </div>
                            
                            <div className="mb-15">
                                
                                <div className="mh-375px scroll-y me-n7 pe-7">
                                    {product_details.length > 0 && product_details.map((obj, index) => {
                                        return (
                                            <>
                                            {obj.add_status=="Active" &&
                                            <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                                                
                                                <div className="d-flex align-items-center">
                                                    
                                                    <div className="symbol symbol-35px symbol-circle">
                                                        
                                                    </div>
                                                    
                                                    
                                                    <div className="ms-6">
                                                        
                                                        <a href="#" className="d-flex align-items-center fs-5 fw-bolder text-dark text-hover-primary">{obj.product_name}
                                                        <span className="badge badge-light fs-8 fw-bold ms-2"></span></a>
                                                        
                                                        
                                                        
                                                        {obj.serial_numbers1.length > 0 && 
                                                        <div className="fw-bold text-gray-400">
                                                        
                                                        {obj.serial_numbers1.map((obj1, index) => {
                                                        return (
                                                            <>
                                                        <span className="badge badge-light-success me-auto rounded">{obj1}</span><span> </span>
                                                        </>
                                                        );
                                                    })}
                                                    </div>}
                                                        
                                                    </div>
                                                    
                                                </div>
                                                
                                                
                                                <div className="d-flex">
                                                    
                                                    <div className="text-end">
                                                        <div className="fs-5 fw-bolder text-dark">{obj.quantity} x {obj.unit_price}</div>
                                                        <div className="fs-7 text-muted"></div>
                                                    </div>
                                                
                                                </div>
                                                
                                            </div>
                                        }
                                            </>
                                            );
                                        })
                                    }
                                    <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                                        
                                        <div className="d-flex align-items-center">
                                            
                                            <div className="symbol symbol-35px symbol-circle">
                                                
                                            </div>
                                            
                                            
                                            <div className="ms-6">
                                                
                                                <a href="#" className="d-flex align-items-center fs-5 fw-bolder text-gray text-hover-primary">Subotal
                                                <span className="badge badge-light fs-8 fw-bold ms-2"></span></a>
                                                
                                                
                                                <div className="fw-bold text-gray-400"></div>
                                                
                                            </div>
                                            
                                        </div>
                                        
                                        
                                        <div className="d-flex">
                                            
                                            <div className="text-end">
                                                <div className="fs-5 fw-bolder text-dark">{amount}</div>
                                                <div className="fs-7 text-muted"></div>
                                            </div>
                                        
                                        </div>
                                        
                                    </div>
                                    {(discount_type!="" && discount_value!="")&&<div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                                        
                                        <div className="d-flex align-items-center">
                                            
                                            <div className="symbol symbol-35px symbol-circle">
                                                
                                            </div>
                                            
                                            
                                            <div className="ms-6">
                                                
                                                <a href="#" className="d-flex align-items-center fs-5 fw-bolder text-gray text-hover-primary">Discount
                                                <span className="badge badge-light fs-8 fw-bold ms-2"></span></a>
                                                
                                                
                                                <div className="fw-bold text-gray-400"></div>
                                                
                                            </div>
                                            
                                        </div>
                                        
                                        
                                        <div className="d-flex">
                                            
                                            <div className="text-end">
                                                <div className="fs-5 fw-bolder text-dark">-{discount_value}</div>
                                                <div className="fs-7 text-muted"></div>
                                            </div>
                                        
                                        </div>
                                        
                                    </div>
                                    }
                                    <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                                        
                                        <div className="d-flex align-items-center">
                                            
                                            <div className="symbol symbol-35px symbol-circle">
                                                
                                            </div>
                                            
                                            
                                            <div className="ms-6">
                                                
                                                <a href="#" className="d-flex align-items-center fs-5 fw-bolder text-gray text-hover-primary">Tax
                                                <span className="badge badge-light fs-8 fw-bold ms-2"></span></a>
                                                
                                                
                                                <div className="fw-bold text-gray-400"></div>
                                                
                                            </div>
                                            
                                        </div>
                                        
                                        
                                        <div className="d-flex">
                                            
                                            <div className="text-end">
                                                <div className="fs-5 fw-bolder text-dark">+{tax}</div>
                                                <div className="fs-7 text-muted"></div>
                                            </div>
                                        
                                        </div>
                                        
                                    </div>

                                    <div className="d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed">
                                        
                                        <div className="d-flex align-items-center">
                                            
                                            <div className="symbol symbol-35px symbol-circle">
                                                
                                            </div>
                                            
                                            
                                            <div className="ms-6">
                                                
                                                <a href="#" className="d-flex align-items-center fs-5 fw-bolder text-dark text-hover-primary">Total
                                                <span className="badge badge-light fs-8 fw-bold ms-2"></span></a>
                                                
                                                
                                                <div className="fw-bold text-gray-400"></div>
                                                
                                            </div>
                                            
                                        </div>
                                        
                                        
                                        <div className="d-flex">
                                            
                                            <div className="text-end">
                                                <div className="fs-5 fw-bolder text-dark">{total}</div>
                                                <div className="fs-7 text-muted"></div>
                                            </div>
                                        
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                    
                </div>
                
            </div>
           


            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>
        </>);

};

export default ProductBillingList;
