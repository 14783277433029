import React, { useState, useEffect } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment';
import Select from 'react-select';
import MUIDataTable from "mui-datatables";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import SetColor from './SetColor.js';
export const ShowProductGroup = () => {
    const [accessToken, setaccessToken] = useState('');
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [unique_id, setUnique_id] = useState("");
    const [loading, setloading] = useState(false);
    let [state, setstate] = useState(2);

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);
    const [groupname, setGroupname] = useState("");
    const [status, setstatus] = useState("");
    // const [categorycode, setCategorycode] = useState("");
    // const [description, setDescription] = useState("");
    const [globalproduct, setGlobalProduct] = useState([]);
    const [product, setProduct] = useState([]);
    const [product1, setProduct1] = useState([]);
    const [filter_product, setfilterProduct] = useState([]);
    const [filter_product1, setfilterProduct1] = useState([]);
    // const [checkCode, setCheckCode] = useState({});
    // const [issubcategory, setIssubcategory] = useState(false);
    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    //const [organization_id, setOrganization_id] = useState("");
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    var page_name = "product-group-list";

    const dropzoneStyle = {
        paddingTop: '15px'
    }
    const dropzoneStyle1 = {
        paddingTop: '15px',
        paddingLeft: '65px'
    }


    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Group',
    };

// console.log(product)

    const columns = [
        {
            name: "group_name",
            label: "Group Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "adddatetime",
            label: "Add Date",
            options: {
                filter: true,
                sort: true
            }
        },

        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    const handleAddFields = () => {

        if(product.length<20){
          const values = [...product];
          values.push([{ pid: ""}]);
        setProduct(values);
        // console.log(inputFields.length)
        }
        else{
            swal("Oops!", `You can add maximum ${product.length} !`, "error");
        }
          
      };

      const handleRemoveFields = (index) => {
        const values = [...product];
        values.splice(index, 1); 
        setProduct(values);

        const values1 = [...product1];
        values1.splice(index, 1); 
        //console.log(values1)
        setProduct1(values1);
    };

    const handleInputChange = (index,event) => {
        const values1 = [...product1];
        values1[index]=event.value; 
        const values = [...product];
        values[index][0].pid=event.value;
        setProduct1(values1);
       
        setProduct(values);
      };

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });

                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++)
							{
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
											break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }

        validateJWT();
    }, [])

    useEffect(() => {
        if (timer > 0) {
            const timer_decrease = setInterval(() => {
                setTimer(timer - 1);
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        window.$('#kt_modal_timer').modal('show');
                    }
                }
            }, 1000);

            return () => clearInterval(timer_decrease);
        }
    }, [timer]);



    useEffect(() => {
        async function getgroupdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/product-group-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            setEditModal(body);

            setloading(true);
        }
        if(read || write)
        {
            (user.email && visibility) && getgroupdetails();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        async function getproductdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/product-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();



            setGlobalProduct(body);

            if (body.length > 0) {
                setstate(1);
                for(let i=0;i<body.length;i++)
                {
                    filter_product.push({label: body[i].product_name, value: body[i].pid})
                    filter_product1.push({label: body[i].product_name, value: body[i].pid})
                }
            }
            else {
                setstate(0);
            }

            setloading(true);
        }
        if(read || write)
        {
            (user.email && visibility)&& getproductdetails();
        }

    }, [user, visibility,read, write])

    useEffect(() => {
        async function geteditdetails() {

            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj) => {

                    var temp_obj = {};
                    temp_obj.group_name = obj.group_name;
                    temp_obj.status = obj.add_status;
                    temp_obj.adddatetime = moment(obj.add_date).format("YYYY-MM-DD  ").concat(obj.add_time);
                    var id = obj.Id;
                    temp_obj.actions =
                        <div class="dropdown">
                            <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                Edit
                            </button>
                            {write ?
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                        {/* <li><a href="#" onClick={(e) => { updateGroup(id) }} data-bs-toggle="modal" data-bs-target="#EditModal" className={`dropdown-item btn btn-md ${write ? "" : "disabled"}`} >Edit details</a></li> */}
                                        <li><a onClick={(e) => { updateStatus(id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" className={`dropdown-item btn btn-md ${write ? "" : "disabled"}`} href="#" >Change status</a></li>
                                        <li><a onClick={(e) => { updateProduct(id) }} data-bs-toggle="modal" data-bs-target={`#x${id}`} className={`dropdown-item btn btn-md ${read ? "" : "disabled"}`} href="#" >Show Mapped Products</a></li>
                                </ul>
                                :
                                read &&
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <li><a onClick={(e) => { updateProduct(id) }} data-bs-toggle="modal" data-bs-target={`#x${id}`} className={`dropdown-item btn btn-md ${read ? "" : "disabled"}`} href="#" >Show Mapped Products</a></li>
                                </ul>
                            }


                        </div>

                    temp_data.push(temp_obj);
                })
                setData(temp_data);
            }
            else {
                setData([]);
            }
        }
        (editmodal.length > 0 && globalproduct.length>0) && geteditdetails();

    }, [editmodal,globalproduct])

    const updateProduct = (id) => {
        var result=[]
        var tempid=[]
        var temp=[]
        document.getElementById("id1").value = id;
        tempid=(editmodal.filter(obj=> obj.Id==id))[0].product_group_id.split(",")
        for(let i=0; i<tempid.length;i++){
            temp=globalproduct.filter(obj => obj.pid == tempid[i])
            if (temp.length!==0){
                product1.push(temp[0].pid)
                result.push(temp)
            }
        }
        setProduct(result);
    }

    
    const updateStatus = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].Id) {
                document.getElementById("st").value = editmodal[i].add_status;
                document.getElementById("id").value = id;
                setstatus(editmodal[i].add_status);
            }
        }

    }

    const submitStatus = (e) => {
        e.preventDefault();

        var id = document.getElementById("id").value;
        var status_value = document.getElementById("st").value;
        var values = { "id": id, "status": status_value,"page_name":page_name };

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-product-group`, values, headers).then((res) => {
           // console.log(res)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Status changed successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }
    
    const areEqual = (first, second) => {
        if(first.length !== second.length){
           return false;
        };
        for(let i = 0; i < first.length; i++){
           if(!second.includes(first[i])){
              return false;
           };
        };
        return true;
     };

    const updateDetails = (e) => {
        e.preventDefault();

        var id = document.getElementById("id1").value;      
        let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index)
        if(findDuplicates(product1).length>0)
        {
            swal("Warning!", "Please select unique product names", "warning");
            return false;
        }
        for(let i=0;i<editmodal.length;i++)
        {
            
            if(editmodal[i].Id!=id && areEqual(editmodal[i].product_group_id.split(","),product1))
            {
                swal("Warning!", "This product combination already exist", "warning");
                return false;
            }
        }
        var values = { "id": id, "product": product1 ,"page_name":page_name };

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
       axios.post(`${process.env.REACT_APP_SERVER_URL}/update-product-group`, values, headers).then((res) => {
           // console.log(res)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Product group update successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }
    return (
        <>
            {
                (redirect == 1 ||(read==0 && write==0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                            <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Show Product Group
                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                            </h1>

                        </div>

                    </div>
                </div>

                <div className="card">

                    <div className="card-body pt-6">
                        {
                            data.length > 0 ?
                                <>
                                    <MUIDataTable
                                        title={"Product Group Details"}
                                        data={data}
                                        columns={columns}
                                        options={options}
                                    />

                                </>
                                :
                                <>
                                    {
                                        state == 2 ?
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                            :
                                            state == 0 &&
                                            <>
                                                <div className="card-body pt-0">
                                                    <div className="text-gray-500 fw-bold fs-5 mb-5">Show Product Group</div>

                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                <div className="fs-6 text-gray-600"> </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </>
                                    }
                                </>
                        }
                    </div>

                </div>


            </div>
            <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog mw-650px">

                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Change Status</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">

                                <div className="col-lg-12">
                                    Current Status is : {status == "Active" ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
                                    <h3>Are you sure about changing status?</h3>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <form >
                                <input type="hidden" id="id" name="activeId" />
                                <input type="hidden" id="st" name="st" />

                                {(status == "Active") ? <button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} >Deactivate</button> : <button type="button" className="btn btn-success" onClick={(e) => { submitStatus(e); }} >Activate</button>}
                            </form>
                        </div>

                    </div>

                </div>

            </div>
            
            {
                editmodal.length > 0 && editmodal.map((obj, index) => {
                    return (
                        <div class="modal fade" id={`x${obj.Id}`} tabindex="-1" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered mw-650px">
                                <div class="modal-content">
                                    <form class="form" action="#" id="kt_modal_new_address_form">
                                        <div class="modal-header" id="kt_modal_new_address_header">
                                            <h2>Show Mapped Products</h2>
                                            <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                                <span class="svg-icon svg-icon-1">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                                        </g>
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>
                                        
                                        
                                           {(product.length > 0 && filter_product.length > 0) ?
                                           <div className="row">
                                           <div className="col-xl-12 p-5">
                                                {product.map((obj1, index) => {
                                                    return (
                                                       
                                                        <div className="form-group row">
                                                            <div className="col-lg-10" >
                                                            <h4 className="modal-body py-4 px-lg-12 text-center ">
                                                                    <Select
                                                                        name="product"
                                                                        id="product"
                                                                        options={filter_product}
                                                                        value={
                                                                            filter_product1.filter(option =>
                                                                                option.value == obj1[0].pid)[0]
                                                                        }
                                                                        onChange={(event) => { handleInputChange(index,event);}}
                                                                    />
                                                                {/* {console.log(obj1)} */}
                                                            </h4>
                                                            </div>
                                                            <div className="col-lg-1" style={dropzoneStyle}>
                                                                <button
                                                                    className="btn font-weight-bold btn-danger btn-icon"
                                                                    type="button"
                                                                    onClick={() => handleRemoveFields(index)}
                                                                    >
                                                                    <i className="la la-remove"></i>
                                                                </button> 
                                                            </div>
                                                        </div>
                                                        
                                                    )

                                                })}
                                                </div>
                                                <div className="form-group row" style={dropzoneStyle1}>
                                                    <div>
                                                        <button type="button" className="btn font-weight-bold btn-primary" onClick={() => handleAddFields()}>
                                                        {/* <button type="button" className="btn font-weight-bold btn-primary" > */}
                                                            <i className="la la-plus"></i>
                                                            Add
                                                        </button>
                                                    </div>
                                                </div>
                                                </div>
                                                
                                                :
                                                <h4 className="modal-body py-4 px-lg-12 text-center ">
                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder text-center">No Data Available.</h4>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </h4>
                                        }
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                            <form >
                                                <input type="hidden" id="id1" name="activeId" />
                                                {<button type="button" className="btn btn-success" onClick={(e) => { updateDetails(e); }} >Update</button>}
                                            </form>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    )
                })
            }



            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>
        </>);

};

export default ShowProductGroup;
