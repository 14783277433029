import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import Select from 'react-select';
import { Link, Redirect } from 'react-router-dom';

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

// import uuid from 'react-uuid';
// import removeImg from './icons/remove.svg'
// import $, { data } from 'jquery';
// import moment from 'moment';
// import Dropzone from 'react-dropzone-uploader';
// import 'react-dropzone-uploader/dist/styles.css'

function AddProject(props) {
    const [user, setUser] = useState({});
    const [organization_id,setOrganization_id] = useState("")
    const [organization_list, setorganization_list] = useState([]);
    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);
    const [state, setstate] = useState(2);

    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
	const [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);

    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    const [deptName, setDeptName] = useState("");
    const [Department_code, setDepartment_code] = useState("");
    const [Department_head, setDepartment_head] = useState("");
    const [deptDesc, setDeptDesc] = useState("");
    const [customer, setcustomer] = useState("");
    const [userlist, setuserList] = useState([])
    const [userlist1, setuserList1] = useState([])
    const [displayvalue, getvalue] = useState();
    const [customerlist, setcustomerlist] = useState([])
    const [editmodal, setEditModal] = useState([]);

    const [submitButtonState, setSubmitButtonState] = useState(false)

    var page_name = "add-project";

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true);
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    setRedirect_to_login(1);
                });
                
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if(temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else
                                    {
                                        temp = 2;
                                    }
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
							
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setRedirect_to_login(1);
                });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }

                // console.log(timer)
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);





    useEffect(() => {
        async function gettripdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/user-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if (body.length > 0) {
                setstate(1);
            }
            else {
                setstate(0);
            }

            let temp=[];
            temp.push({value:"user_all" ,label:"All User"});
            for(let i=0;i<body.length;i++)
            {
                temp.push({value: body[i].user_id ,label:body[i].user_id});
            }
            setuserList1(temp);
            let temp_dropdown = [];
            body.map((obj) => {
                if (obj.log_status === "Active") {
                    let temp_obj = {};
                    temp_obj.label = obj.user_id;
                    temp_obj.value = obj.user_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            
            setuserList(temp_dropdown);
        }
        if(read || write)
        {
            (user.email && visibility) && gettripdetails();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        async function getcustomerdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/customer-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name":page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.add_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.customer_display_name;
                    temp_obj.value = obj.cid;
                    temp_dropdown.push(temp_obj);
                }
            })
            setcustomerlist(temp_dropdown);
        }
        if(read || write)
        {
            user.email && visibility && getcustomerdetails();
        }

    }, [user, visibility,read, write])

    useEffect(() => {
        async function getdeparmentdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/department-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();

            setEditModal(body);
            //setloading(true);
        }
        if (read || write) {
            user.email && visibility && getdeparmentdetails();
        }

    }, [user, visibility, read, write])


    const submitDepartment = (e) => {
        e.preventDefault();

        var object = {};

        object.project_name = deptName.trim()
        object.project_code = Department_code.trim()
        object.project_head = Department_head
        object.project_description = deptDesc.trim()
        object.customer = customer;
        object.selected_users=displayvalue.toString();

        object.organization_id = organization_id
        object.page_name = page_name;

        //console.log(object)

        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 /\\&$|~!@#()*\-+%{}\[\]_.;,:"'<>=]*)?$/;
        if (object.project_name == "") {
            swal("Warning!", "Project Name cannot be a empty", "warning");
            return false;
        }
        else if (!object.project_name.match(nexp)) {
            swal("Warning!", "Project Name cannot contain certain symbols ", "warning");
            return false;
        }
        else if (true) {
            for (var i = 0; i < editmodal.length; ++i) {
                if (editmodal[i].project_name == object.project_name) {
                    swal("Oops!", "Project Name already exists, try different one!", "error");
                    return false;
                }
            }
        }
        if (object.customer == "") {
            swal("Warning!", "Customer cannot be a empty", "warning");
            return false;
        }

        if (object.project_head == "") {
            swal("Warning!", "Project head cannot be a empty", "warning");
            return false;
        }
        else if (!object.project_head.match(nexp)) {
            swal("Warning!", "Project head cannot contain certain symbols ", "warning");
            return false;
        }

        setSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
         
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-project`, object, headers).then((res) => {
            setSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Project added successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else if (res.data.operation === "same_project_name") {
                swal("Oops!", "Use a different Project name!!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        }).catch(function (error) {
            console.log(error)
        });
    }

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> :
                null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}

            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Create Project</h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">

                        {
                            state == 1 ?
                            <div className="card">
                                <form className="form">
                                    <div className="card-body p-9">
                                        <div className=" py-10 px-lg-17">
                                            <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                                <div className="row mb-5">
                                                    <div className="col-md-12 fv-row">
                                                        <label className="required fs-5 fw-bold mb-2">Project Name</label>
                                                        <input id="department_name" type="text" className="form-control form-control-solid " placeholder="" name="department-name" value={deptName} onChange={(e) => { setDeptName(e.target.value) }} readOnly={write?false:true}/>
                                                    </div>
                                                </div>
                                                <div className="row mb-5">
                                                    <div className="col-md-12 fv-row">
                                                        <label className="fs-5 fw-bold mb-2">Project code</label>
                                                        <input id='category_code' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={Department_code} onChange={(e) => { setDepartment_code(e.target.value) }} readOnly={write?false:true}/>
                                                    </div>
                                                </div>
                                                <div className="row mb-5">
                                                    <div className="col-md-12 fv-row">
                                                        <label className="required fs-5 fw-bold mb-2">Project head</label>
                                                        <Select
                                                            name="category"
                                                            id="category"
                                                            className="form-control form-control-solid"
                                                            options={userlist}
                                                            onChange={(event) => { setDepartment_head(event.value) }}
                                                            value={userlist.filter(option => option.value == Department_head).length != 0 && userlist.filter(option => option.value == Department_head)[0]}
                                                            placeholder="--Choose a Project Head--"
                                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-5">
                                                    <div className="col-md-12 fv-row">
                                                        <label className="required fs-5 fw-bold mb-2">Customer Name</label>
                                                        <Select
                                                            name="category"
                                                            id="category"
                                                            className="form-control form-control-solid"
                                                            options={customerlist}
                                                            onChange={(event) => { setcustomer(event.value) }}
                                                            value={customerlist.filter(option => option.value == customer).length != 0 && customerlist.filter(option => option.value == customer)[0]}
                                                            placeholder="--Choose a Customer--"
                                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row mb-5">
                                                    <div className="col-md-12 fv-row">
                                                        <label className="fs-5 fw-bold mb-2">Project Users</label>
                                                        <Select 
                                                            isMulti 
                                                            options={userlist1}  
                                                            onChange={(e) => {
                                                                getvalue(Array.isArray(e)?e.map(x=>x.value):[])
                                                            }}
                                                            styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row mb-5">
                                                    <div className="d-flex flex-column mb-10 fv-row"><label className="fs-6 fw-bold mb-2">Description</label><textarea id="department_description" className="form-control form-control-solid" rows="3" name="discription" placeholder="" autocomplete="off" value={deptDesc} onChange={(e) => { setDeptDesc(e.target.value) }} readOnly={write?false:true}></textarea></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer flex-center">
                                            {
                                                write == 1 &&
                                                <button type="submit" className="btn btn-primary rounded" onClick={(e) => { submitDepartment(e) }} data-kt-indicator={submitButtonState?"on":"off"} disabled={submitButtonState}>
                                                    <span className="indicator-label">Submit</span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            }
                                            
                                        </div>
                                    </div>
                                </form>
                            </div>
                            :
                            state == 2?
                                <div className="card">
                                    <div className="card-body">
                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    </div>
                                </div>
                            :
                            <>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                            <div className="d-flex flex-stack flex-grow-1">
                                                <div className="fw-bold">
                                                    <h4 className="text-gray-800 fw-bolder">Please Login</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>  
                            </>
                        }
                    </div>
                </div>
            </div>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org}/>
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer}/>
        </>
    );
}
export default AddProject;