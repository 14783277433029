import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import {setCookie} from './cookie.js';

const loaderstyle = {
	display: "block",
	marginLeft: "auto",
	marginRight: "auto",
	marginTop: "auto",
	marginBottom: "auto",
};

export default function Organization_modal(props){
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

	return (
		<Modal show={props.OrganizationModalShow} onHide={()=>{props.setOrganizationModalShow1()}} size="lg" centered>
			<Modal.Header>
				<Modal.Title>Select Organization</Modal.Title>
				<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{props.setOrganizationModalShow1(false)}}>
					<span className="svg-icon svg-icon-1">
						<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
							<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
								<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
								<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
							</g>
						</svg>
					</span>
				</div>
			</Modal.Header>
			<Modal.Body>
				<div className='container'>
					<form class="form-default">
						<div  class="modal-body tt-background">
							<div class="tt-modal-discount row">
								<div className="row mb-5">
									{props.state_org==1 ?
									<>
									{props.organization_list.length > 0 && props.organization_list.map((obj, index) => {
										if(index < 5)
										{
											return(
												<>									
												<div class="col-lg-12">
													{(props.organization_id==obj.organization_id)?
													<input type="radio" class="btn-check" onClick={(e) => { setCookie('organization_id', obj.organization_id, 1,true); window.location.reload(); }} id={`org_${obj.organization_id}`} checked/>
													:
													<input type="radio" class="btn-check" onClick={(e) => { setCookie('organization_id', obj.organization_id, 1,true); window.location.reload(); }} id={`org_${obj.organization_id}`}/>
													}
													<label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10" for={obj.organization_id}>
														<span class="svg-icon svg-icon-3x me-5">
															<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																<path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black"></path>
																<path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black"></path>
															</svg>
														</span>
														<span class="d-block fw-bold text-start">
															<span class="text-dark fw-bolder d-block fs-4 mb-2">{obj.organization_name}</span>
															<span class="text-muted fw-bold fs-6">Organization ID:{obj.organization_id}</span>
														</span>
													</label>
												<div class="fv-plugins-message-container invalid-feedback"></div></div>
												</>
											);
										}
									})}
									</>
									:
									props.state_org==3 ?
									<>
									<div class="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-10 p-6">
										<span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
												<rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
												<rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
											</svg>
										</span>
										<div class="d-flex flex-stack flex-grow-1">
											<div class="fw-semibold">
												<h4 class="text-gray-900 fw-bold">Please Note!</h4>
												<div class="fs-6 text-gray-700">Your organization - ({props.organization_name}) is not connected with invoice module. You can complete set up before using this module. 
												<a href="#"></a></div>
											</div>
										</div>
									</div>
									</>
									:
									<>
										<img src="/images/loader-06.svg" alt="" style={loaderstyle}  />
									</>
									}
									
								</div>
							</div>
						</div>
					</form>
				</div>
			</Modal.Body>
			<Modal.Footer> 
			{props.state_org==3 &&
				<button onClick={(e) => {window.location.href="/quick-set-up"}} type="submit" id="kt_modal_new_address_submit" class="btn btn-primary rounded" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}>
					<span class="indicator-label">Proceed....</span>
					<span class="indicator-progress">Please wait...
						<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
					</span>
				</button>
			}
			</Modal.Footer>
		</Modal>
	);
};
