import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import { Link, Redirect } from 'react-router-dom'
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';

function PurchaseDetails(props) {
	let [subscription, setSubscription] = useState([]);
	let [duration, setDuration] = useState("month");
	let [subscription_features, setSubscriptionFeatures] = useState([]);
	let [user, setUser] = useState({});
	let [username, setUsername] = useState("");
	let [product_name, setproduct_name] = useState("");
	let [subscription_name, setsubscription_name] = useState("");
	let [add_status, setadd_status] = useState("");
	let [start_date, setstart_date] = useState("");
	let [end_date, setend_date] = useState("");
	const [PurchaseDetails, setPurchaseDetails] = useState([]);
	const [AddressDetails, setAddressDetails] = useState([]);
	let [state, setstate] = useState(2);
	let [image, setImage] = useState("");
	let [name, setname] = useState("");
	let [timer, setTimer] = useState(0);
	let [clock_timer, setClockTimer] = useState(0);
	const [SubscriptionDetails, setSubscriptionDetails] = useState([]);
	const [Invoicedetails, setInvoicedetails] = useState([]);
	const [invoicedraft, setinvoicedraft] = useState([]);
	let [payment_gateway_id, setpayment_gateway_id] = useState("");
	let [payment_platform, setpayment_platform] = useState("");
	let [timezone_change, set_timezone_change] = useState("+00:00");
	//const [organization_id, setOrganization_id] = useState("");
	const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);
	var page_name = "view-subscription";
	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	const renderTime = ({ remainingTime }) => {

		if (remainingTime === 0) {
			//return <div className="timer">Too lale...</div>;
			window.location.href = "/login";
		}

		return (
			<div className="timer">
				<div className="text">Remaining</div>
				<div className="value">{remainingTime}</div>
				<div className="text">seconds</div>
			</div>
		);
	};

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	useEffect(() => {
		setCookie("lastpage", window.location.href, 1)
		const validateJWT = () => {

			if (getCookie("access_token") != "") {
				var object = {};
				object.token = getCookie("access_token");

				var headers = {
					headers: {
						"Content-Type": "application/json",
						"Accept": "*/*"
					}
				}
				axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var user_object = {};
						user_object.email = res.data.email;
						user_object.name = res.data.name;
						user_object.img = res.data.img;
						setUser(user_object)
					}
					else {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
					.catch(function (error) {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					});

				axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var token = res.data.audience_license;
						if ( res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
						else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
									else
									{
										temp = 2;
									}
                                }

                            }
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
						if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
							setCookie('access_token', res.data.audience_license, 1)
							setTimer(process.env.REACT_APP_JWT_EXPIRY);
							//setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
						}
						else {
							res.data.audience_count = (res.data.audience_count)
							if (res.data.audience_count > 0) {
								const timer_decrease = setInterval(() => {
									res.data.audience_count = (res.data.audience_count - 1);
									//console.log(res.data.audience_count);
									if (res.data.audience_count > 0 && res.data.audience_count < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
										if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
											setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
											window.$('#kt_modal_timer').modal('show');
										}
									}
								}, 1000);

								return () => clearInterval(timer_decrease);
							}
							//setTimer(res.data.audience_count)
							//setClockTimer(res.data.audience_count)
						}
					}
					else {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
					.catch(function (error) {
						console.log(error);
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					});
			}
			else {
				swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
			}
		}

		validateJWT();

	}, [])

	useEffect(() => {
		async function getaccountdetails() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-account-information`, { method: 'POST', body: JSON.stringify({ "email": user.email}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			//console.log(body)
			if (body.length > 0) {
				if (body[0].timezone_value != "" && body[0].timezone_value != null) {
					set_timezone_change(body[0].timezone_value)
					//setstate1(1);
				}
				else {
					//setstate1(2);
				}
				setname(body[0].account_name)
				if (body[0].image_name.startsWith("http")) {
					setImage(body[0].image_name);
				}
				else if (body[0].image_name) {
					setImage(`https://shavastra-gn.s3.ap-south-1.amazonaws.com/account_image/${body[0].image_name}`);
				}
				else {
					setImage(user.img);
				}
			}
			else {

				setname(user.name)
				setImage(user.img)
			}

		}
		(user.email && visibility) && getaccountdetails();

	}, [user, visibility, read, write])



	useEffect(() => {

		async function getpurchasedetails() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-purchase-details`, { method: 'POST', body: JSON.stringify({ "id": props.match.params.id, "email": user.email,"page_name":page_name}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			if (body.length > 0) {
				setstate(1)
				var b = JSON.stringify(body[0].billing_details);
				var billing_details = b.replace(/\\/g, '');
				var billing_details1 = billing_details.replace(/"""/g, '');
				if (billing_details1.startsWith('"')) {
					billing_details1 = billing_details1.slice(1, -1);
				}
				setPurchaseDetails(JSON.parse(billing_details1));
				setAddressDetails(JSON.parse(billing_details1).address_details);
				setproduct_name(body[0].product_name)
				setsubscription_name(body[0].subscription_name)
				setadd_status(body[0].add_status);
				setpayment_gateway_id(body[0].payment_gateway_subscription_id);
				setpayment_platform(body[0].payment_platform);

				let today1 = new Date(body[0].starting_date).toLocaleString('hi-IN', { timeZone: 'Asia/Kolkata', hour12: false });
				var today_array = today1.split(", ");
				var date_string = today_array[0];
				var date_string_array = date_string.split("/");
				let date = new Date(date_string_array[2] + "-" + date_string_array[1].padStart(2, '0') + "-" + date_string_array[0].padStart(2, '0'));
				let longMonth = date.toLocaleString('en-us', { month: 'long' });
				var today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];
				setstart_date(today)


				let today2 = new Date(body[0].ending_date).toLocaleString('hi-IN', { timeZone: 'Asia/Kolkata', hour12: false });
				var today_array1 = today2.split(", ");
				var date_string1 = today_array1[0];
				var date_string_array1 = date_string1.split("/");
				let date1 = new Date(date_string_array1[2] + "-" + date_string_array1[1].padStart(2, '0') + "-" + date_string_array1[0].padStart(2, '0'));
				let longMonth1 = date1.toLocaleString('en-us', { month: 'long' });
				var today3 = date_string_array1[0].padStart(2, '0') + " " + longMonth1 + " " + date_string_array1[2];
				setend_date(today3)

			}
			else {
				setstate(0)
			}

			//console.log(body[0])

		}
		(user.email && visibility) && getpurchasedetails();
	}, [user, visibility, read, write])

	//console.log(setAddressDetails);

	useEffect(() => {
		async function get_invoice_details() {

			var object = {};
			object.email = user.email;
			if (payment_platform == "stripe-euro" || payment_platform == "stripe-usd") {
				const response = await fetch(
					`${process.env.REACT_APP_SERVER_URL}/get-invoice-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "sub_id": payment_gateway_id,"page_name":page_name}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
				);
				const body = await response.json();

				body.value.invoice.map((obj, index) => {
					//END DATE
					body.value.invoice[index].period_end = moment.unix(body.value.invoice[index].period_end).zone(timezone_change).format("DD/MM/YYYY");
					var date_string_array = body.value.invoice[index].period_end.split("/");
					let date = new Date(date_string_array[2] + "-" + date_string_array[1].padStart(2, '0') + "-" + date_string_array[0].padStart(2, '0'));
					let longMonth = date.toLocaleString('en-us', { month: 'long' });
					body.value.invoice[index].period_end1 = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];
					//START DATE
					body.value.invoice[index].period_start = moment.unix(body.value.invoice[index].period_start).zone(timezone_change).format("DD/MM/YYYY");
					var date_string_array1 = body.value.invoice[index].period_start.split("/");
					let date1 = new Date(date_string_array1[2] + "-" + date_string_array1[1].padStart(2, '0') + "-" + date_string_array1[0].padStart(2, '0'));
					let longMonth1 = date1.toLocaleString('en-us', { month: 'long' });
					body.value.invoice[index].period_start1 = date_string_array1[0].padStart(2, '0') + " " + longMonth1 + " " + date_string_array1[2];

				})
				body.value.invoicedraft.map((obj, index) => {
					//END DATE
					body.value.invoicedraft[index].period_end = moment.unix(body.value.invoicedraft[index].period_end).zone(timezone_change).format("DD/MM/YYYY");
					var date_string_array = body.value.invoicedraft[index].period_end.split("/");
					let date = new Date(date_string_array[2] + "-" + date_string_array[1].padStart(2, '0') + "-" + date_string_array[0].padStart(2, '0'));
					let longMonth = date.toLocaleString('en-us', { month: 'long' });
					body.value.invoicedraft[index].period_end1 = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];
					//START DATE
					body.value.invoicedraft[index].period_start = moment.unix(body.value.invoicedraft[index].period_start).zone(timezone_change).format("DD/MM/YYYY");
					var date_string_array1 = body.value.invoicedraft[index].period_start.split("/");
					let date1 = new Date(date_string_array1[2] + "-" + date_string_array1[1].padStart(2, '0') + "-" + date_string_array1[0].padStart(2, '0'));
					let longMonth1 = date1.toLocaleString('en-us', { month: 'long' });
					body.value.invoicedraft[index].period_start1 = date_string_array1[0].padStart(2, '0') + " " + longMonth1 + " " + date_string_array1[2];

				})
				//console.log(body)
				setInvoicedetails(body.value.invoice)
				setinvoicedraft(body.value.invoicedraft)
			}
			else if (payment_platform == "razorpay") {
				const response = await fetch(
					`${process.env.REACT_APP_SERVER_URL}/get-invoice-details2`, { method: 'POST', body: JSON.stringify({ "email": user.email, "sub_id": payment_gateway_id,"page_name":page_name}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
				);
				const body = await response.json();
				// console.log(body)
				body.value.invoice.map((obj, index) => {
					//END DATE
					body.value.invoice[index].period_end = moment.unix(body.value.invoice[index].period_end).zone(timezone_change).format("DD/MM/YYYY");
					var date_string_array = body.value.invoice[index].period_end.split("/");
					let date = new Date(date_string_array[2] + "-" + date_string_array[1].padStart(2, '0') + "-" + date_string_array[0].padStart(2, '0'));
					let longMonth = date.toLocaleString('en-us', { month: 'long' });
					body.value.invoice[index].period_end1 = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];
					//START DATE
					body.value.invoice[index].period_start = moment.unix(body.value.invoice[index].period_start).zone(timezone_change).format("DD/MM/YYYY");
					var date_string_array1 = body.value.invoice[index].period_start.split("/");
					let date1 = new Date(date_string_array1[2] + "-" + date_string_array1[1].padStart(2, '0') + "-" + date_string_array1[0].padStart(2, '0'));
					let longMonth1 = date1.toLocaleString('en-us', { month: 'long' });
					body.value.invoice[index].period_start1 = date_string_array1[0].padStart(2, '0') + " " + longMonth1 + " " + date_string_array1[2];

				})
				body.value.invoicedraft.map((obj, index) => {
					//END DATE
					body.value.invoicedraft[index].period_end = moment.unix(body.value.invoicedraft[index].period_end).zone(timezone_change).format("DD/MM/YYYY");
					var date_string_array = body.value.invoicedraft[index].period_end.split("/");
					let date = new Date(date_string_array[2] + "-" + date_string_array[1].padStart(2, '0') + "-" + date_string_array[0].padStart(2, '0'));
					let longMonth = date.toLocaleString('en-us', { month: 'long' });
					body.value.invoicedraft[index].period_end1 = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];
					//START DATE
					body.value.invoicedraft[index].period_start = moment.unix(body.value.invoicedraft[index].period_start).zone(timezone_change).format("DD/MM/YYYY");
					var date_string_array1 = body.value.invoicedraft[index].period_start.split("/");
					let date1 = new Date(date_string_array1[2] + "-" + date_string_array1[1].padStart(2, '0') + "-" + date_string_array1[0].padStart(2, '0'));
					let longMonth1 = date1.toLocaleString('en-us', { month: 'long' });
					body.value.invoicedraft[index].period_start1 = date_string_array1[0].padStart(2, '0') + " " + longMonth1 + " " + date_string_array1[2];

				})
				//console.log(body)
				setInvoicedetails(body.value.invoice)
				setinvoicedraft(body.value.invoicedraft)
			}
		}
		payment_gateway_id && payment_platform &&  get_invoice_details();
	}, [payment_gateway_id, payment_platform]);

	function cancel_subscription(e) {

		var sub_id = payment_gateway_id;
		var payment_type = payment_platform;

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		var values = { "sub_id": sub_id, "payment_gateway": payment_type,"page_name":page_name}
		//console.log(payment_type);
		let cancel_type = document.querySelector('input[name="cancel_radio"]:checked').value;

		if (cancel_type == "") {
			swal("Oops!", "Please select which type of subscription cancellation you want!", "error");
			return false;
		}
		else if (!isNaN(cancel_type)) {
			swal("Oops!", "Please select which type of subscription cancellation you want!", "error");
			return false;
		}

		if (cancel_type == "billing_period" && (payment_type == "stripe-euro" || payment_type == "stripe-usd")) {

			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription will be cancelled after the billing period!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});

		}
		else if (cancel_type == "now" && (payment_type == "stripe-euro" || payment_type == "stripe-usd")) {

			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription-now`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription cancelled!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});

		}
		else if (cancel_type == "billing_period" && payment_type == "razorpay") {

			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription-razorpay`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription will be cancelled after the billing period!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});

		}
		else if (cancel_type == "now" && payment_type == "razorpay") {

			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription-now-razorpay`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription cancelled!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});

		}
		else if (payment_type == "Trail") {

			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription-trail`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription cancelled!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});

		}
		else {
			swal("Oops!", "Please select which type of subscription cancellation you want!", "error");
			return false;
		}
	}



	return (
		<>
			{
                (redirect == 1 ||(read == 0 && write == 0)) ? 
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
			{state == 1 ?
				<>

					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
						<div className="toolbar" id="kt_toolbar">
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
								<div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
									<h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">View Subscription
										<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
										<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
									</h1>

								</div>

							</div>
						</div>

						<div className="post d-flex flex-column-fluid" id="kt_post">
							<div className="container" id="kt_content_container">
								<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
									<br />

									<div className="d-flex flex-column flex-xl-row">

										<div className="flex-lg-row-fluid me-lg-15">

											<div className="card card-flush pt-3 mb-5 mb-xl-10">

												<div className="card-header">

													<div className="card-title">
														<h2 className="fw-bolder">Purchase Details</h2>
													</div>

													{PurchaseDetails.product_flag == "Trail" ?
														<div className="card-toolbar">
															<a href={"/subscription-list/" + PurchaseDetails.product_id} className="btn btn-light-primary rounded">Update Subscription</a>
														</div>
														:
														<div className="card-toolbar">
															<a href="/purchase" className="btn btn-light-primary rounded">Update Subscription</a>
														</div>
													}

												</div>


												<div className="card-body pt-3">

													<div className="mb-10">

														<h5 className="mb-4">Billing Details:</h5>


														<div className="row gx-10">

															<div className="col-lg-6">
																{AddressDetails &&
																	<table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0">

																		<tr>
																			<td className="text-gray-400 w-175px">Bill to:</td>
																			<td className="text-gray-800 w-30050px">
																				<a className="text-gray-800 text-hover-primary">{AddressDetails.email}</a>
																			</td>
																		</tr>


																		<tr>
																			<td className="text-gray-400">Customer Name:</td>
																			<td className="text-gray-800">{AddressDetails.first_name} {AddressDetails.last_name}</td>
																		</tr>


																		<tr>
																			<td className="text-gray-400">Address:</td>
																			<td className="text-gray-800">{AddressDetails.city}, {AddressDetails.state},  {AddressDetails.country},  {AddressDetails.pincode}.</td>
																		</tr>


																		<tr>
																			<td className="text-gray-400">Phone:</td>
																			<td className="text-gray-800">{AddressDetails.phone}</td>
																		</tr>

																	</table>
																}
															</div>


															<div className="col-lg-6">

																<table className="table fs-6 fw-bold gs-0 gy-2 gx-2 m-0 ms-0 ms-lg-10">

																	<tr>
																		<td className="text-gray-400 w-175px">Subscribed Product:</td>
																		<td className="text-gray-800 w-250px">
																			<a className="text-gray-800 text-hover-primary">{product_name}</a>
																		</td>
																	</tr>


																	<tr>
																		<td className="text-gray-400">Subscription Fees:</td>
																		{PurchaseDetails.currency == "INR" ?
																			<td className="text-gray-800">₹{PurchaseDetails.price} / {PurchaseDetails.duration}</td>
																			:
																			PurchaseDetails.currency == "USD" ?
																				<td className="text-gray-800">${PurchaseDetails.price} / {PurchaseDetails.duration}</td>
																				:
																				PurchaseDetails.currency == "EUR" ?
																					<td className="text-gray-800">€{PurchaseDetails.price} / {PurchaseDetails.duration}</td>
																					:
																					null
																		}
																	</tr>


																	<tr>
																		<td className="text-gray-400">Billing method:</td>
																		<td className="text-gray-800">{PurchaseDetails.duration}</td>
																	</tr>


																	<tr>
																		<td className="text-gray-400">Currency:</td>
																		<td className="text-gray-800">{PurchaseDetails.currency}</td>
																	</tr>

																</table>

															</div>

														</div>

													</div>


													<div className="mb-0">

														<h5 className="mb-4">Subscribed Products:</h5>


														<div className="table-responsive">

															<table className="table align-middle table-row-dashed fs-6 gy-4 mb-0">

																<thead>

																	<tr className="border-bottom border-gray-200 text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0">
																		<th className="min-w-125px">Product</th>
																		<th className="min-w-125px">Start Date</th>
																		<th className="min-w-125px">End Date</th>
																		<th className="min-w-125px">Total</th>
																	</tr>

																</thead>


																<tbody className="fw-bold text-gray-800">
																	<tr>
																		<td>
																			<label className="w-125px">{product_name}</label>

																		</td>
																		<td>
																			{start_date}
																		</td>

																		<td>
																			{end_date}
																		</td>

																		<td>{PurchaseDetails.currency == "INR" ?
																			<span className="fw-bold text-gray-600">₹{PurchaseDetails.price} / {PurchaseDetails.duration}</span>
																			:
																			PurchaseDetails.currency == "USD" ?
																				<span className="fw-bold text-gray-600">${PurchaseDetails.price} / {PurchaseDetails.duration}</span>
																				:
																				PurchaseDetails.currency == "EUR" ?
																					<span className="fw-bold text-gray-600">€{PurchaseDetails.price} / {PurchaseDetails.duration}</span>
																					:
																					null
																		}</td>

																	</tr>

																</tbody>

															</table>

														</div>

													</div>

												</div>

											</div>



											{Invoicedetails.length > 0 ?
												<div className="card card-flush pt-3 mb-5 mb-xl-10">

													<div className="card-header">

														<div className="card-title">
															<h2> Paid Invoices</h2>
														</div>


														<div className="card-toolbar">


														</div>

													</div>


													<div className="card-body pt-2">

														<div id="kt_referred_users_tab_content" className="tab-content">

															<div id="kt_customer_details_invoices_1" className="tab-pane fade show active" role="tabpanel">

																<div className="table-responsive">

																	<table id="kt_customer_details_invoices_table_1" className="table align-middle table-row-dashed fs-6 fw-bolder gs-0 gy-4 p-0 m-0">

																		<thead className="border-bottom border-gray-200 fs-7 text-uppercase fw-bolder">
																			<tr className="text-start text-gray-400">
																				<th className="min-w-100px">Order ID</th>
																				<th className="min-w-100px">Amount</th>
																				<th className="min-w-100px">Status</th>
																				<th className="min-w-100px">Start Date</th>
																				<th className="min-w-100px">End Date</th>
																			</tr>
																		</thead>


																		<tbody className="fs-6 fw-bold text-gray-600">
																			{Invoicedetails.length > 0 && Invoicedetails.map((obj, index) => {
																				return (
																					<>
																						{obj.status == "paid" ?
																							<tr>
																								<td>
																									<a className="text-gray-600 text-hover-primary">{obj.id}</a>
																								</td>
																								<td className="text-success">{obj.currency == "INR" ?
																									<span className="text-success">₹{obj.amount_due}</span>
																									:
																									obj.currency == "usd" ?
																										<span className="text-success">${obj.amount_due}</span>
																										:
																										obj.currency == "eur" ?
																											<span className="text-success">€{obj.amount_due}</span>
																											:
																											null
																								}
																								</td>
																								<td>
																									<span className="badge badge-light-success">Paid</span>
																								</td>
																								<td>{obj.period_start1}</td>
																								<td>{obj.period_end1}</td>

																							</tr>
																							:
																							null
																						}
																					</>
																				);
																			})}

																		</tbody>

																	</table>

																</div>

															</div>



														</div>

													</div>

												</div>
												:
												null
											}
											{invoicedraft.length > 0 ?
												<div className="card card-flush pt-3 mb-5 mb-xl-10">

													<div className="card-header">

														<div className="card-title">
															<h2> Upcoming Invoices</h2>
														</div>


														<div className="card-toolbar">


														</div>

													</div>


													<div className="card-body pt-2">

														<div id="kt_referred_users_tab_content" className="tab-content">

															<div id="kt_customer_details_invoices_1" className="tab-pane fade show active" role="tabpanel">

																<div className="table-responsive">

																	<table id="kt_customer_details_invoices_table_1" className="table align-middle table-row-dashed fs-6 fw-bolder gs-0 gy-4 p-0 m-0">

																		<thead className="border-bottom border-gray-200 fs-7 text-uppercase fw-bolder">
																			<tr className="text-start text-gray-400">
																				<th className="min-w-100px">Order ID</th>
																				<th className="min-w-100px">Amount</th>
																				<th className="min-w-100px">Status</th>
																				<th className="min-w-100px">Start Date</th>
																				<th className="min-w-100px">End Date</th>
																			</tr>
																		</thead>


																		<tbody className="fs-6 fw-bold text-gray-600">
																			{invoicedraft.length > 0 && invoicedraft.map((obj, index) => {
																				return (
																					<>
																						{obj.status == "draft" ?
																							<tr>
																								<td>
																									<a className="text-gray-600 text-hover-primary">{obj.id}</a>
																								</td>
																								<td className="text-success">{obj.currency == "inr" ?
																									<span className="text-success">₹{obj.amount_due}</span>
																									:
																									obj.currency == "usd" ?
																										<span className="text-success">${obj.amount_due}</span>
																										:
																										obj.currency == "eur" ?
																											<span className="text-success">€{obj.amount_due}</span>
																											:
																											null
																								}
																								</td>
																								<td>
																									<span className="badge badge-light-success">Draft</span>
																								</td>
																								<td>{obj.period_start1}</td>
																								<td>{obj.period_end1}</td>

																							</tr>
																							:
																							null
																						}
																					</>
																				);
																			})}

																		</tbody>

																	</table>

																</div>

															</div>



														</div>

													</div>

												</div>
												:
												null
											}



										</div>


										<div className="flex-column flex-lg-row-auto w-100 w-xl-300px mb-10">

											<div className="card card-flush mb-0" data-kt-sticky="true" data-kt-sticky-name="subscription-summary" data-kt-sticky-offset="{default: false, xl: '200px'}" data-kt-sticky-width="{lg: '250px', xl: '300px'}" data-kt-sticky-left="auto" data-kt-sticky-top="150px" data-kt-sticky-animation="false" data-kt-sticky-zindex="95">

												<div className="card-header">

													<div className="card-title">
														<h2>Summary</h2>
													</div>

												</div>


												<div className="card-body pt-0 fs-6">

													<div className="mb-7">

														<div className="d-flex align-items-center">

															<div className="symbol symbol-60px symbol-circle me-3">
																<img alt="Pic" src={image} />
															</div>


															<div className="d-flex flex-column">

																<a className="fs-4 fw-bolder text-gray-900 text-hover-primary me-2">{name}</a>

																<a className="fw-bold text-gray-600 text-hover-primary">{user.email}</a>

															</div>

														</div>

													</div>


													<div className="separator separator-dashed mb-7"></div>


													<div className="mb-7">

														<h5 className="mb-4">Product details</h5>


														<div className="mb-0">

															<span className="badge badge-light-info me-2 rounded">{product_name}</span>

															{PurchaseDetails.currency == "INR" ?
																<span className="fw-bold text-gray-600">₹{PurchaseDetails.price} / {PurchaseDetails.duration}</span>
																:
																PurchaseDetails.currency == "USD" ?
																	<span className="fw-bold text-gray-600">${PurchaseDetails.price} / {PurchaseDetails.duration}</span>
																	:
																	PurchaseDetails.currency == "EUR" ?
																		<span className="fw-bold text-gray-600">€{PurchaseDetails.price} / {PurchaseDetails.duration}</span>
																		:
																		null
															}

														</div>

													</div>

													<div className="separator separator-dashed mb-7"></div>


													<div className="mb-10">

														<h5 className="mb-4">Subscription Details</h5>


														<table className="table fs-6 fw-bold gs-0 gy-2 gx-2">

															<tr className="">
																<td className="text-gray-400">Subscription Name:</td>
																<td className="text-gray-800">{subscription_name}</td>
															</tr>


															<tr className="">
																<td className="text-gray-400">Started:</td>
																<td className="text-gray-800">{start_date}</td>
															</tr>


															<tr className="">
																<td className="text-gray-400">Status:</td>
																<td>
																	<div className="mb-0">
																		{add_status == "Active" ?
																			<span className="badge badge-light-success me-2 rounded">{add_status}</span>
																			:
																			<span className="badge badge-light-danger me-2 rounded">{add_status}</span>
																		}
																	</div>
																</td>
															</tr>


															<tr className="">
																<td className="text-gray-400">End Date:</td>
																<td className="text-gray-800">{end_date}</td>
															</tr>

														</table>

													</div>
													{add_status == "Active" ?

														<div className="mb-0">
															{write &&
															<a type="button" data-bs-toggle="modal" id="change_status" data-bs-target="#kt_modal_1" className="btn btn-primary rounded">Cancel Subscription</a>
															}
														</div>
														:
														null
													}
												</div>

											</div>

										</div>
									</div>

									<br />
								</div>
							</div>
						</div>




					</div>
				</>
				:
				<>
					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">

						<div className="toolbar" id="kt_toolbar">
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
								<div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
									<h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">View Subscription
										<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
										<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
									</h1>
								</div>
							</div>
						</div>

						<div className="post d-flex flex-column-fluid" id="kt_post">
							<div className="container" id="kt_content_container">
								<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
									<br />

									{state == 2 ?
										<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
										:
										<>

											<div className="flex-lg-row-fluid me-lg-15">
												<div className="card card-flush pt-3 mb-5 mb-lg-10">

													<div className="card-header">
														<div className="card-title">
															<h2 className="fw-bolder">View Subscription</h2>
														</div>
													</div>


													<div className="card-body pt-0">

														<div className="d-flex align-items-center p-3 mb-2">


														</div>


														<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
															<div className="d-flex flex-stack flex-grow-1">
																<div className="fw-bold">
																	<h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
																	<div className="fs-6 text-gray-600">
																	</div>
																</div>

															</div>

														</div>

													</div>

												</div>
											</div>

										</>
									}


									<br />
								</div>
							</div>
						</div>
					</div>
				</>
			}


			<div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

				<div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

					<div className="modal-content">

						<div className="modal-header" id="kt_modal_new_address_header">

							<h2>Session Out</h2>

							<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

								<span className="svg-icon svg-icon-1">
									<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
										<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
											<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
											<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
										</g>
									</svg>
								</span>

							</div>

						</div>

						<div className="modal-body py-10 px-lg-17">

							<div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

								<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

									<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
											<rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
											<rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
										</svg>
									</span>


									<div className="d-flex flex-stack flex-grow-1">

										<div className="fw-bold">
											<h4 className="text-gray-800 fw-bolder">Warning</h4>
											<div className="fs-6 text-gray-600">Your session is going to time out</div>
										</div>

									</div>

								</div>

								{clock_timer > 0 &&
									<div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
										<CountdownCircleTimer
											isPlaying
											duration={clock_timer}
											colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
											onComplete={() => [true, 1000]}
										>
											{renderTime}
										</CountdownCircleTimer>
									</div>
								}

							</div>

						</div>

						<div className="modal-footer flex-center">

							<button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
								<span className="indicator-label">Stay Signed in</span>
								<span className="indicator-progress">Please wait...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
							</button>

						</div>

					</div>
				</div>
			</div>

			<div className="modal fade" id="kt_modal_1" tabindex="-1" aria-hidden="true">

				<div className="modal-dialog mw-650px">

					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">Cancel Subscription</h5>
							<div className="modal-header pb-0 border-0 justify-content-end">

								<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

									<span className="svg-icon svg-icon-1">
										<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
												<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
												<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
											</g>
										</svg>
									</span>

								</div>

							</div>
						</div>
						<div className="modal-body">
							<div className="form-group row">


								<div className="col-lg-12">
									Current subscription status is : {<span className="fs-5 fw-bolder text-success"> Active </span>}<br /><br /><br />

									<div className="mb-0 fv-row">

										<label className="d-flex align-items-center form-label mb-5">Select Cancel Mode
											<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""></i></label>


										<div className="mb-0">

											<label className="d-flex flex-stack mb-5 cursor-pointer">

												<span className="d-flex align-items-center me-2" for="once">



													<span className="d-flex flex-column">
														<span className="fw-bolder text-gray-800 text-hover-primary fs-5">Cancel now</span>

													</span>

												</span>

												<span className="form-check form-check-custom form-check-solid">
													<input className="form-check-input" id="cancel_radio" type="radio" name="cancel_radio" value="now" checked="checked" />
												</span>

											</label>

											<label className="d-flex flex-stack mb-5 cursor-pointer">

												<span className="d-flex align-items-center me-2" for="repeat">


													<span className="d-flex flex-column">
														<span className="fw-bolder text-gray-800 text-hover-primary fs-5">Cancel After Billing Period</span>

													</span>

												</span>

												<span className="form-check form-check-custom form-check-solid" >
													<input className="form-check-input" id="cancel_radio" type="radio" name="cancel_radio" value="billing_period" />
												</span>

											</label>

										</div>

									</div>
									<br /><br />
									<h3>Are you sure about cancelling subscription?</h3>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
							<form >
								<button type="button" className="btn btn-danger" onClick={(e) => { cancel_subscription(e); }} >Yes</button>
							</form>
						</div>

					</div>

				</div>

			</div>
		</>
	);
}
export default PurchaseDetails;