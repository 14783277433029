import React, { useState, useEffect } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import Select from 'react-select';
import moment from 'moment';
import ReactTagInput from "@pathofdev/react-tag-input";
import MUIDataTable from "mui-datatables";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import SetColor from './SetColor.js';
import "@pathofdev/react-tag-input/build/index.css";

export const ShowResellerProductsLog = (props) => {
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    let [state, setstate] = useState(2);

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);

    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);
    
    let [serial, setSerial] = useState([]);
    let [Pname, setPname] = useState('');

    var page_name = "reseller-products-log";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        filter: true,
        fixedHeader: false,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Group',
    };



    const columns = [
        {
            name: "product_name",
            label: "Product Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "product_active",
            label: "Product Active",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "quantity",
            label: "Quantity",
            options: {
                filter: true,
                sort: true
            }
        },
        // {
        //     name: "serialNo",
        //     label: "Serial No",
        //     options: {
        //         filter: true,
        //         sort: true
        //     }
        // },
        {
            name: "date_time",
            label: "Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });

                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
                        else {

                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }

        validateJWT();
    }, [])

    useEffect(() => {
        if (timer > 0) {
            const timer_decrease = setInterval(() => {
                setTimer(timer - 1);
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        window.$('#kt_modal_timer').modal('show');
                    }
                }
            }, 1000);

            return () => clearInterval(timer_decrease);
        }
    }, [timer]);


    useEffect(() => {
        async function getproductlog() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-reseller-product-log`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "sid":props.match.params.id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            console.log(body)
            setEditModal(body);

            if (body.length > 0) {
                setstate(1);
            }
            else {
                setstate(0);
            }
        }
        if (read || write) {
            user.email && visibility && getproductlog();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        async function geteditdetails() {

            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj,indx) => {

                    var temp_obj = {};
                    var id = indx;
                    temp_obj.product_name = obj.product_name;
                    temp_obj.product_active = obj.product_active;
                    temp_obj.quantity = obj.quantity;
                    temp_obj.serialNo = obj.serial_list;
                    temp_obj.status = obj.add_status;
                    temp_obj.date_time = moment(obj.add_date_time).format("DD-MM-YYYY  h:mm a")
                    temp_obj.actions =
                        <div class="dropdown">
                            <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                More
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><Link onClick={(e) => { showSerial(id) }} className="dropdown-item">Product Serial No</Link></li>
                            </ul>
                        </div>
                    temp_data.push(temp_obj);
                })
                setData(temp_data);
            }
            else {
                setData([]);
            }
        }
        editmodal.length > 0 && geteditdetails();

    }, [editmodal])

    const showSerial = (id) => {
        const result = Array.from(editmodal[id].serial_list.split(','), x => [x])
        // const result = Array.from(editmodal.filter(obj => obj.pid == id)[0].serial_list.split(','), x => [x])
        const pName = editmodal[id].product_name
        // const pName = editmodal.filter(obj => obj.pid == id)[0].product_name
        setPname(pName)
        setSerial(result)
        window.$("#DataModal").modal("show")
    }

    const columns2 = ["Serial No(s)"];
    const options2 = {
        filter: true,
        filterType: "dropdown",
        responsive,
        searchPlaceholder: 'Search serial',
        tableBodyHeight,
        tableBodyMaxHeight,
        selectableRows: false,
        download: false,
        print: false,
        viewColumns: false
    };


    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                            <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Product Assign History
                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                            </h1>

                        </div>

                    </div>
                </div>

                <div className="card">

                    <div className="card-body pt-6">
                        {
                            data.length > 0 ?
                                <>
                                    <MUIDataTable
                                        title={"Product Details"}
                                        data={data}
                                        columns={columns}
                                        options={options}
                                    />

                                </>
                                :
                                <>
                                    {
                                        state == 2 ?
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                            :
                                            <>
                                                <div className="card-body pt-0">
                                                    <div className="text-gray-500 fw-bold fs-5 mb-5">Show Product</div>

                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">No Product Assigned.</h4>
                                                                <div className="fs-6 text-gray-600"> </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </>
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>

            <div class="modal fade" id="DataModal" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered mw-350px">
                    <div class="modal-content">
                        <div class="modal-header" id="kt_modal_new_address_header">
                            <h3>{Pname}</h3>
                        </div>
                        <div class="modal-body">
                            <MUIDataTable
                                title={"Product Serial no"}
                                data={serial}
                                columns={columns2}
                                options={options2}
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>
        </>);

};

export default ShowResellerProductsLog;
