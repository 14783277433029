import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import { Link } from 'react-router-dom'
import moment from 'moment';
import './Activity.css';

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';

export const Activity = () => {
    const [accessToken, setaccessToken] = useState('');
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [unique_id, setUnique_id] = useState("");
    const [ActivityLogs, setActivityLogs] = useState([]);
    const [GlobalLogs, setGlobalLogs] = useState([]);
    const [loading, setloading] = useState(false);
	
	let [timer,setTimer] = useState(0);
	let [clock_timer,setClockTimer] = useState(0);

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };
	
	const renderTime = ({ remainingTime }) => {
		
	  if (remainingTime === 0)
	  {
		//return <div className="timer">Too lale...</div>;
		window.location.href = "/login";
	  }

	  return (
		<div className="timer">
		  <div className="text">Remaining</div>
		  <div className="value">{remainingTime}</div>
		  <div className="text">seconds</div>
		</div>
	  );
	};


    const colors = ['sucsess', 'primary', 'danger', 'warning',];

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Oops!", "Please login", "error").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                .catch(function (error) {
                        swal("Oops!", "Please login", "error").then((value) => {
                            window.location.href = "/login"
                        });
                });
				
				axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`,object, headers).then((res) => {
				
					if(res.data.operation == "success")
					{
						var token = res.data.audience_license;
						
						if(!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi"))
						{
							setCookie('access_token', res.data.audience_license, 1)
							setTimer(process.env.REACT_APP_JWT_EXPIRY);
							//setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
						}
						else
						{
							setTimer(res.data.audience_count)
							//setClockTimer(res.data.audience_count)
						}
					}
					else
					{
						swal("Oops!", "Please login", "error").then((value) => {
							window.location.href = "/login"
						});
					}
				})
				.catch(function (error) {
					console.log(error);
					swal("Oops!", "Please login", "error").then((value) => {
						window.location.href = "/login";
					});
				});
            }
            else {
                swal("Oops!", "Please login", "error").then((value) => {
                    window.location.href = "/login"
                });
            }
        }
        validateJWT();
    }, [])
	
	useEffect(() => {
		if(timer > 0)
		{
			const timer_decrease = setInterval(() => {
			  setTimer(timer - 1);
			  if(timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE)
			  {
				if(!document.getElementById("kt_modal_timer").classList.contains("show"))
				{
					setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
					window.$('#kt_modal_timer').modal('show');
				}
			  }
			}, 1000);
			
			return () => clearInterval(timer_decrease);
		}
	}, [timer]);


    useEffect(() => {
        async function getactivitydetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/activity-directalerts`, { method: 'POST', body: JSON.stringify({ "email": user.email }), headers: { "Content-type": "application/json; charset=UTF-8", "token" : getCookie("access_token") } }
            );
            const body = await response.json();

            var m, y, d;
            var col = 0;

            for (var i = 0; i < body.length; i++) {
                let a = body[i].log_date_time.split('-');
                y = a[0];
                m = a[1];
                d = a[2].split('T')[0];
                body[i].year = m + '-' + d + '-' + y;
                if (col === colors.length) {
                    col = 0;
                }
                body[i].color = "fa fa-genderless text-" + colors[col++] + " " + "fs-1";
            }
            setGlobalLogs(body);
            setloading(true);
        }
        user.email && getactivitydetails();

    }, [user])

    useEffect(() => {
        today();
    }, [GlobalLogs])



    function convert() {
        let current_datetime = new Date();
        let formatted_date = current_datetime.getFullYear() + "-" + (current_datetime.getMonth() + 1) + "-" + current_datetime.getDate();
        return formatted_date;
    }

    const today = () => {
        var d1 = convert().split('-');
        var d2;
        var objectToday = GlobalLogs.filter((ele) => {
            var container = {};
            d2 = ele.log_date_time.split('T')[0].split('-');
            if (parseInt(d1[0]) == parseInt(d2[0]) && parseInt(d1[1]) == parseInt(d2[1]) && parseInt(d1[2]) == parseInt(d2[2])) {
                container.page = ele.page;
                container.operation = ele.operation;
                container.operation_result = ele.operation_result;
                container.log_date_time = ele.log_date_time;
                return container;
            }
        })
        setActivityLogs(objectToday);
    }

    const weekly = () => {
        var d1 = new Date();
        d1.setDate(d1.getDate() - 7);
        d1 = new Date((d1.getMonth() + 1) + "/" + d1.getDate() + "/" + d1.getFullYear()).getTime();
        var todate = new Date().getTime();


        const objectWeek = GlobalLogs.filter((ele) => {
            var container = {};
            var date = ele.log_date_time.split('T')[0].split('-');
            var d = new Date(date[1] + "/" + date[2] + "/" + date[0]).getTime();

            if (d1 <= d && d <= todate) {
                container.page = ele.page;
                container.operation = ele.operation;
                container.operation_result = ele.operation_result;
                container.log_date_time = ele.log_date_time;
                return container;
            }
        })
        setActivityLogs(objectWeek);
    }

    const monthly = () => {
        var d1 = new Date();
        d1.setDate(d1.getDate() - 30);
        d1 = new Date((d1.getMonth() + 1) + "/" + d1.getDate() + "/" + d1.getFullYear()).getTime();
        var todate = new Date().getTime();

        const objectMonth = GlobalLogs.filter((ele) => {
            var container = {};
            var date = ele.log_date_time.split('T')[0].split('-');
            var d = new Date(date[1] + "/" + date[2] + "/" + date[0]).getTime();

            if (d1 <= d && d <= todate) {
                container.page = ele.page;
                container.operation = ele.operation;
                container.operation_result = ele.operation_result;
                container.log_date_time = ele.log_date_time;
                return container;
            }
        })

        setActivityLogs(objectMonth);
    }
    return (
        <>
            <div className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed" style={{ "--kt-toolbar-height": "55px", "--kt-toolbar-height-tablet-and-mobile": "55px" }} >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">

                        <AsideNavbar />

                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">

                            <Header />

                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                                <div className="toolbar" id="kt_toolbar">
                                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                        <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                                            <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Direct Alerts Activity Logs
                                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                                <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                            </h1>
                                        </div>
                                    </div>
                                </div>

                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div className="container" id="kt_content_container">
                                        <div className="card  pt-0 pb-15 px-3 px-xl-10">
                                            <br />


                                            {loading ?
                                                <>
                                                    <div className="card mb-4 mb-xxl-8">
                                                        <div className="card-header align-items-center border-0 mt-4">
                                                            <h3 className="card-title align-items-start flex-column">
                                                                <span className="fw-bolder mb-2 text-dark">Activities</span>
                                                                <span className="text-muted fw-bold fs-7">{ActivityLogs.length} Logs</span>
                                                            </h3>
                                                            <div class="card-header card-header-stretch">
                                                            <div class="card-title d-flex align-items-center">
                                                            </div>
                                                            <div class="card-toolbar m-0">
                                                                <ul class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bolder" role="tablist">
                                                                    <li class="nav-item" role="presentation">
                                                                        <Link onClick={today} id="kt_activity_today_tab" class="nav-link justify-content-center text-active-gray-800 active" data-bs-toggle="tab" role="tab" href="#kt_activity_today">Today</Link>
                                                                    </li>
                                                                    <li class="nav-item" role="presentation">
                                                                        <Link onClick={weekly} id="kt_activity_week_tab" class="nav-link justify-content-center text-active-gray-800" data-bs-toggle="tab" role="tab" href="#kt_activity_week">Weekly</Link>
                                                                    </li>
                                                                    <li class="nav-item" role="presentation">
                                                                        <Link onClick={monthly} id="kt_activity_month_tab" class="nav-link justify-content-center text-active-gray-800" data-bs-toggle="tab" role="tab" href="#kt_activity_month">Monthly</Link>
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                        </div>

                                                        
                                                    </div>




                                                    {ActivityLogs.length > 0 ?
                                                        <div class="card-body">
                                                            <div class="tab-content">
                                                                <div id="kt_activity_today" class="card-body p-0 tab-pane fade show active" role="tabpanel" aria-labelledby="kt_activity_today_tab">
                                                                    <div class="timeline">
                                                                        <div class="timeline-item">
                                                                            <div className="card-body pt-5">
                                                                                <div className="timeline-label">
                                                                                    {
                                                                                        ActivityLogs.length > 0 ? ActivityLogs.map((obj, index) => {
                                                                                            return (
                                                                                                <div className="timeline-item">
                                                                                                    <div className="timeline-label fw-bolder text-gray-800 fs-6">{moment(obj.log_date_time).format('llll')}</div>
                                                                                                    <div className="timeline-badge">
                                                                                                        <i className={obj.color}></i>
                                                                                                    </div>
                                                                                                    <div className="timeline-content fw-mormal text-muted ps-3" >{obj.operation[0].toUpperCase()}{obj.operation.slice(1)} {obj.operation_result === 'success' ? 'Successfully done' : 'Failed'} on {obj.page}</div>
                                                                                                </div>
                                                                                            )
                                                                                        }) : null

                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        : <div class="tab-pane fade show active" id="kt_topbar_notifications_2" role="tabpanel">
                                                            <div class="d-flex flex-column px-9">
                                                                <div class="pt-10 pb-0">
                                                                    <h3 class="text-dark text-center fw-bolder">No Data Available</h3>
                                                                </div>
                                                                <div class="text-center px-4">
                                                                    <img class="mw-100 mh-200px" alt="metronic" src="assets/media/illustrations/work.png" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                </>
                                                : <>
                                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />

                                                    <div className="flex-lg-row-fluid me-lg-15">
                                                        <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>


								<div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">
									
									<div className="modal-dialog modal-dialog-centered mw-650px mh-950px">
										
										<div className="modal-content">
										
										<div className="modal-header" id="kt_modal_new_address_header">
												
													<h2>Session Out</h2>
													
													<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
														
														<span className="svg-icon svg-icon-1">
															<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
																	<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
																	<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
																</g>
															</svg>
														</span>
														
													</div>
													
												</div>
												
												<div className="modal-body py-10 px-lg-17">
													
													<div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
														
														<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">
															
															<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
																<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																	<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
																	<rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
																	<rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
																</svg>
															</span>
															
															
															<div className="d-flex flex-stack flex-grow-1">
																
																<div className="fw-bold">
																	<h4 className="text-gray-800 fw-bolder">Warning</h4>
																	<div className="fs-6 text-gray-600">Your session is going to time out</div>
																</div>
																
															</div>
															
														</div>
														
														{clock_timer > 0 &&
														<div className="d-flex mb-9 p-6" style = {{"justify-content" : "center", "height" : "150px"}}>
															<CountdownCircleTimer
															  isPlaying
															  duration={clock_timer}
															  colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
															  onComplete={() => [true, 1000]}
															>
															  {renderTime}
															</CountdownCircleTimer>
														</div>
														}
														
													</div>
													
												</div>
												
												<div className="modal-footer flex-center">
													
													<button type="button" onClick={(e) => {window.location.reload()}}  className="btn btn-primary">
														<span className="indicator-label">Stay Signed in</span>
														<span className="indicator-progress">Please wait...
														<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
													</button>
													
												</div>
											
										</div>
									</div>
								</div>



                            <Footer />

                        </div>
                    </div>
                </div>

            </div>


        </>);

};

export default Activity;
