import React, { useState, useEffect } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import Select from 'react-select';
import moment from 'moment';
import ReactTagInput from "@pathofdev/react-tag-input";
import MUIDataTable from "mui-datatables";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import SetColor from './SetColor.js';
import "@pathofdev/react-tag-input/build/index.css";
import { propTypes } from 'react-bootstrap/esm/Image';

export const ViewRoles = (props) => {
    const [accessToken, setaccessToken] = useState('');
    let [user, setUser] = useState({});
    let [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [unique_id, setUnique_id] = useState("");
    const [PurchaseDetails, setPurchaseDetails] = useState([]);
    //const [loading, setloading] = useState(false);
    let [state, setstate] = useState(2);

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    const [Permission, setpermission] = useState([]);
    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [groupname, setGroupname] = useState("");
    const [account_head, setAccount_head] = useState("");
    const [accountdesc, setAccountdesc] = useState("");
    const [product_name, setProduct_name] = useState("");
    const [vendor_name, setVendor_name] = useState("");
    const [manufacturer_name, setManufacturer_name] = useState("");
    const [start_date, setstart_date] = useState("");
    const [end_date, setend_date] = useState("");
    const [shortdesc, setShortdesc] = useState("");
    const [longdesc, setLongdesc] = useState("");
    const [product_active, setproduct_active] = useState("Yes");
    // let [group_type, setGroup_type] = useState("");
    const [status, setstatus] = useState("");
    const [tags, setTags] = React.useState([]);
    const [subcategory, setSubcategory] = useState([]);
    const [category, setCategory] = useState([]);
    const [outofstock,setoutofstock] = useState('');
    const [product_name1, setproduct_name1] = useState("");

    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    const [organization_id, setOrganization_id] = useState("");
    let [organization_list, setorganization_list] = useState([]);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    let [object, setobject] = useState({});
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

    const [Id, setId] = useState("");
    const [userlist, setuserList] = useState([]);
    const [Name, setName] = useState("");
    const [Email, setEmail] = useState("");
    const [Mobile, setMobile] = useState("");
    const [Employee_id, setEmployee_id] = useState("");
    const [Gender, setGender] = useState("");
    const [Designation, setDesignation] = useState("");
    const [Skip_mail, setSkip_mail] = useState("");
    const [Dob_date, setDob_date] = useState("");
    const [Joining_date, setJoining_date] = useState("");
    let [Department, setDepartment] = useState("");
    let [Submit_to, setSubmit_to] = useState("");
    const [DepartmentList, setDepartmentList] = useState([]);
    let [Policy, setPolicy] = useState("");
    const [PolicyList, setPolicyList] = useState([]);
    let [Role, setRole] = useState("");
    const [UserRoleList, setUserRoleList] = useState([]);
    const [GenderList, setGenderList] = useState([{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }]);
    const [editmodal, setEditModal] = useState([]);
    const [DateFormat, setDateFormat] = useState("");

    var page_name = "roles";
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: false,
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Group',
        downloadOptions:{filename: 'tableDownload.csv', separator: ',',filterOptions:{useDisplayedColumnsOnly:true}}
    };

    const columns = [
        {
            name: "role_name",
            label: "Role Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "role_description",
            label: "Role Description",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setorganization_list(res.data)
						window.$("#ModalOrganization").modal("show")
						//setstate1(1)
					}
				})     
            }
        }
        else {
            swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                window.location.href = "/login"
            });
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
                        else {

                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) 
							{
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }
        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        if (timer > 0) {
            const timer_decrease = setInterval(() => {
                setTimer(timer - 1);
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        window.$('#kt_modal_timer').modal('show');
                    }
                }
            }, 1000);

            return () => clearInterval(timer_decrease);
        }
    }, [timer]);


    useEffect(() => {
        async function getbasecurrency() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(body.length>0)
            {
                setDateFormat(body[0].date_format);
            }
        }
        if(read || write)
        {
            (user.email && visibility) && getbasecurrency();
        }
        async function getroledetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-role-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id,"service":"expense","id":props.match.params.id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            setEditModal(body);
            if (body.length > 0) {
                setstate(1);
            }
            else {
                setstate(0);
            }
        }
        if (read || write && organization_id!="") {
            user.email && visibility && getroledetails();
        }

    }, [user, visibility, read, write,organization_id])

    useEffect(() => {
        async function getbasecurrency() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if(body.length>0)
            {
                setDateFormat(body[0].date_format);
            }
        }
        if(read || write)
        {
            (user.email && visibility) && getbasecurrency();
        }
        async function getUserRoledetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-roles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id,"service":"expense"}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.add_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.user_role;
                    temp_obj.value = obj.user_role_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setUserRoleList(temp_dropdown);
        }
        if (read || write) {
            user.email && visibility && getUserRoledetails();
        }

        async function getuserdetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/user-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
      
            if (body.length > 0) {
                var temp_dropdown = [];
                body.map((obj) => {
                    if (obj.log_status === "Active") {
                        var temp_obj = {};
                        temp_obj.label = obj.name;
                        temp_obj.value = obj.user_id;
                        temp_dropdown.push(temp_obj);
                    }
                })
                //setEditModal(body);
                setuserList(temp_dropdown);
            }
            else {
                setstate(0);
            }
        }
        if (read || write && organization_id!="") {
            user.email && visibility && getuserdetails();
        }

        async function getdeparmentdetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/department-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.department_name;
                    temp_obj.value = obj.department_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setDepartmentList(temp_dropdown);
        }
        if (read || write) {
            user.email && visibility && getdeparmentdetails();
        }

        async function getUserPolicydetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-travel-policy`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id,"service":"expense"}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            var temp_dropdown = [];
            body.map((obj) => {
                if (obj.add_status === "Active") {
                    var temp_obj = {};
                    temp_obj.label = obj.policy_name;
                    temp_obj.value = obj.unique_id;
                    temp_dropdown.push(temp_obj);
                }
            })
            setPolicyList(temp_dropdown);
        }
        if (read || write) {
            user.email && visibility && getUserPolicydetails();
        }

    }, [user, visibility, read, write,organization_id])

    
    const deleteStatus = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].user_role_id) {
                setId(editmodal[i].user_role_id)
                setstatus("Deleted");
            }
        }
    }

    const updateStatus = (id) => {
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].unique_id) {
                setId(editmodal[i].unique_id)
                setstatus(editmodal[i].log_status);
            }
        }
    }

    const editDetails = (id) => {
        console.log(editmodal)
        for (let i = 0; i < editmodal.length; i++) {
            
            if (id == editmodal[i].unique_id) {
                setId(editmodal[i].unique_id)
                setName(editmodal[i].name);
                setEmail(editmodal[i].user_id)
                setMobile(editmodal[i].expense.mobile)
                setEmployee_id(editmodal[i].expense.Employee_id)
                setGender(editmodal[i].expense.gender)
                setDesignation(editmodal[i].expense.designation)
                setRole(editmodal[i].expense.role)
                //setSkip_mail(editmodal[i].add_status)
                setpermission(editmodal[i].expense.permission)
                setDob_date(editmodal[i].expense.Date_of_birth)
                setJoining_date(editmodal[i].expense.Joining_date)
                setDepartment(editmodal[i].expense.department)
                setSubmit_to(editmodal[i].expense.Submit_To)
            }
        }
    }

    const UpdateUser = (e) => {
        e.preventDefault();
        var object = {};
        object.organization_id=organization_id;
        object.id = Id;
        object.name = Name;
        object.email_id = Email;
        object.mobile = Mobile;
        object.Employee_id = Employee_id;
        object.Submit_to = Submit_to;
        object.role = Role;
        object.service = "expense";
        object.permission = Permission;
        object.Dob_date = Dob_date;
        object.department = Department;
        object.Joining_date = Joining_date;
        object.Gender = Gender;
        object.Policy = Policy;
        object.Skip_mail = Skip_mail;
        object.Designation= Designation;
        object.page_name = page_name;

        if (object.name == "") {
            swal("Warning!", "Name cannot be a empty", "warning");
            return false;
        }
        var exp=/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        if(object.email_id=="")
        {
            //console.log("7");
            swal("Warning!", "Please provide an email", "warning");
            return false;
        }
        else if (!object.email_id.match(exp))
        {
            //console.log("8");
            swal("Warning!", "Please provide a valid email", "warning");
            return false;
        }
        var oname = editmodal.filter((item) => { return item.unique_id !== object.email_id })
        for (var i = 0; i < oname.length; ++i) {
            if (oname[i].label == object.email_id) {
                swal("Oops!", "This email id already connected to your organization, try different one!", "error");
                return false;
            }
        }
        if (object.role == "") {
            swal("Warning!", "Role field cannot be a empty", "warning");
            return false;
        }
        if (object.Policy == "") {
            swal("Warning!", "Policy field cannot be a empty", "warning");
            return false;
        }
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-user`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "user updated successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else if (res.data.operation === "same_user_name") {
                swal("Oops!", "Use a different User name!!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        }).catch(function (error) {
            console.log(error)
        });
        
    }

    const submitStatus = (e) => {
        e.preventDefault();

        var values = { "id": Id, "status": status, "page_name": page_name,"organization_id":organization_id};
        setMainSubmitButtonState(true)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-user`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Status changed successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
        
    }

    return (
        <>
        { 
        (state==4) ?
        <>
        <Redirect to={{
            pathname: `/update-user/${object.user_unique_id}`,
            state: object
        }}
        />
        </>
        :
        null
        }
        
    
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">View Roles
                            <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                
				<div id="kt_app_content" class="app-content flex-column-fluid">
                    <div id="kt_app_content_container" class="app-container container-xxl">
                    {state==1 ?
                        <div class="d-flex flex-column flex-lg-row">
                            <div class="flex-column flex-lg-row-auto w-100 w-lg-200px w-xl-300px mb-10">
                                <div class="card card-flush">
                                    <div class="card-header">
                                        <div class="card-title">
                                            <h2 class="mb-0">{editmodal[0].user_role}</h2>
                                        </div>
                                    </div>
                                    <div class="card-body pt-0">
                                        <div class="d-flex flex-column text-gray-600">
                                            <div class="d-flex align-items-center py-2">
                                            <span class="bullet bg-primary me-3"></span>{editmodal[0].role_description}</div>
                                        </div>
                                    </div>
                                    <div class="card-footer pt-0">
                                        
                                        <Link to={`${process.env.REACT_APP_SERVER_URL}/update-roles/${editmodal[0].user_role_id}`}><button type="button" class="btn btn-light btn-active-primary me-2" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role">View Role</button></Link>
                                        <Link to={`${process.env.REACT_APP_SERVER_URL}/view-roles/${editmodal[0].user_role_id}`}><button type="button" class="btn btn-light btn-active-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role">Edit Role</button></Link>
                                    </div>	
                                </div>
                            </div>
                            
                            <div class="flex-lg-row-fluid ms-lg-10">
                                <div class="card card-flush mb-6 mb-xl-9"> 
                                    <div class="card-header pt-5">
                                        <div class="card-title">
                                            <h2 class="d-flex align-items-center">Users Assigned
                                            <span class="text-gray-600 fs-6 ms-1">({editmodal[0].user_details.length})</span></h2>
                                        </div>
                                        <div class="card-toolbar">
                                            <a href="/add-user">
                                            <button type="button" class="btn btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_add_permission">
                                            <span class="svg-icon svg-icon-3">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="5" fill="currentColor"></rect>
                                                    <rect x="10.8891" y="17.8033" width="12" height="2" rx="1" transform="rotate(-90 10.8891 17.8033)" fill="currentColor"></rect>
                                                    <rect x="6.01041" y="10.9247" width="12" height="2" rx="1" fill="currentColor"></rect>
                                                </svg>
                                            </span>
                                            Add User</button></a>
											</div>
                                        {/*<div class="card-toolbar">
                                            <div class="d-flex align-items-center position-relative my-1" data-kt-view-roles-table-toolbar="base">
                                                <span class="svg-icon svg-icon-1 position-absolute ms-6">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                                                        <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor" />
                                                    </svg>
                                                </span>
                                                
                                                <input type="text" data-kt-roles-table-filter="search" class="form-control form-control-solid w-250px ps-15" placeholder="Search Users" />
                                            </div>
                                            
                                            <div class="d-flex justify-content-end align-items-center d-none" data-kt-view-roles-table-toolbar="selected">
                                                <div class="fw-bold me-5">
                                                <span class="me-2" data-kt-view-roles-table-select="selected_count"></span>Selected</div>
                                                <button type="button" class="btn btn-danger" data-kt-view-roles-table-select="delete_selected">Delete Selected</button>
                                            </div>   
                                        </div>*/}
                                    </div>
                                    <div class="card-body pt-0">
                                        <table class="table align-middle table-row-dashed fs-6 gy-5 mb-0" id="kt_roles_view_table">
                                            <thead>
                                                <tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                                                    <th class="min-w-50px">Name</th>
                                                    <th class="min-w-125px">Joined Date</th>
                                                    <th class="min-w-125px">Status</th>
                                                    <th class="text-end min-w-100px">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody class="fw-semibold text-gray-600">
                                            {editmodal[0].user_details.length > 0 && editmodal[0].user_details.map((obj, index) => {
                                                return (
                                                <>
                                                <tr>
                                                    <td class="d-flex align-items-center">
                                                        <div class="d-flex flex-column">
                                                            <a href="../../demo1/dist/apps/user-management/users/view.html" class="text-gray-800 text-hover-primary mb-1">{obj.name}</a>
                                                            <span>{obj.user_id}</span>
                                                        </div>
                                                    </td>
                                                    <td>{moment(obj.log_date_time).format(DateFormat)}</td>
                                                    <td><span class="badge py-3 px-4 fs-7 badge-light-success">{obj.log_status}</span></td>
                                                    <td class="text-end">
                                                        <div class="dropdown">
                                                        {
                                                            write ?
                                                        <>
                                                        <button class="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fas fa-bars px-0"></i>
                                                        </button>
                                                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><a onClick={(e) => { editDetails(obj.unique_id) }} data-bs-toggle="modal" data-bs-target="#EditDetails" class="dropdown-item">Edit details</a></li>
                                                            <li><Link to={`/update-user-role/${obj.unique_id}`} class="dropdown-item">Edit role information</Link></li>
                                                            {/*<li><a onClick={(e) => { updateStatus(obj.unique_id) }} data-bs-toggle="modal" data-bs-target="#EditStatus" class="dropdown-item" href="#">Change status</a></li>*/}
                                                            <li><a onClick={(e) => { deleteStatus(obj.unique_id) }} data-bs-toggle="modal" data-bs-target="#deleteStatus" class="dropdown-item" href="#">Delete</a></li>
                                                            {/*obj.message_flag=="later"&&<li><a onClick={(e) => { sendInvitation(obj.unique_id1) }} class="dropdown-item">Send Invitation</a></li>*/}
                                            
                                                            </ul>
                                                        </>        
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    </td>
                                                </tr>
                                                </>
                                                )
                                            })}                   
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <>
                        {
                            state == 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                :
                                <>
                                    <div className="card-body pt-0">
                                        <div className="text-gray-500 fw-bold fs-5 mb-5">View Role Details</div>

                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                            <div className="d-flex flex-stack flex-grow-1">
                                                <div className="fw-bold">
                                                    <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                    <div className="fs-6 text-gray-600"> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </>
                }
                    </div>
                </div>                
    
                {/*<div className="card">

                    <div className="card-body pt-6">
                        {
                            data.length > 0 ?
                                <>
                                    <MUIDataTable
                                        title={"Role Details"}
                                        data={data}
                                        columns={columns}
                                        options={options}
                                    />

                                </>
                                :
                                <>
                                    {
                                        state == 2 ?
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                            :
                                            <>
                                                <div className="card-body pt-0">
                                                    <div className="text-gray-500 fw-bold fs-5 mb-5">Show Policies</div>

                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                <div className="fs-6 text-gray-600"> </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </>
                                    }
                                </>
                        }
                    </div>
                </div>*/}
            </div>
            

            <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Change Status</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">

                                <div className="col-lg-12">
                                    Current Status is : {(status == "Active"|| status == "ACTIVE") ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
                                    <h3>Are you sure about changing status?</h3>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <form >
                                <input type="hidden" id="id" name="activeId" />
                                <input type="hidden" id="st" name="st" />

                                {(status == "Active" || status == "ACTIVE") ? <button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} >Deactivate</button> : <button type="button" className="btn btn-success" onClick={(e) => { submitStatus(e); }} >Activate</button>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
           
            <div class="modal fade" id="ModalOrganization" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1" role="dialog" aria-label="myModalLabel" aria-hidden="true"  data-pause="99999999999999999">
                <div class="modal-dialog mw-700px">
                    <div class="modal-content ">
                        <div class="modal-header">
                            <h2>Select Organization</h2>
                            {/*<div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                <span class="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                            </div> */}
                        </div>
                        <div className="modal-body py-10 px-lg-17">
                            <form class="form-default">
                                <div  class="modal-body tt-background">
                                    <div class="tt-modal-discount row">
                                        <div className="row mb-5">
                                            {
                                            organization_list.length > 0 ? organization_list.map((obj, index) => {
                                                if(index < 5)
                                                {
                                                    return(
                                                        <>									
                                                        <div class="col-lg-12">
                                                            {(getCookie("organization_id")==obj.organization_id)?
                                                            <input type="radio" class="btn-check" onClick={(e) => { setCookie('organization_id', obj.organization_id, 1); window.location.reload(); }} name="account_type" value="personal" id={obj.organization_id} checked/>
                                                            :
                                                            <input type="radio" class="btn-check" name="account_type" onClick={(e) => { setCookie('organization_id', obj.organization_id, 1); window.location.reload(); }} value="personal"  id={obj.organization_id}/>
                                                            }
                                                            <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10" for={obj.organization_id}>
                                                                <span class="svg-icon svg-icon-3x me-5">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black"></path>
                                                                        <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black"></path>
                                                                    </svg>
                                                                </span>
                                                                <span class="d-block fw-bold text-start">
                                                                    <span class="text-dark fw-bolder d-block fs-4 mb-2">{obj.organization_name}</span>
                                                                    <span class="text-muted fw-bold fs-6">Organization ID:{obj.organization_id}</span>
                                                                </span>
                                                            </label>
                                                        <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                        </>
                                                    );
                                                }
                                            })
                                            :
                                            <>
                                            { state == 2 ?
                                                <img src="/images/loader-06.svg" alt="" style={loaderstyle}  />
                                                :
                                                null
                                            }
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="EditDetails" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-750px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Edit Details</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                            <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="400px">
                            <div className="row mb-5">
                                <div className="col-md-6 fv-row">
                                    <label className="required fs-5 fw-bold mb-2">Name</label>
                                    <input id="department_name" type="text" className="form-control form-control-solid " placeholder="" name="department-name" value={Name} onChange={(e) => { setName(e.target.value) }} readOnly={write?false:true}/>
                                </div>
                                <div className="col-md-6 fv-row">
                                    <label className=" fs-5 fw-bold mb-2">Employee ID</label>
                                    <input id="department_name" type="text" className="form-control form-control-solid " placeholder="" name="department-name" value={Employee_id} onChange={(e) => { setEmployee_id(e.target.value) }} readOnly={write?false:true}/>
                                </div>

                            </div>
                            <div className="row mb-5">
                                <div className="col-md-6 fv-row">
                                    <label className="required fs-5 fw-bold mb-2">Email Address</label>
                                    <input id='category_code' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={Email} onChange={(e) => { setEmail(e.target.value) }} readOnly={write?false:true}/>
                                </div>
                                <div className="col-md-6 fv-row">
                                    <label className=" fs-5 fw-bold mb-2">Mobile</label>
                                    <input id='category_code' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={Mobile} onChange={(e) => { setMobile(e.target.value) }} readOnly={write?false:true}/>
                                </div>
                            </div>
                            {/*<div className="row mb-5">
                                <div className="col-md-12 fv-row">
                                    <label className="required fs-5 fw-bold mb-2">Role</label>
                                    <Select
                                        name="category"
                                        id="category"
                                        className="form-control form-control-solid"
                                        options={UserRoleList}
                                        onChange={(event) => { setRole(event.value) }}
                                        value={UserRoleList.filter(option => option.value == Role).length != 0 && UserRoleList.filter(option => option.value == Role)[0]}
                                        placeholder="--Choose a Role--"
                                    />
                                </div>
                            </div>*/}
                            <div className="row mb-5">
                                <div className="col-md-12 fv-row">
                                    <label className=" fs-5 fw-bold mb-2">Submits To</label>
                                    <Select
                                        name="category"
                                        id="category"
                                        className="form-control form-control-solid"
                                        options={userlist}
                                        onChange={(event) => { setSubmit_to(event.value) }}
                                        value={userlist.filter(option => option.value == Submit_to).length != 0 && userlist.filter(option => option.value == Submit_to)[0]}
                                        placeholder="--Select--"
                                    />
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-12 fv-row">
                                    <label className=" fs-5 fw-bold mb-2">Department Name</label>
                                    <Select
                                        name="category"
                                        id="category"
                                        className="form-control form-control-solid"
                                        options={DepartmentList}
                                        onChange={(event) => { setDepartment(event.value) }}
                                        value={DepartmentList.filter(option => option.value == Department).length != 0 && DepartmentList.filter(option => option.value == Department)[0]}
                                        placeholder="--Choose a Department--"
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Policy</label>
                                <Select
                                    name="category"
                                    id="category"
                                    className="form-control form-control-solid"
                                    options={PolicyList}
                                    onChange={(event) => { setPolicy(event.value) }}
                                    value={PolicyList.filter(option => option.value == Policy).length != 0 && PolicyList.filter(option => option.value == Policy)[0]}
                                    placeholder="--Select--"
                                />
                            </div><br/>
                            <div className="row mb-5">
                                <div className="col-md-6 fv-row">
                                    <label className=" fs-5 fw-bold mb-2">Date of birth</label>
                                    <input type="date" name="Dob_date" className="form-control" id="Dob_date" placeholder="Sales Start Date" value={Dob_date}
                                        onChange={(e) => {
                                            setDob_date(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-md-6 fv-row">
                                    <label className=" fs-5 fw-bold mb-2">Gender</label>
                                    <Select
                                        name="category"
                                        id="category"
                                        className="form-control form-control-solid"
                                        options={GenderList}
                                        onChange={(event) => { setGender(event.value) }}
                                        value={GenderList.filter(option => option.value == Gender).length != 0 && GenderList.filter(option => option.value == Gender)[0]}
                                        placeholder="--Select--"
                                    />
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-6 fv-row">
                                    <label className=" fs-5 fw-bold mb-2">Date of Joining</label>
                                    <input type="date" name="Joining_date" className="form-control" id="Joining_date" placeholder="Sales Start Date" value={Joining_date}
                                        onChange={(e) => {
                                            setJoining_date(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-md-6 fv-row">
                                    <label className=" fs-5 fw-bold mb-2">Designation</label>
                                    <Select
                                        name="category"
                                        id="category"
                                        className="form-control form-control-solid"
                                        options={UserRoleList}
                                        onChange={(event) => { setDesignation(event.value) }}
                                        value={UserRoleList.filter(option => option.value == Designation).length != 0 && UserRoleList.filter(option => option.value == Designation)[0]}
                                        placeholder="--Select--"
                                    />
                                </div>
                            </div>
                            <label class="form-check form-check-custom form-check-solid align-items-start">
                                <input class="form-check-input me-3" type="checkbox" name="email-preferences[]" value={Skip_mail} onChange={(e) => {
                                            setSkip_mail(e.target.checked);
                                        }}/>
                                <span class="form-check-label d-flex flex-column align-items-start">
                                    <span class="fw-bolder fs-5 mb-0">Skip invitation email</span>
                                    {Skip_mail &&<span class="text-muted fs-6">You can invite them later from Admin View - User Management.</span>}
                                </span>
                            </label>
                            </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1 &&<form >
                                {<button type="button" className="btn btn-success" onClick={(e) => { UpdateUser(e); }} ><span className="indicator-label" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}>Update</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>}
                            </form>}
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="deleteStatus" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Delete User</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">

                                <div className="col-lg-12">
                                    <h3>Are you sure about delete the user?</h3>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1 &&
                            <form >
                                <input type="hidden" id="id" name="activeId" />
                                <input type="hidden" id="st" name="st" />
                                {<button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}><span className="indicator-label">Delete</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>}
                            </form>}
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="modal fade" id="EditStatus" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Change Status</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">

                                <div className="col-lg-12">
                                    Current Status is : {(status == "Active"|| status == "ACTIVE") ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
                                    <h3>Are you sure about changing status?</h3>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            {write==1 &&<form >
                                <input type="hidden" id="id" name="activeId" />
                                <input type="hidden" id="st" name="st" />

                                {(status == "Active" || status == "ACTIVE") ? <button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}><span className="indicator-label">Deactivate</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button> 
                                : 
                                <button type="button" className="btn btn-success" onClick={(e) => { submitStatus(e); }} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}><span className="indicator-label">Activate</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>}
                            </form>}
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">
                    <div className="modal-content">
                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>
        </>);

};

export default ViewRoles;
