import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom'
import Dropzone from 'react-dropzone-uploader';
// import {Form, Col,Button,InputGroup} from 'react-bootstrap';
import { Formik } from "formik";
import * as yup from "yup";
import 'react-dropzone-uploader/dist/styles.css'
import Select from 'react-select';
import removeImg from './icons/remove.svg'
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import uuid from 'react-uuid';


const divStyle = {
    border: '1px solid #999',
    background: '#FCFBFB',
};

const textstyle = {
    paddingTop: '50px',
};

const dropzoneStyle = {
    paddingTop: '15px',
}

function ProductVideo(props) {
    let [user, setUser] = useState({});

    let [loader, setLoader] = useState(0);

    let [state, setstate] = useState(2);

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0); 
    let [subscription, set_subscription] = useState("");
    let [duration, setduration] = useState("");
    const [PlatformDetails, setPlatformDetails] = useState([]);

    // let [photoName, setPhotoName] = useState([""]);
    // let [idNames, setIdNames] = useState(["", "", ""]);
    // let [fileNames, setFileNames] = useState(["", "", "", ""]);
    const [unique_id, setUnique_id] = useState(uuid());
    const [hasproductvideo,sethasproductvideo] = useState(false)


    //const [organization_id, setOrganization_id] = useState("");
    const [productname,setproductname] = useState("");
    let [inputFields, setInputFields] = useState([]);
    // const [compressedformat, setcompressedformat] = useState("off")

    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);
    var page_name = "product-video";
    // setPhotoName = (name) => {
    //     photoName.push(name)
    //     let uniq = photoName => [...new Set(photoName)];
    //     photoName = photoName.slice(-1)
    // }
    // setIdNames = (name) => {
    //     idNames.push(name)
    //     let uniq = idNames => [...new Set(idNames)];
    //     idNames = idNames.slice(-3)
    // }
    // setFileNames = (photoName, idNames) => {
    //     fileNames = [...photoName, ...idNames]
    // }


    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });

                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;

                        if (res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        } else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            res.data.audience_count = (res.data.audience_count)
                            if (res.data.audience_count > 0) {
                                const timer_decrease = setInterval(() => {
                                    res.data.audience_count = (res.data.audience_count - 1);
                                    //console.log(res.data.audience_count);
                                    if (res.data.audience_count > 0 && res.data.audience_count < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                                        if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                                            setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                                            window.$('#kt_modal_timer').modal('show');
                                        }
                                    }
                                }, 1000);

                                return () => clearInterval(timer_decrease);
                            }
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }

        validateJWT();

    }, [])



    function loadScripts() {

        var elem = document.getElementById("create_account");
        if (!elem) {
            var se = document.createElement('script');
            se.src = "/assets/js/custom/modals/create-account.js";
            se.id = "create_account";
            document.body.appendChild(se);
        }

    }
    useEffect(() => {
    const getUserMetadata = async () => {
              
        async function getproductvideos(values) {
          const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/getproductvideos/${values}`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
              }
          );
          const body = await response.json();
        //   console.log(body)
          var i;
          if(body.length > 0)
            {
            
                sethasproductvideo(true)
                for(i=0;i<body.length;i++){
                    inputFields.push({ video_type: body[i].video_type,video_url:body[i].video_url, file_name: body[i].product_video_file_name, original_file_name:body[i].original_file_name});
                }
            }
            // else
            // {
            //     inputFields.push({ video_type: "youtube", video_url:"",file_name: "",original_file_name:"", show_thumbnail:false});
            // }
        }
        
        getproductvideos(props.match.params.pid)
  
       
  
        async function getproductname(values) {
                  //console.log(values)
              const response = await fetch(
              `${process.env.REACT_APP_SERVER_URL}/getproductname/${values}`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
                      }
                  );
                  const body = await response.json();
                    //   console.log(body)
                  if(body.length===0){
					setproductname("");
                    setstate(0)
				}
				else {
                    setstate(1)
                    setproductname(body[0].product_name);
				}
              }
  
              getproductname(props.match.params.pid);
              
            //   setaccessToken(id_token);
          };
          
        if(read || write)
        {
            (user.email && visibility) && getUserMetadata();
        }
    },[user, visibility, read, write]);
    const handleAddFields = () => {

        if(inputFields.length<20){
          const values = [...inputFields];
          values.push({ video_type: "youtube",video_url:"",file_name:"",original_file_name:"",show_thumbnail:false});
        //   console.log(values)
          setInputFields(values);
        // console.log(inputFields.length)
        }
        else{
            swal("Oops!", `You can add maximum ${inputFields.length} images of a product!`, "error");
        }
          
      };

      const handleRemoveFields = (index) => {
        const values = [...inputFields];
        values.splice(index, 1);
        setInputFields(values);
    };

    const handleInputChange = (index, name,event) => {
        // console.log(name)
          const values = [...inputFields];
          if (name === "video_type") {
              values[index].video_type = event.target.value;
          }
          else if(name === "video_url"){
              values[index].video_url = event.target.value;
          }
          
          else{
              values[index].original_file_name = name;
              values[index].file_name = unique_id.concat("_",name);
              values[index].show_thumbnail = false;
          }
          //console.log(values)
  
          setInputFields(values);
      };

    var files = [];
    
    const onSubmit = (values, { resetForm }) => {
        for (var i = 0; i < inputFields.length; i++){
            if(inputFields[i].video_type=='youtube' && inputFields[i].video_url==''){
                swal("Oops!", "Enter a URL for video "+(i+1), "error");
                return false;
            }
            else if(inputFields[i].video_type=='manual' && inputFields[i].file_name==''){
                swal("Oops!", "Select a file for video "+(i+1), "error");
                return false;
            }
        }
       
            values.user_id=user.email;
            values.unique_id=unique_id;
            values.pvideo=inputFields;
            values.pid = props.match.params.pid;
            values.page_name = page_name
            // console.log(values)

            var headers = {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "*/*",
                    "token": getCookie("access_token")
                }
            }

            axios.post(`${process.env.REACT_APP_SERVER_URL}/submit-product-videos`,values, headers).then((res) => {

                if (res.data.operation === "success") {
                    swal("Great!", "Videos added successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else if(res.data.operation === "filenotuploaded"){
                    swal("Oops!", "You have not uploaded any videos!", "error");
                }

                else{
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
        
        // e.preventDefault();


    }

    const onUpdate = (values, { resetForm }) => {
        for (var i = 0; i < inputFields.length; i++){
            if(inputFields[i].video_type=='youtube' && inputFields[i].video_url==''){
                swal("Oops!", "Enter a URL for video "+(i+1), "error");
                return false;
            }
            else if(inputFields[i].video_type=='manual' && inputFields[i].file_name==''){
                swal("Oops!", "Select a file for video "+(i+1), "error");
                return false;
            }
        }
       
            values.user_id=user.email;
            values.unique_id=unique_id;
            values.pvideo=inputFields;
            values.pid = props.match.params.pid;
            values.page_name = page_name 
            // console.log(values)

            var headers = {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "*/*",
                    "token": getCookie("access_token")
                }
            }

            axios.post(`${process.env.REACT_APP_SERVER_URL}/update-product-video`,values, headers).then((res) => {

                if (res.data.operation === "success") {
                    swal("Great!", "Videos updated successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else{
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
      
        //e.preventDefault();


    }
    


    const schema = yup.object({
        //order: yup.number("Only numbers allowed").required('required'),

    });

  
    const Preview = ({ meta }) => {
        const { name, percent, status, previewUrl } = meta;
        // console.log(name)
        return (
            
            <div className="preview-box">
                <img src={previewUrl} /> 
                <span className="name">{name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>{status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span>}
                {/* {status === "done" && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />} */}
            </div>
        )
    }

    const getUploadParams = ({ file }) => {
        const body = new FormData();
        body.append('dataFiles', file);
        body.append('user_id',user.email)
        body.append('unique_id',unique_id)
        body.append('page_name',page_name)
        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        return { url: `${process.env.REACT_APP_SERVER_URL}/uploadproductvideos`,headers, body }
    }
 

    // handle the status of the file upload
    const handleChangeStatus = ({ xhr }) => {
        if (xhr) {
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    const result = JSON.parse(xhr.response);
                    console.log(result);
                }
            }
        }
    }
    // console.log(inputFields)
    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }

            {state==1 ?
                <>

                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">

                                <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                                    <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Product Videos
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                        <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                    </h1>

                                </div>

                            </div>
                        </div>

                        <div class="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" class="container">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="stepper stepper-links d-flex flex-column" id="kt_create_account_stepper">
                                            <div className="kt-portlet__head">
                                                <div className="kt-portlet__head-label">
                                                    <h3 className="kt-portlet__head-title">
                                                        {productname}
                                                    </h3>
                                                </div>
                                            </div>
                                            <Formik
                                                validationSchema={schema}
                                                onSubmit={hasproductvideo ? onUpdate : onSubmit}
                                                initialValues={{
                                                    video_type: "",	
                                                    video_url:"",							
                                                }} >
                                                {({
                                                    handleSubmit,
                                                    handleChange,
                                                    handleBlur,
                                                    values,
                                                    touched,
                                                    isValid,
                                                    errors,
                                                }) => (
                                                <form className="kt-form kt-form--label-right" id="kt_form" onSubmit={(e) => {
                                                    e.preventDefault();
                                                    handleSubmit();
                                                    }} noValidate id = "wizard_form">
{/*                                                     
                                                    <input type="hidden" id="app_id" name="app_id" value={app_id} />
                                                    <input type="hidden" id="user_id" name="user_id" value={user_id} />
                                                    <input type="hidden" id="unique_id" name="unique_id" value={unique_id} /> */}
                                                                
                                                                <div className="kt-portlet__body">
                                                                <div className="row">
                                                                    <div className="col-xl-12 p-5" style={divStyle}>

                                                                        {inputFields.map((inputField, index) => (
                                                                            <React.Fragment key={`${inputField}~${index}`}>
                                                                            { inputField.show_thumbnail ? null :
                                                                            <>
                                                                                <h5 className="mt-2">Product Video:{index+1}</h5>
                                                                                <div className="form-group row">
                                                                                    <div className="col-lg-5" >
                                                                                        {/* <Form.Group> */}
                                                                                            <label>Video Type:</label>
                                                                                            <select type="select" name="video_type" className={`form-control ${touched.video_type && errors.video_type ? "is-invalid" : ""}`} id="video_type" 
                                                                                            value={inputField.video_type}
                                                                                            onChange={(event)=>{handleChange(event);
                                                                                                handleInputChange(index,event.target.name,event);
                                                                                            }}
                                                                                            >
                                                                                                <option value="youtube">Youtube</option>
                                                                                                <option value="manual">Manual upload</option>
                                                                                                
                                                                                            </select>
                                                                                            {inputField.file_name ? 
                                                                                                <div className="fw-bolder" style={dropzoneStyle}>
                                                                                                {hasproductvideo ? inputField.original_file_name : null}
                                                                                                </div>
                                                                                            :
                                                                                            null
                                                                                            }
                                                                                        {/* </Form.Group> */}
                                                                                    </div>
                                                                                    {inputField.video_type!="manual" ? 

                                                                                    <div className="col-lg-5" >
                                                                                        {/* <Form.Group> */}
                                                                                            <label>Video URL:</label>			
                                                                                            <input type="text" name="video_url" className={`form-control ${touched.video_url && errors.video_url ? "is-invalid" : ""}`} id="video_url" placeholder="Enter video URL" value={inputField.video_url}
                                                                                onChange={(event) => {handleInputChange(index, event.target.name,event);handleChange(event);}}
                                                                                />
                                                                                            {/* <input.Feedback type="invalid" tooltip>{errors.video_url}</input.Feedback> */}
                                                                                        {/* </Form.Group> */}
                                                                                    </div>

                                                                                    :
                                                                                    <>
                                                                                    {inputField.show_thumbnail ===false ? 
                                                                                    <>
                                                                                    <div className="col-xl-5" style={dropzoneStyle}>
                                                                                        {/* <Form.Group> */}
                                                                                            <div>
                                                                                                <Dropzone
                                                                                                    getUploadParams={getUploadParams}
                                                                                                    onChangeStatus={(event)=>{handleChangeStatus(event);
                                                                                                        handleInputChange(index, event.file.name,event);
                                                                                                    }}
                                                                                                    maxFiles={1}
                                                                                                    multiple={false}
                                                                                                    styles={{
                                                                                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                                                                    inputLabelWithFiles: { margin: '20px 3%' }
                                                                                                    }}
                                                                                                    PreviewComponent={Preview}
                                                                                                    accept="video/*"
                                                                                                />
                                                                                            </div>
                                                                                        {/* </Form.Group> */}
                                                                                    </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                    <div className="col-xl-5" style={dropzoneStyle}>
                                                                                        {/* <Form.Group> */}
                                                                                            <div>
                                                                                                <Dropzone
                                                                                                    getUploadParams={getUploadParams}
                                                                                                    onChangeStatus={(event)=>{handleChangeStatus(event);
                                                                                                        handleInputChange(index, event.file.name,event);
                                                                                                    }}
                                                                                                    maxFiles={1}
                                                                                                    multiple={false}
                                                                                                    styles={{
                                                                                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                                                                    inputLabelWithFiles: { margin: '20px 3%' }
                                                                                                    }}
                                                                                                    PreviewComponent={Preview}
                                                                                                  accept="video/*"
                                                                                                />
                                                                                            </div>
                                                                                            
                                                                                        {/* </Form.Group> */}
                                                                                    </div>
                                                                                    
                                                                                    </>
                                                                                    }
                                                                                    </>
                                                                                    }
                                                                                    <div className="col-lg-1" style={dropzoneStyle}>
                                                                                        <button
                                                                                            className="btn font-weight-bold btn-danger btn-icon"
                                                                                            type="button"
                                                                                            onClick={() => handleRemoveFields(index)}
                                                                                            >
                                                                                            <i className="la la-remove"></i>
                                                                                        </button> 
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            }
                                                                            </React.Fragment>
                                                                        
                                                                        
                                                                        ))}
                                                                    </div>
                                                                    
                                                                    <div className="form-group row" style={dropzoneStyle}>
                                                                        <div>
                                                                            <button type="button" className="btn font-weight-bold btn-primary" onClick={() => handleAddFields()}>
                                                                            {/* <button type="button" className="btn font-weight-bold btn-primary" > */}
                                                                                <i className="la la-plus"></i>
                                                                                Add
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    
                                                                    
                                                                </div>
                                                                </div>
                                                                
                                                                <div className="kt-portlet__foot">
                                                                                <div className="kt-form__actions">
                                                                                    <div className="row" align="right">
                                                                                        <div className="col-lg-4"></div>
                                                                                        <div className="col-lg-8">
                                                                                            <button type="submit" className="btn btn-primary">{hasproductvideo?"Update":"Submit"}</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                </div>

                                                </form>
                                                )}
                                                </Formik>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </>
                :
                <>
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                                    <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Product Videos
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                        <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                    </h1>

                                </div>

                            </div>
                        </div>

                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div className="container" id="kt_content_container">
                                <div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
                                    <br />

                                    {state == 2 ?
                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        :
                                        <>

                                            <div className="flex-lg-row-fluid me-lg-15">
                                                <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                                    <div className="card-header">
                                                        <div className="card-title">
                                                            <h2 className="fw-bolder">Product Videos</h2>
                                                        </div>
                                                    </div>


                                                    <div className="card-body pt-0">
                                                        <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                        <div className="d-flex align-items-center p-3 mb-2">


                                                        </div>


                                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                            <div className="d-flex flex-stack flex-grow-1">
                                                                <div className="fw-bold">
                                                                    <h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
                                                                    <div className="fs-6 text-gray-600">
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </>
                                    }


                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            }


            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>


        </>
    );
}
export default ProductVideo;