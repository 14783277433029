import React, {useState, useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import Select, {components} from 'react-select';
import removeImg from './icons/remove.svg'
import uuid from 'react-uuid';
import country_arr from './countries-arr.js';
import s_a from './states-arr.js';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import currency_arr from './currency-arr.js' ;
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import MUIDataTable from "mui-datatables";
import { Workbook } from 'exceljs';

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

// import ReactDOM from 'react-dom';
// import $ from 'jquery';
// import moment from 'moment';
// import { number } from 'yup';

function Customer(props) {
    const [user, setUser] = useState({});
    const [organization_id, setOrganization_id] = useState("")
    const [organization_list, setorganization_list] = useState([]);
    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);
    const [state, setstate] = useState(2);

    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
	const [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);

    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [currencyList, setCurrencyList] = useState([])
    const [termsList, setTermsList] = useState([])
    const [organizationDetails, setOrganizationDetails] = useState(null)

    const [customerType, setCustomerType] = useState("business")
    const [primaryContact, setPrimaryContact] = useState({salutation: null, firstName: "", lastName: ""})
    const [companyName, setCompanyName] = useState("")
    const [customerDisplayNameOptions, setCustomerDisplayNameOptions] = useState([])
    const [selectedCustomerDisplayName, setSelectedCustomerDisplayName] = useState(null)
    const [workPhone, setWorkPhone] = useState("")
    const [mobile, setMobile] = useState("")
    const [designation, setDesignation] = useState("")
    const [department, setDepartment] = useState("")
    const [email, setEmail] = useState("")
    const [website, setWebsite] = useState("")
    const [selectedLeadSource, setSelectedLeadSource] = useState(null)

    const [currentPanel, setCurrentPanel] = useState("otherDetails")

    const [selectedIdentityType, setSelectedIdentityType] = useState(null)
    const [identityNumber, setIdentityNumber] = useState("")
    const [pan, setPan] = useState("")
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const [selectedTerm, setSelectedTerm] = useState(null)
    const [portalCheck, setPortalCheck] = useState(false)
    const [selectedPortalLanguage, setSelectedPortalLanguage] = useState(null)
    const [facebookLink, setFacebookLink] = useState("")
    const [twitterLink, setTwitterLink] = useState("")

    const [billingAttention, setBillingAttention] = useState("")
    const [billingCountryOptions, setBillingCountryOptions] = useState([]);
    const [billingStateOptions, setBillingStateOptions] = useState([]);
    const [billingSelectedCountry, setBillingSelectedCountry] = useState(null)
    const [billingSelectedState, setBillingSelectedState] = useState(null)
    const [billingCity, setBillingCity] = useState("")
    const [billingPincode, setBillingPincode] = useState("")
    const [billingAddress1, setBillingAddress1] = useState("")
    const [billingAddress2, setBillingAddress2] = useState("")
    const [billingPhone, setBillingPhone] = useState("")
    const [billingFax, setBillingFax] = useState("")
    const [billingDeliveryInstruction, setBillingDeliveryInstruction] = useState("")

    const [shippingAttention, setShippingAttention] = useState("")
    const [shippingCountryOptions, setShippingCountryOptions] = useState([]);
    const [shippingStateOptions, setShippingStateOptions] = useState([]);
    const [shippingSelectedCountry, setShippingSelectedCountry] = useState(null)
    const [shippingSelectedState, setShippingSelectedState] = useState(null)
    const [shippingCity, setShippingCity] = useState("")
    const [shippingPincode, setShippingPincode] = useState("")
    const [shippingAddress1, setShippingAddress1] = useState("")
    const [shippingAddress2, setShippingAddress2] = useState("")
    const [shippingPhone, setShippingPhone] = useState("")
    const [shippingFax, setShippingFax] = useState("")
    const [shippingDeliveryInstruction, setShippingDeliveryInstruction] = useState("")

    const [profilePhoto, setProfilePhoto] = useState("");
    const [fileNames, setFileNames] = useState([]);
    const [fileSessionUniqueId, setFileSessionUniqueId] = useState(uuid());


    const [contactPersonsArray, setContactPersonsArray] = useState([{salutation:null, firstName:"", lastName:"", email:"", workPhone:"", mobile:"", designation:"", department:""}])

    const [remarks, setRemarks] = useState("")

    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

    const [termsModalShow, setTermsModalShow] = useState(false)
    const [termsPanelList, setTermsPanelList] = useState([])
    const [termsSubmitButtonState, setTermsSubmitButtonState] = useState(false)

    const [currencyModalShow, setCurrencyModalShow] = useState(false)
    const [selectedCurrencyCode, setSelectedCurrencyCode] = useState(null)
    const [currencySymbol, setCurrencySymbol] = useState("")
    const [selectedCurrencyDecimalPlace, setSelectedCurrencyDecimalPlace] = useState(null)
    const [selectedCurrencyFormat, setSelectedCurrencyFormat] = useState(null)
    const [currencySubmitButtonState, setCurrencySubmitButtonState] = useState(false)

    const [bulkDataModalShow, setBulkDataModalShow] = useState(false)
    const [bulkDataFile, setBulkDataFile] = useState(null)
    const [bulkDataExtracted, setBulkDataExtracted] = useState([])
    const [bulkDataVerifyButtonState, setBulkDataVerifyButtonState] = useState(false)
    const [bulkDataVerified, setBulkDataVerified] = useState(false)
    const [bulkDataSubmitButtonState, setBulkDataSubmitButtonState ] = useState(false)

    const [bulkErrorModalShow, setBulkErrorModalShow] = useState(false)

    const [errInd, seterrInd] = useState([])
    const [errM, seterrMsg] = useState([])

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

	var page_name = "add-customer";

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        filter: false,
        selectableRows: false,
        search: false,
        searchPlaceholder: 'Search Customer',
        download: false,
        print: false,
        setRowProps: (data, dataIndex, rowIndex) => {
            if (errInd.includes(rowIndex)) {
                return {
                    style: { background: "#EF5354" }
                };
            }
            else {
                return {
                    style: { background: "rgba(136, 232, 90, 1)" }
                };
            }
        }
    };

    const columns = [
        { name: "Customer Type", label: "Customer Type"},
        { name: "First Name", label: "First Name"},
        { name: "Last Name", label: "Last Name"},
        { name: "Company Name", label: "Company Name"},
        { name: "Work Phone", label: "Work Phone"},
        { name: "Mobile", label: "Mobile"},
        { name: "Designation", label: "Designation"},
        { name: "Department", label: "Department"},
        { name: "Email", label: "Email"},
        { name: "Website", label: "Website"},
        { name: "Lead Source", label: "Lead Source"},
        { name: "Identity Type", label: "Identity Type"},
        { name: "Identity Number", label: "Identity Number"},
        { name: "PAN", label: "PAN"},
        { name: "Currency", label: "Currency"},
        { name: "Terms", label: "Terms"},
        { name: "Enable Portal", label: "Enable Portal"},
        { name: "Portal Language", label: "Portal Language"},
        { name: "Facebook Link", label: "Facebook Link"},
        { name: "Twitter Link", label: "Twitter Link"},
        { name: "Billing Attention", label: "Billing Attention"},
        { name: "Billing Country", label: "Billing Country"},
        { name: "Billing State", label: "Billing State"},
        { name: "Billing City", label: "Billing City"},
        { name: "Billing Pincode", label: "Billing Pincode"},
        { name: "Billing Address1", label: "Billing Address1"},
        { name: "Billing Address2", label: "Billing Address2"},
        { name: "Billing Phone", label: "Billing Phone"},
        { name: "Billing Fax", label: "Billing Fax"},
        { name: "Billing Delivery Instruction", label: "Billing Delivery Instruction"},
        { name: "Shipping Attention", label: "Shipping Attention"},
        { name: "Shipping Country", label: "Shipping Country"},
        { name: "Shipping State", label: "Shipping State"},
        { name: "Shipping City", label: "Shipping City"},
        { name: "Shipping Pincode", label: "Shipping Pincode"},
        { name: "Shipping Address1", label: "Shipping Address1"},
        { name: "Shipping Address2", label: "Shipping Address2"},
        { name: "Shipping Phone", label: "Shipping Phone"},
        { name: "Shipping Fax", label: "Shipping Fax"},
        { name: "Shipping Delivery Instruction", label: "Shipping Delivery Instruction"},
    ]


    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true);
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    setRedirect_to_login(1);
                });
                
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if(temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else
                                    {
                                        temp = 2;
                                    }
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
							
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setRedirect_to_login(1);
                });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }

                // console.log(timer)
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);





    async function getCurrencies() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCurrencyList(body)
        }
    }
    async function getTerms() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-invoice-terms`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setTermsList(body)
        }
    }
    async function getOrganizationDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/show-organization`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setOrganizationDetails(body.find(x=>x.organization_id == getCookie("organization_id")))
        }
    }
    useEffect(() => {
		if ((read || write) && (user.email && visibility)) {
			let p1 = getCurrencies();
            let p2 = getTerms();
            let p3 = getOrganizationDetails();
            Promise.all([p1,p2,p3])
            .then(()=>{
                setstate(1)
                console.log("all apis done")
            })
            .catch((err)=>{
                setstate(3)
                console.log(err)
            })
		}

	}, [user, visibility, read, write])

    useEffect(() => {
        if((organizationDetails!=null)&&(currencyList.length>0)){
            setSelectedCurrency({label: currencyList.find(x=>x.currency_id==organizationDetails.currency).currency_code,value:organizationDetails.currency})
        }
    }, [organizationDetails,currencyList])
    

    useEffect(() => {
        setSelectedCustomerDisplayName(null)
        let tempArr = []
        if(primaryContact.salutation!=null){
            if(primaryContact.firstName.trim()!=""){
                if(primaryContact.lastName.trim()!=""){
                    tempArr.push(primaryContact.salutation.value+" "+primaryContact.firstName+" "+primaryContact.lastName, primaryContact.firstName+" "+primaryContact.lastName, primaryContact.lastName+", "+primaryContact.firstName)
                }
                else{
                    tempArr.push(primaryContact.salutation.value+" "+primaryContact.firstName, primaryContact.firstName)
                }
            }
            else{
                if(primaryContact.lastName.trim()!=""){
                    tempArr.push(primaryContact.salutation.value+" "+primaryContact.lastName, primaryContact.lastName)
                }
            }
        }
        else{
            if(primaryContact.firstName.trim()!=""){
                if(primaryContact.lastName.trim()!=""){
                    tempArr.push(primaryContact.firstName+" "+primaryContact.lastName, primaryContact.lastName+", "+primaryContact.firstName)
                }
                else{
                    tempArr.push(primaryContact.firstName)
                }
            }
            else{
                if(primaryContact.lastName.trim()!=""){
                    tempArr.push(primaryContact.lastName)
                }
            }
        }
        setCustomerDisplayNameOptions(tempArr)
    }, [primaryContact])
    
	
	useEffect(() => {
		let obj_array = []
		country_arr.country_arr.forEach((obj, index) => {
            if(index!=0){
                let obj2 = {};
                obj2.label = obj;
                obj2.value = obj;

                obj_array.push(obj2);
            }
		})
		setBillingCountryOptions(obj_array);
		setShippingCountryOptions(obj_array);
	}, []);
	
	function loadBillingStateOptions(country) {
		let selectedCountryIndex = 0;
		billingCountryOptions.map((obj, index) => {
			if (obj.value == country.value) {
				selectedCountryIndex = index;
			}
		})
        console.log(selectedCountryIndex)

		let state_arr = s_a.s_a[selectedCountryIndex+1].split("|");
		let obj_array = [];
		for (let i = 0; i < state_arr.length; i++) {
			obj_array.push({ value: state_arr[i], label: state_arr[i] });
		}
		setBillingStateOptions(obj_array);
	}

    function loadShippingStateOptions(country) {
		let selectedCountryIndex = 0;
		shippingCountryOptions.map((obj, index) => {
			if (obj.value == country.value) {
				selectedCountryIndex = index;
			}
		})
        console.log(selectedCountryIndex)

		let state_arr = s_a.s_a[selectedCountryIndex+1].split("|");
		let obj_array = [];
		for (let i = 0; i < state_arr.length; i++) {
			obj_array.push({ value: state_arr[i], label: state_arr[i] });
		}
		setShippingStateOptions(obj_array);
	}

    function copyAddress(){
        setShippingAttention(billingAttention)
        setShippingCountryOptions(billingCountryOptions)
        setShippingStateOptions(billingStateOptions)
        setShippingSelectedCountry(billingSelectedCountry)
        setShippingSelectedState(billingSelectedState)
        setShippingCity(billingCity)
        setShippingPincode(billingPincode)
        setShippingAddress1(billingAddress1)
        setShippingAddress2(billingAddress2)
        setShippingPhone(billingPhone)
        setShippingFax(billingFax)
        setShippingDeliveryInstruction(billingDeliveryInstruction)
    }

    const register = () => {
    
        var nexp = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~0-9]/;
        if ((primaryContact.firstName == "") && (primaryContact.lastName == "")) {
            swal("Warning!", "Primary Contact must contain first name or last name", "warning");
            return
        }
        
        if(nexp.test(primaryContact.firstName)){
            swal("Warning!", "First name cannot contain a number or symbols", "warning");
            return
        }
        if(nexp.test(primaryContact.lastName)){
            swal("Warning!", "Last name cannot contain a number or symbols", "warning");
            return
        }

        if(selectedCustomerDisplayName == null){
            swal("Warning!", "Customer Display Name cannot be empty", "warning");
            return
        }

        if(workPhone == ""){
            swal("Warning!", "Work phone cannot be empty", "warning");
            return
        }
        let regex1 = /^(\+\d{1,3}[- ]?)?\d{10}$/
        if(!regex1.test(workPhone)){
            swal("Warning!", "Please provide valid Work phone number", "warning");
            return
        }


        if((portalCheck) && (email == "")) {
            swal("Warning!", "Email id cannot be empty", "warning");
            return false;
        }
        let regex2 = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if((email!="") && (!regex2.test(email))) {
            swal("Warning!", "Please enter a valid Email id", "warning");
            return false;
        }

        if(selectedLeadSource == null){
            swal("Warning!", "Lead Source cannot be empty", "warning");
            return
        }

        if(selectedIdentityType == null){
            swal("Warning!", "Identity type cannot be empty", "warning");
            return
        }
        if(identityNumber == '')
		{
            swal("Warning!", "Identity Number cannot be empty", "warning");
            return
        }
        let regex3 = /^[0-9]{4}?[0-9]{4}?[0-9]{4}$/;
        if ((selectedIdentityType.value == 'aadhar_card') && (!regex3.test(identityNumber))) {
            swal("Warning!", "Please enter a valid Aadhar number without any spaces", "warning");
            return
        }

        let regex4 = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        if (!regex4.test(pan)) {
            swal("Warning!", "Please enter a valid Pan number", "warning");
            return
        }

        if(selectedCurrency == null){
            swal("Warning!", "Currency cannot be empty", "warning");
            return
        }

        let object = {};
        
        object.customer_type = customerType
        object.primary_contact = {...primaryContact,salutation: primaryContact.salutation==null?null:primaryContact.salutation.value}
        object.company_name = companyName
        object.customer_display_name = selectedCustomerDisplayName.value
        object.work_phone = workPhone
        object.mobile = mobile
        object.designation = designation
        object.department = department
        object.email = email
        object.website = website
        object.lead_source = selectedLeadSource.value

        object.identity_type = selectedIdentityType.value
        object.identity_number = identityNumber
        object.pan = pan
        object.currency = {currency_code: selectedCurrency.label, currency_id: selectedCurrency.value}
        object.term = (selectedTerm==null)?undefined:{term_name:selectedTerm.label, no_of_days:selectedTerm.value}
        object.portal_check = portalCheck?"yes":"no"
        object.portal_language = (selectedPortalLanguage==null)?null:selectedPortalLanguage.value
        object.facebook_link = facebookLink
        object.twitter_link = twitterLink

        object.billing_attention = billingAttention
        object.billing_country = (billingSelectedCountry==null)?null:billingSelectedCountry.value
        object.billing_state = (billingSelectedState==null)?null:billingSelectedState.value
        object.billing_city = billingCity
        object.billing_pincode = billingPincode
        object.billing_address1 = billingAddress1
        object.billing_address2 = billingAddress2
        object.billing_phone = billingPhone
        object.billing_fax = billingFax
        object.billing_delivery_instruction = billingDeliveryInstruction

        object.shipping_attention = shippingAttention
        object.shipping_country = (shippingSelectedCountry==null)?null:shippingSelectedCountry.value
        object.shipping_state = (shippingSelectedState==null)?null:shippingSelectedState.value
        object.shipping_city = shippingCity
        object.shipping_pincode = shippingPincode
        object.shipping_address1 = shippingAddress1
        object.shipping_address2 = shippingAddress2
        object.shipping_phone = shippingPhone
        object.shipping_fax = shippingFax
        object.shipping_delivery_instruction = shippingDeliveryInstruction

        object.profile_photo = profilePhoto
        object.file_names = fileNames
        object.unique_id = fileSessionUniqueId

        let temp = contactPersonsArray.filter((obj)=>{
            if (!((obj.salutation == null) && (obj.firstName == "") && (obj.lastName == "") && (obj.email == "") && (obj.workPhone == "") && (obj.mobile == "") && (obj.designation == "") && (obj.department == "")))
                return true
        })
        temp = temp.map((obj)=>{
            if(obj.salutation != null){
                obj.salutation = obj.salutation.value
            }
            return(obj)
        })
        object.contact_persons_array = temp
        object.remarks = remarks

        object.organization_id = organization_id;
        object.page_name=page_name;

        console.log(object);
        setMainSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/customers`, object, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data.operation === "same_email") {
                swal("Oops!", "Email already exists!!", "error");
            }
            else if (res.data.operation === "success") {
                swal("Great!", "You have registered the Customer successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const newTermsSubmit = () => {
        let f1 = true
        termsPanelList.forEach(obj=>{
            if((obj.term_name.trim() == "") || (obj.no_of_days=="")){
                f1=false
            }
        })
        if(f1 == false){
            swal("Oops!", "Term Name or No of Days cannot be empty", "warning");
            return
        }

        let temp = termsPanelList.map(obj=>{ return obj.term_name.trim() })
        if(temp.length!= [... new Set(temp)].length){
            swal("Oops!", "Term Name cannot be a duplicate", "warning");
            return
        }

        var object = {};

        object.terms_panel_list = termsPanelList

        object.organization_id = organization_id;
        object.page_name = page_name;

        // console.log(object);
        setTermsSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-invoice-terms`, object, headers).then((res) => {
            setTermsSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Sales Person added successfully!", "success")
                handleNewTermsModalClose()
                getTerms()
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const handleNewTermsModalClose = () => {
        setTermsModalShow(false)
        setTermsPanelList([]) 
    }

    const newCurrencySubmit = () => {

        if(selectedCurrencyCode == null){
            swal("Oops!", "Currency Code cannot be empty", "error");
            return
        }
        else if(currencySymbol == ""){
            swal("Oops!", "Currency Symbol cannot be empty", "error");
            return
        }

        var object = {};

        object.currency_code = selectedCurrencyCode.value
        object.currency_symbol = currencySymbol
		object.decimal_place = selectedCurrencyDecimalPlace==null?null:selectedCurrencyDecimalPlace.value
		object.format = selectedCurrencyFormat==null?null:selectedCurrencyFormat.value

        object.organization_id = organization_id;
        object.page_name = page_name;

        // console.log(object);
        setCurrencySubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-currency`, object, headers).then((res) => {
            setCurrencySubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Currency added successfully!", "success")
                handleNewCurrencyModalClose()
                getCurrencies()
            }
            else if(res.data.operation === "same_currency_code"){
                swal("Oops!", "This Currency code is already used!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const handleNewCurrencyModalClose = () => {
        setCurrencyModalShow(false)
        setSelectedCurrencyCode(null) 
        setCurrencySymbol("")
        setSelectedCurrencyDecimalPlace(null)
        setSelectedCurrencyFormat(null)
    }

    const CustomLayout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
        const previewsToDisplay = previews.filter((preview) => {
            return preview.props.meta.status !== 'error_validation';
        });

        return (
            <div {...dropzoneProps}>
                {previewsToDisplay}
                {files.length < maxFiles && input}
            </div>
        );
    };

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        return (
            <div className="preview-box">
                <img src={previewUrl} />
                <span className="name">{name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>
                {status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" style={{ backgroundImage: `url(${removeImg})` }} onClick={remove} />}
                {status === "done" && <span className="dzu-previewButton remove" style={{ backgroundImage: `url(${removeImg})` }} onClick={remove} />}
            </div>
        )
    }

    const getUploadParams1 = ({ file }) => {

        const body = new FormData();
        body.append('dataFiles', file);
        body.append('unique_id', fileSessionUniqueId)
        body.append('user_id', user.email);
        body.append('page_name', page_name);

        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        
        setMainSubmitButtonState(true)
        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-customer-profile`, headers, body }
    }

    const handleChangeStatus1 = ({ meta }, status, allFiles) => {
        const { name } = meta;
        
        if (status === 'done') {
            setMainSubmitButtonState(false)
            setProfilePhoto(name)
        }
        else if (status === 'removed') {
            setMainSubmitButtonState(false)
            setProfilePhoto("")
        }
        else if(status === "error_file_size")
        {
            allFiles[0].remove()
            swal("Oops!", "File size exceeded", "warning");
        }
    };

    const getUploadParams2 = ({ file }) => {

        const body = new FormData();
        body.append('dataFiles', file);
        body.append('unique_id', fileSessionUniqueId)
        body.append('user_id', user.email);
        body.append('page_name', page_name);

        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        
        setMainSubmitButtonState(true)
        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-customer-documents`, headers, body }
    }

    const handleChangeStatus2 = ({ meta }, status, allFiles) => {
        const { name } = meta;
        
        if (status === 'done') {
            setMainSubmitButtonState(false)
            setFileNames([...fileNames, name])
        }
        else if (status === 'removed') {
            setMainSubmitButtonState(false)
            let temp = [...fileNames] 
            temp.splice(temp.indexOf(name), 1)
            setFileNames(temp)
        }
        else if(status === "error_file_size")
        {
            allFiles[0].remove()
            swal("Oops!", "File size exceeded", "warning");
        }
    };

    const genTemplate = async () => {

        function numberToColumn(n) {
            const res = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[n % 26];
            return n >= 26 ? numberToColumn(Math.floor(n / 26) - 1) + res : res;
        }

        let headings = ["Customer Type", "First Name", "Last Name", "Company Name", "Work Phone", "Mobile", "Designation", "Department", "Email", "Website", "Lead Source", "Identity Type", "Identity Number", "PAN", "Currency", "Terms", "Enable Portal", "Portal Language", "Facebook Link", "Twitter Link", 
                        "Billing Attention","Billing Country","Billing State","Billing City","Billing Pincode","Billing Address1","Billing Address2","Billing Phone","Billing Fax","Billing Delivery Instruction", 
                        "Shipping Attention","Shipping Country","Shipping State","Shipping City","Shipping Pincode","Shipping Address1","Shipping Address2","Shipping Phone","Shipping Fax","Shipping Delivery Instruction"]

        let customerTypeL = ["business","individual"]
        let leadSourceL = ["CRM","Websites","Social","Referral","Cold Call","SEO"]
        let identityTypeL = ["aadhar_card","voter_card"]
        let currencyL = currencyList.map(x=>x.currency_code)
        let termsL = termsList.map(x=>x.term_name)
        let enablePortalL = ["on","off"]
        let portalLanguageL = ["Arabic", "Catalan", "Chinese (Simplified)", "Croatian", "Danish", "Dutch", "English", "Finnish", "French", "German", "Greek", "Indonesian", "Italian", "Japanese", "Norwegian", "Polish", "Portuguese", "Portuguese (Brazilian)", "Russian", "Slovak", "Spanish", "Spanish (Argentine)", "Swedish"]
        let countryL = [...country_arr.country_arr]
        countryL.shift()
        let stateL = [...s_a.s_a]
        stateL.shift()

        //Create workbook and worksheet
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet("Sheet1");
        let dynasheet = workbook.addWorksheet("dynadata", { state: 'veryHidden' });
        // let dynasheet = workbook.addWorksheet("dynadata");
        dynasheet.getColumn(1).values = customerTypeL   //A
        dynasheet.getColumn(2).values = leadSourceL     //B
        dynasheet.getColumn(3).values = identityTypeL   //C
        dynasheet.getColumn(4).values = currencyL       //D
        dynasheet.getColumn(5).values = termsL          //E
        dynasheet.getColumn(6).values = enablePortalL   //F
        dynasheet.getColumn(7).values = portalLanguageL //G
        dynasheet.getColumn(8).values = countryL        //H
        // for(let i=0;i<stateL.length;i++){
        //     dynasheet.getColumn(9 + i).values = [countryL[i], ...stateL[i].split("|")]
        // }
        
        // for(let i=0;i<1;i++){
        //     dynasheet.getCell(numberToColumn(8+stateL.length + i)+1).value = {formula: 'UNIQUE(XLOOKUP(Sheet1!V'+(i+2)+',dynadata!I1:IZ1,dynadata!I2:IZ151,"No Country Selected"),,TRUE)'}
        // }
        // for(let i=0;i<1;i++){
        //     row1.getCell(9+stateL.length+1 + i).value = {formula: '=UNIQUE(XLOOKUP(Sheet1!AG'+(i+2)+',dynadata!J1:JA1,dynadata!J2:JA151),,TRUE)'}
        // }
        await dynasheet.protect('dynadata')

        //Add Header Row
        let headerRow = worksheet.addRow(headings);
        worksheet.columns = [
            { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 },
            { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 },
            { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 },
            { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 25 }, { width: 20 }, { width: 20 },
            { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 25 }
        ];

        // Cell Style : Fill and Border
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '34495e' },
                bgColor: { argb: '34495e' }
            }
            cell.font = { color: { argb: "ecf0f1" } };
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
        })

        for (let i = 0; i < 1; i++) {
            worksheet.getCell('A' + (i+2)).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['=dynadata!$A$1:$A$'+customerTypeL.length],
                showErrorMessage: true,
                errorStyle: 'error',
                errorTitle: 'Error',
                error: 'Value must be in the list'
            };
            worksheet.getCell('K' + (i+2)).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['=dynadata!$B$1:$B$'+leadSourceL.length],
                showErrorMessage: true,
                errorStyle: 'error',
                errorTitle: 'Error',
                error: 'Value must be in the list'
            };
            worksheet.getCell('L' + (i+2)).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['=dynadata!$C$1:$C$'+identityTypeL.length],
                showErrorMessage: true,
                errorStyle: 'error',
                errorTitle: 'Error',
                error: 'Value must be in the list'
            };
            worksheet.getCell('O' + (i+2)).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['=dynadata!$D$1:$D$' + currencyL.length],
                showErrorMessage: true,
                errorStyle: 'error',
                errorTitle: 'Error',
                error: 'Value must be in the list'
            };
            worksheet.getCell('P' + (i+2)).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['=dynadata!$E$1:$E$' + termsL.length],
                showErrorMessage: true,
                errorStyle: 'error',
                errorTitle: 'Error',
                error: 'Value must be in the list'
            };
            worksheet.getCell('Q' + (i+2)).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['=dynadata!$F$1:$F$' + enablePortalL.length],
                showErrorMessage: true,
                errorStyle: 'error',
                errorTitle: 'Error',
                error: 'Value must be in the list'
            };
            worksheet.getCell('R' + (i+2)).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['=dynadata!$G$1:$G$' + portalLanguageL.length],
                showErrorMessage: true,
                errorStyle: 'error',
                errorTitle: 'Error',
                error: 'Value must be in the list'
            };
            worksheet.getCell('V' + (i+2)).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['=dynadata!$H$1:$H$' + countryL.length],
                showErrorMessage: true,
                errorStyle: 'error',
                errorTitle: 'Error',
                error: 'Value must be in the list'
            };
            // worksheet.getCell('W' + (i+2)).dataValidation = {
            //     type: 'list',
            //     allowBlank: true,
            //     formulae: ['=dynadata!$JA$1#'],
            //     showErrorMessage: true,
            //     errorStyle: 'error',
            //     errorTitle: 'Error',
            //     error: 'Value must be in the list'
            // };
            worksheet.getCell('AF' + (i+2)).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: ['=dynadata!$H$1:$H$' + countryL.length],
                showErrorMessage: true,
                errorStyle: 'error',
                errorTitle: 'Error',
                error: 'Value must be in the list'
            };
            // worksheet.getCell('AG' + (i+2)).dataValidation = {
            //     type: 'list',
            //     allowBlank: true,
            //     formulae: ['=dynadata!$'+(numberToColumn(260+1)+i)+'$1#'],
            //     showErrorMessage: true,
            //     errorStyle: 'error',
            //     errorTitle: 'Error',
            //     error: 'Value must be in the list'
            // };
        }

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'Customer Template.xlsx');
        })
    }

    const handleChangeStatusExcel = (fileWithMeta, status, allFiles) => {
        // console.log(status)
        setBulkDataVerified(false)

        if (status === 'done') {
            setBulkDataVerifyButtonState(true)

            let reader = new FileReader();
            reader.onload = function(e) {
                let data = e.target.result;
                setBulkDataFile(data)
                setBulkDataVerifyButtonState(false)
            }
            reader.onerror = function(ex) {
                console.log(ex);
            }
            reader.readAsBinaryString(fileWithMeta.file);       
        }
        else if(status === "removed")
        {
            setBulkDataFile(null)
        }
        else if(status === "error_file_size")
        {
            swal("Oops!", "File size exceeded", "warning");
        }
    };

    //Fetch the headers of sheet
    function get_header_row(sheet) {
        var headers = [];
        var range = XLSX.utils.decode_range(sheet['!ref']);
        var C, R = range.s.r; /* start in the first row */
        /* walk every column in the range */
        for (C = range.s.c; C <= range.e.c; ++C) {
            var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })] /* find the cell in the first row */
            var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
            if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
            headers.push(hdr);
        }
        headers.push("rowNo")
        return headers;
    }

    const bulkDataVerify = () => {
        if(bulkDataFile == null){
            swal("Oops!", "Upload a file before verifying", "warning");
            return
        }

        let input_array = ["Customer Type", "First Name", "Last Name", "Company Name", "Work Phone", "Mobile", "Designation", "Department", "Email", "Website", "Lead Source", "Identity Type", "Identity Number", "PAN", "Currency", "Terms", "Enable Portal", "Portal Language", "Facebook Link", "Twitter Link", 
            "Billing Attention","Billing Country","Billing State","Billing City","Billing Pincode","Billing Address1","Billing Address2","Billing Phone","Billing Fax","Billing Delivery Instruction", 
            "Shipping Attention","Shipping Country","Shipping State","Shipping City","Shipping Pincode","Shipping Address1","Shipping Address2","Shipping Phone","Shipping Fax","Shipping Delivery Instruction"]

        new Promise((rs,rj)=>{

            let fullArray = []

            let data = bulkDataFile
            let workbook = XLSX.read(data, {
                type: 'binary'
            });

            //Fetch the name of First Sheet.
            let firstSheet = workbook.SheetNames[0];

            //Fetch the header of First Sheet.
            let headers = get_header_row(workbook.Sheets[firstSheet])

            //Checking if any headers missing or changed from the sheet 
            let allFounded = input_array.every(ai => headers.includes(ai))

            if (allFounded) {
                //Read all rows from First Sheet into an JSON array.
                let excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
                for (let i = 0; i < excelRows.length; i++) {
                    let rowE = {}
                    for (let j = 0; j < input_array.length; j++) {
                        rowE[input_array[j]] = excelRows[i][input_array[j]]
                        if (j == input_array.length - 1) {
                            rowE["rowNo"] = i + 2
                        }
                    }
                    fullArray.push(rowE)
                }
                if (fullArray.length) {
                    if(fullArray.length>20){
                        fullArray.splice(20)
                        swal("Message","More than 20 entries are truncated","info")
                    }

                    rs([fullArray,headers])
                    setBulkDataExtracted(fullArray)
                }
                else {
                    swal("oh!oh", "Spreadsheet is empty!", "warning").then(() => {
                        window.$("#processing").modal("hide")
                    })
                    rj("excel file processing error")
                }
            }
            else {
                swal("oh!oh", "Column headers are missing or modified!", "error").then(() => {
                    window.$("#processing").modal("hide")
                })
                rj("excel file processing error")
            }
        })
        .then((values)=>{
            let [fullArray, headers] = values
            // console.log(fullArray)

            let customerTypeL = ["business","individual"]
            let leadSourceL = ["CRM","Websites","Social","Referral","Cold Call","SEO"]
            let identityTypeL = ["aadhar_card","voter_card"]
            let currencyL = currencyList.map(x=>x.currency_code)
            let termsL = termsList.map(x=>x.term_name)
            let enablePortalL = ["on","off"]
            let portalLanguageL = ["Arabic", "Catalan", "Chinese (Simplified)", "Croatian", "Danish", "Dutch", "English", "Finnish", "French", "German", "Greek", "Indonesian", "Italian", "Japanese", "Norwegian", "Polish", "Portuguese", "Portuguese (Brazilian)", "Russian", "Slovak", "Spanish", "Spanish (Argentine)", "Swedish"]
            let countryL = [...country_arr.country_arr]
            countryL.shift()
            let stateL = [...s_a.s_a]
            stateL.shift()


            let errMsg = [];
            let Ind = [];

            let noValid = ["rowNo", "Company Name", "Mobile", "Designation", "Department", "Website", "Facebook Link", "Twitter Link", 
            "Billing Attention","Billing State","Billing City","Billing Pincode","Billing Address1","Billing Address2","Billing Phone","Billing Fax","Billing Delivery Instruction", 
            "Shipping Attention","Shipping State","Shipping City","Shipping Pincode","Shipping Address1","Shipping Address2","Shipping Phone","Shipping Fax","Shipping Delivery Instruction"]

            
            for (let i = 0; i < fullArray.length; i++) {
                for (let j = 0; j < input_array.length + 1; j++) {
                    if (!noValid.includes(headers[j])) { 

                        let regex1 = /^(\+\d{1,3}[- ]?)?\d{10}$/
                        let regex2 = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                        let regex3 = /^[0-9]{4}?[0-9]{4}?[0-9]{4}$/;
                        let regex4 = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

                        if (fullArray[i][headers[j]] == undefined || fullArray[i][headers[j]] == "") {
                            errMsg.push(headers[j] + " is empty at row " + (i + 1))
                            Ind.push(i)
                        }
                        else if (headers[j] == "Customer Type" && !customerTypeL.includes(fullArray[i]["Customer Type"])) {
                            errMsg.push(headers[j] + " is INVALID at row " + (i + 1))
                            Ind.push(i)
                        }
                        else if (headers[j] == "Work Phone" && !(regex1.test(fullArray[i]["Work Phone"])) ) {
                            errMsg.push(headers[j] + " is INVALID at row " + (i + 1))
                            Ind.push(i)
                        }
                        else if (headers[j] == "Email" && !(regex2.test(fullArray[i]["Email"])) ) {
                            errMsg.push(headers[j] + " is INVALID at row " + (i + 1))
                            Ind.push(i)
                        }
                        else if (headers[j] == "Lead Source" && !leadSourceL.includes(fullArray[i]["Lead Source"])) {
                            errMsg.push(headers[j] + " is INVALID at row " + (i + 1))
                            Ind.push(i)
                        }
                        else if (headers[j] == "Identity Type" && !identityTypeL.includes(fullArray[i]["Identity Type"])) {
                            errMsg.push(headers[j] + " is INVALID at row " + (i + 1))
                            Ind.push(i)
                        }
                        else if (headers[j] == "Identity Number" && fullArray[i]["Identity Type"] == "aadhar_card" && !(regex3.test(fullArray[i]["Identity Number"]))) {
                            errMsg.push(headers[j] + " is INVALID at row " + (i + 1))
                            Ind.push(i)
                        }
                        else if (headers[j] == "PAN" && !(regex4.test(fullArray[i]["PAN"])) ) {
                            errMsg.push(headers[j] + " is INVALID at row " + (i + 1))
                            Ind.push(i)
                        }
                        else if (headers[j] == "Currency" && !currencyL.includes(fullArray[i]["Currency"])) {
                            errMsg.push(headers[j] + " is INVALID at row " + (i + 1))
                            Ind.push(i)
                        }
                        else if (headers[j] == "Terms" && !termsL.includes(fullArray[i]["Terms"])) {
                            errMsg.push(headers[j] + " is INVALID at row " + (i + 1))
                            Ind.push(i)
                        }
                        else if (headers[j] == "Enable Portal" && !enablePortalL.includes(fullArray[i]["Enable Portal"])) {
                            errMsg.push(headers[j] + " is INVALID at row " + (i + 1))
                            Ind.push(i)
                        }
                        else if (headers[j] == "Portal Language" && !portalLanguageL.includes(fullArray[i]["Portal Language"])) {
                            errMsg.push(headers[j] + " is INVALID at row " + (i + 1))
                            Ind.push(i)
                        }
                        else if (headers[j] == "Billing Country" && !countryL.includes(fullArray[i]["Billing Country"]) ) {
                            errMsg.push(headers[j] + " is INVALID at row " + (i + 1))
                            Ind.push(i)
                        }
                        else if (headers[j] == "Shipping Country" && !countryL.includes(fullArray[i]["Shipping Country"])) {
                            errMsg.push(headers[j] + " is INVALID at row " + (i + 1))
                            Ind.push(i)
                        }
                    }
                }
            }

            Ind = [...new Set(Ind)];
            seterrInd(Ind)
            seterrMsg(errMsg)
            if(errMsg.length == 0){
                setBulkDataVerified(true)
            }
            setBulkErrorModalShow(true)
        })
        .catch((err)=>{
            console.log(err)
        })
    }

    const bulkDataSubmit = () => { 

        var object = {};

        let finalArr = []
        bulkDataExtracted.forEach((obj)=>{
            let tObj = {}
            tObj.customer_type = obj["Customer Type"]
            tObj.primary_contact = {salutation: null,firstName: obj["First Name"],lastName: obj["Last Name"]}
            tObj.company_name = (obj["Company Name"]==undefined)?"":obj["Company Name"]
            tObj.customer_display_name = obj["First Name"]+" "+obj["Last Name"]
            tObj.work_phone = obj["Work Phone"]
            tObj.mobile = (obj["Mobile"]==undefined)?"":obj["Mobile"]
            tObj.designation = (obj["Designation"]==undefined)?"":obj["Designation"]
            tObj.department = (obj["Department"]==undefined)?"":obj["Department"]
            tObj.email = obj["Email"]
            tObj.website = (obj["Website"]==undefined)?"":obj["Website"]
            tObj.lead_source = obj["Lead Source"]

            tObj.identity_type = obj["Identity Type"]
            tObj.identity_number = obj["Identity Number"]
            tObj.pan = obj["PAN"]
            tObj.currency = {currency_code: obj["Currency"], currency_id: currencyList.find(x=>x.currency_code==obj["Currency"]).currency_id}
            tObj.term = {term_name: obj["Terms"], no_of_days: termsList.find(x=>x.term_name==obj["Terms"]).no_of_days}
            tObj.portal_check = obj["Enable Portal"]=="on"?"yes":"no"
            tObj.portal_language = (obj["Portal Language"]==null)?null:obj["Portal Language"]
            tObj.facebook_link = (obj["Facebook Link"]==undefined)?"":obj["Facebook Link"]
            tObj.twitter_link = (obj["Twitter Link"]==undefined)?"":obj["Twitter Link"]

            tObj.billing_attention = (obj["Billing Attention"]==undefined)?"":obj["Billing Attention"]
            tObj.billing_country = obj["Billing Country"]
            tObj.billing_state = (obj["Billing State"]==undefined)?"":obj["Billing State"]
            tObj.billing_city = (obj["Billing City"]==undefined)?"":obj["Billing City"]
            tObj.billing_pincode = (obj["Billing Pincode"]==undefined)?"":obj["Billing Pincode"]
            tObj.billing_address1 = (obj["Billing Address1"]==undefined)?"":obj["Billing Address1"]
            tObj.billing_address2 = (obj["Billing Address2"]==undefined)?"":obj["Billing Address2"]
            tObj.billing_phone = (obj["Billing Phone"]==undefined)?"":obj["Billing Phone"]
            tObj.billing_fax = (obj["Billing Fax"]==undefined)?"":obj["Billing Fax"]
            tObj.billing_delivery_instruction = (obj["Billing Delivery Instruction"]==undefined)?"":obj["Billing Delivery Instruction"]

            tObj.shipping_attention = (obj["Shipping Attention"]==undefined)?"":obj["Shipping Attention"]
            tObj.shipping_country = obj["Shipping Country"]
            tObj.shipping_state = (obj["Shipping State"]==undefined)?"":obj["Shipping State"]
            tObj.shipping_city = (obj["Shipping City"]==undefined)?"":obj["Shipping City"]
            tObj.shipping_pincode = (obj["Shipping Pincode"]==undefined)?"":obj["Shipping Pincode"]
            tObj.shipping_address1 = (obj["Shipping Address1"]==undefined)?"":obj["Shipping Address1"]
            tObj.shipping_address2 = (obj["Shipping Address2"]==undefined)?"":obj["Shipping Address2"]
            tObj.shipping_phone = (obj["Shipping Phone"]==undefined)?"":obj["Shipping Phone"]
            tObj.shipping_fax = (obj["Shipping Fax"]==undefined)?"":obj["Shipping Fax"]
            tObj.shipping_delivery_instruction = (obj["Shipping Delivery Instruction"]==undefined)?"":obj["Shipping Delivery Instruction"]

            tObj.contact_persons_array = []
            tObj.remarks = ""

            finalArr.push(tObj)
        })

        object.customer_array = finalArr

        object.organization_id = organization_id
        object.page_name = page_name;

        console.log(object);
        setBulkDataSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-bulk-customers`, object, headers).then((res) => {
            setBulkDataSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Bulk Customers added successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else if(res.data.operation === "same_customer_email") {
                swal("Oops!", `These Customer emails exist already:\n ${res.data.values.join(", ")}`, "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const handleBulkDataModalClose = () => {
        setBulkDataModalShow(false)
        setBulkDataFile(null)
        setBulkDataExtracted([])
        setBulkDataVerifyButtonState(false)
        setBulkDataVerified(false)
    }

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    return (
        <>
            {
                (redirect == 1 ||(read==0 && write==0)) ? 
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> :
                 null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}

            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Add Customer</h1>
                        <div class="d-flex align-items-center gap-2 gap-lg-3">
                            <button className='btn btn-success rounded' data-kt-indicator={state!=1?"on":"off"} disabled={state!=1} onClick={()=>{setBulkDataModalShow(true)}}>
                                <span className="indicator-label"><i class="las la-file-upload" style={{ "font-size": "32px" }}></i>Add Bulk Data</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">

                        {
                            state == 1 ?
                                <div className="card">
                                    <div className="card-body">

                                        <div className='container'>
                                            <div className="row mb-5">
                                                <div className='col-4 my-auto'>
                                                    <label className="fs-5 fw-bold mb-2">Customer Type</label>
                                                </div>
                                                <div className='col-4'>
                                                    <label className="fs-5 mb-2" onClick={()=>{ setCustomerType("business") }}> Business
                                                        <input type="radio" className="form-check-input ms-5" checked={customerType=="business"} />
                                                    </label>
                                                </div>
                                                <div className='col-4'>
                                                    <label className="fs-5 mb-2" onClick={()=>{ setCustomerType("individual") }}>Individual
                                                        <input type="radio" className="form-check-input ms-5" checked={customerType=="individual"}/>
                                                    </label>                                                    
                                                </div>    
                                            </div>

                                            <div className="row mb-5">
                                                <div className="col-3 my-auto">
                                                    <label className="required fs-5 fw-bold mb-2">Primary Contact</label>
                                                </div>
                                                <div className='col-3 my-auto'>
                                                    <Select
                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                        options={["Mr.","Mrs.","Ms.","Miss.","Dr."].map(x=>{return {label:x, value:x} })}
                                                        value={primaryContact.salutation}
                                                        onChange={(val)=>{ 
                                                            setPrimaryContact({...primaryContact, salutation: val})
                                                        }}
                                                        isClearable
                                                        placeholder="Salutation"
                                                    />
                                                </div>
                                                <div className='col-6 input-group-text p-0'>
                                                    <input type="text" className="form-control form-control-solid" value={primaryContact.firstName} onChange={(e)=>{setPrimaryContact({...primaryContact,firstName: e.target.value}) }} placeholder='First Name'/>
                                                    <input type="text" className="form-control form-control-solid" value={primaryContact.lastName} onChange={(e)=>{setPrimaryContact({...primaryContact,lastName: e.target.value}) }} placeholder='Last Name'/>
                                                </div>
                                            </div>

                                            <div className="row mb-5">
                                                <div className="col-md-6">
                                                    <label className="fs-5 fw-bold mb-2">Company Name</label>
                                                    <input type="text" className="form-control form-control-solid" value={companyName} onChange={(e)=>{setCompanyName(e.target.value)}} />
                                                </div>
                                                <div className="col-md-6 my-auto">
                                                    <label className="required fs-5 fw-bold mb-2">Customer Display Name</label>
                                                    <Select
                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                        options={ customerDisplayNameOptions.map(x=>{return {label:x, value:x} })}
                                                        value={selectedCustomerDisplayName}
                                                        onChange={(val)=>{ setSelectedCustomerDisplayName(val) }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mb-5">
                                                <div className="col-md-6">
                                                    <label className="required fs-5 fw-bold mb-2">Work Phone</label>
                                                    <input type="number" className="form-control form-control-solid" value={workPhone} onChange={(e)=>{setWorkPhone(e.target.value)}} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="fs-5 fw-bold mb-2">Mobile</label>
                                                    <input type="number" className="form-control form-control-solid" value={mobile} onChange={(e)=>{setMobile(e.target.value)}} />
                                                </div>
                                            </div>

                                            <div className="row mb-5">
                                                <div className="col-md-6">
                                                    <label className="fs-5 fw-bold mb-2">Designation</label>
                                                    <input type="text" className="form-control form-control-solid" value={designation} onChange={(e)=>{setDesignation(e.target.value)}} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="fs-5 fw-bold mb-2">Department</label>
                                                    <input type="text" className="form-control form-control-solid" value={department} onChange={(e)=>{setDepartment(e.target.value)}} />
                                                </div>
                                            </div> 

                                            <div className="row mb-5">
                                                <div className="col-md-6">
                                                    <label className= {"fs-5 fw-bold mb-2 "+(portalCheck?"required":"")}>Email</label>
                                                    <input type="email" className="form-control form-control-solid" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="fs-5 fw-bold mb-2">Website</label>
                                                    <input type="text" className="form-control form-control-solid" value={website} onChange={(e)=>{setWebsite(e.target.value)}} />
                                                </div>
                                            </div>

                                            <div className="row mb-5">
                                                <div className='col-md-3 my-auto'>
                                                    <label className="required fs-5 fw-bold mb-2">Lead Source:</label>
                                                </div>
                                                <div className='col-md-9'>
                                                    <Select
                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                        options={["CRM","Websites","Social","Referral","Cold Call","SEO"].map(x=>{return {label:x, value:x} })}
                                                        value={selectedLeadSource}
                                                        onChange={(val) => { setSelectedLeadSource(val) }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        
                                        <div className='' style={{marginTop:"35px"}}>
                                            <Tabs className="mb-3 fs-4" activeKey={currentPanel} onSelect={(k) => setCurrentPanel(k)}>

                                                <Tab eventKey="otherDetails" title="Other Details" className='mt-10'>
                                                    <div className='container'>
                                                        <div className='row mb-5'>
                                                            <div className="col-md-4 my-auto">
                                                                <label className="required fs-5 fw-bold mb-2">Identity Type</label>
                                                                <Select
                                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    options = {[{label:"Aadhar Card", value:"aadhar_card"}, {label:"Voter Card", value:"voter_card"}]}
                                                                    value= {selectedIdentityType}
                                                                    onChange= {(val)=>{setSelectedIdentityType(val)} }
                                                                />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label className="required fs-5 fw-bold mb-2">Identity Number</label>
                                                                <input type="text" className="form-control form-control-solid" value={identityNumber} onChange={(e)=>{setIdentityNumber(e.target.value)}} />
                                                            </div>
                                                            <div className="col-md-4">
                                                                <label className="required fs-5 fw-bold mb-2">Pan</label>
                                                                <input type="text" className="form-control form-control-solid" value={pan} onChange={(e)=>{setPan(e.target.value)}} />
                                                            </div>
                                                        </div>

                                                        <div className="row mb-5">
                                                            <div className="col-md-6">
                                                                <label className="required fs-5 fw-bold mb-2">Currency</label>
                                                                <Select
                                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    options={ currencyList.map(x=>{return {label:x.currency_code,value:x.currency_id} }) }
                                                                    onChange={(val) => {setSelectedCurrency(val)}}        
                                                                    value={selectedCurrency}
                                                                    components={{ 
                                                                        MenuList: (props) => {
                                                                            return (
                                                                                <components.MenuList  {...props}>
                                                                                    {props.children} 
                                                                                    <div className='d-flex justify-content-center'>
                                                                                        <button className='btn btn-success rounded btn-sm my-1' onClick={()=>{ setCurrencyModalShow(true) }}> &#10011; Add New Currency</button>
                                                                                    </div>
                                                                                </components.MenuList >
                                                                            ) 
                                                                        } 
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="fs-5 fw-bold mb-2">Terms</label>
                                                                <Select
                                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    options={ termsList.map(x=>{return {label:x.term_name, value:x.no_of_days} }) }
                                                                    onChange={(val) => {setSelectedTerm(val)}}        
                                                                    value={selectedTerm}
                                                                    isClearable
                                                                    components={{ 
                                                                        MenuList: (props) => {
                                                                            return (
                                                                                <components.MenuList  {...props}>
                                                                                    {props.children} 
                                                                                    <div className='d-flex justify-content-center'>
                                                                                        <button className='btn btn-success rounded btn-sm my-1' 
                                                                                            onClick={()=>{
                                                                                                setTermsModalShow(true)
                                                                                                setTermsPanelList(termsList.map(x=>{return {...x}}) ) 
                                                                                            }} 
                                                                                        >&#10011; Configure Terms</button>
                                                                                    </div>
                                                                                </components.MenuList >
                                                                            ) 
                                                                        } 
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row mb-5">
                                                            <div className="col-4 my-auto">
                                                                <label className="fs-5 fw-bold mb-2">Enable Portal for Customer :</label>
                                                            </div>
                                                            <div className="col-2">
                                                                <div className="form-check form-check-solid form-check-primary form-switch mt-2">   
                                                                    <input className="form-check-input w-45px h-30px" type="checkbox" checked={portalCheck} onChange={(e)=>{setPortalCheck(e.target.checked) }} />
                                                                </div>
                                                            </div>
                                                            <div className="col-6">
                                                                <Select
                                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    options={["Arabic", "Catalan", "Chinese (Simplified)", "Croatian", "Danish", "Dutch", "English", "Finnish", "French", "German", "Greek", "Indonesian", "Italian", "Japanese", "Norwegian", "Polish", "Portuguese", "Portuguese (Brazilian)", "Russian", "Slovak", "Spanish", "Spanish (Argentine)", "Swedish"].map(x=>{return {label:x, value:x}})}
                                                                    value={selectedPortalLanguage}
                                                                    onChange={(val)=>{setSelectedPortalLanguage(val)}}
                                                                    placeholder="Select Customer Portal Language"
                                                                />
                                                            </div>
                                                            {portalCheck && <div className='fs-7'>(Email address is mandatory)</div>}
                                                        </div>

                                                        <div className="row mb-5">
                                                            <div className="col-md-6">
                                                                <label className="fs-5 fw-bold mb-2">Facebook</label>
                                                                <div className='input-group'>
                                                                    <div className="input-group-prepend">
                                                                        <span class="input-group-text h-100" >
                                                                            <i className="bi bi-facebook" style={{color:"blue"}}></i>
                                                                        </span>
                                                                    </div>
                                                                    <input type="text" className="form-control" value={facebookLink} onChange={(e)=>{setFacebookLink(e.target.value)}} placeholder={"http://www.facebook.com"} />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="fs-5 fw-bold mb-2">Twitter</label>
                                                                <div className='input-group'>
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text h-100" >
                                                                            <i className="bi bi-twitter" style={{color:"skyblue"}}></i>
                                                                        </span>
                                                                    </div>
                                                                    <input type="text" className="form-control" value={twitterLink} onChange={(e)=>{setTwitterLink(e.target.value)}} placeholder={"http://www.twitter.com"}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>

                                                <Tab eventKey="address" title="Address" className='mt-10'>
                                                    <div className='container'>
                                                        <h3 className='mb-5'>Billing Address:</h3>
                                                        <div className="row mb-5">
                                                            <div className="col-md-12">
                                                                <label className="fs-5 fw-bold mb-2">Attention</label>
                                                                <input type="text" className="form-control form-control-solid" value={billingAttention} onChange={(e)=>{setBillingAttention(e.target.value)}} />
                                                            </div>
                                                        </div>

                                                        <div className="row mb-5">
                                                            <div className="col-md-6">
                                                                <label className="fs-5 fw-bold mb-2">Country</label>
                                                                <Select
                                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    options={billingCountryOptions}
                                                                    value={billingSelectedCountry}
                                                                    onChange={(val) => {setBillingSelectedCountry(val); setBillingSelectedState(null); loadBillingStateOptions(val);}}
                                                                />
                                                            </div>
                                                            
                                                            <div className="col-md-6">
                                                                <label className="fs-5 fw-bold mb-2">State / Province / Region</label>
                                                                <Select
                                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    options={billingStateOptions}
                                                                    value={billingSelectedState}
                                                                    onChange={(val) => {setBillingSelectedState(val)}}
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="row mb-5">
                                                            <div className="col-md-12">
                                                                <label className="fs-5 fw-bold mb-2">Address Line 1</label>
                                                                <input type="text" className="form-control form-control-solid" value={billingAddress1} onChange={(e)=>{setBillingAddress1(e.target.value)}} />
                                                            </div>
                                                        </div>

                                                        <div className="row mb-5">
                                                            <div className="col-md-12">
                                                                <label className="fs-5 fw-bold mb-2">Address Line 2</label>
                                                                <input type="text" className="form-control form-control-solid" value={billingAddress2} onChange={(e)=>{setBillingAddress2(e.target.value)}} />
                                                            </div>
                                                        </div>

                                                        <div className="row mb-5">
                                                            <div className="col-md-6">
                                                                <label className="fs-5 fw-bold mb-2">Town/City</label>
                                                                <input type="text" className="form-control form-control-solid" value={billingCity} onChange={(e)=>{setBillingCity(e.target.value)}} />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="fs-5 fw-bold mb-2">Pin Code</label>
                                                                <input type="text" className="form-control form-control-solid" value={billingPincode} onChange={(e)=>{setBillingPincode(e.target.value)}} />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="row mb-5">
                                                            <div className="col-md-6">
                                                                <label className="fs-5 fw-bold mb-2">Phone</label>
                                                                <input type="text" className="form-control form-control-solid" value={billingPhone} onChange={(e)=>{setBillingPhone(e.target.value)}} />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="fs-5 fw-bold mb-2">Fax</label>
                                                                <input type="text" className="form-control form-control-solid" value={billingFax} onChange={(e)=>{setBillingFax(e.target.value)}} />
                                                            </div>
                                                        </div>

                                                        <div className="row mb-5">
                                                            <div className="col-md-12">
                                                                <label className="form-label">Delivery Instruction </label> 
                                                                <textarea rows="3" className="form-control form-control-solid" value={billingDeliveryInstruction} onChange={(e)=>{setBillingDeliveryInstruction(e.target.value)}} placeholder='Please Enter your Delivery Instruction'></textarea>
                                                            </div>
                                                        </div>

                                                        <div className='d-flex my-5 align-items-center'>
                                                            <h3>Shipping Address:</h3>
                                                            <button className='btn btn-outline-info rounded ms-10' onClick={()=>{copyAddress()}} >Copy Billing Address Details to Shipping Address</button>
                                                        </div>
                                                            
                                                        <div className="row mb-5">
                                                            <div className="col-md-12">
                                                                <label className="fs-5 fw-bold mb-2">Attention</label>
                                                                <input type="text" className="form-control form-control-solid" value={shippingAttention} onChange={(e)=>{setShippingAttention(e.target.value)}} />
                                                            </div>
                                                        </div>

                                                        <div className="row mb-5">
                                                            <div className="col-md-6">
                                                                <label className="fs-5 fw-bold mb-2">Country</label>
                                                                <Select
                                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    options={shippingCountryOptions}
                                                                    value={shippingSelectedCountry}
                                                                    onChange={(val) => {setShippingSelectedCountry(val); setShippingSelectedState(null); loadShippingStateOptions(val);}}
                                                                />
                                                            </div>
                                                            
                                                            <div className="col-md-6">
                                                                <label className="fs-5 fw-bold mb-2">State / Province / Region</label>
                                                                <Select
                                                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                    options={shippingStateOptions}
                                                                    value={shippingSelectedState}
                                                                    onChange={(val) => {setShippingSelectedState(val)}}
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="row mb-5">
                                                            <div className="col-md-12">
                                                                <label className="fs-5 fw-bold mb-2">Address Line 1</label>
                                                                <input type="text" className="form-control form-control-solid" value={shippingAddress1} onChange={(e)=>{setShippingAddress1(e.target.value)}} />
                                                            </div>
                                                        </div>

                                                        <div className="row mb-5">
                                                            <div className="col-md-12">
                                                                <label className="fs-5 fw-bold mb-2">Address Line 2</label>
                                                                <input type="text" className="form-control form-control-solid" value={shippingAddress2} onChange={(e)=>{setShippingAddress2(e.target.value)}} />
                                                            </div>
                                                        </div>

                                                        <div className="row mb-5">
                                                            <div className="col-md-6">
                                                                <label className="fs-5 fw-bold mb-2">Town/City</label>
                                                                <input type="text" className="form-control form-control-solid" value={shippingCity} onChange={(e)=>{setShippingCity(e.target.value)}} />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="fs-5 fw-bold mb-2">Pin Code</label>
                                                                <input type="text" className="form-control form-control-solid" value={shippingPincode} onChange={(e)=>{setShippingPincode(e.target.value)}} />
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="row mb-5">
                                                            <div className="col-md-6">
                                                                <label className="fs-5 fw-bold mb-2">Phone</label>
                                                                <input type="text" className="form-control form-control-solid" value={shippingPhone} onChange={(e)=>{setShippingPhone(e.target.value)}} />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label className="fs-5 fw-bold mb-2">Fax</label>
                                                                <input type="text" className="form-control form-control-solid" value={shippingFax} onChange={(e)=>{setShippingFax(e.target.value)}} />
                                                            </div>
                                                        </div>

                                                        <div className="row mb-5">
                                                            <div className="col-md-12">
                                                                <label className="form-label">Delivery Instruction </label> 
                                                                <textarea rows="3" className="form-control form-control-solid" value={shippingDeliveryInstruction} onChange={(e)=>{setShippingDeliveryInstruction(e.target.value)}} placeholder='Please Enter your Delivery Instruction'></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>

                                                <Tab eventKey="profile" title="Profile" className='mt-10'>
                                                    <div className='container'>
                                                        {
                                                            write == 1 &&
                                                            <>
                                                                <div className='mb-3'>
                                                                    <label className="fs-5 fw-bold mb-2">Upload Profile Photo (Single file upload)</label>
                                                                    <Dropzone
                                                                        getUploadParams={getUploadParams1}
                                                                        onChangeStatus={handleChangeStatus1}
                                                                        LayoutComponent={CustomLayout}
                                                                        PreviewComponent={Preview}
                                                                        styles={{
                                                                            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                                            inputLabelWithFiles: { margin: '20px 3%' }
                                                                        }}
                                                                        maxFiles={1}
                                                                        maxSizeBytes={1024*1024*2}
                                                                        accept="image/*"
                                                                    />
                                                                </div>
                                                                <div className='mb-3'>
                                                                    <label className="fs-5 fw-bold mb-2">Upload Identity Proof (Maximum 3 files)</label>
                                                                    <Dropzone
                                                                        getUploadParams={getUploadParams2}
                                                                        onChangeStatus={handleChangeStatus2}
                                                                        LayoutComponent={CustomLayout}
                                                                        PreviewComponent={Preview}
                                                                        styles={{
                                                                            dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                                            inputLabelWithFiles: { margin: '20px 3%' }
                                                                        }}
                                                                        maxFiles={3}
                                                                        maxSizeBytes={1024*1024*2}
                                                                        accept="image/*,.pdf"
                                                                    />
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </Tab>

                                                <Tab eventKey="contactPersons" title="Contact Persons" className='mt-10'>
                                                    <div className='w-100' style={{overflowX:"scroll"}}>
                                                        <div className='container' style={{minWidth:"max-content"}}>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <label className="fs-5 fw-bold mb-2">Salutation</label>
                                                                </div>
                                                                <div className="col">
                                                                    <label className="fs-5 fw-bold mb-2">First Name</label>
                                                                </div>
                                                                <div className="col">
                                                                    <label className="fs-5 fw-bold mb-2">Last Name</label>
                                                                </div>
                                                                <div className="col">
                                                                    <label className="fs-5 fw-bold mb-2">Email Address</label>
                                                                </div>
                                                                <div className="col">
                                                                    <label className="fs-5 fw-bold mb-2">Work Phone</label>
                                                                </div>
                                                                <div className="col">
                                                                    <label className="fs-5 fw-bold mb-2">Mobile</label>
                                                                </div>
                                                                <div className="col">
                                                                    <label className="fs-5 fw-bold mb-2">Designation</label>
                                                                </div>
                                                                <div className="col">
                                                                    <label className="fs-5 fw-bold mb-2">Department</label>
                                                                </div>
                                                                {
                                                                    contactPersonsArray.length>1 && <div className='col'></div> 
                                                                }
                                                            </div>
                                                            {
                                                                contactPersonsArray.map((obj,indx)=>{
                                                                    return <div className="row mb-1">
                                                                        <div className="col border border-dark rounded my-auto px-0 py-1">
                                                                            <Select
                                                                                styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                                                options={["Mr.","Mrs.","Ms.","Miss.","Dr."].map(x=>{return {label:x, value:x} })}
                                                                                value={obj.salutation}
                                                                                onChange={(val)=>{ 
                                                                                    let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                    temp[indx].salutation = val
                                                                                    setContactPersonsArray(temp)
                                                                                }}
                                                                                placeholder=""
                                                                            />
                                                                        </div>
                                                                        <div className="col border border-dark rounded px-1">
                                                                            <input type="text" className="form-control form-control-solid" value={obj.firstName} 
                                                                                onChange={(e)=>{
                                                                                    let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                    temp[indx].firstName = e.target.value
                                                                                    setContactPersonsArray(temp)
                                                                                }} 
                                                                            />
                                                                        </div>
                                                                        <div className="col border border-dark rounded px-1">
                                                                            <input type="text" className="form-control form-control-solid" value={obj.lastName} 
                                                                                onChange={(e)=>{
                                                                                    let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                    temp[indx].lastName = e.target.value
                                                                                    setContactPersonsArray(temp)
                                                                                }} 
                                                                            />
                                                                        </div>
                                                                        <div className="col border border-dark rounded px-1">
                                                                            <input type="email" className="form-control form-control-solid" value={obj.email} 
                                                                                onChange={(e)=>{
                                                                                    let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                    temp[indx].email = e.target.value
                                                                                    setContactPersonsArray(temp)
                                                                                }} 
                                                                            />
                                                                        </div>
                                                                        <div className="col border border-dark rounded px-1">
                                                                            <input type="text" className="form-control form-control-solid" value={obj.workPhone} 
                                                                                onChange={(e)=>{
                                                                                    let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                    temp[indx].workPhone = e.target.value
                                                                                    setContactPersonsArray(temp)
                                                                                }} 
                                                                            />
                                                                        </div>
                                                                        <div className="col border border-dark rounded px-1">
                                                                            <input type="text" className="form-control form-control-solid" value={obj.mobile} 
                                                                                onChange={(e)=>{
                                                                                    let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                    temp[indx].mobile = e.target.value
                                                                                    setContactPersonsArray(temp)
                                                                                }} 
                                                                            />
                                                                        </div>
                                                                        <div className="col border border-dark rounded px-1">
                                                                            <input type="text" className="form-control form-control-solid" value={obj.designation} 
                                                                                onChange={(e)=>{
                                                                                    let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                    temp[indx].designation = e.target.value
                                                                                    setContactPersonsArray(temp)
                                                                                }} 
                                                                            />
                                                                        </div>
                                                                        <div className="col border border-dark rounded px-1">
                                                                            <input type="text" className="form-control form-control-solid" value={obj.department} 
                                                                                onChange={(e)=>{
                                                                                    let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                    temp[indx].department = e.target.value
                                                                                    setContactPersonsArray(temp)
                                                                                }} 
                                                                            />
                                                                        </div>
                                                                        {
                                                                            contactPersonsArray.length>1 &&
                                                                            <div className="col my-auto">
                                                                                <button className='btn btn-sm btn-outline-danger rounded-pill fs-7 border border-danger' 
                                                                                    onClick={()=>{
                                                                                        let temp = contactPersonsArray.map(a=>{return{...a}})
                                                                                        temp.splice(indx,1)
                                                                                        setContactPersonsArray(temp)
                                                                                    }}
                                                                                >&#10006;</button>
                                                                            </div>
                                                                        }
                                                                    </div>  
                                                                })
                                                            }
                                                            <div className='mt-10'>
                                                                <button className='btn btn-primary rounded' onClick={()=>{ setContactPersonsArray([...contactPersonsArray, {salutation:null, firstName:"", lastName:"", email:"", workPhone:"", mobile:"", designation:"", department:""} ]) }}>+ Add Contact Person</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>

                                                <Tab eventKey="customFields" title="Custom Fields" className='mt-10'>
                                                    <div className='container'>
                                                        This Feature is in development
                                                    </div>
                                                </Tab>

                                                <Tab eventKey="remarks" title="Remarks" className='mt-10'>
                                                    <div className='container'>
                                                        <div className="row mb-5">
                                                            <div className="col-md-12">
                                                                <label className="fs-5 fw-bold mb-2">Remarks (For Internal Use)</label>
                                                                <textarea className="form-control form-control-solid" rows={5} value={remarks} onChange={(e)=>{setRemarks(e.target.value)}} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>

                                            </Tabs>
                                        </div>


                                        <div style={{marginTop:"35px"}}>                           
                                            {
                                                write==1 ?
                                                    <div className='d-flex justify-content-center'>
                                                        <button className="btn btn-primary btn-lg rounded" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} onClick={(e) => {register(e)}}>
                                                            <span className="indicator-label">Submit &#x2794;</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                :
                                                    <div className='d-flex justify-content-center'>
                                                        <button className="btn btn-primary btn-lg rounded" disabled>
                                                            <span className="indicator-label">Submit &#x2794;</span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        </button>
                                                    </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            :
                            state == 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                            :
                                <>
                                    <div className="flex-lg-row-fluid me-lg-15">
                                        <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                            <div className="card-body pt-0">
                                                <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                <div className="d-flex align-items-center p-3 mb-2"></div>

                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                    <div className="d-flex flex-stack flex-grow-1">
                                                        <div className="fw-bold">
                                                            <h4 className="text-gray-800 fw-bolder">Please Refresh this page</h4>
                                                            <div className="fs-6 text-gray-600"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>

            </div>

            <Modal show={currencyModalShow} onHide={()=>{handleNewCurrencyModalClose()}} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>New Currency</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{handleNewCurrencyModalClose()}}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-5 fw-bold mb-2 required">Currency Code</label>
                                <Select
                                    options={Object.keys(currency_arr.currency_arr).map(x=>{return {label: x,value: x}})}
                                    onChange={(e) => { setSelectedCurrencyCode(e); setCurrencySymbol(currency_arr.currency_arr[e.value].currency_symbol); }}
                                    value={selectedCurrencyCode}
                                    placeholder="--Choose a Currency--"
                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-6 fw-bold mb-2 required">Currency symbol</label>
                                <input type="text" className="form-control form-control-solid" value={currencySymbol} onChange={(e)=>{setCurrencySymbol(e.target.value)}}/>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-5 fw-bold mb-2">Decimal Places</label>
                                <Select
                                    options={[0,2,3].map(x=>{return {label:x,value:x}})}
                                    onChange={(e) => { setSelectedCurrencyDecimalPlace(e) }}
                                    value={selectedCurrencyDecimalPlace}
                                    placeholder="--Choose Decimal Places--"
                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-6 fw-bold mb-2">Format</label>
                                <Select
                                    options={["1,234,567.89", "1.234.567,89", "1 234 567,89" ].map(x=>{return {label:x,value:x}})}
                                    onChange={(e) => { setSelectedCurrencyFormat(e) }}
                                    value={selectedCurrencyFormat}
                                    placeholder="--Choose a Format--"
                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    {
                        write == 1 &&
                        <button className="btn btn-success rounded me-auto" data-kt-indicator={currencySubmitButtonState?"on":"off"} disabled={currencySubmitButtonState} onClick={()=>{newCurrencySubmit()}}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                    <button className="btn btn-danger rounded" onClick={()=>{handleNewCurrencyModalClose()}}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={termsModalShow} onHide={()=>{handleNewTermsModalClose()}} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Configure Payment Terms</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{handleNewTermsModalClose()}}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row">
                            <div className='col-5 text-center'>
                                <label className="fs-5 fw-bold my-2 required">Term Name</label>
                            </div>
                            <div className='col-5 text-center'>
                                <label className="fs-5 fw-bold my-2 required">Number of Days</label>
                            </div>
                        </div>
                        {
                            termsPanelList.length > 0 ?
                            termsPanelList.map((x,indx)=>{
                                return (
                                    <div className="row mb-2">
                                        <div className='col-5'>
                                            <input type="text" className="form-control form-control-solid" value={x.term_name} 
                                                onChange={(e)=>{
                                                    let temp = termsPanelList.map(x=>{return {...x}})
                                                    temp[indx].term_name = e.target.value 
                                                    setTermsPanelList(temp)
                                                }}
                                            />
                                        </div>
                                        <div className='col-5'>
                                            <input type="number" min="0" className="form-control form-control-solid" value={x.no_of_days} 
                                                onChange={(e)=>{
                                                    if(e.target.value>=0){
                                                        let temp = termsPanelList.map(x=>{return {...x}})
                                                        temp[indx].no_of_days = e.target.value 
                                                        setTermsPanelList(temp)
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div className='col-2 my-auto'>
                                            <button className='btn btn-sm btn-outline-danger rounded-pill border border-danger' 
                                                onClick={()=>{
                                                    let temp = termsPanelList.map(a=>{return{...a}})
                                                    temp.splice(indx,1)
                                                    setTermsPanelList(temp)
                                                }}
                                            >&#10006;</button>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <p className='text-center'>No terms available. Create a new term</p>
                        }
                        <div className="row">
                            <div className='col-6'>
                                <button className='btn btn-link' onClick={()=>{setTermsPanelList([...termsPanelList, {term_name: "", no_of_days: ""}]) }} >Add a term</button>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    {
                        write == 1 &&
                        <button className="btn btn-success rounded me-auto" data-kt-indicator={termsSubmitButtonState?"on":"off"} disabled={termsSubmitButtonState} onClick={()=>{newTermsSubmit()}}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                    <button className="btn btn-danger rounded" onClick={()=>{handleNewTermsModalClose()}}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={bulkDataModalShow} onHide={()=>{handleBulkDataModalClose()}} size="lg" centered>
                <Modal.Header>
                    <Modal.Title as="h2">Add Customers in Bulk</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{handleBulkDataModalClose()}}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row">
                            <div className='col-6 my-auto'>
                                <label className="fs-5 fw-bold my-2">Download Excel file template:</label>
                            </div>
                            <div className='col-6'>
                                <button className='btn btn-info btn-lg rounded' onClick={()=>{genTemplate()}}><i class="las la-file-download" style={{ "font-size": "32px" }}></i>Download</button>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-12'>
                                <label className="fs-5 fw-bold my-2 required">Select an Excel file with formatted data</label>
                                <Dropzone
                                    // getUploadParams={getUploadParamsExcel}
                                    onChangeStatus={handleChangeStatusExcel}
                                    styles={{ inputLabelWithFiles: { margin: '20px 3%' } }}
                                    maxFiles={1}
                                    maxSizeBytes={1024*1024*2}
                                    PreviewComponent={Preview}
                                    accept=".xlsx, .xls"
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    {
                        write == 1 &&
                        <>
                            {
                                bulkDataVerified?
                                <button className="btn btn-success rounded me-auto" data-kt-indicator={bulkDataSubmitButtonState?"on":"off"} disabled={bulkDataSubmitButtonState} onClick={()=>{bulkDataSubmit()}}>
                                    <span className="indicator-label">Submit</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button> 
                                :
                                <button className="btn btn-success rounded me-auto" data-kt-indicator={bulkDataVerifyButtonState?"on":"off"} disabled={bulkDataVerifyButtonState} onClick={()=>{bulkDataVerify()}}>
                                    <span className="indicator-label">Verify</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                            }
                        </>
                    }
                    <button className="btn btn-danger rounded" onClick={()=>{handleBulkDataModalClose()}}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={bulkErrorModalShow} onHide={()=>{setBulkErrorModalShow(false)}} size="xl" centered>
                <Modal.Header>
                    <Modal.Title as="h2">Data Table</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{setBulkErrorModalShow(false)}}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <MUIDataTable
                            title={"Customer Details"}
                            data={bulkDataExtracted}
                            columns={columns}
                            options={options}
                        />
                        {
                            errM.length > 0 ?
                            <>
                                <div className="text-danger fw-bolder fs-1 my-5">Errors</div>
                                <div className="">
                                    <b style={{ color: "#EA5A24" }}>*Download Latest Template for updated data and get rid of errors.</b>
                                    <br/>
                                    <small style={{ color: "#EA5A24" }}>**Use dropdown list in the Template file to avoid INVALID errors.</small>

                                    <ul>
                                        {
                                            errM.map((item, index) => {
                                                return ( <li>{errM[index]}</li> )
                                            })
                                        }
                                    </ul>
                                </div>
                            </>
                            :
                            <>
                                <div className="text-success fw-bolder fs-1 my-5">Success</div>
                                <div className=""><b>Close current Modal and click on Submit button to Upload All Customers</b></div>
                            </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer> 
                    <button className="btn btn-danger rounded" onClick={()=>{setBulkErrorModalShow(false)}}>Close</button>
                </Modal.Footer>
            </Modal>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org}/>
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer}/>
        </>
    );
}
export default Customer;