import BasePage from "./BasePage";

function App() {
  return (
  <>		
		<BasePage />
  </>
  );
}

export default App;
