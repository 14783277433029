import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link ,Redirect} from 'react-router-dom'
import moment from 'moment';

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

function ViewInvoiceDetails(props) {
    const [user, setUser] = useState({});
    const [organization_id, setOrganization_id] = useState("")
    const [organization_list, setorganization_list] = useState([]);
    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);
    const [state, setstate] = useState(2);

    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
	const [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);
    
    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    // const [taxList, setTaxList] = useState([])
    // const [currencyList, setCurrencyList] = useState([])

    const [invoice, setInvoice] = useState(null)

    const [invoiceName, setInvoiceName] = useState("")
    const [invoiceDate, setInvoiceDate] = useState("")
    const [dueDate, setDueDate] = useState("")
    const [customerName, setCustomerName] = useState("")
    const [issuedBy, setIssuedBy] = useState("")
    const [invoiceCurrencySymbol, setInvoiceCurrencySymbol] = useState("")
    const [itemArray, setItemArray] = useState([])
    const [adjustment, setAdjustment] = useState(null)
    const [tax, setTax] = useState(null)
    const [grandTotal, setGrandTotal] = useState("")
    const [paymentStatus, setPaymentStatus] = useState("")

    var page_name = "view-invoice-details";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true);
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    setRedirect_to_login(1);
                });
                
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
                            console.log(res.data.organization_name)
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if(temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else
                                    {
                                        temp = 2;
                                    }
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
							
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setRedirect_to_login(1);
                });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }

                // console.log(timer)
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);





    async function getInvoice() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/fetch-invoice-details`, { method: 'POST', body: JSON.stringify({ "email": user.email,"invoice_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.length > 0) {
            setInvoice(body[0]);
        }
        else{
            throw("no such invoice")
        }
    }
    async function getOrganizationDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-organization-information`, { method: 'POST', body: JSON.stringify({"page_name": page_name, "organization_id": organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        console.log(body)
        if (body.length != 0) {
            setIssuedBy(body.find(x=>x.organization_id == getCookie("organization_id")).organization_name)
        }
    }
    // async function getTaxes() {
    //     const response = await fetch(
    //         `${process.env.REACT_APP_SERVER_URL}/tax-group-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
    //     );
    //     const body = await response.json();
    //     // console.log(body)
    //     if (body.length != 0) {
    //         setTaxList(body)
    //     }
    // }
    // async function getCurrencies() {
    //     const response = await fetch(
    //         `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
    //     );
    //     const body = await response.json();
    //     // console.log(body)
    //     if (body.length != 0) {
    //         setCurrencyList(body)
    //     }
    // }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getInvoice();
            p1.then(() => {
                let p2 = getOrganizationDetails()
                // let p3 = getTaxes()
                // let p4 = getCurrencies()
                Promise.all([p2])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(4)
                    console.log(err)
                })
            })
            .catch((errx) => {
                if(errx == "no such invoice"){
                    setstate(3)
                    console.log(errx)
                }
                else{
                    setstate(4)
                    console.log(errx)
                }
            })
        }
    }, [user, visibility, read, write])
    

    useEffect(() => {
        if(invoice!=null){
            setInvoiceName(invoice.invoice_name)
            setInvoiceDate(invoice.invoice_date)
            setDueDate(invoice.due_date)
            setCustomerName(JSON.parse(invoice.customer_id).customer_name)
            setInvoiceCurrencySymbol(JSON.parse(invoice.invoice_currency).currency_symbol)
            setItemArray(JSON.parse(invoice.item_array))
            setAdjustment(JSON.parse(invoice.adjustment))
            setTax(invoice.tax==null?0:invoice.tax.tax_percentage)
            setGrandTotal(invoice.grand_total)
            setPaymentStatus(invoice.add_status)
        }

    }, [invoice])
    

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }} />
                </> :
                null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}

            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                            <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Invoice Details
                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                            </h1>
                        </div>
                    </div>
                </div>

                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div className="container" id="kt_content_container">
                        {
                            state == 1?
                                <div className="card">
                                    <div className="card-body p-lg-20">

                                        <div className="d-flex flex-column flex-xl-row">
                                            <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
                                                <div className="mt-n1">
                                                    <div className="d-flex flex-stack pb-10">
                                                        <a href="#"><img alt="Logo" width={"120em"} src="https://cdn-adocrm.s3.amazonaws.com/static/ado-crm-logo.png" /></a>
                                                    </div> 
                                                    <div className="m-0">
                                                        <div className="fw-bold fs-3 text-gray-800 mb-8">Invoice {invoiceName}</div>
                                                        <div className="row g-5 mb-11">
                                                            <div className="col-sm-6">
                                                                <div className="fw-semibold fs-7 text-gray-600 mb-1">Issue Date:</div>
                                                                <div className="fw-bold fs-6 text-gray-800">{moment(invoiceDate).format("DD MMM YYYY")}</div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="fw-semibold fs-7 text-gray-600 mb-1">Due Date:</div>
                                                                <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap">
                                                                    <span className="pe-2">{moment(dueDate).format("DD MMM YYYY")}</span>
                                                                    <span className="fs-7 text-danger d-flex align-items-center">
                                                                    <span className="bullet bullet-dot bg-danger me-2"></span>{(new Date(dueDate) < new Date()) ? "Over Due by " + Math.round(Math.abs(new Date() - new Date(dueDate)) / (1000 * 60 * 60 * 24)) + " days":"Due in " + Math.round(Math.abs(new Date(dueDate) - new Date()) / (1000 * 60 * 60 * 24)) + " days"}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row g-5 mb-12">
                                                            <div className="col-sm-6">
                                                                <div className="fw-semibold fs-7 text-gray-600 mb-1">Issue For:</div>
                                                                <div className="fw-bold fs-6 text-gray-800">{customerName}</div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="fw-semibold fs-7 text-gray-600 mb-1">Issued By:</div>
                                                                <div className="fw-bold fs-6 text-gray-800">{issuedBy}</div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div className="table-responsive border-bottom mb-9">
                                                                <table className="table mb-3">
                                                                    <thead>
                                                                        <tr className="border-bottom fs-6 fw-bold text-muted">
                                                                            <th className="min-w-175px pb-2">Item</th>
                                                                            <th className="min-w-70px text-end pb-2">Quantity</th>
                                                                            <th className="min-w-80px text-end pb-2">Rate</th>
                                                                            <th className="min-w-100px text-end pb-2">Amount</th>
                                                                            <th className="min-w-100px text-end pb-2">Discount</th>
                                                                            <th className="min-w-100px text-end pb-2">Tax</th>
                                                                            <th className="min-w-100px text-end pb-2">Total</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            itemArray.map((obj,indx)=>{
                                                                                return(
                                                                                    <tr className="fw-bold text-gray-700 fs-5 text-end">
                                                                                        <td className="d-flex align-items-center pt-6"><i className={`fa fa-genderless ${indx%3==0?"text-danger":indx%3==1?"text-success":indx%3==2?"text-primary":null} fs-2 me-2`}></i>{obj.item_name}</td>
                                                                                        <td className="pt-6">{obj.quantity}</td>
                                                                                        <td className="pt-6">{obj.rate}</td>
                                                                                        <td className="pt-6 text-dark fw-bolder">{obj.quantity * obj.rate}</td>
                                                                                        <td className="pt-6 text-dark fw-bolder">{obj.discount_unit}{obj.discount_type=="percentage" && "%"}</td>
                                                                                        <td className="pt-6 text-dark fw-bolder">{obj.tax_id==null?"0":obj.tax_percentage+"%"}</td>
                                                                                        <td className="pt-6 text-dark fw-bolder">{Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount"? obj.discount_unit : obj.quantity*obj.rate * obj.discount_unit / 100 ) + (obj.tax_id==null? 0 : obj.quantity*obj.rate * obj.tax_percentage/ 100)) *100)/100}</td>
                                                                                    </tr>            
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="d-flex justify-content-end">
                                                                <div className="mw-300px">
                                                                    <div className="d-flex flex-stack mb-3">
                                                                        <div className="fw-semibold pe-10 text-gray-600 fs-7">Subtotal:</div>
                                                                        <div className="text-end fw-bold fs-6 text-gray-800">{invoiceCurrencySymbol} {Math.round(itemArray.reduce((p,o)=>{return p + (Math.round(((o.quantity * o.rate) - (o.discount_type == "amount"? o.discount_unit : o.quantity*o.rate * o.discount_unit / 100 ) + (o.tax_id==null? 0 : o.quantity*o.rate * o.tax_percentage / 100)) *100)/100) },0) *100)/100 }</div>
                                                                    </div>
                                                                    <div className="d-flex flex-stack mb-3">
                                                                        <div className="fw-semibold pe-10 text-gray-600 fs-7">{adjustment.text}</div>
                                                                        <div className="text-end fw-bold fs-6 text-gray-800">{adjustment.value}</div>
                                                                    </div>
                                                                    <div className="d-flex flex-stack mb-3">
                                                                        <div className="fw-semibold pe-10 text-gray-600 fs-7">Tax</div>
                                                                        <div className="text-end fw-bold fs-6 text-gray-800">{tax} %</div>
                                                                    </div>
                                                                    <div className="d-flex flex-stack">
                                                                        <div className="fw-semibold pe-10 text-gray-600 fs-7">Grand Total</div>
                                                                        <div className="text-end fw-bold fs-6 text-gray-800">{invoiceCurrencySymbol} {grandTotal}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="m-0">
                                                <div className="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten">
                                                    <h6 className="mb-8 fw-bolder text-gray-600 fs-2 text-hover-primary">PAYMENT DETAILS</h6>
                                                    <div className="mb-6">
                                                        <div className="fw-semibold text-gray-600 fs-4">Status: <span className={`badge ${paymentStatus=="DRAFT"?"badge-secondary":paymentStatus=="INVOICED"?"badge-light-primary":paymentStatus=="PAID"?"badge-light-success":(paymentStatus=="VOID"||paymentStatus=="UNCOLLECTIBLE")?"badge-light-dark":null} ms-3 me-2`}>{paymentStatus}</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            :
                            state == 2?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                            :
                            state == 3?
                                <>
                                    <div className="flex-lg-row-fluid me-lg-15">
                                        <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                            <div className="card-body pt-0">
                                                <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                <div className="d-flex align-items-center p-3 mb-2"></div>

                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                    <div className="d-flex flex-stack flex-grow-1">
                                                        <div className="fw-bold">
                                                            <h4 className="text-gray-800 fw-bolder">No Such Invoice</h4>
                                                            <div className="fs-6 text-gray-600"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            :
                                <>
                                    <div className="flex-lg-row-fluid me-lg-15">
                                        <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                            <div className="card-body pt-0">
                                                <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                <div className="d-flex align-items-center p-3 mb-2"></div>

                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                    <div className="d-flex flex-stack flex-grow-1">
                                                        <div className="fw-bold">
                                                            <h4 className="text-gray-800 fw-bolder">Something went wrong. Please refresh this page</h4>
                                                            <div className="fs-6 text-gray-600"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }    
                    </div>
                </div>
            </div>

            <div className="modal fade my-4" id="kt_modal_offer_a_deal" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-800px">
                    <div style={{ borderRadius: '30px' }} className="modal-content">
                        <div className="modal-header py-0 d-flex justify-content-between">
                            <h2></h2>
                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>
                        <img id='inside_model_img' style={{ height: "500px", padding: '25px', marginTop: '0' }} />
                    </div>
                </div>
            </div>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org}/>
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer}/>
        </>
    );
}

export default ViewInvoiceDetails;
