import React, {lazy,Suspense,useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";
//import process.env from '../config.json';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';


function Signin() {
	
	const [RedirectState, setRedirectState] = useState(2);
	const [organization_id, setOrganization_id] = useState("");

	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [captchaToken, setCaptchaToken] = useState("");

	const [submitButtonState, setSubmitButtonState] = useState(false)
	
	function onChange(value) {
		setCaptchaToken(value)
	}
	
	function setCookie(cname, cvalue, exdays) {
	  var d = new Date();
	  d.setTime(d.getTime() + (exdays*24*60*60*1000));
	  var expires = "expires="+ d.toUTCString();
	  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function setCookie1(cname, cvalue, exdays) {
		let url = window.location.href;
		let domain = (new URL(url));
		domain = domain.hostname;
		domain = domain.replace('invoice','');
		// console.log("abc",domain)
		var d = new Date();
		d.setTime(d.getTime() + (exdays*24*60*60*1000));
		var expires = "expires="+ d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + `;domain=${domain}`;
	}
	
	function getCookie(cname) {
	  var name = cname + "=";
	  var ca = document.cookie.split(';');
	  for(var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
		  c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
		  return c.substring(name.length, c.length);
		}
	  }
	  return "";
	}
	
	const login = () => {
		
		var object = {};
		object.email = email;
		object.password = password;
		object.token = captchaToken;
		
		var exp=/^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if(object.email=="")
		{
			swal("Oops!", "Please enter an email id", "error");
			return false;
		}
		else if (!object.email.match(exp))
		{
			swal("Oops!", "Please enter a valid email id", "error");
			return false;
		}
		
		if(object.password=="")
		{
			swal("Oops!", "Please enter password.", "error");
			return false;
		}
		
		if(object.token=="")
		{
			swal("Oops!", "Please Verify the captcha", "error");
			return false;
		}
		else if(!isNaN(object.token))
		{
			swal("Oops!", "Please Verify the captcha", "error");
			return false;
		}
		
		setSubmitButtonState(true)
		
		var headers = {
			headers: {
			"Content-Type": "application/json",
			"Accept": "*/*"
			}
		}
		
		axios.post(`${process.env.REACT_APP_SERVER_URL}/login`,object, headers).then((res) => {
			if(res.data.operation == "success")
			{
				if(res.data.quick_set_up==0)
				{
					var object = {};
					var headers = {
						headers: {
							"Content-Type": "application/json",
							"token": res.data.token,
							"Accept": "*/*"
						}
					}
					axios.post(`${process.env.REACT_APP_SERVER_URL}/show-default-organization`, object, headers).then((res1) => {
						if (res1.data.length>0) {
							setCookie('organization_id', res1.data[0].organization_id, 1);
							//setCookie('aside_nav_flag',"My View", 1);
							//setCookie('organization_id',"5e99801e-c0e2-448c-b6f4-4f98fe2b87af", 1);
							setOrganization_id(res1.data[0].organization_id)
							setCookie('access_token', res.data.token, 1,)
							setRedirectState(1);
							onsubmit();
						}
					})
				}
				else
				{
					setCookie('access_token', res.data.token, 1)
					window.location.href = "/quick-set-up"
				}
			}
			else
			{
				if(res.data.message != "danger")
				{
					swal("Oops!", res.data.message, "error").then((res) => {
						window.location.reload();
					});
				}
				else
				{
					swal("Oops!", "Username or Password is wrong", "error").then((res) => {
						window.location.reload();
					});
				}
			}
		})
		.catch(function (error) {
			swal("Oops!", "something went wrong", "error").then((res) => {
				window.location.reload();
			});
		});
	}

	const onsubmit = () =>{		
		if(getCookie("lastpage") != "")
		{
			window.location.href = getCookie("lastpage");
		}
		else{
			window.location.href = "/dashboard"
		}
	}
	
	return (
		<>
			<div className="d-flex flex-column flex-root" id="kt_app_root">
				<div className="d-flex flex-column flex-lg-row flex-column-fluid">

					<div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
						<div className="d-flex flex-center flex-column flex-lg-row-fluid">
							<div className="w-lg-500px p-10">
									
									<div className="text-center mb-11">
										<h1 className="text-dark fw-bolder mb-3">Sign In</h1>
										<div className="text-gray-500 fw-semibold fs-6">ADOERP Invoice Module</div>
									</div>
									
									<div className="row g-3 mb-9">
										<div className="col-md-6">
											<a href="#" className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
											<img alt="Logo" src="assets/media/svg/brand-logos/google-icon.svg" className="h-15px me-3" />Sign in with Google</a>
										</div>
										
										<div className="col-md-6">
											<a href="#" className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
											<img alt="Logo" src="assets/media/svg/brand-logos/apple-black.svg" className="theme-light-show h-15px me-3" />
											<img alt="Logo" src="assets/media/svg/brand-logos/apple-black-dark.svg" className="theme-dark-show h-15px me-3" />Sign in with Apple</a>
										</div>
									</div>
									
									<div className="separator separator-content my-14">
										<span className="w-125px text-gray-500 fw-semibold fs-7">Or with email</span>
									</div>
									
									<div className="fv-row mb-8"><input type="text" placeholder="Email" autocomplete="off" className="form-control bg-transparent" value={email} onChange={(e)=>{setEmail(e.target.value)}} /></div>
									<div className="fv-row mb-3"><input type="password" placeholder="Password" autocomplete="off" className="form-control bg-transparent" value={password} onChange={(e)=>{setPassword(e.target.value)}} /></div>
									
									<div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
										<a href="/forgot-password-email" className="link-primary">Forgot Password ?</a>
									</div>
									
									<div className="d-grid mb-10">
										<div className="text-center">
											<ReCAPTCHA sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`} onChange={onChange} className="btn btn-lg" size="compact" />
											<br />
											<button type="button" onClick={() => {login()}} data-kt-indicator={submitButtonState?"on":"off"} disabled={submitButtonState} className="btn btn-lg btn-primary w-100 mb-5">
												<span className="indicator-label">Continue</span>
												<span className="indicator-progress">Please wait...
												<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
											</button>
										</div>
									</div>
									
									<div className="text-gray-500 text-center fw-semibold fs-6">Not a Member yet?
									<a href="/register" className="link-primary">Sign up</a></div>
								
							</div>
						</div>
						
						<div className="d-flex flex-center flex-wrap px-5">
							<div className="d-flex fw-semibold text-primary fs-base">
								<a href="#" className="px-5" target="_blank">Terms</a>
								<a href="#" className="px-5" target="_blank">Plans</a>
								<a href="#" className="px-5" target="_blank">Contact Us</a>
							</div>
						</div>
					</div>
					
					<div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{"background-image":"url(assets/media/misc/auth-bg.png)"}}>
						<div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
							
							<a href="#" className="mb-12">
								<img alt="Logo" src=" https://cdnstatic.adocrm.com/static/ado-crm-logo-light.png" className="h-75px" />
							</a>
							
							<img className="mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20" src="assets/media/misc/auth-screens.png" alt="" />
							<h1 className="text-white fs-2qx fw-bolder text-center mb-7">Fast, Efficient and Productive</h1>
							<div className="text-white fs-base text-center">In this kind of post,
							<a href="#" className="opacity-75-hover text-warning fw-bold me-1">the blogger</a>introduces a person they’ve interviewed
							<br />and provides some background information about
							<a href="#" className="opacity-75-hover text-warning fw-bold me-1">the interviewee</a>and their
							<br />work following this is a transcript of the interview.</div>
							
						</div>
					</div>
					
				</div>
			</div>
		</>
	);
}
export default Signin;