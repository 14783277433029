import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import uuid from 'react-uuid';
import moment from 'moment';
import { Modal } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import {MuiThemeProvider, createTheme} from '@material-ui/core';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import removeImg from './icons/remove.svg'
import Select from 'react-select';

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

// import SetColor from './SetColor.js';
// import country_arr from './countries-arr.js';
// import s_a from './states-arr.js';

const ShowEstimates = () => {
    const [user, setUser] = useState({});
    const [organization_id,setOrganization_id] = useState("")
    const [organization_list, setorganization_list] = useState([]);
    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);
    const [state, setstate] = useState(2);

    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
	const [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);

    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [data, setData] = useState([]);
    const [estimateList, setEstimateList] = useState([])

    const [editModalShow, setEditModalShow] = useState(false)

    const [customerList, setCustomerList] = useState([])
    const [itemList, setItemList] = useState([])
    const [salesPersonList, setSalesPersonList] = useState([])
    const [expenseProjectList, setExpenseProjectList] = useState([])
    const [taxList, setTaxList] = useState([])
    const [currencyList, setCurrencyList] = useState([])
    const [currrencyExchangeRates, setCurrrencyExchangeRates] = useState([])
    const [organizationDetails, setOrganizationDetails] = useState(null)

    const [estimateId, setEstimateId] = useState("")
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [estimateRef, setEstimateRef] = useState("")
    const [reference, setReference] = useState("")
    const [estimateDate, setEstimateDate] = useState("")
    const [expiryDate, setExpiryDate] = useState("")
    const [selectedSalesPerson, setSelectedSalesPerson] = useState(null)
    const [selectedExpenseProject, setSelectedExpenseProject] = useState(null)
    const [selectedEstimateCurrency, setSelectedEstimateCurrency] = useState(null)
    const [subject, setSubject] = useState("")
    const [adjustment, setAdjustment] = useState({text:"Adjustment", value:0})
    const [selectedTax, setSelectedTax] = useState(null)
    const [customerNotes, setCustomerNotes] = useState("")
    const [termsAndConditions, setTermsAndConditions] = useState("")

    const [itemArray, setItemArray] = useState([{item: null, quantity: 1, rate: 0, discount_unit: 0, discount_type: {label:"amount", value:"amount"}, tax: null, op_toggle: false}])
    const [subTotal, setSubTotal] = useState(0)

    const [oldFileName, setOldFileName] = useState(null);
    const [fileName, setFileName] = useState("");
    const [unique_id, setunique_id] = useState(uuid())

    const [editModalSubmitButtonState, setEditModalSubmitButtonState] = useState(false);

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    var page_name = "estimates-list";

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        filter: true,
        selectableRows: "none",
        fixedHeader: false,
        filterType: "dropdown",
        responsive: "vertical",
        tableBodyHeight: "480px",
        tableBodyMaxHeight: "",
        searchPlaceholder: 'Search an Estimate',
    };



    const columns = [
        {
            name: "estimate_date",
            label: "Estimate Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "estimate_ref",
            label: "Estimate#",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "reference",
            label: "Reference#",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "customer",
            label: "Customer Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "sales_person",
            label: "Sales Person",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "grand_total",
            label: "Grand Total",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "expiry_date",
            label: "Expiry Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Estimate Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let color
                    if (value == "DRAFT") {
                        color = "grey"
                    }
                    else if (value == "SENT") {
                        color = "blue"
                    }
                    else if (value == "INVOICED") {
                        color = "green"
                    }
                    else if (value == "EXPIRED") {
                        color = "red"
                    }

                    return (
                        <p
                            className='my-auto'
                            value={value}
                            onChange={event => updateValue(event.target.value, tableMeta.columnIndex)}
                            style={{ color: color }}>{value}
                        </p>
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true);
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    setRedirect_to_login(1);
                });
                
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if(temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else
                                    {
                                        temp = 2;
                                    }
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
							
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setRedirect_to_login(1);
                });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }

                // console.log(timer)
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);





    useEffect(() => {
        setSubTotal( Math.round(itemArray.reduce((p,o)=>{return p + o.quantity * o.rate - (o.discount_type.value == "amount"? o.discount_unit : o.quantity*o.rate * o.discount_unit / 100 ) + (o.tax==null? 0 : o.quantity*o.rate * taxList.find(x=>x.tax_group_id==o.tax.value).tax_group_percentage / 100) },0) *100)/100)
    }, [itemArray])
      
    useEffect(() => {
        if((selectedCustomer!=null) && (selectedExpenseProject!=null)){
            let t_proj = expenseProjectList.filter(x=>x.cid == selectedCustomer.value)
            if(t_proj.find(x=>x.project_id == selectedExpenseProject.value) == undefined){
                setSelectedExpenseProject(null)
            }
        }
    }, [selectedCustomer])
    
    useEffect(() => {
        if (timer > 0) {
            const timer_decrease = setInterval(() => {
                setTimer(timer - 1);
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        window.$('#kt_modal_timer').modal('show');
                    }
                }
            }, 1000);

            return () => clearInterval(timer_decrease);
        }
    }, [timer]);

    async function getEstimateDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/invoice-estimate-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length > 0) {
            setEstimateList(body);
        }
        else {
            throw new Error("no estimates")
        }
    }
    async function getCustomers() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/customer-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCustomerList(body)
        }
    }
    async function getSalesPersons() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/salesperson-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setSalesPersonList(body)
        }
    }
    async function getItems() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/product-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setItemList(body)
        }
    }
    async function getTaxes() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/tax-group-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setTaxList(body)
        }
    }
    async function getExpenseProjects() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/project-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setExpenseProjectList(body)
        }
    }
    async function getCurrencies() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCurrencyList(body)
        }
    }
    async function getCurrencyExchangeRates() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-live-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCurrrencyExchangeRates(body)
        }
    }
    async function getOrganizationDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/show-organization`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setOrganizationDetails(body.find(x=>x.organization_id == getCookie("organization_id")))
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getEstimateDetails();
            p1.then(() => {
                let p2 = getCustomers()
                let p3 = getSalesPersons()
                let p4 = getExpenseProjects()
                let p5 = getItems()
                let p6 = getTaxes()
                let p7 = getCurrencies()
                let p8 = getCurrencyExchangeRates()
                let p9 = getOrganizationDetails()
                Promise.all([p2,p3,p4,p5,p6,p7,p8,p9])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(4)
                    console.log(err)
                })
            })
            .catch((errx) => {
                setstate(3)
                console.log(errx)
            })
        }
    }, [user, visibility, read, write])

    const convertCurrencyAmount = (p_curr, amt) =>{
        let pc_code = currencyList.find(x=>x.currency_id==p_curr).currency_code
        
        let c_rate = (selectedEstimateCurrency.label=="USD")?1:currrencyExchangeRates.data.quotes["USD"+selectedEstimateCurrency.label]
        let c2_rate = (pc_code=="USD")?1:currrencyExchangeRates.data.quotes["USD"+pc_code]

        return Math.round(amt * (c_rate/c2_rate) *100)/100
    }

    useEffect(() => {

        if (estimateList.length > 0) {
            let temp_data = []

            estimateList.forEach((obj) => {
                let temp_obj = {};

                temp_obj.estimate_date = moment(obj.estimate_date).format("DD-MM-YYYY");
                temp_obj.estimate_ref = obj.estimate_name
                temp_obj.reference = obj.reference;
                temp_obj.customer = JSON.parse(obj.customer_id).customer_name
                temp_obj.sales_person = (obj.sales_person_id==null)?null:JSON.parse(obj.sales_person_id).sales_person_name
                temp_obj.grand_total = JSON.parse(obj.estimate_currency).currency_symbol + " " + obj.grand_total;
                temp_obj.expiry_date = moment(obj.expiry_date).format("DD-MM-YYYY");
                temp_obj.status = obj.add_status;

                let id = obj.estimate_id;

                temp_obj.actions = <div className="dropdown">
                    <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-bars px-0"></i></button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a href="#" onClick={(e) => { editModalInit(id) }} className="dropdown-item">View/Edit Details</a></li>
                        {write == 1 && obj.add_status != "INVOICED" && <li><Link className="dropdown-item" to={`/add-invoice/${id}`}>Convert to Invoice</Link></li>}
                    </ul>
                </div>

                temp_data.push(temp_obj)
            })

            setData(temp_data);
        }

    }, [estimateList])

    const editModalInit = (id) => {
        let est_obj = estimateList.find(x=>x.estimate_id==id)

        setEstimateId(est_obj.estimate_id)

        let t1 = JSON.parse(est_obj.customer_id)
        setSelectedCustomer({label:t1.customer_name, value:t1.customer_id})
        
        setEstimateRef(est_obj.estimate_name)
        setReference(est_obj.reference)
        setEstimateDate(moment(est_obj.estimate_date).format('YYYY-MM-DD'))
        setExpiryDate(moment(est_obj.expiry_date).format('YYYY-MM-DD'))

        let t2 = est_obj.sales_person_id==null?null:JSON.parse(est_obj.sales_person_id)
        setSelectedSalesPerson(est_obj.sales_person_id==null?null:{label:t2.sales_person_name, value:t2.sales_person_id})

        let t3 = est_obj.project_id==null?null:JSON.parse(est_obj.project_id)
        setSelectedExpenseProject(est_obj.project_id==null?null:{label:t3.expense_project_name, value:t3.expense_project_id})

        setSubject(est_obj.subject)
        setAdjustment(JSON.parse(est_obj.adjustment))

        let t4 = est_obj.tax==null?null:JSON.parse(est_obj.tax)
        setSelectedTax(est_obj.tax==null?null:{label:t4.tax_name, value:t4.tax_id})

        setCustomerNotes(est_obj.customer_notes)
        setTermsAndConditions(est_obj.terms_and_conditions)

        let temp = JSON.parse(est_obj.item_array)
        let temp2 = []
        temp.forEach((obj)=>{
            let tobj = {}
            
            tobj.item = {label: obj.item_name, value: obj.item_id} 
            tobj.quantity = obj.quantity
            tobj.rate = obj.rate
            tobj.discount_unit = obj.discount_unit
            tobj.discount_type = {label:obj.discount_type, value:obj.discount_type}
            tobj.tax = (obj.tax_id==null)?null:{label:obj.tax_name + ` (${obj.tax_percentage}%)`, value:obj.tax_id}
            tobj.op_toggle = false

            temp2.push(tobj)
        })
        setItemArray(temp2)

        setOldFileName(est_obj.original_file_name==null?null:{original_file_name:est_obj.original_file_name, file_name:est_obj.file_name, file_id:est_obj.file_id})
        setEditModalShow(true)
    }

    const documentDelete = (folder, fileName, id) => {
        
        var object = {};
        object.folder = folder
        object.file_name = fileName
        object.id = id

        object.organization_id = organization_id
        object.page_name = page_name;

        console.log(object)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/delete-invoice-estimate-documents`, object, headers).then((res) => {
            if (res.data.operation === "success") {
                swal("Great!", "Document deleted successfully!", "success")
                setOldFileName(null)
                getEstimateDetails()
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const editModalSubmit = () => {
        if(selectedCustomer==null)
        {
            swal("Oops!", "Customer cannot be empty", "warning");
            return
        }
        if(estimateRef.trim()=="")
        {
            swal("Oops!", "Estimate Name cannot be empty", "warning");
            return
        }
        let reg = /^[a-zA-Z_]{1,10}\-\d{1,6}$/gm
        if(!reg.test(estimateRef))
        {
            swal("Oops!", "Estimate Name not valid. i.e. EST-000001", "warning");
            return false
        }
        if(estimateDate=="")
        {
            swal("Oops!", "Estimate Date cannot be empty", "warning");
            return
        }
        for(let o of itemArray)
        {
            if(o.item==null){
                swal("Oops!", "Estimate item cannot be empty", "warning");
                return
            }
        }

        var object = {};
        
        object.estimate_id = estimateId
        object.customer = {customer_id: selectedCustomer.value, customer_name: selectedCustomer.label}
        object.estimate_name = estimateRef.trim()
        object.reference = reference
        object.estimate_date = estimateDate
        object.expiry_date = expiryDate
        object.project = (selectedExpenseProject==null)?undefined:{expense_project_name:selectedExpenseProject.label, expense_project_id:selectedExpenseProject.value}
        object.sales_person = (selectedSalesPerson==null)?undefined:{sales_person_name:selectedSalesPerson.label, sales_person_id:selectedSalesPerson.value}

        let org_c_code = currencyList.find(x=>x.currency_id==organizationDetails.currency).currency_code 
        let cust_c_code = JSON.parse(customerList.find(x=>x.cid==selectedCustomer.value).currency).currency_code
        
        let org_c_rate = (org_c_code=="USD")?1:currrencyExchangeRates.data.quotes["USD"+org_c_code]
        let cust_c_rate = (cust_c_code=="USD")?1:currrencyExchangeRates.data.quotes["USD"+cust_c_code]
        let est_c_rate = (selectedEstimateCurrency.label=="USD")?1:currrencyExchangeRates.data.quotes["USD"+selectedEstimateCurrency.label]

        let exchange_rate ={
            organization:{base_curr:"USD" ,quote_curr: org_c_code, rate: org_c_rate},
            customer:{base_curr:"USD" ,quote_curr: cust_c_code, rate: cust_c_rate},
            estimate:{base_curr:"USD" ,quote_curr: selectedEstimateCurrency.label, rate: est_c_rate},
        }

        let ic = currencyList.find(x=>x.currency_id==selectedEstimateCurrency.value)
        object.estimate_currency = {currency_id:ic.currency_id, currency_symbol:ic.currency_symbol, currency_code:ic.currency_code, format:ic.format, decimal_place:ic.decimal_place, exchange_rate: exchange_rate}

        object.subject = subject

        let temp = []
        itemArray.forEach((obj)=>{
            let tobj = {}
            tobj.item_name = obj.item.label
            tobj.item_id = obj.item.value
            tobj.quantity = obj.quantity
            tobj.rate = obj.rate
            tobj.discount_unit = obj.discount_unit
            tobj.discount_type = obj.discount_type.value
            tobj.tax_id = (obj.tax==null)?null:obj.tax.value
            tobj.tax_name = (obj.tax==null)?null:taxList.find(x=>obj.tax).tax_group_name
            tobj.tax_percentage = (obj.tax==null)?null:taxList.find(x=>obj.tax).tax_group_percentage

            temp.push(tobj)
        })
        object.item_array = temp

        object.adjustment = adjustment
        object.tax = (selectedTax==null)?undefined:{tax_id:selectedTax.value, tax_name:taxList.find(x=>selectedTax.value).tax_group_name, tax_percentage:taxList.find(x=>selectedTax.value).tax_group_percentage}
        object.grand_total = subTotal + adjustment.value + (subTotal * (selectedTax==null?0:parseFloat(taxList.find(x=>x.tax_group_id==selectedTax.value).tax_group_percentage)) / 100)
        object.customer_notes = customerNotes
        object.terms_and_conditions = termsAndConditions

        object.file_name = fileName
        object.unique_id = unique_id

        object.organization_id = organization_id
		object.page_name = page_name;

        console.log(object);
        setEditModalSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-invoice-estimate`, object, headers).then((res) => {
            setEditModalSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Estimate updated successfully!", "success").then((value) => {
                    handleEditModalClose()
                    getEstimateDetails()
                });
            }
            else if(res.data.operation === "same_invoice_estimate_reference") {
                swal("Oops!", "This Estimate Reference already exists!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const handleEditModalClose = () => {
        setEditModalShow(false)

        setEstimateId("")
        setSelectedCustomer(null)
        setEstimateRef("")
        setReference("")
        setEstimateDate("")
        setExpiryDate("")
        setSelectedSalesPerson(null)
        setSelectedExpenseProject(null)
        setSelectedEstimateCurrency(null)
        setSubject("")
        setAdjustment({text:"Adjustment", value:0})
        setSelectedTax(null)
        setCustomerNotes("")
        setTermsAndConditions("")

        setItemArray([{item: null, quantity: 1, rate: 0, discount_unit: 0, discount_type: {label:"amount", value:"amount"}, tax: null, op_toggle: false}])
        setSubTotal(0)

        setFileName("")
        setOldFileName(null)

        setunique_id(uuid())
    }

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        return (
            <div className="preview-box">
                <img src={previewUrl} />
                <div className='d-flex flex-column align-items-center'>
                    <span className="name" title={name}>{name.length>20?name.substring(0,10) +"..."+ name.substring(name.length-10):name}</span>
                    <span className="status text-nowrap">{(status === "done") ? "Successfully Uploaded" : status}</span>
                    {status != "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" style={{ backgroundImage: `url(${removeImg})` }} onClick={remove} />}
                    {status == "done" && <span className="dzu-previewButton remove" style={{ backgroundImage: `url(${removeImg})` }} onClick={remove} />}
                </div>
            </div>
        )
    }

    const getUploadParams = ({ file }) => {
        const body = new FormData()
        body.append('dataFiles', file)
        body.append('unique_id', unique_id)
        body.append('user_id', user.email)
		body.append('page_name', page_name)
		
        // console.log(body);
        setEditModalSubmitButtonState(true)
        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        return { url: `${process.env.REACT_APP_SERVER_URL}/upload-invoice-estimate-document`, headers, body }
    }

    const handleChangeStatus2 = ({ meta }, status, allFiles) => {
        const { name } = meta;
        
        if (status === 'done') {
            setEditModalSubmitButtonState(false)
            setFileName(name)
        }
        else if (status === 'removed') {
            setEditModalSubmitButtonState(false)
            let temp = [...fileName] 
            temp.splice(temp.indexOf(name), 1)
            setFileName(temp)
        }
        else if(status === "error_file_size")
        {
            allFiles[0].remove()
            swal("Oops!", "File size exceeded", "warning");
        }
    };

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }

            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Show Estimates</h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">

                        {
                            state == 2 ?
                            <div className="card">
                                <div className="card-body pt-6">
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                </div>
                            </div>
                            :
                            state == 1 ?
                            <div className="card">
                                <div className="card-body pt-6">
                                    <MuiThemeProvider theme={createTheme({palette: { type:userTheme }})}>
                                        <MUIDataTable
                                            title={"All Estimates"}
                                            data={data}
                                            columns={columns}
                                            options={options}
                                        />
                                    </MuiThemeProvider>
                                </div>
                            </div>
                            :
                            state == 3 ?
                            <div className="card-body pt-0">
                                <div className="notice bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                    <h4 className="text-gray-800 fw-bolder">Create a new Estimate. All added estimates can be managed from here</h4>
                                    <div className='mt-10 text-center'>
                                        <Link className='btn btn-success rounded' to={`/add-estimtates`}>Create an Estimate</Link>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="card-body pt-0">
                                <div className="notice bg-light-danger rounded border-primary border border-dashed rounded-3 p-6">
                                    <h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
                                    <div className='mt-10 text-center'>
                                        <button className='btn btn-success rounded' onClick={() => { window.location.reload() }}>Reload the page</button>
                                    </div>
                                </div>
                            </div>
                        }
                            
                    </div>
                </div>
            </div>


            <Modal show={editModalShow} onHide={() => { handleEditModalClose() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Edit Estimate</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleEditModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>

                        <div className="row mb-5">
                            <div className="col-md-6 mb-2">
                                <label className="required fs-5 fw-bold mb-2">Customer Name</label>
                                <Select
                                    options={ customerList.map(x=>{return {label:x.customer_display_name, value:x.cid} }) }
                                    onChange={(val) => {setSelectedCustomer(val)}}        
                                    value={selectedCustomer}
                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                />
                            </div>
                            <div className="col-md-6">
                                <label className="required fs-5 fw-bold mb-2">Estimate#</label>
                                <input type="text" className="form-control form-control-solid" value={estimateRef} onChange={(e)=>{setEstimateRef(e.target.value)}}/>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 mb-2">
                                <label className="fs-5 fw-bold mb-2">Reference#</label>
                                <input type="text" className="form-control form-control-solid" value={reference} onChange={(e)=>{setReference(e.target.value)}}/>
                            </div>
                            <div className="col-md-6">
                                <label className="required fs-5 fw-bold mb-2">Estimate Date</label>
                                <input type="date" className="form-control form-control-solid" value={estimateDate} onChange={(e)=>{setEstimateDate(e.target.value)}}/>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6">
                                <label className="fs-5 fw-bold mb-2">Expiry Date</label>
                                <input type="date" className="form-control form-control-solid" value={expiryDate} onChange={(e)=>{setExpiryDate(e.target.value)}}/>
                            </div>
                            <div className="col-md-6 mb-2">
                                <label className="fs-5 fw-bold mb-2">Sales Person</label>
                                <Select
                                    options={ salesPersonList.map(x=>{return {label:x.name, value:x.sid} }) }
                                    onChange={(val) => {setSelectedSalesPerson(val)}}       
                                    isClearable 
                                    value={selectedSalesPerson}
                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 mb-2">
                                <label className="fs-5 fw-bold mb-2">Project</label>
                                <Select
                                    options={ expenseProjectList.filter(x=>selectedCustomer!=null && x.cid==selectedCustomer.value).map(x=>{return {label:x.project_name,value:x.project_id} }) }
                                    onChange={(val) => {setSelectedExpenseProject(val)}}        
                                    value={selectedExpenseProject}
                                    isClearable
                                    isDisabled={selectedCustomer==null}
                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                />
                                {selectedCustomer==null && <p className='m-0'>Select a customer to associate a project</p>}
                            </div>
                            <div className="col-md-6">
                                <label className="fs-5 fw-bold mb-2 required">Estimate Currency</label>
                                <Select
                                    options={ currencyList.map(x=>{return {label:x.currency_code,value:x.currency_id} }) }
                                    onChange={(val) => {setSelectedEstimateCurrency(val)}}        
                                    value={selectedEstimateCurrency}
                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12">
                                <label className="fs-5 fw-bold mb-2">Subject</label>
                                <textarea className="form-control form-control-solid fs-6" rows="3" value={subject} onChange={(e) => { setSubject(e.target.value) }} placeholder={"Let your customer know what this Estimate is for"} ></textarea>
                            </div>
                        </div>
                        <hr/>

                        <div className='w-100' style={{overflowX:"auto"}}> 
                            <div className='container p-0' style={{minWidth:"max-content"}}>
                                <div className="row mb-5">
                                    <div className="col-3"><p className="fs-5 text-center">Item</p></div>
                                    <div className="col-1"><p className="fs-5 text-center">Quantity</p></div>
                                    <div className="col-1"><p className="fs-5 text-center">Rate</p></div>
                                    <div className="col-1"><p className="fs-5 text-center">Amount</p></div>
                                    <div className="col-2"><p className="fs-5 text-center">Discount</p></div>
                                    <div className="col-2"><p className="fs-5 text-center">Tax</p></div>
                                    <div className="col-1"><p className="fs-5 text-center">Total</p></div>
                                    <div className="col-1"></div>
                                </div>
                                {
                                    itemArray.map((obj,indx,arr)=>{
                                        return(
                                            <div className="row mb-5">
                                                <div className="col-3 mb-1 ">
                                                    <Select
                                                        options={ itemList.map(x=>{return {label:x.product_name, value:x.pid} }) }      
                                                        onChange={(val) => {
                                                            let temp = itemArray.map(a=>{return{...a}})
                                                            temp[indx].item = val

                                                            let tp = itemList.find(x=>x.pid==val.value)
                                                            temp[indx].rate = tp.product_price==null?0:tp.product_price
                                                            if(tp.product_taxable!="No"){
                                                                if(tp.product_tax==null){
                                                                    temp[indx].tax = null
                                                                }
                                                                else{
                                                                    let temp_tax = taxList.find(obj=>obj.tax_group_id==tp.product_tax)
                                                                    temp[indx].tax = {label:temp_tax.tax_group_name + ` (${temp_tax.tax_group_percentage}%)`, value:temp_tax.tax_group_id}    
                                                                }
                                                            }
                                                            else{
                                                                temp[indx].tax = null
                                                            }
                                                            setItemArray(temp)
                                                        }}        
                                                        value={itemArray[indx].item}
                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                    />
                                                </div>
                                                <div className="col-1 mb-1">
                                                    <input type="number" className="form-control form-control-solid " value={itemArray[indx].quantity} 
                                                        onChange={(e) => {
                                                            if(parseFloat(e.target.value)>=0){
                                                                let temp = itemArray.map(a=>{return{...a}})
                                                                temp[indx].quantity = parseFloat(e.target.value)
                                                                setItemArray(temp)
                                                            }
                                                        }}
                                                    />                                                                        
                                                </div>
                                                <div className="col-1 mb-1">
                                                    <input type="number" className="form-control form-control-solid " value={itemArray[indx].rate} 
                                                        onChange={(e) => {
                                                            if(parseFloat(e.target.value)>=0){
                                                                let temp = itemArray.map(a=>{return{...a}})
                                                                temp[indx].rate = parseFloat(e.target.value)
                                                                setItemArray(temp)
                                                            }
                                                        }}
                                                    />                                                                        
                                                </div>
                                                <div className="col-1 mb-1">
                                                    <input type="number" className="form-control form-control-solid " readOnly value={itemArray[indx].quantity * itemArray[indx].rate}/>
                                                </div>
                                                <div className='col-1 pe-0'>
                                                    <input type="number" className="form-control form-control-solid " value={itemArray[indx].discount_unit} 
                                                        onChange={(e) => {
                                                            if(parseFloat(e.target.value)>=0){
                                                                let temp = itemArray.map(a=>{return{...a}})
                                                                temp[indx].discount_unit = parseFloat(e.target.value)
                                                                setItemArray(temp)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-1 ps-0'>
                                                    <Select
                                                        options={ [{label:"amount", value:"amount"}, {label:"%", value:"percentage"}] }      
                                                        onChange={(val) => {
                                                            let temp = itemArray.map(a=>{return{...a}})
                                                            temp[indx].discount_type = val
                                                            setItemArray(temp)
                                                        }}         
                                                        value={itemArray[indx].discount_type}
                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                    />
                                                </div>
                                                <div className='col-2 ps-0'>
                                                    <Select
                                                        options={ taxList.map(x=>{return {label:x.tax_group_name + ` (${x.tax_group_percentage}%)`, value:x.tax_group_id} }) }
                                                        onChange={(val) => {
                                                            let temp = itemArray.map(a=>{return{...a}})
                                                            temp[indx].tax = val
                                                            setItemArray(temp)
                                                        }} 
                                                        isClearable
                                                        value={itemArray[indx].tax}
                                                        isDisabled={obj.item!=null && itemList.find(x=>x.pid==obj.item.value).product_taxable=="No"}
                                                        styles={userTheme=='dark'?dropdownStyleDark:{}}
                                                    />
                                                </div>
                                                <div className="col-1 mb-1">
                                                    <input type="number" className="form-control form-control-solid " readOnly value={ (itemArray[indx].quantity * itemArray[indx].rate) - (itemArray[indx].discount_type.value == "amount"? itemArray[indx].discount_unit : itemArray[indx].quantity*itemArray[indx].rate * itemArray[indx].discount_unit / 100 ) + (itemArray[indx].tax==null? 0 : itemArray[indx].quantity*itemArray[indx].rate * taxList.find(x=>x.tax_group_id==itemArray[indx].tax.value).tax_group_percentage / 100)}/>
                                                </div>
                                                {
                                                    arr.length>1 &&
                                                    <div className="col-1 mb-1 position-relative">
                                                        <button className='btn btn-sm btn-outline-info rounded-pill border border-info fw-bolder' 
                                                            onClick={()=>{
                                                                let temp = itemArray.map(a=>{return{...a}})
                                                                temp[indx].op_toggle = !temp[indx].op_toggle
                                                                setItemArray(temp)
                                                            }}
                                                        >&#x2630;</button>
                                                        {
                                                            itemArray[indx].op_toggle &&
                                                            <div className='position-absolute' style={{top:"-40px", left:"-30px", width:"max-content"}}>
                                                                <button className='btn btn-sm btn-secondary fw-bolder border border-info fs-5' style={{borderRadius:"50% 0 0 50%", padding:"6px 15px"}} 
                                                                    onClick={()=>{
                                                                        let temp = itemArray.map(a=>{return{...a}})
                                                                        temp.splice(indx,1)
                                                                        setItemArray(temp)
                                                                    }}
                                                                >&#10006;</button>
                                                                <button className='btn btn-sm btn-secondary fw-bolder border-top border-bottom border-info fs-3' style={{padding:"4px 10px"}} 
                                                                    onClick={()=>{
                                                                        let temp = itemArray.map(a=>{return{...a}})
                                                                        temp[indx].op_toggle = false
                                                                        temp.splice(indx,0,temp[indx])
                                                                        setItemArray(temp)
                                                                    }}
                                                                >&#10063;</button>
                                                                <button className='btn btn-sm btn-secondary fw-bolder border border-info fs-5' style={{borderRadius:"0 50% 50% 0", padding:"6px 15px"}} 
                                                                    onClick={()=>{
                                                                        let temp = itemArray.map(a=>{return{...a}})
                                                                        if(indx==0){
                                                                            temp[indx].op_toggle = false
                                                                        }
                                                                        else{
                                                                            let t = itemArray[indx]
                                                                            temp.splice(indx,1)
                                                                            temp.splice(indx-1,0,t)
                                                                        }
                                                                        setItemArray(temp)
                                                                    }}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12" height="12" viewBox="0 0 512.000000 512.000000">
                                                                        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                                                                            <path d="M2475 5090 c-82 -9 -181 -44 -253 -88 -73 -44 -2015 -1625 -2063 -1679 -54 -60 -99 -137 -127 -215 -34 -96 -42 -271 -18 -368 51 -204 209 -377 408 -447 106 -38 264 -44 371 -14 43 12 104 34 135 50 63 32 76 42 977 776 351 286 643 520 649 519 6 -1 342 -270 746 -600 840 -684 838 -683 915 -718 123 -56 276 -73 411 -46 221 44 421 238 479 465 19 76 19 231 0 314 -21 87 -84 204 -147 273 -51 54 -1958 1613 -2049 1675 -122 81 -284 120 -434 103z"/>
                                                                            <path d="M2480 2870 c-64 -7 -165 -37 -225 -67 -52 -26 -115 -77 -1108 -886 -671 -547 -981 -806 -1012 -844 -54 -70 -101 -165 -120 -246 -19 -81 -19 -237 0 -312 56 -221 245 -408 465 -459 151 -36 329 -12 463 62 40 22 377 290 838 666 l770 628 42 -33 c23 -18 368 -299 767 -624 399 -325 745 -604 770 -621 101 -69 222 -106 355 -107 113 -1 177 14 285 65 160 76 290 237 335 413 18 69 20 221 4 297 -19 91 -91 227 -156 294 -56 57 -1935 1592 -2038 1665 -122 86 -277 125 -435 109z"/>
                                                                        </g>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        )
                                    })
                                }
                                <div className="row mb-5">
                                    <div className="col-3">
                                        <button className='btn btn-primary rounded fs-7' onClick={()=>{setItemArray([...itemArray, {item: null, quantity: 1, rate: 0, discount_unit: 0, discount_type: {label:"amount", value:"amount"}, tax: null, op_toggle: false}]) }}>&#10011; Add a row</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='row'>
                            <div className="col-md-3"></div>
                            <div className='col-md-3 d-flex align-items-center'>
                                <p className='m-0 fs-5'>Sub Total</p>
                            </div>
                            <div className="col-md-3"></div>
                            <div className='col-md-3 my-2'>
                                <input type="text" className="form-control form-control-solid" value={subTotal} readOnly/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-3"></div>
                            <div className="col-md-3 my-2">
                                <input type="text" className="form-control form-control-solid" value={adjustment.text} onChange={(e)=>{setAdjustment({...adjustment, text: e.target.value})}}/>
                            </div>
                            <div className="col-md-3"></div>
                            <div className='col-md-3 my-2'>
                                <input type="number" className="form-control form-control-solid" value={adjustment.value} onChange={(e)=>{setAdjustment({...adjustment, value: parseFloat(e.target.value)})}}/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-3"></div>
                            <div className='col-md-3 d-flex align-items-center'>
                                <p className='m-0 fs-5'>Tax added</p>
                            </div>
                            <div className='col-md-3 my-2'>
                                <Select
                                    options={ taxList.map(x=>{return {label:x.tax_group_name + ` (${x.tax_group_percentage}%)`, value:x.tax_group_id} }) }
                                    onChange={(val) => {setSelectedTax(val)}}        
                                    isClearable
                                    value={selectedTax}
                                    styles={userTheme=='dark'?dropdownStyleDark:{}}
                                />
                            </div>
                            <div className='col-md-3 my-2'>
                                <input type="text" className="form-control form-control-solid" value={subTotal * (selectedTax==null?0:parseFloat(taxList.find(x=>x.tax_group_id==selectedTax.value).tax_group_percentage)) / 100} readOnly/>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-3"></div>
                            <div className='col-md-3 mt-4 d-flex align-items-center'>
                                <h4 className='m-0'>Grand Total</h4>
                            </div>
                            <div className="col-md-3"></div>
                            <div className='col-md-3 mt-4 d-flex align-items-center'>
                                <h3>{currencyList.length>0 && selectedEstimateCurrency!=null && currencyList.find(x=>x.currency_id == selectedEstimateCurrency.value).currency_symbol} {subTotal + adjustment.value + (subTotal * (selectedTax==null?0:parseFloat(taxList.find(x=>x.tax_group_id==selectedTax.value).tax_group_percentage)) / 100)}</h3>
                            </div>
                        </div>
                        <hr/>

                        <div className="row mb-5">
                            <div className="col-md-6 my-2">
                                <label className="fs-5 fw-bold mb-2">Customer Notes</label>
                                <textarea className="form-control form-control-solid" rows="3" value={customerNotes} onChange={(e) => {setCustomerNotes(e.target.value)}} placeholder={"Looking forward for your business."}></textarea>
                            </div>
                            <div className="col-md-6 my-auto">
                                {
                                    oldFileName != null?
                                        <div className='text-center text-primary fw-bold fs-3'>
                                            Remove current document to upload new file
                                        </div>
                                    :
                                    write == 1 &&
                                    <div>
                                        <label className="fs-5 fw-bold my-2">Upload a single file</label>
                                        <Dropzone
                                            getUploadParams={getUploadParams}
                                            onChangeStatus={handleChangeStatus2}
                                            styles={{ inputLabelWithFiles: { margin: '20px 3%' } }}
                                            maxFiles={1}
                                            maxSizeBytes={1024*1024*2}
                                            PreviewComponent={Preview}
                                            accept="image/*,.pdf"
                                        />
                                    </div>
                                } 
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 my-2">
                                <label className="fs-5 fw-bold mb-2">Terms and Conditions</label>
                                <textarea className="form-control form-control-solid" rows="3" value={termsAndConditions} onChange={(e) => {setTermsAndConditions(e.target.value)}} placeholder={"Enter the terms and conditions of your business to be displayed in your transaction"}></textarea>
                            </div>
                            <div className='col-6'>
                                {
                                    oldFileName == null ? <div className='text-center pt-20 fs-4 text-gray-600'>No file uploaded previously</div>
                                    :
                                    <div className="col-12 text-center my-auto">
                                        <div className='mb-2 border border-dark rounded fs-6' title={oldFileName.original_file_name}>{oldFileName.original_file_name.length>20?oldFileName.original_file_name.substring(0,10) +"..."+ oldFileName.original_file_name.substring(oldFileName.original_file_name.length-10):oldFileName.original_file_name}</div>
                                        {
                                            write == 1 &&
                                            <button className='btn btn-secondary btn-sm btn-labeled rounded' 
                                                onClick={()=>{
                                                    swal({
                                                        title: "Are you sure?",
                                                        text: "Once deleted, you will not be able to recover this file!",
                                                        icon: "warning",
                                                        buttons: true,
                                                        dangerMode: true,
                                                    })
                                                    .then((willDelete) => {
                                                        if (willDelete) {
                                                            documentDelete("invoice_estimate_documents", oldFileName.file_name, oldFileName.file_id)
                                                        }
                                                    })
                                                }}
                                            >&#10006;</button>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        write == 1 &&
                        <button className="btn btn-success rounded me-auto" data-kt-indicator={editModalSubmitButtonState ? "on" : "off"} disabled={editModalSubmitButtonState} onClick={() => { editModalSubmit() }}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                    <button className='btn btn-danger text-white rounded mt-15 ms-10' 
                        onClick={()=>{
                            var object = {};
                            object.page_name = page_name;

                            console.log(object);
                            
                            var headers = {
                                headers: {
                                    "Content-Type": "application/json",
                                    "Accept": "*/*",
                                    "token": getCookie("access_token")
                                }
                            }
                            axios.post(`${process.env.REACT_APP_SERVER_URL}/delete-invoice-estimate-documents`, object, headers).then((res) => {
                                if (res.data.operation === "success") {
                                    swal("Great!", "Document deleted successfully!", "success")
                                }
                                else {
                                    swal("Oops!", "Something went wrong!", "error");
                                }
                            })
                            .catch(function (error) {
                                console.log(error)
                            });
                        }}
                    >Click Test</button>
                    <button className="btn btn-danger rounded" onClick={() => { handleEditModalClose() }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org}/>
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer}/>
        </>
    );

};

export default ShowEstimates;
