import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import uuid from 'react-uuid';
import moment from 'moment';

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import { Link, Redirect } from 'react-router-dom';



function AccountSetting() {
	const [accessToken, setaccessToken] = useState('');
	let [user, setUser] = useState({});
	let [state, setstate] = useState(2);
	const [SubscriptionDetails, setSubscriptionDetails] = useState([]);
	let [first_name, setfirst_name] = useState("");
	let [last_name, setlast_name] = useState("");
	let [country, setcountry] = useState("");
	let [phn, setphn] = useState("");
	let [timezone, settimezone] = useState("");
	let [email, setemail] = useState("");
	let [phone, setphone] = useState("");
	const [notification_content, setnotification_content] = useState([]);
	const [notification_content_detils, setnotification_content_details] = useState([]);
	const [AddressDetails, setAddressDetails] = useState([]);
	let [image, setImage] = useState("");
	let [file, setfile] = useState([]);
	//const [user_id, setUser_id] = useState(`${user.email}`);
	const [unique_id, setUnique_id] = useState(uuid());
	let [date, setdate] = useState(new Date());
	let [purchase_flag, setpurchase_flag] = useState("");
	const [MessageCount, setMessageCount] = useState([]);
	const [showForm, setShowForm] = useState(false);
	let [timer, setTimer] = useState(0);
	let [clock_timer, setClockTimer] = useState(0);

	let [alert_count, set_alert_count] = useState(0);
	const [visibility, setvisibility] = useState(0);
	let [redirect, setredirect] = useState(0);
	//const [organization_id, setOrganization_id] = useState("");
	let [read, setread] = useState(2);
	let [write, setwrite] = useState(2);

	var page_name = "account-setting"



	const renderTime = ({ remainingTime }) => {

		if (remainingTime === 0) {
			//return <div className="timer">Too lale...</div>;
			window.location.href = "/login";
		}

		return (
			<div className="timer">
				<div className="text">Remaining</div>
				<div className="value">{remainingTime}</div>
				<div className="text">seconds</div>
			</div>
		);
	};



	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	function handleChange(event) {
		setImage(URL.createObjectURL(event.target.files[0]))
		setfile(event.target.files[0]);

		const file = event.target.files[0];

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		const data = new FormData()
		data.append('user_id', user.email)
		data.append('page_name', page_name)
		data.append('unique_id', unique_id)
		data.append('file', file)
		axios.post(`${process.env.REACT_APP_SERVER_URL}/upload`, data, headers)
		.then(res => {
			//console.log(res.statusText);
		})
		.catch((err)=>{
			console.log("account profile image upload error",err)
		})
	}

	useEffect(() => {
		setCookie("lastpage", window.location.href, 1)
		const validateJWT = () => {

			if (getCookie("access_token") != "") {
				var object = {};
				object.token = getCookie("access_token");

				var headers = {
					headers: {
						"Content-Type": "application/json",
						"Accept": "*/*"
					}
				}
				axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var user_object = {};
						user_object.email = res.data.email;
						user_object.name = res.data.name;
						user_object.img = res.data.img;
						user_object.phone = res.data.phone;
						setUser(user_object)
					}
					else {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
					.catch(function (error) {
						console.log(error);
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					});

				axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						if (res.data.organization_flag == 0) {
							swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
								window.location.href = "/login"
							});
						}
						else {
							var path = window.location.pathname;
							path = path.replace(/\/$/, "");
							path = decodeURIComponent(path);
							var original_url = path.split("/")[1]
							let index = 0;
							let temp = -1;
							for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
								let component = res.data.user_access.access[i].inside_components;
								for (let j = 0; j < component.length; j++) {
									if (component[j].hasOwnProperty(original_url)) {
										index = i;
										if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
											setvisibility(1);
											setread(res.data.user_access.access[index].inside_components[j]["read"])
											setwrite(res.data.user_access.access[index].inside_components[j]["write"])
											// setwrite(0)
											break;
										}
										else {
											setredirect(1);
										}
									}
									else
									{
										temp = 2;
									}
								}
							}
							if(temp == 2)
                            {
                                setredirect(1);
                            }
						}
						var token = res.data.audience_license;

						if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
							setCookie('access_token', res.data.audience_license, 1)
							setTimer(process.env.REACT_APP_JWT_EXPIRY);
							//setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
						}
						else {
							setTimer(res.data.audience_count)
							//setClockTimer(res.data.audience_count)
						}
					}
					else {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
					.catch(function (error) {
						console.log(error);
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					});
			}
			else {
				swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
					window.location.href = "/login"
				});
			}
		}

		validateJWT();

	}, []);

	useEffect(() => {
		if (timer > 0) {
			const timer_decrease = setInterval(() => {
				setTimer(timer - 1);
				if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
					if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
						setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
						window.$('#kt_modal_timer').modal('show');
					}
				}
			}, 1000);

			return () => clearInterval(timer_decrease);
		}
	}, [timer]);

	useEffect(() => {
		async function getlivealertdetails() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-live-alert-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			var count = 0;
			for (var i = 0; i < body.live_alert.length; i++) {
				if (body.live_alert[i].add_status == "Active") {
					count = count + 1;
				}
			}
			set_alert_count(count) 

		}
		if (read || write) {
			(user.email && visibility) && getlivealertdetails();
		}


	}, [user, visibility, read, write])

	useEffect(() => {

		async function getpurchaseflagdetails() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-purchase-flag-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			//console.log(body.purchase_flag)
			setpurchase_flag(body.purchase_flag);

		}
		if (read || write) {
			(user.email && visibility) && getpurchaseflagdetails();
		}

		async function getaccountdetails() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-account-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			//console.log(body)
			if (body.length > 0) {
				setphn(body[0].phone);
				setcountry(body[0].country);
				settimezone(body[0].timezone + " || " + body[0].timezone_value);
				if (body[0].image_name.startsWith("http")) {
					setImage(body[0].image_name)
				}
				else if (body[0].image_name) {
					setImage(`https://shavastra-gn.s3.ap-south-1.amazonaws.com/account_image/${body[0].image_name}`);
				}
				else {
					setImage(user.img)
				}


				setfirst_name(body[0].account_name.split(" ")[0])
				setlast_name(body[0].account_name.split(" ")[1])
				let a = [];
				a = body[0].communication.split(",");
				
				let currentPage = window.location.href
				currentPage = currentPage.split("/")
				currentPage = currentPage[currentPage.length-1]

				if(currentPage === "account-setting")
				{
					if (body[0].allow_marketing == "Yes") {
						document.getElementById("allowmarketing").checked = "checked";
					}
					for (let i = 0; i < a.length; i++) {
						if (a[i] == "email") {
							document.getElementById("email").checked = "checked";
						}
						if (a[i] == "phone") {
							document.getElementById("phone").checked = "checked";
						}
					}
				}
			}
			else {
				setphn(user.phone)
				setfirst_name(user.name.split(" ")[0])
				setlast_name(user.name.split(" ")[1])
				setImage(user.img)
			}

		}
		if (read || write) {
			(user.email && visibility) && getaccountdetails();
		}

		async function getmessagecount() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/account-activity`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			setMessageCount(body);

			//console.log('body', body);
		}
		if (read || write) {
			(user.email && visibility) && getmessagecount();
		}

		async function getusessubscription() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-uses-subscription`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			body.map((obj, index) => {
				body[index].ending_date1 = moment.utc(body[index].ending_date).add(1, 'days');
			})
			//console.log(body)
			setSubscriptionDetails(body);
			//setloading(true);
		}
		if (read || write) {
			(user.email && visibility) && getusessubscription();
		}


		async function getnotificationcontent() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-notification-content`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			setnotification_content(body);
		}
		if (read || write) {
			(user.email && visibility) && getnotificationcontent();
		}

		async function getaddressinformation() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-address-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			//setphn(user.phone)
			if (body.length > 0) {

				setAddressDetails(body);

			}
		}
		if (read || write) {
			(user.email && visibility) && getaddressinformation();
		}

	}, [user, visibility, read, write])

	useEffect(() => {

		async function getnotification() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-notification-info`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			let currentPage = window.location.href
			currentPage = currentPage.split("/")
			currentPage = currentPage[currentPage.length-1]
			if(currentPage === "account-setting")
			{
				if (body.length > 0) {
					let a = {};
					a = JSON.parse(body[0].notification_data);
					for (var key in a) {
						for (let i = 0; i < a[key].length; i++) {
							if (a[key][i] == "email") {
								document.getElementById(`email_${key}`).checked = "checked";
							}
							if (a[key][i] == "phone") {
								document.getElementById(`phone_${key}`).checked = "checked";
							}
						}
					}
				}
			}
			
		}
		if (read || write) {
			(user.email && visibility && notification_content.length > 0) && getnotification();
		}

	}, [notification_content, visibility, read, write])

	const updateaccount = () => {

		var id = user.email;
		//var unique_id = unique_id;
		//console.lo
		var first_name = document.getElementById("first_name").value;

		if (first_name == "") {
			document.getElementById('firstname').innerHTML = "Please fill the first name";
			return false;
		}
		else if (!isNaN(first_name)) {
			document.getElementById('firstname').innerHTML = "only charecters are allowed";
			return false;
		}
		else {
			document.getElementById('firstname').innerHTML = " ";
		}
		var last_name = document.getElementById("last_name").value;
		if (last_name == "") {
			document.getElementById('lastname').innerHTML = "Please fill the last name";
			return false;
		}
		else if (!isNaN(last_name)) {
			document.getElementById('lastname').innerHTML = "only charecters are allowed";
			return false;
		}
		else {
			document.getElementById('lastname').innerHTML = " ";
		}
		var country = document.getElementById("country").value;
		if (country == "") {
			document.getElementById('country1').innerHTML = "Please select country";
			return false;
		}
		else {
			document.getElementById('country1').innerHTML = " ";
		}
		var phone = document.getElementById("phn").value;
		var regexp = /^([0|\+[0-9]{1,5})?([0-9]{1,15})$/
		if (phone == "") {
			document.getElementById('phn1').innerHTML = "Please fill the phone number";
			return false;
		} 
		else if (!regexp.test(phone)) {
			document.getElementById('phn1').innerHTML = "Please enter valid phone number";
			return false;
		}
		else {
			document.getElementById('phn1').innerHTML = " ";
		}
		var timezone = document.getElementById("timezone").value;
		if (timezone == "") {
			document.getElementById('timezone1').innerHTML = "Please select timezone";
			return false;
		}
		else {
			document.getElementById('timezone1').innerHTML = " ";
		}
		var file1 = [];
		file1 = file;
		var communication = [];
		let elem = document.getElementsByName('communication[]');
		for (var i = 0; i < elem.length; i++) {
			if (elem[i].checked) {
				communication.push(elem[i].value);
			}
		}
		if (communication.length == 0) {
			document.getElementById('communication1').innerHTML = "Please select a communication";
			return false;
		}
		else {
			document.getElementById('communication1').innerHTML = " ";
		}
		var elem1 = document.getElementById("allowmarketing");

		var allowmarketing = 'off';
		if (elem1.checked) {
			allowmarketing = 'on';
		}
		var values = { "id": id, "unique_id": unique_id, "first_name": first_name, "last_name": last_name, "datafiles": file1, "allowmarketing": allowmarketing, "country": country, "phone": phone, "timezone": timezone, "communication": communication, "page_name": page_name };
		//console.log(values)

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		//console.log(values)
		axios.post(`${process.env.REACT_APP_SERVER_URL}/update-account-information`, values, headers).then((res) => {
			if (res.data && res.data.operation == "success") {
				swal("Great!", "Account saved successfully!", "success").then((value) => {
				});
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});

	}

	const updatenotification = () => {
		//console.log("abc")
		var id = user.email;
		//console.log(notification_content[1]);

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}


		var object = {};
		object.id = user.email;
		object.page_name = page_name;

		for (let i = 0; i < notification_content.length; i++) {
			let unique_id = notification_content[i].unique_id;
			let payment = [];
			let elem4 = document.getElementsByName(unique_id);
			for (let i = 0; i < elem4.length; i++) {
				if (elem4[i].checked) {
					payment.push(elem4[i].value);
				}
			}
			//console.log(payment);
			let notification = notification_content[i].content;
			object[unique_id] = payment;
			if (i == notification_content.length - 1) {
				//console.log(object);
				axios.post(`${process.env.REACT_APP_SERVER_URL}/update-notification`, object, headers).then((res) => {
					if (res.data && res.data.operation == "success") {
						swal("Great!", "notification saved successfully!", "success").then((value) => {
						});
					}
					else {
						swal("Oops!", "Something went wrong!", "error");
					}
				});
			}
		}
	}

	const updatePassword = () => {

		var exp2 = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!$%^&*-]).{8,}$/;

		if (document.getElementById("currentpassword").value == "") {
			swal("Oops!", "Please enter your current password", "error");
			return false;
		}
		else if (document.getElementById("currentpassword").value.includes("@") || document.getElementById("currentpassword").value.includes(".")) {
			swal("Warning!", "Current Password cannot contain '@' and '.' symbols within it.", "warning");
			return false;
		}
		else if (!document.getElementById("currentpassword").value.match(exp2)) {
			swal("Warning!", "Current Password is not valid, try with a different password.", "warning");
			return false;
		}
		else if (document.getElementById("newpassword").value == "") {
			swal("Warning!", "Please enter your new password", "warning");
			return false;
		}
		else if (document.getElementById("newpassword").value.includes("@") || document.getElementById("newpassword").value.includes(".")) {
			swal("Warning!", "New Password cannot contain '@' and '.' symbols within it.", "warning");
			return false;
		}
		else if (!document.getElementById("newpassword").value.match(exp2)) {
			swal("Warning!", "New Password is not valid, try with a different password.", "warning");
			return false;
		}
		else if (document.getElementById("confirmpassword").value == "") {
			swal("Warning!", "Please re-enter your new password", "warning");
			return false;
		}
		else if (document.getElementById("confirmpassword").value != document.getElementById("newpassword").value) {
			swal("Warning!", "Please check your new password and confirm password", "warning");
			return false;
		}




		if (document.getElementById("kt_password_submit")) {
			document.getElementById("kt_password_submit").setAttribute("data-kt-indicator", "on");
			document.getElementById("kt_password_submit").disabled = !0;
		}

		var object = {};
		object.email = user.email;
		object.currentpassword = document.getElementById("currentpassword").value;
		object.newpassword = document.getElementById("newpassword").value;
		object.token = getCookie("access_token");
		object.page_name = page_name;

		axios.post(`${process.env.REACT_APP_SERVER_URL}/generate-change-password-id`, object).then((res) => {

			if (res.data && res.data.operation == "success") {
				swal("Great!", "password changed successfully!", "success").then((value) => {
					window.location.reload();
				});
			}
			else {
				swal("Oops!", "Something went wrong!", "error").then((value) => {
					window.location.reload();
				});
			}
		});

	}

	// function loadScripts() {
	// 	var elem = document.getElementById("signin-methods");
	// 	if (!elem) {
	// 		var se = document.createElement('script');
	// 		se.src = "/assets/js/custom/account/settings/signin-methods.js";
	// 		se.id = "signin-methods";
	// 		document.body.appendChild(se);
	// 	}
	// }

	return (
		<>
			{
				(redirect == 1 || (read == 0 && write == 0)) ?
					<>
						<Redirect to={{
							pathname: "/unauthorized",
						}}
						/>
					</> :
					null
			}

			{user.email ?
				<>
					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">

						<div className="toolbar" id="kt_toolbar">
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
								<div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
									<h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Account Setting
										<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
										<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
									</h1>
								</div>

							</div>
						</div>

						<div className="post d-flex flex-column-fluid" id="kt_post">
							<div className="container" id="kt_content_container">
								<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
									<br />

									<div className="card mb-5 mb-xl-10">
										<div className="card-body pt-9 pb-0">

											<div className="d-flex flex-wrap flex-sm-nowrap mb-3">

												<div className="me-7 mb-4">
													<div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
														<img style={{ "max-height": "150px", "max-width": "150px" }} src={image} alt="image" className="rounded" />
														<div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
													</div>
												</div>


												<div className="flex-grow-1">

													<div className="d-flex justify-content-between align-items-start flex-wrap mb-2">

														<div className="d-flex flex-column">

															<div className="d-flex align-items-center mb-2">
																<a className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">{user.name}</a>

																{SubscriptionDetails.length > 0 ? SubscriptionDetails.map((obj, index) => {
																	return (
																		<>
																			{obj.ending_date1 > date ?
																				<a className="btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3 rounded" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">{obj.product_name}  -  {obj.subscription_name}</a>
																				:
																				null
																			}
																		</>
																	);
																})
																	:
																	null
																}
															</div>


															<div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">

																<a className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">


																	{user.email}</a>
															</div>

														</div>


													</div>
													<div className="d-flex flex-wrap flex-stack">

														<div className="d-flex flex-column flex-grow-1 pe-8">

															<div className="d-flex flex-wrap">



																{
																	(MessageCount.length) ?
																		<>
																			<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

																				<div className="d-flex align-items-center">



																					<div className="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="4500" data-kt-countup-prefix="$">{MessageCount[0].message_count}</div>
																				</div>

																				<div className="fw-bold fs-6 text-gray-400">Message</div>

																			</div>

																			<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

																				<div className="d-flex align-items-center">



																					<div className="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="75">{MessageCount[0].delivery_count}</div>
																				</div>

																				<div className="fw-bold fs-6 text-gray-400">Delivery</div>

																			</div>
																			<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

																				<div className="d-flex align-items-center">

																					<span className="svg-icon svg-icon-3 svg-icon-success me-2">

																					</span>

																					<div className="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="60" data-kt-countup-prefix="%">{alert_count}</div>
																				</div>

																				<div className="fw-bold fs-6 text-gray-400">Direct Alerts</div>

																			</div>
																		</>
																		: null
																}


															</div>

														</div>

														{AddressDetails.length > 0 ?
															<div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
																<div className="d-flex justify-content-between w-100 mt-auto mb-2">
																	<span className="fw-bold fs-6 text-gray-400">Profile Completion</span>
																	<span className="fw-bolder fs-6">100%</span>
																</div>
																<div className="h-5px mx-3 w-100 bg-light mb-3">
																	<div className="bg-success rounded h-5px" role="progressbar" style={{ "width": "100%" }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
																</div>
															</div>
															:
															<div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
																<div className="d-flex justify-content-between w-100 mt-auto mb-2">
																	<span className="fw-bold fs-6 text-gray-400">Profile Completion</span>
																	<span className="fw-bolder fs-6">90%</span>
																</div>
																<div className="h-5px mx-3 w-100 bg-light mb-3">
																	<div className="bg-success rounded h-5px" role="progressbar" style={{ "width": "90%" }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
																</div>
															</div>
														}

													</div>

												</div>

											</div>

											<div className="d-flex overflow-auto h-55px">
												<ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">

													<li className="nav-item">
														<Link className="nav-link text-active-primary me-6" to="/account-overview">Overview</Link>
													</li>

													<li className="nav-item">
														<Link className="nav-link text-active-primary me-6 active" to="/account-setting">Settings</Link>
													</li>

													<li className="nav-item">
														<Link className="nav-link text-active-primary me-6" to="/account-security">Security</Link>
													</li>

													<li className="nav-item">
														<Link className="nav-link text-active-primary me-6" to="/account-billing">Billing</Link>
													</li>

												</ul>
											</div>

										</div>
									</div>

									{purchase_flag == "not found" ?
										<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">

											<span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
												<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
													<path d="M18.5,8 C17.1192881,8 16,6.88071187 16,5.5 C16,4.11928813 17.1192881,3 18.5,3 C19.8807119,3 21,4.11928813 21,5.5 C21,6.88071187 19.8807119,8 18.5,8 Z M18.5,21 C17.1192881,21 16,19.8807119 16,18.5 C16,17.1192881 17.1192881,16 18.5,16 C19.8807119,16 21,17.1192881 21,18.5 C21,19.8807119 19.8807119,21 18.5,21 Z M5.5,21 C4.11928813,21 3,19.8807119 3,18.5 C3,17.1192881 4.11928813,16 5.5,16 C6.88071187,16 8,17.1192881 8,18.5 C8,19.8807119 6.88071187,21 5.5,21 Z" fill="#000000" opacity="0.3"></path>
													<path d="M5.5,8 C4.11928813,8 3,6.88071187 3,5.5 C3,4.11928813 4.11928813,3 5.5,3 C6.88071187,3 8,4.11928813 8,5.5 C8,6.88071187 6.88071187,8 5.5,8 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 C14,5.55228475 13.5522847,6 13,6 L11,6 C10.4477153,6 10,5.55228475 10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,18 L13,18 C13.5522847,18 14,18.4477153 14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 Z M5,10 C5.55228475,10 6,10.4477153 6,11 L6,13 C6,13.5522847 5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 C18.4477153,14 18,13.5522847 18,13 L18,11 C18,10.4477153 18.4477153,10 19,10 Z" fill="#000000"></path>
												</svg>
											</span>

											<div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">

												<div className="mb-3 mb-md-0 fw-bold">
													<h4 className="text-gray-800 fw-bolder">Activate Trial Account</h4>
													<div className="fs-6 text-gray-600 pe-7">You can Activate your Trial account and enjoy 7 days of free subscription.</div>
												</div>
												{
													write ?
														<Link to="/purchase-trial" className="btn btn-primary px-6 align-self-center text-nowrap rounded">Activate</Link>
														:
														<button className="btn btn-primary px-6 align-self-center text-nowrap rounded" disabled>Activate</button>
												}


											</div>

										</div>
										:
										null
									}

									<div className="card mb-5 mb-xl-10">

										<div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">

											<div className="card-title m-0">
												<h3 className="fw-bolder m-0">Profile Details</h3>
											</div>
										</div>


										<div id="kt_account_profile_details" className="collapse show">

											<form id="kt_account_profile_details_form" className="form">

												<div className="card-body border-top p-9">

													<div className="row mb-6">
														<label className="col-lg-4 col-form-label fw-bold fs-6">Avatar</label>
														<div className="col-lg-8">

															<div className="image-input image-input-outline" data-kt-image-input="true" style={{ "background-image": "url(assets/media/avatars/blank.png)" }} >
																<div className="image-input-wrapper w-125px h-125px" style={{ "background-image": `url('${image}')` }} ></div>

																<label className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" title="Change avatar">
																	<i className="bi bi-pencil-fill fs-7"></i>

																	<input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={(event) => { handleChange(event) }} />
																	<input type="hidden" name="avatar_remove" />
																</label>

																{/* <span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" title="Cancel avatar">
																	<i className="bi bi-x fs-2"></i>
																</span>

																<span className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" title="Remove avatar">
																	<i className="bi bi-x fs-2"></i>
																</span> */}
															</div>

															<div className="form-text">Allowed file types: png, jpg, jpeg.</div>
														</div>

													</div>


													<div className="row mb-6">

														<label className="col-lg-4 col-form-label required fw-bold fs-6">Full Name</label>


														<div className="col-lg-8">

															<div className="row">

																<div className="col-lg-6 fv-row">
																	<input type="text" id="first_name" name="fname" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="First name" value={first_name} onChange={(e) => { setfirst_name(e.target.value) }}/>
																	<span id="firstname" class="text-danger font-weight-bold"></span>
																</div>


																<div className="col-lg-6 fv-row">
																	<input type="text" id="last_name" name="lname" className="form-control form-control-lg form-control-solid" placeholder="Last name" value={last_name} onChange={(e) => { setlast_name(e.target.value) }} />
																	<span id="lastname" class="text-danger font-weight-bold"></span>
																</div>

															</div>

														</div>

													</div>



													<div className="row mb-6">

														<label className="col-lg-4 col-form-label fw-bold fs-6">
															<span className="required">Contact Phone</span>
															<i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Phone number must be active"></i>
														</label>


														<div className="col-lg-8 fv-row">
															<input type="tel" name="phone" id="phn" className="form-control form-control-lg form-control-solid" placeholder="Phone number" value={phn} onChange={(e) => { setphn(e.target.value) }} />
															<span id="phn1" class="text-danger font-weight-bold"></span>
														</div>

													</div>

													<div className="row mb-6">

														<label className="col-lg-4 col-form-label fw-bold fs-6">
															<span className="required">Country</span>
															<i className="fas fa-exclamation-circle ms-1 fs-7" data-bs-toggle="tooltip" title="Country of origination"></i>
														</label>


														<div className="col-lg-8 fv-row">
															<select name="country" aria-label="Select a Country" id="country" data-control="select2" data-placeholder="Select a country..." className="form-select form-select-solid form-select-lg fw-bold" value={country} onChange={(e) => { setcountry(e.target.value) }}>
																<option value="">Select a Country...</option>
																<option data-kt-flag="flags/afghanistan.svg" value="AF">Afghanistan</option>
																<option data-kt-flag="flags/aland-islands.svg" value="AX">Aland Islands</option>
																<option data-kt-flag="flags/albania.svg" value="AL">Albania</option>
																<option data-kt-flag="flags/algeria.svg" value="DZ">Algeria</option>
																<option data-kt-flag="flags/american-samoa.svg" value="AS">American Samoa</option>
																<option data-kt-flag="flags/andorra.svg" value="AD">Andorra</option>
																<option data-kt-flag="flags/angola.svg" value="AO">Angola</option>
																<option data-kt-flag="flags/anguilla.svg" value="AI">Anguilla</option>
																<option data-kt-flag="flags/antarctica.svg" value="AQ">Antarctica</option>
																<option data-kt-flag="flags/antigua-and-barbuda.svg" value="AG">Antigua and Barbuda</option>
																<option data-kt-flag="flags/argentina.svg" value="AR">Argentina</option>
																<option data-kt-flag="flags/armenia.svg" value="AM">Armenia</option>
																<option data-kt-flag="flags/aruba.svg" value="AW">Aruba</option>
																<option data-kt-flag="flags/australia.svg" value="AU">Australia</option>
																<option data-kt-flag="flags/austria.svg" value="AT">Austria</option>
																<option data-kt-flag="flags/azerbaijan.svg" value="AZ">Azerbaijan</option>
																<option data-kt-flag="flags/bahamas.svg" value="BS">Bahamas</option>
																<option data-kt-flag="flags/bahrain.svg" value="BH">Bahrain</option>
																<option data-kt-flag="flags/bangladesh.svg" value="BD">Bangladesh</option>
																<option data-kt-flag="flags/barbados.svg" value="BB">Barbados</option>
																<option data-kt-flag="flags/belarus.svg" value="BY">Belarus</option>
																<option data-kt-flag="flags/belgium.svg" value="BE">Belgium</option>
																<option data-kt-flag="flags/belize.svg" value="BZ">Belize</option>
																<option data-kt-flag="flags/benin.svg" value="BJ">Benin</option>
																<option data-kt-flag="flags/bermuda.svg" value="BM">Bermuda</option>
																<option data-kt-flag="flags/bhutan.svg" value="BT">Bhutan</option>
																<option data-kt-flag="flags/bolivia.svg" value="BO">Bolivia, Plurinational State of</option>
																<option data-kt-flag="flags/bonaire.svg" value="BQ">Bonaire, Sint Eustatius and Saba</option>
																<option data-kt-flag="flags/bosnia-and-herzegovina.svg" value="BA">Bosnia and Herzegovina</option>
																<option data-kt-flag="flags/botswana.svg" value="BW">Botswana</option>
																<option data-kt-flag="flags/bouvet-island.svg" value="BV">Bouvet Island</option>
																<option data-kt-flag="flags/brazil.svg" value="BR">Brazil</option>
																<option data-kt-flag="flags/british-indian-ocean-territory.svg" value="IO">British Indian Ocean Territory</option>
																<option data-kt-flag="flags/brunei.svg" value="BN">Brunei Darussalam</option>
																<option data-kt-flag="flags/bulgaria.svg" value="BG">Bulgaria</option>
																<option data-kt-flag="flags/burkina-faso.svg" value="BF">Burkina Faso</option>
																<option data-kt-flag="flags/burundi.svg" value="BI">Burundi</option>
																<option data-kt-flag="flags/cambodia.svg" value="KH">Cambodia</option>
																<option data-kt-flag="flags/cameroon.svg" value="CM">Cameroon</option>
																<option data-kt-flag="flags/canada.svg" value="CA">Canada</option>
																<option data-kt-flag="flags/cape-verde.svg" value="CV">Cape Verde</option>
																<option data-kt-flag="flags/cayman-islands.svg" value="KY">Cayman Islands</option>
																<option data-kt-flag="flags/central-african-republic.svg" value="CF">Central African Republic</option>
																<option data-kt-flag="flags/chad.svg" value="TD">Chad</option>
																<option data-kt-flag="flags/chile.svg" value="CL">Chile</option>
																<option data-kt-flag="flags/china.svg" value="CN">China</option>
																<option data-kt-flag="flags/christmas-island.svg" value="CX">Christmas Island</option>
																<option data-kt-flag="flags/cocos-island.svg" value="CC">Cocos (Keeling) Islands</option>
																<option data-kt-flag="flags/colombia.svg" value="CO">Colombia</option>
																<option data-kt-flag="flags/comoros.svg" value="KM">Comoros</option>
																<option data-kt-flag="flags/congo.svg" value="CG">Congo</option>
																<option data-kt-flag="flags/congo.svg" value="CD">Congo, the Democratic Republic of the</option>
																<option data-kt-flag="flags/cook-islands.svg" value="CK">Cook Islands</option>
																<option data-kt-flag="flags/costa-rica.svg" value="CR">Costa Rica</option>
																<option data-kt-flag="flags/ivory-coast.svg" value="CI">Côte d'Ivoire</option>
																<option data-kt-flag="flags/croatia.svg" value="HR">Croatia</option>
																<option data-kt-flag="flags/cuba.svg" value="CU">Cuba</option>
																<option data-kt-flag="flags/curacao.svg" value="CW">Curaçao</option>
																<option data-kt-flag="flags/cyprus.svg" value="CY">Cyprus</option>
																<option data-kt-flag="flags/czech-republic.svg" value="CZ">Czech Republic</option>
																<option data-kt-flag="flags/denmark.svg" value="DK">Denmark</option>
																<option data-kt-flag="flags/djibouti.svg" value="DJ">Djibouti</option>
																<option data-kt-flag="flags/dominica.svg" value="DM">Dominica</option>
																<option data-kt-flag="flags/dominican-republic.svg" value="DO">Dominican Republic</option>
																<option data-kt-flag="flags/ecuador.svg" value="EC">Ecuador</option>
																<option data-kt-flag="flags/egypt.svg" value="EG">Egypt</option>
																<option data-kt-flag="flags/el-salvador.svg" value="SV">El Salvador</option>
																<option data-kt-flag="flags/equatorial-guinea.svg" value="GQ">Equatorial Guinea</option>
																<option data-kt-flag="flags/eritrea.svg" value="ER">Eritrea</option>
																<option data-kt-flag="flags/estonia.svg" value="EE">Estonia</option>
																<option data-kt-flag="flags/ethiopia.svg" value="ET">Ethiopia</option>
																<option data-kt-flag="flags/falkland-islands.svg" value="FK">Falkland Islands (Malvinas)</option>
																<option data-kt-flag="flags/faroe-islands.svg" value="FO">Faroe Islands</option>
																<option data-kt-flag="flags/fiji.svg" value="FJ">Fiji</option>
																<option data-kt-flag="flags/finland.svg" value="FI">Finland</option>
																<option data-kt-flag="flags/france.svg" value="FR">France</option>
																<option data-kt-flag="flags/french-guiana.svg" value="GF">French Guiana</option>
																<option data-kt-flag="flags/french-polynesia.svg" value="PF">French Polynesia</option>
																<option data-kt-flag="flags/french-southern-territories.svg" value="TF">French Southern Territories</option>
																<option data-kt-flag="flags/gabon.svg" value="GA">Gabon</option>
																<option data-kt-flag="flags/gambia.svg" value="GM">Gambia</option>
																<option data-kt-flag="flags/georgia.svg" value="GE">Georgia</option>
																<option data-kt-flag="flags/germany.svg" value="DE">Germany</option>
																<option data-kt-flag="flags/ghana.svg" value="GH">Ghana</option>
																<option data-kt-flag="flags/gibraltar.svg" value="GI">Gibraltar</option>
																<option data-kt-flag="flags/greece.svg" value="GR">Greece</option>
																<option data-kt-flag="flags/greenland.svg" value="GL">Greenland</option>
																<option data-kt-flag="flags/grenada.svg" value="GD">Grenada</option>
																<option data-kt-flag="flags/guadeloupe.svg" value="GP">Guadeloupe</option>
																<option data-kt-flag="flags/guam.svg" value="GU">Guam</option>
																<option data-kt-flag="flags/guatemala.svg" value="GT">Guatemala</option>
																<option data-kt-flag="flags/guernsey.svg" value="GG">Guernsey</option>
																<option data-kt-flag="flags/guinea.svg" value="GN">Guinea</option>
																<option data-kt-flag="flags/guinea-bissau.svg" value="GW">Guinea-Bissau</option>
																<option data-kt-flag="flags/guyana.svg" value="GY">Guyana</option>
																<option data-kt-flag="flags/haiti.svg" value="HT">Haiti</option>
																<option data-kt-flag="flags/heard-island.svg" value="HM">Heard Island and McDonald Islands</option>
																<option data-kt-flag="flags/vatican-city.svg" value="VA">Holy See (Vatican City State)</option>
																<option data-kt-flag="flags/honduras.svg" value="HN">Honduras</option>
																<option data-kt-flag="flags/hong-kong.svg" value="HK">Hong Kong</option>
																<option data-kt-flag="flags/hungary.svg" value="HU">Hungary</option>
																<option data-kt-flag="flags/iceland.svg" value="IS">Iceland</option>
																<option data-kt-flag="flags/india.svg" value="IN">India</option>
																<option data-kt-flag="flags/indonesia.svg" value="ID">Indonesia</option>
																<option data-kt-flag="flags/iran.svg" value="IR">Iran, Islamic Republic of</option>
																<option data-kt-flag="flags/iraq.svg" value="IQ">Iraq</option>
																<option data-kt-flag="flags/ireland.svg" value="IE">Ireland</option>
																<option data-kt-flag="flags/isle-of-man.svg" value="IM">Isle of Man</option>
																<option data-kt-flag="flags/israel.svg" value="IL">Israel</option>
																<option data-kt-flag="flags/italy.svg" value="IT">Italy</option>
																<option data-kt-flag="flags/jamaica.svg" value="JM">Jamaica</option>
																<option data-kt-flag="flags/japan.svg" value="JP">Japan</option>
																<option data-kt-flag="flags/jersey.svg" value="JE">Jersey</option>
																<option data-kt-flag="flags/jordan.svg" value="JO">Jordan</option>
																<option data-kt-flag="flags/kazakhstan.svg" value="KZ">Kazakhstan</option>
																<option data-kt-flag="flags/kenya.svg" value="KE">Kenya</option>
																<option data-kt-flag="flags/kiribati.svg" value="KI">Kiribati</option>
																<option data-kt-flag="flags/north-korea.svg" value="KP">Korea, Democratic People's Republic of</option>
																<option data-kt-flag="flags/kuwait.svg" value="KW">Kuwait</option>
																<option data-kt-flag="flags/kyrgyzstan.svg" value="KG">Kyrgyzstan</option>
																<option data-kt-flag="flags/laos.svg" value="LA">Lao People's Democratic Republic</option>
																<option data-kt-flag="flags/latvia.svg" value="LV">Latvia</option>
																<option data-kt-flag="flags/lebanon.svg" value="LB">Lebanon</option>
																<option data-kt-flag="flags/lesotho.svg" value="LS">Lesotho</option>
																<option data-kt-flag="flags/liberia.svg" value="LR">Liberia</option>
																<option data-kt-flag="flags/libya.svg" value="LY">Libya</option>
																<option data-kt-flag="flags/liechtenstein.svg" value="LI">Liechtenstein</option>
																<option data-kt-flag="flags/lithuania.svg" value="LT">Lithuania</option>
																<option data-kt-flag="flags/luxembourg.svg" value="LU">Luxembourg</option>
																<option data-kt-flag="flags/macao.svg" value="MO">Macao</option>
																<option data-kt-flag="flags/macedonia.svg" value="MK">Macedonia, the former Yugoslav Republic of</option>
																<option data-kt-flag="flags/madagascar.svg" value="MG">Madagascar</option>
																<option data-kt-flag="flags/malawi.svg" value="MW">Malawi</option>
																<option data-kt-flag="flags/malaysia.svg" value="MY">Malaysia</option>
																<option data-kt-flag="flags/maldives.svg" value="MV">Maldives</option>
																<option data-kt-flag="flags/mali.svg" value="ML">Mali</option>
																<option data-kt-flag="flags/malta.svg" value="MT">Malta</option>
																<option data-kt-flag="flags/marshall-island.svg" value="MH">Marshall Islands</option>
																<option data-kt-flag="flags/martinique.svg" value="MQ">Martinique</option>
																<option data-kt-flag="flags/mauritania.svg" value="MR">Mauritania</option>
																<option data-kt-flag="flags/mauritius.svg" value="MU">Mauritius</option>
																<option data-kt-flag="flags/mayotte.svg" value="YT">Mayotte</option>
																<option data-kt-flag="flags/mexico.svg" value="MX">Mexico</option>
																<option data-kt-flag="flags/micronesia.svg" value="FM">Micronesia, Federated States of</option>
																<option data-kt-flag="flags/moldova.svg" value="MD">Moldova, Republic of</option>
																<option data-kt-flag="flags/monaco.svg" value="MC">Monaco</option>
																<option data-kt-flag="flags/mongolia.svg" value="MN">Mongolia</option>
																<option data-kt-flag="flags/montenegro.svg" value="ME">Montenegro</option>
																<option data-kt-flag="flags/montserrat.svg" value="MS">Montserrat</option>
																<option data-kt-flag="flags/morocco.svg" value="MA">Morocco</option>
																<option data-kt-flag="flags/mozambique.svg" value="MZ">Mozambique</option>
																<option data-kt-flag="flags/myanmar.svg" value="MM">Myanmar</option>
																<option data-kt-flag="flags/namibia.svg" value="NA">Namibia</option>
																<option data-kt-flag="flags/nauru.svg" value="NR">Nauru</option>
																<option data-kt-flag="flags/nepal.svg" value="NP">Nepal</option>
																<option data-kt-flag="flags/netherlands.svg" value="NL">Netherlands</option>
																<option data-kt-flag="flags/new-caledonia.svg" value="NC">New Caledonia</option>
																<option data-kt-flag="flags/new-zealand.svg" value="NZ">New Zealand</option>
																<option data-kt-flag="flags/nicaragua.svg" value="NI">Nicaragua</option>
																<option data-kt-flag="flags/niger.svg" value="NE">Niger</option>
																<option data-kt-flag="flags/nigeria.svg" value="NG">Nigeria</option>
																<option data-kt-flag="flags/niue.svg" value="NU">Niue</option>
																<option data-kt-flag="flags/norfolk-island.svg" value="NF">Norfolk Island</option>
																<option data-kt-flag="flags/northern-mariana-islands.svg" value="MP">Northern Mariana Islands</option>
																<option data-kt-flag="flags/norway.svg" value="NO">Norway</option>
																<option data-kt-flag="flags/oman.svg" value="OM">Oman</option>
																<option data-kt-flag="flags/pakistan.svg" value="PK">Pakistan</option>
																<option data-kt-flag="flags/palau.svg" value="PW">Palau</option>
																<option data-kt-flag="flags/palestine.svg" value="PS">Palestinian Territory, Occupied</option>
																<option data-kt-flag="flags/panama.svg" value="PA">Panama</option>
																<option data-kt-flag="flags/papua-new-guinea.svg" value="PG">Papua New Guinea</option>
																<option data-kt-flag="flags/paraguay.svg" value="PY">Paraguay</option>
																<option data-kt-flag="flags/peru.svg" value="PE">Peru</option>
																<option data-kt-flag="flags/philippines.svg" value="PH">Philippines</option>
																<option data-kt-flag="flags/pitcairn.svg" value="PN">Pitcairn</option>
																<option data-kt-flag="flags/poland.svg" value="PL">Poland</option>
																<option data-kt-flag="flags/portugal.svg" value="PT">Portugal</option>
																<option data-kt-flag="flags/puerto-rico.svg" value="PR">Puerto Rico</option>
																<option data-kt-flag="flags/qatar.svg" value="QA">Qatar</option>
																<option data-kt-flag="flags/réunion.svg" value="RE">Réunion</option>
																<option data-kt-flag="flags/romania.svg" value="RO">Romania</option>
																<option data-kt-flag="flags/russia.svg" value="RU">Russian Federation</option>
																<option data-kt-flag="flags/rwanda.svg" value="RW">Rwanda</option>
																<option data-kt-flag="flags/st-barts.svg" value="BL">Saint Barthélemy</option>
																<option data-kt-flag="flags/saint-helena.svg" value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
																<option data-kt-flag="flags/saint-kitts-and-nevis.svg" value="KN">Saint Kitts and Nevis</option>
																<option data-kt-flag="flags/st-lucia.svg" value="LC">Saint Lucia</option>
																<option data-kt-flag="flags/sint-maarten.svg" value="MF">Saint Martin (French part)</option>
																<option data-kt-flag="flags/saint-pierre.svg" value="PM">Saint Pierre and Miquelon</option>
																<option data-kt-flag="flags/st-vincent-and-the-grenadines.svg" value="VC">Saint Vincent and the Grenadines</option>
																<option data-kt-flag="flags/samoa.svg" value="WS">Samoa</option>
																<option data-kt-flag="flags/san-marino.svg" value="SM">San Marino</option>
																<option data-kt-flag="flags/sao-tome-and-prince.svg" value="ST">Sao Tome and Principe</option>
																<option data-kt-flag="flags/saudi-arabia.svg" value="SA">Saudi Arabia</option>
																<option data-kt-flag="flags/senegal.svg" value="SN">Senegal</option>
																<option data-kt-flag="flags/serbia.svg" value="RS">Serbia</option>
																<option data-kt-flag="flags/seychelles.svg" value="SC">Seychelles</option>
																<option data-kt-flag="flags/sierra-leone.svg" value="SL">Sierra Leone</option>
																<option data-kt-flag="flags/singapore.svg" value="SG">Singapore</option>
																<option data-kt-flag="flags/sint-maarten.svg" value="SX">Sint Maarten (Dutch part)</option>
																<option data-kt-flag="flags/slovakia.svg" value="SK">Slovakia</option>
																<option data-kt-flag="flags/slovenia.svg" value="SI">Slovenia</option>
																<option data-kt-flag="flags/solomon-islands.svg" value="SB">Solomon Islands</option>
																<option data-kt-flag="flags/somalia.svg" value="SO">Somalia</option>
																<option data-kt-flag="flags/south-africa.svg" value="ZA">South Africa</option>
																<option data-kt-flag="flags/south-georgia.svg" value="GS">South Georgia and the South Sandwich Islands</option>
																<option data-kt-flag="flags/south-korea.svg" value="KR">South Korea</option>
																<option data-kt-flag="flags/south-sudan.svg" value="SS">South Sudan</option>
																<option data-kt-flag="flags/spain.svg" value="ES">Spain</option>
																<option data-kt-flag="flags/sri-lanka.svg" value="LK">Sri Lanka</option>
																<option data-kt-flag="flags/sudan.svg" value="SD">Sudan</option>
																<option data-kt-flag="flags/suriname.svg" value="SR">Suriname</option>
																<option data-kt-flag="flags/svalbard.svg" value="SJ">Svalbard and Jan Mayen</option>
																<option data-kt-flag="flags/swaziland.svg" value="SZ">Swaziland</option>
																<option data-kt-flag="flags/sweden.svg" value="SE">Sweden</option>
																<option data-kt-flag="flags/switzerland.svg" value="CH">Switzerland</option>
																<option data-kt-flag="flags/syria.svg" value="SY">Syrian Arab Republic</option>
																<option data-kt-flag="flags/taiwan.svg" value="TW">Taiwan, Province of China</option>
																<option data-kt-flag="flags/tajikistan.svg" value="TJ">Tajikistan</option>
																<option data-kt-flag="flags/tanzania.svg" value="TZ">Tanzania, United Republic of</option>
																<option data-kt-flag="flags/thailand.svg" value="TH">Thailand</option>
																<option data-kt-flag="flags/timor-leste.svg" value="TL">Timor-Leste</option>
																<option data-kt-flag="flags/togo.svg" value="TG">Togo</option>
																<option data-kt-flag="flags/tokelau.svg" value="TK">Tokelau</option>
																<option data-kt-flag="flags/tonga.svg" value="TO">Tonga</option>
																<option data-kt-flag="flags/trinidad-and-tobago.svg" value="TT">Trinidad and Tobago</option>
																<option data-kt-flag="flags/tunisia.svg" value="TN">Tunisia</option>
																<option data-kt-flag="flags/turkey.svg" value="TR">Turkey</option>
																<option data-kt-flag="flags/turkmenistan.svg" value="TM">Turkmenistan</option>
																<option data-kt-flag="flags/turks-and-caicos.svg" value="TC">Turks and Caicos Islands</option>
																<option data-kt-flag="flags/tuvalu.svg" value="TV">Tuvalu</option>
																<option data-kt-flag="flags/uganda.svg" value="UG">Uganda</option>
																<option data-kt-flag="flags/ukraine.svg" value="UA">Ukraine</option>
																<option data-kt-flag="flags/united-arab-emirates.svg" value="AE">United Arab Emirates</option>
																<option data-kt-flag="flags/united-kingdom.svg" value="GB">United Kingdom</option>
																<option data-kt-flag="flags/united-states.svg" value="US">United States</option>
																<option data-kt-flag="flags/uruguay.svg" value="UY">Uruguay</option>
																<option data-kt-flag="flags/uzbekistan.svg" value="UZ">Uzbekistan</option>
																<option data-kt-flag="flags/vanuatu.svg" value="VU">Vanuatu</option>
																<option data-kt-flag="flags/venezuela.svg" value="VE">Venezuela, Bolivarian Republic of</option>
																<option data-kt-flag="flags/vietnam.svg" value="VN">Vietnam</option>
																<option data-kt-flag="flags/virgin-islands.svg" value="VI">Virgin Islands</option>
																<option data-kt-flag="flags/wallis-and-futuna.svg" value="WF">Wallis and Futuna</option>
																<option data-kt-flag="flags/western-sahara.svg" value="EH">Western Sahara</option>
																<option data-kt-flag="flags/yemen.svg" value="YE">Yemen</option>
																<option data-kt-flag="flags/zambia.svg" value="ZM">Zambia</option>
																<option data-kt-flag="flags/zimbabwe.svg" value="ZW">Zimbabwe</option>
															</select>
															<span id="country1" class="text-danger font-weight-bold"></span>
														</div>

													</div>

													<div className="row mb-6">

														<label className="col-lg-4 col-form-label required fw-bold fs-6">Time Zone</label>


														<div className="col-lg-8 fv-row">
															<select name="timezone" aria-label="Select a Timezone" id="timezone" data-control="select2" data-placeholder="Select a timezone.." className="form-select form-select-solid form-select-lg" value={timezone} onChange={(e) => { settimezone(e.target.value) }}>
																<option value="">Select a Timezone..</option>
																<option data-bs-offset="-39600" value="International Date Line West || -11:00">(GMT-11:00) International Date Line West</option>
																<option data-bs-offset="-39600" value="Midway Island || -11:00">(GMT-11:00) Midway Island</option>
																<option data-bs-offset="-39600" value="Samoa || -11:00">(GMT-11:00) Samoa</option>
																<option data-bs-offset="-36000" value="Hawaii || -11:00">(GMT-10:00) Hawaii</option>
																<option data-bs-offset="-28800" value="Alaska || -11:00">(GMT-11:00) Alaska</option>
																<option data-bs-offset="-25200" value="Pacific Time (US &amp; Canada) || -07:00">(GMT-07:00) Pacific Time (US &amp; Canada)</option>
																<option data-bs-offset="-25200" value="Tijuana || -07:00">(GMT-07:00) Tijuana</option>
																<option data-bs-offset="-25200" value="Arizona">(GMT-07:00) Arizona</option>
																<option data-bs-offset="-21600" value="Mountain Time (US &amp; Canada) || -06:00">(GMT-06:00) Mountain Time (US &amp; Canada)</option>
																<option data-bs-offset="-21600" value="Chihuahua || -06:00">(GMT-06:00) Chihuahua</option>
																<option data-bs-offset="-21600" value="Mazatlan || -06:00">(GMT-06:00) Mazatlan</option>
																<option data-bs-offset="-21600" value="Saskatchewan || -06:00">(GMT-06:00) Saskatchewan</option>
																<option data-bs-offset="-21600" value="Central America ||-06:00">(GMT-06:00) Central America</option>
																<option data-bs-offset="-18000" value="Central Time (US &amp; Canada) || -05:00">(GMT-05:00) Central Time (US &amp; Canada)</option>
																<option data-bs-offset="-18000" value="Guadalajara || -05:00">(GMT-05:00) Guadalajara</option>
																<option data-bs-offset="-18000" value="Mexico City || -05:00">(GMT-05:00) Mexico City</option>
																<option data-bs-offset="-18000" value="Monterrey || -05:00">(GMT-05:00) Monterrey</option>
																<option data-bs-offset="-18000" value="Bogota || -05:00">(GMT-05:00) Bogota</option>
																<option data-bs-offset="-18000" value="Lima || -05:00">(GMT-05:00) Lima</option>
																<option data-bs-offset="-18000" value="Quito || -05:00">(GMT-05:00) Quito</option>
																<option data-bs-offset="-14400" value="Eastern Time (US &amp; Canada) || -04:00">(GMT-04:00) Eastern Time (US &amp; Canada)</option>
																<option data-bs-offset="-14400" value="Indiana (East) || -04:00">(GMT-04:00) Indiana (East)</option>
																<option data-bs-offset="-14400" value="Caracas || -04:00">(GMT-04:00) Caracas</option>
																<option data-bs-offset="-14400" value="La Paz || -04:00">(GMT-04:00) La Paz</option>
																<option data-bs-offset="-14400" value="Georgetown || -04:00">(GMT-04:00) Georgetown</option>
																<option data-bs-offset="-10800" value="Atlantic Time (Canada) || -03:00">(GMT-03:00) Atlantic Time (Canada)</option>
																<option data-bs-offset="-10800" value="Santiago || -03:00">(GMT-03:00) Santiago</option>
																<option data-bs-offset="-10800" value="Brasilia || -03:00">(GMT-03:00) Brasilia</option>
																<option data-bs-offset="-10800" value="Buenos Aires || -03:00">(GMT-03:00) Buenos Aires</option>
																<option data-bs-offset="-9000" value="Newfoundland || -02:30">(GMT-02:30) Newfoundland</option>
																<option data-bs-offset="-7200" value="Greenland || -02:00">(GMT-02:00) Greenland</option>
																<option data-bs-offset="-7200" value="Mid-Atlantic || -02:00">(GMT-02:00) Mid-Atlantic</option>
																<option data-bs-offset="-3600" value="Cape Verde Is. || -01:00">(GMT-01:00) Cape Verde Is.</option>
																<option data-bs-offset="0" value="Azores || +00:00">(GMT) Azores</option>
																<option data-bs-offset="0" value="Monrovia || +00:00">(GMT) Monrovia</option>
																<option data-bs-offset="0" value="UTC || +00:00">(GMT) UTC</option>
																<option data-bs-offset="3600" value="Dublin || +01:00">(GMT+01:00) Dublin</option>
																<option data-bs-offset="3600" value="Edinburgh || +01:00">(GMT+01:00) Edinburgh</option>
																<option data-bs-offset="3600" value="Lisbon || +01:00">(GMT+01:00) Lisbon</option>
																<option data-bs-offset="3600" value="London || +01:00">(GMT+01:00) London</option>
																<option data-bs-offset="3600" value="Casablanca || +01:00">(GMT+01:00) Casablanca</option>
																<option data-bs-offset="3600" value="West Central Africa || +01:00">(GMT+01:00) West Central Africa</option>
																<option data-bs-offset="7200" value="Belgrade || +02:00">(GMT+02:00) Belgrade</option>
																<option data-bs-offset="7200" value="Bratislava || +02:00">(GMT+02:00) Bratislava</option>
																<option data-bs-offset="7200" value="Budapest || +02:00">(GMT+02:00) Budapest</option>
																<option data-bs-offset="7200" value="Ljubljana || +02:00">(GMT+02:00) Ljubljana</option>
																<option data-bs-offset="7200" value="Prague || +02:00">(GMT+02:00) Prague</option>
																<option data-bs-offset="7200" value="Sarajevo || +02:00">(GMT+02:00) Sarajevo</option>
																<option data-bs-offset="7200" value="Skopje || +02:00">(GMT+02:00) Skopje</option>
																<option data-bs-offset="7200" value="Warsaw || +02:00">(GMT+02:00) Warsaw</option>
																<option data-bs-offset="7200" value="Zagreb || +02:00">(GMT+02:00) Zagreb</option>
																<option data-bs-offset="7200" value="Brussels || +02:00">(GMT+02:00) Brussels</option>
																<option data-bs-offset="7200" value="Copenhagen || +02:00">(GMT+02:00) Copenhagen</option>
																<option data-bs-offset="7200" value="Madrid || +02:00">(GMT+02:00) Madrid</option>
																<option data-bs-offset="7200" value="Paris || +02:00">(GMT+02:00) Paris</option>
																<option data-bs-offset="7200" value="Amsterdam || +02:00">(GMT+02:00) Amsterdam</option>
																<option data-bs-offset="7200" value="Berlin || +02:00">(GMT+02:00) Berlin</option>
																<option data-bs-offset="7200" value="Bern || +02:00">(GMT+02:00) Bern</option>
																<option data-bs-offset="7200" value="Rome || +02:00">(GMT+02:00) Rome</option>
																<option data-bs-offset="7200" value="Stockholm || +02:00">(GMT+02:00) Stockholm</option>
																<option data-bs-offset="7200" value="Vienna || +02:00">(GMT+02:00) Vienna</option>
																<option data-bs-offset="7200" value="Cairo || +02:00">(GMT+02:00) Cairo</option>
																<option data-bs-offset="7200" value="Harare || +02:00">(GMT+02:00) Harare</option>
																<option data-bs-offset="7200" value="Pretoria || +02:00">(GMT+02:00) Pretoria</option>
																<option data-bs-offset="10800" value="Bucharest || +03:00">(GMT+03:00) Bucharest</option>
																<option data-bs-offset="10800" value="Helsinki || +03:00">(GMT+03:00) Helsinki</option>
																<option data-bs-offset="10800" value="Kiev || +03:00">(GMT+03:00) Kiev</option>
																<option data-bs-offset="10800" value="Kyiv || +03:00">(GMT+03:00) Kyiv</option>
																<option data-bs-offset="10800" value="Riga || +03:00">(GMT+03:00) Riga</option>
																<option data-bs-offset="10800" value="Sofia || +03:00">(GMT+03:00) Sofia</option>
																<option data-bs-offset="10800" value="Tallinn || +03:00">(GMT+03:00) Tallinn</option>
																<option data-bs-offset="10800" value="Vilnius || +03:00">(GMT+03:00) Vilnius</option>
																<option data-bs-offset="10800" value="Athens || +03:00">(GMT+03:00) Athens</option>
																<option data-bs-offset="10800" value="Istanbul || +03:00">(GMT+03:00) Istanbul</option>
																<option data-bs-offset="10800" value="Minsk || +03:00">(GMT+03:00) Minsk</option>
																<option data-bs-offset="10800" value="Jerusalem || +03:00">(GMT+03:00) Jerusalem</option>
																<option data-bs-offset="10800" value="Moscow || +03:00">(GMT+03:00) Moscow</option>
																<option data-bs-offset="10800" value="St. Petersburg || +03:00">(GMT+03:00) St. Petersburg</option>
																<option data-bs-offset="10800" value="Volgograd || +03:00">(GMT+03:00) Volgograd</option>
																<option data-bs-offset="10800" value="Kuwait || +03:00">(GMT+03:00) Kuwait</option>
																<option data-bs-offset="10800" value="Riyadh || +03:00">(GMT+03:00) Riyadh</option>
																<option data-bs-offset="10800" value="Nairobi || +03:00">(GMT+03:00) Nairobi</option>
																<option data-bs-offset="10800" value="Baghdad || +03:00">(GMT+03:00) Baghdad</option>
																<option data-bs-offset="14400" value="Abu Dhabi || +04:00">(GMT+04:00) Abu Dhabi</option>
																<option data-bs-offset="14400" value="Muscat || +04:00">(GMT+04:00) Muscat</option>
																<option data-bs-offset="14400" value="Baku || +04:00">(GMT+04:00) Baku</option>
																<option data-bs-offset="14400" value="Tbilisi || +04:00">(GMT+04:00) Tbilisi</option>
																<option data-bs-offset="14400" value="Yerevan || +04:00">(GMT+04:00) Yerevan</option>
																<option data-bs-offset="16200" value="Tehran || +04:30">(GMT+04:30) Tehran</option>
																<option data-bs-offset="16200" value="Kabul || +04:30">(GMT+04:30) Kabul</option>
																<option data-bs-offset="18000" value="Ekaterinburg || +05:00">(GMT+05:00) Ekaterinburg</option>
																<option data-bs-offset="18000" value="Islamabad || +05:00">(GMT+05:00) Islamabad</option>
																<option data-bs-offset="18000" value="Karachi || +05:00">(GMT+05:00) Karachi</option>
																<option data-bs-offset="18000" value="Tashkent || +05:00">(GMT+05:00) Tashkent</option>
																<option data-bs-offset="19800" value="Chennai || +05:30">(GMT+05:30) Chennai</option>
																<option data-bs-offset="19800" value="Kolkata || +05:30">(GMT+05:30) Kolkata</option>
																<option data-bs-offset="19800" value="Mumbai || +05:30">(GMT+05:30) Mumbai</option>
																<option data-bs-offset="19800" value="New Delhi || +05:30">(GMT+05:30) New Delhi</option>
																<option data-bs-offset="19800" value="Sri Jayawardenepura || +05:30">(GMT+05:30) Sri Jayawardenepura</option>
																<option data-bs-offset="20700" value="Kathmandu || +05:45">(GMT+05:45) Kathmandu</option>
																<option data-bs-offset="21600" value="Astana || +06:00">(GMT+06:00) Astana</option>
																<option data-bs-offset="21600" value="Dhaka || +06:00">(GMT+06:00) Dhaka</option>
																<option data-bs-offset="21600" value="Almaty || +06:00">(GMT+06:00) Almaty</option>
																<option data-bs-offset="21600" value="Urumqi || +06:00">(GMT+06:00) Urumqi</option>
																<option data-bs-offset="23400" value="Rangoon || +06:30">(GMT+06:30) Rangoon</option>
																<option data-bs-offset="25200" value="Novosibirsk || +07:00">(GMT+07:00) Novosibirsk</option>
																<option data-bs-offset="25200" value="Bangkok || +07:00">(GMT+07:00) Bangkok</option>
																<option data-bs-offset="25200" value="Hanoi || +07:00">(GMT+07:00) Hanoi</option>
																<option data-bs-offset="25200" value="Jakarta || +07:00">(GMT+07:00) Jakarta</option>
																<option data-bs-offset="25200" value="Krasnoyarsk || +07:00">(GMT+07:00) Krasnoyarsk</option>
																<option data-bs-offset="28800" value="Beijing || +08:00">(GMT+08:00) Beijing</option>
																<option data-bs-offset="28800" value="Chongqing || +08:00">(GMT+08:00) Chongqing</option>
																<option data-bs-offset="28800" value="Hong Kong || +08:00">(GMT+08:00) Hong Kong</option>
																<option data-bs-offset="28800" value="Kuala Lumpur || +08:00">(GMT+08:00) Kuala Lumpur</option>
																<option data-bs-offset="28800" value="Singapore || +08:00">(GMT+08:00) Singapore</option>
																<option data-bs-offset="28800" value="Taipei || +08:00">(GMT+08:00) Taipei</option>
																<option data-bs-offset="28800" value="Perth || +08:00">(GMT+08:00) Perth</option>
																<option data-bs-offset="28800" value="Irkutsk || +08:00">(GMT+08:00) Irkutsk</option>
																<option data-bs-offset="28800" value="Ulaan Bataar || +08:00">(GMT+08:00) Ulaan Bataar</option>
																<option data-bs-offset="32400" value="Seoul || +09:00">(GMT+09:00) Seoul</option>
																<option data-bs-offset="32400" value="Osaka || +09:00">(GMT+09:00) Osaka</option>
																<option data-bs-offset="32400" value="Sapporo || +09:00">(GMT+09:00) Sapporo</option>
																<option data-bs-offset="32400" value="Tokyo || +09:00">(GMT+09:00) Tokyo</option>
																<option data-bs-offset="32400" value="Yakutsk || +09:00">(GMT+09:00) Yakutsk</option>
																<option data-bs-offset="34200" value="Darwin || +09:30">(GMT+09:30) Darwin</option>
																<option data-bs-offset="34200" value="Adelaide || +09:30">(GMT+09:30) Adelaide</option>
																<option data-bs-offset="36000" value="Canberra || +10:00">(GMT+10:00) Canberra</option>
																<option data-bs-offset="36000" value="Melbourne || +10:00">(GMT+10:00) Melbourne</option>
																<option data-bs-offset="36000" value="Sydney || +10:00">(GMT+10:00) Sydney</option>
																<option data-bs-offset="36000" value="Brisbane || +10:00">(GMT+10:00) Brisbane</option>
																<option data-bs-offset="36000" value="Hobart || +10:00">(GMT+10:00) Hobart</option>
																<option data-bs-offset="36000" value="Vladivostok || +10:00">(GMT+10:00) Vladivostok</option>
																<option data-bs-offset="36000" value="Guam || +10:00">(GMT+10:00) Guam</option>
																<option data-bs-offset="36000" value="Port Moresby || +10:00">(GMT+10:00) Port Moresby</option>
																<option data-bs-offset="36000" value="Solomon Is. || +10:00">(GMT+10:00) Solomon Is.</option>
																<option data-bs-offset="39600" value="Magadan || +11:00">(GMT+11:00) Magadan</option>
																<option data-bs-offset="39600" value="New Caledonia || +11:00">(GMT+11:00) New Caledonia</option>
																<option data-bs-offset="43200" value="Fiji || +12:00">(GMT+12:00) Fiji</option>
																<option data-bs-offset="43200" value="Kamchatka || +12:00">(GMT+12:00) Kamchatka</option>
																<option data-bs-offset="43200" value="Marshall Is. || +12:00">(GMT+12:00) Marshall Is.</option>
																<option data-bs-offset="43200" value="Auckland || +12:00">(GMT+12:00) Auckland</option>
																<option data-bs-offset="43200" value="Wellington || +12:00">(GMT+12:00) Wellington</option>
																<option data-bs-offset="46800" value="Nuku'alofa || +13:00">(GMT+13:00) Nuku'alofa</option>
															</select>
															<span id="timezone1" class="text-danger font-weight-bold"></span>
														</div>


													</div>



													<div className="row mb-6">
														<label className="col-lg-4 col-form-label required fw-bold fs-6">Communication</label>
														<div className="col-lg-8 fv-row">
															<div className="d-flex align-items-center mt-3">

																<label className="form-check form-check-inline form-check-solid me-5">
																	<input className="form-check-input" name="communication[]" id="email" type="checkbox" value="email" onChange={(e) => {

																		setemail(e)

																	}} />
																	<span className="fw-bold ps-2 fs-6">Email</span>
																</label>

																<label className="form-check form-check-inline form-check-solid">
																	<input className="form-check-input" name="communication[]" id="phone" type="checkbox" value="phone" onChange={(e) => {

																		setphone(e)

																	}} />
																	<span className="fw-bold ps-2 fs-6">Phone</span>
																</label>
															</div>
															<span id="communication1" class="text-danger font-weight-bold"></span>
														</div>
													</div>

													<div className="row mb-0">
														<label className="col-lg-4 col-form-label fw-bold fs-6">Allow Marketing</label>
														<div className="col-lg-8 d-flex align-items-center">
															<div className="form-check form-check-solid form-switch fv-row">
																<input className="form-check-input w-45px h-30px" type="checkbox" id="allowmarketing" name="allowmarketing" value="on" />
																<label className="form-check-label" for="allowmarketing"></label>
															</div>
														</div>
													</div>

												</div>

												<div className="card-footer d-flex justify-content-end py-6 px-9">
													<form >
														{
															write ?
																<button type="button" className="btn btn-primary rounded" onClick={updateaccount} >Save Changes</button>
																:
																<button type="button" className="btn btn-primary rounded" disabled >Save Changes</button>
														}
													</form>
												</div>

											</form>

										</div>

									</div>


									<div className="card mb-5 mb-xl-10">

										<div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_signin_method">
											<div className="card-title m-0">
												<h3 className="fw-bolder m-0">Sign-in Method</h3>
											</div>
										</div>
										<div id="kt_account_signin_method" className="collapse show">

											<div className="card-body border-top p-9">

												<div className="d-flex flex-wrap align-items-center mb-10">

													<div id="kt_signin_password" className={`${showForm ? "d-none" : ""}`}>
														<div className="fs-6 fw-bolder mb-1">Password</div>
														<div className="fw-bold text-gray-600">************</div>
													</div>
													<div id="kt_signin_password_edit" className={`${showForm ? "flex-row-fluid" : "flex-row-fluid d-none"}`}>

														<form id="kt_signin_change_password" className="form" novalidate="novalidate">
															<div className="row mb-1">
																<div className="col-lg-4">
																	<div className="fv-row mb-0">
																		<label for="currentpassword" className="form-label fs-6 fw-bolder mb-3">Current Password</label>
																		<input type="password" className="form-control form-control-lg form-control-solid" name="currentpassword" id="currentpassword" />
																	</div>
																</div>
																<div className="col-lg-4">
																	<div className="fv-row mb-0">
																		<label for="newpassword" className="form-label fs-6 fw-bolder mb-3">New Password</label>
																		<input type="password" className="form-control form-control-lg form-control-solid" name="newpassword" id="newpassword" />
																	</div>
																</div>
																<div className="col-lg-4">
																	<div className="fv-row mb-0">
																		<label for="confirmpassword" className="form-label fs-6 fw-bolder mb-3">Confirm New Password</label>
																		<input type="password" className="form-control form-control-lg form-control-solid" name="confirmpassword" id="confirmpassword" />
																	</div>
																</div>
															</div>
															<div className="form-text mb-5">Use 8 or more characters with a mix of letters, numbers & symbols. Password cannot contain "@" and "." Symbols within it.</div>
															<div className="d-flex">

																<button className="btn btn-primary rounded" id="kt_password_submit" onClick={(event) => { updatePassword(); }} >
																	<span className="indicator-label">Update Password</span>
																	<span className="indicator-progress">Please wait...
																		<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
																</button>

																<button onClick={() => { setShowForm(!showForm) }} id="kt_password_cancel" type="button" className="btn btn-color-gray-400 btn-active-light-primary px-6 rounded">Cancel</button>
															</div>
														</form>
													</div>
													<div id="kt_signin_password_button" className={`ms-auto ${showForm ? "d-none" : ""}`}>
														<button onClick={() => { setShowForm(!showForm) }} className="btn btn-light btn-active-light-primary rounded">Reset Password</button>
													</div>
												</div>


											</div>

										</div>

									</div>

									<div className="card mb-5 mb-xl-10">

										<div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_notifications" aria-expanded="true" aria-controls="kt_account_notifications">
											<div className="card-title m-0">
												<h3 className="fw-bolder m-0">Notifications</h3>
											</div>
										</div>

										<div id="kt_account_notifications" className="collapse show">

											<form className="form">

												<div className="card-body border-top px-9 pt-3 pb-4">

													<div className="table-responsive">
														<table className="table table-row-dashed border-gray-300 align-middle gy-6">
															<tbody className="fs-6 fw-bold">

																<tr>
																	<td className="min-w-240px fs-4 fw-bolder">Notifications</td>
																	<td className="w-125px">
																		<label className="form-check-label ps-2" for="kt_settings_notification_email">Email</label>
																	</td>
																	<td className="w-125px">
																		<label className="form-check-label ps-2" for="kt_settings_notification_phone">Phone</label>
																	</td>
																</tr>
																{notification_content.length > 0 ? notification_content.map((obj, index1) => {
																	return (
																		<>
																			<tr>
																				<td>{obj.content}</td>


																				<td>
																					<div className="form-check form-check-solid">
																						<input className="form-check-input" type="checkbox" name={obj.unique_id} value="email" id={`email_${obj.unique_id}`} data-kt-settings-notification="email" />
																						<label className="form-check-label ps-2" for="billing1"></label>
																					</div>
																				</td>
																				<td>
																					<div className="form-check form-check-solid">
																						<input className="form-check-input" type="checkbox" name={obj.unique_id} value="phone" id={`phone_${obj.unique_id}`} data-kt-settings-notification="phone" />
																						<label className="form-check-label ps-2" for="billing2"></label>
																					</div>
																				</td>

																			</tr>

																		</>
																	);
																})
																	:
																	null
																}



															</tbody>
														</table>
													</div>

												</div>

												<div className="card-footer d-flex justify-content-end py-6 px-9">
													{
														write ?
															<button type="button" className="btn btn-primary rounded" onClick={updatenotification} >Save Changes</button>
															:
															<button type="button" className="btn btn-primary rounded" disabled>Save Changes</button>
													}

												</div>

											</form>

										</div>

									</div>

									<br />
								</div>
							</div>
						</div>
					</div>
					{/* {notification_content.length > 0 && <div style={{ "display": "none" }}> setTimeout({loadScripts()},500) </div>} */}
				</>
				:
				<>
					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">

						<div className="toolbar" id="kt_toolbar">
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
								<div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
									<h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Account Setting
										<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
										<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
									</h1>

								</div>

							</div>
						</div>

						<div className="post d-flex flex-column-fluid" id="kt_post">
							<div className="container" id="kt_content_container">
								<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
									<br />

									{state == 2 ?
										<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
										:
										<>

											<div className="flex-lg-row-fluid me-lg-15">
												<div className="card card-flush pt-3 mb-5 mb-lg-10">

													<div className="card-header">
														<div className="card-title">
															<h2 className="fw-bolder">Account Setting</h2>
														</div>
													</div>


													<div className="card-body pt-0">
														<div className="text-gray-500 fw-bold fs-5 mb-5"></div>
														<div className="d-flex align-items-center p-3 mb-2">


														</div>


														<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
															<div className="d-flex flex-stack flex-grow-1">
																<div className="fw-bold">
																	<h4 className="text-gray-800 fw-bolder">Please Login</h4>
																	<div className="fs-6 text-gray-600">
																	</div>
																</div>

															</div>

														</div>

													</div>

												</div>
											</div>

										</>
									}


									<br />
								</div>
							</div>
						</div>
					</div>
				</>
			}


			<div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

				<div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

					<div className="modal-content">

						<div className="modal-header" id="kt_modal_new_address_header">

							<h2>Session Out</h2>

							<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

								<span className="svg-icon svg-icon-1">
									<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
										<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
											<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
											<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
										</g>
									</svg>
								</span>

							</div>

						</div>

						<div className="modal-body py-10 px-lg-17">

							<div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

								<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

									<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
											<rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
											<rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
										</svg>
									</span>


									<div className="d-flex flex-stack flex-grow-1">

										<div className="fw-bold">
											<h4 className="text-gray-800 fw-bolder">Warning</h4>
											<div className="fs-6 text-gray-600">Your session is going to time out</div>
										</div>

									</div>

								</div>

								{clock_timer > 0 &&
									<div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
										<CountdownCircleTimer
											isPlaying
											duration={clock_timer}
											colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
											onComplete={() => [true, 1000]}
										>
											{renderTime}
										</CountdownCircleTimer>
									</div>
								}

							</div>

						</div>

						<div className="modal-footer flex-center">


							<button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
								<span className="indicator-label">Stay Signed in</span>
								<span className="indicator-progress">Please wait...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
							</button>

						</div>

					</div>
				</div>
			</div>


		</>
	);
}
export default AccountSetting;