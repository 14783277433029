import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import { Link } from 'react-router-dom'
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';


export const PaymentResponse = (props) => {
	const [accessToken, setaccessToken] = useState('');
	let [user, setUser] = useState({});
	const [PaymentDetails, setPaymentDetails] = useState({});
	const [loading, setloading] = useState(false);
	let [state, setstate] = useState(2);

	let [timer, setTimer] = useState(0);
	let [clock_timer, setClockTimer] = useState(0);
	//const [organization_id, setOrganization_id] = useState("");

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	const renderTime = ({ remainingTime }) => {

		if (remainingTime === 0) {
			//return <div className="timer">Too lale...</div>;
			window.location.href = "/login";
		}

		return (
			<div className="timer">
				<div className="text">Remaining</div>
				<div className="value">{remainingTime}</div>
				<div className="text">seconds</div>
			</div>
		);
	};

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	useEffect(() => {
		setCookie("lastpage", window.location.href, 1)
		const validateJWT = () => {

			if (getCookie("access_token") != "") {
				var object = {};
				object.token = getCookie("access_token");

				var headers = {
					headers: {
						"Content-Type": "application/json",
						"Accept": "*/*"
					}
				}
				axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var user_object = {};
						user_object.email = res.data.email;
						user_object.name = res.data.name;
						user_object.img = res.data.img;
						setUser(user_object)
					}
					else {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
					.catch(function (error) {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					});

				axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var token = res.data.audience_license;
						if(res.data.organization_flag==0){
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
						if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
							setCookie('access_token', res.data.audience_license, 1)
							setTimer(process.env.REACT_APP_JWT_EXPIRY);
							//setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
						}
						else {
							setTimer(res.data.audience_count)
							//setClockTimer(res.data.audience_count)
						}
					}
					else {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
					.catch(function (error) {
						console.log(error);
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					});
			}
			else {
				swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
					window.location.href = "/login"
				});
			}
		}

		validateJWT();
	}, [])

	useEffect(() => {
		if (timer > 0) {
			const timer_decrease = setInterval(() => {
				setTimer(timer - 1);
				if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
					if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
						setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
						window.$('#kt_modal_timer').modal('show');
					}
				}
			}, 1000);

			return () => clearInterval(timer_decrease);
		}
	}, [timer]);

	useEffect(() => {
		async function getpaymentdetails() {

			var object = {};
			object.email = user.email;
			object.id = props.match.params.id;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-payment-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "id": props.match.params.id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			console.log(body);
			if (body.data == "found" && body.operation == "success") {
				if (body.value.payment_platform == "razorpay") {
					body.value.payment_amount = (JSON.parse(body.value.payment_response).payload.invoice.entity.amount_paid) / 100;
				}
				else {
					if (JSON.parse(body.value.payment_response).amount_total == undefined) {
						body.value.payment_amount = (JSON.parse(body.value.payment_response).data.object.total) / 100;
					}
					else {

						body.value.payment_amount = JSON.parse(body.value.payment_response).amount_total / 100;
					}
				}
				setPaymentDetails(body);

			}
			setstate(1);
			setloading(true);

		}
		user.email && getpaymentdetails();

	}, [user])

	function printInvoice(divValue) {
		console.log("hi");
		var backUp = document.body.innerHTML;
		var divContent = document.getElementById(divValue).innerHTML;
		document.body.innerHTML = divContent;
		window.print();
		document.body.innerHTML = backUp;
	}

	return (
		<>


			<div className="content d-flex flex-column flex-column-fluid" id="kt_content">

				<div className="toolbar" id="kt_toolbar">
					<div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
						<div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
							<h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Payment
								<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
								<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
							</h1>

							<span className="h-20px border-gray-200 border-start mx-4"></span>


							<ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">

								<li className="breadcrumb-item text-muted">
									<Link to="/" className="text-muted text-hover-primary">Home</Link>
								</li>

								<li className="breadcrumb-item">
									<span className="bullet bg-gray-200 w-5px h-2px"></span>
								</li>

								<li className="breadcrumb-item text-dark">Payment</li>

							</ul>

						</div>

					</div>
				</div>

				<div className="post d-flex flex-column-fluid" id="kt_post">
					<div className="container" id="kt_content_container">
						<div className="card  pt-0 pb-5 px-3 px-xl-5">
							<br />
							{PaymentDetails.data == "found" ?
								<>
									<div className="card-body p-lg-20" id="printDiv">

										<div className="d-flex flex-column flex-xl-row">

											<div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">

												<div className="mt-n1">
													<div class="d-flex flex-stack pb-10">
														<a >
															<img alt="Logo" src="/assets/media/svg/brand-logos/code-lab.svg" />
														</a>
													</div>

													<div className="m-0">

														<div className="fw-bolder fs-3 text-gray-800 mb-8">Invoice</div>


														<div className="row g-5 mb-11">

															<div className="col-sm-6">

																<div className="fw-bold fs-7 text-gray-600 mb-1">Date:</div>

																<div className="fw-bolder fs-6 text-gray-800">{moment(PaymentDetails.value.starting_date).format('DD MMMM YYYY')}</div>

															</div>

															{
																props.match.params.response == "success" ?
																	<div className="col-sm-6">

																		<div className="fw-bold fs-7 text-gray-600 mb-1">Expiry Date:</div>


																		<div className="fw-bolder fs-6 text-gray-800 d-flex align-items-center flex-wrap">
																			<span className="pe-2">{moment(PaymentDetails.value.ending_date).format('DD MMMM YYYY')}</span>
																			<span className="fs-7 text-danger d-flex align-items-center">
																				<span className="bullet bullet-dot bg-danger me-2"></span>Expiry in {moment(PaymentDetails.value.ending_date).diff(moment(), 'days') + 1} days</span>
																		</div>

																	</div>
																	:
																	null
															}

														</div>

														{
															props.match.params.response == "success" ?
																<div className="row g-5 mb-12">

																	<div className="col-sm-6">

																		<div className="fw-bold fs-7 text-gray-600 mb-1">Issued For:</div>

																		<div className="fw-bolder fs-6 text-gray-800">{JSON.parse(PaymentDetails.value.billing_details).address_details.first_name} {JSON.parse(PaymentDetails.value.billing_details).address_details.last_name}</div>

																		<div className="fw-bold fs-7 text-gray-600">{JSON.parse(PaymentDetails.value.billing_details).city} {JSON.parse(PaymentDetails.value.billing_details).address_details.state}
																			<br />{JSON.parse(PaymentDetails.value.billing_details).address_details.country} </div>

																	</div>


																	<div className="col-sm-6">

																		<div className="fw-bold fs-7 text-gray-600 mb-1">Issued By:</div>


																		<div className="fw-bolder fs-6 text-gray-800">CodeLab Inc.</div>


																		<div className="fw-bold fs-7 text-gray-600">9858 South 53rd Ave.
																			<br />Matthews, NC 28104</div>

																	</div>

																</div>
																:
																null
														}


														<div className="flex-grow-1">

															<div className="table-responsive border-bottom mb-9">
																<table className="table mb-3">
																	<thead>
																		<tr className="border-bottom fs-6 fw-bolder text-gray-400">
																			<th className="min-w-175px pb-2">Product</th>
																			<th className="min-w-100px text-end pb-2">Amount</th>
																		</tr>
																	</thead>
																	<tbody>
																		<tr className="fw-bolder text-gray-700 fs-5 text-end">
																			<td className="d-flex align-items-center pt-6">
																				<i className="fa fa-genderless text-danger fs-2 me-2"></i>{PaymentDetails.value.product_name}-{PaymentDetails.value.subscription_name}</td>
																			<td className="pt-6 text-dark fw-boldest">{PaymentDetails.value.currency == "EUR" ? "€" : (PaymentDetails.value.currency == "USD") ? "$" : (PaymentDetails.value.currency == "INR") ? "₹" : null} {PaymentDetails.value.payment_amount}</td>
																		</tr>
																	</tbody>
																</table>
															</div>


															<div className="d-flex justify-content-end">

																<div className="mw-300px">

																	<div className="d-flex flex-stack mb-3">

																		<div className="fw-bold pe-10 text-gray-600 fs-7">Subtotal:</div>

																		<div className="text-end fw-bolder fs-6 text-gray-800">{PaymentDetails.value.currency == "EUR" ? "€" : (PaymentDetails.value.currency == "USD") ? "$" : (PaymentDetails.value.currency == "INR") ? "₹" : null} {PaymentDetails.value.payment_amount}</div>

																	</div>

																	<div className="d-flex flex-stack">

																		<div className="fw-bold pe-10 text-gray-600 fs-7">Total</div>

																		<div className="text-end fw-bolder fs-6 text-gray-800">{PaymentDetails.value.currency == "EUR" ? "€" : (PaymentDetails.value.currency == "USD") ? "$" : (PaymentDetails.value.currency == "INR") ? "₹" : null} {PaymentDetails.value.payment_amount}</div>

																	</div>

																</div>

															</div>

														</div>

													</div>

												</div>

											</div>

											<div className="m-0">

												<div className="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten">

													<div className="mb-8">
														{
															(PaymentDetails.value.payment_status == "Verified") ?
																<span className="badge badge-light-success rounded">Payment {PaymentDetails.value.payment_status}</span>
																:
																<span className="badge badge-light-warning rounded">Payment Status Pending</span>
														}

													</div>

													<h6 className="mb-8 fw-boldest text-gray-600 text-hover-primary">PAYMENT DETAILS</h6>

													<div className="mb-6">
														<div className="fw-bold text-gray-600 fs-7">{PaymentDetails.value.payment_platform.split("-")[0][0].toUpperCase()}{PaymentDetails.value.payment_platform.split("-")[0].slice(1)}:</div>
														<div className="fw-bolder text-gray-800 fs-6">{user.email}</div>
													</div>

													<div className="mb-15">
														<div className="fw-bold text-gray-600 fs-7">Payment Term:</div>
														<div className="fw-bolder fs-6 text-gray-800 d-flex align-items-center">{PaymentDetails.value.duration == "month" ? 30 : PaymentDetails.value.duration == "yearly" ? 365 : null} days
														</div>
													</div>



												</div>

											</div>

										</div>


									</div>
									{
										(PaymentDetails.value.payment_status == "Verified") ?
											<div className="d-flex flex-center flex-row-fluid pt-12">
												<button type="button" className="btn btn-sm btn-info me-3 rounded" onClick={(e) => { printInvoice('kt_content_container'); }} >Print Invoice</button>

												{PaymentDetails.value.product_id == "bdua1638osba09376b8b8" ?
													<Link type="button" to="/show-live-alerts" className="btn btn-sm btn-primary me-3 rounded">Create Alerts</Link>
													:
													PaymentDetails.value.product_id == "bdua1638osba09376b8a7" ?
														<Link type="button" to="/create-group" className="btn btn-sm btn-primary me-3 rounded">Create Group</Link>
														:
														null
												}
											</div>
											:
											<div className="d-flex flex-center flex-row-fluid pt-12">
												<Link type="button" to="/" className="btn btn-sm btn-primary me-3">Return To Homepage</Link>
											</div>
									}
								</>
								:
								<>
									{(state == 2) ?
										<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
										:
										<>


											<div className="flex-lg-row-fluid me-lg-15">
												<div className="card card-flush pt-3 mb-5 mb-lg-10">

													<div className="card-header">
														<div className="card-title">
															<h2 className="fw-bolder">Payment Response</h2>
														</div>
													</div>


													<div className="card-body pt-0">


														<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
															<div className="d-flex flex-stack flex-grow-1">
																<div className="fw-bold">
																	<h4 className="text-gray-800 fw-bolder">Something went wrong.</h4>
																	<div className="fs-6 text-gray-600">
																	</div>
																</div>

															</div>

														</div>

													</div>

												</div>
											</div>
										</>
									}
								</>
							}

							<br />
						</div>
					</div>
				</div>
			</div>

			<div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

				<div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

					<div className="modal-content">

						<div className="modal-header" id="kt_modal_new_address_header">

							<h2>Session Out</h2>

							<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

								<span className="svg-icon svg-icon-1">
									<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
										<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
											<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
											<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
										</g>
									</svg>
								</span>

							</div>

						</div>

						<div className="modal-body py-10 px-lg-17">

							<div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

								<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

									<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
											<rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
											<rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
										</svg>
									</span>


									<div className="d-flex flex-stack flex-grow-1">

										<div className="fw-bold">
											<h4 className="text-gray-800 fw-bolder">Warning</h4>
											<div className="fs-6 text-gray-600">Your session is going to time out</div>
										</div>

									</div>

								</div>

								{clock_timer > 0 &&
									<div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
										<CountdownCircleTimer
											isPlaying
											duration={clock_timer}
											colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
											onComplete={() => [true, 1000]}
										>
											{renderTime}
										</CountdownCircleTimer>
									</div>
								}

							</div>

						</div>

						<div className="modal-footer flex-center">

							<button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
								<span className="indicator-label">Stay Signed in</span>
								<span className="indicator-progress">Please wait...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
							</button>

						</div>

					</div>
				</div>
			</div>





		</>);

};

export default PaymentResponse;
