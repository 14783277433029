module.exports.getTemplate = (invoice, customFlag, customObj) => {

    let custom
    if(customFlag=="yes"){
        custom = JSON.parse(customObj)
    }
    else{
        custom = {
            "invoice": true,
            "customer_name": true,
            "order_number": true,
            "issue_date": true,
            "due_date": true,
            "terms": true,
            "sales_person": true,
            "invoice_currency": true,
            "subject": true,
            "customer_notes": true,
            "terms_and_conditions": true
        }
    }

    let template = `
        <div>
            
                <table style="border-collapse:collapse;margin-left:auto;margin-right:auto;" cellspacing="0">
                    <tbody>
                        <tr style="height:90pt">
                            <td style="width:191pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="2">
                                organization image
                            </td>
                            <td style="width:392pt;border-top-style:solid;border-top-width:2pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt" colspan="6">
                                organization address
                            </td>
                        </tr>
                        <tr style="height:48pt">
                            <td style="width:340pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="4">
                                ${custom.invoice?`<p class="s6" style="padding-top: 1pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">Invoice Number: <b>${invoice.invoice_name}</b></p>`:""}
                                ${custom.issue_date?`<p class="s6" style="padding-top: 1pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">Invoice Date: <b>${invoice.invoice_date}</b></p>`:""}
                                ${custom.order_number?`<p class="s6" style="padding-left: 3pt;text-indent: 0pt;text-align: left;">Order Number: <b>${invoice.order_number}</b></p>`:""}
                            </td>
                            <td style="width:243pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt" colspan="4">
                                
                            </td>
                        </tr>
                        <tr style="height:20pt">
                            <td style="width:340pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="4" bgcolor="#98d694">
                                <p class="s8" style="padding-left: 100pt;padding-right: 100pt;text-indent: 0pt;margin-bottom: 0;text-align: center;">Details of Receiver (Billed to)</p>
                            </td>
                            <td style="width:243pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt" colspan="4" bgcolor="#98d694">
                                <p class="s8" style="padding-left: 2pt;text-indent: 0pt;text-align: center;margin-bottom: 0;">Details of Consignee (Shipped to)</p>
                            </td>
                        </tr>
                        <tr style="height:68pt">
                            <td style="width:340pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="4">
                                ${custom.customer_name?`<p class="s7" style="padding-left: 3pt;text-indent: 0pt;line-height: 111%;text-align: left;">Customer Name: <b>${invoice.customer_name}</b></p>`:""}
                                <p class="s7" style="padding-left: 3pt;text-indent: 0pt;line-height: 111%;text-align: left;">Address: <b></b></p>
                            </td>
                            <td style="width:243pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt" colspan="4">

                            </td>
                        </tr>
                        <tr style="height:20pt">
                            <td style="width:26pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" bgcolor="#98d694">
                                <p class="s4" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">Sl</p>
                            </td>
                            <td style="width:165pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" bgcolor="#98d694">
                                <p class="s4" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">Description of Goods</p>
                            </td>
                            <td style="width:20pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" bgcolor="#98d694">
                                <p class="s4" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">Qty</p>
                            </td>
                            <td style="width:49pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" bgcolor="#98d694">
                                <p class="s4" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">Rate (Excl. Tax)</p>
                            </td>
                            <td style="width:39pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" bgcolor="#98d694">
                                <p class="s4" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">Amout</p>
                            </td>
                            <td style="width:36pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" bgcolor="#98d694">
                                <p class="s4" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">Discount</p>
                            </td>
                            <td style="width:45pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" bgcolor="#98d694">
                                <p class="s4" style="padding-top: 4pt;padding-left: 3pt;padding-right: 13pt;text-indent: 0pt;text-align: left;">Tax</p>
                            </td>
                            <td style="width:62pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt" bgcolor="#98d694">
                                <p class="s4" style="padding-top: 4pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">Total</p>
                            </td>
                        </tr>
                        ${
                            invoice.item_array.map((obj,indx)=>{
                                return(
                                    `
                                    <tr style="height:24pt">
                                        <td style="width:26pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                                            <p class="s9" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">${indx+1}</p>
                                        </td>
                                        <td style="width:165pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                                            <p class="s10" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;line-height: 7pt;text-align: left;">${obj.item_name}</p>
                                        </td>
                                        <td style="width:20pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                                            <p class="s9" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">${obj.quantity}</p>
                                        </td>
                                        <td style="width:49pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                                            <p class="s9" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">${invoice.invoice_currency_symbol} ${obj.rate}</p>
                                        </td>
                                        <td style="width:39pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                                            <p class="s9" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">${invoice.invoice_currency_symbol} ${obj.quantity * obj.rate}</p>
                                        </td>
                                        <td style="width:21pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                                            <p class="s9" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">${obj.discount_unit}${obj.discount_type=="amount"?" ":"%"}</p>
                                        </td>
                                        <td style="width:40pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt">
                                            <p class="s9" style="padding-top: 4pt;padding-left: 3pt;text-indent: 0pt;text-align: left;">${obj.tax_id==null?"0":obj.tax_percentage+"%"}</p>
                                        </td>
                                        <td style="width:41pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt">
                                            <p class="s9" style="padding-top: 4pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">${invoice.invoice_currency_symbol}  ${(obj.quantity * obj.rate) - (obj.discount_type == "amount"? obj.discount_unit : obj.quantity*obj.rate * obj.discount_unit / 100 ) + (obj.tax_id==null? 0 : obj.quantity*obj.rate * obj.tax_percentage / 100)}</p>
                                        </td>
                                    </tr>
                                    `
                                )
                            }).join(" ")
                        }
                        
                        <tr style="height:20pt">
                            <td style="width:379pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="5" bgcolor="#98d694">
                                <p class="s8" style="padding-top: 4pt;padding-left: 141pt;padding-right: 138pt;text-indent: 0pt;text-align: center;">Invoice Details</p>
                            </td>
                            <td style="width:142pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="2" bgcolor="#98d694">
                                <p class="s8" style="padding-top: 4pt;padding-right: 1pt;text-indent: 0pt;text-align: right;">Total</p>
                            </td>
                            <td style="width:62pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt" colspan="1" bgcolor="#98d694">
                                <p class="s11" style="padding-top: 4pt;padding-left: 2pt;text-indent: 0pt;text-align: left;">₹27,799.00</p>
                            </td>
                        </tr>
                        <tr style="height:20pt">
                            <td style="width:379pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="5" rowspan="3">
                                ${custom.invoice_currency?`<p class="s6" style="padding-left: 3pt;text-indent: 0pt;text-align: left;">Invoice Currency: <b>${invoice.invoice_currency}</b></p>`:""}
                                ${custom.sales_person?`<p class="s12" style="padding-left: 3pt;text-indent: 0pt;text-align: left;">Sales Person: <b>${invoice.sales_person}</b></p>`:""}
                                ${custom.term?`<p class="s12" style="padding-left: 3pt;text-indent: 0pt;text-align: left;">Payment Terms: <b>${invoice.term}</b> Days</p>`:""}
                                ${custom.due_date?`<p class="s12" style="padding-left: 3pt;text-indent: 0pt;text-align: left;">Invoice Due Date: <b>${invoice.due_date}</b></p>`:""}
                            </td>
                            <td style="width:142pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="2">
                                <p class="s4" style="text-indent: 0pt;text-align: center;margin-bottom: 0;">Tax</p>
                            </td>
                            <td style="width:62pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt" colspan="1">
                                <p class="s4" style="text-indent: 0pt;text-align: center;margin-bottom: 0;">${invoice.tax==null?"0":invoice.tax.tax_percentage+"%"}</p>
                            </td>
                        </tr>
                        <tr style="height:20pt">
                            <td style="width:142pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="2">
                                <p class="s4" style="text-indent: 0pt;text-align: center;margin-bottom: 0;">${invoice.adjustment.text}</p>
                            </td>
                            <td style="width:62pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt" colspan="1">
                                <p class="s12" style="text-indent: 0pt;text-align: center;margin-bottom: 0;">${invoice.invoice_currency_symbol} ${invoice.adjustment.value}</p>
                            </td>
                        </tr>
                        <tr style="height:20pt">
                            <td style="width:142pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="2" bgcolor="#98d694">
                                <p class="s4" style="text-indent: 0pt;text-align: center;margin-bottom: 0;">Grand Total</p>
                            </td>
                            <td style="width:62pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt" colspan="1" bgcolor="#98d694">
                                <p class="s11" style="text-indent: 0pt;text-align: center;margin-bottom: 0;">${invoice.invoice_currency_symbol} ${invoice.grand_total}</p>
                            </td>
                        </tr>
                        <tr style="height:20pt">
                            <td style="width:358pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="5" bgcolor="#98d694">
                                <p class="s8" style="padding-top: 4pt;padding-left: 63pt;padding-right: 61pt;text-indent: 0pt;text-align: center;">CUSTOMER NOTES</p>
                            </td>
                            <td style="width:225pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:2pt" colspan="3" bgcolor="#98d694">
                                <p class="s8" style="padding-top: 4pt;padding-left: 57pt;text-indent: 0pt;text-align: left;">Authorized Signature</p>
                            </td>
                        </tr>
                        <tr style="height:28pt">
                            <td style="width:358pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="5">
                                ${custom.customer_notes?`<p style="text-indent: 0pt;text-align: left;"><b>${invoice.customer_notes}</b></p>`:""}
                            </td>
                            <td style="width:225pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:1pt;border-bottom-style:solid;border-bottom-width:2pt;border-right-style:solid;border-right-width:2pt" colspan="3" rowspan="3">
                                
                            </td>
                        </tr>
                        <tr style="height:20pt">
                            <td style="width:358pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:1pt;border-right-style:solid;border-right-width:1pt" colspan="5" bgcolor="#98d694">
                                <p class="s8" style="padding-top: 4pt;padding-left: 63pt;padding-right: 61pt;text-indent: 0pt;text-align: center;">TERM &amp; CONDITIONS</p>
                            </td>
                        </tr>
                        <tr style="height:18pt">
                            <td style="width:358pt;border-top-style:solid;border-top-width:1pt;border-left-style:solid;border-left-width:2pt;border-bottom-style:solid;border-bottom-width:2pt;border-right-style:solid;border-right-width:1pt" colspan="5" rowspan="4">
                                ${custom.terms_and_conditions?`<p class="s13" style="padding-left: 3pt;text-indent: 0pt;line-height: 131%;text-align: justify;"><b>${invoice.terms_and_conditions}</b></p>`:""}
                            </td>
                        </tr>
                    </tbody>
                </table>
            
        </div>
    `

    return template
}