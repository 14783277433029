import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import Select from 'react-select';

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

// import removeImg from './icons/remove.svg'
// import uuid from 'react-uuid';
// import moment from 'moment';
// import {Modal} from "react-bootstrap";
// import Dropzone from 'react-dropzone-uploader';
// import 'react-dropzone-uploader/dist/styles.css'
import $ from 'jquery';


function ExpensesBulk(props) {
    const [user, setUser] = useState({});
    const [organization_id,setOrganization_id] = useState("")
    const [organization_list, setorganization_list] = useState([]);
    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);
    const [state, setstate] = useState(2);

    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
	const [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);

    const [categoryList, setCategoryList] = useState([])
    const [currencyList, setCurrencyList] = useState([])
    const [customerList, setCustomerList] = useState([])
    const [projectList, setProjectList] = useState([])
    const [currrencyExchangeRates, setCurrrencyExchangeRates] = useState([])
    const [organizationDetails, setOrganizationDetails] = useState(null)

    const [expenseBulkArray, setExpenseBulkArray] = useState([])

    const [submitButtonState, setSubmitButtonState] = useState(false);


	const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);
	
	var page_name = "add-bulk-expenses";
	

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true);
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    setRedirect_to_login(1);
                });
                
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if(temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else
                                    {
                                        temp = 2;
                                    }
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
							
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setRedirect_to_login(1);
                });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }

                // console.log(timer)
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);




    
    useEffect(() => {
      
        let initCount = 10
        let temp = []
        for(let i=0;i<initCount;i++){
            temp.push({expenseDate:"", account:null, currency:null, amount:0, notes:"", expenseName:"", customer:null, project:null, billable:false, markUp:0})
        }
        setExpenseBulkArray(temp)
    }, [])

    async function getExpenseCategories() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/category-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCategoryList(body)
        }
    }
    async function getCurrencies() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCurrencyList(body)
        }
    }
    async function getCustomers() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/customer-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCustomerList(body)
        }
    }
    async function getExpenseProjects() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/project-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setProjectList(body)
        }
    }
    async function getCurrencyExchangeRates() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-live-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCurrrencyExchangeRates(body)
        }
    }
    async function getOrganizationDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/show-organization`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setOrganizationDetails(body.find(x=>x.organization_id == getCookie("organization_id")))
        }
    }

    useEffect(() => {
		if ((read || write) && (user.email && visibility)) {
			let p1 = getExpenseCategories();
            let p2 = getCurrencies();
            let p3 = getCustomers();
            let p4 = getExpenseProjects();
            let p5 = getCurrencyExchangeRates();
            let p6 = getOrganizationDetails();
            Promise.all([p1,p2,p3,p4])
            .then(()=>{
                setstate(1)
                console.log("all apis done")
            })
            .catch((err)=>{
                console.log(err)
            })
		}

	}, [user, visibility, read, write])    

    const bulkInvoiceExpenseSubmit = () => {

        let finalArr = expenseBulkArray.filter((obj)=>{
            if(!((obj.expenseDate == "") && (obj.account == null) && (obj.currency == null) && (obj.amount == 0) && (obj.notes == "") && (obj.expenseName == "") && (obj.customer == null) && (obj.project == null) && (obj.billable == false) && (obj.markUp == 0)))
                return true
        })

        if(finalArr.length==0){
            swal("Oops!", "Enter some expenses before submit", "warning");
            return
        }

        let flag = true
        for(let i=0;i<finalArr.length;i++){
           let obj = finalArr[i] 

           if(obj.expenseDate=="")
            {
                swal("Oops!", "Date cannot be empty", "warning");
                flag = false
                break
            }
            if(obj.account==null)
            {
                swal("Oops!", "Expense Category/Account cannot be empty", "warning");
                flag = false
                break
            }
            if(obj.currency==null)
            {
                swal("Oops!", "Currency cannot be empty", "warning");
                flag = false
                break
            }
            if(obj.amount==0)
            {
                swal("Oops!", "Amount cannot be 0", "warning");
                flag = false
                break
            }
            if(obj.expenseName=="")
            {
                swal("Oops!", "Expense Name cannot be empty", "warning");
                flag = false
                break
            }
            let reg = /^[a-zA-Z_]{1,10}\-\d{1,6}$/gm
            if(!reg.test(obj.expenseName))
            {
                swal("Oops!", "Expense Name not valid. i.e. EXP-000001", "warning");
                return false
            }
        }
        if(!flag){
            return
        }

        let t1 = finalArr.map(x=>x.expenseName)
        let t2 = new Set(t1)
        if(t1.length!=t2.size){
            swal("Oops!", "Expense Name/Reference cannot have duplicates", "warning");
            return
        }

        finalArr = finalArr.map((obj)=>{
            let tObj = {}
            tObj.due_date = obj.expenseDate

            let org_c_code = currencyList.find(x=>x.currency_id==organizationDetails.currency).currency_code 
            let cust_c_code = obj.customer!=null && JSON.parse(customerList.find(x=>x.cid==obj.customer.value).currency).currency_code
            
            let org_c_rate = (org_c_code=="USD")?1:currrencyExchangeRates.data.quotes["USD"+org_c_code]
            let cust_c_rate = (cust_c_code=="USD")?1:currrencyExchangeRates.data.quotes["USD"+cust_c_code]
            let exp_c_rate = (obj.currency.label=="USD")?1:currrencyExchangeRates.data.quotes["USD"+obj.currency.label]

            let exchange_rate ={
                organization:{base_curr:"USD" ,quote_curr: org_c_code, rate: org_c_rate},
                customer:{base_curr:"USD" ,quote_curr: cust_c_code, rate: cust_c_rate},
                expense:{base_curr:"USD" ,quote_curr: obj.currency.label, rate: exp_c_rate},
            }

            let ic = currencyList.find(x=>x.currency_id==obj.currency.value)
            tObj.expense_currency = {currency_id:ic.currency_id, currency_symbol:ic.currency_symbol, currency_code:ic.currency_code, format:ic.format, decimal_place:ic.decimal_place, exchange_rate: exchange_rate}

            tObj.accounts = [{account:obj.account.value,amount:obj.amount,notes:obj.notes}]
            tObj.total_amount = obj.amount
            tObj.reference = obj.expenseName
            tObj.customer_id = obj.customer==null?undefined:{customer_id: obj.customer.value, customer_name: obj.customer.label, customer_email: customerList.find(x=>x.cid == obj.customer.value).email}
            tObj.project_id = obj.project==null?undefined:obj.project.value
            tObj.billable = obj.billable
            tObj.mark_up = obj.markUp

            return tObj
        })

        var object = {};

        object.bulk_invoice_expenses = finalArr

        object.organization_id = organization_id
		object.page_name = page_name;

        // console.log(object);
        setSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-bulk-invoice-expense`, object, headers).then((res) => {
            setSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Bulk Expenses added successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else if(res.data.operation === "same_invoice_expense_reference") {
                swal("Oops!", `These Expense Names/References exist already:\n ${res.data.values.join(", ")}`, "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    return ( 
        <>
			{
               (redirect == 1 || (read == 0 && write == 0)) ? 
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> :
                null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}

            
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div className="d-flex align-items-center me-3 lh-1">
                            <h1 className="text-dark fw-bolder my-1 fs-3">Add Bulk Expenses</h1>
                        </div>
                    </div>
                </div>

                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div id="kt_content_container" className="container">

                        <div className="card">
                            <div className="card-body">
                                {
                                    state == 1 ?
                                    <div className='container-fluid'>

                                        <div className='w-100' style={{overflowX:"auto"}}> 
                                            <div className='container p-0' style={{minWidth:"2000px"}}>
                                                <div className="row mb-5">
                                                    <div className="col-1 text-center"><label className="required fs-7 fw-bold">Expense Date</label></div>
                                                    <div className="col-2 text-center"><label className="required fs-7 fw-bold">Category</label></div>
                                                    <div className="col-2 text-center"><label className="required fs-7 fw-bold">Amount</label></div>
                                                    <div className="col-1 text-center"><label className="fs-7 fw-bold">Note</label></div>
                                                    <div className="col-1 text-center"><label className="required fs-7 fw-bold">Expense#</label></div>
                                                    <div className="col-2 text-center"><label className="fs-7 fw-bold">Customer</label></div>
                                                    <div className="col-1 text-center"><label className="fs-7 fw-bold">Projects</label></div>
                                                    <div className="col-2 d-flex text-center">
                                                        <label className="fs-7 fw-bold">Billable</label>
                                                        <label className="fs-7 fw-bold flex-grow-1">Mark up(%)</label>
                                                    </div>
                                                </div>
                                                {
                                                    expenseBulkArray.map((obj,indx)=>{
                                                        return(
                                                            <>
                                                                <div className="row mb-5">
                                                                    <div className="col-1 my-auto">
                                                                        <input type="date" className="form-control form-control-solid"
                                                                            value={expenseBulkArray[indx].expenseDate} 
                                                                            onChange={(e)=>{
                                                                                let temp = expenseBulkArray.map(a=>{return{...a}})
                                                                                temp[indx].expenseDate = e.target.value
                                                                                setExpenseBulkArray(temp)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-2 my-auto">
                                                                        <Select
                                                                            options={ categoryList.map(x=>{return {label:x.category_name,value:x.id} }) }
                                                                            onChange={(val) => {
                                                                                let temp = expenseBulkArray.map(a=>{return{...a}})
                                                                                temp[indx].account = val
                                                                                setExpenseBulkArray(temp)
                                                                            }}        
                                                                            value={expenseBulkArray[indx].account}
                                                                        />
                                                                    </div>
                                                                    <div className="col-2 form-group d-flex align-items-center">
                                                                        <Select
                                                                            options={ currencyList.map(x=>{return {label:x.currency_code,value:x.currency_id} }) }
                                                                            onChange={(val) => {
                                                                                let temp = expenseBulkArray.map(a=>{return{...a}})
                                                                                temp[indx].currency = val
                                                                                setExpenseBulkArray(temp)
                                                                            }}        
                                                                            value={expenseBulkArray[indx].currency}
                                                                            styles={{
                                                                                container: (provided, state) => ({
                                                                                    ...provided,
                                                                                    minWidth: "100px"
                                                                                }),
                                                                                menu: (provided, state) => ({
                                                                                    ...provided,
                                                                                    minWidth: "100px"
                                                                                })
                                                                            }}
                                                                            placeholder="CCY..."
                                                                        />
                                                                        <input type="number" className="form-control form-control-solid flex-grow-1" 
                                                                            value={expenseBulkArray[indx].amount} 
                                                                            onChange={(e) => {
                                                                                if(parseFloat(e.target.value)>=0){
                                                                                    let temp = expenseBulkArray.map(a=>{return{...a}})
                                                                                    temp[indx].amount = parseFloat(e.target.value) 
                                                                                    setExpenseBulkArray(temp)
                                                                                }
                                                                            }} 
                                                                        />
                                                                    </div>
                                                                    <div className="col-1 my-auto">
                                                                        <textarea className="form-control form-control-solid" rows="1" 
                                                                            value={expenseBulkArray[indx].notes} 
                                                                            onChange={(e) => {
                                                                                let temp = expenseBulkArray.map(a=>{return{...a}})
                                                                                temp[indx].notes = e.target.value
                                                                                setExpenseBulkArray(temp)
                                                                            }} 
                                                                        ></textarea>
                                                                    </div>
                                                                    <div className="col-1 my-auto">
                                                                        <input type="text" className="form-control form-control-solid" 
                                                                            value={expenseBulkArray[indx].expenseName} 
                                                                            onChange={(e) => {
                                                                                let temp = expenseBulkArray.map(a=>{return{...a}})
                                                                                temp[indx].expenseName = e.target.value.trim()
                                                                                setExpenseBulkArray(temp)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="col-2 my-auto">
                                                                        <Select
                                                                            options={ customerList.map(x=>{return {label:x.customer_display_name,value:x.cid} }) }
                                                                            onChange={(val) => {
                                                                                let temp = expenseBulkArray.map(a=>{return{...a}})
                                                                                temp[indx].customer = val
                                                                                temp[indx].project = null
                                                                                temp[indx].billable = false
                                                                                temp[indx].markUp = 0
                                                                                setExpenseBulkArray(temp)
                                                                            }}        
                                                                            value={expenseBulkArray[indx].customer}
                                                                            isClearable
                                                                        />
                                                                    </div>
                                                                    <div className="col-1 my-auto">
                                                                        <Select
                                                                            options={ projectList.map(x=>{return {label:x.project_name,value:x.project_id} }) }
                                                                            onChange={(val) => {
                                                                                let temp = expenseBulkArray.map(a=>{return{...a}})
                                                                                temp[indx].project = val
                                                                                setExpenseBulkArray(temp)
                                                                            }}        
                                                                            value={expenseBulkArray[indx].project}
                                                                            isDisabled={expenseBulkArray[indx].customer==null}
                                                                            isClearable
                                                                        />
                                                                    </div>
                                                                    <div className="col-2 d-flex align-items-center">
                                                                        <div className="form-check form-check-solid form-check-success form-switch me-5 my-auto p-0">
                                                                            <input className="form-check-input w-45px h-30px mx-auto" type="checkbox" 
                                                                                checked={expenseBulkArray[indx].billable} 
                                                                                onChange={(e)=>{
                                                                                    let temp = expenseBulkArray.map(a=>{return{...a}})
                                                                                    temp[indx].billable = e.target.checked  
                                                                                    temp[indx].markUp = 0
                                                                                    setExpenseBulkArray(temp)
                                                                                }}
                                                                                disabled={expenseBulkArray[indx].customer==null}
                                                                            />
                                                                        </div>
                                                                        <input type="number" className="form-control form-control-solid" 
                                                                            value={expenseBulkArray[indx].markUp} 
                                                                            onChange={(e)=>{
                                                                                if(parseFloat(e.target.value)>=0){
                                                                                    let temp = expenseBulkArray.map(a=>{return{...a}})
                                                                                    temp[indx].markUp = parseFloat(e.target.value)
                                                                                    setExpenseBulkArray(temp)
                                                                                }
                                                                            }}
                                                                            disabled={!expenseBulkArray[indx].billable}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>

                                        {
                                            expenseBulkArray.length<20 &&
                                            <button className="btn btn-link text-hover-primary" 
                                                onClick={() => {
                                                    let temp = expenseBulkArray.map(x=>{return {...x}})
                                                    temp.push({expenseDate:"", account:null, currency:null, amount:0, notes:"", expenseName:"", customer:null, project:null, billable:false, markUp:0})
                                                    setExpenseBulkArray(temp)
                                                }}>+ Add More Expenses 
                                            </button>
                                        }
                                        <br/>
                                        {
                                            write == 1 &&
                                            <div className='d-flex justify-content-center'>
                                                <button type="button" className="btn btn-lg btn-primary mt-15 rounded" data-kt-indicator={submitButtonState?"on":"off"} disabled={submitButtonState} onClick={() => { bulkInvoiceExpenseSubmit() }}>
                                                    <span className="indicator-label">Submit
                                                        <span className="svg-icon svg-icon-3 ms-2 me-0">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <polygon points="0 0 24 0 24 24 0 24" />
                                                                    <rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
                                                                    <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </span>
                                                    <span className="indicator-progress">Please wait...
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span>
                                                </button>
                                            </div>
                                        }

                                    </div>
                                    :state == 2 ?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :
                                    <>
                                        <div className="flex-lg-row-fluid me-lg-15">
                                            <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                                <div className="card-body pt-0">
                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <h4 className="text-gray-800">Something went wrong, Please refresh the page</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>



            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org}/>
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer}/>
        </>
    );
}
export default ExpensesBulk;