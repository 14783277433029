import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom'
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import Select from 'react-select';
import removeImg from './icons/remove.svg'
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import uuid from 'react-uuid';

import country_arr from './countries-arr.js';
import s_a from './states-arr.js';

function Manufacturer(props) {
    let [user, setUser] = useState({});

    let [currency_price, setCurrencyPrice] = useState("");
    let [currency_date, setCurrencyDate] = useState("");

    let [loader, setLoader] = useState(0);

    let [currency_value, setCurrencyValue] = useState("");
    let [currency_id, setCurrencyId] = useState("");

    let [state, setstate] = useState(2);

    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);
    let [subscription, set_subscription] = useState("");
    let [duration, setduration] = useState("");
    const [PlatformDetails, setPlatformDetails] = useState([]);

    let [photoName, setPhotoName] = useState([""]);
    let [idNames, setIdNames] = useState(["", "", ""]);
    let [fileNames, setFileNames] = useState(["", "", "", ""]);
    let [State, setState] = useState("");
    let [Country, setCountry] = useState("");
    const [unique_id, setUnique_id] = useState(uuid());
	
	let [country_array, set_country_array] = useState([]);

    let [state_array, set_state_array] = useState([]);
    //const [organization_id, setOrganization_id] = useState("");
	const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);
	
	var page_name = "add-manufacturer";
	
	setPhotoName = (name) => {
        photoName.push(name)
        let uniq = photoName => [...new Set(photoName)];
        photoName = photoName.slice(-1)
    }
    setIdNames = (name) => {
        idNames.push(name)
        let uniq = idNames => [...new Set(idNames)];
        idNames = idNames.slice(-3)
    }
    setFileNames = (photoName,idNames) => {
        fileNames=[...photoName,...idNames]
    }
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });

                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;

                        if(res.data.organization_flag==0){
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
						else
						{
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
						
						
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            res.data.audience_count = (res.data.audience_count)
                            if (res.data.audience_count > 0) {
                                const timer_decrease = setInterval(() => {
                                    res.data.audience_count = (res.data.audience_count - 1);
                                    //console.log(res.data.audience_count);
                                    if (res.data.audience_count > 0 && res.data.audience_count < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                                        if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                                            setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                                            window.$('#kt_modal_timer').modal('show');
                                        }
                                    }
                                }, 1000);

                                return () => clearInterval(timer_decrease);
                            }
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }

        validateJWT();

    }, [])



    function loadScripts() {

        var elem = document.getElementById("create_account");
        if (!elem) {
            var se = document.createElement('script');
            se.src = "/assets/js/custom/modals/create-account.js";
            se.id = "create_account";
            document.body.appendChild(se);
        }
    }
	
	useEffect(() => {
		var obj_array = [];
		var obj2 = {};
		country_arr.country_arr.map((obj, index) => {
			obj2 = {};
			obj2.label = obj;
			if (index > 0) {
				obj2.value = obj;
			}
			else {
				obj2.value = "";
			}
			obj_array.push(obj2);
		})
		set_country_array(obj_array);
	}, []);

    const register = () => {

        var object = {};
        object.unique_id = unique_id;
        //object.organization_id = organization_id;
        object.manufacturer_name = document.getElementById("manufacturer_name").value;
        object.email = document.getElementById("email").value;
        object.phone = document.getElementById("phone").value;
        object.mobile = document.getElementById("mobile").value;

        object.pincode = document.getElementById("pin").value;
        object.city = document.getElementById("city").value;
        object.state = State;
        object.country = Country;
        object.street = document.getElementById("street").value;
        object.website = document.getElementById("website").value;
        object.note = document.getElementById("note").value;
        object.file_names_only=fileNames
		object.page_name = page_name;
        
        
        var nexp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (object.manufacturer_name == "") {
            swal("Warning!", "Manufacturer Name cannot be a empty", "warning");
            return false;
        }
        else if (!isNaN(object.manufacturer_name)) {
            swal("Warning!", "Manufacturer Name cannot be a number", "warning");
            return false;
        }
        else if (object.manufacturer_name.match(nexp)) {
            swal("Warning!", "Manufacturer Name cannot contain symbols ", "warning");
            return false;
        }

        if (object.phone.length == 0) {
            swal("Oops!", "Please fill valid phone number", "error");
            return false;
        }
        

        var exp = /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (object.email.length == 0) {
            swal("Oops!", "Please enter a valid email id", "error");
            return false;
        }
        else if (!object.email.match(exp)) {
            swal("Oops!", "Please enter a valid email id", "error");
            return false;
        }
		
		var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
		if (!regexp.test(object.website))
		{
			swal("Warning!", "Please enter valid Website URL", "warning");
			return false;
		}
			
		if (object.website == "")
		{
            swal("Warning!", "Website URL cannot be a empty", "warning");
            return false;
        }
		
		if (object.street == "") {
            swal("Warning!", "Street cannot be a empty", "warning");
            return false;
        }
		if (object.city == "") {
            swal("Warning!", "City Name cannot be a empty", "warning");
            return false;
        }
        if (object.state == "") {
            swal("Warning!", "State Name cannot be a empty", "warning");
            return false;
        }
        if (object.country == "") {
            swal("Warning!", "Country Name cannot be a empty", "warning");
            return false;
        }


        if (object.pincode.length == 0) {
            swal("Warning!", "Please enter a valid pin number", "error");
            return false;
        }

        if (object.file_names_only[0] == "") {
            swal("Warning!", "Please upload a Profile Photo", "error");
            return false;
        }
        else if (object.file_names_only[1] == "") {
            swal("Warning!", "Please upload atleast one Identity Proof", "error");
            return false;
        }

        console.log(object);

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/manufacturers`, object, headers).then((res) => {
            if (res.data.operation === "same_email") {
                swal("Oops!", "Email already exist!!", "error");
            }
            else if (res.data.operation === "success") {
                swal("Great!", "Manufacturer registered successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else if (res.data.operation === "filenotuploaded") {
                swal("Oops!", "You have not uploaded any images or documents!", "error");
            }

            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
            });
    }
    var files = [];

    const CustomLayout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {
        // Remove previews which do not pass the validation
        const previewsToDisplay = previews.filter((preview) => {
            return preview.props.meta.status !== 'error_validation';
        });

        return (

            <div {...dropzoneProps}>
                {previewsToDisplay}
                {files.length < maxFiles && input}
            </div>

        );
    };

    const handleChangeStatus2 = ({ meta }, status) => {
        // console.log('handleChangeStatus2');
        const { name } = meta;
        var sendButton = document.getElementById("kt_contact_submit_button");
        // console.log(sendButton);
        // console.log(name)
        let updatedFiles = [];
        if (status === 'done' || status === 'removed') {
            // Add meta to files
            if (status === 'done') {
                updatedFiles = [...files, meta];
                sendButton.setAttribute("data-kt-indicator", "off");
                sendButton.disabled = false;
            }
            // Remove meta from files
            else if (status === 'removed') {
                sendButton.setAttribute("data-kt-indicator", "off");
                sendButton.disabled = false;
                
                photoName = photoName.filter(item => item !== String(name))
                if (photoName.length === 0) {
                    photoName.push("")
                }
                idNames = idNames.filter(item => item !== String(name))
                if (idNames.length <3) {
                    for(let i=idNames.length;i<3;i++){
                        idNames.push("");
                        idNames.sort()
                        idNames.reverse(); 
                    }
                }
                if (fileNames.length === 0) {
                    fileNames.push("")
                    fileNames.push("")
                    fileNames.push("")
                    fileNames.push("")
                }
                setFileNames(photoName,idNames)
            }
            else {
                updatedFiles = files.filter(files, (file) => {
                    return file.name !== meta.name
                        || file.size !== meta.size
                        || file.type !== meta.type;
                });

            }
            files = updatedFiles;
        }
        console.log(fileNames)
    };

    const handleSubmit = (files, allFiles) => {
        allFiles.forEach(f => f.remove());
        files = [];
    };
    const handleValidation2 = ({ meta }) => {
        return files.find(e => e.name === meta.name && e.size === meta.size && e.type === meta.type);
    }


    const icon = {
        remove: { backgroundImage: `url(${removeImg})` },
    }

    const Preview = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        // console.log(meta);
        setPhotoName(String(name))
        setFileNames(photoName,idNames)
        return (

            <div className="preview-box">
                <img src={previewUrl} />
                <span className="name">{name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>
                {status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
                {status === "done" && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
            </div>
        )
    }
    const Preview2 = ({ fileWithMeta: { remove }, meta }) => {
        const { name, percent, status, previewUrl } = meta;
        // console.log(meta);
        setIdNames(String(name))
        setFileNames(photoName,idNames)
        return (

            <div className="preview-box">
                <img src={previewUrl} />
                <span className="name">{name}</span> - <span className="status">{(status === "done") ? "Successfully Uploaded" : status}</span>
                {status !== "done" && <span className="percent"> ({Math.round(percent)}%)</span> && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
                {status === "done" && <span className="dzu-previewButton remove" style={icon.remove} onClick={remove} />}
            </div>
        )
    }

    const getUploadParams = ({ file }) => {
        console.log(file);
        const body = new FormData();
        body.append('dataFiles', file);
        body.append('unique_id', unique_id)
        body.append('user_id', user.email);
		body.append('page_name', page_name);
		
        //body.append('organization_id', organization_id);
        // console.log(body);
        var sendButton = document.getElementById("kt_contact_submit_button");
        console.log(sendButton);
        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        sendButton.setAttribute("data-kt-indicator", "on");
        sendButton.disabled = true;
        return { url: `${process.env.REACT_APP_SERVER_URL}/uploadmanufacturerprofile`, headers, body }
    }
    const getUploadParams2 = ({ file }) => {
        //console.log(file);
        const body = new FormData();
        body.append('dataFiles', file);
        body.append('unique_id', unique_id)
        body.append('user_id', user.email);
		body.append('page_name', page_name);
		
        //body.append('organization_id', organization_id);
        var sendButton = document.getElementById("kt_contact_submit_button");
        console.log(sendButton);
        // console.log(body);
        let headers = {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            "token": getCookie("access_token")
        }
        sendButton.setAttribute("data-kt-indicator", "on");
        sendButton.disabled = true;
        return { url: `${process.env.REACT_APP_SERVER_URL}/uploadmanufacturerdocuments`, headers, body }
    }
	
	function loadCountry(country) {

		setCountry(country.value);

		var selectedCountryIndex = 0;
		country_array.map((obj, index) => {
			if (obj.value == country.value) {
				selectedCountryIndex = index;
			}
		})

		var state_arr = s_a.s_a[selectedCountryIndex].split("|");
		//console.log(state_arr)
		var obj_array = [];
		var obj2 = {};
		//obj_array.push({label:"select state",value:""});
		obj_array.push({ value: "", label: "Select State" });
		for (let i = 0; i < state_arr.length; i++) {
			obj_array.push({ value: state_arr[i], label: state_arr[i] });
		}
		set_state_array(obj_array);
	}

    // handle the status of the file upload
    const handleChangeStatus = ({ xhr }) => {
        //console.log('handleChangeStatus');
        if (xhr) {
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    const result = JSON.parse(xhr.response);
                    // console.log(result);
                }
            }
        }
    }

    return (
        <>
			{
               (redirect == 1 || (read == 0 && write == 0)) ? 
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> :
                 null
            }

            {user.email ?
                <>

                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">

                                <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                                    <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Add Manufacturer
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                        <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                    </h1>

                                </div>

                            </div>
                        </div>

                        <div class="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" class="container">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="stepper stepper-links d-flex flex-column" id="kt_create_account_stepper">
                                            <div class="stepper-nav py-5 mt-5">
                                                <div class="stepper-item current" data-kt-stepper-element="nav">
                                                    <h3 class="stepper-title">Basic Information</h3>
                                                </div>
                                                <div class="stepper-item" data-kt-stepper-element="nav">
                                                    <h3 class="stepper-title">Set Your Dddress</h3>
                                                </div>
                                                <div class="stepper-item" data-kt-stepper-element="nav">
                                                    <h3 class="stepper-title">Profile Data</h3>
                                                </div>

                                            </div>
                                            <form class="mx-auto mw-600px w-100 pt-15 pb-10" novalidate="novalidate" id="kt_create_account_form">
                                                <div class="current" data-kt-stepper-element="content">
                                                    <div class="w-100">

                                                        <div class="row mb-5">
                                                            <div class="col-md-6 fv-row">
                                                                <label class="required fs-5 fw-bold mb-2">Manufacturer Name</label>
                                                                <input id="manufacturer_name" type="text" class="form-control form-control-solid" placeholder="" name="manufacturer-name" />
                                                            </div>
                                                            <div class="col-md-6 fv-row">
                                                                <label class="required fs-5 fw-bold mb-2">Email</label>
                                                                <input id="email" type="email" class="form-control form-control-solid" placeholder="email" name="email" />
                                                            </div>
                                                        </div>

                                                        <div class="row mb-5">
                                                            <div class="col-md-6 fv-row">
                                                                <label class="required fs-5 fw-bold mb-2">Phone</label>
                                                                <input id="phone" type="text" class="form-control form-control-solid" placeholder="phone No" name="phone" />
                                                            </div>
                                                            <div class="col-md-6 fv-row">
                                                                <label class="fs-5 fw-bold mb-2">Mobile</label>
                                                                <input id="mobile" type="text" class="form-control form-control-solid" placeholder="Alternative No" name="alternative" />
                                                            </div>
                                                        </div>
                                                        <div class="row mb-5">
                                                            <div class="col-md-6 fv-row">
                                                                <label class="required fs-5 fw-bold mb-2">Website</label>
                                                                <input id="website" type="text" class="form-control form-control-solid" placeholder="Website" name="Website" />
                                                            </div>
                                                           
                                                        </div>


                                                    </div>
                                                </div>
                                                <div data-kt-stepper-element="content">
                                                    <div class="w-100">

                                                        <div class="row mb-5">
                                                            <div class="col-md-12 fv-row">
                                                                <label class="required fs-5 fw-bold mb-2">Street</label>
                                                                <input id="street" type="text" class="form-control form-control-solid" placeholder="" name="full-name" />
                                                            </div>

                                                        </div>


                                                        <div class="row mb-5">
                                                            <div class="col-md-6 fv-row">
                                                                <label class="required fs-5 fw-bold mb-2">City</label>
                                                                <input id="city" type="text" class="form-control form-control-solid" placeholder="" name="city" />
                                                            </div>
                                                            <div class="col-md-6 fv-row">
                                                                <label class="required fs-5 fw-bold mb-2">Pin Code</label>
                                                                <input id='pin' type="text" class="form-control form-control-solid" placeholder="Pin Code" name="pin-code" />
                                                            </div>

                                                        </div>

                                                        <div class="row mb-5">
                                                            <div class="col-md-6 fv-row">
                                                                <div class="d-flex flex-column mb-5 fv-row">
                                                                    <label class="d-flex align-items-center fs-5 fw-bold mb-2">
                                                                        <span class="required">Country</span>
                                                                    </label>
																	
                                                                    <Select
                                                                    name="country" 
                                                                    id="country"
                                                                    options={country_array}
                                                                    onChange={(e) => { setState(""); loadCountry(e);}}
                                                                    />
																	
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 fv-row">
                                                                <div class="d-flex flex-column mb-5 fv-row">
                                                                    <label class="d-flex align-items-center fs-5 fw-bold mb-2">
                                                                        <span class="required">State</span>
                                                                    </label>
																	
                                                                    <Select
                                                                            name="state" 
                                                                            id="state"
                                                                            options={state_array}
                                                                            onChange={(e) => {setState(e.value);}}
                                                                            value  = {
                                                                                state_array.filter(option => 
                                                                                option.value == State)[0]
                                                                            }
                                                                            
                                                                    />
																	
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
												
												
                                                <div data-kt-stepper-element="content">
                                                    <div class="w-100">

                                                    <div class="d-flex flex-column mb-10 fv-row"><label class="fs-6 fw-bold mb-2">Note</label><textarea id="note" class="form-control form-control-solid" rows="3" name="message" placeholder="" autocomplete="off"></textarea></div>
													{
														write == 1 ?
														<>
                                                        <div>
                                                            <label class="fs-5 fw-bold mt-4 mb-2">Upload Profile Photo (Single file upload)</label>
                                                            <Dropzone
                                                                getUploadParams={getUploadParams}
                                                                onChangeStatus={handleChangeStatus, handleChangeStatus2}
                                                                onSubmit={handleSubmit}
                                                                validate={handleValidation2}
                                                                LayoutComponent={CustomLayout}
                                                                styles={{
                                                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                                    inputLabelWithFiles: { margin: '20px 3%' }
                                                                }}
                                                                maxFiles={1}
                                                                // canRemove={false}
                                                                PreviewComponent={Preview}
                                                                accept="image/*,.pdf"
                                                            />
                                                        </div>
                                                        <div className="mt-4">
                                                            <label class="fs-5 fw-bold mb-2">Upload Identity Proof (Maximum 3 files)</label>
                                                            <Dropzone
                                                                getUploadParams={getUploadParams2}
                                                                onChangeStatus={handleChangeStatus, handleChangeStatus2}
                                                                onSubmit={handleSubmit}
                                                                validate={handleValidation2}
                                                                LayoutComponent={CustomLayout}
                                                                styles={{
                                                                    dropzone: { overflow: 'auto', border: '1px solid #999', background: '#f5f5f5' },
                                                                    inputLabelWithFiles: { margin: '20px 3%' }
                                                                }}
                                                                maxFiles={3}
                                                                // canRemove={false}
                                                                PreviewComponent={Preview2}
                                                                accept="image/*,.pdf"
                                                            />
                                                        </div>
														</>
														:
														null
													}
                                                    </div>
                                                </div>
												


                                                <div class="d-flex flex-stack pt-15">
                                                    <div class="mr-2">
                                                        <button type="button" class="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous">
                                                            <span class="svg-icon svg-icon-4 me-1">
                                                                Previous
                                                            </span>
                                                        </button>
                                                    </div>
                                                    <div>
														{
														write == 1 ?
                                                        <button
                                                            type="button"
                                                            id="kt_contact_submit_button"
                                                            class="btn btn-lg btn-primary me-3"
                                                            data-kt-stepper-action="submit"

                                                            onClick={() => { register() }}
                                                        >
                                                            <span class="indicator-label">Submit
                                                                <span class="svg-icon svg-icon-3 ms-2 me-0">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                            <polygon points="0 0 24 0 24 24 0 24" />
                                                                            <rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
                                                                            <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                            </span>
                                                            <span class="indicator-progress">Please wait...
                                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                        </button>
														:
														null
														}
                                                        <button type="button" class="btn btn-lg btn-primary" data-kt-stepper-action="next">Next
                                                            <span class="svg-icon svg-icon-4 ms-1 me-0">
                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                                        <rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
                                                                        <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {<div style={{ "display": "none" }}> setTimeout(function(){loadScripts()},500) </div>}
                </>
                :
                <>
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                                    <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Add Manufacturer
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                        <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                    </h1>

                                </div>

                            </div>
                        </div>

                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div className="container" id="kt_content_container">
                                <div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
                                    <br />

                                    {state == 2 ?
                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        :
                                        <>

                                            <div className="flex-lg-row-fluid me-lg-15">
                                                <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                                    <div className="card-header">
                                                        <div className="card-title">
                                                            <h2 className="fw-bolder">Create Live Alerts</h2>
                                                        </div>
                                                    </div>


                                                    <div className="card-body pt-0">
                                                        <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                        <div className="d-flex align-items-center p-3 mb-2">


                                                        </div>


                                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                            <div className="d-flex flex-stack flex-grow-1">
                                                                <div className="fw-bold">
                                                                    <h4 className="text-gray-800 fw-bolder">Please Login</h4>
                                                                    <div className="fs-6 text-gray-600">
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </>
                                    }


                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            }


            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>


        </>
    );
}
export default Manufacturer;