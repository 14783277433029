import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import Select from 'react-select';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import country_arr from './countries-arr.js';
import s_a from './states-arr.js';

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

// import SetColor from './SetColor.js';

const ShowExpenses = () => {
    const [user, setUser] = useState({});
    const [organization_id,setOrganization_id] = useState("")
    const [organization_list, setorganization_list] = useState([]);
    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);
    const [state, setstate] = useState(0);

    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
	const [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);

    const [data, setData] = useState([]);
    const [expenseList, setExpenseList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [customerList, setCustomerList] = useState([])
    const [editmodal, setEditModal] = useState([]);

    const [fname, setfname] = useState("");
    const [lname, setlname] = useState("");
    const [email, setemail] = useState("");
    const [phone, setphone] = useState("");
    const [rebrand, setrebrand] = useState("");
    const [rebrandmode, setrebrandmode] = useState(false);
    const [status, setstatus] = useState("");
    const [State, setState] = useState("");
    const [Country, setCountry] = useState("");

    let [country_array, set_country_array] = useState([]);
    let [state_array, set_state_array] = useState([]);

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    var page_name = "expenses-list";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        filter: true,
        selectableRows: false,
        fixedHeader: false,
        filterType: "dropdown",
        responsive: "vertical",
        tableBodyHeight: "480px",
        tableBodyMaxHeight: "",
        searchPlaceholder: 'Search an Expense',
    };



    const columns = [
        {
            name: "due_Date",
            label: "Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "accounts",
            label: "Expense Account",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "reference",
            label: "Reference",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "customer",
            label: "Customer Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let color
                    if(value == "NON-BILLABLE"){
                        color = "black"
                    }
                    else if(value == "BILLABLE"){
                        color = "grey"
                    }
                    else if(value == "INVOICED"){
                        color = "red"
                    }
                    
                    return (
                        <p
                            className='my-auto'
                            value={value}
                            onChange={event => updateValue(event.target.value, tableMeta.columnIndex)}
                            style={{ color: color }}>{value}
                        </p>
                    )
                }
            }
        },
        {
            name: "amount",
            label: "Amount",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true);
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    setRedirect_to_login(1);
                });
                
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if(temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else
                                    {
                                        temp = 2;
                                    }
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
							
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setRedirect_to_login(1);
                });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }

                // console.log(timer)
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);





    async function getExpenseDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/invoice-expense-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if(body.length>1){
            setExpenseList(body);
        }
        else{
            throw new Error("no expenses")
        }
    }
    async function getCustomers() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/customer-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)

        if (body.length != 0) {
            setCustomerList(body)
        }
    }
    async function getExpenseCategories() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/category-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)

        if (body.length != 0) {
            setCategoryList(body)
        }
    }

    useEffect(() => {
		if ((read || write) && (user.email && visibility)) {
			let p1 = getExpenseDetails();
            p1.then(()=>{
                let p2 = getExpenseCategories();
                let p3 = getCustomers();
                Promise.all([p2,p3])
                .then(()=>{
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err)=>{
                    setstate(3)
                    console.log(err)
                })
            })
            .catch((errx)=>{
                setstate(2)
                console.log(errx)
            })
		}
	}, [user, visibility, read, write])

    useEffect(() => {

        if ((expenseList.length > 0) && (customerList.length > 0) && (categoryList.length > 0)) {
            let temp_data = []

            expenseList.forEach((obj) => {
                let temp_obj = {};

                temp_obj.due_Date = moment.utc(obj.due_Date).format("DD-MM-YYYY");

                let ta = JSON.parse(obj.accounts)
                temp_obj.accounts = ta.length > 1?"Split":categoryList.find((x)=>x.id==ta[0].account).category_name

                temp_obj.reference = obj.reference;
                temp_obj.customer = obj.customer_id==null?"N/A":JSON.parse(obj.customer_id).customer_name

                temp_obj.status = obj.add_status;
                temp_obj.amount = JSON.parse(obj.expense_currency).currency_symbol + " " + obj.total_amount;

                let id = obj.expense_id;

                temp_obj.actions = <div className="dropdown">    
                    <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-bars px-0"></i></button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        {/* write == 1 && <li><a href="#" onClick={(e) => { editModalInit(id) }} className="dropdown-item">View/Edit Details</a></li> */}
                        {write == 1 && obj.add_status=="UNBILLED" && <li><Link className="dropdown-item" to={`/add-invoice/${id}`}>Convert to Invoice</Link></li>}   
                        {write == 1 && obj.add_status=="NON-BILLABLE" && <li><span className='dropdown-item'>No Options</span></li>}   
                    </ul>
                </div>

                temp_data.push(temp_obj)
            })

            setData(temp_data);
        }

    }, [expenseList,customerList,categoryList])

    const updateReseller = (id) => {
        // console.log(id);
        // console.log(editmodal);
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].sid) {
                const modalid = document.getElementById("EditModal");
                // console.log(editmodal[i]);
                document.getElementById("id").value = id;
                setfname(editmodal[i].fname);
                setlname(editmodal[i].lname);
                setemail(editmodal[i].email);
                setphone(editmodal[i].phone);
                setrebrand(editmodal[i].rebrand);
                setrebrandmode(editmodal[i].rebrand.length == 0 ? false : true)
                setState(editmodal[i].state);
                setCountry(editmodal[i].country);

                var selectedCountryIndex = 0;
                country_array.map((obj, index) => {
                    if (obj.value == editmodal[i].country) {
                        selectedCountryIndex = index;
                    }
                })

                var state_arr = s_a.s_a[selectedCountryIndex].split("|");

                var state_arr = s_a.s_a[selectedCountryIndex].split("|");

                var obj_array = [];
                var obj2 = {};
                state_arr.map((obj, index) => {
                    obj2 = {};
                    obj2.label = obj;

                    obj2.value = obj;

                    obj_array.push(obj2);
                })
                set_state_array(obj_array);
            }
        }
    }
    const updateStatus = (id) => {
        // console.log(id);
        // console.log(editmodal);
        for (let i = 0; i < editmodal.length; i++) {
            if (id == editmodal[i].sid) {
                // console.log(editmodal[i]);
                document.getElementById("st").value = editmodal[i].add_status;
                document.getElementById("id").value = id;
                setstatus(editmodal[i].add_status);
            }
        }
    }

    const submitStatus = (e) => {
        e.preventDefault();

        var id = document.getElementById("id").value;
        var status_value = document.getElementById("st").value;
        var values = { "id": id, "status": status_value, "page_name": page_name, "organization_id": organization_id };

        // console.log(values);

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-reseller`, values, headers).then((res) => {
            // console.log(res)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Status changed successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    useEffect(() => {
        var obj_array = [];
        var obj2 = {};
        country_arr.country_arr.map((obj, index) => {
            obj2 = {};
            obj2.label = obj;
            if (index > 0) {
                obj2.value = obj;
            }
            else {
                obj2.value = "";
            }
            obj_array.push(obj2);
        })
        set_country_array(obj_array);
    }, []);

    function loadCountry(country) {

        setCountry(country.value);

        var selectedCountryIndex = 0;
        country_array.map((obj, index) => {
            if (obj.value == country.value) {
                selectedCountryIndex = index;
            }
        })

        var state_arr = s_a.s_a[selectedCountryIndex].split("|");
        //console.log(state_arr)
        var obj_array = [];
        var obj2 = {};
        //obj_array.push({label:"select state",value:""});
        obj_array.push({ value: "", label: "Select State" });
        for (let i = 0; i < state_arr.length; i++) {
            obj_array.push({ value: state_arr[i], label: state_arr[i] });
        }
        set_state_array(obj_array);
    }

    const submitResellerdetails = (e) => {
        e.preventDefault();
        var id = document.getElementById("id").value;
        var fname = document.getElementById("fname").value;
        var lname = document.getElementById("lname").value;
        var country = Country;
        var state = State;
        var email = document.getElementById("email").value;
        var phone = document.getElementById("phone").value;

        var rebrand = ""
        if (rebrandmode)
            rebrand = document.getElementById("rebrand").value;


        var nexp = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (fname == "") {
            swal("Warning!", "First Name cannot be empty", "warning");
            return false;
        }
        else if (!isNaN(fname)) {
            swal("Warning!", "First Name cannot be a number", "warning");
            return false;
        }
        else if (fname.match(nexp)) {
            swal("Warning!", "First Name cannot contain symbols ", "warning");
            return false;
        }

        if (lname == "") {
            swal("Warning!", "Last Name cannot be empty", "warning");
            return false;
        }
        else if (!isNaN(lname)) {
            swal("Warning!", "Last Name cannot be a number", "warning");
            return false;
        }
        else if (lname.match(nexp)) {
            swal("Warning!", "Last Name cannot contain symbols ", "warning");
            return false;
        }

        // var exp = /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        var exp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email.length == 0) {
            swal("Oops!", "Email id cannot be empty", "error");
            return false;
        }
        else if (!email.match(exp)) {
            swal("Oops!", "Please enter a valid email id", "error");
            return false;
        }
        else if (true) {
            var oemail = editmodal.filter((item) => { return item.sid !== id })
            for (var i = 0; i < oemail.length; ++i) {
                if (oemail[i].email == email) {
                    swal("Oops!", "Email already exist for another account", "error");
                    return false;
                }
            }
        }
        if (phone.length == 0) {
            swal("Oops!", "Please fill valid phone number", "error");
            return false;
        }
        if ((rebrandmode == true) && (rebrand == "")) {
            swal("Warning!", "Rebrand name is empty", "warning");
            return false;
        }
        if (country === undefined || country === "") {
            swal("Warning!", "Select a Country", "warning");
            return false;
        }

        if (state === undefined || state === "") {
            swal("Warning!", "Select a State", "warning");
            return false;
        }

        var values = { "id": id, "fname": fname, "lname": lname, "country": country, "state": state, "email": email, "phone": phone, "rebrand": rebrand, "page_name": page_name, "organization_id": organization_id };
        // console.log(values)
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-reseller`, values, headers).then((res) => {
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Reseller details updated successfully!", "success").then((value) => {
                    window.location.reload();
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });

    }

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> :
                null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}

            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                            <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Show Expenses
                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                            </h1>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body pt-6">
                        {
                            state == 0 ?
                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                            :
                            state == 1 ?  
                                <MUIDataTable
                                    title={"All Expenses"}
                                    data={data}
                                    columns={columns}
                                    options={options}
                                />
                            :
                            state == 2 ?  
                            <>
                                <div className="card-body pt-0">
                                    <div className="notice bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                        <h4 className="text-gray-800 fw-bolder">Create a new expense. All added expenses can be managed from here</h4>
                                        <div className='mt-10 text-center'>
                                            <Link className='btn btn-success rounded' to={`/add-expenses`}>Create an Expense</Link>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :  
                            <>
                                <div className="card-body pt-0">
                                    <div className="notice bg-light-danger rounded border-primary border border-dashed rounded-3 p-6">
                                        <h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
                                        <div className='mt-10 text-center'>
                                            <button className='btn btn-success rounded' onClick={()=>{window.location.reload()}}>Reload the page</button>
                                        </div>
                                    </div>
                                </div>
                            </>                             
                        }
                    </div>

                </div>
            </div>


            <div className="modal fade" id="EditStatus" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog mw-650px">
                    <div className="modal-content">

                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Change Status</h5>
                            <div className="modal-header pb-0 border-0 justify-content-end">
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-lg-12">
                                    Current Status is : {status == "Active" ? <span className="fs-5 fw-bolder text-success"> Active </span> : <span className="fs-5 fw-bolder text-danger"> Deactivated </span>}
                                    <h3>Are you sure about changing status?</h3>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            <form >
                                <input type="hidden" id="id" name="activeId" />
                                <input type="hidden" id="st" name="st" />
                                {(status == "Active") ? <button type="button" className="btn btn-danger" onClick={(e) => { submitStatus(e); }} >Deactivate</button> : <button type="button" className="btn btn-success" onClick={(e) => { submitStatus(e); }} >Activate</button>}
                            </form>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade" id="EditModal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div className="modal-content">

                        <form className="form" action="#" id="kt_modal_new_address_form">

                            <div className="modal-header" id="kt_modal_new_address_header">
                                <h2>Update Reseller</h2>
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>

                            <div className="modal-body py-10 px-lg-17">
                                <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                    <div className="row mb-5">
                                        <div className="col-md-12 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Reseller Name</label>
                                            <input id="fname" type="text" className="form-control form-control-solid" placeholder="First Name" name="vendor-name" value={fname} onChange={(e) => { setfname(e.target.value) }} />
                                        </div>

                                        <div className="col-md-12 fv-row mt-2">
                                            {/* <label className="required fs-5 fw-bold mb-2">Last Name</label> */}
                                            <input id="lname" type="text" className="form-control form-control-solid" placeholder="Last Name" name="vendor-name" value={lname} onChange={(e) => { setlname(e.target.value) }} />
                                        </div>
                                    </div>

                                    <div className="d-flex flex-column mb-5 fv-row">
                                        <label className="required fs-5 fw-bold mb-2">Email</label>
                                        <input id="email" className="form-control form-control-solid" placeholder="" name="email" value={email} onChange={(e) => { setemail(e.target.value) }} />
                                    </div>
                                    <div className="d-flex flex-column mb-5 fv-row">
                                        <label className="required fs-5 fw-bold mb-2">Phone</label>
                                        <input id="phone" className="form-control form-control-solid" placeholder="" name="phone" value={phone} onChange={(e) => { setphone(e.target.value) }} />
                                    </div>


                                    <div className="row mb-5">
                                        <div className="col-md-6">
                                            <label className="required fs-5 fw-bold mb-2">Rebranding Active</label>
                                            <div className="col-12">
                                                <span className="kt-switch kt-switch--success">
                                                    <label>
                                                        <div className="col-lg-8 d-flex align-items-center">
                                                            <div className="form-check form-check-solid form-switch fv-row">
                                                                <input className="form-check-input w-45px h-30px" type="checkbox" id="rebrand_checkbox" name="rebrand_checkbox" checked={rebrandmode} onClick={() => { setrebrandmode(rebrandmode == true ? false : true) }} />
                                                                <label className="form-check-label" htmlFor="allowmarketing"></label>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </span>
                                            </div>
                                        </div>
                                        {
                                            (rebrandmode) ?
                                                <div className="d-flex flex-column col-md-6 fv-row">
                                                    <label className="required fs-5 fw-bold mb-2">Rebrand</label>
                                                    <input id="rebrand" className="form-control form-control-solid" placeholder="" name="rebrand" value={rebrand} onChange={(e) => { setrebrand(e.target.value) }} />
                                                </div>
                                                : null
                                        }
                                    </div>

                                    <div className="row mb-5">

                                        <div className="col-md-6 fv-row">
                                            <div className="d-flex flex-column mb-5 fv-row">
                                                <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                                    <span className="required">Country</span>
                                                </label>

                                                <Select
                                                    name="country"
                                                    id="country"
                                                    options={country_array}
                                                    onChange={(e) => { setState(""); loadCountry(e); }}
                                                    value={
                                                        country_array.filter(option =>
                                                            option.value == Country)[0]
                                                    }
                                                />

                                            </div>
                                        </div>

                                        <div className="col-md-6 fv-row">
                                            <div className="d-flex flex-column mb-5 fv-row">
                                                <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                                    <span className="required">State</span>
                                                </label>

                                                <Select
                                                    name="state"
                                                    id="state"
                                                    options={state_array}
                                                    onChange={(e) => { setState(e.value); }}
                                                    value={
                                                        state_array.filter(option =>
                                                            option.value == State)[0]
                                                    }

                                                />

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="modal-footer flex-center">
                                <button onClick={(e) => { submitResellerdetails(e) }} type="submit" id="kt_modal_new_address_submit" className="btn btn-primary rounded">
                                    <span className="indicator-label">Submit</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>

                            <input type="hidden" id="id" name="activeId" />

                        </form>

                    </div>
                </div>
            </div>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org}/>
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer}/>
        </>
    );
};

export default ShowExpenses;
