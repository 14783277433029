import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment';
import { Modal } from "react-bootstrap";
import MUIDataTable from "mui-datatables";
import {MuiThemeProvider, createTheme} from '@material-ui/core';
import ReactTagInput from "@pathofdev/react-tag-input";
import jsPDF from "jspdf"
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver'

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

// import Dropzone from 'react-dropzone-uploader';
// import uuid from 'react-uuid';
// import 'react-dropzone-uploader/dist/styles.css'
// import removeImg from './icons/remove.svg'
// import Select from 'react-select';
// import SetColor from './SetColor.js';
// import country_arr from './countries-arr.js';
// import s_a from './states-arr.js';

const ShowInvoices = () => {
    const [user, setUser] = useState({});
    const [organization_id, setOrganization_id] = useState("")
    const [organization_list, setorganization_list] = useState([]);
    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);
    const [state, setstate] = useState(2);

    const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
	const [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);

    const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [data, setData] = useState([]);
    const [invoiceList, setInvoiceList] = useState([])
    const [allTemplates, setAllTemplates] = useState([])
    const [organizationTemplates, setOrganizationTemplates] = useState([])

    const elemRef = useRef(null)

    const [sendInvoicePromptModalShow, setSendInvoicePromptModalShow] = useState(false)
    const [sendInvoicePromptInvoiceId, setSendInvoicePromptInvoiceId] = useState("")
    const [sendInvoicePromptCustomerId, setSendInvoicePromptCustomerId] = useState("")
    const [sendInvoicePromptCustomerName, setSendInvoicePromptCustomerName] = useState("")
    const [sendInvoicePromptExistingMail, setSendInvoicePromptExistingMail] = useState("")
    const [sendInvoicePromptPrimaryMail, setSendInvoicePromptPrimaryMail] = useState("")
    const [sendInvoicePromptSecondaryMails, setSendInvoicePromptSecondaryMails] = useState([])
    const [sendInvoicePromptGrandTotal, setSendInvoicePromptGrandTotal] = useState(0)
    const [sendInvoicePromptSubmitButtonState, setSendInvoicePromptSubmitButtonState] = useState(false)

    const [cancelInvoicePromptModalShow, setCancelInvoicePromptModalShow] = useState(false)
    const [cancelInvoicePromptInvoiceId, setCancelInvoicePromptInvoiceId] = useState("")
    const [cancelInvoicePromptStatus, setCancelInvoicePromptStatus] = useState("void")
    const [cancelInvoicePromptNote, setCancelInvoicePromptNote] = useState("")
    const [cancelInvoicePromptSubmitButtonState, setCancelInvoicePromptSubmitButtonState] = useState(false)

    const [paidInvoicePromptModalShow, setPaidInvoicePromptModalShow] = useState(false)
    const [paidInvoicePromptInvoiceId, setPaidInvoicePromptInvoiceId] = useState("")
    const [paidInvoicePromptNote, setPaidInvoicePromptNote] = useState("")
    const [paidInvoicePromptSubmitButtonState, setPaidInvoicePromptSubmitButtonState] = useState(false)

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    var page_name = "invoice-list";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        filter: true,
        selectableRows: false,
        fixedHeader: false,
        filterType: "dropdown",
        responsive: "vertical",
        tableBodyHeight: "480px",
        tableBodyMaxHeight: "",
        searchPlaceholder: 'Search an Invoice',
    };



    const columns = [
        {
            name: "invoice_date",
            label: "Invoice Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "invoice_ref",
            label: "Invoice Number",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "order_number",
            label: "Order Number",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "customer",
            label: "Customer Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "sales_person",
            label: "Sales Person",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "grand_total",
            label: "Grand Total",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "due_date",
            label: "Due Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Invoice Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let color
                    if (value == "DRAFT") {
                        color = "grey"
                    }
                    else if (value == "INVOICED") {
                        color = "blue"
                    }
                    else if (value == "PAID") {
                        color = "green"
                    }
                    else if ((value == "VOID")||(value == "UNCOLLECTIBLE")) { 
                        color = "black"
                    }
                    else{
                        color = "red"
                    }

                    return (
                        <p
                            className='my-auto'
                            value={value}
                            onChange={event => updateValue(event.target.value, tableMeta.columnIndex)}
                            style={{ color: color }}>{value}
                        </p>
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						setOrganizationModalShow(true);
					}
				})     
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1,false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    setRedirect_to_login(1);
                });
                
                object.organization_id=organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {
                    
                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true); 
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if(temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else
                                    {
                                        temp = 2;
                                    }
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
							
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    setRedirect_to_login(1);
                });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id!="" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }

                // console.log(timer)
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);





    async function getInvoiceDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/invoice-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.length > 0) {
            setInvoiceList(body);
        }
        else {
            throw("no invoices")
        }
    }
    async function getAllTemplates() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-all-templates`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setAllTemplates(body)
        }
    }
    async function getOrganizationTemplates() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-organization-templates`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setOrganizationTemplates(body.filter(x=>x.template_category=="invoice"))
        }
    } 

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getInvoiceDetails();
            let p2 = getAllTemplates();
            let p3 = getOrganizationTemplates();

            Promise.all([p1,p2,p3])
            .then(() => {
                setstate(1)
                console.log("all apis done")
            })
            .catch((errx) => {
                console.log(errx)
                if (errx == "no invoices") {
                    setstate(3)
                }
                else {
                    setstate(4)
                }
            })
        }
    }, [user, visibility, read, write])

    useEffect(() => {

        if (invoiceList.length > 0) {
            let temp_data = []

            invoiceList.forEach((obj) => {
                let temp_obj = {};

                temp_obj.invoice_date = moment(obj.invoice_date).format("DD-MM-YYYY");
                temp_obj.invoice_ref = obj.invoice_name
                temp_obj.order_number = obj.order_number
                temp_obj.customer = JSON.parse(obj.customer_id).customer_name
                temp_obj.sales_person = (obj.sales_person_id == null) ? null : JSON.parse(obj.sales_person_id).sales_person_name
                temp_obj.grand_total = JSON.parse(obj.invoice_currency).currency_symbol + " " + obj.grand_total
                temp_obj.due_date = moment(obj.due_date).format("DD-MM-YYYY");

                if((obj.add_status=="INVOICED") && (new Date(obj.due_date) < new Date())){
                    temp_obj.status = "OVER DUE by " + Math.round(Math.abs(new Date() - new Date(obj.due_date)) / (1000 * 60 * 60 * 24)) + " days"
                }
                else{
                    temp_obj.status = obj.add_status
                }

                let id = obj.invoice_id;

                temp_obj.actions = <div className="dropdown">
                    <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-bars px-0"></i></button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        {write == 1 && <li><a href="#" className="dropdown-item" onClick={() => { downloadInvoice(id) }} >Download Invoice</a></li>}
                        {write == 1 && <li><Link className="dropdown-item" to={`/view-invoice-details/${id}`}>View Invoice</Link></li>}
                        {write == 1 && obj.add_status == "DRAFT" && <li><Link className="dropdown-item" to={`/edit-invoice-details/${id}`}>Edit Details</Link></li>}
                        {write == 1 && obj.add_status == "DRAFT" && <li><a href="#" className="dropdown-item" onClick={() => { sendInvoicePromptInit(id) }} >Send Invoice</a></li>}
                        {/* {write == 1 && obj.add_status == "INVOICED" && <li><a href="#" className="dropdown-item" onClick={()=>{sendInvoicePromptInit(id)}} >Re-Send Invoice</a></li>} */}
                        {write == 1 && obj.add_status == "INVOICED" && <li><a href="#" className="dropdown-item" onClick={() => { cancelInvoicePromptInit(id) }} >Cancel Invoice</a></li>}
                        {write == 1 && obj.add_status == "INVOICED" && <li><a href="#" className="dropdown-item" onClick={() => { paidInvoicePromptInit(id) }} >Mark as Paid</a></li>}
                    </ul>
                </div>

                temp_data.push(temp_obj)
            })

            setData(temp_data);
        }

    }, [invoiceList])

    const sendInvoicePromptInit = (id) => {
        setSendInvoicePromptModalShow(true)
        setSendInvoicePromptInvoiceId(id)

        let tinv = invoiceList.find(x => x.invoice_id == id)
        let tinv_c = JSON.parse(tinv.customer_id)

        setSendInvoicePromptCustomerId(tinv_c.customer_id)
        setSendInvoicePromptCustomerName(tinv_c.customer_name)
        setSendInvoicePromptExistingMail(tinv_c.customer_email)
        setSendInvoicePromptPrimaryMail(tinv_c.customer_email)
        setSendInvoicePromptGrandTotal(tinv.grand_total)
    }

    const sendInvoiceMail = () => {

        let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if ((sendInvoicePromptPrimaryMail.trim() == "") || (!regex.test(sendInvoicePromptPrimaryMail.trim()))) {
            swal("Oops!", "Please enter a valid Primary email", "warning");
            return
        }

        let p
        if (sendInvoicePromptExistingMail == "") {
            p = new Promise((rs, rj) => {
                let object = {};

                object.cid = sendInvoicePromptCustomerId
                object.email = sendInvoicePromptPrimaryMail.trim()

                object.organization_id = organization_id
                object.page_name = page_name;

                console.log(object);
                setSendInvoicePromptSubmitButtonState(true)

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*",
                        "token": getCookie("access_token")
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/update-email-customer`, object, headers).then((res) => {
                    setSendInvoicePromptSubmitButtonState(false)
                    if (res.data.operation == "same_email") {
                        swal("Oops!", "Email already exists!!", "error");
                        rj()
                    }
                    else if (res.data.operation == "success") {
                        rs()
                    }
                    else {
                        swal("Oops!", "Something went wrong!", "error");
                        rj()
                    }
                })
                .catch(function (error) {
                    console.log(error)
                });
            })
        }
        else {
            p = Promise.resolve()
        }

        p.then(() => {
            let object = {};

            object.invoice_name = invoiceList.find(x => x.invoice_id == sendInvoicePromptInvoiceId).invoice_name
            object.primary_email = sendInvoicePromptPrimaryMail.trim()
            object.secondary_emails = sendInvoicePromptSecondaryMails

            object.organization_id = organization_id
            object.page_name = page_name;

            console.log(object)

            var headers = {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "*/*",
                    "token": getCookie("access_token")
                }
            }
            axios.post(`${process.env.REACT_APP_SERVER_URL}/send-invoice-mail`, object, headers).then((res) => {
                setSendInvoicePromptSubmitButtonState(false)
                if (res.data.operation === "success") {
                    swal("Great!", "Invoice Mail sent and saved successfully!", "success")
                    handleSendInvoicePromptModalClose()
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            })
                .catch(function (error) {
                    console.log(error)
                });
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const handleSendInvoicePromptModalClose = () => {
        setSendInvoicePromptModalShow(false)
        setSendInvoicePromptPrimaryMail("")
        setSendInvoicePromptSecondaryMails([])
    }

    const cancelInvoicePromptInit = (id) => {
        setCancelInvoicePromptModalShow(true)
        setCancelInvoicePromptInvoiceId(id)
    }

    const cancelInvoice = () => {

        if (cancelInvoicePromptStatus == "") {
            swal("Oops!", "Please enter a status before cancelling Invoice", "warning");
            return
        }
        if (cancelInvoicePromptNote.trim() == "") {
            swal("Oops!", "Please enter an appropiate reason for cancelling Invoice", "warning");
            return
        }

        let object = {};

        object.invoice_id = cancelInvoicePromptInvoiceId
        object.invoice_name = invoiceList.find(x=>x.invoice_id==cancelInvoicePromptInvoiceId).invoice_name
        object.cancel_status = cancelInvoicePromptStatus
        object.cancel_note = cancelInvoicePromptNote 

        object.organization_id = organization_id
        object.page_name = page_name;

        console.log(object)
        setCancelInvoicePromptSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-invoice`, object, headers).then((res) => {
            setCancelInvoicePromptSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Invoice successfully cancelled", "success")
                getInvoiceDetails()
                handleCancelInvoicePromptModalClose()
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const handleCancelInvoicePromptModalClose = () => {
        setCancelInvoicePromptModalShow(false)
        setCancelInvoicePromptInvoiceId("")
        setCancelInvoicePromptStatus("void")
        setCancelInvoicePromptNote("")
    }

    const paidInvoicePromptInit = (id) => {
        setPaidInvoicePromptModalShow(true)
        setPaidInvoicePromptInvoiceId(id)
    }

    const paidInvoice = () => {

        if (paidInvoicePromptNote.trim() == "") {
            swal("Oops!", "Please enter an appropiate reason for Marking Invoice as Paid", "warning");
            return
        }

        let object = {};

        object.invoice_id = paidInvoicePromptInvoiceId
        object.invoice_name = invoiceList.find(x=>x.invoice_id==paidInvoicePromptInvoiceId).invoice_name
        object.mode = "manual"
        object.paid_note = paidInvoicePromptNote 

        object.organization_id = organization_id
        object.page_name = page_name;

        console.log(object)
        setPaidInvoicePromptSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*", 
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/paid-invoice`, object, headers).then((res) => {
            setPaidInvoicePromptSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Invoice marked as paid successfully", "success")
                getInvoiceDetails()
                handlePaidInvoicePromptModalClose()
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    const handlePaidInvoicePromptModalClose = () => {
        setPaidInvoicePromptModalShow(false)
        setPaidInvoicePromptInvoiceId("") 
        setPaidInvoicePromptNote("")
    }

    const downloadInvoice = (id) => {
        let object = {};

        object.id = id
        object.template_category = "invoice"

        object.organization_id = organization_id
        object.page_name = page_name;

        // console.log(object)
        
        var headers = {
            responseType: 'arraybuffer',
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*", 
                "token": getCookie("access_token"),
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/generate-pdf`, object, headers).then((res) => {
            if (res.data.operation === "danger") {
                swal("Oops!", "Something went wrong!", "error");
            }
            else {
                console.log(res)
                let pdfBlob = new Blob([res.data], { type: 'application/pdf' });

                let t1 = invoiceList.find(x=>x.invoice_id==id)
                let t2 = JSON.parse(t1.customer_id).customer_name
                let t3 = t1.invoice_name
                saveAs(pdfBlob, `${t2.replace(" ","-")}_${t3}.pdf`)
                
                swal("Great!", "Invoice downloaded successfully", "success")
                console.log("pdf downloaded")
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

    useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}

            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Show Invoices</h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">

                        <div className="card">
                            <div className="card-body pt-6">
                                {
                                    state == 2 ?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :
                                    state == 1 ?
                                    <MuiThemeProvider theme={createTheme({palette: { type:userTheme }})}>
                                        <MUIDataTable
                                            title={"All Invoices"}
                                            data={data}
                                            columns={columns}
                                            options={options}
                                        />
                                    </MuiThemeProvider>
                                    :
                                    state == 3 ?
                                    <>
                                        <div className="notice bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                            <h4 className="text-gray-800 fw-bolder">Create a new Invoice. All added invoices can be managed from here</h4>
                                            <div className='mt-10 text-center'>
                                                <Link className='btn btn-success rounded' to={`/add-invoice`}>Create an Invoice</Link>
                                            </div>
                                        </div>  
                                    </>
                                    :
                                    <>
                                        <div className="notice bg-light-danger rounded border-primary border border-dashed rounded-3 p-6">
                                            <h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
                                            <div className='mt-10 text-center'>
                                                <button className='btn btn-success rounded' onClick={() => { window.location.reload() }}>Reload the page</button>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Modal show={sendInvoicePromptModalShow} onHide={() => { handleSendInvoicePromptModalClose() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Send Invoice</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleSendInvoicePromptModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <h4 className='mb-5'>Send Invoice of Amount {sendInvoicePromptGrandTotal} to {sendInvoicePromptCustomerName}?</h4>
                        <div className="row">
                            <div className='col-12'>
                                <label className="fs-5 fw-bold my-2">Customer email</label>
                                <input type="email" className="form-control form-control-solid" value={sendInvoicePromptPrimaryMail} onChange={(e) => { setSendInvoicePromptPrimaryMail(e.target.value) }} />
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-12'>
                                <label className="fs-5 fw-bold my-2">Include on this email</label>
                                <ReactTagInput
                                    tags={sendInvoicePromptSecondaryMails}
                                    onChange={(tags) => {
                                        let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                                        let flag = true
                                        let t = tags.map(x => x.trim())
                                        t.forEach((tag) => {
                                            if ((tag == "") || (!regex.test(tag))) {
                                                flag = false
                                            }
                                        })
                                        if (flag == false) {
                                            swal("Warning", "Please enter a valid email id", "warning")
                                        }
                                        else {
                                            setSendInvoicePromptSecondaryMails(t)
                                        }
                                    }}
                                    placeholder="Add emails (Optional) - Type and press Enter"
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        write == 1 &&
                        <button className="btn btn-info rounded me-auto" data-kt-indicator={sendInvoicePromptSubmitButtonState ? "on" : "off"} disabled={sendInvoicePromptSubmitButtonState} onClick={() => { sendInvoiceMail() }}>
                            <span className="indicator-label">Send Invoice</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                    <button className="btn btn-secondary rounded" onClick={() => { handleSendInvoicePromptModalClose() }}>Cancel</button>
                </Modal.Footer>
            </Modal>

            <Modal show={cancelInvoicePromptModalShow} onHide={() => { handleCancelInvoicePromptModalClose() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Cancel Invoice</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleCancelInvoicePromptModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row mb-5">
                            <div className='col-4 my-auto'><label className="fs-5 fw-bold mb-2">Cancel Status</label></div>
                            <div className='col-4'>
                                <label className="fs-5 mb-2" onClick={() => { setCancelInvoicePromptStatus("void") }}>Void
                                    <input type="radio" className="form-check-input ms-5" checked={cancelInvoicePromptStatus == "void"} />
                                </label>
                            </div>
                            <div className='col-4'>
                                <label className="fs-5 mb-2" onClick={() => { setCancelInvoicePromptStatus("uncollectible") }}>Uncollectible
                                    <input type="radio" className="form-check-input ms-5" checked={cancelInvoicePromptStatus == "uncollectible"} />
                                </label>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-12 my-2">
                                <label className="fs-5 fw-bold mb-2">Note</label>
                                <textarea className="form-control form-control-solid" rows="3" value={cancelInvoicePromptNote} onChange={(e) => {setCancelInvoicePromptNote(e.target.value)}} placeholder={"Reason for cancellation"}></textarea>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        write == 1 &&
                        <button className="btn btn-info rounded me-auto" data-kt-indicator={cancelInvoicePromptSubmitButtonState ? "on" : "off"} disabled={cancelInvoicePromptSubmitButtonState} 
                            onClick={() => {
                                swal({
                                    title: "Are you sure?",
                                    text: "This invoice will be permanently cancelled!",
                                    icon: "warning",
                                    buttons: true,
                                    dangerMode: true,
                                })
                                .then((willDelete) => {
                                    if (willDelete) {
                                        cancelInvoice()
                                    }
                                });
                            }}
                        >
                            <span className="indicator-label">Cancel Invoice</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button>
                    }
                    <button className="btn btn-secondary rounded" onClick={() => { handleCancelInvoicePromptModalClose() }}>Cancel</button>
                </Modal.Footer>
            </Modal>

            <Modal show={paidInvoicePromptModalShow} onHide={() => { handlePaidInvoicePromptModalClose() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Mark Invoice as Paid</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handlePaidInvoicePromptModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row mb-5">
                            <div className="col-12 my-2">
                                <label className="fs-5 fw-bold mb-2">Note</label>
                                <textarea className="form-control form-control-solid" rows="3" value={paidInvoicePromptNote} onChange={(e) => {setPaidInvoicePromptNote(e.target.value)}} placeholder={"Reason for marking invoice as paid"}></textarea>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        write == 1 &&
                        <button className="btn btn-info rounded me-auto" data-kt-indicator={paidInvoicePromptSubmitButtonState ? "on" : "off"} disabled={paidInvoicePromptSubmitButtonState} onClick={() => {paidInvoice() }}>
                            <span className="indicator-label">Mark Invoice as Paid</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button>
                    }
                    <button className="btn btn-secondary rounded" onClick={() => { handlePaidInvoicePromptModalClose() }}>Cancel</button>
                </Modal.Footer>
            </Modal>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org}/>
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer}/>
        </>
    );
};

export default ShowInvoices;
