import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom'
//import process.env from '../config.json';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import moment from 'moment';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css'
import Select from 'react-select';
import removeImg from './icons/remove.svg'
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import uuid from 'react-uuid';
import {Form, Col,Button,InputGroup} from 'react-bootstrap';
import { Formik } from "formik";
import * as yup from "yup";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";

function UpdateProductSerial(props) {
    let [user, setUser] = useState({});
    let [loader, setLoader] = useState(0);
    let [state, setstate] = useState(2);
    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    let [SerialNumber, setSerialNumber] = useState(false);
	let [ProductNumber, setProductNumber] = useState("");
    let [Serial_information, setSerial_information] = useState([]);


    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);
    //const [organization_id, setOrganization_id] = useState("");
    const [appname_short,setappname_short] = useState("");

    const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState([]);

  const[usageunit,setusageunit] = useState("");
  const[countinstock,setcountinstock] = useState("");
  const[reorderlevel,setreorderlevel] = useState("");
  const[product_name,setproduct_name] = useState("");
  const [globalsubcategory, setGlobalSubcategory] = useState([]);
    var page_name = "update-product-serial";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };
    
    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });

                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
                        else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url))
									{
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else
										{
                                            setredirect(1);
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            res.data.audience_count = (res.data.audience_count)
                            if (res.data.audience_count > 0) {
                                const timer_decrease = setInterval(() => {
                                    res.data.audience_count = (res.data.audience_count - 1);
                                    //console.log(res.data.audience_count);
                                    if (res.data.audience_count > 0 && res.data.audience_count < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                                        if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                                            setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                                            window.$('#kt_modal_timer').modal('show');
                                        }
                                    }
                                }, 1000);

                                return () => clearInterval(timer_decrease);
                            }
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }

        validateJWT();

    }, [])
    const handleInputChange = (index,event) => {
        const values = [...Serial_information];

            values[index].serial_number = event.target.value;

        setSerial_information(values);
    };
    useEffect(() => {

		async function getstockinfo() {
			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/getstockinfo`, { method: 'POST',body: JSON.stringify({ "email": user.email,"page_name":page_name,"id":props.match.params.id}),  headers: {"Content-type": "application/json; charset=UTF-8", "token" : getCookie("access_token") }
				}
			);
			const body = await response.json();
			//console.log(body);
			if(body.length>0)
            {
				setcountinstock(body[0].count_in_stock)
            }
		}
		
		if(read || write)
		{
        (user.email && visibility) && getstockinfo();
        }
        
        async function getserialinfo() {
			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-serial-info`, { method: 'POST',body: JSON.stringify({ "email": user.email,"page_name":page_name,"id":props.match.params.id}),  headers: {"Content-type": "application/json; charset=UTF-8", "token" : getCookie("access_token") }
				}
			);
			const body = await response.json();
			//console.log(body);
			if(body.length>0)
            {
				setSerial_information(body)
            }
		}
		
		if(read || write)
		{
        (user.email && visibility) && getserialinfo();
        }

        async function getproductname() {
			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/product-datatable`, { method: 'POST',body: JSON.stringify({ "email": user.email,"page_name":page_name}),  headers: {"Content-type": "application/json; charset=UTF-8", "token" : getCookie("access_token") }
				}
			);
			const body = await response.json();
			//console.log(body);
            let id=props.match.params.id;
			if(body.length>0)
            {
                const result = body.filter(obj => obj.pid == id);
                if(result.length>0)
                {
                setproduct_name(result[0].product_name)
                setstate(1)
                }
                else{
                    setstate(0)
                }
            }
		}
		
		if(read || write)
		{
        (user.email && visibility) && getproductname();
        }

	}, [user, visibility, read, write])

    function containsDuplicates(a) {
        for (let i = 0; i < a.length; i++) {
          if (a.indexOf(a[i]) !== a.lastIndexOf(a[i])) {
            return true
          }
        }
        return false
      }

const onsubmit  =(e)=>{
    var values={};
    values.uid = props.match.params.id;
    values.page_name = page_name;
    var count_in_stock = countinstock;
	var serial_number_array = [];
   
   var elem1 =  document.getElementById("serial_nuber_entry");
   
   if(elem1.checked)
   {
	   var value2 =  document.getElementById("product_number").value;
	   if(value2 != "" && value2 != null)
	   {
		   if(Number(value2) > 0 && Number(value2) <= Number(count_in_stock))
		   {
			   var elems = document.getElementsByName("product_serial_number[]");
			   
			   for(var i=0; i<elems.length; i++)
			   {
				   if(elems[i].value != "" && elems[i].value != null)
				   {
					   serial_number_array[i] = elems[i].value;
				   }
				   else
				   {
					   swal("Warning!", "Enter all the serial numbers", "warning");
						return false;
				   }
			   }
		   }
		   else
		   {
			   swal("Warning!", "provide a proper value of how many serial numbers you want to insert", "warning");
			   return false;
		   }
	   }
	   else
	   {
		   swal("Warning!", "provide how many serial numbers you want to insert", "warning");
		   return false;
	   }
   }
   else{
    var elems = document.getElementsByName("product_serial_number[]");
			   
    for(var i=0; i<elems.length; i++)
    {
        if(elems[i].value != "" && elems[i].value != null)
        {
            serial_number_array[i] = elems[i].value;
        }
        else
        {
            swal("Warning!", "Enter all the serial numbers", "warning");
             return false;
        }
    }
   }
   //console.log(serial_number_array);
   if(containsDuplicates(serial_number_array)==true)
   {
    swal("Warning!", "Please enter all unique Serial number", "warning");
    return false;
   }
   values.serial_number_array=serial_number_array;
    var headers = {
        headers: {
            "Content-Type": "application/json",
            "Accept": "*/*",
            "token": getCookie("access_token")
        }
    }
    
	axios.post(`${process.env.REACT_APP_SERVER_URL}/update-serial-info`,values, headers).then((res) => {
		if(res.data && res.data.operation == "success")
		{
			swal("Great!", "Product serial number updated successfully!", "success").then((value) => {
                window.location.reload();
            });
		}
		else{
			swal("Oops!", "Something went wrong!", "error");
		}
	});
	
	}

    return (
        <>

            {
                (redirect == 1 ||(read==0 && write==0)) ? 
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> :
                 null
            }
            {state == 1 ?
                <>

                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">

                                <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                                    <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Update Product Serial
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                        <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                    </h1>

                                </div>

                            </div>
                        </div>

                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div id="kt_content_container" className="container">
                                <div className="card">
                                <div class="card-body p-lg-17">
                                <div class="row mb-3">
                                    <div class="col-md-12 pe-lg-10">
                                <form class="form mb-15" id="kt_contact_form">
                                <h1 class="fw-bolder text-dark mb-9">{product_name}</h1>
                                <div className=" py-10 px-lg-17">
                                
                                        <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
								
										
											<div className="kt-portlet__body">
											<div className="row">
                                            
												<div className="row mb-5">

                                                    <div className="col-md-12 fv-row">
														<label className="fs-5 fw-bold mb-2">Count in Stock-</label>
														<span>   {countinstock}</span>
														
													
												    </div>
                                                </div>
                                                <div className="row mb-5">

                                                    <div className="col-md-12 fv-row">
														<label className="fs-5 fw-bold mb-2">Available serial number-</label>
														<span>   {Serial_information.length}</span>
														
													
												    </div>
                                                </div>
                                                {
                                                    Serial_information.length>0 && Serial_information.map((obj, i) => {
                                                        return(
                                                        <div className="col-md-12 fv-row">
                                                            <label className="required fs-5 fw-bold mb-2">Serial {i+1}</label>
                                                            <input type="text" name="product_serial_number[]" className="form-control form-control-solid" placeholder="Serial Number"
                                                                value={obj.serial_number}  onChange={(event) => {handleInputChange(i,event);}} />
                                                        </div>
                                                        )
                                                    })
												}

                                                <div className="col-xl-6">
													<br/>
													<label className="fs-5 fw-bold mb-2">Add More Serial Number</label>
													<div className="col-12">
														<span className="kt-switch kt-switch--success">
															<label>
                                                            <div className="col-lg-8 d-flex align-items-center">
                                                                <div className="form-check form-check-solid form-switch fv-row">
                                                                    <input className="form-check-input w-45px h-30px" type="checkbox" id="serial_nuber_entry" name="serial_nuber_entry" value="No" onChange={(event) => {setSerialNumber(event.target.checked);}} />
                                                                </div>
                                                            </div>
															</label>
														</span>
													</div>
													</div>
													
													{SerialNumber && 
													<>
													
													<div className="col-md-12 fv-row">
														<label className="required fs-5 fw-bold mb-2">How Many Products</label>
														<input type="text" name="product_number"className="form-control form-control-solid" id="product_number" placeholder="How many products" value={ProductNumber}
														onChange={(event)=>{
                  											setProductNumber(event.target.value);}}
														 />
														<span className="form-text text-muted">How many products?</span>
														
													</div>
													<br/>	
														{
															((ProductNumber > 0 && ProductNumber <= Number(countinstock-Serial_information.length))
															?
															<>
															{
																[...Array(Number(ProductNumber))].map((x, i) => {
																	return(
																	<div className="col-md-12 fv-row">
																		<label className="required fs-5 fw-bold mb-2">Serial {Serial_information.length+i+1}</label>
																		<input type="text" name="product_serial_number[]"className="form-control form-control-solid" placeholder="Serial Number"
																		 />
																	</div>
																	)
																})
															}
															</>
															:
															<>
															{
															ProductNumber > 0 &&
															<div className="col-md-12 fv-row">
																<h4 className="form-text" style={{"color" : "red"}}>You can only enter less then the number of stock</h4>
															</div>
															}
															</>
															)
														}
													</>
													}
											</div>
											</div>
										</div>
											
											<div className="modal-footer flex-center">
                                                {write == 1 ?
                                                    <button type="button"
                                                        className="btn btn-primary rounded"
                                                        onClick={(e) => { onsubmit(e) }}
                                                    >
                                                        <span className="indicator-label">Update</span>
                                                        <span className="indicator-progress">Please wait...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                    </button>
                                                    :
                                                    <button type="button"
                                                        className="btn btn-primary rounded"
                                                        onClick={(e) => { onsubmit(e) }} disabled
                                                    >
                                                        <span className="indicator-label">Update</span>
                                                        <span className="indicator-progress">Please wait...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                    </button>
                                                }
                                                </div>

							
                                </div>
                            </form>
                                </div>
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                </>
                :
                <>
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                                    <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Update Product Serial
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                        <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                    </h1>

                                </div>

                            </div>
                        </div>

                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div className="container" id="kt_content_container">
                                <div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
                                    <br />

                                    {state == 2 ?
                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        :
                                        <>

                                            <div className="flex-lg-row-fluid me-lg-15">
                                                <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                                    <div className="card-header">
                                                        <div className="card-title">
                                                            <h2 className="fw-bolder">Update Product Serial</h2>
                                                        </div>
                                                    </div>


                                                    <div className="card-body pt-0">
                                                        <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                        <div className="d-flex align-items-center p-3 mb-2">


                                                        </div>


                                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                            <div className="d-flex flex-stack flex-grow-1">
                                                                <div className="fw-bold">
                                                                    <h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
                                                                    <div className="fs-6 text-gray-600">
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </>
                                    }


                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            }


            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>


        </>
    );
}
export default UpdateProductSerial;