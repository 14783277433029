import React, { useState, useEffect } from 'react';
import axios from 'axios';

import swal from 'sweetalert';
import swalr from '@sweetalert/with-react';

import { Link, Redirect } from 'react-router-dom'
import moment from 'moment';

import MUIDataTable from "mui-datatables";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';

import SetColor from './SetColor.js';
import Select from 'react-select';

import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";

export const ShowResellerBills = (props) => {
    let [user, setUser] = useState({});
    let [timer, setTimer] = useState(0);
    let [clock_timer, setClockTimer] = useState(0);

    const [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    let [state, setstate] = useState(2);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");

    const [data, setData] = useState([])
    const [editmodal, setEditModal] = useState([])
    const [taxGroups, setTaxGroups] = useState([])
    const [currentBill, setCurrentBill] = useState(null)

    const [due_date, setDue_date] = useState(undefined)
    const [availableProducts, setAvailableProducts] = useState([])
    const [reqState, setReqState] = useState("init")

    const [productDropdown,setProductDropdown] = useState([])
    const [products,setProducts] = useState([])

    const [productCart,setProductCart] = useState([])
    
    const [subTotal,setSubTotal] = useState(0) 
    const [totalTax,setTotalTax] = useState(0) 
    const [discount,setDiscount] = useState(0)
    const [grandTotal,setGrandTotal] = useState(0) 

    const [discountType, setDiscountType] = useState("")
    const [discountUnit,setDiscountUnit] = useState(0)
    const [note,setNote] = useState("")

    const [currencyIcon,setCurrencyIcon] = useState("$")

    var page_name = "reseller-bill-list";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        filter: true,
        selectableRows: "none",
        filterType: "dropdown",
        responsive,
        tableBodyHeight,
        tableBodyMaxHeight,
        searchPlaceholder: 'Search Bill',
    };



    const columns = [
        {
            name: "add_date_time",
            label: "Billing Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "total_price",
            label: "Subtotal",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "total_tax_value",
            label: "Tax Amount",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "discount_value",
            label: "Discount",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "discount_type",
            label: "Discount Type",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "discounted_total_price",
            label: "Bill Amount",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "due_date",
            label: "Due Date",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                .catch(function (error) {
                    swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                        window.location.href = "/login"
                    });
                });

                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
                        else {

                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                    
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                .catch(function (error) {
                    swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                        window.location.href = "/login"
                    });
                });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }

        validateJWT();
    }, [])

    useEffect(() => {
        if (timer > 0) {
            const timer_decrease = setInterval(() => {
                setTimer(timer - 1);
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        window.$('#kt_modal_timer').modal('show');
                    }
                }
            }, 1000);

            return () => clearInterval(timer_decrease);
        }
    }, [timer]);


    useEffect(() => {
        async function getTaxGroups() {

            const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/gettaxes`, { method: 'POST',body: JSON.stringify({ "email": user.email,"page_name":page_name}),  headers: {"Content-type": "application/json; charset=UTF-8", "token" : getCookie("access_token") }
				}
			);
			const body = await response.json();
            // console.log(body)
            setTaxGroups(body)
        }
        if (read || write) {
            user.email && visibility && getTaxGroups();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        async function getResellerBills() {

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-reseller-bills`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "sid":props.match.params.id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            // console.log(body)

            if (body.length > 0) {
                setstate(1);
            }
            else {
                setstate(0);
            }

            let body2 = []
            body.map(element => {
                let id = element.billing_group_id.toString();
                if((body2.find(obj=>obj.billing_group_id===id))===undefined){
                    let temp = {}
                    temp["billing_group_id"]=id
                    temp["total_price"]=element.total_price
                    temp["discount_type"]=element.discount_type
                    temp["discount_value"]=element.discount_value
                    temp["total_tax_value"]=element.total_tax_value
                    temp["discounted_total_price"]=element.discounted_total_price
                    temp["due_date"]=element.due_date
                    temp["add_status"]=element.add_status
                    temp["add_date_time"]=element.add_date_time
                    temp["product"]=[]
                    temp["note"]=element.note
                    temp["product"].push(element)
                    
                    body2.push(temp)
                }
                else{
                    body2.map(obj=>{
                        if (obj.billing_group_id===id){
                            obj.product.push(element)
                        }
                    })
                }
            });
            // console.log(body2)
            setEditModal(body2);
        }
        if (read || write) {
            user.email && visibility && getResellerBills();
        }

    }, [user, visibility, read, write])

    useEffect(() => {
            if (editmodal.length > 0) {
                let temp_data = [];
                editmodal.map((obj) => {

                    let temp_obj = {};
                    let id = obj.billing_group_id;
                    // temp_obj.billing_group_id = obj.billing_group_id;
                    temp_obj.add_date_time = moment(obj.add_date_time).format("DD-MMM-YYYY  h:mm a")
                    temp_obj.total_price = obj.total_price;
                    temp_obj.discount_value = obj.discount_value;
                    temp_obj.total_tax_value = obj.total_tax_value;
                    temp_obj.discount_type = obj.discount_type;
                    temp_obj.discounted_total_price = obj.discounted_total_price;
                    temp_obj.status = obj.add_status;
                    temp_obj.due_date = moment(obj.due_date).format("DD-MMM-YYYY")
                    temp_obj.status = obj.add_status;
                    temp_obj.product = obj.product;
                    temp_obj.actions = (temp_obj.status === 'Active') ? 
                        <div className="dropdown">
                            <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">Options</button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><button type="button" data-bs-toggle="modal" data-bs-keyboard="false" data-bs-backdrop="static" data-bs-target="#EditModal" onClick={()=>{handleBillSelect(id)}} data-bs-trigger="hover" className="dropdown-item">Edit billing details</button></li>
                                <li><button type="button" onClick={()=>{downloadBill(id)}} className="dropdown-item">Download Bill Invoice</button></li>
                            </ul>
                        </div>
                    :
                        <button className="btn btn-sm btn-secondary rounded" type="button" aria-expanded="false" onClick={()=>{downloadBill(id)}} title="Download bill">
                            <i className="las la-file-download" style={{ "font-size": "24px" }}></i>
                        </button>

                    temp_data.push(temp_obj);
                })

                setData(temp_data);
            }
            else {
                setData([]);
            }
    }, [editmodal])

    const downloadBill = (id) =>{
        console.log("Downloading bill",id)
    }

    const handleBillSelect = (id) => {
        let temph = editmodal.find(x => x.billing_group_id == id)
        temph = {...temph}
        setCurrentBill(temph)
    }

    useEffect(()=>{
        if(currentBill == null)
        {
            setAvailableProducts([])
            setProductCart([])
            setProducts([])
            setDue_date(undefined)
            setDiscountType("")
            setDiscountUnit(0)
            setNote("")
        }
        else
        {
            getProductDetails()
            setDue_date(moment(currentBill.due_date).format('YYYY-MM-DD'))
            setDiscountType(currentBill.discount_type)
            setDiscountUnit(currentBill.discount_value)
            setNote(currentBill.note)
        }
    },[currentBill])

    const getProductDetails = () => {
        setReqState("processing")
        fetch(`${process.env.REACT_APP_SERVER_URL}/available-product-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }} )
        .then(response => response.json())
        .then(body =>{
            setReqState("init")
            if(body.length > 0) {
                console.log(body)
                let temp = currentBill.product.map(x=>x.product_id)
                let newBody = body.map((obj)=>{
                    if(temp.includes(obj.pid)){
                        let x = currentBill.product.find(x=>obj.pid==x.product_id).serial_numbers.split(",")
                        let tObj = [...obj.serial_list]
                        if(x[0]!="")
                            tObj = [...tObj,...x]
                        return {...obj, serial_list: tObj}
                    }
                    else
                        return {...obj}
                })
                setAvailableProducts(newBody);
            }
        });
    }

    useEffect(() => {
        if(currentBill!=null)
        {
            let temp = currentBill.product.map(x => x.product_id)
            
            let temp2 = availableProducts.filter(obj => temp.includes(obj.pid)).map((obj)=>{
                let tObj = {}
                let cObj = currentBill.product.find(x => obj.pid == x.product_id)

                tObj = {...obj}

                tObj["serial_button"] = cObj.serial_numbers==""?false:true
                tObj["serial_numbers"] = (cObj.serial_numbers=="")?[]:cObj.serial_numbers.split(",").map(obj => {return {value: obj, label: obj}})
                tObj["selected_quantity"] = cObj.quantity
                tObj["selected_price"] = cObj.unit_price
                tObj["total_price"] = Math.round(cObj.quantity * cObj.unit_price *100)/100 
                tObj["tax"] = cObj.tax

                if(obj.taxable == "Yes"){
                    if(cObj.tax_group_id!="None"){
                        let tp = taxGroups.find(x =>x.tax_group_id == cObj.tax_group_id)
                        tObj["tax_group_id"] = {value:[tp.tax_group_percentage,tp.tax_group_id], label:`${tp.tax_group_name} [${tp.tax_group_percentage}%] - [${tp.tax_type1} ${tp.tax_percentage1}%] + [${tp.tax_type2} ${tp.tax_percentage2}%]`}
                    }
                    else{
                        tObj["tax_group_id"] = undefined
                    }
                }
                         
                return tObj
            })

            console.log(temp2)
            setProductCart(temp2)
        }
    }, [availableProducts])

    useEffect(() => {
        let temp = productCart.map(e => e.pid)

        let options = []
        availableProducts.forEach((elem) => {
            if (!temp.includes(elem.pid)) 
                options.push({value: elem.pid, label: elem.product_name})
        })
        
        options.sort((a,b)=> (a.label > b.label ? 1 : -1))
        setProductDropdown(options)
	}, [availableProducts,productCart])

    const removeFromCart = (pid) => {
        let temp = productCart.filter(obj => obj.pid != pid)
        setProductCart(temp)
    }

    const addToCart = () => {
        let temp = products.map(e => e.value)
        let temp2 = availableProducts.filter(e => temp.includes(e.pid))

        temp2 = temp2.map(a => {return {...a, serial_button: false, serial_numbers: [], selected_quantity: 1, selected_price: 0, total_price: 0, tax: 0, tax_group_id :undefined }})

        setProductCart([...productCart, ...temp2])
        setProducts([])
    };

    //function to handle serial toggle buttons
    const serialToggle = (pid) => {
        let temp = productCart.map((elem) => {
            if(elem.pid == pid){
                if(elem.serial_button){
                    let t = {...elem, serial_button: !elem.serial_button, serial_numbers: []}
                    if(elem.selected_quantity>elem.quantity-elem.serial_list.length){
                        t = {...t, selected_quantity: elem.quantity-elem.serial_list.length}
                    }

                    return t
                }
                else
                    return {...elem, serial_button: !elem.serial_button}
            }
            else
                return {...elem}
        })

        setProductCart(temp)
    }

    //function to handle serial selection
    const handleSerialChange = (pid,arr) => {
        let temp = productCart.map((elem) => {
            if(elem.pid == pid)
            {
                return {...elem, serial_numbers: arr, selected_quantity: elem.selected_quantity<arr.length?arr.length:elem.selected_quantity}
            }
            else
            {
                return {...elem}
            }
        })  
        setProductCart(temp)
    }

    //function to handle quantity input
    const handleQuantityChange = (pid,val) => {
        let temp = productCart.map((elem) => {
            if(elem.pid == pid)
            {
                let flag = true
                if(val>elem.quantity)
                {
                    swal("Oops!", "Count can't exceed your stock limit", "warning")
                    flag = false
                }
                else if(val<elem.serial_numbers.length)
                {
                    swal("Oops!", "You can decrease your product count after removing product serial", "warning")
                    flag = false
                }
                else if(val>(elem.quantity - elem.serial_list.length) + elem.serial_numbers.length)
                {
                    swal("Oops!", "You can increase your product count after adding product serial", "warning")
                    flag = false
                }
                else if(isNaN(val))
                {
                    swal("Oops!", "Quantity has to be a number", "warning")
                    flag = false
                }
                else if(val<1)
                {
                    swal("Oops!", "Quantity can't be less than 1", "warning")
                    flag = false
                }

                if(flag)
                    return {...elem, selected_quantity: parseInt(val*100)/100, total_price: Math.round((parseInt(elem.selected_price*100)/100)*val * 100) / 100}
                else
                    return {...elem}    
            }            
            else
                return {...elem}
        })

        setProductCart(temp)
    };
      
    //function to handle price input
    const handlePriceChange = (pid,val) => {
        let temp = productCart.map((elem) => {
            if(elem.pid == pid)
            {
                let flag = true
                if(val<0)
                {
                    swal("Oops!", "Price can't be less than 0", "warning")
                    flag = false
                }
                else if(isNaN(val))
                {
                    swal("Oops!", "Price has to be a number", "warning")
                    flag = false
                }

                if(flag)
                {
                    return {...elem, selected_price: parseInt(val*100)/100 , total_price: Math.round((parseInt(val*100)/100)*elem.selected_quantity * 100) / 100}
                }
                else
                    return {...elem}    
            }            
            else
                return {...elem}
        })

        setProductCart(temp)
    };

    const handleTaxChange = (pid,val) => {
        let temp = productCart.map((elem) => {
            if(elem.pid == pid)
            {
                return {...elem, tax_group_id: val, tax:val.value[0]}
            }            
            else
                return {...elem}
        })

        setProductCart(temp)
    };

    const handleDateChange = (val) => {
        if(val < moment.utc().format("YYYY-MM-DD"))
        {
            swal("Oops!", "Due Date cannot be earlier than today ", "warning")
        }
        else
        {
            setDue_date(val)
        }
    }

    const handleDiscountChange = (val) => {
        if(val>=0)
            setDiscountUnit(parseInt(val*100)/100)
        else
            swal("Oops!", "Discount can't be less than 0", "warning")
    };

    useEffect(()=>{
        if(discountType=="")
        {
            setDiscount(0)
            setDiscountUnit(0)
        }
        else
        {
            setDiscount(Math.round(((discountType=="Percentage")?(subTotal*discountUnit/100) : discountUnit) * 100)/100)
        }
    },[subTotal,discountType,discountUnit]) 

    useEffect(()=>{
        let temp = productCart.reduce((a, c) => a + c.total_price, 0)
        setSubTotal(Math.round(temp * 100)/100) 
    },[productCart])

    useEffect(()=>{
        let temp = productCart.reduce((a, c) => a + (c.total_price * ((c.taxable=='Yes')?c.tax:0) / 100) , 0)
        setTotalTax(Math.round(temp * 100)/100)
    },[productCart,subTotal]) 

    useEffect(()=>{
        setGrandTotal(Math.round((subTotal + totalTax - discount) * 100)/100)
    },[subTotal,discount,totalTax])

    const editedFinalSubmit = () => {
        //validations
        if(due_date == "")
        {
            swal("Oops!", "Due Date cannot be empty ", "warning")
        }
        else
        {
            let pdraft = productCart.map(a => {
                let t = {}
                t["pid"]=a.pid
                t["product_name"]=a.product_name
                t["quantity"]=a.selected_quantity
                t["unit_price"]=a.selected_price
                t["taxable"]=a.taxable
                t["tax_group_id"]=((a.tax_group_id=="None")||(a.tax_group_id==undefined))?"None":a.tax_group_id.value[1]
                t["tax"]=a.tax
                t["serial_numbers"] = a.serial_numbers.map(x => x.value).join()

                return t
            })
            // console.log(pdraft)
            
            let object = {}

            object.sid = props.match.params.id;
            object.user_id = user.email
            object.due_date = due_date
            object.billing_group_id = currentBill.billing_group_id

            object.total_price = subTotal
            object.discount_type = discountType
            object.discount_value = discountUnit
            object.discounted_total_price = grandTotal
            object.total_tax_value = totalTax

            object.note = note
            object.product_array = pdraft
            object.page_name = page_name;

            // console.log(object)

            var headers = {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "*/*",
                    "token": getCookie("access_token")
                }
            }

            document.getElementById("editedFinalSubmit").setAttribute("data-kt-indicator", "on");
            document.getElementById("editedFinalSubmit").disabled = true;

            axios.post(`${process.env.REACT_APP_SERVER_URL}/add-reseller-product`, object, headers)
            .then((res) => {    

                document.getElementById("editedFinalSubmit").setAttribute("data-kt-indicator", "off");
                document.getElementById("editedFinalSubmit").disabled = false;

                if (res.data.operation == "success") {
                    swal("Great!", "Billing details updated Succesfully", "success").then((res) => {
                        window.location.reload();
                    });
                }
                else if (res.data.operation == "Process error"){
                    swalr({ 
                        title: "Warning", 
                        icon: "warning",
                        content: <ul style={{height: "300px", overflow: "hidden", overflowY: "scroll"}} >{ res.data.err.map((elem) => {return <li style={{marginLeft: "50px", textAlign: "left"}} >{elem}</li> })}</ul>,
                        buttons: true,
                    })
                    .then(()=>{
                        window.location.reload();
                    })
                }
                else{
                    swal("Oops!", "something went wrong", "error").then((res) => {
                        window.location.reload();
                    });
                }
            })
            .catch((error) => {
                swal("Oops!", "something went wrong", "error").then((res) => {
                    window.location.reload();
                });
            });
        }
    }

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> : null
            }

            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                <div className="toolbar" id="kt_toolbar">
                    <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                        <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                            <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Reseller Bills
                                <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                            </h1>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body pt-6">
                        {
                            state == 2 ?
                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                            :
                            state == 1 ?
                                <>
                                    <MUIDataTable
                                        title={"Billing Details"}
                                        data={data}
                                        columns={columns}
                                        options={options}
                                    />

                                </>
                            :
                                <>
                                    <div className="card-body pt-0">
                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                            <div className="d-flex flex-stack flex-grow-1">
                                                <div className="fw-bold">
                                                    <h4 className="text-gray-800 fw-bolder">No Bill Available</h4>
                                                    <div className="fs-6 text-gray-600"> </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>   
                        }
                    </div>
                </div>
            </div>

            <div className="modal fade" id="EditModal" tabIndex="-1" data-bs-keyboard="false" data-bs-backdrop="static" aria-hidden="true" >
                <div className="modal-xl modal-dialog modal-dialog-centered" >
                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">
                            <h1 className="mx-auto">Edit Bill Details</h1>
                            <button className="btn btn-sm btn-icon btn-active-color-danger" disabled={(reqState=="processing"?true:false)} onClick={()=>{setCurrentBill(null)}} data-bs-dismiss="modal">
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="scroll-y me-n7 pe-7 " id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                 <div className="container min-h-500px">

                                    <div className="row gx-10 mb-5">
                                        <div className="col-lg-6">
                                            <label className="form-label fs-6 fw-bolder text-gray-700 mb-3">Due Date:</label>			
                                            <input type="date" className="form-control" name="invoice_due_date"min={moment.utc().format("YYYY-MM-DD")} value={due_date} onChange={(e) => {handleDateChange(e.target.value)}} />
                                        </div>
                                    </div>		

                                    <div className="row gx-10 mb-5">
                                        <div className="col-lg-6">
                                            <label className="form-label fs-6 fw-bolder text-gray-700 mb-3">Select Products</label>
                                            <div className="mb-5"> 
                                                <Select
                                                    styles={{
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            fontWeight: '500',
                                                            fontSize: 'larger'
                                                        }),
                                                        multiValue: (provided, state) => ({
                                                            ...provided,
                                                            fontWeight: '500',
                                                            fontSize: 'larger'
                                                        }),
                                                        placeholder: (provided, state) => ({
                                                            ...provided,
                                                            fontWeight: '500',
                                                            fontSize: 'larger'
                                                        })
                                                    }} 
                                                    isMulti
                                                    options={productDropdown}
                                                    isLoading={reqState=="processing"?true:false}
                                                    onChange={(val) => {typeof(val)=="object"?setProducts(val):setProducts(...products,...val)}}        
                                                    value={products}
                                                />
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6">
                                            <button type="button" className="btn btn-primary btn-rounded mt-9" onClick={addToCart}> &#10011; Add items </button>  
                                        </div>
                                    </div>
                            
                                    {
                                        reqState=="processing"? 
                                            <div className="container text-center" style={{paddingTop: "150px"}}>
                                                <div className="spinner-border text-primary mx-auto" style={{width: "50px", height: "50px"}}></div>
                                            </div>
                                        :
                                        productCart.length>0 && 
                                        <div className="border-top border-top-dashed border-dark pt-3">
                                            <div className="table-responsive mb-10">
                                                <table className="table g-5 gs-0 mb-0" data-kt-element="items">
                                                    <thead className="text-gray-700" >
                                                        <tr className="border-bottom fs-7 text-uppercase">
                                                            <th className="min-w-300px"><h5>Item</h5></th>
                                                            <th className="min-w-150px w-150px"><h5>Serial</h5></th>
                                                            <th className="min-w-150px w-150px"><h5>Quantity</h5></th>  
                                                            <th className="min-w-150px w-150px"><h5>Unit Price</h5></th>
                                                            <th className="min-w-150px w-150px"><h5>Total</h5></th>
                                                            <th className="min-w-400px w-400px"><h5>Tax Applied</h5></th>
                                                            <th className="min-w-100px w-100px"><h5>Action</h5></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {productCart.map((obj,index) =>
                                                            {
                                                                return(   
                                                                    <React.Fragment key={index}>
                                                                    <tr data-kt-element="item" className="border-bottom border-bottom-dashed">
                                                                        <td className="pt-8 text-nowrap">

                                                                            {obj.product_name}
                                                                            <br/><br/>
                                                                            {obj.serial_button &&
                                                                            <>
                                                                                <Select 
                                                                                    isMulti 
                                                                                    options={obj.serial_list.map((e)=>{ return {value:e,label:e} })}
                                                                                    value={obj.serial_numbers}  
                                                                                    
                                                                                    onChange={(val) => { handleSerialChange(obj.pid,val,obj.selected_quantity) }}
                                                                                />
                                                                            </>
                                                                            }
                                                                            
                                                                        </td>

                                                                        <td>
                                                                            <div className="form-check form-check-solid form-switch fv-row">
                                                                                <input className="form-check-input w-45px h-30px mt-5" type="checkbox" checked={obj.serial_button} onChange={() => { serialToggle(obj.pid) }} />
                                                                            </div>
                                                                        </td>

                                                                        <td className="pt-8 text-nowrap">  
                                                                            <input className="form-control form-control-solid" type="number" min="1" step=".25" value={obj.selected_quantity} onChange={(event) => { handleQuantityChange(obj.pid, parseFloat(event.target.value)) }}/>
                                                                        </td>
                                                                            
                                                                        <td className="pt-8 text-nowrap d-flex">
                                                                            <input className="form-control form-control-solid" type="number" min="0" step=".25" value={obj.selected_price} onChange={(event) => { handlePriceChange(obj.pid, parseFloat(event.target.value)) }}/>
                                                                        </td>

                                                                        <td className="pt-8 text-nowrap">
                                                                            <input className="form-control form-control-solid" type="number" value={obj.total_price} readOnly/>
                                                                        </td> 
                                                                        
                                                                        <td className="pt-8 text-nowrap">
                                                                            {
                                                                                obj.taxable=="No"?<input className="form-control form-control-solid" type="text" value="Not Taxable" readOnly/>
                                                                                :
                                                                                <Select 
                                                                                    value={obj.tax_group_id}
                                                                                    onChange={(val)=>{handleTaxChange(obj.pid, val); }}
                                                                                    options={ taxGroups.map(obj => {return {value:[obj.tax_group_percentage,obj.tax_group_id], label:`${obj.tax_group_name} [${obj.tax_group_percentage}%] - [${obj.tax_type1} ${obj.tax_percentage1}%] + [${obj.tax_type2} ${obj.tax_percentage2}%]`}} )}
                                                                                />
                                                                            }   
                                                                        </td> 

                                                                        <td className="pt-8">
                                                                            <button type="button" className="btn btn-outline-dark py-1 rounded" title="Remove" onClick={() => { removeFromCart(obj.pid) }} >
                                                                                <span className="svg-icon svg-icon-1 m-auto p-auto">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="40px" height="40px" viewBox="-3 -3 30 30" version="1.1">
                                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                            <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fillRule="nonzero" />
                                                                                            <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" />
                                                                                        </g>
                                                                                    </svg>
                                                                                </span>
                                                                            </button>
                                                                        </td>
            
                                                                    </tr>
                                                                    </React.Fragment>
                                                                );
                                                            }
                                                        )}
                                                    </tbody>

                                                    <tfoot>
                                                        <tr className="border-top border-top-dashed border-primary align-top fs-6 fw-bolder text-gray-700">
                                                            <th colspan="2">
                                                                <div className="d-flex flex-column align-items-end my-7">
                                                                    <button className="btn btn-link text-danger py-0" style={{marginTop: "1.74em"}} onClick={()=>{setDiscountType("")}} >Remove Discount</button>
                                                                </div>
                                                            </th>
                                                            <th colSpan="1" className="border-bottom border-bottom-dashed border-primary ps-0">
                                                                <div className="d-flex flex-column align-items-start">
                                                                    <div className="fs-5">Subtotal</div>
                                                                    <div className="fs-5">Total Tax</div>
                                                                    <button className="btn btn-link py-1 text-success" data-bs-toggle="modal" data-bs-target="#DiscountModal" data-bs-trigger="hover" >Add discount</button>
                                                                </div>
                                                            </th>
                                                            <th colSpan="3" className="border-bottom border-bottom-dashed border-primary">
                                                                <div><span style={{marginRight: "0px", marginLeft: "160px"}}> </span><span>{currencyIcon} {subTotal}</span></div>
                                                                <div><span style={{marginRight: "20px", marginLeft: "131px"}}>+</span><span>{currencyIcon} {totalTax}</span></div>
                                                                <div><span style={{marginRight: "20px", marginLeft: "132px"}} >-</span><span>{currencyIcon} {discount}</span></div>
                                                            </th>
                                                        </tr>

                                                        <tr className="align-top fw-bolder text-gray-700">
                                                            <th colSpan="2"></th>
                                                            <th colSpan="1" className="fs-4 ps-0">Total</th>
                                                            <th colSpan="3" className="fs-4 text-nowrap"><span style={{marginRight: "0px", marginLeft: "160px"}}> </span><span>{currencyIcon} {grandTotal}</span></th>
                                                        </tr>
                                                    </tfoot>
                                                </table>

                                                <div className="mb-0">
                                                    <label className="form-label fs-6 fw-bolder text-gray-700">Notes</label>
                                                    <textarea name="notes" className="form-control form-control-solid" rows="3" placeholder="Thanks for your business" value={note} onChange={e=>setNote(e.target.value)} autoComplete="off"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    } 

                                </div> 
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button className="btn btn-danger rounded" disabled={(reqState=="processing"?true:false)} onClick={()=>{setCurrentBill(null); }} data-bs-dismiss="modal">Close</button>
                            <button className="btn btn-success rounded" id="editedFinalSubmit" disabled={(reqState=="processing"?true:false)} onClick={editedFinalSubmit} >
                                <span className="indicator-label">Save and Proceed</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button> 
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade" id="DiscountModal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div className="modal-content">

                        <form className="form" action="#" id="kt_modal_new_address_form">
                            <div className="modal-header" id="kt_modal_new_address_header">
                                <h2>Enter Discount Information</h2>
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>

                            <div className="modal-body py-10 px-lg-17">
                                <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                    <div className="d-flex flex-column mb-5 fv-row">
                                        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                            <span className="required">Discount type:</span>
                                        </label>
                                        <select
                                            name="discount_type"
                                            data-placeholder="Select a Country..."
                                            className="form-select form-select-solid"
                                            id="lead_source"
                                            value={discountType} 
                                            onChange={(e) => { setDiscountType(e.target.value); setDiscountUnit(0); }}
                                        >
                                            <option value="" disabled hidden>Choose Type</option>
                                            <option value="Amount">Amount</option>
                                            <option value="Percentage">Percentage</option>
                                        </select>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-7 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Discount value:</label>
                                            <div className="d-flex">
                                                <input type="number" className="form-control form-control-solid" min="0" value={discountUnit} onChange={(e)=>{ handleDiscountChange(discountType!=""?parseFloat(e.target.value):0) }} />
                                                {discountType=="Percentage" && <span className="my-auto">%</span>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <div className="modal fade in" id="kt_modal_timer" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">
                    <div className="modal-content">
                        <div className="modal-header" id="kt_modal_new_address_header">
                            <h2>Session Out</h2>
                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>

                        <div className="modal-body py-10 px-lg-17">
                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>

                                    <div className="d-flex flex-stack flex-grow-1">
                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>
                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }
                            </div>

                        </div>

                        <div className="modal-footer flex-center">
                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>);

};

export default ShowResellerBills;
