module.exports.getTemplate = (invoice, custom) => {

    let template = `
        <div>
            <h1>Invoice 2</h1>
            ${custom.invoice?`<label>Invoice: </label><span class="ms-3">${invoice.invoice_name}</span>`:" "}<br/>
            <label>Customer: </label><span class="ms-3">${invoice.customer_name}</span><br/>
            <label>Order No.: </label><span class="ms-3">${invoice.order_number}</span><br/>
            <label>Issue Date: </label><span class="ms-3">${invoice.invoice_date}</span><br/>
            <label>Due Date: </label><span class="ms-3">${invoice.due_date}</span><br/>
            <label>Term: </label><span class="ms-3">${invoice.term}</span><br/>
            <label>Sales Person: </label><span class="ms-3">${invoice.sales_person}</span><br/>
            <label>Invoice Currency: </label><span class="ms-3">${invoice.invoice_currency}</span><br/>
            <label>Subject: </label><span class="ms-20">${invoice.subject}</span><br/>

            <table class="table mt-5">
            <thead>
                <tr>
                    <th scope="col">Item</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Rate</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Discount</th>
                    <th scope="col">Tax</th>
                    <th scope="col">Total</th>
                </tr>
            </thead>
            <tbody>
                ${
                    invoice.item_array.map(obj=>{
                        return(
                            `<tr>
                                <td scope="col">${obj.item_name}</td>
                                <td scope="col">${obj.quantity}</td>
                                <td scope="col">${obj.rate}</td>
                                <td scope="col">${obj.quantity * obj.rate}</td>
                                <td scope="col">${obj.discount_unit}${obj.discount_type=="amount"?" ":"%"}</td>
                                <td scope="col">${obj.tax_id==null?"0":obj.tax_percentage+"%"}</td>
                                <td scope="col">${(obj.quantity * obj.rate) - (obj.discount_type == "amount"? obj.discount_unit : obj.quantity*obj.rate * obj.discount_unit / 100 ) + (obj.tax_id==null? 0 : obj.quantity*obj.rate * obj.tax_percentage / 100)}</td>
                            </tr>`
                        )
                    }).join(" ")
                }
                <tr>
                    <td scope="col" colspan="6" class="text-end">Sub Total</td>
                    <td scope="col">${Math.round(invoice.item_array.reduce((p,o)=>{return p + o.quantity * o.rate - (o.discount_type == "amount"? o.discount_unit : o.quantity*o.rate * o.discount_unit / 100 ) + (o.tax_id==null? 0 : o.quantity*o.rate * o.tax_percentage / 100) },0) *100)/100}</td>
                </tr>
                <tr>
                    <td scope="col" colspan="6" class="text-end">${invoice.adjustment.text}</td>
                    <td scope="col">${invoice.adjustment.value}</td>
                </tr>
                <tr>
                    <td scope="col" colspan="6" class="text-end">Tax</td>
                    <td scope="col">${invoice.tax==null?"0":invoice.tax.tax_percentage+"%"}</td>
                </tr>
                <tr>
                    <td scope="col" colspan="6" class="text-end">Grand Total</td>
                    <td scope="col">${invoice.grand_total}</td>
                </tr>
            </tbody>
            </table>

            <label>Customer Notes: </label><span class="ms-3">${invoice.customer_notes}</span><br />
            <label>Terms and Conditions: </label><span class="ms-3">${invoice.terms_and_conditions}</span><br />
        </div>

    `

    

    return template
}