export  function setCookie(cname, cvalue, exdays,flag) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	// console.log(cname,flag);
	var expires = "expires=" + d.toUTCString();
	if(!flag)
	{
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}
	else
	{
		let url = window.location.href;
		let domain = (new URL(url));
		domain = domain.hostname;
		domain = domain.replace('expense','');
		console.log(flag,domain);
		document.cookie = cname + "=" + cvalue + ";" + expires + `;domain=${domain}`;
		//swindow.location.reload(); 
	}
}

export  function getCookie(cname) {
	var name = cname + "=";
	var ca = document.cookie.split(';');
	//console.log(ca);
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}
