import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom'
import CryptoJS from 'crypto-js'
import moment from 'moment';
import useRazorpay from "react-razorpay";

import {setCookie,getCookie} from '../common_component/cookie.js';


// import {Modal} from "react-bootstrap";
// import html2canvas from 'html2canvas';
// import uuid from 'react-uuid';
// import moment from 'moment';
// import Dropzone from 'react-dropzone-uploader';
// import 'react-dropzone-uploader/dist/styles.css'
// import Select, {components} from 'react-select';
// import removeImg from './icons/remove.svg'
// import ReactTagInput from "@pathofdev/react-tag-input"; 
// import "@pathofdev/react-tag-input/build/index.css";
// import currency_arr from './currency-arr.js' ;
// import ReactDOM from 'react-dom';
// import $ from 'jquery';


function CustomerPaymentInvoice(props) {
    const [state, setstate] = useState(2);

    const [paymentConfigs, setPaymentConfigs] = useState([])

    const [invoice, setInvoice] = useState(null)
    
    const [invoiceName, setInvoiceName] = useState("")
    const [invoiceDate, setInvoiceDate] = useState("")
    const [dueDate, setDueDate] = useState("")
    const [customerName, setCustomerName] = useState("")
    const [issuedBy, setIssuedBy] = useState("")
    const [invoiceCurrency, setInvoiceCurrency] = useState(null)
    const [invoiceCurrencySymbol, setInvoiceCurrencySymbol] = useState("")
    const [itemArray, setItemArray] = useState([])
    const [adjustment, setAdjustment] = useState({text:"Adjustment", value:0})
    const [tax, setTax] = useState(null)
    const [grandTotal, setGrandTotal] = useState("")
    const [paymentStatus, setPaymentStatus] = useState("")
    const [paymentPortal, setPaymentPortal] = useState("")
    const [transactionDateTime, setTransactionDateTime ] = useState("")
    const [payButtonState, setPayButtonState ] = useState(false)
    
    const Razorpay = useRazorpay();

    // const [customerNotes, setCustomerNotes] = useState("")
    // const [termsAndConditions, setTermsAndConditions] = useState("")
    // const [selectedPaymentPortal, setSelectedPaymentPortal] = useState(null)
    // const [selectedOrganizationTemplateId, setSelectedOrganizationTemplateId] = useState(null)

	var page_name = "customer-payment-invoice";
	
    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    





    async function getPaymentPortalConfigs(val) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-payment-config-portal`, { method: 'POST', body: JSON.stringify({"platform": val, "organization_id": props.match.params.oid }), headers: { "Content-type": "application/json; charset=UTF-8" } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setPaymentConfigs(body[0])
        }
    }
    async function getInvoice() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/fetch-invoice-details-by-id`, { method: 'POST', body: JSON.stringify({ "invoice_id": props.match.params.id, "organization_id": props.match.params.oid }), headers: { "Content-type": "application/json; charset=UTF-8" } }
        );
        const body = await response.json();

        if (body.length > 0){
            setInvoice(body[0]);

            if(body[0].add_status=="INVOICED")
                return body[0].payment_portal
        }
        else{
            throw("no such invoice")
        }
    }
   

    useEffect(() => {
        let p1 = getInvoice();
        p1.then((val) => {
            let p2
            if(val!=null){
                p2 = getPaymentPortalConfigs(val)
            }
            else{
                p2 = Promise.resolve()
            }
            Promise.all([p2])
            .then(() => {
                setstate(1)
                console.log("all apis done")
            })
            .catch((err) => {
                setstate(4)
                console.log(err)
            })
        })
        .catch((errx) => {
            if(errx == "no such invoice"){
                setstate(3)
                console.log(errx)
            }
            else{
                setstate(4)
                console.log(errx)
            }
        })
    }, [])

    useEffect(() => {
        if(invoice!=null){
            setInvoiceName(invoice.invoice_name)
            setInvoiceDate(invoice.invoice_date)
            setDueDate(invoice.due_date)
            setCustomerName(JSON.parse(invoice.customer_id).customer_name)
            setIssuedBy(invoice.organization_name)
            setInvoiceCurrency(JSON.parse(invoice.invoice_currency).currency_code)
            setInvoiceCurrencySymbol(JSON.parse(invoice.invoice_currency).currency_symbol)
            setItemArray(JSON.parse(invoice.item_array))
            setAdjustment(JSON.parse(invoice.adjustment))
            setTax(invoice.tax==null?0:invoice.tax.tax_percentage)
            setGrandTotal(invoice.grand_total)
            setPaymentStatus(invoice.add_status)
            setPaymentPortal(invoice.payment_portal)
            setTransactionDateTime(moment(invoice.transaction_date_time).format("DD-MM-YYYY HH:MM"))
        }

    }, [invoice])

    const initiatePayment = async () => {

        if(paymentPortal == "stripe"){
            const orderUrl = `${process.env.REACT_APP_SERVER_URL}/create-payment-session-stripe`;

            var object = {};
            
            object.invoice_id = props.match.params.id
            object.amount = grandTotal;
            object.currency = invoiceCurrency;
            object.item_array = itemArray
            object.platform = paymentPortal;
            object.page_name = page_name;
            object.organization_id = props.match.params.oid;

            console.log(object)
            setPayButtonState(true)

            const response = await axios.post(orderUrl,object);
            if(response.data.message.id)
            {
                setPayButtonState(false);

                let d_publishable_key = CryptoJS.AES.decrypt(paymentConfigs.publishable_key, process.env.REACT_APP_CRYPTOJS_SECRET_KEY).toString(CryptoJS.enc.Utf8);
                // console.log(d_publishable_key)

                var stripe = window.Stripe(d_publishable_key);
                setTimeout(function() {
                    stripe.redirectToCheckout({sessionId: response.data.message.id})
                    .then(function (result) {
                        console.log(result);
                    });
                }, 1000);
            }
            else
            {
                swal("Oops!", "Something went wrong!", "error");
            }
        }
        else if(paymentPortal == "razorpay"){
            const orderUrl = `${process.env.REACT_APP_SERVER_URL}/create-order-razorpay`;

            var object = {};
            
            object.invoice_id = props.match.params.id
            object.amount = grandTotal;
            object.currency = invoiceCurrency;
            object.item_array = itemArray
            object.platform = paymentPortal;
            object.page_name = page_name;
            object.organization_id = props.match.params.oid;

            console.log(object)
            setPayButtonState(true)

            const response = await axios.post(orderUrl,object);
            console.log(response.data)

            
            const options = {
                "key": "rzp_test_r3Kv7gUvAxJall",
                "order_id": response.data.order_id,
                "amount": response.data.amount,
                "currency": response.data.currency,
                "name": issuedBy,
                "theme": {
                    "color": "#3399cc"
                },
                "handler": async (response) => {
                    try {
                        console.log("this is the handler function",response)
                        // const paymentId = response.razorpay_payment_id;

                        // if (response.razorpay_payment_id) {
                        //     object.response = response;
                        //     object.id = res.data.id;
                        //     object.page_name = page_name;

                        //     axios.post(`${process.env.REACT_APP_SERVER_URL}/purchase-plan-razorpay-update`, object, headers).then((res2) => {
                        //         if (res2.data && res2.data.operation == "success") {
                        //             swal("Great!", "Payment Successfull!", "success").then((value) => {
                        //                 window.location.href = "/payment-response/success/" + res.data.id;
                        //             });
                        //         }
                        //         else {
                        //             swal("Great!", "We are still processing your payment. Please check back after few minutes.", "success").then((value) => {
                        //                 window.location.href = "/payment-response/success/" + res.data.id;
                        //             });
                        //         }
                        //     });
                        // }
                        // else {
                        //     swal("Oops!", "Something went wrong!", "error");
                        // }


                        //const url = `${API_URL}/capture/${paymentId}`;
                        /*
                        const url = `${API_URL}/verification`;
                        
                        var object2 = {};
                        object2.razorpay_payment_id = response.razorpay_payment_id;
                        object2.razorpay_signature = response.razorpay_signature;
                        object2.sid = data.id;
                
                        const captureResponse = await axios.post(url, object2)
                        
                        let response1 = JSON.parse(captureResponse.data);
                        
                        if(response1.id)
                        {
                        object.response = response1;
                        object.id = res.data.id;
                        
                        axios.post(`${process.env.REACT_APP_SERVER_URL}/purchase-plan-razorpay-update`,object, headers).then((res) => {
                            if(res.data && res.data.operation == "success")
                            {
                                swal("Great!", "Payment Successfull!", "success").then((value) => {
                                    //window.location.href = "/subscription-history";
                                });
                            }
                            else
                            {
                                swal("Great!", "Order Placed!", "success").then((value) => {
                                    //window.location.href = "/subscription-history";
                                });
                            }
                        });
                        
                        }
                        */
                    } catch (err) {
                        console.log(err);
                        swal("Oops!", "Something went wrong!", "error");
                    }
                }
            };
            // const rzp1 = new Razorpay(options);

            // rzp1.on("payment.failed", function (response) {
            //     alert(response.error.code, response.error.description, response.error.source, response.error.step, response.error.reason, response.error.metadata.order_id, response.error.metadata.payment_id);
            // });

            // rzp1.open();
            
                

            // if(response.data.message.id)
            // {
            //     setPayButtonState(false);

            //     let d_publishable_key = CryptoJS.AES.decrypt(paymentConfigs.publishable_key, process.env.REACT_APP_CRYPTOJS_SECRET_KEY).toString(CryptoJS.enc.Utf8);
            //     // console.log(d_publishable_key)

            //     var stripe = window.Stripe(d_publishable_key);
            //     setTimeout(function() {
            //         stripe.redirectToCheckout({sessionId: response.data.message.id})
            //         .then(function (result) {
            //             console.log(result);
            //         });
            //     }, 1000);
            // }
            // else
            // {
            //     swal("Oops!", "Something went wrong!", "error");
            // }
        }
	}

    return ( 
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Pay Invoice</h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            state == 1?
                            <>                            
                                <div className="card">
                                    <div className="card-body p-lg-20">

                                        <div className="d-flex flex-column flex-xl-row">
                                            <div className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
                                                <div className="mt-n1">
                                                    <div className="d-flex flex-stack pb-10">
                                                        <a href="#"><img alt="Logo" width={"120em"} src="https://cdn-adocrm.s3.amazonaws.com/static/ado-crm-logo.png" /></a>
                                                        {
                                                            invoice!=null && invoice.add_status == "INVOICED" &&
                                                            <button className="btn btn-success" data-kt-indicator={payButtonState?"on":"off"} disabled={payButtonState} onClick={()=>{initiatePayment()}}>
                                                                <span className="indicator-label">Pay Now</span>
                                                                <span className="indicator-progress">Please wait...
                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                </span>
                                                            </button>
                                                        }
                                                    </div> 
                                                    <div className="m-0">
                                                        <div className="fw-bold fs-3 text-gray-800 mb-8">Invoice {invoiceName}</div>
                                                        <div className="row g-5 mb-11">
                                                            <div className="col-sm-6">
                                                                <div className="fw-semibold fs-7 text-gray-600 mb-1">Issue Date:</div>
                                                                <div className="fw-bold fs-6 text-gray-800">{moment(invoiceDate).format("DD MMM YYYY")}</div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="fw-semibold fs-7 text-gray-600 mb-1">Due Date:</div>
                                                                <div className="fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap">
                                                                    <span className="pe-2">{moment(dueDate).format("DD MMM YYYY")}</span>
                                                                    <span className="fs-7 text-danger d-flex align-items-center">
                                                                    <span className="bullet bullet-dot bg-danger me-2"></span>{(new Date(dueDate) < new Date()) ? "Over Due by " + Math.round(Math.abs(new Date() - new Date(dueDate)) / (1000 * 60 * 60 * 24)) + " days":"Due in " + Math.round(Math.abs(new Date(dueDate) - new Date()) / (1000 * 60 * 60 * 24)) + " days"}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row g-5 mb-12">
                                                            <div className="col-sm-6">
                                                                <div className="fw-semibold fs-7 text-gray-600 mb-1">Issue For:</div>
                                                                <div className="fw-bold fs-6 text-gray-800">{customerName}</div>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="fw-semibold fs-7 text-gray-600 mb-1">Issued By:</div>
                                                                <div className="fw-bold fs-6 text-gray-800">{issuedBy}</div>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <div className="table-responsive border-bottom mb-9">
                                                                <table className="table mb-3">
                                                                    <thead>
                                                                        <tr className="border-bottom fs-6 fw-bold text-muted">
                                                                            <th className="min-w-175px pb-2">Item</th>
                                                                            <th className="min-w-70px text-end pb-2">Quantity</th>
                                                                            <th className="min-w-80px text-end pb-2">Rate</th>
                                                                            <th className="min-w-100px text-end pb-2">Amount</th>
                                                                            <th className="min-w-100px text-end pb-2">Discount</th>
                                                                            <th className="min-w-100px text-end pb-2">Tax</th>
                                                                            <th className="min-w-100px text-end pb-2">Total</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            itemArray.map((obj,indx)=>{
                                                                                return(
                                                                                    <tr className="fw-bold text-gray-700 fs-5 text-end">
                                                                                        <td className="d-flex align-items-center pt-6"><i className={`fa fa-genderless ${indx%3==0?"text-danger":indx%3==1?"text-success":indx%3==2?"text-primary":null} fs-2 me-2`}></i>{obj.item_name}</td>
                                                                                        <td className="pt-6">{obj.quantity}</td>
                                                                                        <td className="pt-6">{obj.rate}</td>
                                                                                        <td className="pt-6 text-dark fw-bolder">{obj.quantity * obj.rate}</td>
                                                                                        <td className="pt-6 text-dark fw-bolder">{obj.discount_unit}{obj.discount_type=="percentage" && "%"}</td>
                                                                                        <td className="pt-6 text-dark fw-bolder">{obj.tax_id==null?"0":obj.tax_percentage+"%"}</td>
                                                                                        <td className="pt-6 text-dark fw-bolder">{Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount"? obj.discount_unit : obj.quantity*obj.rate * obj.discount_unit / 100 ) + (obj.tax_id==null? 0 : obj.quantity*obj.rate * obj.tax_percentage/ 100)) *100)/100}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            <div className="d-flex justify-content-end">
                                                                <div className="mw-300px">
                                                                    <div className="d-flex flex-stack mb-3">
                                                                        <div className="fw-semibold pe-10 text-gray-600 fs-7">Subtotal:</div>
                                                                        <div className="text-end fw-bold fs-6 text-gray-800">{invoiceCurrencySymbol} {Math.round(itemArray.reduce((p,o)=>{return p + (Math.round(((o.quantity * o.rate) - (o.discount_type == "amount"? o.discount_unit : o.quantity*o.rate * o.discount_unit / 100 ) + (o.tax_id==null? 0 : o.quantity*o.rate * o.tax_percentage / 100)) *100)/100) },0) *100)/100 }</div>
                                                                    </div>
                                                                    <div className="d-flex flex-stack mb-3">
                                                                        <div className="fw-semibold pe-10 text-gray-600 fs-7">{adjustment.text}</div>
                                                                        <div className="text-end fw-bold fs-6 text-gray-800">{adjustment.value}</div>
                                                                    </div>
                                                                    <div className="d-flex flex-stack mb-3">
                                                                        <div className="fw-semibold pe-10 text-gray-600 fs-7">Tax</div>
                                                                        <div className="text-end fw-bold fs-6 text-gray-800">{tax} %</div>
                                                                    </div>
                                                                    <div className="d-flex flex-stack">
                                                                        <div className="fw-semibold pe-10 text-gray-600 fs-7">Grand Total</div>
                                                                        <div className="text-end fw-bold fs-6 text-gray-800">{invoiceCurrencySymbol} {grandTotal}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="m-0">
                                                <div className="d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten">
                                                    <h6 className="mb-8 fw-bolder text-gray-600 fs-2 text-hover-primary">PAYMENT DETAILS</h6>
                                                    <div className="mb-6">
                                                        <div className="fw-semibold text-gray-600 fs-4 my-3">Status: <span className={`fs-3 badge ${paymentStatus=="DRAFT"?"badge-secondary":paymentStatus=="INVOICED"?"badge-light-primary":paymentStatus=="PAID"?"badge-light-success":(paymentStatus=="VOID"||paymentStatus=="UNCOLLECTIBLE")?"badge-light-dark":null} ms-3 me-2`}>{paymentStatus}</span></div>
                                                        <div className="fw-semibold text-gray-600 fs-4 my-3">Payment Gateway: <span>{paymentPortal}</span></div>
                                                        {paymentStatus=="PAID" && <div className="fw-semibold text-gray-600 fs-4 my-3">Transaction Date & Time: <span>{transactionDateTime}</span></div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>
                            :
                            state == 2 ?
                            <div className="card">
                                <div className="card-body pt-6">
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                </div>
                            </div>
                            :
                            <div className="card">
                                <div className="card-body">
                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                        <div className="d-flex flex-stack flex-grow-1">
                                            <div className="fw-bold">
                                                <h4 className="text-gray-800 fw-bolder">Something went wrong. Please reload this page.</h4>
                                                <div className="fs-6 text-gray-600"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>

            </div>
        </>
    );
}


export default CustomerPaymentInvoice;