import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";
//import process.env from '../config.json';
import ReCAPTCHA from "react-google-recaptcha"
import { Link } from 'react-router-dom';
import intlTelInput from 'intl-tel-input';
import $ from 'jquery';
import country_arr from './countries-arr.js';
import s_a from './states-arr.js';
import currency_arr from './currency-arr.js';
import Select from 'react-select';
import moment from 'moment';

function QuickSetUp() {
	const modal = 2;
	const [user, setUser] = useState({});
	const [RedirectState, setRedirectState] = useState(2);
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
	const [captchaToken, setCaptchaToken] = useState("");
	const [phone_input, setphone_input] = useState({});
	const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))

	const [OrganizationName, setOrganizationName] = useState("");
	const [OrganizationId, setOrganizationId] = useState("");
	const [CurrencyCode, setCurrencyCode] = useState("INR");
	const [CurrencySymbol, setCurrencySymbol] = useState("Rs.");
	const [Currencylist, setCurrencylist] = useState([])
	const [Industrylist, setIndustrylist] = useState([{ label: "Agriculture", value: "Agriculture" }, { label: "Construction", value: "Construction" }, { label: "Education", value: "Education" }, { label: "Engineering", value: "Engineering" }, { label: "Entertainment", value: "Entertainment" }])
	const [Languagelist, setLanguagelist] = useState([{ label: "English", value: "English" }])
	const [timezone, settimezone] = useState("");
	const [country, setcountry] = useState("India");
	const [Industry, setIndustry] = useState("");
	const [organization_domain, setorganization_domain] = useState("");
	const [Language, setLanguage] = useState("English");
	const [state1, setstate1] = useState("");
	const [country_array, set_country_array] = useState([]);
	const [state_array, set_state_array] = useState([]);
	const [alert_count, set_alert_count] = useState({});
	const [quick_set_flag, setquick_set_flag] = useState("");
	const [content_flag, setcontent_flag] = useState(2);
	const [OrganizationList, setOrganizationList] = useState([]);
	const [OrganizationDetails, setOrganizationDetails] = useState([]);
	const [PlanDetails, setPlanDetails] = useState([]);

	const [FiscalYearlist, setFiscalYearlist] = useState([{ label: "January-December", value: "January-December" }, { label: "February-January", value: "February-January" }, { label: "March-February", value: "March-February" }, { label: "April-March", value: "April-March" }, { label: "May-April", value: "May-April" }, { label: "June-May", value: "June-May" }, { label: "July-June", value: "July-June" }, { label: "August-July", value: "August-July" }, { label: "September-August", value: "September-August" }, { label: "October-September", value: "October-September" }, { label: "November-October", value: "November-October" }, { label: "December-November", value: "December-November" }])
	const [FiscalYear, setFiscalYear] = useState("");
	const [DateFormatlist, setDateFormatlist] = useState([{ label: `MM-DD-YY(${moment().format('MM-DD-YY')})`, value: "MM-DD-YY" }, { label: `DD-MM-YY(${moment().format('DD-MM-YY')})`, value: "DD-MM-YY" }, { label: `YY-MM-DD(${moment().format('YY-MM-DD')})`, value: "YY-MM-DD" }, { label: `MM-DD-YYYY(${moment().format('MM-DD-YYYY')})`, value: "MM-DD-YYYY" }, { label: `DD-MM-YYYY(${moment().format('DD-MM-YYYY')})`, value: "DD-MM-YYYY" }, { label: `YYYY-MM-DD(${moment().format('YYYY-MM-DD')})`, value: "YYYY-MM-DD" }, { label: `${moment().format('LL')}`, value: "LL" }, { label: `${moment().format('dddd,LL')}`, value: "dddd,LL" }])
	const [DateFormat, setDateFormat] = useState("");

	const [value, setValue] = useState();

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	let dropdownStyleDark = {
		option: (provided, state) => ({
			...provided,
			color: '#dddddf',
			backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
		}),
		control: (provided, state) => ({
			...provided,
			backgroundColor: "#2b2b40",
			borderColor: "#4c4c71"
		}),
		placeholder: (provided, state) => ({
			...provided,
			color: "#dddddf",
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: "#dddddf",
		}),
		menu: (provided, state) => ({
			...provided,
			backgroundColor: "#2b2b40",
		})
	}

	function onChange(value) {
		setCaptchaToken(value)
	}

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	function loadScripts() {

		var elem = document.getElementById("create_account");
		if (!elem) {
			var se = document.createElement('script');
			se.src = "/assets/js/custom/utilities/modals/create-account.js";
			se.id = "create_account";
			document.body.appendChild(se);
		}
	}

	useEffect(() => {
		let id = setInterval(() => {
			setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
		}, 10)

		return () => {
			clearInterval(id)
		}
	}, [])

	useEffect(() => {
		if (getCookie("access_token") != "") {
			var object = {};
			object.token = getCookie("access_token");

			var headers = {
				headers: {
					"Content-Type": "application/json",
					"Accept": "*/*"
				}
			}
			axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

				if (res.data.operation == "success") {
					var user_object = {};
					//console.log(res.data)
					user_object.phone = res.data.phone;
					user_object.email = res.data.email;
					user_object.name = res.data.name;
					user_object.img = res.data.img;
					setUser(user_object)
				}
				else {
					swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
						window.location.href = "/login"
					});
				}
			})
				.catch(function (error) {
					swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
						window.location.href = "/login"
					});
				});
		}

		//loadScripts();

		/*
			var se = document.createElement('script');
			se.src = "/assets/js/custom/authentication/sign-up/general.js";
			document.body.appendChild(se);
			*/
	}, [])

	useEffect(() => {
		var obj_array = [];
		var obj2 = {};
		country_arr.country_arr.map((obj, index) => {
			obj2 = {};
			obj2.label = obj;
			if (index > 0) {
				obj2.value = obj;
			}
			else {
				obj2.value = "";
			}
			obj_array.push(obj2);
		})
		set_country_array(obj_array);
	}, [])

	useEffect(() => {
		loadCountry({ value: country })
	}, [country_array])

	useEffect(() => {
		var obj_array = [];
		var jsonParsedArray = currency_arr.currency_arr[0]
		
		for (let key in jsonParsedArray) {
			var obj2 = {};
			if (jsonParsedArray.hasOwnProperty(key)) {
				obj2.label = jsonParsedArray[key].currency_name;
				obj2.value = key;
				obj2.symbol = jsonParsedArray[key].currency_symbol;
				obj_array.push(obj2);
			}
		}
		
		setCurrencylist(obj_array);
	}, []);


	useEffect(() => {
		async function getSetupinformation() {
			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-set-up-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "service": "expense" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			//console.log("abc",body.organization_list)
			if (body.quick_set_up == 1) {
				setcontent_flag(1);
				if (body.flag == 1) {
					setquick_set_flag(1);
				}
				else {
					setquick_set_flag(0);
				}
				
				setPlanDetails(body.plan_details);
				var temp_dropdown = [];
				if(body.organization_list!=undefined)
                {
					setOrganizationDetails(body.organization_list);
					body.organization_list.map((obj) => {
						var temp_obj = {};
						console.log(obj)
						temp_obj.label = obj.organization_name;
						temp_obj.value = obj.organization_id;
						temp_dropdown.push(temp_obj);
					})
				}
				setOrganizationList(temp_dropdown);
			}
			else if (body.quick_set_up == 0) {
				setcontent_flag(0);
			}
		}

		user.email && getSetupinformation();

	}, [user])

	function loadCountry(country) {
		setcountry(country.value);
		var selectedCountryIndex = 0;
		country_array.map((obj, index) => {
			if (obj.value == country.value) {
				selectedCountryIndex = index;
			}
		})
		var state_arr = s_a.s_a[selectedCountryIndex].split("|");
		//console.log(state_arr)
		var obj_array = [];
		var obj2 = {};
		obj_array.push({ value: "", label: "Select State" });
		for (let i = 0; i < state_arr.length; i++) {
			obj_array.push({ value: state_arr[i], label: state_arr[i] });
		}
		set_state_array(obj_array);
	}

	const Save_set_up_information = (e) => {
		//e.preventDefault();

		console.log("283")
		var nexp2 = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 &$|~!@#()_.;,:*-+%]*)?$/; //NOT operator needed in match
		if (OrganizationName == "") {
			//console.log("1");
			swal("Warning!", "Organization Name cannot be empty", "warning");
			return false;
		}
		else if (!isNaN(OrganizationName)) {
			//console.log("2");
			swal("Warning!", "Organization Name cannot be a number", "warning");
			return false;
		}
		else if (!OrganizationName.match(nexp2)) {
			//console.log("3");
			swal("Warning!", "Organization Name cannot contain symbols ", "warning");
			return false;
		}

		var exp3 = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9\/\-.:]*)?$/;
		//only single word without space, numbers or special characters
		if (organization_domain == "") {
			//console.log("7");
			swal("Warning!", "Please provide a Organization domain name", "warning");
			return false;
		}
		else if (!organization_domain.match(exp3)) {
			swal("Warning!", "Certain symbols are not allowed in domain name", "warning");
			return false;
		}
		if (country == "") {
			//console.log("7");
			swal("Warning!", "Please select a Country", "warning");
			return false;
		}
		if (state1 == "") {
			//console.log("7");
			swal("Warning!", "Please select a State", "warning");
			return false;
		}

		setMainSubmitButtonState(true)
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		console.log(quick_set_flag);
		if (quick_set_flag == 0) {
			var values = { "OrganizationName": OrganizationName, "organization_domain": organization_domain, "country": country, "state": state1, "timezone": timezone, "CurrencyCode": CurrencyCode, "CurrencySymbol": CurrencySymbol, "language": Language, "Industry": Industry, "Fiscal_Year": FiscalYear, "DateFormat": DateFormat, "service": "invoice", "name": user.name, "user_details": user };
			axios.post(`${process.env.REACT_APP_SERVER_URL}/save-set-up-information`, values, headers).then((res) => {

				if (res.data && res.data.operation == "success") {
					setMainSubmitButtonState(false)
					swal("Great!", "Organization details saved successfully!", "success").then((value) => {
						console.log(res.data.organization_id)
						setCookie('organization_id', res.data.organization_id, 1);
						//setCookie('aside_nav_flag',"My View", 1);
						window.location.href = "/"
					});
				}
				else {
					setMainSubmitButtonState(false)
					swal("Oops!", "Something went wrong!", "error");
				}
			});
		}
		else {
			console.log("abc")
			var values = { "OrganizationName": OrganizationName, "OrganizationId": OrganizationId, "organization_domain": organization_domain, "country": country, "state": state1, "timezone": timezone, "CurrencyCode": CurrencyCode, "CurrencySymbol": CurrencySymbol, "language": Language, "Industry": Industry, "Fiscal_Year": FiscalYear, "DateFormat": DateFormat, "service": "invoice", "name": user.name, "user_details": user };
			axios.post(`${process.env.REACT_APP_SERVER_URL}/update-set-up-information`, values, headers).then((res) => {

				if (res.data && res.data.operation == "success") {
					setMainSubmitButtonState(false)
					swal("Great!", "Organization details saved successfully!", "success").then((value) => {
						console.log(res.data.organization_id)
						setCookie('organization_id', res.data.organization_id, 1);
						//setCookie('aside_nav_flag',"My View", 1);
						window.location.href = "/"
					});
				}
				else {
					setMainSubmitButtonState(false)
					swal("Oops!", "Something went wrong!", "error");
				}
			});
		}
	}

	const dyanamic_org = (e) => {
        let arr=OrganizationDetails.filter(option => option.organization_id ==e.value)[0];

        setOrganizationName(e.label);
        settimezone(arr.timezone + " || " + arr.timezone_value);
        setFiscalYear(arr.fiscal_year);
        setDateFormat(arr.date_format);
        setIndustry(arr.industry);
        setCurrencyCode(arr.currency_code);
        setOrganizationId(e.value);

        setcountry(arr.country);
        loadCountry({value:arr.country});

        setstate1(arr.state);
        setorganization_domain(arr.organization_domain);
    }

	return (
		<>
			{
				(RedirectState == 1) ? ((getCookie("lastpage") != "") ? window.location.href = getCookie("lastpage") : window.location.href = "/"
				)
					:
					null
			}

			<div className="d-flex flex-column flex-root" id="kt_app_root">
				<div className="d-flex flex-column flex-lg-row flex-column-fluid">
					{content_flag == 1 ?
						<>
							<div className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column" id="kt_create_account_stepper">
								<div className="d-flex flex-column flex-lg-row-auto w-xl-500px bg-lighten shadow-sm">
									<div className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-500px scroll-y">
										<div className="d-flex flex-row-fluid flex-column flex-center p-10 pt-lg-20">
											<a href="/login" className="mb-10 mb-lg-20">
												<img alt="Logo" src="/assets/media/logos/logo-1.svg" className="h-40px" />
											</a>
											<div className="stepper-nav">
												<div className="stepper-item current" data-kt-stepper-element="nav">
													<div className="stepper-wrapper">
														<div className="stepper-icon w-40px h-40px">
															<i className="stepper-check fas fa-check"></i>
															<span className="stepper-number">1</span>
														</div>
														<div className="stepper-label">
															<h3 className="stepper-title">Organization Settings</h3>
															<div className="stepper-desc fw-semibold">Setup Your Organization Details</div>
														</div>
													</div>
													<div className="stepper-line h-40px"></div>
												</div>

												<div className="stepper-item" data-kt-stepper-element="nav">
													<div className="stepper-wrapper">
														<div className="stepper-icon w-40px h-40px">
															<i className="stepper-check fas fa-check"></i>
															<span className="stepper-number">2</span>
														</div>

														<div className="stepper-label">
															<h3 className="stepper-title">Account Settings</h3>
															<div className="stepper-desc fw-semibold">Setup Your Account Settings</div>
														</div>
													</div>
													<div className="stepper-line h-40px"></div>
												</div>

												<div className="stepper-item" data-kt-stepper-element="nav">
													<div className="stepper-wrapper">
														<div className="stepper-icon w-40px h-40px">
															<i className="stepper-check fas fa-check"></i>
															<span className="stepper-number">3</span>
														</div>
														<div className="stepper-label">
															<h3 className="stepper-title">Completed</h3>
															<div className="stepper-desc fw-semibold">Woah, we are here</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-150px min-h-lg-300px" style={{ "background-image": "url(assets/media/illustrations/sketchy-1/16.png" }}></div>
									</div>
								</div>

								<div className="d-flex flex-column flex-lg-row-fluid py-10">
									<div className="d-flex flex-center flex-column flex-column-fluid">
										<div className="w-lg-700px p-10 p-lg-15 mx-auto">
											<form className="my-auto pb-5" novalidate="novalidate" id="kt_create_account_form">
												<div className="current" data-kt-stepper-element="content">
													<div className="w-100">
														<div className="fv-row">
															<div className="row mb-5">
																<div className="col-md-12 fv-row">
																	<label className="required fs-5 fw-bold mb-2">Organization name</label>
																	{quick_set_flag == 0 ?
																		<input id="subcategory_name" type="text" className="form-control" placeholder="" name="Organization-name" value={OrganizationName} onChange={(e) => {
																			setOrganizationName(e.target.value);

																		}}
																		/>
																		:
																		<Select
																			styles={userTheme == 'dark' ? dropdownStyleDark : {}}
																			name="category"
																			id="category"
																			options={OrganizationList}
																			onChange={(event) => {
																				dyanamic_org(event);
																			}}
																			value={OrganizationList.filter(option => option.value == OrganizationId).length != 0 && OrganizationList.filter(option => option.value == OrganizationId)[0]}
																			placeholder="--Select Organization--"
																		/>
																	}
																</div>
															</div>
															{OrganizationId != "" &&
																<div className="row mb-5">
																	<div className="col-md-12 fv-row">
																		<label className="fs-5 fw-bold mb-2">Connected Modules</label><br />
																		{OrganizationDetails.filter(option => option.organization_id == OrganizationId)[0].invoice &&
																			<span class="badge badge-light-success fs-7 fw-bold me-2">Invoice</span>}
																		{OrganizationDetails.filter(option => option.organization_id == OrganizationId)[0].support &&
																			<span class="badge badge-light-info fs-7 fw-bold me-2">Support</span>}
																	</div>
																</div>}
															<div className="row fv-row mb-7">
																<div className="mb-1">
																	<label className="form-label fw-bolder text-dark fs-6">Organization Domain Name</label>
																	{quick_set_flag == 1 ?
																		<input className="form-control" type="text" placeholder="example: subway" id="orgdomain" name="org-domain" autocomplete="off" value={organization_domain} onChange={(e) => { setorganization_domain(e.target.value) }} disabled />
																		:
																		<input className="form-control" type="text" placeholder="example: subway" id="orgdomain" name="org-domain" autocomplete="off" value={organization_domain} onChange={(e) => { setorganization_domain(e.target.value) }} />
																	}
																</div>
															</div>
															<div className="d-flex flex-column mb-5 fv-row">
																<label className="d-flex align-items-center fs-5 fw-bold mb-2">
																	<span className="required">Country</span>
																	<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Your payment statements may very based on selected position"></i>
																</label>
																{
																	country_array.length > 0 &&
																	<Select
																		styles={userTheme == 'dark' ? dropdownStyleDark : {}}
																		name="country"
																		id="country"
																		options={country_array}
																		value={
																			country_array.filter(option =>
																				option.value == country)[0]
																		}
																		onChange={(e) => { setcountry(e.value); setstate1(""); loadCountry(e); }}
																		isDisabled={quick_set_flag}
																	/>
																}
																<span id="country1" className="text-danger font-weight-bold"></span>
															</div>
															<div className="d-flex flex-column mb-5 fv-row">
																<label className="d-flex align-items-center fs-5 fw-bold mb-2">
																	<span className="required">State</span>
																	{/* <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Your payment statements may very based on selected position"></i> */}
																</label>
																{
																	state_array.length > 0 &&
																	<Select
																		styles={userTheme == 'dark' ? dropdownStyleDark : {}}
																		name="state"
																		id="state"
																		options={state_array}
																		onChange={(e) => { setstate1(e.value); }}
																		value={
																			state_array.filter(option =>
																				option.value == state1)[0]
																		}
																		isDisabled={quick_set_flag}
																	/>
																}
																<span id="state2" className="text-danger font-weight-bold"></span>
															</div>
															<div className="row mb-5">
																<div className="col-md-12 fv-row">
																	<label className="col-lg-4 col-form-label required fw-bold fs-6">Time Zone</label>
																	<div className="col-lg-12 fv-row">
																		<select name="timezone" aria-label="Select a Timezone" id="timezone" data-placeholder="Select a timezone.." className="form-select" value={timezone} onChange={(e) => { settimezone(e.target.value) }}>
																			<option value="">Select a Timezone..</option>
																			<option data-bs-offset="-39600" value="International Date Line West || -11:00">(GMT-11:00) International Date Line West</option>
																			<option data-bs-offset="-39600" value="Midway Island || -11:00">(GMT-11:00) Midway Island</option>
																			<option data-bs-offset="-39600" value="Samoa || -11:00">(GMT-11:00) Samoa</option>
																			<option data-bs-offset="-36000" value="Hawaii || -11:00">(GMT-10:00) Hawaii</option>
																			<option data-bs-offset="-28800" value="Alaska || -11:00">(GMT-11:00) Alaska</option>
																			<option data-bs-offset="-25200" value="Pacific Time (US &amp; Canada) || -07:00">(GMT-07:00) Pacific Time (US &amp; Canada)</option>
																			<option data-bs-offset="-25200" value="Tijuana || -07:00">(GMT-07:00) Tijuana</option>
																			<option data-bs-offset="-25200" value="Arizona || -07:00">(GMT-07:00) Arizona</option>
																			<option data-bs-offset="-21600" value="Mountain Time (US &amp; Canada) || -06:00">(GMT-06:00) Mountain Time (US &amp; Canada)</option>
																			<option data-bs-offset="-21600" value="Chihuahua || -06:00">(GMT-06:00) Chihuahua</option>
																			<option data-bs-offset="-21600" value="Mazatlan || -06:00">(GMT-06:00) Mazatlan</option>
																			<option data-bs-offset="-21600" value="Saskatchewan || -06:00">(GMT-06:00) Saskatchewan</option>
																			<option data-bs-offset="-21600" value="Central America || -06:00">(GMT-06:00) Central America</option>
																			<option data-bs-offset="-18000" value="Central Time (US &amp; Canada) || -05:00">(GMT-05:00) Central Time (US &amp; Canada)</option>
																			<option data-bs-offset="-18000" value="Guadalajara || -05:00">(GMT-05:00) Guadalajara</option>
																			<option data-bs-offset="-18000" value="Mexico City || -05:00">(GMT-05:00) Mexico City</option>
																			<option data-bs-offset="-18000" value="Monterrey || -05:00">(GMT-05:00) Monterrey</option>
																			<option data-bs-offset="-18000" value="Bogota || -05:00">(GMT-05:00) Bogota</option>
																			<option data-bs-offset="-18000" value="Lima || -05:00">(GMT-05:00) Lima</option>
																			<option data-bs-offset="-18000" value="Quito || -05:00">(GMT-05:00) Quito</option>
																			<option data-bs-offset="-14400" value="Eastern Time (US &amp; Canada) || -04:00">(GMT-04:00) Eastern Time (US &amp; Canada)</option>
																			<option data-bs-offset="-14400" value="Indiana (East) || -04:00">(GMT-04:00) Indiana (East)</option>
																			<option data-bs-offset="-14400" value="Caracas || -04:00">(GMT-04:00) Caracas</option>
																			<option data-bs-offset="-14400" value="La Paz || -04:00">(GMT-04:00) La Paz</option>
																			<option data-bs-offset="-14400" value="Georgetown || -04:00">(GMT-04:00) Georgetown</option>
																			<option data-bs-offset="-10800" value="Atlantic Time (Canada) || -03:00">(GMT-03:00) Atlantic Time (Canada)</option>
																			<option data-bs-offset="-10800" value="Santiago || -03:00">(GMT-03:00) Santiago</option>
																			<option data-bs-offset="-10800" value="Brasilia || -03:00">(GMT-03:00) Brasilia</option>
																			<option data-bs-offset="-10800" value="Buenos Aires || -03:00">(GMT-03:00) Buenos Aires</option>
																			<option data-bs-offset="-9000" value="Newfoundland || -02:30">(GMT-02:30) Newfoundland</option>
																			<option data-bs-offset="-7200" value="Greenland || -02:00">(GMT-02:00) Greenland</option>
																			<option data-bs-offset="-7200" value="Mid-Atlantic || -02:00">(GMT-02:00) Mid-Atlantic</option>
																			<option data-bs-offset="-3600" value="Cape Verde Is. || -01:00">(GMT-01:00) Cape Verde Is.</option>
																			<option data-bs-offset="0" value="Azores || +00:00">(GMT) Azores</option>
																			<option data-bs-offset="0" value="Monrovia || +00:00">(GMT) Monrovia</option>
																			<option data-bs-offset="0" value="UTC || +00:00">(GMT) UTC</option>
																			<option data-bs-offset="3600" value="Dublin || +01:00">(GMT+01:00) Dublin</option>
																			<option data-bs-offset="3600" value="Edinburgh || +01:00">(GMT+01:00) Edinburgh</option>
																			<option data-bs-offset="3600" value="Lisbon || +01:00">(GMT+01:00) Lisbon</option>
																			<option data-bs-offset="3600" value="London || +01:00">(GMT+01:00) London</option>
																			<option data-bs-offset="3600" value="Casablanca || +01:00">(GMT+01:00) Casablanca</option>
																			<option data-bs-offset="3600" value="West Central Africa || +01:00">(GMT+01:00) West Central Africa</option>
																			<option data-bs-offset="7200" value="Belgrade || +02:00">(GMT+02:00) Belgrade</option>
																			<option data-bs-offset="7200" value="Bratislava || +02:00">(GMT+02:00) Bratislava</option>
																			<option data-bs-offset="7200" value="Budapest || +02:00">(GMT+02:00) Budapest</option>
																			<option data-bs-offset="7200" value="Ljubljana || +02:00">(GMT+02:00) Ljubljana</option>
																			<option data-bs-offset="7200" value="Prague || +02:00">(GMT+02:00) Prague</option>
																			<option data-bs-offset="7200" value="Sarajevo || +02:00">(GMT+02:00) Sarajevo</option>
																			<option data-bs-offset="7200" value="Skopje || +02:00">(GMT+02:00) Skopje</option>
																			<option data-bs-offset="7200" value="Warsaw || +02:00">(GMT+02:00) Warsaw</option>
																			<option data-bs-offset="7200" value="Zagreb || +02:00">(GMT+02:00) Zagreb</option>
																			<option data-bs-offset="7200" value="Brussels || +02:00">(GMT+02:00) Brussels</option>
																			<option data-bs-offset="7200" value="Copenhagen || +02:00">(GMT+02:00) Copenhagen</option>
																			<option data-bs-offset="7200" value="Madrid || +02:00">(GMT+02:00) Madrid</option>
																			<option data-bs-offset="7200" value="Paris || +02:00">(GMT+02:00) Paris</option>
																			<option data-bs-offset="7200" value="Amsterdam || +02:00">(GMT+02:00) Amsterdam</option>
																			<option data-bs-offset="7200" value="Berlin || +02:00">(GMT+02:00) Berlin</option>
																			<option data-bs-offset="7200" value="Bern || +02:00">(GMT+02:00) Bern</option>
																			<option data-bs-offset="7200" value="Rome || +02:00">(GMT+02:00) Rome</option>
																			<option data-bs-offset="7200" value="Stockholm || +02:00">(GMT+02:00) Stockholm</option>
																			<option data-bs-offset="7200" value="Vienna || +02:00">(GMT+02:00) Vienna</option>
																			<option data-bs-offset="7200" value="Cairo || +02:00">(GMT+02:00) Cairo</option>
																			<option data-bs-offset="7200" value="Harare || +02:00">(GMT+02:00) Harare</option>
																			<option data-bs-offset="7200" value="Pretoria || +02:00">(GMT+02:00) Pretoria</option>
																			<option data-bs-offset="10800" value="Bucharest || +03:00">(GMT+03:00) Bucharest</option>
																			<option data-bs-offset="10800" value="Helsinki || +03:00">(GMT+03:00) Helsinki</option>
																			<option data-bs-offset="10800" value="Kiev || +03:00">(GMT+03:00) Kiev</option>
																			<option data-bs-offset="10800" value="Kyiv || +03:00">(GMT+03:00) Kyiv</option>
																			<option data-bs-offset="10800" value="Riga || +03:00">(GMT+03:00) Riga</option>
																			<option data-bs-offset="10800" value="Sofia || +03:00">(GMT+03:00) Sofia</option>
																			<option data-bs-offset="10800" value="Tallinn || +03:00">(GMT+03:00) Tallinn</option>
																			<option data-bs-offset="10800" value="Vilnius || +03:00">(GMT+03:00) Vilnius</option>
																			<option data-bs-offset="10800" value="Athens || +03:00">(GMT+03:00) Athens</option>
																			<option data-bs-offset="10800" value="Istanbul || +03:00">(GMT+03:00) Istanbul</option>
																			<option data-bs-offset="10800" value="Minsk || +03:00">(GMT+03:00) Minsk</option>
																			<option data-bs-offset="10800" value="Jerusalem || +03:00">(GMT+03:00) Jerusalem</option>
																			<option data-bs-offset="10800" value="Moscow || +03:00">(GMT+03:00) Moscow</option>
																			<option data-bs-offset="10800" value="St. Petersburg || +03:00">(GMT+03:00) St. Petersburg</option>
																			<option data-bs-offset="10800" value="Volgograd || +03:00">(GMT+03:00) Volgograd</option>
																			<option data-bs-offset="10800" value="Kuwait || +03:00">(GMT+03:00) Kuwait</option>
																			<option data-bs-offset="10800" value="Riyadh || +03:00">(GMT+03:00) Riyadh</option>
																			<option data-bs-offset="10800" value="Nairobi || +03:00">(GMT+03:00) Nairobi</option>
																			<option data-bs-offset="10800" value="Baghdad || +03:00">(GMT+03:00) Baghdad</option>
																			<option data-bs-offset="14400" value="Abu Dhabi || +04:00">(GMT+04:00) Abu Dhabi</option>
																			<option data-bs-offset="14400" value="Muscat || +04:00">(GMT+04:00) Muscat</option>
																			<option data-bs-offset="14400" value="Baku || +04:00">(GMT+04:00) Baku</option>
																			<option data-bs-offset="14400" value="Tbilisi || +04:00">(GMT+04:00) Tbilisi</option>
																			<option data-bs-offset="14400" value="Yerevan || +04:00">(GMT+04:00) Yerevan</option>
																			<option data-bs-offset="16200" value="Tehran || +04:30">(GMT+04:30) Tehran</option>
																			<option data-bs-offset="16200" value="Kabul || +04:30">(GMT+04:30) Kabul</option>
																			<option data-bs-offset="18000" value="Ekaterinburg || +05:00">(GMT+05:00) Ekaterinburg</option>
																			<option data-bs-offset="18000" value="Islamabad || +05:00">(GMT+05:00) Islamabad</option>
																			<option data-bs-offset="18000" value="Karachi || +05:00">(GMT+05:00) Karachi</option>
																			<option data-bs-offset="18000" value="Tashkent || +05:00">(GMT+05:00) Tashkent</option>
																			<option data-bs-offset="19800" value="Chennai || +05:30">(GMT+05:30) Chennai</option>
																			<option data-bs-offset="19800" value="Kolkata || +05:30">(GMT+05:30) Kolkata</option>
																			<option data-bs-offset="19800" value="Mumbai || +05:30">(GMT+05:30) Mumbai</option>
																			<option data-bs-offset="19800" value="New Delhi || +05:30">(GMT+05:30) New Delhi</option>
																			<option data-bs-offset="19800" value="Sri Jayawardenepura || +05:30">(GMT+05:30) Sri Jayawardenepura</option>
																			<option data-bs-offset="20700" value="Kathmandu || +05:45">(GMT+05:45) Kathmandu</option>
																			<option data-bs-offset="21600" value="Astana || +06:00">(GMT+06:00) Astana</option>
																			<option data-bs-offset="21600" value="Dhaka || +06:00">(GMT+06:00) Dhaka</option>
																			<option data-bs-offset="21600" value="Almaty || +06:00">(GMT+06:00) Almaty</option>
																			<option data-bs-offset="21600" value="Urumqi || +06:00">(GMT+06:00) Urumqi</option>
																			<option data-bs-offset="23400" value="Rangoon || +06:30">(GMT+06:30) Rangoon</option>
																			<option data-bs-offset="25200" value="Novosibirsk || +07:00">(GMT+07:00) Novosibirsk</option>
																			<option data-bs-offset="25200" value="Bangkok || +07:00">(GMT+07:00) Bangkok</option>
																			<option data-bs-offset="25200" value="Hanoi || +07:00">(GMT+07:00) Hanoi</option>
																			<option data-bs-offset="25200" value="Jakarta || +07:00">(GMT+07:00) Jakarta</option>
																			<option data-bs-offset="25200" value="Krasnoyarsk || +07:00">(GMT+07:00) Krasnoyarsk</option>
																			<option data-bs-offset="28800" value="Beijing || +08:00">(GMT+08:00) Beijing</option>
																			<option data-bs-offset="28800" value="Chongqing || +08:00">(GMT+08:00) Chongqing</option>
																			<option data-bs-offset="28800" value="Hong Kong || +08:00">(GMT+08:00) Hong Kong</option>
																			<option data-bs-offset="28800" value="Kuala Lumpur || +08:00">(GMT+08:00) Kuala Lumpur</option>
																			<option data-bs-offset="28800" value="Singapore || +08:00">(GMT+08:00) Singapore</option>
																			<option data-bs-offset="28800" value="Taipei || +08:00">(GMT+08:00) Taipei</option>
																			<option data-bs-offset="28800" value="Perth || +08:00">(GMT+08:00) Perth</option>
																			<option data-bs-offset="28800" value="Irkutsk || +08:00">(GMT+08:00) Irkutsk</option>
																			<option data-bs-offset="28800" value="Ulaan Bataar || +08:00">(GMT+08:00) Ulaan Bataar</option>
																			<option data-bs-offset="32400" value="Seoul || +09:00">(GMT+09:00) Seoul</option>
																			<option data-bs-offset="32400" value="Osaka || +09:00">(GMT+09:00) Osaka</option>
																			<option data-bs-offset="32400" value="Sapporo || +09:00">(GMT+09:00) Sapporo</option>
																			<option data-bs-offset="32400" value="Tokyo || +09:00">(GMT+09:00) Tokyo</option>
																			<option data-bs-offset="32400" value="Yakutsk || +09:00">(GMT+09:00) Yakutsk</option>
																			<option data-bs-offset="34200" value="Darwin || +09:30">(GMT+09:30) Darwin</option>
																			<option data-bs-offset="34200" value="Adelaide || +09:30">(GMT+09:30) Adelaide</option>
																			<option data-bs-offset="36000" value="Canberra || +10:00">(GMT+10:00) Canberra</option>
																			<option data-bs-offset="36000" value="Melbourne || +10:00">(GMT+10:00) Melbourne</option>
																			<option data-bs-offset="36000" value="Sydney || +10:00">(GMT+10:00) Sydney</option>
																			<option data-bs-offset="36000" value="Brisbane || +10:00">(GMT+10:00) Brisbane</option>
																			<option data-bs-offset="36000" value="Hobart || +10:00">(GMT+10:00) Hobart</option>
																			<option data-bs-offset="36000" value="Vladivostok || +10:00">(GMT+10:00) Vladivostok</option>
																			<option data-bs-offset="36000" value="Guam || +10:00">(GMT+10:00) Guam</option>
																			<option data-bs-offset="36000" value="Port Moresby || +10:00">(GMT+10:00) Port Moresby</option>
																			<option data-bs-offset="36000" value="Solomon Is. || +10:00">(GMT+10:00) Solomon Is.</option>
																			<option data-bs-offset="39600" value="Magadan || +11:00">(GMT+11:00) Magadan</option>
																			<option data-bs-offset="39600" value="New Caledonia || +11:00">(GMT+11:00) New Caledonia</option>
																			<option data-bs-offset="43200" value="Fiji || +12:00">(GMT+12:00) Fiji</option>
																			<option data-bs-offset="43200" value="Kamchatka || +12:00">(GMT+12:00) Kamchatka</option>
																			<option data-bs-offset="43200" value="Marshall Is. || +12:00">(GMT+12:00) Marshall Is.</option>
																			<option data-bs-offset="43200" value="Auckland || +12:00">(GMT+12:00) Auckland</option>
																			<option data-bs-offset="43200" value="Wellington || +12:00">(GMT+12:00) Wellington</option>
																			<option data-bs-offset="46800" value="Nuku'alofa || +13:00">(GMT+13:00) Nuku'alofa</option>
																		</select>
																		<span id="timezone1" className="text-danger font-weight-bold"></span>
																	</div>
																</div>
															</div>
															<div className="row mb-5">
																<div className="col-md-12 fv-row">
																	<label className="required fs-5 fw-bold mb-2">Currency:</label>
																	<Select
																		styles={userTheme == 'dark' ? dropdownStyleDark : {}}
																		name="currency"
																		id="currency"
																		options={Currencylist}
																		onChange={(event) => { setCurrencyCode(event.value); setCurrencySymbol(event.symbol) }}
																		value={Currencylist.filter(option => option.value == CurrencyCode).length != 0 && Currencylist.filter(option => option.value == CurrencyCode)[0]}
																		placeholder="--Choose a Currency--"
																		isDisabled={quick_set_flag}
																	/>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div data-kt-stepper-element="content">
													<div className="w-100">
														<div className="pb-10 pb-lg-15">
															<h2 className="fw-bolder text-dark">Account Info</h2>
															<div className="text-muted fw-bold fs-6">If you need more info, please check out
																<a href="#" className="link-primary fw-bolder">Help Page</a>.</div>
														</div>
														<div className="row mb-5">
															<div className="col-md-12 fv-row">
																<label className="fs-5 fw-bold mb-2">Industry:</label>
																<Select
																	styles={userTheme == 'dark' ? dropdownStyleDark : {}}
																	name="category"
																	id="category"
																	options={Industrylist}
																	onChange={(event) => {
																		setIndustry(event.value)
																	}}
																	value={Industrylist.filter(option => option.value == Industry).length != 0 && Industrylist.filter(option => option.value == Industry)[0]}
																	placeholder="--Select Industry--"
																/>
															</div>
														</div>
														<div className="row mb-5">
															<div className="col-md-12 fv-row">
																<label className="fs-5 fw-bold mb-2">Fiscal Year:</label>
																<Select
																	styles={userTheme == 'dark' ? dropdownStyleDark : {}}
																	name="category"
																	id="category"
																	options={FiscalYearlist}
																	onChange={(event) => {
																		setFiscalYear(event.value)
																	}}
																	value={FiscalYearlist.filter(option => option.value == FiscalYear).length != 0 && FiscalYearlist.filter(option => option.value == FiscalYear)[0]}
																	placeholder="--Choose a Type--"
																/>
															</div>
														</div>
														<div className="row mb-5">
															<div className="col-md-12 fv-row">
																<label className="fs-5 fw-bold mb-2">Date Format:</label>
																<Select
																	styles={userTheme == 'dark' ? dropdownStyleDark : {}}
																	name="category"
																	id="category"
																	options={DateFormatlist}
																	onChange={(event) => {
																		setDateFormat(event.value)
																	}}
																	value={DateFormatlist.filter(option => option.value == DateFormat).length != 0 && DateFormatlist.filter(option => option.value == DateFormat)[0]}
																	placeholder="--Choose a Type--"
																/>
															</div>
														</div>

														{/*<div className="mb-0 fv-row">
											<label className="d-flex align-items-center form-label mb-5">Select Account Plan
											<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Monthly billing will be based on your account plan"></i></label>
											<div className="mb-0">
												<label className="d-flex flex-stack mb-5 cursor-pointer">
													<span className="d-flex align-items-center me-2">
														<span className="symbol symbol-50px me-6">
															<span className="symbol-label">
																<span className="svg-icon svg-icon-1 svg-icon-gray-600">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<path d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z" fill="black" />
																		<path opacity="0.3" d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z" fill="black" />
																	</svg>
																</span>
															</span>
														</span>

														<span className="d-flex flex-column">
															<span className="fw-bolder text-gray-800 text-hover-primary fs-5">Company Account</span>
															<span className="fs-6 fw-bold text-muted">Use images to enhance your post flow</span>
														</span>
													</span>

													<span className="form-check form-check-custom form-check-solid">
														<input className="form-check-input" type="radio" name="account_plan" value="1" />
													</span>
												</label>

												<label className="d-flex flex-stack mb-5 cursor-pointer">
													<span className="d-flex align-items-center me-2">
														<span className="symbol symbol-50px me-6">
															<span className="symbol-label">
																<span className="svg-icon svg-icon-1 svg-icon-gray-600">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<path d="M13 5.91517C15.8 6.41517 18 8.81519 18 11.8152C18 12.5152 17.9 13.2152 17.6 13.9152L20.1 15.3152C20.6 15.6152 21.4 15.4152 21.6 14.8152C21.9 13.9152 22.1 12.9152 22.1 11.8152C22.1 7.01519 18.8 3.11521 14.3 2.01521C13.7 1.91521 13.1 2.31521 13.1 3.01521V5.91517H13Z" fill="black" />
																		<path opacity="0.3" d="M19.1 17.0152C19.7 17.3152 19.8 18.1152 19.3 18.5152C17.5 20.5152 14.9 21.7152 12 21.7152C9.1 21.7152 6.50001 20.5152 4.70001 18.5152C4.30001 18.0152 4.39999 17.3152 4.89999 17.0152L7.39999 15.6152C8.49999 16.9152 10.2 17.8152 12 17.8152C13.8 17.8152 15.5 17.0152 16.6 15.6152L19.1 17.0152ZM6.39999 13.9151C6.19999 13.2151 6 12.5152 6 11.8152C6 8.81517 8.2 6.41515 11 5.91515V3.01519C11 2.41519 10.4 1.91519 9.79999 2.01519C5.29999 3.01519 2 7.01517 2 11.8152C2 12.8152 2.2 13.8152 2.5 14.8152C2.7 15.4152 3.4 15.7152 4 15.3152L6.39999 13.9151Z" fill="black" />
																	</svg>
																</span>
															</span>
														</span>
														<span className="d-flex flex-column">
															<span className="fw-bolder text-gray-800 text-hover-primary fs-5">Developer Account</span>
															<span className="fs-6 fw-bold text-muted">Use images to your post time</span>
														</span>
													</span>
													<span className="form-check form-check-custom form-check-solid">
														<input className="form-check-input" type="radio" checked="checked" name="account_plan" value="2" />
													</span>
												</label>
												<label className="d-flex flex-stack mb-0 cursor-pointer">
													<span className="d-flex align-items-center me-2">
														<span className="symbol symbol-50px me-6">
															<span className="symbol-label">
																<span className="svg-icon svg-icon-1 svg-icon-gray-600">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<path d="M13 10.9128V3.01281C13 2.41281 13.5 1.91281 14.1 2.01281C16.1 2.21281 17.9 3.11284 19.3 4.61284C20.7 6.01284 21.6 7.91285 21.9 9.81285C22 10.4129 21.5 10.9128 20.9 10.9128H13Z" fill="black" />
																		<path opacity="0.3" d="M13 12.9128V20.8129C13 21.4129 13.5 21.9129 14.1 21.8129C16.1 21.6129 17.9 20.7128 19.3 19.2128C20.7 17.8128 21.6 15.9128 21.9 14.0128C22 13.4128 21.5 12.9128 20.9 12.9128H13Z" fill="black" />
																		<path opacity="0.3" d="M11 19.8129C11 20.4129 10.5 20.9129 9.89999 20.8129C5.49999 20.2129 2 16.5128 2 11.9128C2 7.31283 5.39999 3.51281 9.89999 3.01281C10.5 2.91281 11 3.41281 11 4.01281V19.8129Z" fill="black" />
																	</svg>
																</span>
															</span>
														</span>
														<span className="d-flex flex-column">
															<span className="fw-bolder text-gray-800 text-hover-primary fs-5">Testing Account</span>
															<span className="fs-6 fw-bold text-muted">Use images to enhance time travel rivers</span>
														</span>	
													</span>
													<span className="form-check form-check-custom form-check-solid">
														<input className="form-check-input" type="radio" name="account_plan" value="3" />
													</span>
												</label>
											</div>
											</div>*/}
													</div>
												</div>


												<div className="" data-kt-stepper-element="content">
													<div className="w-100">
														<div className="pb-8 pb-lg-10">
															<h2 className="fw-bolder text-dark">Your Are Done!</h2>
															<div className="text-muted fw-bold fs-6">If you need more info, please
																<a href="../../demo1/dist/authentication/sign-in/basic.html" className="link-primary fw-bolder">Sign In</a>.</div>
														</div>
														<div className="mb-0">
															<div className="fs-6 text-gray-600 mb-5">Writing headlines for blog posts is as much an art as it is a science and probably warrants its own post, but for all advise is with what works for your great &amp; amazing audience.</div>
															<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
																<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
																	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																		<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
																		<rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="black" />
																		<rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="black" />
																	</svg>
																</span>
																<div className="d-flex flex-stack flex-grow-1">
																	<div className="fw-bold">
																		<h4 className="text-gray-900 fw-bolder">Start your {PlanDetails.duration} {PlanDetails.unit} free trial</h4>
																		<div className="fs-6 text-gray-700">To start using great tools, please, please
																			<a href="#" className="fw-bolder">Create Team Platform</a></div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>

												<div class="d-flex flex-stack pt-10">

													<div class="mr-2">
														<button type="button" class="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous">

															<span class="svg-icon svg-icon-4 me-1">
																<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor" />
																	<path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor" />
																</svg>
															</span>
															Back</button>
													</div>

													<div>
														<button type="button" class="btn btn-lg btn-primary me-3" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} onClick={(event) => { Save_set_up_information(); }} data-kt-stepper-action="submit">
															<span class="indicator-label">Submit

																<span class="svg-icon svg-icon-3 ms-2 me-0">
																	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor" />
																		<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" />
																	</svg>
																</span>
															</span>
															<span class="indicator-progress">Please wait...
																<span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
														</button>
														<button type="button" class="btn btn-lg btn-primary" data-kt-stepper-action="next">Continue

															<span class="svg-icon svg-icon-4 ms-1 me-0">
																<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="currentColor" />
																	<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="currentColor" />
																</svg>
															</span>
														</button>
													</div>

												</div>
												{/*<div className="d-flex flex-stack pt-15">
									<div className="mr-2">
										<button type="button" className="btn btn-lg btn-light-primary me-3" data-kt-stepper-action="previous">
										<span className="svg-icon svg-icon-4 me-1">
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
												<rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black" />
												<path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="black" />
											</svg>
										</span>
										</button>
									</div>
									<div>
										<button type="button" className="btn btn-lg btn-primary" data-kt-indicator={mainSubmitButtonState?"on":"off"} pdisabled={mainSubmitButtonState} onClick={(event) => {Save_set_up_information();}} data-kt-stepper-action="submit">
											<span className="indicator-label">Submit
											<span className="svg-icon svg-icon-4 ms-2">
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
													<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
													<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
												</svg>
											</span>
											</span>
											<span className="indicator-progress">Please wait...
											<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
										</button>
										<button type="button" className="btn btn-lg btn-primary" data-kt-stepper-action="next">Continue
										<span className="svg-icon svg-icon-4 ms-1">
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
												<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black" />
												<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black" />
											</svg>
										</span>
									</button>
									</div>
								</div>*/}
											</form>
										</div>
									</div>
									<div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
										<div className="d-flex flex-center fw-bold fs-6">
											<a href="https://keenthemes.com" className="text-muted text-hover-primary px-2" target="_blank">About</a>
											<a href="https://devs.keenthemes.com" className="text-muted text-hover-primary px-2" target="_blank">Support</a>
											<a href="https://1.envato.market/EA4JP" className="text-muted text-hover-primary px-2" target="_blank">Purchase</a>
										</div>
									</div>
								</div>
							</div>
							{content_flag == 1 && <div style={{ "display": "none" }}> setTimeout(function(){loadScripts()},500) </div>}
						</>
						:
						<>
							{
								content_flag == 2 ?
									<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
									:

									<div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2" style={{ "background-image": "url(assets/media/misc/auth-bg.png)" }}>

										<div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">

											<a href="../../demo1/dist/index.html" className="mb-12">
												<img alt="Logo" src="assets/media/logos/custom-1.png" className="h-75px" />
											</a>

											<div class="d-flex flex-column flex-center text-center p-10">
												<div class="card card-flush w-md-650px py-5">
													<div class="card-body py-15 py-lg-20">

														{/*<div class="mb-7">
										<a href="/metronic8/demo1/../demo1/index.html" class="">
											<img alt="Logo" src="/assets/media/logos/custom-2.svg" class="h-40px"/>
										</a>
									</div>
									
									<h1 class="fw-bolder text-gray-900 mb-5">Welcome to Metronic</h1>*/}

														<div className="notice d-flex bg-light-danger rounded border-danger border border-dashed rounded-3 p-6">
															<div className="d-flex flex-stack flex-grow-1">
																<div className="fw-bold">
																	<h4 className="text-gray-800 fw-bolder">Quick Set Up already Done.</h4>
																	<div className="fs-6 text-gray-600"> </div>
																</div>

															</div>

														</div>

														<div class="mb-0">
															<img src="/assets/media/auth/welcome.png" class="mw-100 mh-300px theme-light-show" alt="" />
															<img src="/assets/media/auth/welcome-dark.png" class="mw-100 mh-300px theme-dark-show" alt="" />
														</div>

														<div class="mb-0">
															<a href="/dashboard" class="btn btn-sm btn-primary">Go To Dashboard Page</a>
														</div>

													</div>
												</div>
											</div>
											<h1 className="text-white fs-2qx fw-bolder text-center mb-7">Fast, Efficient and Productive</h1>

											<div className="text-white fs-base text-center">In this kind of post,
												<a href="#" className="opacity-75-hover text-warning fw-bold me-1">the blogger</a>introduces a person they’ve interviewed
												<br />and provides some background information about
												<a href="#" className="opacity-75-hover text-warning fw-bold me-1">the interviewee</a>and their
												<br />work following this is a transcript of the interview.</div>
										</div>
									</div>
							}
						</>
					}
				</div>
			</div>
		</>
	);
}
export default QuickSetUp;