import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import country_arr from './countries-arr.js';
import s_a from './states-arr.js';
import moment from 'moment';
import Select from 'react-select';

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import { Link, Redirect } from 'react-router-dom';

function AccountBilling() {
	let [user, setUser] = useState({});
	let [state, setstate] = useState(2);
	const [SubscriptionDetails, setSubscriptionDetails] = useState([]);
	const [BillingDetails, setBillingDetails] = useState([]);

	let [first_name, setfirst_name] = useState("");
	let [last_name, setlast_name] = useState("");
	let [country, setcountry] = useState("");
	let [pin, setpin] = useState("");
	let [address1, setaddress1] = useState("");
	let [address2, setaddress2] = useState("");
	let [city, setcity] = useState("");
	let [state1, setstate1] = useState("");
	let [phone, setphone] = useState("");
	let [phn, setphn] = useState("");
	let [email, setemail] = useState("");
	let [name, setname] = useState("");
	let [image, setImage] = useState("");
	let [date, setdate] = useState(new Date());
	let [purchase_flag, setpurchase_flag] = useState("");
	let [country_array, set_country_array] = useState([]);
	const [MessageCount, setMessageCount] = useState([]);
	let [cards, set_cards] = useState([]);

	let [state_array, set_state_array] = useState([]);

	let [timer, setTimer] = useState(0);

	let [billingstate, setbillingstate] = useState(2);

	let [clock_timer, setClockTimer] = useState(0);

	let [alert_count, set_alert_count] = useState({ "count": 0, "total_count": 0 });
	let [create_button_show, set_create_button_show] = useState("");
	let [duration, set_duration] = useState("");

	let [payment_sub_id, set_payment_sub_id] = useState("");

	let [timezone_change, set_timezone_change] = useState("+00:00");

	const [visibility, setvisibility] = useState(0);
	let [redirect, setredirect] = useState(0);
	//const [organization_id, setOrganization_id] = useState("");
	let [read, setread] = useState(2);
	let [write, setwrite] = useState(2);

	var page_name = "account-billing";

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	const renderTime = ({ remainingTime }) => {

		if (remainingTime === 0) {
			//return <div className="timer">Too lale...</div>;
			window.location.href = "/login";
		}

		return (
			<div className="timer">
				<div className="text">Remaining</div>
				<div className="value">{remainingTime}</div>
				<div className="text">seconds</div>
			</div>
		);
	};

	let loader = 0;
	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	useEffect(() => {
		setCookie("lastpage", window.location.href, 1)
		const validateJWT = () => {

			if (getCookie("access_token") != "") {
				var object = {};
				object.token = getCookie("access_token");

				var headers = {
					headers: {
						"Content-Type": "application/json",
						"Accept": "*/*"
					}
				}
				axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var user_object = {};
						user_object.email = res.data.email;
						user_object.name = res.data.name;
						user_object.img = res.data.img;
						user_object.phone = res.data.phone;
						setUser(user_object)
					}
					else {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
					.catch(function (error) {
						console.log(error);
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					});

				axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

					if (res.data.operation == "success") {

						if (res.data.organization_flag == 0) {
							swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
								window.location.href = "/login"
							});
						}
						else {
							var path = window.location.pathname;
							path = path.replace(/\/$/, "");
							path = decodeURIComponent(path);
							var original_url = path.split("/")[1]
							let index = 0;
							let temp = -1;
							for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
								let component = res.data.user_access.access[i].inside_components;
								for (let j = 0; j < component.length; j++) {
									if (component[j].hasOwnProperty(original_url)) {
										index = i;
										if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
											setvisibility(1);
											setread(res.data.user_access.access[index].inside_components[j]["read"])
											setwrite(res.data.user_access.access[index].inside_components[j]["write"])
											// setwrite(0)
											break;
										}
										else {
											setredirect(1);
										}

									}
									else
									{
										temp = 2;
									}
								}

							}
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
						}
						var token = res.data.audience_license;

						if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
							setCookie('access_token', res.data.audience_license, 1)
							setTimer(process.env.REACT_APP_JWT_EXPIRY);
							//setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
						}
						else {
							res.data.audience_count = (res.data.audience_count)
							if (res.data.audience_count > 0) {
								const timer_decrease = setInterval(() => {
									res.data.audience_count = (res.data.audience_count - 1);
									//console.log(res.data.audience_count);
									if (res.data.audience_count > 0 && res.data.audience_count < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
										if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
											setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
											window.$('#kt_modal_timer').modal('show');
										}
									}
								}, 1000);

								return () => clearInterval(timer_decrease);
							}
							//setTimer(res.data.audience_count)
							//setClockTimer(res.data.audience_count)
						}
					}
					else {
						swal("Session Expired!", "Please login to continue using.11", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
					.catch(function (error) {
						console.log(error);
						swal("Session Expired!", "Please login to continue using.22", "warning").then((value) => {
							window.location.href = "/login"
						});
					});
			}
			else {
				swal("Session Expired!", "Please login to continue using.33", "warning").then((value) => {
					window.location.href = "/login"
				});
			}
		}

		validateJWT();

	}, [])


	useEffect(() => {
		async function getlivealertdetails() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-live-alert-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			var count = 0;
			for (var i = 0; i < body.live_alert.length; i++) {
				if (body.live_alert[i].add_status == "Active") {
					count = count + 1;
				}
			}

			const response2 = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-live-alert-count`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "subscription": create_button_show, "duration": duration }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body2 = await response2.json();

			set_alert_count({ "count": count, "total_count": body2.count })

		}
		if (read || write) {
			(user.email && create_button_show && visibility) && getlivealertdetails();
		}


	}, [user, create_button_show, visibility, read, write])

	useEffect(() => {
		function compare(now, date, status) {
			if (status == 'Active') {
				if (moment(now).isAfter(date)) {
					return true;
				}
			}
			return false;
		}

		async function get_subscription_details() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-purchase-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			var flag = 0;
			var subscription = "";
			var product = "";
			var duration = "";
			var sub_id = "";

			let now = new Date();
			for (var i = 0; i < body.length; i++) {
				body[i].ending_date = moment.utc(body[i].ending_date).add(1, 'days').format('YYYY-MM-DD');
				if (compare(now, body[i].ending_date, body[i].add_status)) {
					body[i].add_status = 'Expired';
				}
				if (body[i].add_status == "Active" && (body[i].product_id == "bdua1638osba09376b8b8" || body[i].product_id == "bdua1638osba09376b8c9")) {
					flag = 1;
					subscription = body[i].subscription_id;
					duration = body[i].duration;
					product = body[i].product_name;
					sub_id = body[i].sub_id;
					break;
				}
			}

			//console.log(body)

			if (flag == 1) {
				if (duration == "month") {
					set_duration("mon");
				}
				else if (duration == "yearly") {
					set_duration("year");
				}
				else {
					set_duration(duration);
				}
				set_create_button_show(subscription);
			}
			else {
				setstate(0);
			}

		}
		if (read || write) {
			user.email && visibility && get_subscription_details();
		}




		async function getpurchaseflagdetails() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-purchase-flag-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			//console.log(body.purchase_flag)
			setpurchase_flag(body.purchase_flag);

		}
		if (read || write) {
			user.email && visibility && getpurchaseflagdetails();
		}

		async function getusessubscription() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-uses-subscription`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			//var now = new Date();
			//console.log(now);
			body.map((obj, index) => {
				let today1 = new Date(body[index].ending_date).toLocaleString('hi-IN', { timeZone: 'Asia/Kolkata', hour12: false });
				var today_array = today1.split(", ");
				var date_string = today_array[0];
				var date_string_array = date_string.split("/");
				let date = new Date(date_string_array[2], date_string_array[1] - 1, date_string_array[0].padStart(2, '0'));
				let longMonth = date.toLocaleString('en-us', { month: 'long' }); var today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

				var today = date_string_array[0].padStart(2, '0') + " " + longMonth + "," + date_string_array[2];

				body[index].ending_date = moment.utc(body[index].ending_date).add(1, 'days').format('DD MMMM YYYY');
				//body[index].ending_date1 = date;
				body[index].ending_date1 = moment.utc(body[index].ending_date).add(1, 'days');

				let today2 = new Date(body[index].starting_date).toLocaleString('hi-IN', { timeZone: 'Asia/Kolkata', hour12: false });
				var today_array2 = today2.split(", ");
				var date_string2 = today_array2[0];
				var date_string_array2 = date_string2.split("/");
				let date1 = new Date(date_string_array2[2], date_string_array2[1] - 1, date_string_array2[0].padStart(2, '0'));
				let longMonth1 = date1.toLocaleString('en-us', { month: 'long' });
				var today3 = date_string_array2[0].padStart(2, '0') + " " + longMonth1 + " " + date_string_array2[2];
				//body[index].starting_date = today3;
				//body[index].starting_date1 = date1;

				body[index].starting_date = moment.utc(body[index].starting_date).add(1, 'days').format('DD MMMM YYYY');
				body[index].starting_date1 = moment.utc(body[index].starting_date).add(1, 'days').format('DD MMMM YYYY');

			})
			//console.log(body)
			setSubscriptionDetails(body);
			//setloading(true);
		}
		if (read || write) {
			user.email && visibility && getusessubscription();
		}

		async function getaddressinformation() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-address-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			//setphn(user.phone)
			if (body.length > 0) {
				//console.log(body[0].country);
				setcountry(body[0].country);

				var selectedCountryIndex = 0;
				country_array.map((obj, index) => {
					if (obj.value == body[0].country) {
						selectedCountryIndex = index;
					}
				})

				var state_arr = s_a.s_a[selectedCountryIndex].split("|");

				var obj_array = [];
				var obj2 = {};
				state_arr.map((obj, index) => {
					obj2 = {};
					obj2.label = obj;
					if (index > 0) {
						obj2.value = obj;
					}
					else {
						obj2.value = "";
					}
					obj_array.push(obj2);
				})
				set_state_array(obj_array);


				setpin(body[0].pincode)
				setfirst_name(body[0].first_name)
				setlast_name(body[0].last_name)
				setstate1(body[0].state)
				setaddress1(body[0].address_line1)
				setaddress2(body[0].address_line2)
				setcity(body[0].city)
				setphn(body[0].phone)
				setemail(body[0].email)
				//console.log(body)
				setBillingDetails(body);
				setbillingstate(1);
			}
			else {
				setbillingstate(0);

			}
		}
		if (read || write) {
			user.email && visibility && getaddressinformation();
		}

		async function getaccountdetails() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-account-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			//console.log(body)
			if (body.length > 0) {
				if (body[0].timezone_value != "" && body[0].timezone_value != null) {
					let timezone_change = body[0].timezone_value;

					set_timezone_change(timezone_change)
				}

				setname(body[0].account_name)
				if (body[0].image_name.startsWith("http")) {
					setImage(body[0].image_name)
				}
				else if (body[0].image_name) {
					setImage(`https://shavastra-gn.s3.ap-south-1.amazonaws.com/account_image/${body[0].image_name}`);
				}
				else {
					setImage(user.img)
				}
			}
			else {

				setname(user.name)
				setImage(user.img)
			}

		}
		if (read || write) {
			user.email && visibility && getaccountdetails();
		}


		async function getmessagecount() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/account-activity`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			setMessageCount(body);

			//console.log('body', body);


		}
		if (read || write) {
			user.email && visibility && getmessagecount();
		}

	}, [user, visibility, read, write])


	useEffect(() => {
		var obj_array = [];
		var obj2 = {};
		country_arr.country_arr.map((obj, index) => {
			obj2 = {};
			obj2.label = obj;
			if (index > 0) {
				obj2.value = obj;
			}
			else {
				obj2.value = "";
			}
			obj_array.push(obj2);
		})
		set_country_array(obj_array);
	}, []);

	useEffect(() => {
		async function get_card_details() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-card-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "sub_id": SubscriptionDetails[0].sub_id, "id": SubscriptionDetails[0].unique_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			//console.log(body.value)
			set_cards(body.value);
		}
		if (read || write) {
			user.email && visibility && SubscriptionDetails.length > 0 && get_card_details();
		}
	}, [SubscriptionDetails, visibility, read, write]);

	function loadCurrency(country) {

		setcountry(country.value);

		var selectedCountryIndex = 0;
		country_array.map((obj, index) => {
			if (obj.value == country.value) {
				selectedCountryIndex = index;
			}
		})

		var state_arr = s_a.s_a[selectedCountryIndex].split("|");
		//console.log(state_arr)
		var obj_array = [];
		var obj2 = {};
		//obj_array.push({label:"select state",value:""});
		obj_array.push({ value: "", label: "Select State" });
		for (let i = 0; i < state_arr.length; i++) {
			obj_array.push({ value: state_arr[i], label: state_arr[i] });
		}
		set_state_array(obj_array);
	}

	function cancel_subscription(e) {

		//var sub_id = document.getElementById("id").value;
		var sub_id = payment_sub_id;
		var payment_type = sub_id.split("+")[1];
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		var values = { "sub_id": sub_id.split("+")[0], "payment_gateway": payment_type, "page_name": page_name }

		let cancel_type = document.querySelector('input[name="cancel_radio"]:checked').value;

		if (cancel_type == "") {
			swal("Oops!", "Please select which type of subscription cancellation you want!", "error");
			return false;
		}
		else if (!isNaN(cancel_type)) {
			swal("Oops!", "Please select which type of subscription cancellation you want!", "error");
			return false;
		}

		console.log(values);
		console.log(cancel_type);

		if (cancel_type == "billing_period" && (payment_type == "stripe-euro" || payment_type == "stripe-usd")) {

			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription will be cancelled after the billing period!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});

		}
		else if (cancel_type == "now" && (payment_type == "stripe-euro" || payment_type == "stripe-usd")) {

			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription-now`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription cancelled!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});

		}
		else if (cancel_type == "billing_period" && payment_type == "razorpay") {

			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription-razorpay`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription will be cancelled after the billing period!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});

		}
		else if (cancel_type == "now" && payment_type == "razorpay") {

			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription-now-razorpay`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription cancelled!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});

		}
		else if (payment_type == "Trail") {
			axios.post(`${process.env.REACT_APP_SERVER_URL}/cancel-subscription-trial`, values, headers).then((res) => {
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Subscription cancelled!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});
		}
		else {
			swal("Oops!", "Please select which type of subscription cancellation you want!", "error");
			return false;
		}
	}

	const updateaccount = () => {
		var id = user.email;
		var first_name = document.getElementById("first_name").value;

		if (first_name == "") {
			document.getElementById('firstname').innerHTML = "Please fill the first name";
			return false;
		}
		else if (!isNaN(first_name)) {
			document.getElementById('firstname').innerHTML = "only charecters are allowed";
			return false;
		}
		else {
			document.getElementById('firstname').innerHTML = " ";
		}
		var last_name = document.getElementById("last_name").value;
		if (last_name == "") {
			document.getElementById('lastname').innerHTML = "Please fill the last name";
			return false;
		}
		else if (!isNaN(last_name)) {
			document.getElementById('lastname').innerHTML = "only charecters are allowed";
			return false;
		}
		else {
			document.getElementById('lastname').innerHTML = " ";
		}
		//var country = document.getElementById("country").value;
		//var country = country;
		if (country == "") {
			document.getElementById('country1').innerHTML = "Please select country";
			return false;
		}
		else {
			document.getElementById('country1').innerHTML = "";
		}
		//var state1 = document.getElementById("state").value;
		//console.log(state1);
		//var state1 =state1;
		if (state1 == "") {
			document.getElementById('state2').innerHTML = "Please select state";
			return false;
		}
		else {
			document.getElementById('state2').innerHTML = " ";
		}
		var address1 = document.getElementById("address1").value;
		if (address1 == "") {
			document.getElementById('address11').innerHTML = "Please fill the Address Line1";
			return false;
		}
		else {
			document.getElementById('address11').innerHTML = " ";
		}
		var address2 = document.getElementById("address2").value;

		var pin = document.getElementById("pin").value;
		if (pin == "") {
			document.getElementById('pin1').innerHTML = "Please fill the Zip Code";
			return false;
		}
		else if (isNaN(pin)) {
			document.getElementById('pin1').innerHTML = "User must write digits only not charecters";
			return false;
		}
		else {
			document.getElementById('pin1').innerHTML = " ";
		}
		var city = document.getElementById("city").value;
		if (city == "") {
			document.getElementById('town').innerHTML = "Please fill the town";
			return false;
		}
		else {
			document.getElementById('town').innerHTML = " ";
		}
		var phn = document.getElementById("phn").value;
		var regexp = /^([0|\+[0-9]{1,5})?([0-9]{1,15})$/
		if (phn == "") {
			document.getElementById('phn1').innerHTML = "Please fill the phone number";
			return false;
		}
		else if (!regexp.test(phn)) {
			document.getElementById('phn1').innerHTML = "Please enter valid phone number";
			return false;
		}
		else {
			document.getElementById('phn1').innerHTML = " ";
		}
		var values = { "id": id, "first_name": first_name, "city": city, "last_name": last_name, "country": country, "phone": phn, "email": user.email, "address1": address1, "address2": address2, "pin": pin, "state": state1, "page_name": page_name };

		//console.log(values);

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/update-address`, values, headers).then((res) => {
			if (res.data && res.data.operation == "success") {
				swal("Great!", "Address saved successfully!", "success").then((value) => {
					window.location.reload();
				});
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});

	}

	return (
		<>

			{
				(redirect == 1 || (read == 0 && write == 0)) ?
					<>
						<Redirect to={{
							pathname: "/unauthorized",
						}}
						/>
					</> :
					null
			}


			{user.email ?
				<>
					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">

						<div className="toolbar" id="kt_toolbar">
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
								<div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
									<h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Account Billing
										<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
										<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
									</h1>
								</div>

							</div>
						</div>

						<div className="post d-flex flex-column-fluid" id="kt_post">
							<div className="container" id="kt_content_container">
								<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
									<br />

									<div className="card mb-5 mb-xl-10">
										<div className="card pt-9 pb-0">

											<div className="d-flex flex-wrap flex-sm-nowrap mb-3">

												<div className="me-7 mb-4">
													<div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
														<img style={{ "max-height": "150px", "max-width": "150px" }} src={image} alt="image" className="rounded" />
														<div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
													</div>
												</div>


												<div className="flex-grow-1">

													<div className="d-flex justify-content-between align-items-start flex-wrap mb-2">

														<div className="d-flex flex-column">

															<div className="d-flex align-items-center mb-2">
																<a className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">{name}</a>

																{SubscriptionDetails.length > 0 ? SubscriptionDetails.map((obj, index) => {
																	return (
																		<>
																			{obj.ending_date1 > date ?
																				<a className="btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3 rounded" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">{obj.product_name}  -  {obj.subscription_name}</a>
																				:
																				null
																			}
																		</>
																	);
																})
																	:
																	null
																}
															</div>


															<div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">

																<a className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">


																	{user.email}</a>
															</div>

														</div>

													</div>
													<div className="d-flex flex-wrap flex-stack">

														<div className="d-flex flex-column flex-grow-1 pe-8">

															<div className="d-flex flex-wrap">

																{
																	(MessageCount.length) ?
																		<>
																			<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

																				<div className="d-flex align-items-center">



																					<div className="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="4500" data-kt-countup-prefix="$">{MessageCount[0].message_count}</div>
																				</div>

																				<div className="fw-bold fs-6 text-gray-400">Message</div>

																			</div>

																			<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

																				<div className="d-flex align-items-center">
																					<div className="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="75">{MessageCount[0].delivery_count}</div>
																				</div>

																				<div className="fw-bold fs-6 text-gray-400">Delivery</div>

																			</div>
																			<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

																				<div className="d-flex align-items-center">

																					<span className="svg-icon svg-icon-3 svg-icon-success me-2">

																					</span>

																					<div className="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="60" data-kt-countup-prefix="%">{alert_count.count}</div>
																				</div>

																				<div className="fw-bold fs-6 text-gray-400">Direct Alerts</div>

																			</div>
																		</>
																		: null
																}



															</div>

														</div>

														{BillingDetails.length > 0 ?
															<div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
																<div className="d-flex justify-content-between w-100 mt-auto mb-2">
																	<span className="fw-bold fs-6 text-gray-400">Profile Completion</span>
																	<span className="fw-bolder fs-6">100%</span>
																</div>
																<div className="h-5px mx-3 w-100 bg-light mb-3">
																	<div className="bg-success rounded h-5px" role="progressbar" style={{ "width": "100%" }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
																</div>
															</div>

															:
															<div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
																<div className="d-flex justify-content-between w-100 mt-auto mb-2">
																	<span className="fw-bold fs-6 text-gray-400">Profile Completion</span>
																	<span className="fw-bolder fs-6">90%</span>
																</div>
																<div className="h-5px mx-3 w-100 bg-light mb-3">
																	<div className="bg-success rounded h-5px" role="progressbar" style={{ "width": "90%" }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
																</div>
															</div>
														}

													</div>

												</div>

											</div>

											<div className="d-flex overflow-auto h-55px">
												<ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">

													<li className="nav-item">
														<Link className="nav-link text-active-primary me-6" to="/account-overview">Overview</Link>
													</li>

													<li className="nav-item">
														<Link className="nav-link text-active-primary me-6" to="/account-setting">Settings</Link>
													</li>

													<li className="nav-item">
														<Link className="nav-link text-active-primary me-6" to="/account-security">Security</Link>
													</li>

													<li className="nav-item">
														<Link className="nav-link text-active-primary me-6 active" to="/account-billing">Billing</Link>
													</li>

												</ul>
											</div>

										</div>
									</div>


									{purchase_flag == "not found" ?
										<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">

											<span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
												<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
													<path d="M18.5,8 C17.1192881,8 16,6.88071187 16,5.5 C16,4.11928813 17.1192881,3 18.5,3 C19.8807119,3 21,4.11928813 21,5.5 C21,6.88071187 19.8807119,8 18.5,8 Z M18.5,21 C17.1192881,21 16,19.8807119 16,18.5 C16,17.1192881 17.1192881,16 18.5,16 C19.8807119,16 21,17.1192881 21,18.5 C21,19.8807119 19.8807119,21 18.5,21 Z M5.5,21 C4.11928813,21 3,19.8807119 3,18.5 C3,17.1192881 4.11928813,16 5.5,16 C6.88071187,16 8,17.1192881 8,18.5 C8,19.8807119 6.88071187,21 5.5,21 Z" fill="#000000" opacity="0.3"></path>
													<path d="M5.5,8 C4.11928813,8 3,6.88071187 3,5.5 C3,4.11928813 4.11928813,3 5.5,3 C6.88071187,3 8,4.11928813 8,5.5 C8,6.88071187 6.88071187,8 5.5,8 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 C14,5.55228475 13.5522847,6 13,6 L11,6 C10.4477153,6 10,5.55228475 10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,18 L13,18 C13.5522847,18 14,18.4477153 14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 Z M5,10 C5.55228475,10 6,10.4477153 6,11 L6,13 C6,13.5522847 5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 C18.4477153,14 18,13.5522847 18,13 L18,11 C18,10.4477153 18.4477153,10 19,10 Z" fill="#000000"></path>
												</svg>
											</span>

											<div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">

												<div className="mb-3 mb-md-0 fw-bold">
													<h4 className="text-gray-800 fw-bolder">Activate Trial Account</h4>
													<div className="fs-6 text-gray-600 pe-7">You can Activate your Trial account and enjoy 7 days of free subscription.</div>
												</div>
												{
													write ?
														<Link to="/purchase-trial" className="btn btn-primary px-6 align-self-center text-nowrap rounded">Activate</Link>
														:
														<button className="btn btn-primary px-6 align-self-center text-nowrap rounded" disabled>Activate</button>
												}


											</div>

										</div>
										:
										null
									}

									<div className="card mb-5 mb-xl-10">

										<div className="card">
											{(SubscriptionDetails.length > 0) ? SubscriptionDetails.map((obj, index) => {
												return (
													<>
														{obj.ending_date1 > date ?
															<div className="card-body">
																<div className="row">
																	<div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap pt-6 pb-6">

																		<div className="col-lg-7">

																			<h3 className="mb-2"><a className="btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3 rounded" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">{obj.product_name}  -  {obj.subscription_name}</a> Active until {moment(obj.ending_date).format("DD MMM YYYY")}</h3>
																			<p className="fs-6 text-gray-600 fw-bold mb-6 mb-lg-15">We will send you a notification upon Subscription expiration</p>


																			<div className="fs-5 mb-2">
																				{obj.currency == "USD" ?
																					<span className="text-gray-800 fw-bolder me-1">${obj.price}</span>
																					:
																					obj.currency == "INR" ?
																						<span className="text-gray-800 fw-bolder me-1">₹{obj.price}</span>
																						:
																						obj.currency == "EUR" ?
																							<span className="text-gray-800 fw-bolder me-1">€{obj.price}</span>
																							:
																							null
																				}
																				<span className="text-gray-600 fw-bold">per {obj.duration}</span>
																			</div>

																			<div className="fs-6 text-gray-600 fw-bold">{obj.short_description}</div>

																		</div>


																		<div className="col-lg-5">
																			{obj.product_name == "Direct Alerts" &&
																				<>
																					<div className="d-flex text-muted fw-bolder fs-5 mb-3">
																						<span className="flex-grow-1 text-gray-800">Alerts</span>
																						<span className="text-gray-800">{alert_count.count} of {alert_count.total_count} Used</span>
																					</div>


																					<div className="progress h-8px bg-light-primary mb-2">
																						<div className="bg-success rounded h-8px" role="progressbar" style={{ "width": (alert_count.count / alert_count.total_count) * 100 > "100%" ? 100 : ((alert_count.count / alert_count.total_count) * 100) + "%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
																					</div>

																					<div className="fs-6 text-gray-600 fw-bold mb-10">{alert_count.total_count - alert_count.count < 0 ? 0 : alert_count.total_count - alert_count.count} Alerts remaining until your plan requires update</div>
																				</>
																			}

																			<div className="d-flex justify-content-end pb-0 px-0">
																				<input type="hidden" id="id" value={`${obj.sub_id}+${obj.payment_platform}`} name="activeId" />
																				{
																					write ?
																						<>
																							<a onClick={(e) => { set_payment_sub_id(`${obj.sub_id}+${obj.payment_platform}`) }} type="button" data-bs-toggle="modal" data-bs-target="#kt_modal_1" className="btn btn-light btn-active-light-primary me-2">Cancel Subscription</a>
																							<a type="button" className="btn btn-primary" href={`subscription-list/${obj.product_id}`}>Upgrade Plan</a>
																						</>
																						:
																						<>
																							<button className="btn btn-light btn-active-light-primary me-2" disabled>Cancel Subscription</button>
																							<button type="button" className="btn btn-primary" disabled>Upgrade Plan</button>
																						</>
																				}

																			</div>

																		</div>


																	</div>

																</div>
															</div>
															:
															null
														}
													</>
												);
											})
												:
												null
											}


										</div>

									</div>
									{cards.brand &&
										<div className="card mb-5 mb-xl-10">

											<div className="card-header card-header-stretch pb-0">

												<div className="card-title">
													<h3 className="m-0">Payment Methods</h3>
												</div>


												<div className="card-toolbar m-0">

													<ul className="nav nav-stretch nav-line-tabs border-transparent" role="tablist">

														<li className="nav-item" role="presentation">
															<a id="kt_billing_creditcard_tab" className="nav-link fs-5 fw-bolder me-5 active" data-bs-toggle="tab" role="tab" href="#kt_billing_creditcard">Credit / Debit Card</a>
														</li>

													</ul>

												</div>

											</div>


											<div id="kt_billing_payment_tab_content" className="card-body tab-content">

												<div id="kt_billing_creditcard" className="tab-pane fade show active" role="tabpanel">

													<h3 className="mb-5">My Cards</h3>


													<div className="row gx-9 gy-6">



														<div className="col-xl-6">

															<div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">

																<div className="d-flex flex-column py-2">

																	<div className="d-flex align-items-center fs-4 fw-bolder mb-5"></div>


																	<div className="d-flex align-items-center">
																		{cards.brand == "visa" ?
																			<img src="assets/media/svg/card-logos/visa.svg" alt="" className="me-4" />
																			:
																			(
																				cards.brand == "mastercard" ?
																					<img src="assets/media/svg/card-logos/mastercard.svg" alt="" className="me-4" />
																					:
																					<img src="assets/media/svg/card-logos/american-express.svg" alt="" className="me-4" />
																			)
																		}

																		<div>
																			<div className="fs-4 fw-bolder">{cards.brand} **** {cards.last4}</div>
																			<div className="fs-6 fw-bold text-gray-400">Card expires at {cards.exp_month}/{cards.exp_year}</div>
																		</div>

																	</div>

																</div>


															</div>

														</div>




													</div>

												</div>

											</div>


										</div>
									}

									{billingstate == 1 ? BillingDetails.map((obj, index) => {
										return (

											<div className="card mb-5 mb-xl-10">

												<div className="card-header">

													<div className="card-title">
														<h3>Billing Address</h3>
													</div>

												</div>


												<div className="card-body">

													<div className="row gx-9 gy-6">

														<div className="col-xl-6">

															<div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">

																<div className="d-flex flex-column py-2">
																	<div className="d-flex align-items-center fs-5 fw-bolder mb-5">Address 1
																		<span className="badge badge-light-success fs-7 ms-2 rounded">Primary</span></div>
																	<div className="fs-6 fw-bold text-gray-600">{obj.address_line1},{obj.state}
																		<br />{obj.city}-{obj.pincode}
																		<br />{obj.country}</div>
																</div>


																<div className="d-flex align-items-center py-2">
																	{
																		write ?
																		<button className="btn btn-sm btn-light btn-active-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_address">Edit</button>
																		: 
																		<button className="btn btn-sm btn-light btn-active-light-primary" disabled>Edit</button>
																	}
																	
																</div>

															</div>

														</div>

													</div>

												</div>

											</div>
										);
									})
										:
										billingstate == 0 ?
											<div className="col-xl-12">

												<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed flex-stack h-xl-100 mb-10 p-6">

													<div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">

														<div className="mb-3 mb-md-0 fw-bold">
															<h4 className="text-gray-800 fw-bolder">This is a very important note!</h4>
															<div className="fs-6 text-gray-600 pe-7">Please add Billing address for purchasing products</div>
														</div>
														{
															write ?
																<a className="btn btn-primary px-6 align-self-center text-nowrap rounded" data-bs-toggle="modal" data-bs-target="#kt_modal_new_address">New Address</a>
																:
																<button className="btn btn-primary px-6 align-self-center text-nowrap rounded" data-bs-toggle="modal" data-bs-target="#kt_modal_new_address" disabled>New Address</button>
														}


													</div>

												</div>

											</div>
											:
											null

									}

									{SubscriptionDetails.length > 0 ?
										<div className="card">

											<div className="card-header card-header-stretch border-bottom border-gray-200">

												<div className="card-title">
													<h3 className="fw-bolder m-0">Billing History</h3>
												</div>




											</div>


											<div className="tab-content">

												<div id="kt_billing_months" className="card-body p-0 tab-pane fade show active" role="tabpanel" aria-labelledby="kt_billing_months">

													<div className="table-responsive">

														<table className="table table-row-bordered align-middle gy-4 gs-9">
															<thead className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bolder bg-light bg-opacity-75">
																<tr>
																	<td className="min-w-150px">Starting Date</td>
																	<td className="min-w-150px">Ending Date</td>
																	<td className="min-w-250px">email</td>
																	<td className="min-w-150px">Amount</td>
																	<td className="min-w-150px">duration</td>
																	<td></td>
																</tr>
															</thead>
															<tbody className="fw-bold text-gray-600">
																{SubscriptionDetails.map((obj, index) => {
																	return (
																		<>
																			<tr>
																				<td>{obj.starting_date}</td>
																				<td>{obj.ending_date}</td>
																				<td>
																					<a >{obj.user_id}</a>
																				</td>
																				{obj.currency == "USD" ?
																					<td>${obj.price}</td>
																					:
																					obj.currency == "INR" ?
																						<td>₹{obj.price}</td>
																						:
																						obj.currency == "EUR" ?
																							<td>€{obj.price}</td>
																							:
																							null
																				}

																				<td>
																					{obj.duration}
																				</td>

																			</tr>
																		</>
																	);
																})}


															</tbody>
														</table>

													</div>
												</div>
											</div>

										</div>
										:
										null
									}



									<br />
								</div>
							</div>
						</div>
					</div>
				</>
				:
				<>
					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">

						<div className="toolbar" id="kt_toolbar">
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
								<div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
									<h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Account Billing
										<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
										<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
									</h1>

								</div>

							</div>
						</div>

						<div className="post d-flex flex-column-fluid" id="kt_post">
							<div className="container" id="kt_content_container">
								<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
									<br />

									{state == 2 ?
										<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
										:
										<>

											<div className="flex-lg-row-fluid me-lg-15">
												<div className="card card-flush pt-3 mb-5 mb-lg-10">

													<div className="card-header">
														<div className="card-title">
															<h2 className="fw-bolder">Account Billing</h2>
														</div>
													</div>


													<div className="card-body pt-0">
														<div className="text-gray-500 fw-bold fs-5 mb-5"></div>
														<div className="d-flex align-items-center p-3 mb-2">


														</div>


														<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
															<div className="d-flex flex-stack flex-grow-1">
																<div className="fw-bold">
																	<h4 className="text-gray-800 fw-bolder">Please Login</h4>
																	<div className="fs-6 text-gray-600">
																	</div>
																</div>

															</div>

														</div>

													</div>

												</div>
											</div>

										</>
									}


									<br />
								</div>
							</div>
						</div>
					</div>
				</>
			}



			<div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

				<div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

					<div className="modal-content">

						<div className="modal-header" id="kt_modal_new_address_header">

							<h2>Session Out</h2>

							<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

								<span className="svg-icon svg-icon-1">
									<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
										<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
											<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
											<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
										</g>
									</svg>
								</span>

							</div>

						</div>

						<div className="modal-body py-10 px-lg-17">

							<div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

								<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

									<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
											<rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
											<rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
										</svg>
									</span>


									<div className="d-flex flex-stack flex-grow-1">

										<div className="fw-bold">
											<h4 className="text-gray-800 fw-bolder">Warning</h4>
											<div className="fs-6 text-gray-600">Your session is going to time out</div>
										</div>

									</div>

								</div>

								{clock_timer > 0 &&
									<div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
										<CountdownCircleTimer
											isPlaying
											duration={clock_timer}
											colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
											onComplete={() => [true, 1000]}
										>
											{renderTime}
										</CountdownCircleTimer>
									</div>
								}

							</div>

						</div>

						<div className="modal-footer flex-center">

							<button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
								<span className="indicator-label">Stay Signed in</span>
								<span className="indicator-progress">Please wait...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
							</button>

						</div>

					</div>
				</div>
			</div>










			<div className="modal fade" id="kt_modal_new_address" tabindex="-1" aria-hidden="true">

				<div className="modal-dialog modal-dialog-centered mw-850px">

					<div className="modal-content">

						<form className="form" action="#" id="kt_modal_new_address_form">

							<div className="modal-header" id="kt_modal_new_address_header">

								<h2>Add New Address</h2>

								<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

									<span className="svg-icon svg-icon-1">
										<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
												<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
												<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
											</g>
										</svg>
									</span>

								</div>

							</div>

							<div className="modal-body py-10 px-lg-17">

								<div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

									<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">

										<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
											<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
												<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
												<rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
												<rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
											</svg>
										</span>


										<div className="d-flex flex-stack flex-grow-1">

											<div className="fw-bold">
												<h4 className="text-gray-800 fw-bolder">Warning</h4>
												<div className="fs-6 text-gray-600">Updating address may affect to your
													<a > Currency</a></div>
											</div>

										</div>

									</div>



									<div className="row mb-5">

										<div className="col-md-6 fv-row">

											<label className="required fs-5 fw-bold mb-2">First name</label>


											<input type="text" className="form-control form-control-solid" placeholder="" name="first-name" id="first_name" value={first_name} onChange={(e) => { setfirst_name(e.target.value) }} />
											<span id="firstname" class="text-danger font-weight-bold"></span>

										</div>


										<div className="col-md-6 fv-row">

											<label className="required fs-5 fw-bold mb-2">Last name</label>


											<input type="text" className="form-control form-control-solid" placeholder="" name="last-name" id="last_name" value={last_name} onChange={(e) => { setlast_name(e.target.value) }} />
											<span id="lastname" class="text-danger font-weight-bold"></span>

										</div>

									</div>
									<div className="row g-9 mb-5">

										<div className="col-md-6 fv-row">

											<label className="fs-5 fw-bold mb-2">phone number</label>

											<input className="form-control form-control-solid" placeholder="" id="phn" name="phn" value={phn} onChange={(e) => { setphn(e.target.value) }} />
											<span id="phn1" class="text-danger font-weight-bold"></span>

										</div>


										<div className="col-md-6 fv-row">

											<label className="fs-5 fw-bold mb-2">Email</label>



											<input className="form-control form-control-solid" placeholder="" name="email" id="email" value={user.email} readonly />
											<span id="email1" class="text-danger font-weight-bold"></span>

										</div>

									</div>


									<div className="d-flex flex-column mb-5 fv-row">

										<label className="d-flex align-items-center fs-5 fw-bold mb-2">
											<span className="required">Country</span>
											<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Your payment statements may very based on selected position"></i>
										</label>

										{
											country_array.length > 0 &&
											<Select
												name="country"
												id="country"
												options={country_array}
												value={
													country_array.filter(option =>
														option.value == country)[0]
												}
												onChange={(e) => { setcountry(e.value); setstate1(""); loadCurrency(e); }}
											/>
										}
										<span id="country1" class="text-danger font-weight-bold"></span>
									</div>


									<div className="d-flex flex-column mb-5 fv-row">

										<label className="d-flex align-items-center fs-5 fw-bold mb-2">
											<span className="required">State</span>
											{/* <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Your payment statements may very based on selected position"></i> */}
										</label>

										{
											state_array.length > 0 &&
											<Select
												name="state"
												id="state"
												options={state_array}
												onChange={(e) => { setstate1(e.value); }}
												value={
													state_array.filter(option =>
														option.value == state1)[0]
												}
											/>
										}
										<span id="state2" class="text-danger font-weight-bold"></span>
									</div>

									<div className="d-flex flex-column mb-5 fv-row">

										<label className="required fs-5 fw-bold mb-2">Address Line 1</label>


										<input className="form-control form-control-solid" placeholder="" name="address1" id="address1" value={address1} onChange={(e) => { setaddress1(e.target.value) }} />
										<span id="address11" class="text-danger font-weight-bold"></span>
									</div>


									<div className="d-flex flex-column mb-5 fv-row">

										<label className="fs-5 fw-bold mb-2">Address Line 2</label>


										<input className="form-control form-control-solid" placeholder="" name="address2" id="address2" value={address2} onChange={(e) => { setaddress2(e.target.value) }} />
										<span id="address21" class="text-danger font-weight-bold"></span>
									</div>

									<div className="row g-9 mb-5">

										<div className="col-md-6 fv-row">

											<label className="required fs-5 fw-bold mb-2">Town</label>


											<input className="form-control form-control-solid" placeholder="" name="city" id="city" value={city} onChange={(e) => { setcity(e.target.value) }} />
											<span id="town" class="text-danger font-weight-bold"></span>
										</div>


										<div className="col-md-6 fv-row">

											<label className="required fs-5 fw-bold mb-2">Zip Code</label>


											<input className="form-control form-control-solid" placeholder="" name="postcode" id="pin" value={pin} onChange={(e) => { setpin(e.target.value) }} />
											<span id="pin1" class="text-danger font-weight-bold"></span>
										</div>

									</div>



									<div className="fv-row mb-5">

									</div>

								</div>

							</div>

							<div className="modal-footer flex-center">

								<button type="reset" id="kt_modal_new_address_cancel" data-bs-dismiss="modal" className="btn btn-white me-3">Discard</button>


								<button type="button" onClick={updateaccount} className="btn btn-primary">
									<span className="indicator-label">Submit</span>
									<span className="indicator-progress">Please wait...
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
								</button>

							</div>

						</form>

					</div>
				</div>
			</div>


			<div className="modal fade" id="kt_modal_1" tabindex="-1" aria-hidden="true">

				<div className="modal-dialog mw-650px">

					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">Cancel Subscription</h5>
							<div className="modal-header pb-0 border-0 justify-content-end">

								<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

									<span className="svg-icon svg-icon-1">
										<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
												<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
												<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
											</g>
										</svg>
									</span>

								</div>

							</div>
						</div>
						<div className="modal-body">
							<div className="form-group row">


								<div className="col-lg-12">
									Current subscription status is : {<span className="fs-5 fw-bolder text-success"> Active </span>}<br /><br /><br />

									<div className="mb-0 fv-row">

										<label className="d-flex align-items-center form-label mb-5">Select Cancel Mode
											<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title=""></i></label>


										<div className="mb-0">

											<label className="d-flex flex-stack mb-5 cursor-pointer">

												<span className="d-flex align-items-center me-2" for="once">



													<span className="d-flex flex-column">
														<span className="fw-bolder text-gray-800 text-hover-primary fs-5">Cancel now</span>

													</span>

												</span>

												<span className="form-check form-check-custom form-check-solid">
													<input className="form-check-input" id="cancel_radio" type="radio" name="cancel_radio" value="now" checked="checked" />
												</span>

											</label>

											<label className="d-flex flex-stack mb-5 cursor-pointer">

												<span className="d-flex align-items-center me-2" for="repeat">


													<span className="d-flex flex-column">
														<span className="fw-bolder text-gray-800 text-hover-primary fs-5">Cancel After Billing Period</span>

													</span>

												</span>

												<span className="form-check form-check-custom form-check-solid" >
													<input className="form-check-input" id="cancel_radio" type="radio" name="cancel_radio" value="billing_period" />
												</span>

											</label>

										</div>

									</div>
									<br /><br />

									<h3>Are you sure about cancelling subscription?</h3>
								</div>

							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
							<form >

								<button type="button" className="btn btn-danger" onClick={(e) => { cancel_subscription(e); }} >Yes</button>
							</form>
						</div>

					</div>

				</div>

			</div>

		</>
	);
}
export default AccountBilling;