import React from 'react'

const SetColor = (props) => {
    const { value, index, change } = props;
    let color = 'green';
    if (value == 'Stop' || value == 'Pending' || value == 'Open' || value == 'Expired' || value == 'Deactivated') {
        color = 'red';
    }

    return (
        <p
            value={value}
            onChange={event => change(event.target.value, index)}
            style={{ color: color }}>{value}
        </p>
    )
}

export default SetColor
