import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom'

import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';

import "@pathofdev/react-tag-input/build/index.css";
import Select from 'react-select';
import uuid from 'react-uuid';
import swalr from '@sweetalert/with-react';

import Dropzone from 'react-dropzone-uploader';
import $, { type } from 'jquery';
import ReactDOM from 'react-dom';
import {Form, Col,Button,InputGroup} from 'react-bootstrap';
import { Formik } from "formik";
import * as yup from "yup";
import ReactTagInput from "@pathofdev/react-tag-input";
import { object } from 'yup/lib/locale';

function ResellerAssignProducts(props) {
    let [user, setUser] = useState({});
    let [state, setstate] = useState(2);
    let [clock_timer, setClockTimer] = useState(0);
    let [timer, setTimer] = useState(0);
    let [loader, setLoader] = useState(0);

    const [visibility, setvisibility] = useState(0);
    let [redirect, setredirect] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);

    const [resellerName,setResellerName] = useState("")
    const [due_date, setDue_date] = useState(moment.utc().add(7, 'days').format('YYYY-MM-DD'))
    const [availableProducts, setAvailableProducts] = useState([])

    const [productDropdown,setProductDropdown] = useState([])
    const [products,setProducts] = useState([])

    const [productCart,setProductCart] = useState([])

    const [taxing,setTaxing] = useState(false)
    const [pricing,setPricing] = useState(false)
    
    const [subTotal,setSubTotal] = useState(0) 
    const [totalTax,setTotalTax] = useState(0) 
    const [discount,setDiscount] = useState(0)
    const [grandTotal,setGrandTotal] = useState(0) 

    const [discountType, setDiscountType] = useState("");
    const [discountUnit,setDiscountUnit] = useState(0)
    const [note,setNote] = useState("")

    const [currencyIcon,setCurrencyIcon] = useState("$")

    var page_name = "assign-products";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };
    
    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too late...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });

                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
                        else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }
                            }
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            res.data.audience_count = (res.data.audience_count)
                            if (res.data.audience_count > 0) {
                                const timer_decrease = setInterval(() => {
                                    res.data.audience_count = (res.data.audience_count - 1);
                                    //console.log(res.data.audience_count);
                                    if (res.data.audience_count > 0 && res.data.audience_count < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                                        if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                                            setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                                            window.$('#kt_modal_timer').modal('show');
                                        }
                                    }
                                }, 1000);

                                return () => clearInterval(timer_decrease);
                            }
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                        window.location.href = "/login"
                    });
                });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }

        validateJWT();

    }, [])

    useEffect(() => {
		//function to get reseller name
		async function getresellername() {
			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/reseller-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			if (body.length != 0) {
				body.forEach((elem) => {
					if (elem.sid == props.match.params.id) {
						setResellerName(elem.fname + " " + elem.lname)
					}
				})
			}
		}
		if (read || write) {
			(user.email && visibility) && getresellername();
		}

	}, [user, visibility, read, write])

    useEffect(() => {
        // function to get all available products
        async function getProductDetails() {
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/available-product-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            // console.log(body)
            if (body.length > 0) {
                setAvailableProducts(body);
                setstate(1);
            }
            else {
                setstate(3);
            }
        }
        if(read || write)
        {
            (user.email && visibility)&& getProductDetails();
        }

	}, [user, visibility, read, write])

    //function to create options for product list dropdown
	useEffect(() => {
		let temp = productCart.map(e => e.pid)

        let options = []
		availableProducts.forEach((elem) => {
			if (!temp.includes(elem.pid)) 
			    options.push({value: elem.pid, label: elem.product_name})
		})
		
		options.sort((a,b)=> (a.label > b.label ? 1 : -1))
		setProductDropdown(options)
	}, [availableProducts,productCart])

    //function to remove item from cart
    const removeFromCart = (pid) => {
        let temp = productCart.filter(obj => obj.pid != pid)
        setProductCart(temp)
    }

    //function to add item to cart
    const addToCart = () => {
        let temp = products.map(e => e.value)
        let temp2 = availableProducts.filter(e => temp.includes(e.pid))

        temp2 = temp2.map(a => {return {...a, serial_button: false, serial_numbers: [], selected_quantity: 1, selected_price: 0, total_price: 0 }})

        setProductCart([...productCart, ...temp2])
        setProducts([])
    };

    //function to handle serial toggle buttons
    const serialToggle = (pid) => {
        let temp = productCart.map((elem) => {
            if(elem.pid == pid){
                if(elem.serial_button){
                    let t = {...elem, serial_button: !elem.serial_button, serial_numbers: []}
                    if(elem.selected_quantity>elem.quantity-elem.serial_list.length){
                        t = {...t, selected_quantity: elem.quantity-elem.serial_list.length}
                    }

                    return t
                }
                else
                    return {...elem, serial_button: !elem.serial_button}
            }
            else
                return {...elem}
        })

        setProductCart(temp)
    }

    //function to handle serial selection
    const handleSerialChange = (pid,arr) => {
        let temp = productCart.map((elem) => {
            if(elem.pid == pid)
            {
                return {...elem, serial_numbers: arr, selected_quantity: elem.selected_quantity<arr.length?arr.length:elem.selected_quantity}
            }
            else
            {
                return {...elem}
            }
        })  
        setProductCart(temp)
    }

    //function to handle quantity input
    const handleQuantityChange = (pid,val) => {
        let temp = productCart.map((elem) => {
            if(elem.pid == pid)
            {
                let flag = true
                if(val>elem.quantity)
                {
                    swal("Oops!", "Count can't exceed your stock limit", "warning")
                    flag = false
                }
                else if(val<elem.serial_numbers.length)
                {
                    swal("Oops!", "You can decrease your product count after removing product serial", "warning")
                    flag = false
                }
                else if(val>(elem.quantity - elem.serial_list.length) + elem.serial_numbers.length)
                {
                    swal("Oops!", "You can increase your product count after adding product serial", "warning")
                    flag = false
                }
                else if(isNaN(val))
                {
                    swal("Oops!", "Quantity has to be a number", "warning")
                    flag = false
                }
                else if(val<1)
                {
                    swal("Oops!", "Quantity can't be less than 1", "warning")
                    flag = false
                }

                if(flag)
                    return {...elem, selected_quantity: parseInt(val*100)/100, total_price: Math.round((parseInt(elem.selected_price*100)/100)*val * 100) / 100}
                else
                    return {...elem}    
            }            
            else
                return {...elem}
        })

        setProductCart(temp)
    };
      
    //function to handle price input
    const handlePriceChange = (pid,val) => {
        let temp = productCart.map((elem) => {
            if(elem.pid == pid)
            {
                let flag = true
                if(val<0)
                {
                    swal("Oops!", "Price can't be less than 0", "warning")
                    flag = false
                }
                else if(isNaN(val))
                {
                    swal("Oops!", "Price has to be a number", "warning")
                    flag = false
                }

                if(flag)
                {
                    return {...elem, selected_price: parseInt(val*100)/100 , total_price: Math.round((parseInt(val*100)/100)*elem.selected_quantity * 100) / 100}
                }
                else
                    return {...elem}    
            }            
            else
                return {...elem}
        })

        setProductCart(temp)
    };
    
    const handleDateChange = (val) => {
        if(val < moment.utc().format("YYYY-MM-DD"))
        {
            swal("Oops!", "Due Date cannot be earlier than today ", "warning")
        }
        else
        {
            setDue_date(val)
        }
    }

    const handleDiscountChange = (val) => {
        if(val>=0)
            setDiscountUnit(parseInt(val*100)/100)
        else
            swal("Oops!", "Discount can't be less than 0", "warning")
    };

    useEffect(()=>{
        if(discountType=="")
        {
            setDiscount(0)
            setDiscountUnit(0)
        }
        else
        {
            setDiscount(Math.round(((discountType=="Percentage")?(subTotal*discountUnit/100) : discountUnit) * 100)/100)
        }
    },[subTotal,discountType,discountUnit]) 

    useEffect(()=>{
        let temp = productCart.reduce((a, c) => a + c.total_price, 0)
        setSubTotal(Math.round(temp * 100)/100) 
    },[productCart])

    useEffect(()=>{
        let temp = productCart.reduce((a, c) => a + (c.total_price * ((c.taxable=='Yes')?c.tax:0) / 100) , 0)
        setTotalTax(Math.round(temp * 100)/100)
    },[productCart]) 

    useEffect(()=>{
        setGrandTotal(Math.round((subTotal + (taxing?totalTax:0) - discount) * 100)/100)
    },[subTotal,discount,totalTax,taxing])

    useEffect(()=>{
        if(pricing == false)
        {
            let temp = productCart.map((elem) => {return {...elem, selected_price: 0, total_price: 0} })
            setProductCart(temp)
            setTaxing(false) 
        }
    },[pricing])


    const finalSubmit = () => {
        //validations
        if(due_date == "")
        {
            swal("Oops!", "Due Date cannot be empty ", "warning")
        }
        else
        {
            let pdraft = productCart.map(a => {
                let t = {}
                t["pid"]=a.pid
                t["product_name"]=a.product_name
                t["quantity"]=a.selected_quantity
                t["unit_price"]=a.selected_price
                t["taxable"]=a.taxable
                t["tax_group_id"]=taxing? a.taxable=="Yes"?a.tax_group_id:"None" :"None"
                t["tax"]=taxing? a.taxable=="Yes"?a.tax:0 :0
                t["serial_numbers"] = a.serial_numbers.map(x => x.value).join()

                return t
            })
            // console.log(pdraft)
            
            let object = {}

            object.sid = props.match.params.id;
            object.user_id = user.email
            object.due_date = due_date
            object.billing_group_id = uuid()

            object.total_price = subTotal
            object.discount_type = discountType
            object.discount_value = discountUnit
            object.discounted_total_price = grandTotal
            object.total_tax_value = taxing?totalTax:0

            object.note = note
            object.product_array = pdraft
            object.page_name = page_name;

            // console.log(object)

            var headers = {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "*/*",
                    "token": getCookie("access_token")
                }
            }

            document.getElementById("addFinalSubmit").setAttribute("data-kt-indicator", "on");
            document.getElementById("addFinalSubmit").disabled = true;

            axios.post(`${process.env.REACT_APP_SERVER_URL}/add-reseller-product`, object, headers)
            .then((res) => {    

                document.getElementById("addFinalSubmit").setAttribute("data-kt-indicator", "off");
                document.getElementById("addFinalSubmit").disabled = false;

                if (res.data.operation == "success") {
                    swal("Great!", "Products Succesfully Assigned to Reseller", "success").then((res) => {
                        window.location.reload();
                    });
                }
                else if (res.data.operation == "Process error"){
                    swalr({ 
                        title: "Warning", 
                        icon: "warning",
                        content: <ul style={{height: "300px", overflow: "hidden", overflowY: "scroll"}} >{ res.data.err.map((elem) => {return <li style={{marginLeft: "50px", textAlign: "left"}} >{elem}</li> })}</ul>,
                        buttons: true,
                    })
                    .then(()=>{
                        window.location.reload();
                    })
                }
                else{
                    swal("Oops!", "something went wrong", "error").then((res) => {
                        window.location.reload();
                    });
                }
            })
            .catch((error) => {
                swal("Oops!", "something went wrong", "error").then((res) => {
                    window.location.reload();
                });
            });
        }
    }

    return (
        <>
            {
                (redirect == 1 ||(read==0 && write==0)) ? 
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> : null
            }
            {state == 1 ?
                <>

                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">

                                <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                                    <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Assign Products to Reseller :
                                        <span className="border-gray-200 border-start ms-10" style={{color : "purple"}}>{resellerName}</span>
                                        <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                    </h1>

                                </div>

                            </div>
                        </div>

                        <div id="tt-pageContent">
                            <div className="card">
                            
                                <div className="card-body p-lg-17">
                                    
                                    <div className="mb-0">
                                        <div className="row gx-10 mb-5">
                                            <div className="col-lg-6">
                                                <label className="form-label fs-6 fw-bolder text-gray-700 mb-3">Due Date:</label>			
                                                <input type="date" className="form-control" name="invoice_due_date" min={moment.utc().format("YYYY-MM-DD")} value={due_date} onChange={(e) => {handleDateChange(e.target.value)}} />
                                            </div>
                                        </div>		

                                        <div className="row gx-10 mb-5">
                                            <div className="col-lg-6">
                                                <label className="form-label fs-6 fw-bolder text-gray-700 mb-3">Select Products</label>
                                                <div className="mb-5"> 
                                                    <Select
                                                        styles={{
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                fontWeight: '500',
                                                                fontSize: 'larger'
                                                            }),
                                                            multiValue: (provided, state) => ({
                                                                ...provided,
                                                                fontWeight: '500',
                                                                fontSize: 'larger'
                                                            }),
                                                            placeholder: (provided, state) => ({
                                                                ...provided,
                                                                fontWeight: '500',
                                                                fontSize: 'larger'
                                                            })
                                                        }}
                                                        isMulti
                                                        options={productDropdown}
                                                        onChange={(val) => {typeof(val)=="object"?setProducts(val):setProducts(...products,...val)}}        
                                                        value={products}
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div className="col-lg-6">
                                                <button type="button" className="btn btn-primary btn-rounded mt-9" onClick={addToCart}> &#10011; Add items </button>  
                                            </div>
                                        </div>
                                
                                        {productCart.length>0 && 
                                        <div className="border-top border-top-dashed border-dark pt-3">
                                            <div className="row">
                                                <div className="col-sm-3 d-flex mx-3">
                                                    <label className="fs-5 fw-bold me-3">Pricing&nbsp;Details</label>
                                                    <div className="form-check form-check-solid form-switch">    
                                                        <input className="form-check-input" type="checkbox" checked={pricing} onChange={(e)=>{setPricing(e.target.checked)}} />
                                                    </div>
                                                </div>
                                                <div className={`col-sm-8 d-flex mx-3 ${!pricing? "d-none":""}`} >
                                                    <label className="fs-5 fw-bold me-3">Apply&nbsp;Tax</label>
                                                    <div className="form-check form-check-solid form-switch">    
                                                        <input className="form-check-input" type="checkbox" checked={taxing} onChange={(e)=>{setTaxing(e.target.checked)}} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-responsive mb-10">
                                                <table className="table g-5 gs-0 mb-0" data-kt-element="items">
                                                    <thead className="text-gray-700" >
                                                        <tr className="border-bottom fs-7 text-uppercase">
                                                            <th className="min-w-300px w-300px"><h5>Item</h5></th>
                                                            <th className="min-w-150px w-150px"><h5>Serial</h5></th>
                                                            <th className="min-w-150px w-150px"><h5>Quantity</h5></th>
                                                            {pricing && 
                                                                <>   
                                                                <th className="min-w-150px w-150px"><h5>Unit Price</h5></th>
                                                                <th className="min-w-150px w-150px"><h5>Total</h5></th>
                                                                </>
                                                            }
                                                            <th className="min-w-75px w-75px"><h5>Action</h5></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {productCart.map((obj,index) =>
                                                            {
                                                                return(   
                                                                    <React.Fragment key={index}>
                                                                    <tr data-kt-element="item" className="border-bottom border-bottom-dashed">
                                                                        <td className="pt-8 text-nowrap">

                                                                            {obj.product_name}
                                                                            <br/><br/>
                                                                            {obj.serial_button &&
                                                                            <>
                                                                                <Select 
                                                                                    isMulti 
                                                                                    options={obj.serial_list.map((e)=>{ return {label:e,value:e} })}  
                                                                                    onChange={(val) => { handleSerialChange(obj.pid,val,obj.selected_quantity) }}
                                                                                />
                                                                            </>
                                                                            }
                                                                            
                                                                        </td>

                                                                        <td>
                                                                            <div className="form-check form-check-solid form-switch fv-row">
                                                                                <input className="form-check-input w-45px h-30px mt-5" type="checkbox" onChange={() => { serialToggle(obj.pid) }} />
                                                                            </div>
                                                                        </td>

                                                                        <td className="pt-8 text-nowrap">  
                                                                            <input className="form-control form-control-solid" type="number" min="1" step=".25" value={obj.selected_quantity} onChange={(event) => { handleQuantityChange(obj.pid, parseFloat(event.target.value)) }}/>
                                                                        </td>

                                                                        {pricing && 
                                                                            <>
                                                                                <td className="pt-8 text-nowrap d-flex">
                                                                                    <i className="fas fa-exclamation-circle ms-2 fs-7 px-1 my-auto" title={`Previous price: ${obj.unit_price}`}></i>
                                                                                    <input className="form-control form-control-solid" type="number" min="0" step=".25" value={obj.selected_price} onChange={(event) => { handlePriceChange(obj.pid, parseFloat(event.target.value)) }}/>
                                                                                </td>

                                                                                <td className="pt-8 text-nowrap">
                                                                                    <input className="form-control form-control-solid" type="number" value={obj.total_price} readOnly/>
                                                                                </td> 
                                                                            </>
                                                                        }

                                                                        <td className="pt-8">
                                                                            <button type="button" className="btn btn-outline-dark py-1 rounded" title="Remove" onClick={() => { removeFromCart(obj.pid) }} >
                                                                                <span className="svg-icon svg-icon-1 m-auto p-auto">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="40px" height="40px" viewBox="-3 -3 30 30" version="1.1">
                                                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                            <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fillRule="nonzero" />
                                                                                            <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" />
                                                                                        </g>
                                                                                    </svg>
                                                                                </span>
                                                                            </button>
                                                                        </td>
            
                                                                    </tr>
                                                                    </React.Fragment>
                                                                );
                                                            }
                                                        )}
                                                    </tbody>

                                                    {pricing && 
                                                        <tfoot>
                                                            <tr className="border-top border-top-dashed border-primary align-top fs-6 fw-bolder text-gray-700">
                                                                <th colspan="2">
                                                                    <div className="d-flex flex-column align-items-end my-7">
                                                                        {taxing && <button className="btn btn-link pt-0" style={{paddingBottom: "1px"}} data-bs-toggle="modal" data-bs-target="#EditModal2" data-bs-trigger="hover" >View Taxing Details</button>}
                                                                        <button className="btn btn-link py-1 text-danger" onClick={()=>{setDiscountType("")}} >Remove Discount</button>
                                                                    </div>
                                                                </th>
                                                                <th colSpan="1" className="border-bottom border-bottom-dashed border-primary ps-0">
                                                                    <div className="d-flex flex-column align-items-start">
                                                                        <div className="fs-5">Subtotal</div>
                                                                        {taxing && <div className="fs-5">Total Tax</div>}
                                                                        <button className="btn btn-link py-1 text-success" data-bs-toggle="modal" data-bs-target="#EditModal" data-bs-trigger="hover" >Add discount</button>
                                                                    </div>
                                                                </th>
                                                                <th colSpan="3" className="border-bottom border-bottom-dashed border-primary">
                                                                    <div><span style={{marginRight: "0px", marginLeft: "160px"}}> </span><span>{currencyIcon} {subTotal}</span></div>
                                                                    {taxing && <div><span style={{marginRight: "20px", marginLeft: "131px"}}>+</span><span>{currencyIcon} {totalTax}</span></div>}
                                                                    <div><span style={{marginRight: "20px", marginLeft: "132px"}} >-</span><span>{currencyIcon} {discount}</span></div>
                                                                </th>
                                                            </tr>

                                                            <tr className="align-top fw-bolder text-gray-700">
                                                                <th colSpan="2"></th>
                                                                <th colSpan="1" className="fs-4 ps-0">Total</th>
                                                                <th colSpan="3" className="fs-4 text-nowrap"><span style={{marginRight: "0px", marginLeft: "160px"}}> </span><span>{currencyIcon} {grandTotal}</span></th>
                                                            </tr>
                                                        </tfoot>
                                                    }
                                                </table>

                                                <div className="mb-0">
                                                    <label className="form-label fs-6 fw-bolder text-gray-700">Notes</label>
                                                    <textarea name="notes" className="form-control form-control-solid" rows="3" placeholder="Thanks for your business" value={note} onChange={e=>setNote(e.target.value)} autoComplete="off"></textarea>
                                                </div>
                                                <br/><br/>
                                                <div className="row gx-10 mb-5">
                                                    <div className="col-lg-8"></div>
                                                    <div className="col-lg-3">
                                                        <button type="submit" id="addFinalSubmit" onClick={finalSubmit} className="btn btn-primary w-100">
                                                            <span className="indicator-label">
                                                                <span className="svg-icon svg-icon-3">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <path d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z" fill="black"></path>
                                                                        <path opacity="0.3" d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z" fill="black"></path>
                                                                    </svg>
                                                                </span>
                                                                Save details
                                                            </span>
                                                            <span className="indicator-progress">Please wait...
                                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        }
                                            
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                </>
                :
                <>
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div className="container" id="kt_content_container">
                                <div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
                                    <br />

                                    {
                                        state == 2 ?
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        :
                                            <>
                                                <div className="flex-lg-row-fluid me-lg-15">
                                                    <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                                        <div className="card-body pt-0">
                                                            <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                            <div className="d-flex align-items-center p-3 mb-2"></div>

                                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                                <div className="d-flex flex-stack flex-grow-1">
                                                                    <div className="fw-bold">
                                                                        <h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
                                                                        <div className="fs-6 text-gray-600">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </>
                                    }


                                    <br />
                                </div>
                            </div>
                        </div>

                    </div>
                </>
            }

            <div className="modal fade" id="EditModal" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div className="modal-content">

                        <form className="form" action="#" id="kt_modal_new_address_form">
                            <div className="modal-header" id="kt_modal_new_address_header">
                                <h2>Enter Discount Information</h2>
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>

                            <div className="modal-body py-10 px-lg-17">
                                <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                    <div className="d-flex flex-column mb-5 fv-row">
                                        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                            <span className="required">Discount type:</span>
                                        </label>
                                        <select
                                            name="discount_type"
                                            data-placeholder="Select a Country..."
                                            className="form-select form-select-solid"
                                            id="lead_source"
                                            value={discountType} 
                                            onChange={(e) => { setDiscountType(e.target.value); setDiscountUnit(0); }}
                                        >
                                            <option value="" disabled hidden>Choose Type</option>
                                            <option value="Amount">Amount</option>
                                            <option value="Percentage">Percentage</option>
                                        </select>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-7 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Discount value:</label>
                                            <div className="d-flex">
                                                <input type="number" className="form-control form-control-solid" min="0" value={discountUnit} onChange={(e)=>{ handleDiscountChange(discountType!=""?parseFloat(e.target.value):0) }} />
                                                {discountType=="Percentage" && <span className="my-auto">%</span>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

            <div className="modal fade" id="EditModal2" tabIndex="-1" aria-hidden="true">
                <div className="modal-lg modal-dialog modal-dialog-centered">
                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">
                            <h1 className="mx-auto">Taxing Details</h1>
                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                            </div>
                        </div>

                        <div className="modal-body py-10 px-10">
                            <div className="scroll-y me-n7 pe-7 " id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                
                                <div className="container">
                                    {
                                        productCart.filter(x => x.taxable == 'Yes').length>=1 ?
                                        <table className="table table-responsive">
                                            <thead>
                                                <tr className="row mb-2">
                                                    <th className="col-6"><h3>Product Name</h3></th>
                                                    <th className="col-6"><h3>Tax Applied</h3></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {   
                                                    productCart.map((elem, index)=>{
                                                        if(elem.taxable=='Yes')
                                                        { 
                                                            return (
                                                                <tr className="row" key={index}>
                                                                    <td className="col-6 pb-0" style={{fontSize:"larger"}}>{elem.product_name}</td>
                                                                    <td className="col-6 pb-0" style={{fontSize:"larger"}}>{elem.tax} %</td>
                                                                </tr>
                                                            )
                                                        }
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                        :
                                        <p className="text-gray-500 text-center display-6" style={{fontWeight: "initial"}}>No Taxable Items</p> 
                                    }
                                </div>

                            </div>
                        </div>

                        <div className="modal-footer">
                            <button className="btn btn-danger rounded" data-bs-dismiss="modal">Close</button>
                        </div>

                    </div>
                </div>
            </div>

            <div className="modal fade in" id="kt_modal_timer" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">
                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">
                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>
                        </div>

                        <div className="modal-body py-10 px-lg-17">
                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>
                        </div>

                        <div className="modal-footer flex-center">
                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
}
export default ResellerAssignProducts;