import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom'

import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import Select from 'react-select';

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';

import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import $ from 'jquery';
import Dropzone from 'react-dropzone-uploader';
// import 'react-dropzone-uploader/dist/styles.css'
import removeImg from './icons/remove.svg'
import uuid from 'react-uuid';
import {Form, Col,Button,InputGroup} from 'react-bootstrap';
import { Formik } from "formik";
import * as yup from "yup";
import { object } from 'yup/lib/locale';
import ReactTagInput from "@pathofdev/react-tag-input";
import { placeholder } from '@babel/types';
// import "@pathofdev/react-tag-input/build/index.css";

import country_arr from './countries-arr.js';
import s_a from './states-arr.js';



function UpdateProductBilling(props) {
    // const [loader, setLoader] = useState(0);

    const [user, setUser] = useState({});
    const [state, setstate] = useState(2);
    const [state1, setstate1] = useState(2);
    const [state2, setstate2] = useState(2);
    
    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);
    
    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    const [inputFields, setInputFields] = useState([]);
    const [filter_product, setfilterProduct] = useState([]);
    const [filter_product1, setfilterProduct1] = useState([]);
    const [apiState, setapiState] = useState("done");

    const [globalproduct, setGlobalProduct] = useState([]);

    const [pid, setpid] = useState("");

    const [total_price, settotal_price] = useState("");
    const [subtotal_price, setsubtotal_price] = useState("");

    // const [selectedOption, setSelectedOption] = useState([]);     
    // const [product, setProduct] = useState([]);
    // const [SerialNumber, setSerialNumber] = useState(false);
    // const [editmodal, setEditModal] = useState([]);
    // const [product_details, setproduct_details] = useState([]);
    // const [customerlist, setcustomerlist] = useState([]);

    const [billing_group_id, setbilling_group_id] = useState("");
    const [due_date, setdue_date] = useState("")

    const [cid, setcid] = useState("");

    const [customerName, setCustomerName] = useState("");

    const [discount_type, setdiscount_type] = useState("");
    const [discount_value, setdiscount_value] = useState("");
    const [discount_price, setdiscount_price] = useState("");

    const [tax, settax] = useState("");

    let [first_name, setfirst_name] = useState("");
	let [alternative_mobile, setalternative_mobile] = useState("");
	let [country, setcountry] = useState("");
	let [pin, setpin] = useState("");
    let [landmark, setlandmark] = useState("");
	let [address1, setaddress1] = useState("");
	let [address2, setaddress2] = useState("");
	let [city, setcity] = useState("");
	//let [state1, setstate1] = useState("");
	let [phone, setphone] = useState("");
	let [phn, setphn] = useState("");
	let [email, setemail] = useState("");
	let [date, setdate] = useState(new Date());
	let [country_array, set_country_array] = useState([]);
    const [customer_address, setcustomer_address] = useState([]);
	let [state_array, set_state_array] = useState([]);
    const [selected_customer_address, setselected_customer_address] = useState([]);
    const [updated_address, setupdated_address] = useState([]);
    const [state3, setstate3] = useState(0);

    var subtotal = 0;
    var page_name = "update-product-billing";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };
    
    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    function loadCountry(country) {

		setcountry(country.value);

		var selectedCountryIndex = 0;
		country_array.map((obj, index) => {
			if (obj.value == country.value) {
				selectedCountryIndex = index;
			}
		})
		var state_arr = s_a.s_a[selectedCountryIndex].split("|");
		var obj_array = [];
		var obj2 = {};
		obj_array.push({ value: "", label: "Select State" });
		for (let i = 0; i < state_arr.length; i++) {
			obj_array.push({ value: state_arr[i], label: state_arr[i] });
		}
		set_state_array(obj_array);
	}

    useEffect(() => {
		var obj_array = [];
		var obj2 = {};
		country_arr.country_arr.map((obj, index) => {
			obj2 = {};
			obj2.label = obj;
			if (index > 0) {
				obj2.value = obj;
			}
			else {
				obj2.value = "";
			}
			obj_array.push(obj2);
		})
		set_country_array(obj_array);
	}, []);

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });

                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
                        else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            //console.log(original_url)
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }
                            }
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            res.data.audience_count = (res.data.audience_count)
                            if (res.data.audience_count > 0) {
                                const timer_decrease = setInterval(() => {
                                    res.data.audience_count = (res.data.audience_count - 1);
                                    //console.log(res.data.audience_count);
                                    if (res.data.audience_count > 0 && res.data.audience_count < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                                        if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
                                            setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                                            window.$('#kt_modal_timer').modal('show');
                                        }
                                    }
                                }, 1000);

                                return () => clearInterval(timer_decrease);
                            }
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }

        validateJWT();

    }, [])
    
    useEffect(() => {
        async function getpurchasedetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/billing-product-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            console.log(body)

            const result1 = body.filter(obj => obj.add_status == "Active");
            const result3 = result1.filter(obj => obj.unique_id == props.match.params.id);
            const result4 = result3[0].product_list;        
            let address=[]
            address.push(JSON.parse(result3[0].shipping_address))
            setselected_customer_address(address);
            setdue_date(moment.utc(result3[0].due_date).add(1, 'days').format('YYYY-MM-DD'))
            setcid(result3[0].customer_id)
            setCustomerName(result3[0].email)
            setbilling_group_id(result3[0].billing_group_id)

            let total1
            if(result3[0].discount_type=="Amount")
            {
                result3[0].discount_value1=result3[0].discount_value;
                total1=result3[0].total_price-result3[0].discount_value;
                setstate2(0)
            }
            else if(result3[0].discount_type=="Percentage"){
                result3[0].discount_value1=(result3[0].discount_value*result3[0].total_price)/100;
                total1=result3[0].total_price-result3[0].discount_value;
                setstate2(0)
            }
            else
            {
                result3[0].discount_value1=0;
            }

            setdiscount_price(result3[0].discount_value1)
            setdiscount_type(result3[0].discount_type)
            setdiscount_value(result3[0].discount_value)


            for(let i=0;i<result4.length;i++)
            {
                result4[i].changes={};
                result4[i].count=result4[i].quantity;
                result4[i].count1=result4[i].quantity;
                result4[i].final_price=result4[i].unit_price;
                result4[i].total_price=parseInt(result4[i].quantity)*parseInt(result4[i].final_price)
                subtotal=subtotal+result4[i].total_price;
                if(result4[i].serial_numbers!="")
                {
                    result4[i].Serial_Number_checkbox=true;
                }
            }
            
            settotal_price(parseInt(subtotal))
            setsubtotal_price(parseInt(subtotal))
            setInputFields(result4)
            setstate1(3)
        }
        if (read || write) {
            user.email && visibility && getpurchasedetails();
        }

        // async function getcustomerdetails() {

        //     var object = {};
        //     object.email = user.email;

        //     const response = await fetch(
        //         `${process.env.REACT_APP_SERVER_URL}/customer-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        //     );
        //     const body = await response.json();
        //     console.log(body)

        //     let customer=[]
		// 	if(body.length>0)
        //     {
        //         for(let i=0;i<body.length;i++)
        //         {
        //             customer.push({label: body[i].email, value: body[i].cid})
        //         }
        //         setcustomerlist(customer)
        //     }
        // }
        // if(read || write)
        // {
        //     user.email && visibility && getcustomerdetails();
        // }

    }, [user, visibility, read, write])

    useEffect(() => {
		async function getcustomeraddress() {
            setapiState("processing")
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-customer-address-info`, { method: 'POST', body: JSON.stringify({"id":cid,"email": user.email,"page_name":page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();	
            //console.log(body)
			if(body.length>0){
                setapiState("done")
                setcustomer_address(body)        
            }
        }
        if(cid!="")
        {
            user.email && visibility && getcustomeraddress();
        }
	}, [cid]);

    useEffect(() => {

        async function getproductdetails() {

            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/product-sell-info`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            console.log("products",body)

            if (body.length > 0) {

                setGlobalProduct(body);              
                for(let i=0;i<body.length;i++)
                {
                    body[i].product_flag=0;
                    if(body[i].product_flag)
                    {
                        break;
                    }
                    for(let j=0;j<inputFields.length;j++)
                    {
                        if(body[i].product_name==inputFields[j].product_name)
                        {
                            body[i].product_flag=1;
                        }
                    }
                }

                const result4 = body.filter(obj => obj.product_flag == 0);
                for(let k=0;k<result4.length;k++)
                {
                    filter_product.push({label: result4[k].product_name, value: result4[k].pid})
                    filter_product1.push({label: result4[k].product_name, value: result4[k].pid})
                }

                const result3 = body.filter(obj => obj.product_flag == 1);
                for(let k=0;k<result3.length;k++)
                {
                    filter_product1.push({label: result3[k].product_name, value: result3[k].pid})
                }

                let tax1=0;
                for(let j=0;j<inputFields.length;j++)
                {
                    if(inputFields[j].Serial_Number_checkbox)
                    {
                        document.getElementById(`checkbox_${inputFields[j].product_billing_id}`).checked = "checked";
                    }

                    let serial=[];
                   
                    for(let k=0;k<result3.length;k++)
                    {
                        if(inputFields[j].product_name==result3[k].product_name)
                        {
                            if(inputFields[j].taxable=="Yes")
                            {
                                tax1=tax1+Math.round((parseInt(inputFields[j].tax*inputFields[j].count*inputFields[j].final_price*100)/100)/100);
                                inputFields[j].tax_value=Math.round((parseInt(inputFields[j].tax*inputFields[j].count*inputFields[j].final_price*100)/100)/100);
                            }

                            inputFields[j].quantity=result3[k].quantity;
                            for(let n=0;n<result3[k].serial_number.length;n++)
                            {
                                serial.push({value: result3[k].serial_number[n] ,label:result3[k].serial_number[n]});
                            }
                        }
                    }
                    inputFields[j].serial_number=serial;
                    inputFields[j].serial_numbers1=inputFields[j].serial_number
                    
                    inputFields[j].serial_numbers=inputFields[j].serial_numbers.split(",");
                    
                    let serial1=[];
                    let serial2=[];
                    if(inputFields[j].serial_numbers.length>1)
                    {
                        for(let m=0;m< inputFields[j].serial_numbers.length;m++)
                        {
                            serial2.push(inputFields[j].serial_numbers[m]);
                            serial1.push({value: inputFields[j].serial_numbers[m] ,label:inputFields[j].serial_numbers[m]});
                        }
                    }
                    else
                    {
                        if(inputFields[j].serial_numbers[0]!='')
                        {
                            serial2.push(inputFields[j].serial_numbers[0]);
                            serial1.push({value: inputFields[j].serial_numbers[0] ,label:inputFields[j].serial_numbers[0]});
                        }
                    
                    }
                    inputFields[j].serial_numbers=serial1;
                    inputFields[j].Selected_Serial_Number=serial2;
                    inputFields[j].Selected_Serial_Number1=serial2;
                }
                settax(tax1)
                setstate1(5)
                setstate(1)
            }
            else {
                setstate(0);
            }
        }
        if(read || write)
        {
            (state1==3 && inputFields.length>0 && user.email && visibility) && getproductdetails();
        }
	}, [user, visibility, read, write, inputFields, state1])


    function removeproduct(data) {
		const data1 = [...inputFields];
        const result4 = filter_product1.filter(obj => obj.value == data);
        filter_product.push(result4[0])
		if(data1.length > 0)
		{
			for(var i=0; i<data1.length; i++)
			{
                let tax1=0;
				if(data1[i].pid == data)
				{	if(data1[i].taxable=="Yes")
                    {
                        let tax1=tax-data1[i].tax_value;
                    }
                    settax(tax1)
                    let subtotal=total_price-data1[i].total_price;
                    settotal_price(subtotal)
                    setsubtotal_price(subtotal)
					data1.splice(i,1);
					break;
				}
			}
        }
        setInputFields(data1);
    }
    const add_cart_list = () => {
        const values1 = [...inputFields];
        if(pid!="Select Product")
        {
            const result3 = filter_product.filter(obj => obj.value != pid);
            const result2 = globalproduct.filter(obj => obj.pid == pid);

            document.getElementById("product").value="Select Product";

            setfilterProduct(result3)
            result2[0].count=1;
            result2[0].Serial_Count=0
            result2[0].changes={}; 
            result2[0].total_price=result2[0].final_price;
            let serial=[]
            for(let j=0;j<result2[0].serial_number.length;j++)
            {
                serial.push({value: result2[0].serial_number[j] ,label:result2[0].serial_number[j]});
            }
            let tax_value=0;
            if( result2[0].taxable=="Yes")
            {
                tax_value=tax+(result2[0].tax*result2[0].final_price)/100;
            }
            result2[0].tax_value=tax_value;
            result2[0].serial_number=serial;
            result2[0].Selected_Serial_Number=[];
            result2[0].Serial_Number_checkbox=false;
            values1.push(result2[0])
            setInputFields(values1)
            let subtotal=0;
            let tax_value1=0;
            for(let i=0;i<values1.length;i++)
            {
                subtotal=subtotal+values1[i].total_price;
                tax_value1=tax+values1[i].tax_value;
            }
            settax(tax_value1)
            settotal_price(subtotal)
            setsubtotal_price(subtotal)
        }
        setpid("Select Product")
    };

    const handleInputChange = (index,event) => {
        const values1 = [...inputFields];
        //console.log(values1)
        let tax_value1=0;
        if(values1[index].quantity<event.target.value.replace(/\D/,''))
        {
            swal("oops!", "Count can't exceed your stock limit", "warning")
        }
        else
        {
            if(values1[index].Serial_Count>event.target.value.replace(/\D/,''))
            {
                swal("oops!", "you can decrease your product count after removing product serial", "warning")
            }
            else if(event.target.value > (values1[index].quantity-values1[index].serial_number.length) + values1[index].Serial_Count)
            {
                swal("oops!", "you can increase your product count after adding product serial", "warning")
            }
            else if(event.target.value.replace(/\D/,'')==0)
            {
                swal("oops!", "Product quantity always should be greater than zero", "warning")
            }
            else{
                values1[index].count=parseInt(event.target.value.replace(/\D/,'')); 
                values1[index].total_price= (event.target.value.replace(/\D/,''))* parseInt(values1[index].final_price);
                values1[index].tax_value=Math.round((parseInt((event.target.value.replace(/\D/,''))* parseInt(values1[index].tax)*values1[index].final_price*100)/100)/100);
            }

            if(values1[index].count1!=event.target.value.replace(/\D/,''))
            {
                values1[index].changes.previous_count=parseInt(values1[index].count1)
                values1[index].changes.count=parseInt(event.target.value.replace(/\D/,''));
            }   
        }
        setInputFields(values1)
        let subtotal=0;
        for(let i=0;i<values1.length;i++)
        {
            subtotal=subtotal+values1[i].total_price;
            if(values1[i].taxable=="Yes")
            {
                tax_value1=tax_value1+values1[i].tax_value;
            }
        }
        settax(tax_value1);
        settotal_price(subtotal)
        setsubtotal_price(subtotal)
    };

    const check_serial_count = (e,total_count,index) => {
        const values1 = [...inputFields];
        
        if(e.map(x=>x.value).length>total_count)
        {
            swal("oops!", "Product serial number can't exceed your product count", "warning").then((value) => {
            });
        }
        else
        {
            values1[index].Selected_Serial_Number=e.map(x=>x.value);
            
            let arr1=values1[index].Selected_Serial_Number;
            let arr2=values1[index].Selected_Serial_Number1;
            if (JSON.stringify(arr1) !== JSON.stringify(arr2)) {
                values1[index].changes.previous_serial=arr2;
                values1[index].changes.serial=e.map(x=>x.value);
            } 
            
            let serial_numbers=[]
            for(let i=0;i<e.map(x=>x.value).length;i++)
            {
                serial_numbers.push({label:e.map(x=>x.value)[i],value:e.map(x=>x.value)[i]})
            }
            values1[index].serial_numbers =serial_numbers;
            values1[index].Serial_Count=e.map(x=>x.value).length;
            setInputFields(values1)
            if(values1[index].count > (values1[index].quantity-values1[index].serial_number.length) + values1[index].Serial_Count)
            {
                values1[index].count = (values1[index].quantity-values1[index].serial_number.length) + values1[index].Serial_Count
            }
        }
    }

    const Serial_Number_checkbox = (event,index) => {
        const values1 = [...inputFields];
        values1[index].Serial_Number_checkbox=event.target.checked;
        if(event.target.checked==false)
        {
            if(values1[index].count>values1[index].quantity-values1[index].serial_number.length){
                values1[index].count = values1[index].quantity-values1[index].serial_number.length
            }
            values1[index].Selected_Serial_Number=[]
            values1[index].Serial_Count = 0
        }
        setInputFields(values1)
    }

    const onsubmit = (e) => {

        var values={};
        values.billing_product = inputFields;
        values.page_name=page_name;
        values.billing_group_id=billing_group_id;
        values.unique_id=props.match.params.id;
        values.customer_id=cid;
        values.due_date=due_date;
        values.subtotal_price=subtotal_price;
        values.total_price=(subtotal_price-discount_price+tax);
        values.discount_type=discount_type;
        values.discount_value=discount_value;
        values.tax_value=tax;
        values.shipping_address=selected_customer_address[0];

        console.log(values)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
       
         axios.post(`${process.env.REACT_APP_SERVER_URL}/update-product-billing`,values, headers).then((res) => {
             if(res.data && res.data.operation == "success")
             {
                swal("Great!", "Billing details updated successfully!", "success").then((value) => {
                     window.location.reload();
                 });
             }
            else{
                swal("Oops!", "Something went wrong!", "error");
            }
        }); 
    }

    const submitDiscountdetails=(e)=>{
        let total=0;
        if(discount_type=="Percentage")
        {
            total=(total_price*discount_value)/100;
        }
        else{
            total=discount_value;
        }
        setdiscount_price(total)
        setstate2(1)
    }

    const removediscount=()=>{
        setdiscount_value("")
        setdiscount_type("")
        setdiscount_price("")
        setstate2(2)
    }
    const  select_address=(index)=>{
        let address=[];
        address.push(customer_address[index])
        setselected_customer_address(address);
    }
    const  update_address=(i)=>{
        //address.push(customer_address[index])
        setcountry(customer_address[i].country)
       var selectedCountryIndex = 0;
				country_array.map((obj, index) => {
                    
					if (obj.value == customer_address[i].country) {
						selectedCountryIndex = index;
					}
				})

				var state_arr = s_a.s_a[selectedCountryIndex].split("|");

				var obj_array = [];
				var obj2 = {};
				state_arr.map((obj, index) => {
					obj2 = {};
					obj2.label = obj;
					if (index > 0) {
						obj2.value = obj;
					}
					else {
						obj2.value = "";
					}
					obj_array.push(obj2);
				})
				set_state_array(obj_array);
        
        setfirst_name(customer_address[i].full_name);
        setalternative_mobile(customer_address[i].alternate_mobile_address)
        setphn(customer_address[i].address_mobile)
        setstate1(customer_address[i].state)
        setpin(customer_address[i].pincode)
        setlandmark(customer_address[i].landmark)
        setcity(customer_address[i].city)
        setaddress1(customer_address[i].address_line1)
        setaddress2(customer_address[i].address_line2)
        document.getElementById("id1").value = customer_address[i].id;
        setstate3(1)
    }
    const  new_address=()=>{
        //address.push(customer_address[index])
        setcountry("")
        setfirst_name("");
        setalternative_mobile("")
        setphn("")
        setstate1("")
        setpin("")
        setlandmark("")
        setcity("")
        setaddress1("")
        setaddress2("");
        document.getElementById("id1").value ="";
        setstate3(0)
    }
    const update_customer_address = () => {
		var id = document.getElementById("id1").value; 
        var landmark = document.getElementById("landmark").value;
        //var delivery_instruction = document.getElementById("delivery_instruction").value;
		var first_name = document.getElementById("first_name").value;

		if (first_name == "") {
			document.getElementById('firstname').innerHTML = "Please fill the first name";
			return false;
		}
		else if (!isNaN(first_name)) {
			document.getElementById('firstname').innerHTML = "only charecters are allowed";
			return false;
		}
		else {
			document.getElementById('firstname').innerHTML = " ";
		}
		var alternative_mobile = document.getElementById("alternative_mobile").value;
		
		//var country = document.getElementById("country").value;
		//var country = country;
		if (country == "") {
			document.getElementById('country1').innerHTML = "Please select country";
			return false;
		}
		else {
			document.getElementById('country1').innerHTML = "";
		}
		//var state1 = document.getElementById("state").value;
		//console.log(state1);
		//var state1 =state1;
		if (state1 == "") {
			document.getElementById('state2').innerHTML = "Please select state";
			return false;
		}
		else {
			document.getElementById('state2').innerHTML = " ";
		}
		var pin = document.getElementById("pin").value;
		if (pin == "") {
			document.getElementById('pin1').innerHTML = "Please fill the Zip Code";
			return false;
		}
		else if (isNaN(pin)) {
			document.getElementById('pin1').innerHTML = "User must write digits only not charecters";
			return false;
		}
		else {
			document.getElementById('pin1').innerHTML = " ";
		}
		var city = document.getElementById("city").value;
		if (city == "") {
			document.getElementById('town').innerHTML = "Please fill the town";
			return false;
		}
		else {
			document.getElementById('town').innerHTML = " ";
		}
		var phn = document.getElementById("phn").value;
		var regexp = /^([0|\+[0-9]{1,5})?([0-9]{1,15})$/
		if (phn == "") {
			document.getElementById('phn1').innerHTML = "Please fill the phone number";
			return false;
		}
		else if (!regexp.test(phn)) {
			document.getElementById('phn1').innerHTML = "Please enter valid phone number";
			return false;
		}
		else {
			document.getElementById('phn1').innerHTML = " ";
		}
        	
        var address1 = document.getElementById("address1").value;
		if (address1 == "") {
			document.getElementById('address11').innerHTML = "Please fill the Address Line1";
			return false;
		}
		else {
			document.getElementById('address11').innerHTML = " ";
		}
		var address2 = document.getElementById("address2").value;
		
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
        if(id=="")
        {
            var values = { "id": cid, "full_name": first_name,"landmark":landmark,"address1":address1,"address2":address2,"city": city, "alternative_mobile": alternative_mobile, "country": country, "phone": phn, "email": user.email,"pin": pin, "state": state1, "page_name": page_name };

            axios.post(`${process.env.REACT_APP_SERVER_URL}/save-customer-address`, values, headers).then((res) => {
                if (res.data && res.data.operation == "success") {
                    swal("Great!", "Address saved successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });
        }
        else
        {
            var values = { "id":id,"cid": cid, "full_name": first_name,"landmark":landmark,"address1":address1,"address2":address2,"city": city, "alternative_mobile": alternative_mobile, "country": country, "phone": phn, "email": user.email,"pin": pin, "state": state1, "page_name": page_name };

            axios.post(`${process.env.REACT_APP_SERVER_URL}/update-customer-address`, values, headers).then((res) => {
                if (res.data && res.data.operation == "success") {
                    swal("Great!", "Address updated successfully!", "success").then((value) => {
                        window.location.reload();
                    });
                }
                else {
                    swal("Oops!", "Something went wrong!", "error");
                }
            });

        }
	}

    return (
        <>
            {
                (redirect == 1 ||(read==0 && write==0)) ? 
                <>
                    <Redirect to={{
                        pathname: "/unauthorized",
                    }}
                    />
                </> :
                 null
            }
            {state == 1 ?
                <>

                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">

                                <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                                    <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Product Billing Information
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                        <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                    </h1>

                                </div>

                            </div>
                        </div>

                        <div id="tt-pageContent">
                        <div className="card mb-5 mb-xl-10">
								
                                <div className="card-header">
                                    
                                    <div className="card-title">
                                        <h3>Billing Address</h3>
                                    </div>
                                    
                                </div>
                                
                                <div className="card-body">
                                    
                                <div className="row gx-10 mb-5">
                                        
                                       
                                        <div className="col-lg-5">
                                            <label className="form-label fs-6 fw-bolder text-gray-700 mb-3">Select Customer</label>
                                        
                                            <h3 className="mt-2 ms-10">{customerName}</h3>               
                                            
                                        </div>
                                        
                                        
                                        {selected_customer_address.length>0 &&
                                        <>
                                         <div className="col-xl-6">
                                            <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">
                                                <div className="d-flex flex-column py-2">
                                                    <div className="d-flex align-items-center fs-5 fw-bolder mb-5">Selected Address
                                                    {selected_customer_address[0].address_type=="Billing" &&<span className="badge badge-light-success fs-7 ms-2">Primary</span>}</div>
                                                    <div className="fs-6 fw-bold text-gray-600">{selected_customer_address[0].city},{selected_customer_address[0].address_line1},{selected_customer_address[0].landmark}
                                                    <br/>{selected_customer_address[0].state},{selected_customer_address[0].pincode}
                                                    <br/>{selected_customer_address[0].country}</div>
                                                </div>
                                                
                                                <div className="d-flex align-items-center py-2">
                                                
                                                    <button className="btn btn-sm btn-light btn-active-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_all_address">Edit</button>
                                                </div>
                                            </div>
                                        </div>
                                         </>
                                        }     
                                       
                                        <div className="col-lg-4">
                                        <label className="form-label fs-6 fw-bolder text-gray-700 mb-3">Due Date:</label>			
                                                <input className="form-control" name="invoice_due_date" type="date" min={moment.utc().format("YYYY-MM-DD")} value={due_date} 
                                                    onChange={(e) => {
                                                        if(e.target.value<moment.utc().format("YYYY-MM-DD")){
                                                            swal("oops","Due date cannot be earlier than today","warning")
                                                        }
                                                        else{
                                                            setdue_date(e.target.value); 
                                                        }
                                                    }}
                                                />			
                                       </div>
                                    
                                    </div>	
                                    
                                </div>

                                
                            </div>
                            <div className="card">
                            <div className="card-header">
                                    
                                    <div className="card-title">
                                        <h3>
                                        Billing Items </h3>
                                    </div>
                                    
                                </div>
                                <div className="card-body p-lg-17">
                                    <div className="mb-0">

                                        <div className="row gx-10 mb-5">
                                            {filter_product &&
                                                <div className="col-lg-6">
                                                    <label className="form-label fs-6 fw-bolder text-gray-700 mb-3">Select Product</label>
                                                    <div className="mb-5"> 
                                                        <Select
                                                            styles={{
                                                                option: (provided, state) => ({
                                                                    ...provided,
                                                                    fontWeight: '500',
                                                                    fontSize: 'larger'
                                                                }),
                                                                multiValue: (provided, state) => ({
                                                                    ...provided,
                                                                    fontWeight: '500',
                                                                    fontSize: 'larger'
                                                                }),
                                                                placeholder: (provided, state) => ({
                                                                    ...provided,
                                                                    fontWeight: '500',
                                                                    fontSize: 'larger'
                                                                })
                                                            }}
                                                            name="product"
                                                            id="product"
                                                            options={filter_product}
                                                            onChange={(event) => { setpid(event.value) }}
                                                            value={ filter_product.filter(option => option.value == pid).length != 0 && filter_product.filter(option => option.value == pid)[0]}   
                                                            placeholder="Select Product..."
                                                        />                                         
                                                    </div>
                                                </div>
                                            }
                                            
                                            <div className="col-lg-6 mt-9">
                                                <button type="button" className="btn btn-primary btn-rounded" onClick={(event) => { add_cart_list();}}>&#10011; Add item</button>  
                                            </div>
                                        
                                        </div>

                                        {(inputFields.length>0 && state==1) && 
                                        <>
                                        <div className="table-responsive mb-10">
                                            <table className="table g-5 gs-0 mb-0 fw-bolder text-gray-700" data-kt-element="items">

                                                <thead>
                                                    <tr className="border-bottom fs-7 fw-bolder text-gray-700 text-uppercase">
                                                        <th className="min-w-300px w-475px">Item</th>
                                                        <th className="min-w-100px w-100px">Serial Number</th>
                                                        <th className="min-w-100px w-100px">QTY</th>
                                                        <th className="min-w-150px w-100px">Unit Price</th>
                                                        <th className="min-w-100px w-150px">Total</th>
                                                        <th className="min-w-75px w-75px">Action</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                {
                                                    inputFields.map((obj,index) =>{
                                                        return(
                                                            <>
                                                                <tr data-kt-element="item" className="border-bottom border-bottom-dashed">
                                                                    <td className="pt-8 text-nowrap">
                                                                        {obj.product_name}
                                                                        {obj.taxable=="Yes" && <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title={`tax-${obj.tax}%`}></i>}
                                                                        <br/><br/><br/>
                                                                        {(obj.Serial_Number_checkbox && state1==5) &&
                                                                            <>
                                                                                <Select 
                                                                                    styles={{
                                                                                        option: (provided, state) => ({
                                                                                            ...provided,
                                                                                            fontWeight: '500',
                                                                                            fontSize: 'larger'
                                                                                        }),
                                                                                        multiValue: (provided, state) => ({
                                                                                            ...provided,
                                                                                            fontWeight: '500',
                                                                                            fontSize: 'larger'
                                                                                        }),
                                                                                        placeholder: (provided, state) => ({
                                                                                            ...provided,
                                                                                            fontWeight: '500',
                                                                                            fontSize: 'larger'
                                                                                        })
                                                                                    }} 
                                                                                    isMulti 
                                                                                    options={(parseInt(obj.Selected_Serial_Number.length) == parseInt(obj.count)) ? []: obj.serial_number}
                                                                                    value={obj.serial_numbers}  
                                                                                    onChange={(e) => {check_serial_count(e,obj.count,index); }}
                                                                                />
                                                                            </>
                                                                        } 
                                                                    </td>

                                                                    <td className="pt-8 text-nowrap">
                                                                        <span className="kt-switch kt-switch--success">
                                                                            <label>
                                                                                <div className="col-lg-8 d-flex align-items-center">
                                                                                    <div className="form-check form-check-solid form-switch fv-row">
                                                                                        <input className="form-check-input w-45px h-30px" type="checkbox" id="serial_nuber_entry" name="serial_nuber_entry" id={`checkbox_${obj.product_billing_id}`} value="No" onChange={(event) => { Serial_Number_checkbox(event,index); }} />
                                                                                    </div>
                                                                                </div>
                                                                            </label>
                                                                        </span>  
                                                                    </td>

                                                                    <td className="ps-0">
                                                                        <input className="form-control form-control-solid" type="number" min="1" name="quantity" value={obj.count} onChange={(event) => { handleInputChange(index,event);}}/>
                                                                    </td>

                                                                    <td className="pt-8 text-nowrap">
                                                                        <span data-kt-element="total">{obj.final_price}</span>
                                                                    </td>

                                                                    <td className="pt-8 text-nowrap">
                                                                        <span data-kt-element="total"></span>{obj.total_price}
                                                                    </td>

                                                                    <td className="pt-5 text-end">
                                                                        <button type="button" className="btn btn-sm btn-icon btn-active-color-primary" onClick={(event) => {removeproduct(obj.pid);}} data-kt-element="remove-item">

                                                                            <span className="svg-icon svg-icon-3">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                        <rect x="0" y="0" width="24" height="24" />
                                                                                        <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero" />
                                                                                        <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3" />
                                                                                    </g>
                                                                                </svg>
                                                                            </span>

                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        );
                                                    })
                                                }
                                                </tbody>

                                                <tfoot>
                                                    <tr className="border-top border-top-dashed align-top fs-6 fw-bolder text-gray-700">
                                                        <th className="text-primary">
                                                            <button className="btn btn-link py-1" data-kt-element="add-item"></button>
                                                        </th>

                                                        <th colspan="2" className="border-bottom border-bottom-dashed ps-0">
                                                            <div className="d-flex flex-column align-items-start">
                                                                <div className="fs-5">Subtotal</div>
                                                                {tax!="" ?<button className="btn btn-link py-1" data-bs-toggle="modal" data-bs-target="#ViewDetails">View tax details</button>:<button className="btn btn-link py-1">View tax details</button>}
                                                                <button className="btn btn-link py-1" data-bs-toggle="modal" data-bs-target="#EditModal" data-bs-trigger="hover" title="Coming soon">Add discount</button>
                                                            </div>
                                                        </th>

                                                        <th colspan="2" className="border-bottom border-bottom-dashed text-end">$
                                                            <span style={{marginRight: "75px"}}>{subtotal_price}</span><br/>
                                                            +&emsp;$<span style={{marginRight: "75px"}}>{tax}</span><br/>
                                                            {state2!==2 &&<span style={{marginRight: "75px"}}>-&emsp;${discount_price}</span>}
                                                        </th>

                                                        <th colspan="2" className="border-bottom border-bottom-dashed ps-0">
                                                            <div className="d-flex flex-column align-items-end">
                                                                <div className="fs-5"></div>
                                                                <button className="btn btn-link py-1" data-bs-toggle="modal" data-bs-target="#ViewDetails"></button><br/>
                                                                {state2!==2 && 
                                                                    <button type="button" className="btn btn-sm btn-icon btn-active-color-primary" title="Remove discount" onClick={(event) => {removediscount();}} data-kt-element="remove-item">
                                                                        <span className="svg-icon svg-icon-3">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                                    <path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fill-rule="nonzero" />
                                                                                    <path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3" />
                                                                                </g>
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                }
                                                            </div>
                                                        </th>
                                                    </tr>
                                                    <tr className="align-top fw-bolder text-gray-700">
                                                        <th></th>
                                                        <th colspan="2" className="fs-4 ps-0">Total</th>
                                                        <th colspan="2" className="text-end fs-4 text-nowrap">$
                                                        <span style={{marginRight: "75px"}}>{subtotal_price-discount_price+tax}</span></th>
                                                    </tr>
                                                </tfoot>

                                            </table>
                                                <div className="mb-0">
                                                    <label className="form-label fs-6 fw-bolder text-gray-700">Notes</label>
                                                    <textarea name="notes" className="form-control form-control-solid" rows="3" placeholder="Thanks for your business"></textarea>
                                                </div>
                                                <br/><br/>
                                                <div className="row gx-10 mb-5">
                                                    <div className="col-lg-8"></div>
                                                    <div className="col-lg-3">
                                                        <button type="submit"  onClick={(e) => { onsubmit(e) }} className="btn btn-primary w-100" id="kt_invoice_submit_button">
                                                            <span className="svg-icon svg-icon-3">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z" fill="black"></path>
                                                                    <path opacity="0.3" d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648Z" fill="black"></path>
                                                                </svg>
                                                            </span>
                                                            Save details
                                                        </button>
                                                    </div>
                                                </div>  
                                            </div>
                                        </>
                                        }
                                            
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </>
                :
                <>
                    <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                        <div className="toolbar" id="kt_toolbar">
                            <div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
                                <div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
                                    <h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Product Billing Information
                                        <span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
                                        <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                                    </h1>

                                </div>

                            </div>
                        </div>

                        <div className="post d-flex flex-column-fluid" id="kt_post">
                            <div className="container" id="kt_content_container">
                                <div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
                                    <br />

                                    {state == 2 ?
                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        :
                                        <>

                                            <div className="flex-lg-row-fluid me-lg-15">
                                                <div className="card card-flush pt-3 mb-5 mb-lg-10">

                                                    <div className="card-header">
                                                        <div className="card-title">
                                                            <h2 className="fw-bolder">Product Stock Information</h2>
                                                        </div>
                                                    </div>


                                                    <div className="card-body pt-0">
                                                        <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                        <div className="d-flex align-items-center p-3 mb-2">


                                                        </div>


                                                        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                            <div className="d-flex flex-stack flex-grow-1">
                                                                <div className="fw-bold">
                                                                    <h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
                                                                    <div className="fs-6 text-gray-600">
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </>
                                    }


                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            }
            <div className="modal fade" id="kt_modal_all_address" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-850px">

                    <div className="modal-content">

                            <div className="modal-header" id="kt_modal_new_address_header">

                                <h2>Add New Address</h2>

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>

                            <div className="modal-body py-10 px-lg-17">

                                <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                    <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">

                                        <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                                <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                                <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                            </svg>
                                        </span>


                                        <div className="d-flex flex-stack flex-grow-1">

                                            <div className="fw-bold">
                                                <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                                <div className="fs-6 text-gray-600">Updating address may affect to your
                                                    <a > Currency</a></div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="row gx-9 gy-6">
                                        {apiState!="done"?<div className="col-xl-6 my-auto text-center"><div className="spinner-grow text-info" style={{width: "50px", height: "50px"}}></div></div>
                                        :
                                        <>
                                        {customer_address.map((obj,index) =>
                                            {
                                                return(        
                                            <>
                                            <div className="col-xl-6">
                                                <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">
                                                    <div className="d-flex flex-column py-2">
                                                        <div className="d-flex align-items-center fs-5 fw-bolder mb-5">Address {index+1}
                                                        {obj.address_type=="Billing" &&<span className="badge badge-light-success fs-7 ms-2">Primary</span>}</div>
                                                        <div className="fs-6 fw-bold text-gray-600">{obj.city},{obj.address_line1},{obj.landmark}
                                                        <br/>{obj.state},{obj.pincode}
                                                        <br/>{obj.country}</div>
                                                    </div>
                                                    
                                                    <div className="d-flex align-items-center py-2">
                                                    <button type="reset" data-bs-dismiss="modal" onClick={(event) => { select_address(index);}} className="btn btn-sm btn-primary">Select</button>
                                                    <button onClick={(event) => { update_address(index);}} className="btn btn-sm btn-light btn-active-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_new_address">Edit</button>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            </>
                                            );
                                        }
                                    )}
                                    </>}
                                        {customer_address.length<=4 &&             
                                            <div className="col-xl-6">
                                                
                                                <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed flex-stack h-xl-100 mb-10 p-6">
                                                
                                                    <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                                        
                                                        <div className="mb-3 mb-md-0 fw-bold">
                                                        <div className="mb-3 mb-md-0 fw-bold">
                                                            <h4 className="text-gray-800 fw-bolder">This is a very important note!</h4>
                                                            <div className="fs-6 text-gray-600 pe-7">Please add Billing address for purchasing products</div>
                                                        </div>
                                                        </div>
                                                        
                                                        <a href="#" onClick={(event) => { new_address();}} className="btn btn-primary px-6 align-self-center text-nowrap" data-bs-toggle="modal" data-bs-target="#kt_modal_new_address">New Address</a>
                                                        
                                                    </div>
                                                    
                                                </div>
                                                
                                            </div>
                                        }
                                        
                                        </div>

                                </div>

                            </div>

                            <div className="modal-footer flex-center">

                                <button type="reset" id="kt_modal_new_address_cancel" data-bs-dismiss="modal" className="btn btn-white me-3">Discard</button>

                            </div>

                    </div>
                </div>
            </div>
            <div className="modal fade" id="kt_modal_new_address" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-850px">

                    <div className="modal-content">

                        <form className="form" action="#" id="kt_modal_new_address_form">

                            <div className="modal-header" id="kt_modal_new_address_header">

                                <h2>Add New Address</h2>

                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>

                                </div>

                            </div>

                            <div className="modal-body py-10 px-lg-17">

                                <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                    <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">

                                        <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                                <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                                <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                            </svg>
                                        </span>


                                        <div className="d-flex flex-stack flex-grow-1">

                                            <div className="fw-bold">
                                                <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                                <div className="fs-6 text-gray-600">Updating address may affect to your
                                                    <a > Currency</a></div>
                                            </div>

                                        </div>

                                    </div>



                                    <div className="row mb-5">

                                        <div className="col-md-6 fv-row">

                                            <label className="required fs-5 fw-bold mb-2"> Full name</label>


                                            <input type="text" className="form-control form-control-solid" placeholder="" name="first-name" id="first_name" value={first_name} onChange={(e) => { setfirst_name(e.target.value) }} />
                                            <span id="firstname" className="text-danger font-weight-bold"></span>

                                        </div>


                                        <div className="col-md-6 fv-row">

                                            <label className="fs-5 fw-bold mb-2">Alternate Mobile</label>


                                            <input type="text" className="form-control form-control-solid" placeholder="" name="last-name" id="alternative_mobile" value={alternative_mobile} onChange={(e) => { setalternative_mobile(e.target.value) }} />
                                            <span id="lastname" className="text-danger font-weight-bold"></span>

                                        </div>

                                    </div>
                                    <div className="row g-9 mb-5">

                                        <div className="col-md-6 fv-row">

                                            <label className="required fs-5 fw-bold mb-2">phone number</label>

                                            <input className="form-control form-control-solid" placeholder="" id="phn" name="phn" value={phn} onChange={(e) => { setphn(e.target.value) }} />
                                            <span id="phn1" className="text-danger font-weight-bold"></span>

                                        </div>


                                        <div className="col-md-6 fv-row">

                                            <label className="required fs-5 fw-bold mb-2">Email</label>



                                            <input className="form-control form-control-solid" placeholder="" name="email" id="email" value={user.email} readonly />
                                            <span id="email1" className="text-danger font-weight-bold"></span>

                                        </div>

                                    </div>

                                    <div className="d-flex flex-column mb-5 fv-row">

										<label className="required fs-5 fw-bold mb-2">Address Line 1</label>


										<input className="form-control form-control-solid" placeholder="" name="address1" id="address1" value={address1} onChange={(e) => { setaddress1(e.target.value) }} />
										<span id="address11" className="text-danger font-weight-bold"></span>
									</div>


									<div className="d-flex flex-column mb-5 fv-row">

										<label className="fs-5 fw-bold mb-2">Address Line 2</label>


										<input className="form-control form-control-solid" placeholder="" name="address2" id="address2" value={address2} onChange={(e) => { setaddress2(e.target.value) }} />
										<span id="address21" className="text-danger font-weight-bold"></span>
									</div>
                                    <div className="d-flex flex-column mb-5 fv-row">

                                        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                            <span className="required">Country</span>
                                            <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Your payment statements may very based on selected position"></i>
                                        </label>

                                        {
                                            country_array.length > 0 &&
                                            <Select
                                                name="country"
                                                id="country"
                                                options={country_array}
                                                value={
                                                    country_array.filter(option =>
                                                        option.value == country)[0]
                                                }
                                                onChange={(e) => { setcountry(e.value); setstate1(""); loadCountry(e); }}
                                            />
                                        }
                                        <span id="country1" className="text-danger font-weight-bold"></span>
                                        </div>
                                        <div className="d-flex flex-column mb-5 fv-row">

                                        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                            <span className="required">State</span>
                                            {/* <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Your payment statements may very based on selected position"></i> */}
                                        </label>
                                        {
                                            state_array.length > 0 &&
                                            <Select
                                                name="state"
                                                id="state"
                                                options={state_array}
                                                onChange={(e) => { setstate1(e.value); }}
                                                value={
                                                    state_array.filter(option =>
                                                        option.value == state1)[0]
                                                }
                                            />
                                        }
                                        <span id="state2" className="text-danger font-weight-bold"></span>
                                        </div>
                                    <div className="row g-9 mb-5">

                                        <div className="col-md-6 fv-row">

                                            <label className="required fs-5 fw-bold mb-2">Town</label>


                                            <input className="form-control form-control-solid" placeholder="" name="city" id="city" value={city} onChange={(e) => { setcity(e.target.value) }} />
                                            <span id="town" className="text-danger font-weight-bold"></span>
                                        </div>
                                        <div className="col-md-6 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Zip code</label>
                                            <input className="form-control form-control-solid" placeholder="" name="postcode" id="pin" value={pin} onChange={(e) => { setpin(e.target.value) }} />
                                            <span id="pin1" className="text-danger font-weight-bold"></span>
                                        </div>

                                    </div><div className="row mb-5">
                                        <div className="col-md-6 fv-row">
                                            <label className="fs-5 fw-bold mb-2">Landmark</label>
                                            <input id="landmark" type="text" className="form-control form-control-solid" placeholder="" name="landmark"  value={landmark} onChange={(e) => { setlandmark(e.target.value) }}/>
                                        </div>
                                    </div>



                                    <div className="fv-row mb-5">

                                    </div>

                                </div>

                            </div>

                            <div className="modal-footer flex-center">

                                <button type="reset" id="kt_modal_new_address_cancel" data-bs-dismiss="modal" className="btn btn-white me-3">Discard</button>
                                <input type="hidden" id="id1" name="activeId" />
                                <button type="button" onClick={update_customer_address} className="btn btn-primary">
                                    <span className="indicator-label">Submit</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>

                            </div>

                        </form>

                    </div>
                </div>
            </div>
            <div className="modal fade" id="ViewDetails" tabindex="-1" aria-hidden="true">
									
                <div className="modal-dialog mw-650px">
                    
                    <div className="modal-content">
                        
                        <div className="modal-header pb-0 border-0 justify-content-end">
                            
                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                            
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                                
                            </div>
                            
                        </div>
                        
                        <div className="modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15">
                            
                            <div className="text-center mb-13">
                                
                                <h1 className="mb-3">Tax Details</h1>
                                
                                <div className="text-gray-400 fw-bold fs-5"></div>
                                
                            </div>
                            {inputFields.map((obj,index) =>
                                    {
                                        return(
                                            
                                        <>
                                        {obj.taxable=="Yes"&&
                                            <div class="d-flex align-items-center bg-light-success rounded p-5 mb-7">
                                                            
                                                <span class="svg-icon svg-icon-success me-5">
                                                
                                                    <span class="svg-icon svg-icon-1">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                            <path opacity="0.3" d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z" fill="black"></path>
                                                            <path d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z" fill="black"></path>
                                                        </svg>
                                                    </span>
                                                    
                                                </span>
                                                
                                                <div class="flex-grow-1 me-2">
                                                    <a href="#" class="fw-bolder text-gray-800 text-hover-primary fs-6"> {obj.product_name} </a>
                                                    <span class="text-muted fw-bold d-block"></span>
                                                </div>
                                                
                                                <span class="fw-bolder text-success py-1">+{obj.tax}%</span>
                                                
                                            </div>
                                        }
                                            </>
                                                );
                                            }
                                        )} 
                            
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                    
                </div>
                
            </div>
            <div className="modal fade" id="EditModal" tabindex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    <div className="modal-content">
                        <form className="form" action="#" id="kt_modal_new_address_form">
                            <div className="modal-header" id="kt_modal_new_address_header">
                                <h2>Enter Discount Information</h2>
                                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                                    <span className="svg-icon svg-icon-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                                <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                                <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div className="modal-body py-10 px-lg-17">
                                <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                                    <div className="d-flex flex-column mb-5 fv-row">
                                        <label className="d-flex align-items-center fs-5 fw-bold mb-2">
                                            <span className="required">Discount type:</span>
                                        </label>
                                        <select
                                            name="discount_type"
                                            data-placeholder="Select a Country..."
                                            className="form-select form-select-solid"
                                            id="lead_source"
                                            value={discount_type} 
                                            onChange={(e) => { setdiscount_type(e.target.value) }}
                                           
                                        >
                                            <option value="">Select</option>
                                            <option value="Amount">Amount</option>
                                            <option value="Percentage">Percentage</option>
                                        </select>
                                    </div>
                                    <div className="row mb-5">
                                        <div className="col-md-7 fv-row">
                                            <label className="required fs-5 fw-bold mb-2">Discount value:</label>
                                            <div className="d-flex">

                                                <input id="fname" type="text" className="form-control form-control-solid" placeholder="0" name="first-name" value={discount_value}

                                                    onChange={(e) => {

                                                        if(!isNaN(e.target.value))

                                                        {

                                                            setdiscount_value(e.target.value)

                                                        }

                                                        else{

                                                            swal("Oops!","Discount has to be a number","warning")

                                                        }

                                                    }}

                                                />

                                                {discount_type=="Percentage" &&<span> %</span>}

                                            </div>
                                        </div>
                                        
                                        
                                    </div>

                                    

                                </div>
                            </div>
                            <div className="modal-footer flex-center">
                                <button onClick={(e)=>{submitDiscountdetails(e)}} type="button" id="kt_modal_new_address_submit" className="btn btn-primary rounded">
                                    <span className="indicator-label">Apply</span>
                                    <span className="indicator-progress">Please wait...
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                </button>
                            </div>
                            <input type="hidden" id="id" name="activeId" />
                        </form>
                    </div>
                </div>
            </div>

            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>


        </>
    );
}
export default UpdateProductBilling;