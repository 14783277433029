import React, {lazy,Suspense,useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import $ from 'jquery';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';

function PurchaseTrail(props) {
	let [subscription_features, setSubscriptionFeatures] = useState([]);
	let [product_details, setProductDetails] = useState([]);
	let [product, setProduct] = useState("");
	let [user, setUser] = useState({});
	let [redirect, setRedirect] = useState(2);
	let [state,setstate] = useState(2);
	
	let [purchase_flag,setpurchase_flag] = useState("");
	
	let [timer,setTimer] = useState(0);
	let [clock_timer,setClockTimer] = useState(0);
	//const [organization_id, setOrganization_id] = useState("");
	const [visibility, setvisibility] = useState(0);
    let [redirect1, setredirect1] = useState(0);
    let [read, setread] = useState(2);
    let [write, setwrite] = useState(2);
	var page_name = "purchase-trial";
	let loader = 0;
	
	const loaderstyle = {
	  display: "block",
	  marginLeft: "auto",
	  marginRight: "auto",
	  marginTop: "auto",
	  marginBottom: "auto",
	};
	
	function setCookie(cname, cvalue, exdays) {
	  var d = new Date();
	  d.setTime(d.getTime() + (exdays*24*60*60*1000));
	  var expires = "expires="+ d.toUTCString();
	  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}
	
	function getCookie(cname) {
	  var name = cname + "=";
	  var ca = document.cookie.split(';');
	  for(var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
		  c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
		  return c.substring(name.length, c.length);
		}
	  }
	  return "";
	}
	
	const renderTime = ({ remainingTime }) => {
		
	  if (remainingTime === 0)
	  {
		//return <div className="timer">Too lale...</div>;
		window.location.href = "/login";
	  }

	  return (
		<div className="timer">
		  <div className="text">Remaining</div>
		  <div className="value">{remainingTime}</div>
		  <div className="text">seconds</div>
		</div>
	  );
	};
	
	useEffect(() => {
		setCookie("lastpage", window.location.href, 1)
		const validateJWT =()=>{
		
			if(getCookie("access_token") != "")
			{
				var object = {};
				object.token = getCookie("access_token");
			
				var headers = {
				  headers: {
					"Content-Type": "application/json",
					"Accept": "*/*"
				  }
				}
				axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`,object, headers).then((res) => {
				
					if(res.data.operation == "success")
					{
						var user_object = {};
						user_object.email = res.data.email;
						user_object.name = res.data.name;
						user_object.img = res.data.img;
						setUser(user_object)
					}
					else
					{
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
				.catch(function (error) {
					swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
				});
				
				axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`,object, headers).then((res) => {
				
					if(res.data.operation == "success")
					{
						var token = res.data.audience_license;
						if(res.data.organization_flag==0){
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
						else {
                            // alert("Please login")
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"]);
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"]);
                                            break;
                                        }
                                        else {
                                            setredirect1(1);
                                        }

                                    }
									else
									{
										temp = 2;
									}
                                }

                            }
							
							if(temp == 2)
                            {
                                setredirect1(1);
                            }
                        }
						if(!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi"))
						{
							setCookie('access_token', res.data.audience_license, 1)
							setTimer(process.env.REACT_APP_JWT_EXPIRY);
							//setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
						}
						else
						{
							setTimer(res.data.audience_count)
							//setClockTimer(res.data.audience_count)
						}
					}
					else
					{
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
				.catch(function (error) {
					console.log(error);
					swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
				});
				
			}
			else
			{
				swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
			}
			
				
		}
		
		validateJWT();
	},[])
	
	useEffect(() => {
		if(timer > 0)
		{
			const timer_decrease = setInterval(() => {
			  setTimer(timer - 1);
			  if(timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE)
			  {
				if(!document.getElementById("kt_modal_timer").classList.contains("show"))
				{
					setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
					window.$('#kt_modal_timer').modal('show');
				}
			  }
			}, 1000);
			
			return () => clearInterval(timer_decrease);
		}
	}, [timer]);
	
	
	useEffect(() => {
		
			async function getsubscriptionfeatureslist(values) {
				const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-subscription-feature-list`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name}), headers: {"Content-type": "application/json; charset=UTF-8", "token" : getCookie("access_token") }
						}
					);
				const body = await response.json();
				setSubscriptionFeatures(body)
			}
			
			(user.email && visibility) && getsubscriptionfeatureslist()
			
			
			async function getProductSubscriptionFeaturesList(values) {
				const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-product-subscription-feature-list`, { method: 'POST', body: JSON.stringify({ "email": user.email,"page_name":page_name}), headers: {"Content-type": "application/json; charset=UTF-8", "token" : getCookie("access_token") }
						}
					);
				const body = await response.json();
				
				var body2 = body.filter(function (el) {
				  return el.product_flag == "Trail";
				});
				
				//console.log(body2)
				if(body2.length > 0)
				{
					setProduct(body2[0].unique_id)
				}
				setProductDetails(body2)
				setstate(1)
			}
			if(read || write)
			{
				(user.email && visibility) && getProductSubscriptionFeaturesList()
			}
			
			async function getpurchaseflagdetails() {
			
				var object = {};
				object.email = user.email;
				
				const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-purchase-flag-details`, { method: 'POST', body : JSON.stringify({"email" : user.email,"page_name":page_name}) , headers: {"Content-type": "application/json; charset=UTF-8", "token" : getCookie("access_token") }}
					);
					const body = await response.json();
					//console.log(body.purchase_flag)
					setpurchase_flag(body.purchase_flag);
			}
			if(read || write)
			{
				(user.email && visibility) && getpurchaseflagdetails();
			}
			
	},[user, visibility, read, write])
	
	const purchase_plan =()=>{
		window.location.href="/subscription-list-trial/"+product;
	}
	
	
	return (
		<>
					{
						(redirect1 == 1 ||(read == 0 && write == 0)) ? 
							<>
								<Redirect to={{
									pathname: "/unauthorized",
								}}
								/>
							</> :
							null
					}
		
					{(product_details.length > 0 && purchase_flag == "not found") ?
					<>
					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
						<div className="toolbar" id="kt_toolbar">
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
								<div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
									<h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Trial Packages
										<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
										<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
									</h1>
									
								</div>
								
							</div>
						</div>
						
						
						
						<div className="post d-flex flex-column-fluid" id="kt_post">
						<div className="container" id="kt_content_container">
						<div className="card modal-body pt-0 pb-15 px-5 px-xl-5 ">
						<br />
						<div className="mb-13 text-center">
							<h1 className="mb-3">Trial Products</h1>
							<div className="text-gray-400 fw-bold fs-5">Check our trial  products.</div>
						</div>
						
						
						<div className="d-flex flex-column" id="kt_modal_upgrade_plan">
							
							<div className="card mt-5 mt-xxl-8">
									
									<div className="card-header card-header-stretch">
										
										
										
										<div className="card-rounded bg-light d-flex flex-stack flex-wrap p-5 col-12">
											
											<ul className="nav flex-wrap border-transparent fw-bolder col-12">
												
												{product_details.map((obj,index) => {
													if(obj.product_flag == "Trail")
													{
														if(index == 0)
														{
															return(
															<li className="nav-item my-1 col-6" role="presentation">
																<a onClick={(event) => {setProduct(obj.unique_id)}} className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase active" data-bs-toggle="tab" role="tab" href={`#kt_activity_product${index+1}`} aria-selected="true" >{obj.product_name}</a>
															</li>
															);
														}
														else{
															return(
															<li className="nav-item my-1 col-6" role="presentation">
																<a onClick={(event) => {setProduct(obj.unique_id)}} className="btn btn-color-gray-600 btn-active-white btn-active-color-primary fw-boldest fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase" data-bs-toggle="tab" role="tab" href={`#kt_activity_product${index+1}`} aria-selected="true">{obj.product_name}</a>
															</li>
															);
														}
													}
												})}
												
											</ul>
											
										</div>
										
										
										
									</div>
									
									
									<div className="card-body">
										
										<div className="tab-content">
										
											
											
											{product_details.map((obj,index) => {
													if(obj.product_flag == "Trail")
													{
														if(index == 0)
														{
															return(
															<>
																
																
																<div className="tab-pane fade show active" id={`kt_activity_product${index+1}`}>
																	<div className="row g-9" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button]">
																			{obj.subscription.map((obj,index) => {
																				return(
																				<div className="col-md-6 col-lg-6 col-xxl-6">
																					<label className="btn btn-outline btn-outline-dashed btn-outline-default active d-flex text-start p-6" data-kt-button="true">
																						
																						<span className="ms-5">
																							<span className="fs-4 fw-bolder mb-1 d-block">{obj.subscription_name}</span>
																							<span className="fw-bold fs-7 text-gray-600">{obj.short_description}</span>
																						</span>
																					</label>
																				</div>
																				);
																			})}
																			
																			
																	</div>
																	
																	<br />
																	
																	<div className="pb-5">
																		<h2 className="fw-bolder text-dark">What’s in {obj.product_name}?</h2>
																		<div className="text-gray-400 fw-bold">{obj.product_description}</div>
																	</div>
																	<div className="pt-1" >
																	
																	
																	{
																		subscription_features.map((obj2,index2) => {
																					if(obj.feature_limit.filter(item=> item == obj2.unique_id).length > 0)
																					{
																					return(
																						<div className="d-flex align-items-center mb-7">
																										<span className="fw-bold fs-5 text-gray-700 flex-grow-1">{obj2.feature_name}</span>
																										<span className="svg-icon svg-icon-1 svg-icon-success">
																											<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																												<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
																												<path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="#000000" fill-rule="nonzero" />
																											</svg>
																										</span>
																										
																						</div>
																					);
																					}
																				
																		})
																	}
																	
																	</div>
																</div>
															</>
															);
														}
														else
														{
															return(
																<>
																
																
																<div className="tab-pane fade"  id={`kt_activity_product${index+1}`}>
																	<div className="row g-9" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button]">
																			{obj.subscription.map((obj,index) => {
																				return(
																				<div className="col-md-6 col-lg-6 col-xxl-6">
																					<label className="btn btn-outline btn-outline-dashed btn-outline-default active d-flex text-start p-6" data-kt-button="true">
																						
																						<span className="ms-5">
																							<span className="fs-4 fw-bolder mb-1 d-block">{obj.subscription_name}</span>
																							<span className="fw-bold fs-7 text-gray-600">{obj.short_description}</span>
																						</span>
																					</label>
																				</div>
																				);
																			})}
																			
																			
																	</div>
																	
																	<br />
																	
																	<div className="pb-5">
																		<h2 className="fw-bolder text-dark">What’s in {obj.product_name}?</h2>
																		<div className="text-gray-400 fw-bold">{obj.product_description}</div>
																	</div>
																	<div className="pt-1" >
																	
																	
																	{
																		subscription_features.map((obj2,index2) => {
																					if(obj.feature_limit.filter(item=> item == obj2.unique_id).length > 0)
																					{
																					return(
																						<div className="d-flex align-items-center mb-7">
																										<span className="fw-bold fs-5 text-gray-700 flex-grow-1">{obj2.feature_name}</span>
																										<span className="svg-icon svg-icon-1 svg-icon-success">
																											<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																												<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
																												<path d="M16.7689447,7.81768175 C17.1457787,7.41393107 17.7785676,7.39211077 18.1823183,7.76894473 C18.5860689,8.1457787 18.6078892,8.77856757 18.2310553,9.18231825 L11.2310553,16.6823183 C10.8654446,17.0740439 10.2560456,17.107974 9.84920863,16.7592566 L6.34920863,13.7592566 C5.92988278,13.3998345 5.88132125,12.7685345 6.2407434,12.3492086 C6.60016555,11.9298828 7.23146553,11.8813212 7.65079137,12.2407434 L10.4229928,14.616916 L16.7689447,7.81768175 Z" fill="#000000" fill-rule="nonzero" />
																											</svg>
																										</span>
																										
																						</div>
																					);
																					}
																				
																		})
																	}
																	
																	</div>
																</div>
																</>
															);
														}
													}
											})}
											
											
											
										</div>
									</div>
							</div>
								
						</div>
						<div className="d-flex flex-center flex-row-fluid pt-12">
							{write ?
							<button type="submit" className="btn btn-primary" onClick={(e) => {purchase_plan();}} >View All Plans</button>
							:
							<button type="submit" className="btn btn-primary" onClick={(e) => {purchase_plan();}}disabled>View All Plans</button>
							}
						</div>
						<br />
						</div>
						</div>
						</div>
					</div>
					
					</>
					:
					<>
					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">
						
						<div className="post d-flex flex-column-fluid" id="kt_post">
						<div className="container" id="kt_content_container">
						<div className="card modal-body pt-0 pb-15 px-5 px-xl-20" >
						<br />
						
						{ state == 2 ?
                            <img src="/images/loader-06.svg" alt="" style={loaderstyle}  />
							:
							<>
						
									<div className="flex-lg-row-fluid me-lg-15">
											<div className="card card-flush pt-3 mb-5 mb-lg-10">
												
												<div className="card-header">
													<div className="card-title">
														<h2 className="fw-bolder">Purchase Plan</h2>
													</div>
												</div>
												
												
												<div className="card-body pt-0">
													<div className="text-gray-500 fw-bold fs-5 mb-5">Purchase a subscription:</div>
													<div className="d-flex align-items-center p-3 mb-2">
														
													</div>
													<div className="mb-7 d-none">
														<a  className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_customer_search">Select Customer</a>
														<span className="fw-bolder text-gray-500 mx-2">or</span>
														<a  className="btn btn-light-primary" data-bs-toggle="modal" data-bs-target="#kt_modal_users_search">Add New Customer</a>
													</div>
													
													<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
														<div className="d-flex flex-stack flex-grow-1">
															<div className="fw-bold">
																<h4 className="text-gray-800 fw-bolder">Subscriptions are not Available for the Product.</h4>
																<div className="fs-6 text-gray-600">
																</div>
															</div>
															
														</div>
														
													</div>
													
												</div>
												
											</div>			
									</div>
									
							</>
						}
						
						
						<br />
						</div>
						</div>
						</div>
					</div>
					</>
					}
					
					
					
								
								<div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">
									
									<div className="modal-dialog modal-dialog-centered mw-650px mh-950px">
										
										<div className="modal-content">
										
										<div className="modal-header" id="kt_modal_new_address_header">
												
													<h2>Session Out</h2>
													
													<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
														
														<span className="svg-icon svg-icon-1">
															<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
																	<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
																	<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
																</g>
															</svg>
														</span>
														
													</div>
													
												</div>
												
												<div className="modal-body py-10 px-lg-17">
													
													<div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
														
														<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">
															
															<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
																<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
																	<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
																	<rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
																	<rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
																</svg>
															</span>
															
															
															<div className="d-flex flex-stack flex-grow-1">
																
																<div className="fw-bold">
																	<h4 className="text-gray-800 fw-bolder">Warning</h4>
																	<div className="fs-6 text-gray-600">Your session is going to time out</div>
																</div>
																
															</div>
															
														</div>
														
														{clock_timer > 0 &&
														<div className="d-flex mb-9 p-6" style = {{"justify-content" : "center", "height" : "150px"}}>
															<CountdownCircleTimer
															  isPlaying
															  duration={clock_timer}
															  colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
															  onComplete={() => [true, 1000]}
															>
															  {renderTime}
															</CountdownCircleTimer>
														</div>
														}
														
													</div>
													
												</div>
												
												<div className="modal-footer flex-center">
													
													<button type="button" onClick={(e) => {window.location.reload()}}  className="btn btn-primary">
														<span className="indicator-label">Stay Signed in</span>
														<span className="indicator-progress">Please wait...
														<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
													</button>
													
												</div>
											
										</div>
									</div>
								</div>
					
						
		</>
	);
}
export default PurchaseTrail;