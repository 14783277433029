import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom'
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import axios from 'axios';
import swal from 'sweetalert';
import Select from 'react-select';

export const UpdateProfiles = (props) => {

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2)
    let [user, setUser] = useState({});
    const [clock_timer, setClockTimer] = useState(0);
    let [timer, setTimer] = useState(0);
    const [state, setState] = useState(2);

    const [organization_list, setorganization_list] = useState([]);
    const [organization_id, setOrganization_id] = useState("");
    const [profiles, setProfiles] = useState([]);
    const [profile, setProfile] = useState({ permissions: [] });
    const [newProfile, setNewProfile] = useState({ permissions: [] });
    const [profile_name, setProfile_name] = useState("");
    const [profile_description, setProfile_description] = useState("");
    const [profileId, setProfileId] = useState({});
    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
    const [buttonStat, setButtonStat] = useState(1)

    var page_name = "update-profile";

    const setCookie = (cname, cvalue, exdays) => {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    const getCookie = (cname) => {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    const renderTime = ({ remainingTime }) => {

        if (remainingTime === 0) {
            //return <div className="timer">Too lale...</div>;
            window.location.href = "/login";
        }

        return (
            <div className="timer">
                <div className="text">Remaining</div>
                <div className="value">{remainingTime}</div>
                <div className="text">seconds</div>
            </div>
        );
    };

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }
    useEffect(() => {
        let id = setInterval(() => {
            setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
        }, 10)

        return () => {
            clearInterval(id)
        }
    }, [])

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if (getCookie("organization_id") != "") {
                setOrganization_id(getCookie("organization_id"));
            }
            else {
                var object = {};
                object.page_name = page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
                    if (res.data.length == 1) {
                        setCookie('organization_id', res.data[0].organization_id, 1);
                        setOrganization_id(res.data[0].organization_id)
                    }
                    else {
                        setorganization_list(res.data)
                        window.$("#ModalOrganization").modal("show")
                        //setstate1(1)
                    }
                })
            }
        }
        else {
            swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                window.location.href = "/login"
            });
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id = organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
                                window.location.href = "/login"
                            });
                        }
                        else {

                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else if (component[j].hasOwnProperty("key")) {
                                        let sub_inside_components = component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if (sub_inside_components[k].hasOwnProperty(original_url)) {
                                                index = i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;

                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
            }
            else {
                swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                    window.location.href = "/login"
                });
            }
        }
        organization_id != "" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        async function get_profiles() {

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-profiles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "expense" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if (body.length > 0) {
                setProfiles(body)
            }
            else {
                setProfiles([])
            }
            setState(1)
        }
        if (read || write) {
            (user.email && visibility) && get_profiles();
        }
    }, [user, visibility, read, write])

    useEffect(() => {
        async function get_profile_details() {

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-profile-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "service": "expense","id":props.match.params.id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if (body.length > 0) {
                setProfile_name(body[0].user_profile);
                setProfile_description(body[0].profile_description);
                //handleProfile(body[0].user_profile_id)
                setProfileId(body[0].user_profile_id);
                setProfile(JSON.parse(body[0].user_profile_actions))
                setNewProfile(JSON.parse(body[0].user_profile_actions))
                //setProfiles(body)
            }
            else {
                //setProfiles([])
            }
            setState(1)
        }
        if (read || write) {
            (user.email && visibility) && get_profile_details();
        }
    }, [user, visibility, read, write])

    const handleProfile = (event) => {
        setProfileId(event);
        // console.log(profileId)
        setProfile(JSON.parse(profiles.find((profile) => profile.user_profile_id == event).user_profile_actions))
        setNewProfile(JSON.parse(profiles.find((profile) => profile.user_profile_id == event).user_profile_actions))
    }

    const processTitle = (title) => {
        return title.replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
    }
    const foreignCheck = (dependencies) => {
        const singleDependency = (dependency) => {
            let module_value = 0
            let item_value = 0
            let option_value = 0
            let final = 1
            let dependencies_tree = dependency.split(".")
            // length 1
            if (dependencies_tree.length >= 1) {
                newProfile.permissions.map((module) => {
                    if (module.key == dependencies_tree[0]) {
                        module_value = module.value
                    }
                })
                final = final * module_value
            }

            // length 2
            if (dependencies_tree.length >= 2 && module_value == 1) {
                newProfile.permissions.map((module) => {
                    if (module.key == dependencies_tree[0]) {
                        module.items.map((item) => {
                            if (item.key == dependencies_tree[1]) {
                                item_value = item.value
                            }
                        })
                    }
                })
                final = final * item_value
            }
            // length 3
            if (dependencies_tree.length >= 3 && module_value == 1 && item_value == 1) {
                newProfile.permissions.map((module) => {
                    if (module.key == dependencies_tree[0]) {
                        module.items.map((item) => {
                            if (item.key == dependencies_tree[1]) {
                                item.options.map((option) => {
                                    if (option.key == dependencies_tree[2]) {
                                        option_value = option.value
                                    }
                                })
                            }
                        })
                    }
                })
                final = final * option_value
            }
            return final
        }
        let foreignFlag = dependencies.map((dependency) => { return singleDependency(dependency) }).reduce((a, b) => a * b, 1)
        // console.log(dependencies,foreignFlag)

        return foreignFlag
    }


    const updateNewPermission = (module_name, item_name, option_name, check_value) => {
        // console.log(module_name, item_name, option_name, check_value)

        let temp = JSON.parse(JSON.stringify(newProfile))
        let temp2 = { permissions: [] }
        temp2.permissions = temp.permissions.map((module) => {
            if (module_name == module.key) {
                if (item_name == undefined) {
                    return { ...module, value:foreignCheck(module.foreign) && check_value?1:0}
                }
                else {
                    let temp3 = module.items.map((item) => {
                        if (item_name == item.key) {
                            if (option_name == undefined) {
                                return { ...item, value: foreignCheck(item.foreign) && check_value?1:0 }
                            }
                            else {
                                let temp4 = item.options.map((option) => {
                                    if (option_name == option.key) {
                                        return { ...option, value: foreignCheck(option.foreign) && check_value?1:0 }
                                    }
                                    else {
                                        return { ...option }
                                    }
                                })
                                return { ...item, options: temp4 }
                            }
                        }
                        else {
                            return { ...item }
                        }
                    })
                    return { ...module, items: temp3 }
                }
            }
            else {
                return { ...module }
            }
        })
        // console.log("part1=>",temp2)
        
        // temp2.permissions= temp2.permissions.map((module) => {
        //         let module_value = foreignCheck(module.foreign) && module.value
        //         let temp_items = module.items.map((item) => {
        //             let item_value = foreignCheck(item.foreign) && item.value
        //             let temp_options = item.options.map((option) => {
        //                 let option_value = foreignCheck(option.foreign) && option.value
        //                 return { ...option, value: option_value }
        //             })
        //             return { ...item, value: item_value, options: temp_options }
        //         })
        //         return { ...module, value: module_value, items: temp_items }
        //     })
        // console.log("part2=>",temp2)

        // let mainList = []
        // let StackDep = []
        // // let x = module_name + (item_name==undefined)?"":("."+item_name) + (option_name==undefined)?"":("."+option_name)
        // let x =[module_name,item_name, option_name ].filter(x=>x!=undefined).join(".")
        // StackDep.push(x)
        // while(StackDep.length){
        //     let y = StackDep.pop()
        //     mainList.push(y)
        //     for(let i=0;i<temp2.permissions.length;i++){
        //         temp2.permissions[i].foreign.includes(y) && StackDep.push(temp2.permissions[i].key)
        //         for(let j=0;j<temp2.permissions[i].items.length;j++){
        //             temp2.permissions[i].items[j].foreign.includes(y) && StackDep.push(temp2.permissions[i].key+"."+temp2.permissions[i].items[j].key)
        //             for(let k=0;k<temp2.permissions[i].items[j].options.length;k++){
        //                 temp2.permissions[i].items[j].options[k].foreign.includes(y) && StackDep.push(temp2.permissions[i].key+"."+temp2.permissions[i].items[j].key+"."+temp2.permissions[i].items[j].options[k].key)
        //             }
        //         }
        //     }
        // }
        // console.log(mainList,StackDep)
        setNewProfile(temp2)
    }
    const updateNewPermission1 = (module_name, item_name, option_name, check_value) => {
        // console.log(module_name, item_name, option_name, check_value)
        console.log("bc");
        let temp = JSON.parse(JSON.stringify(newProfile))
        let temp2 = { permissions: [] }
        temp2.permissions = temp.permissions.map((module) => {
            if (module_name == module.key) {
                if (item_name == undefined) {
                    
                    return { ...module, value:foreignCheck(module.foreign) && check_value?1:0}
                }
                else {
                    
                    let temp3 = module.items.map((item) => {
                        if (item_name == item.key) {
                            if (option_name == undefined) {
                                console.log("bc",check_value);
                                return { ...item, value:check_value}
                            }
                        }
                        else {
                            return { ...item }
                        }
                    })
                    return { ...module, items: temp3 }
                }
            }
            else {
                return { ...module }
            }
        })
        //console.log(temp2)
        setNewProfile(temp2)
    }

    const updateProfile = (e) => {
        e.preventDefault();
        var object = {};
        object.id = props.match.params.id;
        object.user_profile = profile_name.trim();
        object.profile_description =profile_description;
        object.user_profile_actions = JSON.stringify(newProfile)
        // console.log(newProfile)
        object.page_name = page_name;
        object.organization_id = organization_id
        object.service = "expense"

        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 /\\&$|~!@#()*\-+%{}\[\]_.;,:"'<>=]*)?$/;
        if (object.user_profile == "") {
            swal("Warning!", "Profile name cannot be a empty", "warning");
            return false;
        }
        else if (!object.user_profile.match(nexp)) {
            swal("Warning!", "Profile name cannot contain certain symbols ", "warning");
            return false;
        }
       
            profiles.map((profile) => {
                if(profile.user_profile_id!=object.id)
                {
                    if (profile.user_profile.trim().toLowerCase() == object.user_profile.toLowerCase()) {
                    swal("Warning!", "Profile name already exists", "warning");
                    return false;
                }
                else if (JSON.stringify(JSON.parse(profile.user_profile_actions)) == object.user_profile_actions) {
                    console.log("abc")
                    swal("Warning!", `Permission setting already exists for ${profile.user_profile}`, "warning");
                    return false;
                }

                }
                
            })
        

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        setButtonStat(0)
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-profile-details`, object, headers).then((res) => {
        setButtonStat(1)
             if (res.data && res.data.operation == "success") {
                 swal("Great!", "Profile added successfully!", "success").then((value) => {
                     // window.location.reload();
                 });
             }
             else if (res.data.operation === "same_entity") {
                 swal("Oops!", "Use a different profile name!!", "error");
             }
             else {
                 swal("Oops!", "Something went wrong!", "error");
             }
         }).catch(function (error) {
            console.log(error)
         });
    }
    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            <div className="d-flex flex-column flex-column-fluid">

                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">

                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Update Profile
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">

                        <div class="card h-100">
                            <div class="card-body">
                                <div className="overflow-auto pe-7 my-5">

                                    {profiles.length > 0 ?
                                        <>
                                            <div className="row mb-5">
                                                <div className="col-md-6 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">Profile Name</label>
                                                    <input type="text" name="customer_name" className="form-control" id="customer_name" placeholder="Profile Name" value={profile_name}
                                                        onChange={(e) => setProfile_name(e.target.value)}
                                                    />
                                                    <span className="form-text text-muted">Please give a profile name.</span>

                                                </div>

                                                <div className="col-md-6 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">Profile Reference</label>
                                                    <Select
                                                        styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                        class="form-select form-select-solid select2-accessible"
                                                        data-control="select2"
                                                        options={profiles.map((obj) => { return { label: obj.user_profile, value: obj.user_profile_id } })}
                                                        onChange={(event) => { handleProfile(event.value) }}
                                                    />
                                                    <span className="form-text text-muted">Select a already avaiable profile for reference</span>
                                                </div>
                                            </div>
                                            <div className="row mb-5">
                                                <div className="col-md-6 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">Profile Description</label>
                                                    <input type="text" name="Description" className="form-control" id="Description" placeholder="Profile Description" value={profile_description}
                                                        onChange={(e) => setProfile_description(e.target.value)}
                                                    />
                                                    <span className="form-text text-muted">Please give a profile Description.</span>
                                                </div>
                                            </div>
                                            {/* <div className="row mb-5"> */}
                                            <button className='btn btn-light-primary mx-2' onClick={(e) => updateProfile(e)} data-kt-indicator={buttonStat ? "off" : "on"} disabled={write && buttonStat ? false : true} >
                                                <span class="indicator-label">
                                                    Submit
                                                </span>
                                                <span class="indicator-progress">Please wait...
                                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                            {/* </div> */}
                                            <div className="row mt-10">
                                                <div className="col-12 fv-row">
                                                    <label className="fs-5 fw-bold mb-2">Permissions</label>
                                                    <div className="d-flex flex-wrap align-items-center justify-content-center">
                                                        {newProfile.permissions.length > 0 ?
                                                            <>
                                                                {newProfile.permissions.map((module,index1) => {
                                                                    return (
                                                                        <div className={`card border border-border-2 rounded shadow m-3`} style={{ minHeight: "200px", minWidth: "300px" }} >
                                                                            <div className='card-header align-items-center' style={{ minHeight: "40px", padding: "10px" }}>
                                                                                {processTitle(module.key)}
                                                                                <div className="form-check form-switch form-check-custom form-check-solid form-check-warning mt-2">
                                                                                    <input className="form-check-input w-40px h-20px" type="checkbox" checked={foreignCheck(module.foreign) && module.value} onChange={(e) => updateNewPermission(module.key, undefined, undefined, e.target.checked)} />
                                                                                </div>
                                                                            </div>
                                                                            <div className=' p-5' >
                                                                                <div className='d-flex justify-content-between flex-column mb-5'>

                                                                                    {module.items.map((item,index2) => {
                                                                                        return (
                                                                                            <>
                                                                                                {item.options.length > 0 ?
                                                                                                    <>
                                                                                                        <div className='d-flex container align-items-center justify-content-between my-2 p-0' title={processTitle(item.key)}>
                                                                                                        <div className='d-flex container align-items-center justify-content-between my-2 p-0' title={processTitle(item.key)}>
                                                                                                            <span>{processTitle(item.key)}</span>
                                                                                                            
                                                                                                            <div className="col-md-6 fv-row">
                                                                                                                <Select
                                                                                                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                                                                                                    class="form-select form-select-solid select2-accessible"
                                                                                                                    data-control="select2"
                                                                                                                    options={item.options.map((obj1) => { return { label: obj1.key, value: obj1.value}})}
                                                                                                                    value={{label:item.value,value:item.value}}
                                                                                                                    onChange={(e) => updateNewPermission1(module.key, item.key, undefined, e.value)} 
                                                                                                                />
                                                                                                            </div>
                                                                                                            
                                                                                                            {/*<div class="dropdown">
                                                                                                                <div class="" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
                                                                                                                    <span>{processTitle(item.key)}</span>
                                                                                                                    <i class="las la-angle-down px-2"></i>
                                                                                                                </div>
                                                                                                                <ul class="dropdown-menu p-3" aria-labelledby="dropdownMenuButton1">
                                                                                                                    {item.options.map((option) => {
                                                                                                                        return (
                                                                                                                            <>
                                                                                                                                <div className='d-flex container align-items-center justify-content-between my-2 p-0' title={processTitle(option.key)}>
                                                                                                                                    <span>{processTitle(option.key)}</span>

                                                                                                                                    <input className="form-check-input" type="checkbox" checked={foreignCheck(option.foreign) && option.value} onChange={(e) => updateNewPermission(module.key, item.key, option.key, e.target.checked)} />
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                        )
                                                                                                                    })}
                                                                                                                </ul>*/}
                                                                                                            </div>

                                                                                                            

                                                                                                        </div>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <div className='d-flex container align-items-center justify-content-between my-2 p-0' title={processTitle(item.key)}>
                                                                                                            <span>{processTitle(item.key)}</span>
                                                                                                            <div className="form-check form-switch form-check-custom form-check-solid form-check-success mt-2">
                                                                                                                <input className="form-check-input w-40px h-20px" type="checkbox" checked={foreignCheck(item.foreign) && item.value} onChange={(e) => updateNewPermission(module.key, item.key, undefined, e.target.checked)} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                        :
                                        <>
                                            {state == 2 ?
                                                <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                :
                                                <>
                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6 m-10">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">No Data Available.</h4>
                                                                <div className="fs-6 text-gray-600"> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </>
                                    }

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div class="modal fade" id="ModalOrganization" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1" role="dialog" aria-label="myModalLabel" aria-hidden="true" data-pause="99999999999999999">
                <div class="modal-dialog mw-700px">
                    <div class="modal-content ">
                        <div class="modal-header">
                            <h2>Select Organization</h2>
                        </div>
                        <div className="modal-body py-10 px-lg-17">
                            <form class="form-default">
                                <div class="modal-body tt-background">
                                    <div class="tt-modal-discount row">
                                        <div className="row mb-5">
                                            {
                                                organization_list.length > 0 ? organization_list.map((obj, index) => {
                                                    if (index < 5) {
                                                        return (
                                                            <>
                                                                <div class="col-lg-12">
                                                                    {(getCookie("organization_id") == obj.organization_id) ?
                                                                        <input type="radio" class="btn-check" onClick={(e) => { setCookie('organization_id', obj.organization_id, 1); window.location.reload(); }} name="account_type" value="personal" id={obj.organization_id} checked />
                                                                        :
                                                                        <input type="radio" class="btn-check" name="account_type" onClick={(e) => { setCookie('organization_id', obj.organization_id, 1); window.location.reload(); }} value="personal" id={obj.organization_id} />
                                                                    }
                                                                    <label class="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10" for={obj.organization_id}>
                                                                        <span class="svg-icon svg-icon-3x me-5">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                <path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black"></path>
                                                                                <path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black"></path>
                                                                            </svg>
                                                                        </span>
                                                                        <span class="d-block fw-bold text-start">
                                                                            <span class="text-dark fw-bolder d-block fs-4 mb-2">{obj.organization_name}</span>
                                                                            <span class="text-muted fw-bold fs-6">Organization ID:{obj.organization_id}</span>
                                                                        </span>
                                                                    </label>
                                                                    <div class="fv-plugins-message-container invalid-feedback"></div></div>
                                                            </>
                                                        );
                                                    }
                                                })
                                                    :
                                                    <>
                                                        {state == 2 ?
                                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                                            :
                                                            null
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

                <div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

                    <div className="modal-content">

                        <div className="modal-header" id="kt_modal_new_address_header">

                            <h2>Session Out</h2>

                            <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>

                            </div>

                        </div>

                        <div className="modal-body py-10 px-lg-17">

                            <div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

                                <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

                                    <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
                                            <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
                                            <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
                                        </svg>
                                    </span>


                                    <div className="d-flex flex-stack flex-grow-1">

                                        <div className="fw-bold">
                                            <h4 className="text-gray-800 fw-bolder">Warning</h4>
                                            <div className="fs-6 text-gray-600">Your session is going to time out</div>
                                        </div>

                                    </div>

                                </div>

                                {clock_timer > 0 &&
                                    <div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
                                        <CountdownCircleTimer
                                            isPlaying
                                            duration={clock_timer}
                                            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
                                            onComplete={() => [true, 1000]}
                                        >
                                            {renderTime}
                                        </CountdownCircleTimer>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="modal-footer flex-center">

                            <button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
                                <span className="indicator-label">Stay Signed in</span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateProfiles;