import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment'

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './timer-style.css';
import { Link, Redirect } from 'react-router-dom';

function AccountSecurity() {
	let [user, setUser] = useState({});
	let [state, setstate] = useState(2);
	const [GuidlineDetails, setGuidlineDetails] = useState([]);
	const [SubscriptionDetails, setSubscriptionDetails] = useState([]);
	const [AddressDetails, setAddressDetails] = useState([]);
	let [name, setname] = useState("");
	let [image, setImage] = useState("");
	let [login_sessions, setLoginSessions] = useState([]);
	let [date, setdate] = useState(new Date());
	let [purchase_flag, setpurchase_flag] = useState("");
	const [MessageCount, setMessageCount] = useState([]);
	let [timer, setTimer] = useState(0);
	let [clock_timer, setClockTimer] = useState(0);

	let [alert_count, set_alert_count] = useState(0);

	let [timezone_change, set_timezone_change] = useState("+00:00");

	const [visibility, setvisibility] = useState(0);
	let [redirect, setredirect] = useState(0);
	//const [organization_id, setOrganization_id] = useState("");
	let [read, setread] = useState(2);
	let [write, setwrite] = useState(2);

	var page_name = "account-security";
	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	const renderTime = ({ remainingTime }) => {

		if (remainingTime === 0) {
			//return <div className="timer">Too lale...</div>;
			window.location.href = "/login";
		}

		return (
			<div className="timer">
				<div className="text">Remaining</div>
				<div className="value">{remainingTime}</div>
				<div className="text">seconds</div>
			</div>
		);
	};

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	useEffect(() => {
		setCookie("lastpage", window.location.href, 1)
		const validateJWT = () => {

			if (getCookie("access_token") != "") {
				var object = {};
				object.token = getCookie("access_token");

				var headers = {
					headers: {
						"Content-Type": "application/json",
						"Accept": "*/*"
					}
				}
				axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var user_object = {};
						user_object.email = res.data.email;
						user_object.name = res.data.name;
						user_object.img = res.data.img;
						setUser(user_object)
					}
					else {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
					.catch(function (error) {
						console.log(error);
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					});

				axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						if (res.data.organization_flag == 0) {
							swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
								window.location.href = "/login"
							});
						}
						else {
							var path = window.location.pathname;
							path = path.replace(/\/$/, "");
							path = decodeURIComponent(path);
							var original_url = path.split("/")[1]
							let index = 0;
							let temp = -1;
							for (let i = 0; i < res.data.user_access.access.length; i++) {
								if(temp == 1) break;
								let component = res.data.user_access.access[i].inside_components;
								for (let j = 0; j < component.length; j++) {
									if (component[j].hasOwnProperty(original_url)) {
										index = i;
										if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
											setvisibility(1);
											setread(res.data.user_access.access[index].inside_components[j]["read"])
											setwrite(res.data.user_access.access[index].inside_components[j]["write"])
											// setwrite(0)
											break;
										}
										else {
											setredirect(1);
										}

									}
									else
									{
										temp = 2;
									}
								}

							}
							if(temp == 2)
                            {
                                setredirect(1);
                            }
						}
						var token = res.data.audience_license;

						if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
							setCookie('access_token', res.data.audience_license, 1)
							setTimer(process.env.REACT_APP_JWT_EXPIRY);
							//setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
						}
						else {
							setTimer(res.data.audience_count)
							//setClockTimer(res.data.audience_count)
						}
					}
					else {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
					.catch(function (error) {
						console.log(error);
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					});

				headers = {
					headers: {
						"Content-Type": "application/json",
						"Accept": "*/*",
						"token": getCookie("access_token")
					}
				}

				axios.post(`${process.env.REACT_APP_SERVER_URL}/get-recent-login`, object, headers).then((res) => {
					setLoginSessions(res.data);
				})
					.catch(function (error) {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					});
			}
			else {
				swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
					window.location.href = "/login"
				});
			}
		}

		validateJWT();

	}, [])

	useEffect(() => {
		if (timer > 0) {
			const timer_decrease = setInterval(() => {
				setTimer(timer - 1);
				if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
					if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
						setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
						window.$('#kt_modal_timer').modal('show');
					}
				}
			}, 1000);

			return () => clearInterval(timer_decrease);
		}
	}, [timer]);

	useEffect(() => {
		async function getlivealertdetails() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-live-alert-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			var count = 0;
			for (var i = 0; i < body.live_alert.length; i++) {
				if (body.live_alert[i].add_status == "Active") {
					count = count + 1;
				}
			}
			set_alert_count(count)

		}
		if (read || write) {
			user.email && visibility && getlivealertdetails();
		}


	}, [user, visibility, read, write])

	useEffect(() => {

		async function getpurchaseflagdetails() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-purchase-flag-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			//console.log(body.purchase_flag)
			setpurchase_flag(body.purchase_flag);

		}
		if (read || write) {
			user.email && visibility && getpurchaseflagdetails();
		}

		async function getguidelinedetails() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-security-guidlines`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			//console.log(body[0].publish_date)
			body.map((obj, index) => {
				let today1 = new Date(obj.publish_date).toLocaleString('hi-IN', { timeZone: 'Asia/Kolkata', hour12: false });
				var today_array = today1.split(", ");
				var date_string = today_array[0];
				var date_string_array = date_string.split("/");
				let date = new Date(date_string_array[2], date_string_array[1] - 1, date_string_array[0].padStart(2, '0')); let longMonth = date.toLocaleString('en-us', { month: 'long' }); var today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

				var today = date_string_array[0].padStart(2, '0') + " " + longMonth + " " + date_string_array[2];

				body[index].publish_date = today;
			})
			setGuidlineDetails(body);
			//setloading(true);
		}
		if (read || write) {
			user.email && visibility && getguidelinedetails();
		}

		async function getusessubscription() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-uses-subscription`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			body.map((obj, index) => {
				body[index].ending_date1 = moment.utc(body[index].ending_date).add(1, 'days');
			})
			//console.log(body)
			setSubscriptionDetails(body);
			//setloading(true);
		}
		if (read || write) {
			user.email && visibility && getusessubscription();
		}

		async function getaddressinformation() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-address-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			//setphn(user.phone)
			if (body.length > 0) {

				setAddressDetails(body);

			}
		}
		if (read || write) {
			user.email && visibility && getaddressinformation();
		}

		async function getaccountdetails() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-account-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			if (body.length > 0) {
				if (body[0].timezone_value != "" && body[0].timezone_value != null) {
					let timezone_change = body[0].timezone_value;

					set_timezone_change(timezone_change)
				}

				setname(body[0].account_name)
				if (body[0].image_name.startsWith("http")) {
					setImage(body[0].image_name)
				}
				else if (body[0].image_name) {
					setImage(`https://shavastra-gn.s3.ap-south-1.amazonaws.com/account_image/${body[0].image_name}`);
				}
				else {
					setImage(user.img)
				}
			}
			else {

				setname(user.name)
				setImage(user.img)
			}

		}
		if (read || write) {
			user.email && visibility && getaccountdetails();
		}

		async function getmessagecount() {

			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/account-activity`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			setMessageCount(body);

		}
		if (read || write) {
			user.email && visibility && getmessagecount();
		}

	}, [user, login_sessions, visibility, read, write])



	//console.log(GuidlineDetails[1].heading)
	return (
		<>


			{
				(redirect == 1 || (read == 0 && write == 0)) ?
					<>
						<Redirect to={{
							pathname: "/unauthorized",
						}}
						/>
					</> :
					null
			}


			{user.email ?
				<>
					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">

						<div className="toolbar" id="kt_toolbar">
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
								<div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
									<h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Account Security
										<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
										<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
									</h1>
								</div>

							</div>
						</div>

						<div className="post d-flex flex-column-fluid" id="kt_post">
							<div className="container" id="kt_content_container">
								<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
									<br />

									<div className="card mb-5 mb-xl-10">
										<div className="card-body pt-9 pb-0">

											<div className="d-flex flex-wrap flex-sm-nowrap mb-3">

												<div className="me-7 mb-4">
													<div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
														<img style={{ "max-height": "150px", "max-width": "150px" }} src={image} alt="image" className="rounded" />
														<div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
													</div>
												</div>


												<div className="flex-grow-1">

													<div className="d-flex justify-content-between align-items-start flex-wrap mb-2">

														<div className="d-flex flex-column">

															<div className="d-flex align-items-center mb-2">
																<a className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">{name}</a>

																{SubscriptionDetails.length > 0 ? SubscriptionDetails.map((obj, index) => {
																	return (
																		<>
																			{obj.ending_date1 > date ?
																				<a className="btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3 rounded" data-bs-toggle="modal" data-bs-target="#kt_modal_upgrade_plan">{obj.product_name}  -  {obj.subscription_name}</a>
																				:
																				null
																			}
																		</>
																	);
																})
																	:
																	null
																}
															</div>


															<div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">

																<a className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">


																	{user.email}</a>
															</div>

														</div>

													</div>
													<div className="d-flex flex-wrap flex-stack">

														<div className="d-flex flex-column flex-grow-1 pe-8">

															<div className="d-flex flex-wrap">



																{
																	(MessageCount.length) ?
																		<>
																			<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

																				<div className="d-flex align-items-center">



																					<div className="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="4500" data-kt-countup-prefix="$">{MessageCount[0].message_count}</div>
																				</div>

																				<div className="fw-bold fs-6 text-gray-400">Message</div>

																			</div>

																			<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

																				<div className="d-flex align-items-center">



																					<div className="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="75">{MessageCount[0].delivery_count}</div>
																				</div>

																				<div className="fw-bold fs-6 text-gray-400">Delivery</div>

																			</div>
																			<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">

																				<div className="d-flex align-items-center">

																					<span className="svg-icon svg-icon-3 svg-icon-success me-2">

																					</span>

																					<div className="fs-2 fw-bolder counted" data-kt-countup="true" data-kt-countup-value="60" data-kt-countup-prefix="%">{alert_count}</div>
																				</div>

																				<div className="fw-bold fs-6 text-gray-400">Direct Alerts</div>

																			</div>
																		</>
																		: null
																}



															</div>

														</div>

														{AddressDetails.length > 0 ?
															<div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
																<div className="d-flex justify-content-between w-100 mt-auto mb-2">
																	<span className="fw-bold fs-6 text-gray-400">Profile Completion</span>
																	<span className="fw-bolder fs-6">100%</span>
																</div>
																<div className="h-5px mx-3 w-100 bg-light mb-3">
																	<div className="bg-success rounded h-5px" role="progressbar" style={{ "width": "100%" }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
																</div>
															</div>
															:
															<div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
																<div className="d-flex justify-content-between w-100 mt-auto mb-2">
																	<span className="fw-bold fs-6 text-gray-400">Profile Completion</span>
																	<span className="fw-bolder fs-6">90%</span>
																</div>
																<div className="h-5px mx-3 w-100 bg-light mb-3">
																	<div className="bg-success rounded h-5px" role="progressbar" style={{ "width": "90%" }} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
																</div>
															</div>
														}

													</div>

												</div>

											</div>

											<div className="d-flex overflow-auto h-55px">
												<ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">

													<li className="nav-item">
														<Link className="nav-link text-active-primary me-6" to="/account-overview">Overview</Link>
													</li>

													<li className="nav-item">
														<Link className="nav-link text-active-primary me-6" to="/account-setting">Settings</Link>
													</li>

													<li className="nav-item">
														<Link className="nav-link text-active-primary me-6 active" to="/account-security">Security</Link>
													</li>

													<li className="nav-item">
														<Link className="nav-link text-active-primary me-6" to="/account-billing">Billing</Link>
													</li>

												</ul>
											</div>

										</div>
									</div>
									{purchase_flag == "not found" ?
										<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">

											<span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
												<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
													<path d="M18.5,8 C17.1192881,8 16,6.88071187 16,5.5 C16,4.11928813 17.1192881,3 18.5,3 C19.8807119,3 21,4.11928813 21,5.5 C21,6.88071187 19.8807119,8 18.5,8 Z M18.5,21 C17.1192881,21 16,19.8807119 16,18.5 C16,17.1192881 17.1192881,16 18.5,16 C19.8807119,16 21,17.1192881 21,18.5 C21,19.8807119 19.8807119,21 18.5,21 Z M5.5,21 C4.11928813,21 3,19.8807119 3,18.5 C3,17.1192881 4.11928813,16 5.5,16 C6.88071187,16 8,17.1192881 8,18.5 C8,19.8807119 6.88071187,21 5.5,21 Z" fill="#000000" opacity="0.3"></path>
													<path d="M5.5,8 C4.11928813,8 3,6.88071187 3,5.5 C3,4.11928813 4.11928813,3 5.5,3 C6.88071187,3 8,4.11928813 8,5.5 C8,6.88071187 6.88071187,8 5.5,8 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 C14,5.55228475 13.5522847,6 13,6 L11,6 C10.4477153,6 10,5.55228475 10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,18 L13,18 C13.5522847,18 14,18.4477153 14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 Z M5,10 C5.55228475,10 6,10.4477153 6,11 L6,13 C6,13.5522847 5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 C18.4477153,14 18,13.5522847 18,13 L18,11 C18,10.4477153 18.4477153,10 19,10 Z" fill="#000000"></path>
												</svg>
											</span>

											<div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">

												<div className="mb-3 mb-md-0 fw-bold">
													<h4 className="text-gray-800 fw-bolder">Activate Trial Account</h4>
													<div className="fs-6 text-gray-600 pe-7">You can Activate your Trial account and enjoy 7 days of free subscription.</div>
												</div>
												{
													write?
													<Link to="/purchase-trial" className="btn btn-primary px-6 align-self-center text-nowrap rounded">Activate</Link>
													:
													<button className="btn btn-primary px-6 align-self-center text-nowrap rounded" disabled>Activate</button>
												}
												

											</div>

										</div>
										:
										null
									}

									<div className="row g-xxl-9">

										<div className="col-xxl-8">

											<div className="card card-xxl-stretch mb-1 mb-xl-1">

												<div className="card-header card-header-stretch">

													<div className="card-title">
														<h3 className="m-0 text-gray-800">Security Summary</h3>
													</div>

												</div>
											</div>
										</div>



										<div className="col-xxl-12">


											<div className="card card-xxl-stretch-50 mb-2 mb-xl-2">

												<div className="card-body pt-5">

													<div id="kt_security_guidelines" className="carousel carousel-custom carousel-stretch slide" data-bs-ride="carousel" data-bs-interval="8000">

														<div className="d-flex flex-stack align-items-center flex-wrap">
															<h4 className="text-gray-400 fw-bold mb-0 pe-2">Security Guidelines</h4>

															<ol className="p-0 m-0 carousel-indicators carousel-indicators-dots">
																<li data-bs-target="#kt_security_guidelines" data-bs-slide-to="0" className="ms-1 active"></li>
																<li data-bs-target="#kt_security_guidelines" data-bs-slide-to="1" className="ms-1"></li>
																<li data-bs-target="#kt_security_guidelines" data-bs-slide-to="2" className="ms-1"></li>
															</ol>

														</div>


														<div className="carousel-inner pt-6">


															{GuidlineDetails.length > 0 ? GuidlineDetails.map((obj, index) => {
																return (
																	<>
																		{index == 0 ?
																			<div className="carousel-item active">

																				<div className="carousel-wrapper">

																					<div className="d-flex flex-column flex-grow-1">
																						<a className="fs-5 fw-bolder text-dark text-hover-primary">{GuidlineDetails[index].heading}</a>
																						<p className="text-gray-600 fs-6 fw-bold pt-3 mb-0">{GuidlineDetails[index].short_description}</p>
																					</div>


																					<div className="d-flex flex-stack pt-8">
																						<span className="badge badge-light-primary fs-7 fw-bolder me-2 rounded">{GuidlineDetails[index].publish_date}</span>
																						<a onClick={(e) => { window.location.href = obj.link }} className="btn btn-sm btn-light">{GuidlineDetails[index].button}</a>
																					</div>

																				</div>

																			</div>
																			:
																			<div className="carousel-item">

																				<div className="carousel-wrapper">

																					<div className="d-flex flex-column flex-grow-1">
																						<a className="fw-bolder text-dark text-hover-primary">{GuidlineDetails[index].heading}</a>
																						<p className="text-gray-600 fs-6 fw-bold pt-3 mb-0">{GuidlineDetails[index].short_description}</p>
																					</div>


																					<div className="d-flex flex-stack pt-8">
																						<span className="badge badge-light-primary fs-7 fw-bolder me-2">{GuidlineDetails[index].publish_date}</span>
																						<Link to={`${obj.link}`} className="btn btn-light btn-sm btn-color-muted fs-7 fw-bolder px-5">{GuidlineDetails[index].button}</Link>
																					</div>

																				</div>

																			</div>
																		}

																	</>
																);
															})
																:
																null
															}
														</div>

													</div>

												</div>

											</div>
										</div>

									</div>

									<div className="card mb-5 mb-xl-10">

										<div className="card-header">

											<div className="card-title">
												<h3>Login Sessions</h3>
											</div>

										</div>

										<div className="card-body">
											<div className="tab-content">
												<div id="kt_activity_today" className="card-body p-0 tab-pane fade show active" role="tabpanel" aria-labelledby="kt_activity_today_tab">
													<div className="timeline">
														<div className="timeline-item">
															<div className="card-body pt-5">
																<div className="timeline-label">
																	{
																		login_sessions.length > 0 ? login_sessions.map((obj, index) => {
																			return (
																				<div className="timeline-item">
																					<div className="timeline-label fw-bolder text-gray-800 fs-6">Login Time</div>
																					<div className="timeline-badge">
																						<i className="fa fa-genderless text-success fs-1"></i>
																					</div>
																					<div className="timeline-content fw-mormal text-muted ps-3" >{moment(obj.instant).zone(timezone_change).format('DD MMM YYYY HH:mm:ss')}</div>
																				</div>
																			)
																		}) : null
																	}
																</div>
															</div>
														</div>
													</div>
												</div>

											</div>
										</div>



									</div>




									<br />
								</div>
							</div>
						</div>
					</div>
				</>
				:
				<>
					<div className="content d-flex flex-column flex-column-fluid" id="kt_content">

						<div className="toolbar" id="kt_toolbar">
							<div id="kt_toolbar_container" className="container-fluid d-flex flex-stack">
								<div data-kt-place="true" data-kt-place-mode="prepend" data-kt-place-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}" className="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
									<h1 className="d-flex align-items-center text-dark fw-bolder my-1 fs-3">Account Security
										<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
										<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
									</h1>

								</div>

							</div>
						</div>

						<div className="post d-flex flex-column-fluid" id="kt_post">
							<div className="container" id="kt_content_container">
								<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
									<br />

									{state == 2 ?
										<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
										:
										<>

											<div className="flex-lg-row-fluid me-lg-15">
												<div className="card card-flush pt-3 mb-5 mb-lg-10">

													<div className="card-header">
														<div className="card-title">
															<h2 className="fw-bolder">Account Security</h2>
														</div>
													</div>


													<div className="card-body pt-0">
														<div className="text-gray-500 fw-bold fs-5 mb-5"></div>
														<div className="d-flex align-items-center p-3 mb-2">


														</div>


														<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
															<div className="d-flex flex-stack flex-grow-1">
																<div className="fw-bold">
																	<h4 className="text-gray-800 fw-bolder">Please Login</h4>
																	<div className="fs-6 text-gray-600">
																	</div>
																</div>

															</div>

														</div>

													</div>

												</div>
											</div>

										</>
									}


									<br />
								</div>
							</div>
						</div>
					</div>
				</>
			}




			<div className="modal fade in" id="kt_modal_timer" tabindex="-1" aria-hidden="true">

				<div className="modal-dialog modal-dialog-centered mw-650px mh-950px">

					<div className="modal-content">

						<div className="modal-header" id="kt_modal_new_address_header">

							<h2>Session Out</h2>

							<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">

								<span className="svg-icon svg-icon-1">
									<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
										<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
											<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
											<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
										</g>
									</svg>
								</span>

							</div>

						</div>

						<div className="modal-body py-10 px-lg-17">

							<div className=" me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">

								<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-3 p-6">

									<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
											<rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
											<rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
										</svg>
									</span>


									<div className="d-flex flex-stack flex-grow-1">

										<div className="fw-bold">
											<h4 className="text-gray-800 fw-bolder">Warning</h4>
											<div className="fs-6 text-gray-600">Your session is going to time out</div>
										</div>

									</div>

								</div>

								{clock_timer > 0 &&
									<div className="d-flex mb-9 p-6" style={{ "justify-content": "center", "height": "150px" }}>
										<CountdownCircleTimer
											isPlaying
											duration={clock_timer}
											colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
											onComplete={() => [true, 1000]}
										>
											{renderTime}
										</CountdownCircleTimer>
									</div>
								}

							</div>

						</div>

						<div className="modal-footer flex-center">

							<button type="button" onClick={(e) => { window.location.reload() }} className="btn btn-primary">
								<span className="indicator-label">Stay Signed in</span>
								<span className="indicator-progress">Please wait...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
							</button>

						</div>

					</div>
				</div>
			</div>

		</>
	);
}
export default AccountSecurity;